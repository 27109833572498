<template lang="pug">
  div(:class="b()")
    div(:class="b('title')").text--secondary Dictámenes anteriores
    div(:class="b('list')")
      opinion-item(
        v-for="opinion in [...opinions].reverse()"
        :key="opinion.id"
        :opinion="opinion"
        :currency="currency"
      )
</template>

<script>
  import OpinionItem from './opinions-list-item';

  export default {
    name: 'opinions-list',

    props: {
      opinions: { type: Array, default: () => [] },
      currency: { type: Object },
    },

    components: { OpinionItem },
  };
</script>

<style lang="sass" scoped>
  .opinions-list
    &__title
      font-size: 18px
      text-align: center
</style>