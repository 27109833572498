<template lang="pug">
  tr
    td
    td(style="font-weight: 500; background-color: #eaeaea") Productos
    td(colspan="5" style="padding: 0")
      table(:class="b('inner-table')")
        tr(v-for="product, idx in entry.products" :key="idx")
          td {{ product.product }}
          td.text-no-wrap {{ product.amount | formatCurrency }}
</template>

<script>
import getCalificationColor from '@/utils/buro-qualification-color';
import formatCalification from '@/utils/format-buro-qualification';

export default {
  name: 'summary-table-products-row',

  props: {
    entry: { type: Object },
  },

  methods: { getCalificationColor },

  filters: { formatCalification },
};
</script>

<style lang="sass" scoped>
  .summary-table-products-row
    &__inner-table
      border-collapse: collapse
      width: 100%

      td
        background-color: darken(white, 2%)
</style>