<template lang="pug">
  div(:class="b()")
    template(v-if="infoMonthlyRows.length")
      .tax-report__title Declaración Mensual ({{ new Date().getFullYear() }})
      tax-report-monthly-third-chart(:entries="infoMonthlyRows")
    template(v-if="yearlyThirdRows.length")
      .tax-report__title Declaraciones anuales
      tax-report-yearly-third-chart(:entries="yearlyThirdRows")
</template>

<script>
import TaxReportYearlyThirdChart from './tax-report-yearly-third-chart.vue';
import TaxReportMonthlyThirdChart from './tax-report-monthly-chart.vue';

export default {
  name: 'tax-report-third-declared-tab',

  props: {
    infoMonthlyRows: { type: Array, default: () => [] },
    yearlyThirdRows: { type: Array, default: () => [] },
  },


  components: { TaxReportYearlyThirdChart, TaxReportMonthlyThirdChart },
};
</script>

<style lang="sass" scoped>

</style>