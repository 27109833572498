<template lang="pug">
  div(:class="b()")
    figure(:class="b('avatar')")
      img(:src="testimony.image")
    div(:class="b('body')")
      div(:class="b('names')") {{ testimony.names }}
      div(:class="b('occupation')") {{ testimony.occupation }}
      div(:class="b('message')") {{ testimony.testimony }}
      div(:class="b('actions')").text-right
        v-btn(text color="error" @click="onClickDelete").mr-2 Eliminar
        v-btn(text color="primary" @click="onClickEdit") Editar
</template>

<script>
export default {
  name: 'testimonies-item',

  props: {
    testimony: { type: Object },
  },

  methods: {
    onClickDelete() {
      if(window.confirm('¿Está seguro de que desea eliminar el testimonio?'))
        this.$emit('click-delete', { testimony: this.testimony });
    },

    onClickEdit() {
      this.$emit('click-edit', { testimony: this.testimony });
    },
  },
};
</script>

<style lang="sass" scoped>
  .testimonies-item
    display: flex
    padding-bottom: 8px

    &__avatar
      flex-shrink: 0
      width: 64px
      height: 64px
      margin-right: 16px
      background-color: $cloudyColor
      border-radius: 50%
      font-size: 0

      img
        width: 100%
        height: 100%
        border-radius: 50%

    &__body
      flex: 1

    &__names, &__occupation
      font-weight: 500

    &__occupation
      color: darken(white, 60%)

    &__message
      padding-top: 4px
</style>