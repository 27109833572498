<template lang="pug">
  div(:class="b({ empty: !value && !initialImage, error: Boolean(errorMessages) })" @click="onClickAvatar")
    div(:class="b('wrapper')")
      user-avatar(
        :url="value || initialImage"
        :first-name="firstName"
        :last-name="lastName"
        :size="size"
      )
      input(
        :class="b('input')"
        @change="onChangeInput"
        ref="input"
        type="file"
        accept=".png,.jpg,.jpeg,.bmp,.gif"
      )
      div(:class="b('overlay')")
        v-icon {{ overlayIcon }}
    .v-messages.error--text.mt-1(v-if="errorMessages && errorMessages.length")
      .v-messages__message(v-for="error in errorMessages" :key="error") {{ error }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';

  const fileToBase64 = (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  export default {
    name: 'avatar-input',

    props: {
      value: { type: String },
      initialImage: { type: String },
      firstName: { type: String },
      lastName: { type: String },
      size: { type: Number },
      errorMessages: { type: Array },
    },

    methods: {
      onClickAvatar() {
        this.$refs.input.click();
      },

      onChangeInput(event) {
        const files = event.target.files;

        if(!files.length) return;

        fileToBase64(files[0])
          .then(base64 => this.$emit('input', base64));
      },
    },

    computed: {
      overlayIcon() {
        return !this.value && !this.initialImage ? 'mdi-cloud-upload' : 'mdi-pencil';
      },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass" scoped>
  .avatar-input
    $c: &

    display: inline-block

    &__wrapper
      position: relative
      cursor: pointer
      display: inline-block

    &__input
      display: none

    &__overlay
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      background-color: rgba(0,0,0,0.3)
      border-radius: 50%
      opacity: 0

      &:hover
        opacity: 1

      i
        color: darken(white, 10%)

    &--empty
      #{$c}
        &__overlay
          opacity: 1

    &--error
      #{$c}
        &__overlay
          background-color: rgba(255,0,0,1)
</style>