<template lang="pug">
  .dashboard-card(:class="b()")
    .dashboard-card__header
      .dashboard-card__title Negociaciones sin actividad
      .dashboard-card__count(v-if="deals") {{ deals.length }}
    .dashboard-card__body
      .dashboard-card__scrollable-content
        loading-wrapper(
          v-if="loading || error"
          :error="error"
          @retry="args => $emit('retry', args)"
        )
        template(v-else-if="deals && deals.length")
          deal-card(
            v-for="deal in deals"
            :key="deal.id"
            :deal="deal"
          )
        v-alert(v-else color="info" :value="true" outlined) Estás al día con tus negociaciones 🌈
</template>

<script>
  import DealCard from '@/views/deals/list/components/board-view/deal-card';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  export default {
    name: 'deals-without-activity',

    props: {
      deals: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
      error: { type: Error },
    },

    components: { DealCard, LoadingWrapper },
  };
</script>

<style lang="sass" scoped>

</style>