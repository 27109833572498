import VSelect from 'vuetify/lib/components/VSelect';

export default {
  name: 'adviser-group-select',

  extends: VSelect,

  props: {
    items: { type: Array, default: () => [
      { value: 'IN_HOUSE', text: 'RebajaTusCuentas' },
      { value: 'EXTERNAL', text: 'Externos' },
    ]},
    label: { type: String, default: 'Asesores' },
  },
};
