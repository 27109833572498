<template lang="pug">
  v-select(
    :value="value"
    :name="name"
    :disabled="disabled"
    :items="items"
    :label="label"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :item-disabled="itemDisabled"
    @input="args => $emit('input', args)"
  )
</template>

<script>
const MONTHS = [
  { value: 1, text: 'Enero' },
  { value: 2, text: 'Febrero' },
  { value: 3, text: 'Marzo' },
  { value: 4, text: 'Abril' },
  { value: 5, text: 'Mayo' },
  { value: 6, text: 'Junio' },
  { value: 7, text: 'Julio' },
  { value: 8, text: 'Agosto' },
  { value: 9, text: 'Septiembre' },
  { value: 10, text: 'Octubre' },
  { value: 11, text: 'Noviembre' },
  { value: 12, text: 'Diciembre' },
];

export default {
  name: 'month-select',

  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String },
    name: { type: String },
    value: { type: Number },
    errorMessages: { type: Array, default: () => [] },
    hideDetails: { type: Boolean, default: false },
    itemDisabled: { type: Function },
  },

  data() {
    return {
      items: MONTHS,
    };
  },
};
</script>

<style lang="sass" scoped>

</style>