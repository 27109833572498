<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    property-form(
      :property="property"
      :customer="customer"
      :fill-risk="fillRisk"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import PropertyForm from '@/components/forms/property-form';

  export default {
    name: 'property-form-modal',

    props: {
      fillRisk: { type: Boolean, default: false },
      customer: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'property' })],

    components: { PropertyForm },
  };
</script>
