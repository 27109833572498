import VSelect from 'vuetify/lib/components/VSelect';
import Config from '@/config';


export default {
  name: 'civil-status-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: { type: Array, default: () => Config.MARITAL_STATUS_SELECT },
    label: { type: String, default: 'Estado Civil' },
  },
};
