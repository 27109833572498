import DealSelect from '@/components/inputs/deal-select';

import { RTC_BANK_ID, DEAL_STATUS } from '@/utils/constants';

export default {
  extends: DealSelect,

  props: {
    additionalSearchParams: {
      type: Object,
      default: () => ({
        status: DEAL_STATUS.ACTIVE,
        bankId: RTC_BANK_ID,
        investorAssigned: false,
      })
    },
  },
};