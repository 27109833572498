<template lang="pug">
  form(@submit.prevent="onSubmit")
    v-layout(wrap)
      v-flex(xs12 sm6 md4).px-2
        bank-select(
          v-model="data.bankId"
          label="Banco"
          :lazy="lazySelects"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.simpleFee"
          :prefix="data.currency.symbol"
          type="number"
          step="0.01"
          :label="$t('simpleFee')"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.doubleFee"
          :prefix="data.currency.symbol"
          type="number"
          step="0.01"
          label="Cuota Doble"
        )
      v-flex(xs12 sm6 md4).px-2
        rate-type-select(
          v-model="data.rateType"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.tea"
          type="number"
          step="0.01"
          :label="$t('tea')"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.tcea"
          type="number"
          step="0.01"
          :label="$t('tcea')"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.limitMonths"
          type="number"
          label="Plazo"
        )
      v-flex(xs12 sm6 md4).px-2
        v-text-field(
          v-model.number="data.credit"
          :prefix="data.currency.symbol"
          type="number"
          label="Crédito"
        )
      v-flex(v-if="renderField('currencyId')" xs12 sm6 md4).px-2
        currency-select(
          v-model="data.currency"
          return-object
        )
      v-flex(xs12 sm6 md4).px-2
        v-switch(
          label="Activa"
          v-model="data.active"
        )
    v-divider
    v-card-actions
      v-spacer
      v-btn(color="error" text @click="onCancel") Cancelar
      v-btn(
        :loading="requesting"
        color="primary"
        text
        type="submit"
      ) Guardar
</template>

<script>
import Config from '@/config';
import basicFormMixin from '@/components/forms/basic-form-mixin';
import RateTypeSelect from '@/components/inputs/credit-rate-type-select';
import CurrencySelect from '@/components/inputs/currency-select';
import BankSelect from '@/components/inputs/bank-select';
import { IS_MX_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'proposal-form',

  props: {
    lazySelects: { type: Boolean, default: true },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'proposal',
      mapPropsToData({ proposal }) {
        return {
          id: proposal?.id,
          simpleFee: proposal?.simpleFee,
          doubleFee: proposal?.doubleFee,
          rateType: proposal?.rateType,
          tea: proposal?.tea,
          tcea: proposal?.tcea,
          limitMonths: proposal?.limitMonths,
          credit: proposal?.credit,
          currency: proposal?.currency || Config.CURRENCIES_SELECT[0],
          bankId: proposal?.bank?.id,
          active: proposal?.active,
        };
      },

      parseSubmit() {
        const { currency, ...data } = this.data;

        return {
          proposal: {
            ...data,
            currencyId: currency?.id,
            simpleFee: data.simpleFee || null,
            doubleFee: data.doubleFee || null,
            tea: data.tea || null,
            tcea: data.tcea || null,
            limitMonths: data.limitMonths || null,
            credit: data.credit || null,
          },
        };
      },
    }),
  ],

  computed: {
    internalHiddenFields() {
      return IS_MX_COUNTRY ? ['currencyId'] : [];
    },
  },

  components: { RateTypeSelect, CurrencySelect, BankSelect },
};
</script>

<style lang="sass" scoped></style>
