<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(v-if="strategies.length > 2" xs12 sm6).px-2
          strategy-select(
            v-model="data.generateStrategy"
            :strategies="strategies"
            label="Estrategia"
          )
        v-flex(v-if="fieldsKeys.includes('currencyId')" xs12 sm6).px-2
          currency-select(
            v-model="data.currency"
            return-object
          )
        v-flex(v-if="fieldsKeys.includes('totalIncoming')" xs12 sm6).px-2
          validation-provider(name="ingresos" :rules="rules.totalIncoming" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.totalIncoming"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Ingresos"
              name="ingresos"
              step="0.01"
              type="number"
            )
        v-flex(v-if="fieldsKeys.includes('propertyValue')" xs12 sm6).px-2
          validation-provider(name="valor del inmueble" :rules="rules.propertyValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.propertyValue"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Valor del inmueble"
              name="valor del inmueble"
              type="number"
            )
        v-flex(v-if="fieldsKeys.includes('creditAmount')" xs12 sm6).px-2
          validation-provider(name="crédito" :rules="rules.creditAmount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.creditAmount"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Crédito"
              name="crédito"
              step="0.01"
              type="number"
            )
        v-flex(v-if="fieldsKeys.includes('feeType')" xs12 sm6).px-2
          fee-type-select(v-model="data.feeType")
        v-flex(v-if="fieldsKeys.includes('propertyEndoser')" xs12 sm6).px-2
          v-switch(
            label="Endosar inmueble"
            v-model="data.propertyEndoser"
          )
        v-flex(v-if="fieldsKeys.includes('insuranceTypeId')" xs12 sm6).px-2
          insurance-type-select(v-model="data.insuranceTypeId")
        v-flex(v-if="fieldsKeys.includes('openAmount')" xs12 sm6).px-2
          validation-provider(name="inicial" :rules="rules.openAmount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.openAmount"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Inicial"
              name="inicial"
              step="0.01"
              type="number"
            )
        v-flex(v-if="fieldsKeys.includes('limitMonths')" xs12 sm6).px-2
          validation-provider(name="plazo" :rules="rules.limitMonths" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.limitMonths"
              label="Plazo"
              name="plazo"
              :error-messages="errors"
              type="number"
            )
        //-- componente guaranteeTypes 
        v-flex(xs12 sm6).px-2
            validation-provider(name="tipo de garantia" :rules="rules.guaranteeType" v-slot="{ errors }")
              guaranteeTypes-select(
                  v-model="data.guaranteeType.slug"
                  :error-messages="errors"
                  label="Tipo de Garantia"
                  name="tipo de garantia"
              )
      div(:class="b('custom-box')")
        div(:class="b('custom-box-title')") Propuesta personalizada (opcional)
        div(:class="b('custom-box-content')")
          v-layout(wrap)
            v-flex(v-if="showBankSelect" xs12 sm6).px-2
              validation-provider(name="banco" :rules="rules.customArguments.bankId" v-slot="{ errors }")
                bank-select(   
                  v-model="data.customArguments.bankId"
                  :itemState="showBankSelect"
                  :error-messages="errors"
                  label="Banco"
                  name="banco"
                  clearable
                )
            v-flex(xs12 sm6).px-2
              validation-provider(name="apertura" :rules="rules.customArguments.openAmount" v-slot="{ errors }")
                v-text-field(
                  v-model.number="data.customArguments.openAmount"
                  :error-messages="errors"
                  label="Costo de Apertura"
                  name="apertura"
                  type="number"
                  step="0.01"
                )
            v-flex(xs12 sm6).px-2
              validation-provider(name="tasa" :rules="rules.customArguments.rate" v-slot="{ errors }")
                v-text-field(
                  v-model.number="data.customArguments.rate"
                  :error-messages="errors"
                  label="Tasa"
                  name="tasa"
                  type="number"
                  step="0.01"
                )
            v-flex(xs12 sm6).px-2
              validation-provider(name="tipo de tasa" :rules="rules.customArguments.rateType" v-slot="{ errors }")
                rate-type-select(
                  v-model="data.customArguments.rateType"
                  :error-messages="errors"
                  label="Tipo de tasa"
                  name="tipo de tasa"
                )
            v-flex(v-if="fieldsKeys.includes('balloon')" xs12 sm6).px-2
              validation-provider(name="capital al final del periodo" :rules="rules.balloon" v-slot="{ errors }")
                v-text-field(
                  v-model.number="data.customArguments.balloon"
                  :error-messages="errors"
                  label="Capital al final del periodo"
                  name="capital al final del periodo"
                  type="number"
                  step="0.01"
                  prefix="%"
                )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Generar Propuestas
</template>

<script>
  import pick from 'lodash/pick';
  import { RATE_TYPE, FEE_TYPE, RTC_BANK_ID } from '@/utils/constants';

  import basicFormMixin from '@/components/forms/basic-form-mixin';
  import BankSelect from '@/components/inputs/bank-select';
  import RateTypeSelect from '@/components/inputs/credit-rate-type-select';
  import FeeTypeSelect from '@/components/inputs/fee-type-select';
  import CurrencySelect from '@/components/inputs/currency-select';
  import InsuranceTypeSelect from '@/components/inputs/insurance-type-select';
  import StrategySelect from '@/components/inputs/generate-strategy-select';
  import GuaranteeTypesSelect from '@/components/inputs/guaranteeTypes-select/guaranteeType-select';
  import { NEW_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';
  import Config from '@/config';

  export default {
    name: 'proposals-filters-form',

    data() {
      return {
        showBankSelect: this.shouldShowBankSelect(), // Llama a la función para determinar si se debe mostrar
        customArguments: {
          bankId: process.env.VUE_APP_RTC_BANK_ID || RTC_BANK_ID, // Inicializa con el ID del banco por defecto
        },
      };
    },

    props: {
      incomes: { type: Array },
      properties: { type: Array },
      strategies: { type: Array },
      maxMonths: { type: Number },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'proposal',
        veeValidation: true,
        mapPropsToData({
          proposal,
          incomes,
          properties,
        }) {
          const productSlug = proposal?.product?.slug;

          return {
            productSlug: proposal?.product?.slug,
            generateStrategy: 'custom',
            totalIncoming: proposal?.income || incomes?.[0]?.amount,
            propertyValue: properties?.[0]?.value,
            creditAmount: proposal?.credit || ((properties?.[0]?.value || 0) * 0.15),
            openAmount: proposal?.openAmount || ((properties?.[0]?.value || 0) * 0.15),
            guaranteeType: proposal?.guaranteeType || null,
            limitMonths: do{
              if(proposal?.limitMonths) proposal.limitMonths;
              else if(productSlug === NEW_MORTGAGE_SLUG) 300;
              else if(productSlug === HOME_EQUITY_LOAN_SLUG) 35;
              else properties?.[0]?.credit?.limitMonths || 0;
            },
            feeType: do{
              // Checkeamos que tenga simpleFee así sabemos de que no es una propuesta vacía
              if(proposal && proposal.simpleFee) proposal.doubleFee ? FEE_TYPE.DOUBLE : FEE_TYPE.SIMPLE;
              else if(productSlug === NEW_MORTGAGE_SLUG) FEE_TYPE.DOUBLE;
              else if(productSlug === HOME_EQUITY_LOAN_SLUG) FEE_TYPE.SIMPLE
              else properties?.[0]?.credit?.doubleFee ? FEE_TYPE.DOUBLE : FEE_TYPE.SIMPLE
            },
            rateType: proposal?.rateType || properties?.[0]?.credit?.rateType,
            insuranceTypeId: do {
              if(proposal?.insuranceType) proposal.insuranceType.id
              else if([NEW_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG].includes(productSlug)) '2';  // Individual
              else properties?.[0]?.credit?.insuranceType?.id;
            },
            currency: proposal?.currency || properties?.[0]?.currency || Config.CURRENCIES_SELECT[0],
            customArguments: {
              bankId: process.env.VUE_APP_RTC_BANK_ID || RTC_BANK_ID, // Asegúrate de que el valor por defecto esté aquí
              rate: null,
              rateType: RATE_TYPE.FIXED,
              balloon: null,
            },
            
          };
        },

        parseSubmit() {
          return {
            filters: {
              ...pick(this.data, this.fieldsKeys),
              currencyId: this.data.currency?.id,
              customArguments: this.hasCustomRate ? {
                ...this.data.customArguments,
                balloon: this.data.customArguments.balloon || undefined,
                rate: this.data.customArguments.rate || null,
              } : undefined,
              guaranteeType: this.data.guaranteeType ? this.data.guaranteeType.slug : null,
            }
          };
        },
      }),
    ],

    methods: {
      shouldShowBankSelect() {
        // Por defecto, oculta el select
        return process.env.VUE_APP_LOCALE === 'pe' ? false : true ;
      }
    },

    computed: {
      fieldsKeys() {
        return Config.PRODUCT_FILTER_FIELDS(this.proposal?.product?.slug) || [];
      },

      rules() { 
        return {
          ...Config.PROPOSAL_FILTERS_FORM_RULES({ data: this.data, maxMonths: this.maxMonths }),
          customArguments: {
            bankId: { required: this.hasCustomRate },
            //rate: { required: this.shouldShowBankSelect() === false ? false : Boolean(this.data.customArguments?.bankId), decimal: 2, min_value: 4, max_value: 40 },
            rate: { required: this.shouldShowBankSelect() === false ? false : false, decimal: 2, min_value: 4, max_value: 40 },
            rateType: { required: this.hasCustomRate },
            openAmount: { required: false },
          },
        };
      },

      hasCustomRate() {
        return Boolean(this.data.customArguments?.rate);
      },
    },

    watch: {
      properties() {
        this.mapPropsToData();
      },
    },

    components: {
      BankSelect,
      RateTypeSelect,
      CurrencySelect,
      InsuranceTypeSelect,
      FeeTypeSelect,
      StrategySelect,
      GuaranteeTypesSelect,
    },
  };
</script>

<style lang="sass" scoped>
  .proposals-filters-form
    &__custom-box
      background-color: lighten($cloudyColor, 6%)
      padding: 8px
      border-radius: 6px

      &-title
        font-weight: 500
        padding-left: 4px
</style>
