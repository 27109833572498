<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      ul
        address-row(
          v-for="(address, index) in addresses"
          :class="b('address')"
          :key="index"
          :address="address"
        )
</template>

<script>
  import AddressRow from './addresses-row';

  export default {
    name: 'addresses',

    props: {
      addresses: { type: Array, default: () => [] },
    },

    components: { AddressRow },
  };
</script>

<style lang="sass" scoped>
  .addresses
    &__container
      max-width: 600px
      margin: 0 auto
      padding-top: 8px
</style>