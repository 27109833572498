<template lang="pug">
  v-navigation-drawer(
    :value="value"
    @input="args => $emit('input', args)"
    width="300px"
    app clipped
  )
    v-layout.pa-4(v-if="currentUser")
      div
        user-avatar(
          :url="currentUser.avatar"
          :first-name="currentUser.firstName"
          :last-name="currentUser.lastName"
        ).mr-3
      v-flex
        .subheading.font-weight-bold {{ currentUser.firstName }} {{ currentUser.lastName }}
        div {{ currentUser.email }}
        .text-right
          v-tooltip(v-if="canLandingPage" top)
            template(v-slot:activator="{ on }")
              v-btn(
                v-on="on"
                color="primary"
                text small icon
                @click="args => $emit('click-edit-landing', args)"
              )
                v-icon mdi-monitor-edit
            span Editar página personal
          v-tooltip(v-if="canLandingPage" top)
            template(v-slot:activator="{ on }")
              v-btn(
                v-on="on"
                color="primary"
                text small icon
                @click="onClickInviteLink"
              ).ml-2
                v-icon mdi-link-variant
            span Copiar link de invitación
          v-btn(
            color="primary"
            title="Cambiar Contraseña"
            @click="$emit('click-change-password')"
            text small icon
          ).ml-2
            v-icon mdi-lock-reset
          v-btn(
            :to="{ name: 'customers.show', params: { customerId: currentUser.id }}" color="primary"
            title="Mi perfil"
            text small icon
          ).ml-2
            v-icon mdi-account
    v-divider
    v-list
      drawer-list-item(v-for="item in items" :key="item.title" :item="item")
    div(:class="b('version')") Version: #[span.font-weight-medium {{ version }}]
</template>

<script>
  import copy from 'copy-to-clipboard';
  import UserAvatar from '@/components/UI/user-avatar';
  import { APP_VERSION } from '@/utils/constants';
  import DrawerListItem from './drawer-list-item';

  export default {
    name: 'drawer',

    props: {
      value: { type: Boolean, default: false },
      items: { type: Array, default: () => [] },
      currentUser: { type: Object },
      canLandingPage: { type: Boolean, default: false },
    },

    data() {
      return {
        version: APP_VERSION,
      };
    },

    methods: {
      onClickInviteLink() {
        const url = this.$formURL(`?adviser=${this.currentUser.id}&utm_source=adviser_link`);

        copy(url);

        window.alert('El link ha sido copiado al portapapeles');
      },
    },

    components: { UserAvatar, DrawerListItem },
  };
</script>

<style lang="sass" scoped>
  .drawer
    &__version
      position: absolute
      background-color: darken(white, 6%)
      padding: 3px 12px
      bottom: 0
      right: 0
</style>