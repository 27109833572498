<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    filters-form(
      :proposal="proposal"
      :incomes="incomes"
      :properties="properties"
      :strategies="strategies"
      :max-months="maxMonths"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import FiltersForm from '@/components/forms/proposals-filters-form';

  export default {
    name: 'proposals-filters-form-modal',

    props: {
      incomes: { type: Array },
      properties: { type: Array },
      strategies: { type: Array },
      maxMonths: { type: Number },
    },

    mixins: [formModalMixin({ entityName: 'proposal' })],

    components: { FiltersForm },
  };
</script>
