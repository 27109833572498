import VSelect from 'vuetify/lib/components/VSelect';

const ITEMS = [
  { value: 10, text: '10 por página' },
  { value: 20, text: '20 por página' },
  { value: 50, text: '50 por página' },
  { value: 100, text: '100 por página' },
  { value: 150, text: '150 por página' },
  { value: 200, text: '200 por página' },
  { value: 250, text: '250 por página' },
];

export default {
  name: 'income-category-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: { type: Array, default: () => ITEMS },
  },
};

