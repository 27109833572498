<template lang="pug">
  v-dialog(
    :value="value"
    width="700"
    @input="args => $emit('input', args)"
  )
    v-card
      div(:class="b('header')")
        div(:class="b('header-title')") Enviar WhatsApp
        v-btn(:class="b('header-close')" icon @click="onClickClose")
          v-icon mdi-close
      v-divider
      div(:class="b('content')")
        loading-wrapper(v-if="$apollo.queries.whatsappTemplates.loading")
        whatsapp-templates(
          v-else
          ref="whatsappTemplates"
          :templates="whatsappTemplates"
          :requesting="isSending"
          @submit-send="onSubmitSend"
        )
</template>

<script>
import { WhatsappTemplatesQuery } from './graphql/queries.gql';
import { SendWhatsappMutation } from './graphql/mutations.gql';
import WhatsappTemplates from './components/whatsapp-templates';

import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

export default {
  name: 'whatsapp-send-modal',

  mixins: [
    mutationsHandlersMixin(),
  ],

  props: {
    value: { type: Boolean, default: false },
    customerId: { type: String },
  },

  apollo: {
    whatsappTemplates: {
      query: WhatsappTemplatesQuery,

      skip() {
        return this.skipQuery;
      }
    },
  },

  data() {
    return {
      whatsappTemplates: [],
      isSending: false,
    };
  },

  computed: {
    skipQuery() {
      return !this.customerId || !this.value;
    },
  },

  methods: {
    onSubmitSend({ template }) {
      this.handleUpdateMutation({
        mutation: SendWhatsappMutation,
        requestingStateKey: 'isSending',
        successMessage: 'El mensaje ha sido enviado exitosamente',

        variables: {
          data: {
            userId: this.customerId,
            templateId: template.id,
          },
        },
      }).then(() => {
        this.onClickClose();
      });
    },

    onClickClose() {
      this.$emit('input', false);

      this.$refs.whatsappTemplates?.clear();
    },
  },

  components: {
    WhatsappTemplates,
    LoadingWrapper,
  },
};
</script>

<style lang="sass" scoped>
  .whatsapp-send-modal
    background-color: white

    &__header
      display: flex
      align-items: center
      padding: 8px 8px

      &-title
        flex: 1
        font-size: 18px
        font-weight: bold
        text-align: center

    &__content
      padding: 16px
</style>