<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import debounce from 'lodash/debounce';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import basicStringSearch from '@/utils/basic-string-search';

  import { DealsSelectQuery } from './graphql/queries.gql';
  import DealCard from './components/deal-card';

  export default {
    name: 'deal-select',

    mixins: [lazySelectMixin({ itemsKey: 'deals' })],

    props: {
      additionalSearchParams: { type: Object, default: () => ({}) },
      itemValue: { type: String, default: 'id' },
    },

    data() {
      return {
        deals: [],
        search: null,
      };
    },

    apollo: {
      deals: {
        query: DealsSelectQuery,
        fetchPolicy: 'cache-and-network',
        manual: true,

        variables() {
          return {
            name: this.search,
            ...this.additionalSearchParams,
          };
        },

        result({ data }) {
          this.deals = data?.deals?.objects || [];
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      searchFn({ customer }, query) {
        return basicStringSearch([customer?.firstName, customer?.lastName, customer?.vat], query);
      },
    },

    render(h) {
      return h(
        VAutocomplete, {
          class: this.b(),

          props: {
            ...this.$attrs,
            ...this.$props,
            items: this.lazyItems,
            filter: this.searchFn,
            loading: this.$apollo.queries.deals.loading,
          },

          on: {
            ...this.$listeners,
            'update:search-input': this.onChangeSearch,
            focus: this.onFocus,
          },

          scopedSlots: {
            selection: data => h(DealCard, { props: { deal: data.item }}),
            item: data => h(DealCard, { props: { deal: data.item }}),
          }
        },
      );
    },
  };
</script>

<style lang="sass" scoped>
  .deal-select
    &__deals-list
      background-color: pink
</style>