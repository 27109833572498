<script>
import moment from 'moment';
import { formatCurrency } from '@/filters/accounting';
import { groupBy } from 'lodash/fp';
import { Bar } from 'vue-chartjs';

const PERIOD_TO_STRING = (month, year) => `${month}/${year}`

const HUMANIZE_PERIOD = period => {
  const [, month, year] = period.match(/(\d+)\/(\d{4})/);

  return month === '13' ? `Año (${year})` : moment(`${year}-${month}-01`).format('MMM/YYYY');
};

export default {
  name: 'declaration-report-chart',

  extends: Bar,

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {
    entriesByPeriod() {
      return groupBy((entry) => PERIOD_TO_STRING(entry.periodMonth, entry.periodYear))(this.entries);
    },

    labels() {
      return Object.keys(this.entriesByPeriod);
    },

    datasets() {
      return [{
        label: 'Declaraciones',
        pointBorderWidth: 3,
        pointBackgroundColor: 'rgb(0, 88, 122, 0.9)',
        borderColor: 'rgb(0, 88, 122, 0.7)',
        backgroundColor: 'rgb(0, 88, 122, 0.8)',
        data: this.labels.map(
          period => (this.entriesByPeriod?.[period] || []).reduce((acc, entry) => Number(entry.amount || 0) + acc, 0),
        ),
      }];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            ticks: {
              callback(value) {
                return HUMANIZE_PERIOD(value);
              },
            },
          }],
          yAxes: [
            {
              ticks: {
                min: 0,
                callback(value) {
                  return formatCurrency(value);
                },
              },
            },
          ]
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            title(entries) {
              const firstEntryPeriod = entries[0].label;

              return HUMANIZE_PERIOD(firstEntryPeriod);
            },

            label: ({ value, datasetIndex }) => {
              const title = this.datasets[datasetIndex].label;
              const result = formatCurrency(value);

              return [`${title}: ${result}`];
            },
          }
        }
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>