<template lang="pug">
  div(:class="b()")
    vehicular-credits-table(:credits="credits")
</template>

<script>
  import VehicularCreditsTable from './vehicular-credits-table';

  export default {
    name: 'vehicular-credits',

    props: {
      credits: { type: Array, default: () => [] },
    },

    components: { VehicularCreditsTable },
  };
</script>

<style lang="sass" scoped>

</style>