<template lang="pug">
  div(:class="b({ disabled: errorMessages.length > 0 })")
    product-icon(:slug="slug" :size="64")
    div(:class="b('body')")
      div(:class="b('title')") {{ title }}
      div(:class="b('subtitle')") {{ subtitle }}
      ul(v-if="errorMessages.length" :class="b('errors')")
        li(v-for="error in errorMessages" :key="error") {{ error }}
</template>

<script>
  import getProductMotive from '@/utils/get-product-motive';
  import ProductIcon from '@/components/UI/product-icon';

  export default {
    name: 'products-list-row',

    props: {
      slug: { type: String, required: true },
      motive: { type: String },
      errorMessages: { type: Array, default: () => [] },
    },

    computed: {
      title() {
        return getProductMotive(this.slug, this.motive);
      },

      subtitle() {
        return this.$t(`productSlug.${this.slug}`);
      },
    },

    components: { ProductIcon },
  };
</script>

<style lang="sass" scoped>
  .products-list-row
    display: flex
    align-items: center
    cursor: pointer
    padding: .25rem
    border-radius: 3px

    &__body
      flex: 1
      padding-left: 14px

    &__errors
      color: #ff7474
      font-size: 0.9em

    &__title
      font-size: 16px
      font-weight: 500

    &__subtitle
      font-size: 14px
      color: darken(white, 60%)

    &:hover
      background-color: darken(white, 3%)

    &--disabled
      cursor: not-allowed
</style>

