<script>
import moment from 'moment';
import { Line } from 'vue-chartjs';

export default {
  name: 'borrowers-aums-chart',

  extends: Line,

  props: {
    entries: { type: Object, required: true},
    parseValue: { type: Function, default: val => val },
    title: { type: String },
  },

  computed: {
    labels() {
      return (this.totals || [])
        .map(({ month, year }) => moment(`${year}-${month}`, 'YYYY-M').format('MMM/YYYY'));
    },

    totals() {
      return this.entries?.tables?.totals || [];
    },

    average() {
      const validTotals = this.totals.filter(({ value }) => Number(value) > 0);
      const amountSum = validTotals.reduce((acc, { value }) => acc + value, 0);

      return (amountSum / validTotals.length).toFixed(2);
    },

    datasets() {
      return [
        {
          label: this.title,
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(88,202,121)',
          borderColor: 'rgb(88,202,121)',
          backgroundColor: 'rgba(88,202,121, 0.3)',
          data: this.totals.map(({ value }) => value)
        },
        // {
        //   type: 'line',
        //   label: 'Promedio',
        //   pointRadius: 0,
        //   // pointBorderWidth: 0,
        //   pointBackgroundColor: 'rgb(21,151,187)',
        //   backgroundColor: 'rgba(88,202,121, 0)',
        //   borderColor: 'rgb(21,151,187)',
        //   data: this.totals.map(() => this.average),
        // },
      ]
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return this.parseValue(value);
                },
              },
            },
          ],
        },

        tooltips: {
          mode: 'label',

          callbacks: {
            label: ({ yLabel, datasetIndex }) => {
              return `${this.datasets[datasetIndex].label}: ${this.parseValue(yLabel)}`;
            }
          },
        },
      });
    });
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-aums-chart
</style>