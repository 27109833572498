import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { BusinessVerificationSelectQuery } from './queries.gql';

export default {
  name: 'business-verification-income-select',

  mixins: [lazySelectMixin({ itemsKey: 'businessVerification' })],

  props: {
    label: { type: String },
    errorMessages: { type: Array },
    value: { type: String, default: '' },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
    riskFileId: { type: String, required: '' },
    riskIncomeId: { type: String, required: '' },
  },

  data() {
    return {
      businessVerification: [],
    };
  },

  apollo: {
    businessVerification: {
      query: BusinessVerificationSelectQuery,

      variables() {
        return {
          riskIncomeId: this.riskIncomeId,
        };
      },

      result({ data }) {
        return data.businessVerification;
      },

      skip() {
        return !this.riskIncomeId || this.lazySkipQuery;
      },
    },
  },

  mounted() {
    this.$apollo.queries.businessVerification.start();
  },

  render(h) {
    return h(VSelect, {
      props: {
        ...this.$attrs,
        ...this.$props,
        items: this.lazyItems,
        loading: this.$apollo.queries.businessVerification.loading,
      },
      on: {
        ...this.$listeners,
        focus: this.onFocus,
      },
    });
  },
};
