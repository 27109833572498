<template lang="pug">
  div(:class="b()")
    div(:class="b('icon')")
      v-icon mdi-text-box-check
    div(:class="b('body')")
      div(:class="b('title')") {{ title }}
      div(:class="b('status')")
        v-icon(:class="b('status-icon')" :color="status.color") {{ status.icon }}
        span(:class="b('status-text')") {{ status.text }}
      div(v-if="!isValidated" :class="b('actions')")
        v-btn(color="secondary" @click="onClickRequestNip" :disabled="requesting") ENVIAR NIP
        v-btn(color="primary" @click="onClickValidateNip" :disabled="requesting") VALIDAR NIP
</template>

<script>
  export default {
    name: 'nip-validation-card',

    props: {
      customer: { type: Object, required: true },
      contextName: { type: String },
      requesting: { type: Boolean, default: false },
    },

    computed: {
      title() {
        const { firstName, lastName } = this.customer;

        return `${firstName} ${lastName} (${this.contextName})`;
      },

      isValidated() {
        return this.customer.nipValidated;
      },

      status() {
        return this.isValidated ? {
          text: 'NIP Validado',
          color: '#4caf50',
          icon: 'mdi-check-circle',
        } : {
          text: 'NIP sin validar',
          color: '#f44336',
          icon: 'mdi-alert-circle',
        };
      }
    },

    methods: {
      onClickRequestNip() {
        this.$emit('click-request-nip', { customer: this.customer });
      },

      onClickValidateNip() {
        this.$emit('click-validate-nip', { customer: this.customer });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .nip-validation-card
    display: flex
    padding: 12px 16px
    background-color: $cloudyColor
    // border: 3px solid lighten($primaryColor, 25%)
    // background-color: transparentize($primaryColor, 0.95)
    border-radius: 6px

    &__icon
      margin-right: 8px
      i
        font-size: 32px

    &__body
      flex: 1

    &__title
      font-size: 16px
      font-weight: 500

    &__status
      display: flex
      align-items: center
      &-icon
        margin-right: 4px

    &__actions
      margin-top: 6px
      display: flex
      justify-content: center

      & > *
        margin-left: 6px
        margin-right: 6px
</style>
