<template lang="pug">
  thead
    tr
      th Producto
      th Deals
      th % Total deals
      th Inversión
      th Costo por deal
</template>

<script>
  export default {
    name: 'costs-per-deal-table-head',
  };
</script>

<style lang="sass" scoped>

</style>