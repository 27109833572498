import { render, staticRenderFns } from "./incomes-form-step-column.vue?vue&type=template&id=0e4ba509&scoped=true&lang=pug"
import script from "./incomes-form-step-column.vue?vue&type=script&lang=js"
export * from "./incomes-form-step-column.vue?vue&type=script&lang=js"
import style0 from "./incomes-form-step-column.vue?vue&type=style&index=0&id=0e4ba509&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e4ba509",
  null
  
)

export default component.exports