<template lang="pug">
  metrics-layout
    filters(
      slot="filters"
      :filters="filters"
      @submit="onSubmitFilters"
    )
    div(:class="b()")
      loading-wrapper(v-if="$apollo.queries.metrics.loading || !metrics")
      template(v-else)
        loans-summary(:summary="metrics.data").mb-4

        v-card
          v-tabs(v-model="currentTab" fixed-tabs)
            v-tab AUM
            v-tab-item
              borrowers-aums-chart(
                :entries="borrowersAumsValues"
                :parse-value="parseCurrencyValue"
                title="AUM"
              ).mb-2
              div(:class="b('table-container')").mb-2
                borrowers-aums-table(
                  :entries="borrowersAumsValues"
                  :parse-value="parseCurrencyValue"
                )
</template>

<script>
import Config from '@/config';
import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { formatCurrency } from '@/filters/accounting';
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';
import { LoansMetricsGeneralQuery } from './graphql/queries.gql';

import MetricsLayout from '../../metrics/components/metrics-layout';
import LoansSummary from '../components/loans-summary';
import Filters from '../components/filters';
import BorrowersAumsTable from '../components/borrowers-aums-table';
import BorrowersAumsChart from '../components/borrowers-aums-chart';

export default {
  name: 'loans-metrics-general-view',

  data() {
    return {
      metrics: null,
      currentTab: null,

      filters: {
        country: Config.COUNTRY_CODE.toUpperCase(),
        status: [INVESTMENT_PROPOSAL_STATUS.COLLECTING, INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED],
        borrowerIds: [],
        investorIds: [],
      },
    };
  },

  apollo: {
    metrics: {
      query: LoansMetricsGeneralQuery,

      variables() {
        const { country, status, borrowerIds, investorIds } = this.filters;

        return {
          country,
          status: status?.length ? status : undefined,
          borrowerIds: borrowerIds?.length ? borrowerIds : undefined,
          investorIds: investorIds?.length ? investorIds : undefined,
        };
      },
    },
  },

  methods: {
    onSubmitFilters({ filters }) {
      if (isEqual({ ...filters }, { ...this.filters })) {
        this.$apollo.queries.metrics.refetch();
      }
      else
        Object.assign(this.filters, filters);
    },

    parseCurrencyValue(val) {
      return val ? formatCurrency(val) : val;
    },
  },

  computed: {

    borrowersAumsValues() {
      return this.metrics?.tables?.borrowersAumsValues;
    },
  },

  components: {
    LoansSummary,
    MetricsLayout,
    Filters,
    LoadingWrapper,
    BorrowersAumsTable,
    BorrowersAumsChart
  },
};
</script>

<style lang="sass" scoped>
  .loans-metrics-general-view
    &__table-container
      padding: 16px
      overflow-x: auto
</style>
