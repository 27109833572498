import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { DocumentsByCategoryTypeIdAndUserIdSelectQuery } from './queries.gql';
import { DOCUMENT_STATUS } from '@/utils/constants';

export default {
  name: 'documents-select',

  mixins: [lazySelectMixin({ itemsKey: 'documentsByCategoryTypeIdAndUserId' })],

  props: {
    label: { type: String },
    errorMessages: { type: Array },
    value: { type: Array, default: () => [] },
    returnObject: { type: Boolean, default: false },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'docType.description' },
    disabled: { type: Boolean },
    customerId: { type: String, required: true },
    status: { type: String, default: DOCUMENT_STATUS.APPROVED },
  },

  data() {
    return {
      documentsByCategoryTypeIdAndUserId: [],
    };
  },

  apollo: {
    documentsByCategoryTypeIdAndUserId: {
      query: DocumentsByCategoryTypeIdAndUserIdSelectQuery,
      fetchPolicy: 'no-cache',

      variables() {
        return {
          userId: this.customerId,
          status: this.status,
          documentCategoryTypeId: 2,
        };
      },

      result({ data }) {
        const dataResult = data.documentsByCategoryTypeIdAndUserId.map(
          document => {
            return {
              id: document?.docType?.id,
              description: document?.docType?.description,
            };
          },
        );
        return dataResult;
      },

      skip() {
        return !this.customerId || this.lazySkipQuery;
      },
    },
  },

  mounted() {
    this.$apollo.queries.documentsByCategoryTypeIdAndUserId.start();
  },

  render(h) {
    return h(VSelect, {
      props: {
        ...this.$attrs,
        ...this.$props,
        multiple: true,
        items: this.lazyItems,
        loading: this.$apollo.queries.documentsByCategoryTypeIdAndUserId.loading,
      },
      on: {
        ...this.$listeners,
        focus: this.onFocus,
      },
    });
  },
};
