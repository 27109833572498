<template lang="pug">
  div(:class="b()" v-if="firstProposal")
    v-layout(wrap)
      v-flex(xs12 sm6 xl4)
        b Crédito:&nbsp;
        span {{ firstProposal.credit | formatCurrency(firstProposal.currency) }}
      v-flex(xs12 sm6 xl4)
        b Plazo:&nbsp;
        span {{ firstProposal.limitMonths }} meses&nbsp;
        saving-text(
          v-if="firstProposal.savings && firstProposal.savings.limitMonths"
          :amount="firstProposal.savings.limitMonths"
          :render="amount => `${amount} meses`"
        )
      v-flex(xs12 sm6 xl4)
        b Seguro:&nbsp;
        span {{ $t(`insuranceTypeId.${firstProposal.insuranceType.id}`) }}
      v-flex(v-if="firstProposal.openAmount" xs12 sm6 xl4)
        b Inicial:&nbsp;
        span {{ firstProposal.openAmount | formatCurrency(firstProposal.currency) }}
      v-flex(v-if="!hiddenFields.includes('propertyValue') && firstProposal.propertyValue" xs12 sm6 xl4)
        b Valor Propiedad:&nbsp;
        span {{ firstProposal.propertyValue | formatCurrency(firstProposal.currency) }}
      v-flex(xs12 sm6 xl4)
        b Motivo:&nbsp;
        span {{ humanizedMotive }}
      v-flex(xs12 sm6 xl4)
        b Tipo de garantía: &nbsp;
        span {{ firstProposal?.guaranteeType?.name || '-' }}
      v-flex(xs12 sm6 xl4)
        b RTC Score: &nbsp;
        span {{ firstProposal?.rtcScore || '-' }}
</template>

<script>
import SavingText from '@/components/UI/saving-text';
import getProductMotive from '@/utils/get-product-motive';
import { HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'deal-summary',

  props: {
    deal: { type: Object },
  },

  computed: {
    humanizedMotive() {
      return getProductMotive(this.deal?.product?.slug, this.deal?.motive);
    },

    firstProposal() {
      return this.deal?.proposals?.[0];
    },

    hiddenFields() {
      if (IS_PE_COUNTRY && this.deal?.product?.slug === HOME_EQUITY_LOAN_SLUG)
        return ['propertyValue'];

      return [];
    },
  },

  components: { SavingText },
};
</script>

<style lang="sass" scoped>
.deal-summary
  // padding: 8px 0 !important
  background-color: darken(white, 4%)
  padding: .5rem 2rem
  margin-bottom: 12px
  line-height: 1.3
</style>
