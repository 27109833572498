<template lang="pug">
  div(:class="b()")
    v-alert(type="info" v-if="creating") El expediente de riesgos se está creando, por favor espere...
    v-alert(type="error" v-else-if="createError")
      b Error al crear el expediente:&nbsp;
      span {{ createError.message }}
    template(v-else-if="riskFile")
      div(v-if="opinion" :class="b('opinion')")
        div(:class="b('opinion-title')") Último dictamen de riesgos
        .d-flex.flex-wrap.justify-end.pt-2
          v-btn(v-if="canNotify" color="primary" @click="onClickNotify" :loading="isNotificating").mx-1.mb-2
            v-icon.mr-1 mdi-bell-ring
            span Notificar
          v-btn(v-if="canVisit && !isFailedStatus" :to="riskFileLink" color="primary" outlined).mx-1.mb-2
            v-icon.mr-1 mdi-share
            span Ir al expediente de riesgos
        .d-flex.align-center
          v-chip(:color="statusColor" text-color="white").mr-2 {{ $t(`riskOpinionStatus.${opinion.status}`) }}
          span {{ opinion.createdAt | formatDate('D MMMM YYYY - hh:mm a') }}
        .d-flex.align-center
          b Primera cuota: {{ firstPaymentDate | formatDate('DD MMMM YYYY')}}
        .d-flex.align-center
          b.pr-1 Analista:
          p(style="vertical-align: center").mb-0 {{ riskFile.lastOpinion.user.firstName }} {{ riskFile.lastOpinion.user.lastName }}
        div(:class="b('opinion-description')" v-html="opinion.description")
        div(v-if="opinion.refusedReason" :class="b('opinion-refused-reason')")
          span.font-weight-medium Motivo de rechazo:
          span &nbsp;{{ opinion.refusedReason.reason }}
        div(:class="b('opinion-data')")
          div(v-if="opinion.netIncome").mr-2
            b Ingresos Netos:&nbsp;
            span {{ opinion.netIncome | formatCurrency(currency) }}
          div(v-if="opinion.monthlyPayment").mr-2
            b Pago Mensual:&nbsp;
            span {{ opinion.monthlyPayment | formatCurrency(currency) }}
          div(v-if="opinion.simpleFeeMax").mr-2
            b Cuota Max:&nbsp;
            span {{ opinion.simpleFeeMax | formatCurrency(currency) }}
          div(v-if="opinion.rciMax").mr-2
            b RCI Max:&nbsp;
            span {{ opinion.rciMax }} %
          div(v-if="opinion.ltvMax").mr-2
            b LTV Max:&nbsp;
            span {{ opinion.ltvMax }} %
      v-alert(v-else type="info").text-center
        div El expediente se encuentra en riesgos esperando por un dictamen.
        v-btn(v-if="canVisit" :to="riskFileLink" color="white" outlined).mt-2
          v-icon.mr-1 mdi-share
          span Ir al expediente de riesgos
    loading-wrapper(v-else-if="fetching")
    div(v-if="!fetching && !riskFileIsActive && !creating" :class="b('buttons')").mt-2
      risk-file-validations-state(v-if="validations.length" :validations="validations")
      .d-flex.justify-center
        v-btn(
          color="primary"
          :disabled="hasErrors || disabled"
          @click="onClickCreateRiskFile"
        ) Enviar expediente a riesgos
</template>

<script>
import { RISK_OPINION_STATUS } from '@/utils/constants';
import RiskFileValidationsState from '@/components/UI/risk-file-validations-state';
import getStatusColor from '@/utils/get-risk-opinion-status-color';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

export default {
  name: 'risk-file-actions',

  props: {
    creating: { type: Boolean, default: false },
    fetching: { type: Boolean, default: false },
    isNotificating: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    riskFile: { type: Object },
    validations: { type: Array, default: () => ([]) },
    canVisit: { type: Boolean, default: false },
    canNotify: { type: Boolean, default: false },
    currency: { type: Object },
  },

  methods: {
    onClickCreateRiskFile() {
      this.$emit('click-create-risk-file');
    },

    onClickNotify() {
      if (window.confirm('¿Está seguro de que desea notificar el dictamen?'))
        this.$emit('click-notify', { riskFile: this.riskFile });
    },

  },

  computed: {
    riskFileIsActive() {
      return this.riskFile && ![RISK_OPINION_STATUS.REFUSED, RISK_OPINION_STATUS.OBSERVED, RISK_OPINION_STATUS.FAILED].includes(this.riskFile?.status);
    },

    createError() {
      return this.riskFile?.errorMessage ? new Error(this.riskFile.errorMessage) : null;
    },

    opinion() {
      return this.riskFile.lastOpinion;
    },
    firstPaymentDate() {
      return JSON.parse(this.riskFile.riskAssessmentRequest).riskFile.firstPaymentDate;
    },
    statusColor() {
      return getStatusColor(this.opinion?.status);
    },

    hasErrors() {
      return this.validations.some(({ valid }) => valid === false);
    },

    riskFileLink() {
      return {
        name: 'risk_files.show',
        params: {
          riskFileId: this.riskFile?.id,
        },
      };
    },

    isFailedStatus() {
      return this.riskFile?.status === RISK_OPINION_STATUS.FAILED;
    },
  },

  components: { RiskFileValidationsState, LoadingWrapper },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .risk-file-actions
    &__opinion
      background-color: darken(white, 3%)
      border-radius: 6px
      padding: 8px 16px

      &-title
        text-align: center
        font-size: 17px
        font-weight: 500
        color: darken(white, 50%)

      &-description
        margin-top: 8px

    +sm
      &__opinion
        &-data
          display: flex
</style>
