export default {
  name: 'loading-wrapper',

  props: {
    loading: { type: Boolean, default: true },
    error: { type: Error },
  },

  render(h) {
    if(this.error)
      return h('fetch-error-alert', {
        props: { error: this.error },
        on: {
          retry: () => this.$emit('retry'),
        },
      });
    else if(this.loading)
      return h('div', {
          class: 'loading-container',
        }, [h('loading-spinner')
      ]);
  }
};