<template lang="pug">
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      v-icon.pl-2( @click.stop="e => on.click(e)").hidden-sm-and-up mdi-file-document-edit
      v-btn(text color="primary" small @click.stop="e => on.click(e)").hidden-xs-only File Comercial
    v-list(:class="b('menu')" dense @click.stop)
      v-list-item(:disabled="!fileUrl" :href="fileUrl" target="_blank")
        v-list-item-title Visualizar
      v-list-item(@click="$emit('generate')")
        v-list-item-title {{ generatetext }}
</template>

<script>
  export default {
    name: 'commercial-file-button',

    props: {
      fileUrl: { type: String },
    },

    computed: {
      generatetext() {
        return this.fileUrl ? 'Regenerar' : 'Generar';
      },
    },
  };
</script>

<style lang="sass">
  .commercial-file-button
    &__menu
      .v-icon
        margin-right: 1rem
</style>