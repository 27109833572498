import Vue from 'vue';
import Vuei18n from 'vue-i18n';
import Config from '@/config';
import * as locales from './locales/*';

Vue.use(Vuei18n);

const fileNameToLangName = name => name
  .replace('_', '-')
  .replace(/Js$/, '');

const messages = Object.entries(locales)
  .reduce(
    (obj, [file, messages]) => ({
      ...obj,
      [fileNameToLangName(file)]: messages,
    }),
    {},
  );

export default new Vuei18n({
  locale: Config.LOCALE,
  fallbackLocale: 'es-PE',
  messages,
});

