<template lang="pug">
  div(:class="b()")
    .create-customer-modal__step-title Información de la Propiedad

    base-property-form(
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      @submit="args => $emit('submit', args)"
    )
      template(slot="actions")
        v-spacer
        v-btn(
          :disabled="requesting"
          color="error"
          type="button"
          text
          @click="$emit('cancel')"
        ).px-4 Atras
        v-btn(
          :loading="requesting"
          color="primary"
          type="submit"
        ).px-4 Siguiente
</template>

<script>
import BasePropertyForm from '@/components/forms/property-form';
import { IS_MX_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'property-form',

  props: {
    requesting: { type: Boolean, default: false },
  },

  computed: {
    hiddenFields() {
      return [
        'auctionValue',
        'curencyId',
        'valuationDate',
        'realOwnerId',
        'typeId',
        'yearOfConstruction',
        'totalArea',
        'address.zipCode',
      ].concat(IS_MX_COUNTRY ? ['address.line1', 'address.location'] : []);
    },
  },

  components: { BasePropertyForm },
};
</script>

<style lang="sass" scoped></style>
