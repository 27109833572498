<template lang="pug">
  tbody
    tr(
      v-for="row, idx in rows"
      :key="idx"
    )
      td {{ row.source }}
      td {{ row.medium }}
      td {{ row.campaign }}
      td {{ row.deals }}
      td {{ row.pctTotalDeals }}
      td {{ row.users }}
      td {{ row.cnvUserToDeal }}
      td {{ row.investment }}
      td {{ row.costPerDeal }}
</template>

<script>
  export default {
    name: 'table-by-source-body',

    props: {
      rows: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>