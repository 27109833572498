<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :persistent="persistent")
    appraisal-form(
      :appraisal="appraisal"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import AppraisalForm from '@/components/forms/appraisal-form';

  export default {
    name: 'appraisal-form-modal',

    mixins: [formModalMixin({ entityName: 'appraisal' })],

    components: { AppraisalForm },
  };
</script>
