<template lang="pug">
  router-link(
    :class="b()"
    :to="{ name: 'deals.show', params: { dealId: activeProduct.dealId }}"
    title="ir a negociación en CRM"
  )
    div.pr-1.hidden-xs-only
      product-icon(:slug="activeProduct.product.slug" :size="42").mr-2
    v-flex.d-flex(style="flex-direction: column; justify-content: center")
      div(:class="b('title')") {{ $t(`productSlug.${activeProduct.product.slug}`)}}
      div
        .d-inline-block(v-if="activeProduct.credit && activeProduct.currency").mr-2
          | #[span.font-weight-medium Crédito:] {{ activeProduct.credit | formatCurrency(activeProduct.currency) }}
        .d-inline-block(v-if="activeProduct.product.slug === 'buy_mortgage'" xs6 md4)
          | #[span.font-weight-medium Inscrita:] {{ activeProduct.inscribed ? 'Si' : 'No' }}
</template>

<script>
  import ProductIcon from '@/components/UI/product-icon';

  export default {
    name: 'active-products-row',

    props: {
      activeProduct: {
        type: Object,
        required: true,
      },
    },

    components: { ProductIcon },
  };
</script>

<style lang="sass" scoped>
  .active-products-row
    display: flex
    align-items: center

    padding: .5rem 0rem

    font-size: .9rem
    text-decoration: none

    color: inherit

    &:hover
      background-color: darken(white, 3%)

    &__title
      font-size: 1rem
      font-weight: 500
</style>
