<template lang="pug">
  v-select(
    :value="value"
    :name="name"
    :disabled="disabled"
    :items="items"
    :label="label"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :item-disabled="itemDisabled"
    @input="args => $emit('input', args)"
  )
</template>

<script>
export default {
  name: 'year-select',

  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String },
    name: { type: String },
    value: { type: Number },
    errorMessages: { type: Array, default: () => [] },
    from: { type: Number, default: () => new Date().getFullYear() - 60 },
    until: { type: Number, default: () => new Date().getFullYear() },
    hideDetails: { type: Boolean, default: false },
    itemDisabled: { type: Function },
  },

  computed: {
    items() {
      const years = this.until - this.from;

      return Array.from({ length: years + 1 })
        .map((_, index) => ({
          value: this.from + index,
          text: this.from + index,
        }));
    },
  },

};
</script>

<style lang="sass" scoped>

</style>