<template lang="pug">
  div(:class="b()" :style="{ backgroundColor: color }") {{ $t(`trustBondStatus.${status}`)}}
</template>

<script>
  import statusColor from '@/utils/investment-proposal-status-color';

  export default {
    name: 'bond-status-tag',

    props: {
      status: { type: String, required: true },
    },

    computed: {
      color() {
        return statusColor(this.status);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .bond-status-tag
    display: flex
    align-items: center
    justify-content: center
    padding: 2px 12px
    border-radius: 4px
    text-transform: uppercase
    font-size: 9px
    font-weight: 500
    color: white

    +md
      padding: 2px 12px
      font-size: 11px
</style>