<template lang="pug">
  div(:class="b({ disabled })")
    div(:class="b('header')")
      div(:class="b('number')") {{ number }}
      div(:class="b('title')") {{ title }}
    div(:class="b('content')")
      slot
</template>

<script>
  export default {
    name: 'step',

    props: {
      title: { type: String },
      number: { type: Number },
      disabled: { type: Boolean, default: false },
    },
  };
</script>

<style lang="sass" scoped>
  .step
    $c: &

    &__header
      display: flex
      align-items: center
      padding-bottom: 16px

    &__number
      display: flex
      align-items: center
      justify-content: center
      width: 28px
      height: 28px
      margin-right: 12px

      color: white
      background-color: $primaryColor
      border-radius: 50%

      font-size: 16px

    &__title
      font-size: 16px
      font-weight: 500

    &--disabled
      #{$c}
        &__number
          background-color: darken(white, 6%)
</style>