<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      div(:class="b('type')")
        tax-report-essalud-reg(:years="yearlyIncomeByRegRows")
      div(:class="b('charts')")
        div(:class="b('chart')")
          .tax-report__title Ventas
          tax-report-essalud-chart(
            :entries="essalud"
            data-key="sales"
            title="Ventas"
          )
        div(:class="b('chart')")
          .tax-report__title Ingresos netos
          tax-report-essalud-chart(
            :entries="essalud"
            data-key="netIncomes"
            title="Ingresos Netos"
          )
</template>

<script>
import TaxReportEssaludChart from './tax-report-essalud-chart';
import TaxReportEssaludReg from './tax-report-essalud-reg';

export default {
  name: 'tax-report-essalud',

  props: {
    essalud: { type: Array, default: () => [] },
    yearlyIncomeByRegRows: { type: Array, default: () => [] },
  },

  components: { TaxReportEssaludChart, TaxReportEssaludReg },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .tax-report-essalud
    &__charts
      padding-left: 18px
      flex: 1

    +md
      &__container
        display: flex

      &__type
        padding-top: 80px
        flex-shrink: 0

</style>