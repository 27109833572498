<template lang="pug">
  metrics-layout
    filters(
      :filters="filters"
      :requesting="$apollo.queries.generalTable.loading"
      @submit="onSubmitFilters"
      slot="filters"
    )
    div
      loading-wrapper(v-if="$apollo.queries.generalTable.loading")
      .pa-2(v-else)
        general-table(:items="generalTable")
      loading-wrapper(v-if="$apollo.queries.generalGraphics.loading")
      v-layout(v-else wrap)
        v-flex(
          v-for="graphic in generalGraphics"
          :key="graphic.name"
          xs12 md6 lg4
        ).pa-2
          chart-card(
            :title="graphic.name"
            :data="graphic.data"
          )
</template>

<script>
  import isEqual from 'lodash/isEqual';
  import moment from 'moment';

  import Config from '@/config';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import { RTC_BANK_ID } from '@/utils/constants';

  import MetricsLayout from '../components/metrics-layout';
  import Filters from './components/filters';
  import GeneralTable from './components/general-table';
  import ChartCard from './components/chart-card';
  import {
    GeneralTableQuery,
    GeneralGraphicQuery,
  } from './graphql/queries.gql';

  export default {
    name: 'metrics-general-view',

    data() {
      return {
        filters: {
          country: Config.COUNTRY_CODE.toUpperCase(),
          bankId: undefined,
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          product: undefined,
          source: undefined,
          medium: undefined,
          adviserEmail: undefined,
          campaign: undefined,
          groupBy: 'DAY',
          onlyRtc: false,
          dealStatus: undefined,
          dealMotive: undefined,
        },
        generalTable: [],
        generalGraphics: [],
      };
    },

    apollo: {
      generalTable: {
        query: GeneralTableQuery,
        fetchPolicy: 'network-only',

        variables() {
          return {
            ...this.filters,
            soloBankId: this.filters.onlyRtc ? RTC_BANK_ID : undefined,
          };
        },
      },

      generalGraphics: {
        query: GeneralGraphicQuery,
        fetchPolicy: 'network-only',

        variables() {
          return {
            ...this.filters,
            soloBankId: this.filters.onlyRtc ? RTC_BANK_ID : undefined,
          };
        },
      },
    },

    components: {
      Filters,
      MetricsLayout,
      GeneralTable,
      ChartCard,
      LoadingWrapper
    },

    methods: {
      onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.generalTable.refetch();
          this.$apollo.queries.generalGraphics.refetch();
        }
        else
          Object.assign(this.filters, filters);
      },
    },
  };
</script>