<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="400")
    password-form(
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import FormModal from './base-form-modal';
  import PasswordForm from '@/components/forms/password-form';

  export default {
    name: 'password-form-modal',

    props: {
      value: { type: Boolean },
      requesting: { type: Boolean },
    },

    components: { FormModal, PasswordForm },
  };
</script>
