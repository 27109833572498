import VSelect from 'vuetify/lib/components/VSelect';
import { MORTGAGE_NUMBER_STATUS } from '@/utils/constants';

export default {
  name: 'mortgage-number-status-select',

  functional: true,

  render(h, context) {
    const items = Object.values(MORTGAGE_NUMBER_STATUS).map(status => ({
        value: status,
        text: context.parent.$t(`mortgageNumberStatus.${status}`),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
