import Config from '@/config';
import { IS_PE_COUNTRY, IS_MX_COUNTRY } from '@/utils/country-checker';
import Vue from 'vue';

const VueProjectConfig = {
  install(Vue) {
    Vue.prototype.$projectConfig = Config;
    Vue.prototype.$projectConfig.isPECountry = IS_PE_COUNTRY;
    Vue.prototype.$projectConfig.isMXCountry = IS_MX_COUNTRY;
  },
};

Vue.use(VueProjectConfig);
