<template lang="pug">
  titled-card(
    :title="title"
    :count="incomes.length"
    icon="mdi-currency-usd"
  )
    div(slot="actions")
      v-btn(@click.native="onClickAdd" text small color="primary").ma-0.px-1
        span Agregar
        v-icon mdi-plus
    incomes-list(
      :incomes="incomes"
      @edit="({ income }) => onClickEdit(income)"
      @delete="({ income }) => onClickDelete(income)"
    )
    v-alert(
      v-if="!incomes.length"
      :value="true"
      type="info"
      outlined
    ) No hay ingresos registrados
    form-modal(
      v-if="formModal"
      v-model="formModal"
      :income="editingEntity"
      :requesting="requesting"
      @submit="onSubmit"
    )
</template>

<script>
  import omit from 'lodash/omit';
  import compact from 'lodash/compact';
  import gestionableList from '@/mixins/gestionable-list-mixin';
  import FormModal from '@/components/modals/income-form-modal';

  import IncomesList from './incomes-list';
  import TitledCard from '@/components/UI/titled-card';

  export default {
    name: 'incomes',

    mixins: [
      gestionableList({
        entityName: 'income',
      }),
    ],

    props: {
      customer: { type: Object, required: true },
      requesting: { type: Boolean, default: false },
      isCouple: { type: Boolean, default: false },
    },

    components: { TitledCard, IncomesList, FormModal },

    computed: {
      incomes() {
        return this.customer.incomes || [];
      },

      title() {
        return compact([
          'Ingresos de',
          this.customer.firstName,
          this.customer.lastName,
          this.isCouple && '(pareja)',
        ]).join(' ');
      },
    },

    methods: {
      onSubmit({ income }) {
        if(income.id) this.onUpdate(income)
        else this.onCreate(income);
      },

      onUpdate(income) {
        this.$emit('update', {
          income,
          isCouple: this.isCouple
        });
      },

      onCreate(income) {
        this.$emit('create', {
          income: omit(income, 'id'),
          customerId: this.customer.id,
          isCouple: this.isCouple,
        });
      },

      onDelete(income) {
        this.$emit('delete', {
          income,
          isCouple: this.isCouple,
        });
      },
    },

  };
</script>
