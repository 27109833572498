<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    credit-form(
      :credit="credit"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import CreditForm from '@/components/forms/credit-form';

  export default {
    name: 'credit-form-modal',

    mixins: [formModalMixin({ entityName: 'credit' })],

    components: { CreditForm },
  };
</script>
