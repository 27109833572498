import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { DealLostReasonsSelectQuery } from './queries.gql';

export default {
  name: 'deal-lost-reason-select',

  mixins: [lazySelectMixin({ itemsKey: 'lostReasons' })],

  props: {
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'reason' },
  },

  data() {
    return {
      lostReasons: [],
    };
  },

  apollo: {
    lostReasons: {
      query: DealLostReasonsSelectQuery,

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.lostReasons.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};
