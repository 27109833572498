<template lang="pug">
  tag(:color="color") {{ $t(`formalizationStatus.${status}`) }}
</template>

<script>
  import { FORMALIZATION_STATUS } from '@/utils/constants';
  import Tag from '@/components/UI/tag';

  const COLORS = {
    [FORMALIZATION_STATUS.CLOSED]: '#6c5ce7',
    [FORMALIZATION_STATUS.ACTIVE]: '#4caf50',
    [FORMALIZATION_STATUS.LOST]: '#d63031',
  };

  export default {
    name: 'formalization-status-tag',

    props: {
      status: { type: String, required: true },
    },

    computed: {
      color() {
        return COLORS[this.status];
      },
    },

    components: { Tag },
  };
</script>
