<template lang="pug">
  div(:class="b()")
    div(:class="b('form-container')")
      message-form(
        ref="messageForm"
        :requesting="requesting"
        :current-user="currentUser"
        :mention-groups-ids="mentionGroupsIds"
        @submit="args => $emit('create', args)"
      ).pb-2
        template(slot="actions")
          slot(name="form-actions")
    div(:class="b('list-container')")
      message(
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :deletable="canDeleteMessage(message)"
        @delete="args => $emit('delete', args)"
      )
</template>

<script>
  import Message from './messages-item';
  import MessageForm from './message-form';

  export default {
    name: 'messages',

    props: {
      requesting: { type: Boolean, default: false },
      currentUser: { type: Object, required: true },
      mentionGroupsIds: { type: Array },
      messages: { type: Array, default: () => [] },
    },

    methods: {
      clearForm() {
        this.$refs.messageForm.clearForm();
      },

      canDeleteMessage(message) {
        return this.currentUser.isSuperuser || message?.author?.id === this.currentUser.id;
      },
    },

    components: { Message, MessageForm },
  };
</script>

<style lang="sass" scoped>
  .messages
    display: flex
    flex-direction: column
    height: 100%

    &__form-container
      flex-shrink: 0

    &__list-container
      flex: 1
      overflow-y: auto
</style>