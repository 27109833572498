<template lang="pug">
  div
    v-container(fluid)
      v-layout(wrap)
        v-flex(
          v-for="customer in customers"
          :key="customer.id"
          xs12
          md6
          xl4
        )
          customer-card(
            :customer="customer"
            @edit="onSubmit"
            @delete="({ customer }) => onDelete(customer)"
          )
    form-modal(
      v-model="formModal"
      :customer="editingEntity"
    )
</template>

<script>
  import CustomerCard from '@/components/cards/customer-card';
  import FormModal from '@/components/modals/customer-form-modal';
  import gestionableList from '@/mixins/gestionable-list-mixin';

  export default {
    name: 'customers-list',

    mixins: [
      gestionableList({
        entityName: 'customer',
        deleteConfirmText: customer => `¿Está seguro que desea eliminar a ${customer.firstName} ${customer.lastName}`,
      }),
    ],

    props: {
      customers: { type: Array, required: true },
    },

    components: {
      CustomerCard,
      FormModal,
    },
  };
</script>

<style lang="sass" scoped>

</style>