<template lang="pug">
  div(:class="b()")
    div(:class="b('filters-card')" ref="toolbar")
      filters(
        :filters="filters"
        :requesting="$apollo.queries.quotes.loading"
        @submit="onSubmitFilters"
      )
    v-container(fluid :class="b('body')")
      loading-wrapper(
        v-if="$apollo.queries.quotes.loading || quotesError"
        :error="quotesError"
        @retry="onRetryQuery"
      )
      template(v-else-if="quotes && quotes.objects && quotes.objects.length")
        div(:class="b('controls')")
          .d-flex.align-center.mr-2
            b Entradas:&nbsp;
            per-page-select(
              v-model="perPage"
              :class="b('per-page-select')"
              hide-details
            ).ml-2.mb-1
          .spacer
          v-btn(
            v-if="selectedIds.length"
            :loading="isDownloadingCsv"
            color="primary"
            @click="onClickDownloadTrustBondsFeeCsv"
          ).mr-3.mb-1
            v-icon mdi-download
            span Descargar Cuotas
          div #[b.mr-1 Resultados: ] {{ quotes.count }} Cuotas
        div(:class="b('table-container')")
          quotes-table(
            :quotes="quotes.objects"
            :order-by="filters.orderBy"
            :order-flow="filters.orderFlow"
            :selected-ids.sync="selectedIds"
            @order="onOrder"
          )
      v-alert(v-else type="info" outlined) No se han encontrado cuotas con los filtros indicados
      .text-center
        v-pagination(
          v-if="quotes && quotes.pages > 1"
          v-model="currentPage"
          :length="quotes.pages"
          :total-visible="15"
        )
</template>

<script>
import { FEE_STATUS } from '@/utils/constants';
import quotesViewMixin from '@/views/investment/quotes-view-mixin';
import authStorage from '@/utils/auth-storage';

import Filters from './components/filters';
import QuotesTable from './components/quotes-table';
import { BondsQuotesListQuery } from './graphql/queries.gql';
import { ExportTrustBondsFeeCsvMutation } from './graphql/export-trust-bonds-fee.gql';

export default {
  name: 'bonds-quotes-list-view',

  data() {
    return {
      selectedIds: [],
      isDownloadingCsv: false,
    };
  },

  mixins: [
    quotesViewMixin({
      query: BondsQuotesListQuery,

      filtersDefaultValues: {
        investorIds: [],
        investmentVehicleIds: [],
        expirationDate: null,
        paymentDate: null,
        orderBy: "original_data__expiration_date",
        orderFlow: 'ASC',
        status: [FEE_STATUS.PENDING, FEE_STATUS.EXPIRED],
      }
    }),
  ],

  apollo: {
    quotes: {
      query: BondsQuotesListQuery,
      fetchPolicy: 'cache-and-network',

      update({ quotes }) {
        return quotes;
      },

      variables() {
        const {
          investorIds,
          investmentVehicleIds,
          expirationDate,
          paymentDate,
          status,
          orderFlow,
          orderBy
        } = this.filters || {};

        return {
          page: this.currentPage,
          perPage: this.perPage,
          investorIds: investorIds?.length ? investorIds : undefined,
          investmentVehicleIds: investmentVehicleIds?.length ? investmentVehicleIds : undefined,
          expirationDateSince: expirationDate?.[0],
          expirationDateUntil: expirationDate?.[1] || expirationDate?.[0],
          paidDateSince: paymentDate?.[0],
          paidDateUntil: paymentDate?.[1] || paymentDate?.[0],
          status,
          orderFlow,
          orderBy,
        };
      },

      error() {
        this.quotesError = new Error('Ha ocurrido un error al obtener los datos');
      },
    },
  },

  methods: {
    onClickDownloadTrustBondsFeeCsv() {
      this.isDownloadingCsv = true;
      const { token } = authStorage.getCredentials();

      fetch(process.env.VUE_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${token}`
        },
        body: JSON.stringify({ 
          query: ExportTrustBondsFeeCsvMutation.loc.source.body, 
          variables: { trustBondsIds: this.selectedIds } 
        })
      })
      .then(response => response.json())
      .then(data => {
          const csvContent = data.data.cvs.contents;
          const blob = new Blob([csvContent], { type: data.data.cvs.headers[0].value });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = data.data.cvs.headers[1].value.match(/filename="([^"]+)"/)[1];
          
          document.body.appendChild(link);
          link.click();
          
          document.body.removeChild(link);
      })
      .catch(error => new Error(`Ha ocurrido un error al descargar el archivo: ${error}`))
      .finally(() => {
        this.isDownloadingCsv = false;
      });
    },
  },

  components: { QuotesTable, Filters },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .bonds-quotes-list-view
    &__table-container
      overflow-x: auto

    &__controls
      padding-bottom: 12px

      +sm
        display: flex
        flex-wrap: wrap
        align-items: center

      & > div
        display: flex
        align-items: center
        padding: 4px 0

    &__filters-card
      padding: 8px 24px
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      background-color: white

      +md
        display: block !important

    &__body
      padding-top: 32px

      +md
        padding-top: 64px

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +md
        display: none

    &__per-page-select
      max-width: 160px
      padding-top: 0
      margin-top: 0

</style>