<template lang="pug">
  div(:class="b()")
    div(:class="b('credit')" v-for="(credit, index) in credits" :key="index")
      div(:class="b('credit-header')")
        span(:class="b('credit-product')") {{ credit.tipoCredito | humanizeCreditType }}&nbsp;
        span(:class="b('credit-responsability')") {{ credit.tipoResponsabilidad | humanizeResponsability }}
      div(:class="b('credit-container')")
        div(:class="b('credit-body')")
          v-layout(wrap)
            v-flex(xs12 sm6)
              span.font-weight-medium Otorgante:&nbsp;
              span {{ credit.nombreOtorgante }}
            v-flex(xs12 sm6)
              span.font-weight-medium Cuenta:&nbsp;
              span {{ credit.tipoCuenta | humanizeAccountType }}
            v-flex(xs12 sm6)
              span.font-weight-medium Pagos Vencidos:&nbsp;
              span {{ credit.numeroPagosVencidos }}
            v-flex(xs12 sm6)
              span.font-weight-medium Último pago:&nbsp;
              span {{ credit.fechaUltimoPago }}

        div(:class="b('credit-amount')") $ {{ credit.saldoActual | formatNumber }}
</template>

<script>
  import humanizeAccountType from '../../utils/humanize-account-type';
  import humanizeResponsability from '../../utils/humanize-responsability';
  import humanizeCreditType from '../../utils/humanize-credit-type';

  export default {
    name: 'credits-list',

    props: {
      credits: { type: Array, default: () => [] },
    },

    filters: {
      humanizeAccountType,
      humanizeResponsability,
      humanizeCreditType,
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .credits-list
    // Credit sub-component
    &__credit
      padding: 8px 24px

      &-container
        +lg
          display: flex

      &-body
        flex: 1
        color: darken(white, 75%)
        padding-top: 4px

      &-product
        font-size: 17px
        font-weight: 500

      &-amount
        font-weight: bold
        font-size: 22px
        text-align: right
        white-space: nowrap

      &-responsability
        padding: 2px 12px
        border-radius: 12px
        background-color: darken(white, 5%)
        font-size: 13px
</style>