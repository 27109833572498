<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    income-form(
      :income="income"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import IncomeForm from '@/components/forms/income-form';

  export default {
    name: 'income-form-modal',

    mixins: [formModalMixin({ entityName: 'income' })],

    components: { IncomeForm },
  };
</script>
