<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="loading || error"
      :error="error"
      @retry="args => $emit('retry', args)"
    )
    div(v-else-if="results" :class="b('content')")
      div(:class="b('scores')")
        div(:class="b('score')")
          div(:class="b('score-amount')" :style="{ color: scoreColor }") {{ results.score }}
          div(:class="b('score-amount-title')") Scotia (score)
        div(:class="b('score')")
          div(:class="b('score-amount')" :style="{ color: scoreColor }") {{ results.predicta }}
          div(:class="b('score-amount-title')") Predicta (score)
      div(:class="b('score-amount')" :style="{ Color: scoreColor }") {{ results.reason}}
      div(:class="b('date')") {{ results.updatedDate | formatDate('D/MMMM/YYYY hh:mm a') }}

    .text-center(v-else style="padding: 28px 8px")
      v-btn(color="primary" @click="$emit('init')") Obtener Score Scotia
</template>

<script>
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

const COLORS = {
  verde: '#49c073',
  amarillo: '#FEC049',
  rojo: '#f6412d',
  rosado: '#52aaf9',
  gris: '#bbbbbb'
 };

export default {
  name: 'scotia-score-tab',

  props: {
    results: { type: Object },
    error: { type: Error },
    fetched: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    scoreColor() {
      const sanitizedColorName = (this.results?.color || 'gris').toLowerCase().match(/[a-z]+/g);

      return COLORS[sanitizedColorName];
    },
  },

  components: { LoadingWrapper },
};
</script>

<style lang="sass" scoped>
  .scotia-score-tab
    &__content
      text-align: center
      padding-bottom: 12px

    &__date
      font-style: italic
      font-weight: 500

    &__scores
      display: flex
      justify-content: center
      flex-wrap: wrap

    &__score
      padding: 6px 12px
      margin: 0 8px
      margin-bottom: 6px
      background-color: darken(white, 3%)
      border-radius: 6px

      &-amount
        text-align: center
        font-size: 26px
        font-weight: bold

      &-title
        font-size: 18px
        color: darken(white, 70%)
        font-weight: 500

</style>
