import { render, staticRenderFns } from "./customer-chip.vue?vue&type=template&id=2d1b2868&scoped=true&lang=pug"
import script from "./customer-chip.vue?vue&type=script&lang=js"
export * from "./customer-chip.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1b2868",
  null
  
)

export default component.exports