<template lang="pug">
  v-card(:class="b()")
    div(:class="b('table-container')")
      table(:class="b('table')").rtc-metrics-table.rtc-metrics-table--striped
        table-head(
          :order-by="orderBy"
          :order-flow="orderFlow"
          @order="args => $emit('order', args)"
        )
        table-body(:rows="rows")
        table-footer(:totals="totals")
</template>

<script>
  import TableHead from './table-by-source-head';
  import TableBody from './table-by-source-body';
  import TableFooter from './table-by-source-footer';

  export default {
    name: 'table-by-source',

    props: {
      rows: { type: Array },
      totals: { type: Object },
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    components: {
      TableHead,
      TableBody,
      TableFooter,
    },
  };
</script>

<style lang="sass"></style>