import fanci from 'fanci';
import {
  DeletePropertyMutation,
  DeleteCreditMutation,
  CreatePropertyMutation,
  CreateCreditMutation,
  UpdatePropertyMutation,
  UpdateCreditMutation,
  CreatePropertyRealOwnerMutation,
  //CreatePropertiesCertificatesMutation,
} from './graphql/mutations.gql';
import Properties from '@/components/UI/properties';

export default ({
  getQueryMethod = 'getCustomerQuery',
  beforeUpdate,
} = {}) => ({
  data() {
    return {
      propertyIsMutating: false,
      creditIsMutating: false,
    };
  },

  methods: {
    async onDeleteProperty({ property }) {
      this.handleDeleteMutation({
        mutation: DeletePropertyMutation,

        variables: { id: property.id },

        query: this[getQueryMethod](),

        updateQueryFn: ({ cachedQuery }) => ({
          customer: {
            properties: {
              $set: cachedQuery.customer.properties.filter(cProperty => cProperty.id !== property.id),
            },
          },
        }),
      });
    },

    async $_customerPropertiesMixin_createRealOwner(customer) {
      const results = await this.handleCreateMutation({
        mutation: CreatePropertyRealOwnerMutation,

        variables: { customer: { ...customer, adviserId: this.currentUser.id, notificationsActive: false } },
      });

      return results?.data?.createCustomer;
    },

    async onCreateProperty({ property, realOwner }) {
      let realOwnerCreated;

      try {
        this.propertyIsMutating = true;

        if (realOwner) realOwnerCreated = await this.$_customerPropertiesMixin_createRealOwner(realOwner);

        await this.handleCreateMutation({
          mutation: CreatePropertyMutation,

          variables: {
            data: {
              ...fanci.rename(property, {
                address: true,
                propertyValue: 'value',
                propertyAuctionValue: 'auctionValue',
                currencyId: true,
                valuationDate: true,
                typeId: true,
                yearOfConstruction: true,
                registrationNumber: true,
              }),
              ownerId: this.customer.id,
              realOwnerId: realOwnerCreated?.customer?.id || property?.realOwnerId,
            },
          },

          query: this[getQueryMethod](),

          updateQueryFn: ({
            resultsData: { createProperty: { property: newProperty } },
          }) => newProperty ? ({
            customer: {
              properties: { $push: [newProperty] },
            }
          }) : null,
        })

        this.$refs.properties.closeForm();
      } catch {
        // everythnig ok
      } finally {
        this.propertyIsMutating = false;
      }
    },

    async onUpdateProperty({ property, realOwner }) {
      let realOwnerCreated;

      try {
        this.propertyIsMutating = true;

        if (realOwner) realOwnerCreated = await this.$_customerPropertiesMixin_createRealOwner(realOwner);

        await this.handleUpdateMutation({
          mutation: UpdatePropertyMutation,

          variables: {
            data: {
              ...fanci.rename(property, {
                id: true,
                address: true,
                propertyValue: 'value',
                propertyAuctionValue: 'auctionValue',
                currencyId: true,
                valuationDate: true,
                typeId: true,
                yearOfConstruction: true,
                registrationNumber: true,
              }),
              realOwnerId: realOwnerCreated?.customer?.id || property?.realOwnerId,
            },
          },

          query: this[getQueryMethod](),

          updateQueryFn: ({
            cachedQuery,
            resultsData: { updateProperty: { property: updatedProperty } }
          }) => updatedProperty ? ({
            customer: {
              properties: {
                $set: cachedQuery.customer.properties.map(
                  cProperty => cProperty.id === property.id ? updatedProperty : cProperty,
                )
              },
            }
          }) : null,
        });

        this.$refs.properties.closeForm();
      } catch {
        // everythnig ok
      } finally {
        this.propertyIsMutating = false;

        if (beforeUpdate) beforeUpdate.call(this);
      }
    },

    onCreateCredit({ credit }) {
      this.handleCreateMutation({
        mutation: CreateCreditMutation,

        variables: { ...credit, miVivienda: false },

        query: this[getQueryMethod](),

        updateQueryFn: ({
          cachedQuery,
          resultsData: { createCredit: { credit: newCredit } },
        }) => newCredit ? ({
          customer: {
            properties: {
              $set: cachedQuery.customer.properties.map(
                cProperty => cProperty.id === credit.propertyId ?
                  ({ ...cProperty, credit: newCredit }) :
                  cProperty,
              ),
            },
          }
        }) : null,

        requestingStateKey: 'creditIsMutating',
      }).then(this.$refs.properties.closeForm);
    },

    onUpdateCredit({ credit }) {
      this.handleUpdateMutation({
        mutation: UpdateCreditMutation,

        variables: { ...credit },

        query: this[getQueryMethod](),

        updateQueryFn: ({
          cachedQuery,
          resultsData: { updateCredit: { property: updatedCredit } }
        }) => updatedCredit ? ({
          customer: {
            properties: {
              $set: cachedQuery.customer.properties.map(
                cProperty => cProperty?.credit?.id === updatedCredit.id ? updatedCredit : cProperty,
              ),
            },
          },
        }) : null,

        requestingStateKey: 'creditIsMutating',
      }).then(this.$refs.properties.closeForm);
    },

    async onDeleteCredit({ credit }) {
      this.handleDeleteMutation({
        mutation: DeleteCreditMutation,

        variables: { id: credit.id },

        query: this[getQueryMethod](),

        updateQueryFn: ({ cachedQuery }) => ({
          customer: {
            properties: {
              $set: cachedQuery.customer.properties.map(
                cProperty => cProperty.id === credit.propertyId ? { ...cProperty, credit: null } : cProperty,
              )
            }
          },
        }),
      });
    },
  },

  components: { Properties },
});
