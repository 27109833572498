<template lang="pug">
  div(:class="b()")
    div(class="editor" :class="b('editor')")
      editor-menu-bar(:editor="editor" v-slot="{ commands, isActive }")
        div(:class="b('menu-bar')")
          //- div(:class="b('buttons-group')")
          //-   div(
          //-     :class="b('button', { active: isActive.bold() })"
          //-     @click="commands.undo"
          //-     flat
          //-   ): v-icon mdi-undo
          //-   div(
          //-     :class="b('button', { active: isActive.bold() })"
          //-     @click="commands.redo"
          //-     flat
          //-   ): v-icon mdi-redo
          div(:class="b('buttons-group')")
            div(
              :class="b('button', { active: isActive.bold() })"
              @click="commands.bold"
              flat
            ): v-icon mdi-format-bold
            div(
              :class="b('button', { active: isActive.italic() })"
              @click="commands.italic"
              flat
            ): v-icon mdi-format-italic
            div(
              :class="b('button', { active: isActive.underline() })"
              @click="commands.underline"
              flat
            ): v-icon mdi-format-underline
            div(
              :class="b('button', { active: isActive.strike() })"
              @click="commands.strike"
              flat
            ): v-icon mdi-format-strikethrough
          div(:class="b('buttons-group')")
            div(
              :class="b('button', { active: isActive.blockquote() })"
              @click="commands.blockquote"
              flat
            ): v-icon mdi-format-quote-close
            div(
              :class="b('button', { active: isActive.horizontal_rule() })"
              @click="commands.horizontal_rule"
              flat
            ): v-icon mdi-minus
          div(:class="b('buttons-group')")
            div(
              :class="b('button', { active: isActive.heading({ level: 1 }) })"
              @click="commands.heading({ level: 1 })"
              flat
            ): v-icon mdi-format-header-1
            div(
              :class="b('button', { active: isActive.heading({ level: 2 }) })"
              @click="commands.heading({ level: 2 })"
              flat
            ): v-icon mdi-format-header-2
            div(
              :class="b('button', { active: isActive.heading({ level: 3 }) })"
              @click="commands.heading({ level: 3 })"
              flat
            ): v-icon mdi-format-header-3
          div(:class="b('buttons-group')")
            div(
              :class="b('button')"
              @click="commands.ordered_list"
              flat
            ): v-icon mdi-format-list-numbered
            div(
              :class="b('button')"
              @click="commands.bullet_list"
              flat
            ): v-icon mdi-format-list-bulleted

          div(:class="b('buttons-group')")
            div(
              :class="b('button')"
              @click="commands.createTable({ rowsCount: 2, colsCount: 2, widthHeaderRow: false })"
              flat
            ): v-icon mdi-table-plus

            template(v-if="isActive.table()")
              div(
                :class="b('button')"
                @click="commands.deleteTable"
                flat
              ): v-icon mdi-table-remove

              div(
                :class="b('button')"
                @click="commands.addColumnBefore"
                flat
              ): v-icon mdi-table-column-plus-before

              div(
                :class="b('button')"
                @click="commands.addColumnAfter"
                flat
              ): v-icon mdi-table-column-plus-after

              div(
                :class="b('button')"
                @click="commands.deleteColumn"
                flat
              ): v-icon mdi-table-column-remove

              div(
                :class="b('button')"
                @click="commands.addRowBefore"
                flat
              ): v-icon mdi-table-row-plus-before

              div(
                :class="b('button')"
                @click="commands.addRowAfter"
                flat
              ): v-icon mdi-table-row-plus-after

              div(
                :class="b('button')"
                @click="commands.deleteRow"
                flat
              ): v-icon mdi-table-row-remove

              div(
                :class="b('button')"
                @click="commands.toggleCellMerge"
                flat
              ): v-icon mdi-table-merge-cells

      editor-content(:editor="editor" :class="b('editor-content', { error: Boolean(errorMessages && errorMessages.length) })")

    .v-messages.error--text.mt-2(v-if="errorMessages && errorMessages.length")
      .v-messages__message(v-for="error in errorMessages" :key="error") {{ error }}

    div(:class="b('suggestions-list')" v-show="showSuggestions" ref="suggestions")
      template(v-if="hasResults")
        suggestion-item(
          v-for="(user, idx) in suggestedUsers"
          :key="user.id"
          :user="user"
          :selected="navigatedUserIndex === idx"
          @click.native="selectUser(user)"
        )
</template>

<script>
  import basicStringSearch from '@/utils/basic-string-search';
  import debounce from 'lodash/debounce';
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
  import {
    Blockquote,
    Bold,
    BulletList,
    Heading,
    History,
    HorizontalRule,
    Italic,
    Link,
    ListItem,
    Mention,
    OrderedList,
    Placeholder,
    Strike,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Underline,
  } from 'tiptap-extensions';

  import { MentionableAdvisersQuery } from './queries.gql';
  import SuggestionItem from './suggestion-item';

  export default {
    name: 'rich-textarea',

    props: {
      value: { type: String },
      placeholder: { type: String },
      errorMessages: { type: Array },
      mentionGroupsIds: { type: Array },
    },

    apollo: {
      advisers: {
        query: MentionableAdvisersQuery,
        manual: true,

        variables() {
          return {
            groupsIds: this.mentionGroupsIds,
          };
        },

        result({ data }) {
          this.advisers = data?.advisers?.objects || [];
        },
      },
    },

    data() {
      return {
        editor: null,
        query: null,
        suggestionRange: null,
        advisers: [],
        suggestedUsers: [],
        navigatedUserIndex: 0,
        insertMention: () => {},
        observer: null,
      };
    },

    components: { EditorContent, EditorMenuBar, SuggestionItem },

    mounted() {
      this.editor = new Editor({
        content: this.value,

        onUpdate: debounce(({ getHTML }) => {
          const htmlContent = getHTML();

          this.$emit('input', htmlContent === '<p></p>' ? null : htmlContent);
        }, 500, { maxWait: 3000 }),

        extensions: [
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new Heading({ levels: [1, 2, 3]}),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new BulletList(),
          new Blockquote(),
          new History(),
          new Link(),
          new Table(),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Mention({
            items: () => this.advisers,
            // is called when a suggestion starts
            onEnter: ({
              query, range, command
            }) => {
              this.query = query
              this.suggestedUsers = this.advisers;
              this.suggestionRange = range
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command
            },
            // is called when a suggestion has changed
            onChange: ({
              items, query, range
            }) => {
              this.query = query
              this.suggestedUsers = items;
              this.suggestionRange = range
              this.navigatedUserIndex = 0
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null
              this.suggestedUsers = []
              this.suggestionRange = null
              this.navigatedUserIndex = 0
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              // pressing up arrow
              if (event.keyCode === 38) {
                this.upHandler()
                return true
              }
              // pressing down arrow
              if (event.keyCode === 40) {
                this.downHandler()
                return true
              }
              // pressing enter
              if (event.keyCode === 13) {
                this.enterHandler()
                return true
              }
              return false
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            onFilter: (items, query) => {
              return items.filter(
                ({ firstName, lastName, username }) =>
                  basicStringSearch([firstName, lastName, username], query)
              );
            },
          })
        ],
      });
    },

    computed: {
      hasResults() {
        return this.suggestedUsers.length;
      },

      showSuggestions() {
        return this.query && this.hasResults;
      },
    },

    methods: {
      // navigate to the previous item
      // if it's the first item, navigate to the last one
      upHandler() {
        this.navigatedUserIndex = ((this.navigatedUserIndex + this.suggestedUsers.length) - 1) % this.suggestedUsers.length
      },
      // navigate to the next item
      // if it's the last item, navigate to the first one
      downHandler() {
        this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.suggestedUsers.length
      },

      enterHandler() {
        const user = this.suggestedUsers[this.navigatedUserIndex]
        if (user) this.selectUser(user)
      },

      // we have to replace our suggestion text with a mention
      // so it's important to pass also the position of your suggestion text
      selectUser(user) {
        this.insertMention({
          range: this.suggestionRange,
          attrs: {
            id: user.id,
            label: user.username,
          },
        })
        this.editor.focus()
      },

      clear() {
        this.editor.clearContent();
      },
    },

    beforeDestroy() {
      this.editor.destroy();
    },
  };
</script>

<style lang="sass">
  .rich-textarea

    &__buttons-group
      display: inline-flex
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
      margin-right: 12px
      margin-bottom: 6px
      border-radius: 3px

    &__suggestions-list
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
      max-height: 300px
      overflow-y: auto

    &__button
      padding: 4px 8px
      cursor: pointer

      &--active
        background-color: #E3EFF9
        i
          color: #1976d2 !important

    &__editor
      @import '~@/sass/message-format'

      &-content
        margin-top: 6px
        background-color: darken(white, 2%)

        &--error
          .ProseMirror
            border: 2px solid red
            outline-color: red

        .ProseMirror
          padding: 6px

  p.is-editor-empty:first-child::before
    content: attr(data-empty-text)
    position: absolute
    float: left
    color: darken(white, 40%)
    pointer-events: none

  .ProseMirror
    min-height: 100px

</style>