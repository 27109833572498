<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 md8).px-2
          validation-provider(name="tipo de documento" :rules="'required'" v-slot="{ errors }")
            document-type-select(
              v-model="data.documentTypeId"
              :error-messages="errors"
              name="tipo de documento"
              label="Tipo de documento"
            )
        v-flex(xs12 md4).px-2
          validation-provider(name="cantidad" :rules="'required|min_value:1|max_value:10'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.quantity"
              :error-messages="errors"
              type="number"
              label="Cantidad"
              name="cantidad"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Agregar
</template>

<script>
  import DocumentTypeSelect from '@/components/inputs/document-type-select';

  import basicFormMixin from './basic-form-mixin';

  export default {
    name: 'document-form',

    props: {
      lazySelects: { type: Boolean, default: false },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'document',
        veeValidation: true,

        mapPropsToData({ document }) {
          return {
            documentTypeId: document?.documentTypeId,
            quantity: 1,
          };
        },
      }),
    ],

    components: {
      DocumentTypeSelect,
    },
  };
</script>

<style lang="sass" scoped>

</style>