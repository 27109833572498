<template lang="pug">
  router-link(:class="b()" :to="{ name: 'risk_files.show', params: { riskFileId: riskFile.id }}")
    div(:class="b('data')")
      div(:class="b('header')")
        span(:class="b('label')" :style="{ backgroundColor: statusColor }") {{ $t(`riskOpinionStatus.${status}`) }}
        span(:class="b('date')") {{ riskFile.createdAt | formatDate('D MMMM YYYY - hh:mm a') }}
      template(v-if="opinion")
        div(:class="b('description')")
          span(:class="b('name')") {{ opinion.user.firstName }} {{ opinion.user.lastName }}:
          span.text--secondary &nbsp;{{ description }}
        div(:class="b('values')").pt-1
          span(v-if="opinion.ltvMax").mr-2
            span.font-weight-medium LTV Max:&nbsp;
            span {{ opinion.ltvMax }} %
          span(v-if="opinion.simpleFeeMax").mr-2
            span.font-weight-medium Cuota Max:&nbsp;
            span {{ opinion.simpleFeeMax | formatCurrency(currency) }}
          span(v-if="opinion.rciMax")
            span.font-weight-medium RCI Max:&nbsp;
            span {{ opinion.rciMax }} %

</template>

<script>
  import { RISK_OPINION_STATUS } from '@/utils/constants';
  import getStatusColor from '@/utils/get-risk-opinion-status-color';

  const MAX_DESCRIPTION_CHARS = 100;

  export default {
    name: 'risk-files-history-item',

    props: {
      riskFile: { type: Object, required: true },
    },

    computed: {
      opinion() {
        return this.riskFile.lastOpinion;
      },

      status() {
        return this.opinion?.status || RISK_OPINION_STATUS.PENDING;
      },

      statusColor() {
        return getStatusColor(this.status);
      },

      description() {
        if(!this.opinion?.description) return;

        const description = this.opinion.description.replace(/(<([^>]+)>)/ig,"");

        return description.length > MAX_DESCRIPTION_CHARS ?
          description.slice(0, MAX_DESCRIPTION_CHARS) + '...' :
          description;
      },

      currency() {
        return this.riskFile.customer.proposal.currency;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .risk-files-history-item
    display: flex
    align-items: center
    padding: 6px 0
    text-decoration: none
    color: inherit

    &:hover
      background-color: darken(white, 3%)

    &__data
      padding-left: 8px
      flex: 1
      font-size: 14px

    &__date
      font-weight: 500

    &__name
      font-weight: 500

    &__description
      padding-top: 2px

    &__label
      display: inline-block
      width: 110px
      padding: 2px 0
      border-radius: 6px
      text-align: center
      color: white
      font-weight: 500
      font-size: 12px
      margin-right: 4px
</style>