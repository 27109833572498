<template lang="pug">
  v-dialog(
    :value="value"
    width="1000"
    @input="args => $emit('input', args)"
  )
    v-card
      //- div(:class="b('header')").pa-4
      //-   div(:class="b('header-title')") Recordatorios
      //-   .spacer
      //-   v-btn(icon @click="onClickClose")
      //-     v-icon mdi-close
      //- v-divider

      .pa-4
        .d-flex.align-center
          .font-weight-medium Recordatorios
          .spacer
          v-btn(small icon @click="onClickClose")
            v-icon mdi-close
      v-divider

      .pa-4
        reminders(
          :reminders="reminders"
          :loading="loading"
          @complete="args => $emit('complete', args)"
          @delete="args => $emit('delete', args)"
          @change-page="args => $emit('change-page', args)"
          @click-add="args => $emit('click-add', args)"
        )
</template>

<script>
import Reminders from './reminders';

export default {
  name: 'reminders-modal',

  props: {
    value: { type: Boolean, default: false },
    reminders: { type: Object },
    loading: { type: Boolean, default: false },
  },

  methods: {
    onClickClose() {
      this.$emit('input', false);
    },
  },

  components: { Reminders },
};
</script>

<style lang="sass">
  .reminders-modal
    &__header
      display: flex
      align-items: center

      &-title
        font-size: 16px
        font-weight: 500
</style>