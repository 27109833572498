import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import phoneNumber from './phone-number';
import minValueOrZero from './min-value-or-zero';
import decimal from './decimal';
import after from './after';
import before from './before';
import alphaUnderscore from './alpha-underscore';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('phone_number', phoneNumber);
extend('min_value_or_zero', minValueOrZero);
extend('decimal', decimal);
extend('after', after);
extend('before', before);
extend('alpha_underscore', alphaUnderscore);
