<template lang="pug">
  thead
    tr
      th Mes
      th Cuota Promedio Teórica
      th Recaudo Teórico
      th Recaudo Real
      th Interés Teórico
      th Interés Real
      th # Cuotas
      th % Recaudo
      th % Cuotas
</template>

<script>
export default {
  name: 'forecast-table-head',
};
</script>

<style lang="sass" scoped>
//.forecast-table-head
</style>
