<template lang="pug">
  div(:class="b()")
    product-stage(
      :class="b('item')"
      v-for="stage in stages"
      :key="stage.name"
      :stage="stage"
      :currency="currency"
    )
</template>

<script>
  import ProductStage from './product-stages-item';

  export default {
    name: 'product-stages',

    props: {
      stages: { type: Array, default: () => [] },
      currency: { type: Object, required: true },
    },

    components: { ProductStage },
  };
</script>

<style lang="sass" scoped>
  .product-stages
    &__item
      border-bottom: 1px solid darken(white, 6%)

      &:last-child
        border-bottom: 0
</style>