const COLORS = {
  CPP: '#ff6fe7',
  DEF: '#FFCE63',
  DUD: '#2BC0BF',
  NOR: '#28a745',
  PER: '#dc3545',
  SCH: '#b8b5ff',
};

export default qualification => COLORS[qualification];
