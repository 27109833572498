<template lang="pug">
  div(:class="b()")
    status-icon(:class="b('icon')" :status="requirement.status.name" :color="requirement.status.color")
    div(:class="b('body')")
      div(:class="b('name')") {{ requirement.name }}
      div(:class="b('status-row')")
        status-select(
          :class="b('status-select')"
          :requirement-id="requirement.typeId"
          :disabled="requirement.disabled"
          :value="requirement.status.id"
          :default-items="[{ id: requirement.status.id, name: requirement.status.name }]"
          @input="onChangeStatus"
        )
        div(:class="b('date')")
          v-icon(:class="b('date-icon')") mdi-calendar
          span {{ requirement.modifiedAt | formatDate }}
      div(:class="b('description')") {{ requirement.description }}
    div(:class="b('actions')")
      v-icon(v-if="canDelete" @click="onClickDelete") mdi-delete
</template>

<script>
  import StatusSelect from '@/components/inputs/requirement-status-select';
  import StatusIcon from '@/components/UI/requirement-status-icon';

  export default {
    name: 'requirements-item',

    props: {
      requirement: { type: Object, required: true },
    },

    computed: {
      canDelete() {
        return this.isCustom && this.requirement.status.id === "1"; // Solo pendings
      },

      isCustom() {
        return !this.requirement.stage?.id;
      },
    },

    components: { StatusSelect, StatusIcon },

    methods: {
      onChangeStatus(statusId) {
        this.$emit('change-status', { statusId , requirement: this.requirement });
      },

      onClickDelete() {
        this.$emit('delete', { requirement: this.requirement });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .requirements-item
    display: flex
    padding: 1rem 0

    &__icon
      margin-right: 1rem
      align-self: flex-start
      font-size: 30px

    &__name
      font-weight: bold

    &__name, &__description
      &::first-letter
        text-transform: uppercase

    &__status-row
      display: flex
      align-items: center
      justify-content: space-between

    &__date
      display: flex
      align-items: center
      white-space: nowrap

      &-icon
        font-size: 1.5em
        padding-right: 4px
        padding-left: 8px
</style>

<style lang="sass">
  .requirements-item
    &__status-select
      flex: 1 1 180px
      padding: 0

      .v-input__slot
        margin: 0

        &:before, &:after
          border: none !important

      .v-select__selection
        color: #1976d2
        font-size: 1rem

      .v-text-field__details
        display: none

      .v-icon
        color: #1976d2
</style>
