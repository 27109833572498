<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs6)
        div(:class="b('data-row')")
          div(:class="b('data-row-value')") {{ Number(statement.borrowerRate * 100).toFixed(2) }} %
          div(:class="b('data-row-title')") TEA
      v-flex(xs6)
        div(:class="b('data-row')")
          div(:class="b('data-row-value')") {{ Number(statement.tcea).toFixed(2) }} %
          div(:class="b('data-row-title')") TCEA
      v-flex(xs6 v-if="showDetail")
        div(:class="b('data-row')")
          div(:class="b('data-row-value')") {{ statement.remainingMonths }}
          div(:class="b('data-row-title')") Cuotas pendientes
      v-flex(v-if="statement.nextFeeAmount && showDetail" xs6)
        div(:class="b('data-row')")
          div(:class="b('data-row-value')") {{ statement.nextFeeAmount | formatCurrency(investmentProposal.currency) }}
          div(:class="b('data-row-title')") Próxima cuota
      v-flex(mb6 style="display: flex; flex-direction: column; justify-content: center; align-items: center;")
        div(:class="b('data-row')" v-if="showDetail")
          div(:class="b('data-row')")
          div(:class="b('data-row')")
          div(:class="b('data-row-detail')") #[h2 Liquidacion al {{statement.settlementDate  | formatDate}}]
          v-flex(mb6)
          div(:class="b('data-row-detail')") Saldo capital: #[b {{ statement.totalToCancelDebt | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')") Total de intereses compensatorios: #[b {{ statement.totalToCancelInterest | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.totalToCancelPenalty !== 0") Total de penalidades: #[b {{ statement.totalToCancelPenalty | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.totalToCancelDefault !== 0") Total de intereses moratorios: #[b {{ statement.totalToCancelDefault | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.totalToCancelPropertyInsurance !== 0") Total de seguro inmueble: #[b {{ statement.totalToCancelPropertyInsurance | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.totalToCancelLifeInsurance !== 0") Total de seguro vida: #[b {{ statement.totalToCancelLifeInsurance  | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.collectingExpenses !== 0") Total costas procesales: #[b {{ statement.collectingExpenses | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.legalExpenses !== 0") Total costos procesales: #[b {{ statement.legalExpenses | formatCurrency(investmentProposal.currency) }}]
            div(:class="b('data-row-detail')" v-if="statement.judicializedPenalty !== 0") Penalidad por disolución: #[b {{ statement.judicializedPenalty | formatCurrency(investmentProposal.currency) }}]
          div(:class="b('data-row')")
          div(:class="b('data-row')")
          div(:class="b('data-row-detail')") #[h2 Total: {{statement.totalDetail  | formatCurrency(investmentProposal.currency) }}]
</template>

<script>
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';
export default {
  name: 'investment-proposal-card',

  props: {
    statement: { type: Object },
    investmentProposal: { required: true, type: Object },
  },
  computed: {
    showDetail() {
      return this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.COLLECTING || this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED;
    },
  },
};
</script>

<style lang="sass" scoped>
  .investment-proposal-card
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    padding-left: 60px

    &__name
      font-size: 20px
      font-weight: 500
      margin-bottom: 16px
      text-align: center

    &__data-row
      text-align: center
      margin-bottom: 18px

      &-date
        color: darken(white, 60%)
        font-weight: 500

      &-value
        font-size: 22px
        margin-bottom: 4px
        line-height: 1
        font-weight: 500

      &-title
        font-size: 16px
        line-height: 1.25

      &-detail
        font-size: 16px
        line-height: 1.25
        text-align: left
</style>
