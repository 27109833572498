<template lang="pug">
  div(:class="b()")
    div(:class="b('debt')" v-for="debt, idx in debts" :key="idx")
      div(:class="b('debt-title')") {{ debt.period | formatDate }}
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="debtType in debt.dafpDebtTypes" :key="debtType.id")
          div(v-if="debtType.afps") #[b AFPS: ] {{ debtType.afps }}
          div(v-if="debtType.amount") #[b Deuda: ] {{ debtType.amount | formatCurrency }}
          div(v-if="debtType.months") #[b Meses: ] {{ debtType.months }}
</template>

<script>
  export default {
    name: 'afp-debts-tab',

    block: 'debts-tab',

    props: {
      debts: { type: Array, default: () => [] },
    },
  };
</script>
