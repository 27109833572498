<template lang="pug">
  table(:class="b()")
    thead
      tr
        th #
        th Fecha Vcto.
        th Amortización
        th Interés
        th(v-if="hasPropertyInsurance") Seg. bien
        th(v-if="hasLifeInsurance") Seg. Desg.
        th Cuota
        th Saldo Capital
        th IR
        th Gastos
        th Cuota Neta
    tbody
      tr(v-for="entry, idx in schedule" :key="idx")
        td {{ entry.feeNumber }}
        td {{ entry.date | formatDate('DD MMM YYYY') }}
        td {{ entry.amortization | formatNumber(currency) }}
        td {{ entry.interest | formatNumber(currency) }}
        td(v-if="hasPropertyInsurance") {{ entry.propertyInsurance | formatNumber(currency)  }}
        td(v-if="hasLifeInsurance") {{ entry.lifeInsurance | formatNumber(currency)  }}
        td {{ entry.fee | formatNumber(currency)  }}
        td {{ entry.capital | formatNumber(currency)  }}
        td {{ entry.irAmount | formatNumber(currency)  }}
        td {{ entry.rtcIva | formatNumber(currency)  }}
        td {{ entry.netFee | formatNumber(currency)  }}
</template>

<script>
  export default {
    name: 'schedule-table',

    props: {
      schedule: { type: Array },
      currency: { type: Object },
    },

    computed: {
      hasPropertyInsurance() {
        return this.schedule.reduce((acc, { propertyInsurance }) => acc + propertyInsurance, 0) > 0;
      },

      hasLifeInsurance() {
        return this.schedule.reduce((acc, { lifeInsurance }) => acc + lifeInsurance, 0) > 0;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .schedule-table
    width: 100%
    border-collapse: collapse
    white-space: nowrap

    td, th
      padding: 0 9px

    thead
      text-align: right

    tbody
      text-align: right

      tr
        td
          &:first-child
            width: 20px

        &:nth-child(odd)
          background-color: darken(white, 5%)
</style>