<template lang="pug">
  div(:class="b()")
    div(:class="b('filters-card')" ref="toolbar")
      filters(
        :filters="filters"
        :requesting="$apollo.queries.quotes.loading"
        @submit="onSubmitFilters"
      )
    v-container(fluid :class="b('body')")
      loading-wrapper(
        v-if="$apollo.queries.quotes.loading || quotesError"
        :error="quotesError"
        @retry="onRetryQuery"
      )
      template(v-else-if="quotes && quotes.objects && quotes.objects.length")
        div(:class="b('controls')")
          .d-flex.align-center
            b Entradas:&nbsp;
            per-page-select(
              v-model="perPage"
              :class="b('per-page-select')"
              hide-details
            ).ml-2
          .spacer
          div #[b.mr-1 Resultados: ] {{ quotes.count }} Inversionistas
        div(:class="b('table-container')")
          investor-quotes-table(
            :quotes="quotes.objects"
            :order-by="filters.orderBy"
            :order-flow="filters.orderFlow"
            @order="onOrder"
          )
      v-alert(v-else type="info" outlined) No se han encontrado prestatarios con los filtros indicados
      .text-center
        v-pagination(
          v-if="quotes && quotes.pages > 1"
          v-model="currentPage"
          :length="quotes.pages"
          :total-visible="15"
        )
</template>

<script>
import { QUOTE_STATUS } from '@/utils/constants';
import quotesViewMixin from '../quotes-view-mixin';

import Filters from './components/filters';
import InvestorQuotesTable from './components/investor-quotes-table';
import { InvestorQuotesListQuery } from './graphql/queries.gql';

export default {
  name: 'investor-quotes-view',

  mixins: [
    quotesViewMixin({
      query: InvestorQuotesListQuery,

      filtersDefaultValues: {
        investorIds: [],
        borrowerIds: [],
        expirationDate: null,
        paymentDate: null,
        orderBy: null,
        orderFlow: null,
        status: [QUOTE_STATUS.PENDING, QUOTE_STATUS.EXPIRED],
      }
    }),
  ],

  apollo: {
    quotes: {
      query: InvestorQuotesListQuery,
      fetchPolicy: 'cache-and-network',

      update({ investorQuotes }) {
        return investorQuotes;
      },

      variables() {
        const {
          investorIds,
          borrowerIds,
          expirationDate,
          paymentDate,
          status,
          orderFlow,
          orderBy
        } = this.filters || {};

        return {
          page: this.currentPage,
          perPage: this.perPage,
          investorIds: investorIds?.length ? investorIds : undefined,
          borrowerIds: borrowerIds?.length ? borrowerIds : undefined,
          expirationDateSince: expirationDate?.[0],
          expirationDateUntil: expirationDate?.[1] || expirationDate?.[0],
          paidDateSince: paymentDate?.[0],
          paidDateUntil: paymentDate?.[1] || paymentDate?.[0],
          status,
          orderFlow,
          orderBy,
        };
      },

      error() {
        this.quotesError = new Error('Ha ocurrido un error al obtener los datos');
      },
    },
  },

  components: { InvestorQuotesTable, Filters },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investor-quotes-view
    &__table-container
      overflow-x: auto

    &__controls
      padding-bottom: 12px

      +sm
        display: flex
        align-items: center

      & > div
        display: flex
        align-items: center
        padding: 4px 0

    &__filters-card
      padding: 8px 24px
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      background-color: white

      +md
        display: block !important

    &__body
      padding-top: 32px

      +md
        padding-top: 64px

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +md
        display: none

    &__per-page-select
      max-width: 160px
      padding-top: 0
      margin-top: 0

</style>