<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="$apollo.queries.customer.loading || $apollo.queries.couple.loading || !customer"
    )
    template(v-else)
      nip-validation(
        v-if="hasPendingNipValidations"
        :customer="customer"
        :couple="couple"
        :requesting="nipIsMutating"
        @submit-request-nip="onRequestNip"
        @submit-authorize-nip="onAuthorizeNip"
      ).mb-2
      v-tabs(v-model="currentTab")
        v-tab(v-if="rciEnabled") Endeudamiento
        v-tab(v-if="canSeeScotiaScore") Score Scotia
        v-tab(
          v-for="context in buroContexts"
          :key="context.title"
        ) {{ context.title }}
        v-tab(v-if="isSentinelEnabled()" @click="getBuroV2(true)") Sentinel
        v-tab-item(v-if="rciEnabled").pt-4
          rci-tab(
            :values="rci.values"
            :loading="$apollo.queries.rci.loading"
            :skipped="rci.skipQuery"
            :error="rci.error"
            :requirements="rciRequirements"
            @init="rci.skipQuery = false"
            @retry="onRetryRci"
            @change-rci="args => $emit('change-rci', args)"
          )
          slot(name="rci-tab")
        v-tab-item(v-if="canSeeScotiaScore").pt-4
          scotia-score-tab(
            :loading="$apollo.queries.scotiaScore.loading"
            :results="scotiaScore.results"
            :error="scotiaScore.error"
            @retry="onRetryScotiaScore"
            @init="scotiaScore.skipQuery = false"
          )
        v-tab-item(
          v-for="context in buroContexts"
          :key="context.contextKey"
        ).pt-4
          buro-tab(
            :buro="buro[context.contextKey].buro"
            :loading="$apollo.queries[context.contextKey].loading"
            :error="buro[context.contextKey].error"
            :requirements="getBuroRequirements(context)"
            @init="buro[context.contextKey].skipQuery = false"
            @retry="() => onRetryBuro(context.contextKey)"
          )
        template(v-if="customer && isSentinelEnabled()")
          v-tab-item
            v-tabs
              v-tab(v-if="customer" @click="getBuroV2(true)") Consulta Rápida (Titular)
              v-tab(v-if="customer && customer?.couple" @click="getBuroV2(false)") Consulta Rápida (Pareja)
              v-tab(v-if="customer" @click="getBuroV2(true)") Aval (Titular)
              v-tab(v-if="customer && customer?.couple" @click="getBuroV2(false)") Aval (Pareja)
              v-tab-item(v-if="customer")
                loading-wrapper(v-if="!buroV2 && !buroV2Error" @retry="buroV2(true)")
                sentinel(:buroV2="buroV2 || {}")
              v-tab-item(v-if="customer && customer?.couple")
                loading-wrapper(v-if="!buroV2Couple && !buroV2CoupleError" @retry="buroV2(false)")
                sentinel(:buroV2="buroV2Couple || {}")
              v-tab-item(v-if="customer")
                loading-wrapper(v-if="!buroV2 && !buroV2Error" @retry="buroV2(true)")
                sentinel(:buroV2="buroV2 || {}" guarantor=true)
              v-tab-item(v-if="customer && customer?.couple")
                loading-wrapper(v-if="!buroV2Couple && !buroV2CoupleError" @retry="buroV2(false)")
                sentinel(:buroV2="buroV2Couple || {}" guarantor=true)
</template>

<script>
import Config from '@/config';
import isEmpty from 'lodash/isEmpty';
import humps from 'humps';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import compact from 'lodash/compact';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import currentUserMixin from '@/mixins/current-user-mixin';
import { PERMISSIONS } from '@/utils/constants';
import { IS_MX_COUNTRY, IS_PE_COUNTRY } from '@/utils/country-checker';

import { parse as parseRCIJson } from './utils/rci-json-parser';
import RciTab from './components/rci-tab';
import BuroTab from './components/buro-tab';
import ScotiaScoreTab from './components/scotia-score-tab';
import NipValidation from './components/nip-validation';
import Sentinel from '../../../views/customers/show/components/sentinel/sentinel';
import {
  BuroUpdateCustomerMutation,
  SendNipMutation,
  AuthorizeNipMutation,
} from './graphql/mutations.gql';
import {
  CustomerNipStateQuery,
  DebtsGenerateRciQuery,
  ScotiaScoreQuery,
  PEBuroQuery,
  MXBuroQuery,
} from './graphql/queries.gql';

const COUNTRY_CODE = Config.COUNTRY_CODE;

const BURO_COUNTRY_QUERY = do {
  if (IS_PE_COUNTRY) PEBuroQuery;
  else if (IS_MX_COUNTRY) MXBuroQuery;
  else null;
};

export default {
  name: 'debts',

  mixins: [mutationsHandlersMixin(), currentUserMixin()],

  props: {
    customer: { type: Object },
    couple: { type: Object },
    dealId: { type: [Number, String] },
    rciEnabled: { type: Boolean, default: true },
    fullQuery: { type: Boolean, default: true },
  },

  data() {
    return {
      mounted: false,
      nipIsMutating: false,
      currentTab: 0,
      rci: {
        values: null,
        error: null,
        skipQuery: true,
      },
      scotiaScore: {
        results: null,
        error: null,
        skipQuery: true,
      },
      buro: {},
      buroV2: null,
      buroV2Couple: null,
      buroV2Error: null,
      buroV2CoupleError: null,
    };
  },

  apollo: {
    customer: {
      query: CustomerNipStateQuery,
      manual: true,

      result({ data }) {
        if (!data?.customer) return;

        this.$set(this.customer, 'nipValidated', data.customer.nipValidated);
      },

      variables() {
        return { customerId: this.customer.id };
      },

      skip() {
        return !Config.NIP_VALIDATION || !this.customer || !this.mounted;
      },
    },

    couple: {
      query: CustomerNipStateQuery,
      manual: true,

      result({ data }) {
        if (!data?.customer) return;

        this.$set(this.couple, 'nipValidated', data.customer.nipValidated);
      },

      variables() {
        return { customerId: this.couple.id };
      },

      skip() {
        return !Config.NIP_VALIDATION || !this.couple || !this.mounted;
      },
    },

    rci: {
      query: DebtsGenerateRciQuery,
      fetchPolicy: 'cache-and-network',
      manual: true,

      variables() {
        return {
          customerId: this.customer.id,
          dealId: this.dealId,
        };
      },

      result({ data }) {
        if (data?.generateRci?.rci) {
          this.rci.values = parseRCIJson(data.generateRci.rci);

          this.$emit('rci-loaded');
        }
      },

      error(e) {
        this.rci.error = new Error(
          e?.messagge || 'Ha ocurrido un error al obtener el reporte',
        );
      },

      skip() {
        return this.rci.skipQuery;
      },
    },

    scotiaScore: {
      query: ScotiaScoreQuery,
      manual: true,

      variables() {
        return {
          vat: this.customer.vat,
        };
      },

      result({ data }) {
        if (data?.scotiaScore) {
          this.scotiaScore.results = data.scotiaScore;
        } else {
          this.scotiaScore.error = new Error(
            'No se ha encontrado información sobre el score del cliente',
          );
        }
      },

      error(e) {
        this.scotiaScore.error = new Error(
          e?.message || 'Ha ocurrido un error al obtener el report',
        );
      },

      skip() {
        return this.scotiaScore.skipQuery;
      },
    },
  },

  computed: {
    hasPendingNipValidations() {
      return (
        Config.NIP_VALIDATION &&
        [this.customer?.nipValidated, this.couple?.nipValidated].includes(false)
      );
    },

    rciRequirements() {
      return Config.NIP_VALIDATION
        ? compact([
            this.customer.nipValidated === false && {
              title: `[VALIDAR NIP] ${this.customer.firstName} ${this.customer.lastName} (Titular)`,
              description:
                'Se necesita validar el NIP del titular para poder calcular su endeudamiento',
              iconColor: '#f44336',
            },
            this.couple?.nipValidated === false && {
              title: `[VALIDAR NIP] ${this.couple.firstName} ${this.couple.lastName} (Pareja)`,
              description:
                'Para un cálculo mas certero del endeudamiento se debe validar el NIP de la pareja',
              icon: 'mdi-alert',
              iconColor: '#ffc107',
              optional: true,
            },
          ])
        : [];
    },

    buroContexts() {
      const ORDER_VALUES = {
        buro_customer: -10,
        buro_couple: -5,
      };

      return Object.entries(this.buro)
        .map(([contextKey, value]) => ({
          ...value,
          contextKey,
        }))
        .sort(
          (a, b) =>
            (ORDER_VALUES[a.contextKey] || 0) -
            (ORDER_VALUES[b.contextKey] || 0),
        );
    },

    buroContextsKeys() {
      return Object.keys(this.buro);
    },

    canSeeScotiaScore() {
      return (
        Config.SCOTIA_SCORE_ENABLED &&
        this.currentUserHasPermissions([PERMISSIONS.PEGASUS.SCOTIA_SCORE])
      );
    },
  },

  methods: {
    addBuroContext(key, title, data) {
      if (this.buroContextsKeys.includes(key)) return;

      this.$set(this.buro, key, {
        buro: null,
        error: null,
        skipQuery: true,
        title,
        data,
      });

      this.$apollo.addSmartQuery(key, {
        query: BURO_COUNTRY_QUERY,
        manual: true,

        variables() {
          return {
            userId: data?.id,
            country: COUNTRY_CODE.toUpperCase(),
            vat: data?.vat,
            docTypeId: data?.vatType?.id || '6',
            full: this.fullQuery,
            reportType: 'equifax',
          };
        },

        result({ data: { buro } }) {
          /*
              En PE los resultados son en la key "buro.data" en un formato underscore
              mientras que en MX ya están en camelCase y directamente en la key "buro"
              */
          const buroData = buro.data ? humps.camelizeKeys(buro.data) : buro;
          const firstError = buroData.errors?.[0]?.message;

          this.buro[key].buro = { ...buroData };
          this.buro[key].error = firstError && new Error(firstError);
        },

        skip() {
          return this.buro[key].skipQuery;
        },

        error(e) {
          this.buro[key].error = new Error(
            e?.messagge || 'Ha ocurrido un error al obtener el reporte',
          );
        },
      });
    },

    getBuroRequirements(context) {
      return Config.NIP_VALIDATION && context.data.nipValidated === false
        ? [
            {
              title: `[VALIDAR NIP] ${context.data.firstName} ${context.data.lastName}`,
              description:
                'Se necesita validar el NIP para obtener los datos en Buro',
              iconColor: '#f44336',
            },
          ]
        : [];
    },

    refetchRci() {
      this.$apollo.queries.rci.refetch();
    },

    async onRequestNip({ customer, component }) {
      try {
        await this.handleUpdateMutation({
          mutation: BuroUpdateCustomerMutation,

          variables: { customer },

          successMessage: null,

          requestingStateKey: 'nipIsMutating',
        });

        await this.handleUpdateMutation({
          mutation: SendNipMutation,

          variables: {
            customerId: customer.id,
            vat: customer.vat,
          },

          successMessage: 'El NIP ha sido enviado exitosamente',

          requestingStateKey: 'nipIsMutating',
        });

        if (component?.closeForm) component.closeForm();
      } catch (e) {
        return e;
      }
    },

    onAuthorizeNip({ code, customer, component }) {
      this.handleUpdateMutation({
        mutation: AuthorizeNipMutation,

        variables: {
          customerId: customer.id,
          vat: customer.vat,
          code,
        },

        query: {
          query: CustomerNipStateQuery,
          variables: { customerId: customer.id },
        },

        updateQueryFn: ({
          resultsData: {
            authorizeNip: { errors },
          },
        }) =>
          !errors
            ? {
                customer: { nipValidated: { $set: true } },
              }
            : null,

        successMessage: 'El NIP ha sido validado exitosamente',

        requestingStateKey: 'nipIsMutating',
      })
        .then(() => {
          if (component?.closeForm) component.closeForm();
        })
        .catch(() => null);
    },

    onRetryRci() {
      this.rci.error = null;
      this.$apollo.queries.rci.refetch();
    },

    onRetryScotiaScore() {
      this.scotiaScore.error = null;
      this.$apollo.queries.scotiaScore.refetch();
    },

    onRetryBuro(contextKey) {
      this.buro[contextKey].error = null;
      this.$apollo.queries[contextKey].refetch();
    },

    async getBuroV2(titular) {
      this.$apollo.query({
        query: PEBuroQuery,
        variables: {
          userId: titular ? parseInt(this.customer?.id) : parseInt(this.customer?.couple?.id),
          country: 'PE',
          vat: titular ? this.customer?.vat : this.customer?.couple?.vat,
          docTypeId: titular ? this.customer?.vatType?.id : this.customer?.couple?.vatType?.id,
          full: true,
          reportType: 'sentinel',
        },
      }).then(
        ({ data }) => {
          if (titular) {
            this.buroV2 = data.buro.data;
          } else {
            this.buroV2Couple = data.buro.data;
          }
        },
      ).catch(error => {
        if (titular)
          this.buroV2Error = new Error('Ha ocurrido un error al obtener los datos') || error;
        else
          this.buroV2CoupleError = new Error('Ha ocurrido un error al obtener los datos') || error;
      });
    },

    isSentinelEnabled() {
      return Config.SENTINEL_ENABLED;
    }
  },

  components: {
    NipValidation,
    RciTab,
    BuroTab,
    ScotiaScoreTab,
    LoadingWrapper,
    Sentinel,
  },

  mounted() {
    this.mounted = true;
  },

  watch: {
    customer: {
      immediate: true,
      deep: true,

      handler(values) {
        if (!isEmpty(values)) {
          this.addBuroContext('buro_customer', 'BURO (TITULAR)', {
            ...values,
          });

          (values?.incomes || []).forEach((income, index) => {
            const workPlace = do {
              if (income.workPlace) income.workPlace;
              else if (income.workPlaceName && income.workPlaceVat)
                ({ vat: income.workPlaceVat, name: income.workPlaceName });
              else null;
            };

            if (workPlace) {
              const tabName = `BURO ${workPlace.name.slice(0, 16)}${
                workPlace.name.length > 16 ? '...' : ''
              }`;

              this.addBuroContext(`buro_customer_workplace_${index}`, tabName, {
                ...workPlace,
              });
            }
          });
        }
      },
    },

    couple: {
      immediate: true,
      deep: true,

      handler(values) {
        if (!isEmpty(values)) {
          this.addBuroContext('buro_couple', 'BURO (PAREJA)', {
            ...values,
          });

          (values?.incomes || []).forEach((income, index) => {
            const workPlace = do {
              if (income.workPlace) income.workPlace;
              else if (income.workPlaceName && income.workPlaceVat)
                ({ vat: income.workPlaceVat, name: income.workPlaceName });
              else null;
            };

            if (workPlace) {
              const tabName = `BURO ${workPlace.name.slice(0, 16)}${
                workPlace.name.length > 16 ? '...' : ''
              }`;

              this.addBuroContext(`buro_couple_workplace_${index}`, tabName, {
                ...workPlace,
              });
            }
          });
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped></style>
