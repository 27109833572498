<template lang="pug">
  base-dialog(
    :value="value"
    title="Buen contribuyente"
    @input="args => $emit('input', args)"
  )
    div(:class="b('debt')" v-for="payer in taxpayers" :key="payer.id")
      div(v-if="payer.reportedDate") #[b Fecha: ] {{ payer.reportedDate | formatDate }}
      div(v-if="payer.source") #[b Fuente: ] {{ payer.source }}
      div(v-if="payer.description") #[b Descripción: ] {{ payer.description }}
</template>

<script>
  import BaseDialog from './base-dialog';

  export default {
    name: 'good-taxpayers-dialog',

    block: 'debts-tab',

    props: {
      value: { type: Boolean },
      taxpayers: { type: Array, default: () => [] },
    },

    components: {
      BaseDialog,
    },
  };
</script>
