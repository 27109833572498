<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(v-if="!isCreating" xs12).px-2
          validation-provider(name="estado" :rules="rules.status" v-slot="{ errors }")
            status-select(
              v-model="data.status"
              :error-messages="errors"
              :disabled="disabledFields.includes('status')"
              name="estado"
              label="Estado"
            )

        v-flex(:class="b('rich-textarea-container')" v-if="isPendingStatus" xs12).px-2.py-3
          validation-provider(name="mecánica de desembolso" :rules="'required'" v-slot="{ errors }")
            rich-textarea(
              ref="richTextarea"
              v-model="data.disbursementProcedure"
              :error-messages="errors"
              :disabled="requesting"
              name="mecánica de desembolso"
              placeholder="Mecánica de desembolso..."
            )

        v-flex(xs12).px-2
          validation-provider(name="plantillas de contrato" :rules="rules.agreementIds" v-slot="{ errors }")
            agreement-select(
              v-model="data.agreementIds"
              :error-messages="errors"
              :default-items="agreementSelectDefaultItems"
              :disabled="disabledFields.includes('agreementIds') || isEmittedStatus"
              name="plantillas de contrato"
              label="Plantillas de contrato"
              multiple
              small-chips
              deletable-chips
            )

        v-flex(v-if="!isCreating" xs12)
          h3.text--secondary.mb-3.text-center Detalles del Desembolso
          contract-pay-details-input(
            v-model="data.toPayDetails"
            :disabled-fields="toPayDetailsDisabledFields"
            :required-total="toPayDetailsTotal"
            ref="payDetailsInput"
          )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
import omit from 'lodash/omit';

import AgreementSelect from '@/components/inputs/agreement-select';
import ContractPayDetailsInput from '@/components/inputs/contract-pay-details-input';
import StatusSelect from '@/components/inputs/contract-status-select';
import RichTextarea from '@/components/inputs/rich-textarea';
import { CONTRACT_STATUS } from '@/utils/constants';

import basicFormMixin from './basic-form-mixin';

export default {
  name: 'contract-form',

  props: {
    toPayDetailsTotal: { type: Number },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'contract',
      veeValidation: true,

      mapPropsToData() {
        const { status, disbursementProcedure } = this.contract || {};
        return {
          id: this.contract?.id,
          status: status || CONTRACT_STATUS.PENDING,
          agreementIds: (this.contract?.agreements || []).map(({ id }) => id),
          toPayDetails: this.contract?.toPayDetails || [],
          disbursementProcedure: disbursementProcedure ?
            disbursementProcedure :
            (
              (status === CONTRACT_STATUS.PENDING || !status) ?
                `<p>Señor notario por favor entregar de:</p>
                <ul>
                  <li>Cheque por S/ XXXXXX</li>
                  <li>Al recibir el comprobante XXXXX entregar el cheque S/ XXXXXX</li>
                </ul>
                ` : null
            )
        };
      },

      parseSubmit() {
        const { toPayDetails, ...data } = this.data;

        return {
          contract: {
            ...data,
            toPayDetails: toPayDetails.map(detail => ({
              ...omit(detail, ['id', '__typename']),
              amount: parseFloat(detail.amount) || null,
            })),
          }
        }
      }
    }),
  ],

  data() {
    return {
      rules: {
        agreementIds: { required: true },
        status: { required: true },
      },
    };
  },

  methods: {
    async validate() {
      try {
        const success = await this.$refs.form.validate();

        if(!this.isCreating) await this.$refs.payDetailsInput.validate();

        if(!success) return Promise.reject();

        return Promise.resolve()
      } catch {
        return Promise.reject();
      }
    },
  },

  computed: {
    isCreating() {
      return !this.contract;
    },

    isPendingStatus() {
      return this.data.status === CONTRACT_STATUS.PENDING;
    },

    isEmittedStatus() {
      return this.data.status === CONTRACT_STATUS.EMITTED;
    },

    agreementSelectDefaultItems() {
      return this.contract?.agreements ? [...this.contract.agreements] : [];
    },

    toPayDetailsDisabledFields() {
      return !this.isPendingStatus ? [
        'creditor',
        'amount',
        'add',
        'delete',
      ] : [];
    },
  },

  components: { AgreementSelect, ContractPayDetailsInput, StatusSelect, RichTextarea },
};
</script>

<style lang="sass" scoped>
</style>