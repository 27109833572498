import formatDate from '@/filters/formatDate';
import compact from 'lodash/compact';

export default formalization => {
  const { mortgageNumber, attestationDate, blockCity } = formalization;

  return compact([
    attestationDate && formatDate(attestationDate, 'YYYY'),
    mortgageNumber,
    blockCity,
  ]).join('-');
};
