<template lang="pug">
  .data-card
    .data-card__title Resumen de operación
    .data-card__body
      div(:class="b('name')") {{ customerFullName }} - {{ proposal.customer.vat }}
      v-layout(wrap)
        v-flex(xs6)
          .data-row
            b Crédito total (incluido gastos):&nbsp;
            span {{ totalCredit | formatCurrency(proposal.currency) }}
          .data-row(v-if="hasValidGraceDays")
            b Capitalización por días de gracia:&nbsp;
            span {{ capitalizationGraceDays | formatCurrency(proposal.currency) }}
          .data-row
            b Monto solicitado:&nbsp;
            span {{ proposal.credit | formatCurrency(proposal.currency) }}
          .data-row
            b Plazo:&nbsp;
            span {{ proposal.limitMonths }} meses
          //- .data-row
          //-   b Tasa:&nbsp;
          //-   span {{ $t(`rateType.${proposal.rateType}`)}}
          .data-row(v-if="dates")
            b Fecha de desembolso:&nbsp;
            span {{ dates.initDay | formatDate('DD MMMM YYYY') }}
          .data-row(v-if="dates")
            b Fecha de primera cuota:&nbsp;
            span {{ dates.payDay | formatDate('DD MMMM YYYY') }}
          .data-row(v-if="hasValidGraceDays")
            b Días de gracia:&nbsp;
            span {{ graceDays }}
          .data-row(v-if="openingCommission")
            b Costos de apertura, registrales y notariales:&nbsp;
            span {{ openingCommission }}
        v-flex(xs6)
          .data-row
            b Cuota Simple:&nbsp;
            span {{ simpleFee | formatCurrency(proposal.currency) }}
          .data-row(v-if="Boolean(doubleFee)")
            b Cuota Doble:&nbsp;
            span {{ doubleFee | formatCurrency(proposal.currency) }}
          .data-row
            b {{ $t('tea') }}:&nbsp;
            span {{ tea ? tea : proposal.tea }}%
          .data-row
            b {{ $t('tcea') }}:&nbsp;
            span {{ tcea ? tcea : proposal.tcea }}%
          .data-row
            b Mensualidad:&nbsp;
            span {{ $t(`amortization.${proposal.amortizationType}` )}}
            span(v-if="proposal.balloonPaymentType") &nbsp;({{ proposal.balloonPaymentType | balloonPaymentType }})
          .data-row
            b Moneda:&nbsp;
            span {{ proposal.currency.name }}
</template>

<script>
import moment from 'moment';
import balloonPaymentType from '@/filters/balloon-payment-type';

export default {
  name: 'proposal-card',

  props: {
    proposal: { type: Object },
    dates: { type: Object },
    simpleFee: { type: Number },
    doubleFee: { type: Number },
    tea: { type: Number },
    tcea: { type: Number },
    schedule: { type: Array, default: () => [] },
  },

  computed: {
    openingCommission() {
      return this.proposal.openingCommission?.openingCommission > 0 ? 'Financiados' : null;
    },

    administrationExpenses() {
      return this.proposal.openingCommission?.administrationExpenses;
    },

    totalCredit() {
      return this.proposal?.credit + this.proposal?.openingCommission?.openingCommission
    },

    customerFullName() {
      return `${this.proposal.customer.firstName} ${this.proposal.customer.lastName}`;
    },

    capitalizationGraceDays() {
      return this.schedule.reduce((acc, { amortization }) => acc + amortization, 0) - this.totalCredit;
    },

    graceDays() {
      return moment(this.dates.payDay).diff(this.dates.initDay, 'days') - 30;
    },

    hasValidGraceDays() {
      return this.graceDays > 0 && this.capitalizationGraceDays > 0;
    }
  },

  filters: { balloonPaymentType },
};
</script>

<style lang="sass" scoped>
  .proposal-card
    &__name
      font-size: 19px
      font-weight: 500
      margin-bottom: 8px
      text-align: center
</style>
