<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="400")
    .font-weight-bold.text-center.mb-2 Agregar Requisito
    requirement-select(
      v-model="selected"
      :exclude-ids="excludeIds"
      :exclude-stage-requirements="excludeStageRequirements"
    )
    v-divider
    v-card-actions
      v-spacer
      v-btn(color="error" text @click="$emit('input', false)") Cancelar
      v-btn(
        :loading="requesting"
        :disabled="!selected"
        color="primary"
        text
        @click="onSubmit"
      ) Agregar
</template>

<script>
  import FormModal from './base-form-modal';
  import RequirementSelect from '@/components/inputs/requirement-select';

  export default {
    name: 'income-form-modal',

    props: {
      value: { type: Boolean },
      requesting: { type: Boolean },
      excludeIds: { type: Array },
      excludeStageRequirements: { type: Boolean, default: true },
    },

    data() {
      return {
        selected: null,
      };
    },

    components: { RequirementSelect, FormModal },

    methods: {
      onSubmit() {
        this.$emit('submit', { id: this.selected });
      },
    },
  };
</script>
