<template lang="pug">
  v-app
    loading-view(v-if="!currentUser || $apollo.queries.currentUser.loading")
    v-main(v-else)
      router-view
</template>

<script>
  import currentUserMixin from '@/mixins/current-user-mixin';

  import LoadingView from './dashboard/components/loading-view';
  import protectedLayoutMixin from './mixins/protected-layout-mixin';

  export default {
    name: 'bare-protected-layout',

    mixins: [
      currentUserMixin(),
      protectedLayoutMixin(),
    ],

    components: { LoadingView },
  };
</script>

<style lang="sass" scoped>
</style>