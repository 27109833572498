<template lang="pug">
  div(:class="b()")
    div(:class="b('shared-data')" v-if="proposals.length")
      div(:class="b('shared-data-item')")
        div(:class="b('shared-data-item-value')") {{ firstProposal.credit | formatCurrency(firstProposal.currency) }}
        div(:class="b('shared-data-item-title')")  Crédito Solicitado
      div(:class="b('shared-data-item')")
        div(:class="b('shared-data-item-value')") {{ firstProposal.propertyValue | formatCurrency(firstProposal.currency) }}
        div(:class="b('shared-data-item-title')")  Propiedad
      div(:class="b('shared-data-item')")
        div(:class="b('shared-data-item-value')") {{ firstProposal.limitMonths }} meses
        div(:class="b('shared-data-item-title')")  Plazo

    div(:class="b('proposals-container')").pt-2
      ghost-simulator-proposals-item(
        :class="b('proposal')"
        v-for="proposal in proposals"
        :key="proposal.id"
        :proposal="proposal"
      )
</template>

<script>
import GhostSimulatorProposalsItem from './ghost-simulator-proposals-item';

export default {
  name: 'ghost-simulator-proposals',

  props: {
    proposals: { type: Array, default: () => [] },
  },

  computed: {
    firstProposal() {
      return this.proposals?.[0];
    },
  },

  components: { GhostSimulatorProposalsItem },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .ghost-simulator-proposals
    &__shared-data
      padding: 8px 16px

      background-color: darken(white, 5%)
      border-radius: 0px 0px 12px 12px

      &-item
        display: flex
        align-items: center

        &-value
          font-size: 20px
          font-weight: 500
          margin-right: 8px

        &-title
          font-size: 14px


    &__proposal
      border-bottom: 1px solid darken(white, 6%)

      &:last-child
        border-bottom: 0

    +lg
      &__shared-data
        display: flex
        justify-content: space-around
        max-width: 800px
        padding: 8px 0px
        margin: 0 auto

        &-item-value
          font-size: 16px

        &-item-title
          font-size: 13px

      &__proposals-container
        display: flex
        flex-direction: column
        align-items: center

</style>