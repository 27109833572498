<template lang="pug">
  li(:class="b()") {{ entity.description }}
    read-more
      template(v-slot:trigger="{ open }")
        v-btn(text color="primary")
          v-icon {{ open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          span {{ open ? 'Ocultar' : 'Mostrar' }} periodos
      table(:class="b('periods')" style="margin: 8px 0")
        thead
          tr
            th Periodo
            th Balance
            th Calificación
        tbody
          entity-period(
            v-for="period in periods"
            :key="period.id"
            :period="period"
          )
</template>

<script>
import EntityPeriod from './guarantees-dialog-entity-period';
import ReadMore from '@/components/wrappers/read-more';

export default {
  name: 'guarantees-dialog-entity',

  props: {
    entity: { type: Object },
  },

  computed: {
    periods() {
      return this.entity.guaranteeEntityPeriods.filter(({ balance }) => Boolean(balance));
    },
  },

  components: { EntityPeriod, ReadMore },
};
</script>

<style lang="sass">
  .guarantees-dialog-entity
    table
      thead
        background-color: darken(white, 6%)

      td, th
        padding: 2px 4px
        border-bottom: 1px solid darken(white, 6%)

</style>