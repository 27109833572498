<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    reminder-form(
      v-if="value"
      :reminder="reminder"
      :requesting="requesting"
      :current-user-id="currentUserId"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import ReminderForm from '@/components/forms/reminder-form';

  export default {
    name: 'reminder-form-modal',

    mixins: [formModalMixin({ entityName: 'reminder' })],

    props: {
      currentUserId: { type: String },
    },

    components: { ReminderForm },
  };
</script>
