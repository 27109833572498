<template lang="pug">
  div(:class="b()")
    appraisals-list-item(
      v-for="appraisal in appraisals"
      :key="appraisal.id"
      :appraisal="appraisal"
      @click-edit="({ appraisal }) => $emit('click-edit', appraisal)"
    )
    v-alert(v-if="!appraisals.length" color="info" outlined)
      div No hay registradas tasaciones aún
      .d-flex.justify-center.mt-1
        v-btn(color="primary" @click="onClickAdd").ml-2
          v-icon mdi-plus
          span Agregar
</template>

<script>
import AppraisalsListItem from './appraisals-list-item';

export default {
  name: 'appraisals-list',

  props: {
    appraisals: { type: Array, default: () => [] },
  },

  methods: {
    onClickAdd() {
      this.$emit('click-add');
    },
  },

  components: { AppraisalsListItem },
};
</script>

<style lang="sass" scoped>

</style>