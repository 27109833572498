import { BUY_MORTGAGE_SLUG, NEW_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

export default {
  COUNTRY_CODE: 'co',

  /**
   * FEATURES
   */
  CREDIT_HIDDEN_FIELDS: ['doubleFee', 'amortizationType'],
  RCI_ENABLED: false,

  /**
   * UNIQUE VALUES
   */
  DEFAULT_CURRENCY_ID: '3',
  PHONE_CODE: 57,
  GROUPS_INVESTOR_ID: process.env.VUE_APP_GROUPS_INVESTOR_ID || '8',

  /**
   * THEMING
   */
  LOCALE: 'es-CO',
  FORM_URL: 'https://rebajatuscuentas.com/co/formulario',
  CRM_FUNNEL_LABELS: [
    { title: 'Funnel de Ventas', color: '#f44336', size: 2 },
    { title: 'Integración', color: '#f44336', size: 1 },
    { title: 'Autorización', color: '#ffc107', size: 1 },
    { title: 'Avalúo', color: '#ffc107', size: 1 },
    { title: 'Notaría/Venta', color: '#4caf50', size: 1 },
  ],

  /**
   * SELECTS
   */
  CURRENCIES_SELECT: [
    { text: 'Pesos', id: '3', symbol: '$' },
  ],

  PRODUCTS_SELECT: [
    { value: BUY_MORTGAGE_SLUG, text: 'Compra de cartera hipotecaria' },
    { value: NEW_MORTGAGE_SLUG, text: 'Nuevo crédito hipotecario' },
  ],

  INCOME_CATEGORIES_SELECT: [
    { text: 'Empleado', value: '1' },
    { text: 'Independiente', value: '2' },
    { text: 'Pensionado', value: '3' },
    { text: 'Rentista', value: '4' },
    { text: 'Prestación de servicios', value: '5' },
    { text: 'Transportador', value: '6' },
  ],

  /**
   * Render & Hiddeners
   */

  PRODUCT_FILTER_FIELDS: slug => {
    switch (slug) {
      case BUY_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'insuranceTypeId', 'limitMonths'];
      case NEW_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'totalIncoming', 'propertyValue', 'insuranceTypeId', 'openAmount', 'limitMonths']
      case HOME_EQUITY_LOAN_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'totalIncoming', 'creditAmount', 'propertyValue', 'insuranceTypeId', 'limitMonths']
      default:
        return [];
    }
  },


  /**
   * VALIDATIONS
   */
  PHONE_VALIDATION: {
    REGXP: /^573[0-9]{9}$/,
    MESSAGE: 'El campo {_field_} debe tener el formato +(57)3#########',
  },

  CUSTOMER_FORM_RULES: () => ({
    firstName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    maternalLastName: { alpha_spaces: true, min: 3, max: 50 },
    email: {
      required: true,
      email: true,
    },
    mobile: {
      phone_number: true,
    },
    vat: {
      required: true,
      alpha_num: true,
      min: 7,
      max: 10,
    },
    address: {
      zipCode: { numeric: true },
    },
  }),

  BURO_FORM_RULES: () => ({
    firstName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    maternalLastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    vatTypeId: { required: true },
    vat: { required: true, alpha_num: true, min: 7, max: 10 },
    birth: { required: true },
    mobile: { phone_number: true },
    address: {
      zipCode: { required: true, numeric: true },
    },
  }),


  PROPERTY_FORM_RULES: ({ data: { value }}) => ({
    value: {
      required: true,
      decimal: 2,
      min_value: 5e7,
      max_value: 6e9,
    },
    auctionValue: {
      decimal: 2,
      min_value: 4e4,
      max_value: value * 0.7,
    },
    yearOfConstruction: { min_value: 1900, max_value: new Date().getFullYear() },
    address: {
      zipCode: { numeric: true },
    },
  }),

  INCOME_FORM_RULES: ({ data: { amount }, hasWorkplaceData }) => ({
    amount: {
      decimal: 2,
      min_value: 828116,
      max_value: 16e7,
    },
    netAmount: {
      required: true,
      decimal: 2,
      max_value: Number(amount),
    },
    workPlace: hasWorkplaceData ? {
      name: { required: true, min: 3, max: 50 },
      vat: { required: true },
    } : {
      name: null,
      vat: null,
    },
  }),

  CREDIT_FORM_RULES: ({ data: { tea }}) => ({
    bankId: { required: true },
    insuranceTypeId: { required: true },
    credit: { required: true, decimal: 2, min_value: 1e7, max: 48e8 },
    rateType: { required: true },
    tea: { decimal: 2, min_value: 0, max_value: 18 },
    tcea: { decimal: 2, min_value: tea },
    simpleFee: { required: true, decimal: 2, min_value: 4e5, max_value: 3e7 },
    limitMonths: { required: true, numeric: true, min_value: 60, max_value: 240 },
  }),
};
