<template lang="pug">
  titled-card(
    :title="title"
    :count="incomes.length"
    icon="mdi-currency-usd"
  )
    incomes-list(:incomes="incomes" :riskFileId="riskFileId" :isApproved="getRiskFileIsApproved")
</template>

<script>
  import compact from 'lodash/compact';
  import FormModal from '@/components/modals/income-form-modal';

  import IncomesList from './incomes-list';
  import TitledCard from '@/components/UI/titled-card';

  import { RISK_OPINION_STATUS } from '@/utils/constants';

  export default {
    name: 'incomes',

    props: {
      customer: { type: Object, required: true },
      requesting: { type: Boolean, default: false },
      riskFileId: { type: String, default: '' },
      status: { type: String, default: '' },
    },

    components: { TitledCard, IncomesList, FormModal },

    computed: {
      incomes() {
        return this.customer.incomes || [];
      },

      title() {
        return compact([
          'Ingresos de',
          this.customer.firstName,
          this.customer.lastName,
        ]).join(' ');
      },

      getRiskFileIsApproved() {
      return RISK_OPINION_STATUS[this.status] === RISK_OPINION_STATUS.APPROVED;
    },
    },
  };
</script>
