import Incomes from '@/components/UI/incomes';

import {
  CreateIncomeMutation,
  UpdateIncomeMutation,
  DeleteIncomeMutation,
} from './graphql/mutations.gql';

export default ({
  getQueryMethod = 'getCustomerQuery'
} = {}) => ({
  data() {
    return {
      incomeIsMutating: false,
    };
  },

  methods: {
    $_customerIncomesMixin_closeForms() {
      this.$refs.incomes.closeForm();
      this.$refs.coupleIncomes?.closeForm();
    },

    onCreateIncome({ income, customerId, isCouple = false }) {
      const updateQueryFnPayload = (newIncome) => isCouple ? ({
        customer: { couple: { incomes: { $push: [newIncome] } }}
      }) : ({
        customer: { incomes: { $push: [newIncome] } }
      });

      this.handleCreateMutation({
        mutation: CreateIncomeMutation,

        variables: { data: { ...income, userId: customerId } },

        query: this[getQueryMethod](),

        updateQueryFn: ({
          resultsData: { createIncome: { income: newIncome }},
        }) => newIncome ? updateQueryFnPayload(newIncome) : null,

        requestingStateKey: 'incomeIsMutating',
      }).then(this.$_customerIncomesMixin_closeForms);
    },

    onUpdateIncome({ income, isCouple = false }) {
      const updateQueryFnPayload = (updatedIncome) => isCouple ? ({
        customer: {
          couple: {
            incomes: {
              $apply: incomes => incomes.map(
                cIncome => cIncome.id === income.id ? updatedIncome : cIncome,
              )
            },
          },
        },
      }) : ({
        customer: {
          incomes: {
            $apply: incomes => incomes.map(
              cIncome => cIncome.id === income.id ? updatedIncome : cIncome,
            )
          },
        }
      });

      this.handleUpdateMutation({
        mutation: UpdateIncomeMutation,

        variables: { data: { ...income } },

        query: this[getQueryMethod](),

        updateQueryFn: ({
          resultsData: { updateIncome: { income: updatedIncome }}
        }) => updatedIncome ? updateQueryFnPayload(updatedIncome) : null,

        requestingStateKey: 'incomeIsMutating',
      }).then(this.$_customerIncomesMixin_closeForms);
    },

    onDeleteIncome({ income, isCouple = false }) {
      this.handleDeleteMutation({
        mutation: DeleteIncomeMutation,

        variables: { id: income.id },

        query: this[getQueryMethod](),

        updateQueryFn: () => isCouple ? ({
          customer: {
            couple: {
              incomes: {
                $apply: incomes => incomes.filter(cIncome => cIncome.id !== income.id ),
              },
            },
          },
        }) : ({
          customer: {
            incomes: {
              $apply: incomes => incomes.filter(cIncome => cIncome.id !== income.id ),
            },
          }
        }),
      });
    },
  },

  components: {
    Incomes,
  },
});