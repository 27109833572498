<template lang="pug">
  v-layout(:class="b('attachments')" wrap)
    file-item(
      v-for="file in files"
      :key="file.id"
      :file="file"
    )
</template>

<script>
  import FileItem from './file-list-item';

  export default {
    name: 'file-list',

    props: {
      files: { type: Array, default: () => [] },
    },

    components: { FileItem },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .file-list
    display: flex
    align-items: center
    flex-wrap: wrap
</style>