<template lang="pug">
  div(:class="b()")
    v-breadcrumbs(:items="breadcrumbItems")

    loading-wrapper(
      v-if="$apollo.queries.riskFile.loading || riskFileError || !riskFile"
      :error="riskFileError"
      @retry="onRetryRiskFile"
    )
    v-container(v-else fluid)
      opinion-form-modal(
        v-model="opinionFormModal"
        :opinion="opinionFormDefaultValues"
        :opinions="riskFile.opinions"
        :currency="currency"
        :variables="riskFile.customer"
        :requesting="opinionsIsMutating"
        :totalIncomesResult="totalIncomesResult"
        :totalExpensesResult="totalExpensesResult"
        :totalExpensesPTIResult="totalExpensesPTIResult"
        @submit="onSubmitOpinionForm"
      )
      .d-flex.flex-wrap.align-center.justify-space-between.px-2
        v-chip(color="primary" text-color="white").mb-2 {{ $t(`productSlug.${riskFile.product.slug}`) }}
        div(:class="b('mkt')")
          div(v-if="riskFile.source")
            b Source:
            span {{ riskFile.source }}
          div(v-if="riskFile.medium")
            b Medium:
            span {{ riskFile.medium }}
          div(v-if="riskFile.campaign")
            b Campaign
            span {{ riskFile.campaign }}
        div(v-if="riskFile.interview1")
          b Fecha de entrevista 1: {{riskFile.interview1 | formatDateTime}}
        div(v-if="riskFile.interview2")
          b Fecha de entrevista 2: {{riskFile.interview2 | formatDateTime}}
        div(:class="b('request-number')").mb-2
          router-link(:class="b('customer-name')" :to="{ name: 'deals.show', params: { dealId: riskFile.dealId }}")
            | Solicitud # {{ riskFile.dealId }}
      v-layout(wrap)
        v-flex(xs12 lg4).pa-2
          risk-files-history-card(:risk-files="riskFilesHistory")
        v-flex(xs12 lg8).pa-2
          risk-summary-card(
            :risk-file="riskFile"
            @click-opinion="onClickOpinion"
          )
        v-flex(xs3).pa-2
          analyst-select(
            v-if="riskFile.riskAnalyst && riskFile.riskAnalyst.id"
            v-model="riskFile.riskAnalyst.id"
            label="Analista"
            @change="onChangeRiskAnalyst"
            :default-items="[riskFile.riskAnalyst]"
          )
          analyst-select(
            v-else="!riskFile.riskAnalyst"
            label="Analista"
            @change="onChangeRiskAnalyst"
          )
        v-flex(xs2).pa-2
          v-btn(
            color="primary"
            @click="onClickAssignToMe"
          ) Asígnamelo
        v-flex(v-if="riskFile.lastOpinion && riskFile.lastOpinion.user" :class="b('last-opinion')" xs6).pa-2
          | Dictaminado el&nbsp;
          span.font-weight-medium {{ riskFile.lastOpinion.createdAt | formatDate }}&nbsp;
          | por&nbsp;
          span.font-weight-bold {{ riskFile.lastOpinion.user.firstName }} {{ riskFile.lastOpinion.user.lastName }}
        v-flex(xs12)
          v-layout(wrap)
            v-flex(xs12 md7)
              div(ref="summaryContainer")
                customer-card(
                  :customer="customer"
                  :profile-link="false"
                  label="Titular"
                  extended
                )
                customer-card(
                  v-if="hasCouple"
                  :customer="couple"
                  :profile-link="false"
                  label="Pareja"
                  extended
                )
                risk-file-description(:description="riskFile.description")
                selected-debts(
                  v-if="selectedDebts.length"
                  :debts="selectedDebts"
                )
            v-flex(:class="b('messages')" xs12 md5).pl-2
              div(ref="messagesContainer")
                v-card(style="height: 100%").pa-3
                  messages(
                    ref="messages"
                    :requesting="isCreatingMessage"
                    :messages="riskFile.messages"
                    :current-user="currentUser"
                    :mention-groups-ids="[$projectConfig.GROUPS_RISK_ID]"
                    @hook:mounted="setCalculatedMessagesHeight"
                    @create="onCreateMessage"
                    @delete="onDeleteMessage"
                  )
        //-- Componente padres del Popup de modificación de cuentas a pagar
        //-- button(@click="openPopup") click 2 **: Un botón que abre el popup al hacer clic, llamando al método `openPopup`
        v-flex(xs2).pa-2
          v-btn(
            color="primary"
            @click="openPopup"
            :disabled="this.riskFile.status === 'APPROVED' || this.riskFile.status === 'REFUSED'"
          ) Actualizar Deudas a Pagar
          popup(:isVisible="isPopupVisible" :debts="this.buros" :riksFile="this.riskFile" @close="isPopupVisible = false")
        v-flex(xs12)
          div(:class="b('title')") Ingresos
        v-flex(xs12 lg6)
          incomes-card(:customer="customer" :riskFileId="riskFileId" :status="riskFile.status")
        v-flex(xs12 lg6)
          incomes-card(v-if="hasCouple" :customer="couple" :riskFileId="riskFileId" :status="riskFile.status")
        v-flex(xs12 lg6)
          titled-card(
            title="DTI y PTI Presentado"
          )
            rci(
              :init-values="rciAdviser"
              :status="riskFile.status"
              disabled
            )
        v-flex(xs12 lg6)
          titled-card(
            title="DTI y PTI Riesgo"
          )
            rci(
              :init-values="rciValuesRiskAgent"
              :totalExpensesResult="totalExpensesResult"
              @change="onChangeRciAgent"
              @totalIncomesResult="totalIncomesResultHandler"
              @totalExpensesResult="totalExpensesResultHandler"
              @totalExpensesPTIResult="totalExpensesPTIResultHandler"
              :riskFileId="riskFile.id"
              :status="riskFile.status"
              risk
            )
        v-flex(
          v-if="buros.length || customerReports && customerReports.incomeReports && customerReports.incomeReports.length || customerReports && customerReports.taxReport || coupleReports && coupleReports.incomeReports && coupleReports.incomeReports.length || coupleReports && coupleReports.taxReport"
          xs12
        ).pa-2
          v-card(:class="b()").mb-3
            buro-and-reports-tabs(
              :buros="buros"
              :customer-reports="customerReports"
              :couple-reports="coupleReports"
              :customerId="customer?.dsId"
              :variables="riskFile.customer"
              :variables-couple="riskFile.customer.couple"
            )
        v-flex(xs12 v-if="firstPaymentDate").pa-2
          v-chip(color="primary" text-color="white")
            span Fecha de primera cuota:&nbsp;
            b {{ firstPaymentDate | formatDate('DD MMMM YYYY')}}
        v-flex(xs12 lg4).pa-1
          titled-card(
            title="Documentos"
          )
            v-tabs(v-model="currentDocumentsTab")
              v-tab Documentos Titular
              v-tab(v-if="couple") Documentos Pareja
              v-tab-item
                customer-documents(
                  :customer-id="customer && customer.dsId"
                  :gestionable="false"
                  ref="documents"
                )
              v-tab-item
                customer-documents(
                  :customer-id="couple.dsId"
                  :gestionable="false"
                  ref="documents"
                )
        v-flex(xs12 lg8).pa-1
          referrals-card(:referrals="referrals")
          titled-card(
            title="Simulator Hipotecario"
          )
            ghost-simulator(
              :filters="ghostFiltersDefaultValues"
              :property-value="propertyValue"
              :proposals="ghostProposals"
              :fetching="ghostSimulatorIsMutating"
              @submit-filters="onSubmitGhostSimulatorFilters"
            )

</template>

<script>
import popup from './components/selected-debts-popup/popup.vue';
import omit from 'lodash/omit';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import humps from 'humps';
import debounce from 'lodash/debounce';

import {
  parse as parseRCIJson,
  unparse as unparseRCIJson,
} from '@/components/smart/debts/utils/rci-json-parser';
import { FEE_TYPE, RISK_OPINION_STATUS } from '@/utils/constants';
import TitledCard from '@/components/UI/titled-card';
import Rci from '@/components/UI/rci';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import CustomerDocuments from '@/components/smart/customer-documents';
import Messages from '@/components/UI/messages';
import insuranceTypeIdFromSlug from '@/utils/insurance-type-id-from-slug';

import { RiskFileQuery, RiskFilesHistory, ReportsQuery } from './graphql/queries.gql';
import {
  CreateRiskOpinionMutation,
  GenerateGhostProposalsMutation,
  CreateRiskFileMessageMutation,
  DeleteRiskFileMessageMutation,
  DoUpdateRiskFile,
} from './graphql/mutations.gql';
import CustomerCard from './components/customer-card';
import IncomesCard from './components/incomes-card';
import OpinionFormModal from './components/opinion-form-modal';
import RiskFileDescription from './components/risk-file-description';
import RiskFilesHistoryCard from './components/risk-files-history-card'
import RiskSummaryCard from './components/risk-summary-card';
import BuroAndReportsTabs from './components/buro-and-reports-tabs';
import GhostSimulator from './components/ghost-simulator';
import SelectedDebts from './components/selected-debts';
import ReferralsCard from './components/referrals-card';
import AnalystSelect from '@/components/inputs/analyst-select';
const MIN_MESSAGES_HEIGHT = 600;

export default {
  name: 'risk-files-show-view',

  mixins: [
    mutationsHandlersMixin(),
    currentUserMixin(),
  ],

  apollo: {
    riskFile: {
      query: RiskFileQuery,

      errorPolicy: 'ignore',

      variables() {
        return {
          id: this.riskFileId,
        };
      },

      error() {
        this.riskFileError = new Error('Ha ocurrido un error al obtener los datos');
      }
    },

    riskFilesHistory: {
      query: RiskFilesHistory,
      manual: true,

      variables() {
        return {
          dealId: this.riskFile.dealId
        };
      },

      result({ data }) {
        this.riskFilesHistory = (data?.riskFiles?.objects || this.riskFilesHistory)
          .filter(({ id, status }) => id !== this.riskFileId && status !== RISK_OPINION_STATUS.FAILED);
      },

      skip() {
        return !this.riskFile?.dealId;
      },
    },

    customerReports: {
      query: ReportsQuery,

      variables() {
        return {
          userId: this.riskFile.customer.dsId,
        };
      },

      update({ reports }) {
        return reports ? humps.camelizeKeys(reports.response) : null;
      },

      skip() {
        return !this.riskFile?.customer?.dsId;
      },
    },

    coupleReports: {
      query: ReportsQuery,

      variables() {
        return {
          userId: this.couple.dsId,
        };
      },

      update({ reports }) {
        return reports ? humps.camelizeKeys(reports.response) : null;
      },

      skip() {
        return !this.couple?.dsId
      },
    }
  },

  data() {
    return {
      currentTab: 0,
      riskFile: null,
      riskFileError: null,
      riskFilesHistory: [],
      ghostProposals: [],
      rciAgent: null,
      currentDocumentsTab: 0,
      opinionsIsMutating: false,
      ghostSimulatorIsMutating: false,
      opinionFormModal: false,
      isCreatingMessage: false,
      customerReports: null,
      coupleReports: null,
      totalIncomesResult: 0,
      totalExpensesResult: 0,
      totalExpensesPTIResult: 0,
      selectedId: "",
      riskFormModal: false,
      showRiskFormModal: false,
      isPopupVisible: false,
    };
  },

  computed: {
    riskFileId() {
      return this.$route.params.riskFileId;
    },

    breadcrumbItems() {
      return [
        { text: 'Riesgos', to: { name: 'risk_files.list' }, exact: true },
        { text: 'File', disabled: true }
      ];
    },

    customer() {
      return omit(this.riskFile?.customer, ['couple']);
    },

    couple() {
      return omit(this.riskFile?.customer?.couple, ['couple']);
    },

    buros() {
      return compact([
        this.customer?.buroData && { title: 'Buro (Titular)', results: this.customer.buroData },
        this.couple?.buroData && { title: 'Buro (Pareja)', results: this.couple.buroData },
        ...[...this.customer?.incomes || [], ...this.couple?.incomes || []]
          .filter(({ buroData }) => Boolean(buroData))
          .map((income) => ({
            title: income.workPlaceName,
            results: income.buroData
          }))
      ]).map((buro) => ({ ...buro, results: humps.camelizeKeys(buro.results) }));
    },

    hasCouple() {
      return !isEmpty(this.couple);
    },

    rciAdviser() {
      return this.riskFile?.rciAdviser ? parseRCIJson(this.riskFile.rciAdviser) : null;
    },

    rciValuesRiskAgent() {
      if ( this.riskFile?.rciRiskAgent ) {
        const data = parseRCIJson(this.riskFile.rciRiskAgent);
        data.incomes.main.variable = "0.00"
        data.incomes.couple.variable = "0.00"
        const mainSumIncomes = this.riskFile?.customer?.incomes?.reduce((acc, income) => acc + parseFloat(income.analystAmount), 0) || 0;
        const coupleSumIncomes = this.riskFile?.customer?.couple?.incomes?.reduce((acc, income) => acc + parseFloat(income.analystAmount), 0) || 0;
        data.incomes.main.fixed = mainSumIncomes.toFixed(2);
        data.incomes.couple.fixed = coupleSumIncomes.toFixed(2);
        return data;
      } 
      return null;
    },

    opinionFormDefaultValues() {
      return {
        rciMax: this.rciAgent?.rci,
        simpleFeeMax: this.rciAgent?.values?.product?.fee,
        ltvMax: this.riskFile?.loanToValueAuction,
        DTI: this.dti,
        PTI: this.pti,
      };
    },

    ghostFiltersDefaultValues() {
      return {
        limitMonths: this.customer?.proposal?.months,
        creditAmount: this.customer?.proposal?.amount,
        tea: this.customer?.proposal?.tea,
      };
    },

    selectedDebts() {
      return this.riskFile?.riskAssessmentRequest?.personalStep?.selectedDebts || [];
    },

    currency() {
      return this.riskFile?.customer?.proposal?.currency;
    },

    propertyValue() {
      return this.customer?.property?.commercialValue;
    },

    firstPaymentDate() {
      return this.riskFile?.riskAssessmentRequest?.riskFile?.firstPaymentDate;
    },

    referrals() {
      return this.riskFile?.riskAssessmentRequest?.riskFile?.referrals || [];
    },

    dti() {
      return Number(((this.totalExpensesResult + this.totalExpensesPTIResult) / this.totalIncomesResult * 100).toFixed(2)) || 0;
    },

    pti() {
      return Number((this.totalExpensesPTIResult / this.totalIncomesResult * 100).toFixed(2)) || 0;
    },

  },

  methods: {

    openPopup() {
      // Con el Objecto "this.buros" puedo pasar todo el objecto por el props que voy pasar la información
      this.isPopupVisible = true;
    },

    getRiskFileQuery() {
      return {
        query: RiskFileQuery,
        variables: { id: this.riskFileId },
      };
    },

    onChangeRciAgent(rciData) {
      this.rciAgent = rciData;
    },

    onChangeRiskAnalyst(value) {
      this.handleUpdateMutation({
        mutation: DoUpdateRiskFile,

        variables: {
          id: this.riskFile.id,
          riskAnalystId: value ,
        },
      })
    },

    onClickAssignToMe() {
      this.handleUpdateMutation({
        mutation: DoUpdateRiskFile,

        variables: {
          id: this.riskFile.id,
        },
      }).then((res) => {
        this.riskFile.riskAnalyst = res.data.updateRiskFile.riskFile.riskAnalyst;
      });
    },

    analystInitialValue() {
      return this.riskFile?.riskAnalyst?.id || null;
    },

    onClickOpinion() {
      this.opinionFormModal = true;
    },

    createRiskFormModal() {
      this.riskFormModal = true;
    },

    onRetryRiskFile() {
      this.riskFileError = null;
      this.$apollo.queries.riskFile.refetch();
    },

    onSubmitOpinionForm({ opinion }) {
      this.handleCreateMutation({
        mutation: CreateRiskOpinionMutation,

        variables: {
          ...opinion,
          rciAgent: unparseRCIJson(this.rciAgent.values),
          riskFileId: this.riskFileId,
          simpleFeeMax: parseFloat(this.rciAgent?.values?.product?.fee),
          monthlyPayment: parseFloat(this.totalExpensesResult),
          netIncome: parseFloat(this.totalIncomesResult),
          DTI: parseFloat(this.dti),
          PTI: parseFloat(this.pti),
        },

        query: this.getRiskFileQuery(),

        updateQueryFn: ({
          resultsData: { createRiskOpinion: { opinion: createdOpinion } }
        }) => createdOpinion ? ({
          riskFile: {
            status: { $set: createdOpinion.status },
            lastOpinion: { $set: createdOpinion },
            opinions: { $push: [createdOpinion] }
          },
        }) : null,

        requestingStateKey: 'opinionsIsMutating',
      }).then(() => {
        this.opinionFormModal = false;
      });
    },

    onSubmitGhostSimulatorFilters({ filters }) {
      this.handleUpdateMutation({
        mutation: GenerateGhostProposalsMutation,

        variables: {
          ...filters,
          currencyId: '1',
          feeType: FEE_TYPE.SIMPLE,
          insuranceTypeId: insuranceTypeIdFromSlug(this.customer?.proposal?.insuranceTypeModeName) || '2',
          customerId: this.customer?.dsId,
          customArguments: {
            bankId: 41,
            rate: filters.tea,
            balloon: filters.balloon
          }
        },

        requestingStateKey: 'ghostSimulatorIsMutating',
      }).then(({ data: { generateProposals } }) => {
        if (generateProposals?.proposals)
          this.ghostProposals = generateProposals.proposals;
      });
    },

    onCreateMessage({ body }) {
      this.handleUpdateMutation({
        mutation: CreateRiskFileMessageMutation,

        requestingStateKey: 'isCreatingMessage',

        variables: {
          riskFileId: this.riskFile.id,
          body,
        },

        query: this.getRiskFileQuery(),

        updateQueryFn: ({
          resultsData: { createRiskFileMessage: { message: createdMessage } }
        }) => createdMessage ? ({
          riskFile: {
            messages: { $unshift: [createdMessage] },
          },
        }) : null,
      }).then(() => {
        this.$refs.messages?.clearForm();

        this.$nextTick(() => {
          this.setCalculatedMessagesHeight();
        });
      }).catch(() => null);
    },

    onDeleteMessage({ message }) {
      this.handleDeleteMutation({
        mutation: DeleteRiskFileMessageMutation,


        variables: { id: message.id },

        query: this.getRiskFileQuery(),

        updateQueryFn: () => ({
          riskFile: {
            messages: {
              $apply: messages => messages.filter(cMessage => cMessage.id !== message.id),
            },
          },
        }),
      }).then(() => {
        this.$nextTick(() => {
          this.setCalculatedMessagesHeight();
        });
      });
    },

    onResize: debounce(function () {
      this.setCalculatedMessagesHeight();
    }, 300),

    setOnResizeListener() {
      window.addEventListener('resize', this.onResize.bind(this));
    },

    removeOnResizeListener() {
      window.removeEventListener('resize', this.onResize.bind(this));
    },

    setCalculatedMessagesHeight() {
      if (!this.$refs.summaryContainer || !this.$refs.messagesContainer) return;

      this.$refs.messagesContainer.style.height = 'auto';

      // En móbiles mantenemos el height auto
      if (window.innerWidth < 960) return;

      const summaryContainerHeight = this.$refs.summaryContainer.offsetHeight;
      const messagesContainerHeight = this.$refs.messagesContainer.offsetHeight;

      if (summaryContainerHeight < MIN_MESSAGES_HEIGHT && messagesContainerHeight > MIN_MESSAGES_HEIGHT)
        this.$refs.messagesContainer.style.height = `${MIN_MESSAGES_HEIGHT}px`;
      else if (summaryContainerHeight > messagesContainerHeight) return;
      else
        this.$refs.messagesContainer.style.height = `${summaryContainerHeight}px`;
    },

    totalIncomesResultHandler(value) {
      this.totalIncomesResult = value;
    },

    totalExpensesResultHandler(value) {
      this.totalExpensesResult = value;
    },

    totalExpensesPTIResultHandler(value) {
      this.totalExpensesPTIResult = value;
    },

  },

  components: {
    popup,
    BuroAndReportsTabs,
    CustomerCard,
    CustomerDocuments,
    IncomesCard,
    LoadingWrapper,
    Messages,
    OpinionFormModal,
    Rci,
    RiskFileDescription,
    RiskFilesHistoryCard,
    RiskSummaryCard,
    ReferralsCard,
    TitledCard,
    GhostSimulator,
    SelectedDebts,
    AnalystSelect,
  },

  mounted() {
    this.$nextTick(() => {
      this.setCalculatedMessagesHeight();

      this.setOnResizeListener();
    });
  },

  beforeDestroy() {
    this.removeOnResizeListener();
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .risk-files-show-view
    &__last-opinion
      font-size: 16px

      +lg
        text-align: right

    &__title
      font-size: 16px
      font-weight: bold
      margin-bottom: 8px

    &__request-number
      padding: 4px 14px
      margin-right: 8px

      color: white
      border: 2px solid lighten($primaryColor, 15%)
      color: lighten($primaryColor, 15%)
      background-color: lighten($primaryColor, 50%)

      font-size: 16px
      font-weight: 500

      border-radius: 6px
    &__mkt
      background-color: transparentize($primaryColor, 0.9)
      border-radius: 3px
      margin-top: 6px

      & > div
        padding: 2px 12px
        display: inline

        b
          margin-right: 6px
</style>
