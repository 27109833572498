<script>
import moment from 'moment';
import { Line } from 'vue-chartjs';
import { formatCurrency } from '@/filters/accounting';

export default {
  name: 'forecast-chart',

  extends: Line,

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {
    labels() {
      return this.entries.map(({ date }) => moment(date).format('MMM/YY'));
    },

    datasets() {
      return [
        {
          label: 'Cuota Promedio Teórica',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(3,80,111)',
          borderColor: 'rgb(3,80,111)',
          backgroundColor: 'rgba(3,80,111, 0.3)',
          data: this.entries.map(({ amountAvg }) => amountAvg),
        },
        {
          label: 'Recaudo Teórico',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(88,202,121)',
          borderColor: 'rgb(88,202,121)',
          backgroundColor: 'rgba(88,202,121, 0.3)',
          data: this.entries.map(({ amount }) => amount),
        },
        {
          label: 'Recaudo Real',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(189,32,0)',
          borderColor: 'rgb(189,32,0)',
          backgroundColor: 'rgba(189,32,0, 0.3)',
          data: this.entries.map(({ realAmount }) => realAmount),
        },
        {
          label: 'Interés Teórico',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(225,112,26)',
          borderColor: 'rgb(225,112,26)',
          backgroundColor: 'rgba(225,112,26, 0.3)',
          data: this.entries.map(({ interest }) => interest),
        },
        {
          label: 'Interés Real',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(134,122,233)',
          borderColor: 'rgb(134,122,233)',
          backgroundColor: 'rgba(134,122,233, 0.3)',
          data: this.entries.map(({ realInterest }) => realInterest),
        },
      ];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.datasets,
        },
        {
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  callback(value) {
                    return formatCurrency(value);
                  },
                },
              },
            ],
          },

          tooltips: {
            mode: 'label',
            callbacks: {
              label: ({ yLabel, datasetIndex }) => {
                return `${this.datasets[datasetIndex].label}: ${formatCurrency(
                  yLabel,
                )}`;
              },
            },
          },
        },
      );
    });
  },
};
</script>

<style lang="sass" scoped>
//.forecast-chart
</style>
