<template lang="pug">
  v-card(:class="b()").pa-3
    div(:class="b('header')")
      router-link(:class="b('customer-name')" :to="{ name: 'customers.show', params: { customerId: customer.id }}")
        | {{ customer.firstName }} {{ customer.lastName }}
    div(:class="b('product')") [{{ $t(`productSlug.${deal.product.slug}`) }}]
    v-layout(align-center)
      tag(v-if="deal.product.slug === 'buy_mortgage' && !mortgageInscribed" color="#9c8ade") no inscrita
      v-flex(v-if="deal.credit" :class="b('credit')") {{ deal.credit | formatCurrency(deal.currency) }}
    div(:class="b('data')")
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-label
          span Estado
        status-select(
          :class="b('compact-select')"
          :value="deal.status"
          :loading="requesting"
          @input="onChangeStatusSelect"
        )
      lost-reason(v-if="isLost") {{ deal.lostReason.reason }}
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-content-duplicate
          span Etapa
        stage-select(
          :class="b('compact-select')"
          :value="deal.currentStage.id"
          :default-items="[{ id: deal.currentStage.id, name: deal.currentStage.name }]"
          :loading="requesting"
          :product-slug="deal.product.slug"
          @input="onChangeStage"
        )
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-lightbulb-on
          span Destino del préstamo
        motive-select(
          v-if="canModifyMotive"
          :class="b('compact-select')"
          :value="deal.motive"
          :loading="requesting"
          @input="onChangeMotiveSelect"
        )
        span(v-else) {{ motive }}
      div(:class="b('data-row')")
        v-icon mdi-attachment
        component(
          :is="commercialFileUrl ? 'a' : 'span'"
          :href="commercialFileUrl"
          target="_blank"
          style="flex: 1"
        ) File Comercial
        v-btn(
          color="primary"
          text small
          :disabled="!canGenerateCommercialFile"
          @click="onClickGenerateCommercialFile"
        ) {{ commercialFileUrl ? 'REGENERAR' : 'GENERAR' }}
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on")  mdi-account-card-details
          span DNI
        span {{ customer.vat }}
      div(v-if="customer.mobile" :class="b('data-row')")
        v-icon  mdi-cellphone
        a(:href="`tel:${customer.mobile}`" target="_blank") {{ customer.mobile }}
        .spacer
        div(:class="b('data-row-actions')")
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              a(:href="`tel:${customer.mobile}`" v-on="on" target="_blank")
                v-icon mdi-phone-outgoing
            span Llamar
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              a(:href="whatsappLink" v-on="on" target="_blank")
                v-icon mdi-whatsapp
            span Enviar mensaje a whatsapp
      
      div(v-if="customer.phone" :class="b('data-row')")
        v-icon  mdi-phone
        a(:href="`tel:${customer.phone}`" target="_blank") {{ customer.phone }}
        .spacer
        div(:class="b('data-row-actions')")
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              a(:href="`tel:${customer.phone}`" v-on="on" target="_blank")
                v-icon mdi-phone-outgoing
            span Llamar
      div(:class="b('data-row')")
        v-icon  mdi-email
        a(:href="`mailto:${customer.email}`") {{ customer.email }}
      div(v-if="$projectConfig.WHATSAPP_TEMPLATES" :class="b('data-row')" )
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-whatsapp
          span Enviar mensaje a whatsapp
        a(@click.prevent="() => whatsappSendModal = true") Enviar Mensaje de Whatsapp
      div(v-else-if="deal.currentStage.id === '1' && deal.customer.whatsappLoginUrl" :class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-whatsapp
          span Enviar mensaje a whatsapp
        a(:href="deal.customer.whatsappLoginUrl" target="_blank") Aceptar Propuesta
      div(v-else-if="deal.currentStage.id === '2' && deal.customer.whatsappMissingDocuments" :class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-whatsapp
          span Enviar mensaje a whatsapp
        a(:href="deal.customer.whatsappMissingDocuments" target="_blank") Cargar Documentos
      div(v-if="adviser" :class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-account-tie
          span Asesor
        span {{ adviser.firstName }} {{ adviser.lastName }}
      div(:class="b('dates-row')")
        div(:class="b('data-row')")
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              v-icon(v-on="on") mdi-calendar-plus
            span Fecha de Creación
          span {{ deal.createdAt | formatDate }}
        div(v-if="deal.disbursementDate" :class="b('data-row')")
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              v-icon(v-on="on") mdi-calendar-heart
            span Fecha de Desembolso
          span {{ deal.disbursementDate | formatDate }}
      div(v-if="adviser" :class="b('data-row')")
        v-flex(v-if="deal.source || deal.medium || deal.campaign" xs12)
          div(:class="b('mkt')")
            div(v-if="deal.source")
              b Source:
              span {{ deal.source }}
            div(v-if="deal.medium")
              b Medium:
              span {{ deal.medium }}
            div(v-if="deal.campaign")
              b Campaign:
              span {{ deal.campaign }}  
      div(:class="b('actions')")
        v-menu(v-if="copyUrls.length" offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              v-bind="attrs"
              v-on="on"
              color="primary"
              text
            )
              v-icon(left) mdi-content-copy
              span Copiar Enlace
          v-list
            v-list-item(v-for="item in copyUrls" :key="item.text" link)
              v-list-item-title(@click="copyToClipboard(item.url)") {{ item.text }}
        v-badge(
          bordered
          :content="remindersCount"
          :value="remindersCount"
          overlap
        )
          v-btn(color="primary" text @click="onClickReminders")
            v-icon(left) mdi-alarm
            span Recordatorios
    lost-reason-modal(
      v-model="lostReasonModal"
      @submit="lostReasonId => onChangeStatus('LOST', { lostReasonId })"
    )
    disbursement-date-modal(
      v-model="disbursementDateModal"
      @submit="disbursementDate => onChangeStatus('WON', { disbursementDate })"
    )
    whatsapp-send-modal(v-model="whatsappSendModal" :customer-id="customer.id")
</template>

<script>
  import { HOME_EQUITY_LOAN_SLUG, DEAL_STATUS } from '@/utils/constants';
  import { buildDocumentsURL, buildProposalsURL } from '@/utils/user-account-url-builder';
  import StageSelect from '@/components/inputs/deal-stage-select';
  import StatusSelect from '@/components/inputs/deal-status-select';
  import MotiveSelect from '@/components/inputs/deal-motive-select';
  import Tag from '@/components/UI/tag';
  import LostReasonModal from '@/components/modals/deal-lost-reason-modal';
  import DisbursementDateModal from '@/components/modals/deal-disbursement-date-modal';
  import getProductMotive from '@/utils/get-product-motive';
  import WhatsappSendModal from '@/components/smart/whatsapp-send-modal';
  import compact from 'lodash/compact';
  import copy from 'copy-to-clipboard';
  import iziToast from 'izitoast';

  import LostReason from './lost-reason';

  export default {
    name: 'deal-card',

    data() {
      return {
        lostReasonModal: false,
        disbursementDateModal: false,
        whatsappSendModal: false,
      };
    },

    props: {
      deal: { type: Object, required: true },
      reminders: { type: Object, required: true },
      canGenerateCommercialFile: { type: Boolean },
      commercialFileUrl: { type: String },
      requesting: { type: Boolean, default: false },
    },

    computed: {
      customer() {
        return this.deal?.customer;
      },

      whatsappLink() {
        return `https://api.whatsapp.com/send?phone=${this.customer?.mobile}`;
      },

      mortgageInscribed() {
        return this.customer?.mortgageInscribed;
      },

      adviser() {
        return this.customer?.adviser;
      },

      motive() {
        return getProductMotive(this.deal.product.slug, this.deal.motive);
      },

      canModifyMotive() {
        return this.deal.product.slug === HOME_EQUITY_LOAN_SLUG;
      },

      remindersCount() {
        return this.reminders?.count || 0;
      },

      isLost() {
        return this.deal?.status === DEAL_STATUS.LOST;
      },

      copyUrls() {
        const { loginUrl } = this.customer || {};

        return compact([
          loginUrl && { text: 'Inicio de sesión', url: loginUrl },
          loginUrl
            && {
              text: 'Propuestas',
              url: buildProposalsURL(loginUrl, this.deal.product.slug),
            },
          loginUrl
            && {
              text: 'Documentos',
              url: buildDocumentsURL(loginUrl, this.deal.product.slug),
            },
        ]);
      },
    },

    methods: {
      copyToClipboard(text) {
        copy(text);

        iziToast.success({ message: 'El enlace ha sido copiado al portapapeles' });
      },

      onChangeStage(stage) {
        this.$emit('change-stage', { stage });
      },

      onChangeStatusSelect(status) {
        if(status === DEAL_STATUS.LOST) this.lostReasonModal = true
        else if(status === DEAL_STATUS.WON) this.disbursementDateModal = true;
        else this.onChangeStatus(status);
      },

      onChangeStatus(status, attrs = {}) {
        this.$emit('change-status', { status, ...attrs });

        this.lostReasonModal = false;
        this.disbursementDateModal = false;
      },

      onChangeMotiveSelect(motive) {
        this.$emit('change-motive', { motive });
      },

      onClickGenerateCommercialFile() {
        this.$emit('generate-commercial-file');
      },

      onClickReminders() {
        this.$emit('click-reminders');
      },
    },

    components: {
      DisbursementDateModal,
      StageSelect,
      StatusSelect,
      MotiveSelect,
      LostReasonModal,
      LostReason,
      Tag,
      WhatsappSendModal,
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deal-card
    &__header
      display: flex
      align-items: center

    &__customer-name
      font-size: 22px

    &__product
      font-weight: 500

    &__credit
      font-size: 22px
      font-weight: bold
      text-align: right

    &__data-row
      display: flex
      align-items: center
      margin: .5rem 0

      & > .v-icon:first-child
        margin-right: 1rem

      &-actions
        display: flex
        align-items: center
        color: $primaryColor

        & > *
          margin-left: 6px

        .v-icon
          color: inherit

    &__actions
      display: flex
      justify-content: flex-end

    &__dates-row
      display: flex

      & > *
        margin-right: 8px

        +md
          margin-right: 12px

        &:last-child
          margin-right: 0

    a
      text-decoration: none
</style>

<style lang="sass">
  .deal-card
    &__compact-select
      display: inline-flex
      padding: 0

      .v-input__slot
        margin: 0

        &:before, &:after
          border: none !important

      .v-select__selection
        color: #1976d2
        font-size: 1rem

      .v-text-field__details
        display: none

      .v-icon
        color: #1976d2
    &__mkt
      background-color: transparentize($primaryColor, 0.9)
      border-radius: 3px
      margin-top: 6px

      & > div
        display: inline
        padding: 2px 12px

        b
          margin-right: 6px
</style>
