<template lang="pug">
  v-expansion-panel
    v-expansion-panel-header(:class="b('title')") {{ subcategory.name }}
    v-expansion-panel-content.pl-3
      file-list(:files="subcategory.items")
</template>

<script>
  import FileList from './file-list';

  export default {
    name: 'subcategory-group',

    props: {
      subcategory: { type: Object, required: true },
    },

    components: { FileList },
  };
</script>

<style lang="sass" scoped>
  .subcategory-group
    box-shadow: none

    &__title
      font-size: 16px
      font-weight: 500
</style>
