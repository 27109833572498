<template lang="pug">
  tr(:class="b()")
    td {{ period.period | formatDate('MMMM YYYY') }}
    td {{ period.balance | formatCurrency(undefined, { precision: 0 }) }}
    td(:style="{ color: qualificationColor }") {{ period.qualification | formatQualification }}
</template>

<script>
import getCalificationColor from '@/utils/buro-qualification-color';
import formatQualification from '@/utils/format-buro-qualification';

export default {
  name: 'guarantees-dialog-entity-period',

  props: {
    period: { type: Object, required: true },
  },

  computed: {
    qualificationColor() {
      return getCalificationColor(this.period.qualification);
    },
  },

  filters: {
    formatQualification,
  },

};
</script>

<style lang="sass" scoped>

</style>