<template lang="pug">
  div(:class="b()")
    table(:class="b('table')")
      thead(:class="b('table-head')")
        tr
          th Nombre
          th DNI
          th Correo
          th Teléfono
          th Score
          th
      tbody(:class="b('table-body')")
        formalization-referrals-table-row(
          v-for="referral in referrals"
          :key="referral.id"
          :referral="referral"
          :validable="validable"
          @click-edit="args => $emit('click-edit', args)"
          @click-validate="args => $emit('click-validate', args)"
        )
</template>

<script>
import FormalizationReferralsTableRow from './formalization-referrals-table-row';

export default {
  name: 'formalization-referrals-table',

  props: {
    referrals: { type: Array, default: () => [] },
    validable: { type: Boolean, default: false },
  },

  components: { FormalizationReferralsTableRow },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'
  .formalization-referrals-table
    @media screen and (max-width: 959px)

      table, thead, tbody, th, td, tr
        display: block

      thead
        display: none

      &::v-deep
        tr, td
          display: block

        td
          position: relative
          padding: 4px 0
          min-height: 30px
          padding-left: 30%
          border-bottom: 1px solid darken(white, 6%)

          &:before
            position: absolute
            left: 4px
            top: 4px
            width: 25%
            white-space: nowrap
            font-weight: 500

          &:nth-of-type(1):before
            content: 'Nombre'
          &:nth-of-type(2):before
            content: 'DNI'
          &:nth-of-type(3):before
            content: 'Correo'
          &:nth-of-type(3):before
            content: 'Teléfono'
          &:nth-of-type(4):before
            content: 'Score'

          &:last-child
            margin-bottom: 12px
            border-bottom: 0

    +md
      &__table
        width: 100%
        border-collapse: collapse

        &-head
          th
            padding: 3px 0
            background-color: darken(white, 3%)

        &-body
          &::v-deep tr
            td
              padding: 2px 6px
              border-bottom: 1px solid darken(white, 7%)

            &:last-child
              td
                border-bottom: 0
</style>