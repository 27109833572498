export default {
  name: 'generate-strategy-select',

  functional: true,

  props: {
    strategies: { type: Array, default: () => [] },
  },

  render(h, context) {
    const items = (context.props.strategies || [])
      .map(strategy => ({
        value: strategy,
        text: context.parent.$t(`generateStrategy.${strategy}`),
      }));

    return h(
      'v-select',
      {
        props: {
          ...context.props,
          items,
        },
        on: context.listeners,
      }
    );
  },
};