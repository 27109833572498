<script>
import { Doughnut } from 'vue-chartjs';
import { formatCurrency } from '@/filters/accounting';

export default {
  name: 'statement-chart',

  extends: Doughnut,

  props: {
    totals: { type: Object, required: true },
  },

  computed: {
    datasets() {
      return [{
        label: 'Monto',
        data: [
          Number(this.totals.totalAmortization),
          Number(this.totals.interest),
          Number(this.totals.insurancesProperty),
          Number(this.totals.insurancesLife),
          Number(this.totals.defaultsAndPenalties),
        ],
        backgroundColor: [
          '#4BB543',
          '#3282b8',
          '#845ec2',
          '#880d1e',
          '#f14668',
        ]
      }];
    },

    labels() {
      return ['Total amortizado','Interés', 'Seguro inmueble', 'Seguro vida', 'Moras y Penalidades'];
    },
  },

  mounted() {
    this.renderChart({
      labels: this.labels,
      datasets: this.datasets,
    }, {
      maintainAspectRatio: false,
      legend: {
        position: 'right',
        labels: {
          fontSize: 16,
        }
      },

      tooltips: {
        mode: 'label',
        callbacks: {
          label: ({ index }) => {
            return [this.labels[index], formatCurrency(this.datasets[0].data[index])].join(': ');
          },
        },
      },
    });
  },
};
</script>