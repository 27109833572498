<template lang="pug">
  v-select(
    :value="value"
    :items="lazyItems"
    :label="label"
    :error-messages="errorMessages"
    :return-object="returnObject"
    :loading="$apollo.queries.evaluationTypes.loading"
    :item-value="itemValue"
    :disabled="disabled"
    item-text="name"
    @input="args => $emit('input', args)"
    @focus="onFocus"
  )
</template>

<script>
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import { EvaluationTypesSelectQuery } from './queries.gql';

  export default {
    name: 'evaluation-type-select',

    mixins: [lazySelectMixin({ itemsKey: 'evaluationTypes' })],

    props: {
      label: { type: String },
      errorMessages: { type: Array },
      value: { type: String },
      returnObject: { type: Boolean },
      itemValue: { type: String, default: 'id' },
      disabled: { type: Boolean },
    },

    data() {
      return {
        evaluationTypes: [],
      };
    },

    apollo: {
      evaluationTypes: {
        query: EvaluationTypesSelectQuery,

        skip() {
          return this.lazySkipQuery;
        },
      },
    },
  };
</script>
