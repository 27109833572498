<template lang="pug">
  div(:class="b()")
    v-tabs(v-model="currentTab" fixed-tabs)
      v-tab(v-for="contextResults, contextKey in contexts" :key="contextKey") {{ contextKey }}
      v-tab-item(v-for="contextResults, contextKey in contexts" :key="contextKey").pt-3
        results-context(:results="contextResults")
</template>

<script>
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import humps from 'humps';
import ResultsContext from './buro-results-context';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'buro-results',

  data() {
    return {
      currentTab: 0,
    };
  },

  props: {
    results: { type: Object },
  },

  computed: {
    contexts() {
      const finalContext = {};

      Object.entries(this.results).forEach(([key, value]) => {
        // Removemos los nodos anidados en Perú que no tienen datos basados en el Total 0
        if (
          IS_PE_COUNTRY &&
          Object.keys(value).length === 3 &&
          ['0', '0.00'].includes(value.total)
        )
          return;

        if (
          (isArray(value) && value.length > 0) ||
          (isPlainObject(value) && Object.keys(value).length > 0)
        )
          finalContext[humps.decamelize(key, { separator: ' ' })] = value;
      });

      return finalContext;
    },
  },

  components: { ResultsContext },
};
</script>

<style lang="sass"></style>
