<template lang="pug">
  div(:class="b()")
    div(:class="b('filters-card')" ref="toolbar")
      filters(
        :filters="filters"
        :fetching="$apollo.queries.bonds.loading"
        @submit="onSubmitFilters"
      )
    v-container(fluid :class="b('body')")
      .d-flex.justify-end
        v-btn(color="primary" :to="{ name: 'bonds.new' }")
          v-icon.mr-2 mdi-plus
          span Nuevo Bono

      loading-wrapper(
        v-if="$apollo.queries.bonds.loading || bondsError"
        :error="bondsError"
        @retry="onRetryQuery"
      )
      template(v-else-if="bonds && bonds.objects && bonds.objects.length")
        div(:class="b('controls')")
          .d-flex.align-center
            b Entradas:&nbsp;
            per-page-select(
              v-model="perPage"
              :class="b('per-page-select')"
              hide-details
            ).ml-2
          .spacer
          div #[b.mr-1 Resultados: ] {{ bonds.count }} Bonos
        div(:class="b('table-container')")
          bonds-table(
            :bonds="bonds.objects"
            :totals="bonds.totals"
            :order-by="filters.orderBy"
            :order-flow="filters.orderFlow"
            @order="onOrder"
          )
      v-alert(v-else type="info" outlined) No se han encontrado bonos con los filtros indicados
      .text-center
        v-pagination(
          v-if="bonds && bonds.pages > 1"
          v-model="currentPage"
          :length="bonds.pages"
          :total-visible="15"
        )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import PerPageSelect from '@/components/inputs/per-page-select';

  import { BondsListQuery } from './graphql/queries.gql';
  import BondsTable from './components/bonds-table';
  import Filters from './components/filters';

  export default {
    name: 'bonds-list-view',

    apollo: {
      bonds: {
        query: BondsListQuery,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',

        variables() {
          const {
            status,
            emittionDate,
            expirationDate,
            ...filters
          } = this.filters || {};

          return {
            ...filters,
            page: this.currentPage,
            perPage: this.perPage,
            status: status?.length ? status : undefined,
            issueDateFrom: emittionDate?.[0],
            issueDateTo: emittionDate?.[1] || emittionDate?.[0],
            endPaymentDateFrom: expirationDate?.[0],
            endPaymentDateTo: expirationDate?.[1] || expirationDate?.[0],
          };
        },

        update({ bonds = {} }) {
          return {
            pages: 1,
            objects: [],
            ...bonds,
          };
        },

        error() {
          this.bondsError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },
    },

    data() {
      return {
        currentPage: 1,
        perPage: 20,
        filters: {
          orderBy: undefined,
          orderFlow: undefined,
        },
        bonds: {
          pages: 1,
          objects: [],
        },
        bondsError: null,
      };
    },

    components: { Filters, BondsTable, LoadingWrapper, PerPageSelect },

    methods: {
      onRetryQuery() {
        this.bondsError = null;
        this.$apollo.queries.bonds.refetch();
      },

      async onSubmitFilters({ filters }) {
        this.filters = {
          ...this.filters,
          ...filters,
        };
      },

      onOrder({ key, flow }) {
        this.filters.orderBy = key;
        this.filters.orderFlow = flow;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .bonds-list-view
    &__table-container
      overflow-x: auto

    &__controls
      padding-bottom: 12px

      +sm
        display: flex
        align-items: center

      & > div
        display: flex
        align-items: center
        padding: 4px 0

    &__filters-card
      padding: 8px 24px
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      background-color: white

      +md
        display: block !important

    &__body
      padding-top: 32px

      +md
        padding-top: 64px

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +md
        display: none

    &__per-page-select
      max-width: 160px
      padding-top: 0
      margin-top: 0
</style>