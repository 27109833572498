import { BURO_QUALIFICATIONS } from '@/utils/constants';

const CALIFICATIONS = {
  [BURO_QUALIFICATIONS.DUD]: 'Dudoso',
  [BURO_QUALIFICATIONS.NOR]: 'Normal',
  [BURO_QUALIFICATIONS.DEF]: 'Deficiente',
  [BURO_QUALIFICATIONS.PER]: 'Pérdida',
  [BURO_QUALIFICATIONS.CPP]: 'Potencial Pérdida',
  [BURO_QUALIFICATIONS.SCH]: 'Reprogramada',
};

export default calification => CALIFICATIONS[calification] || calification || '-';
