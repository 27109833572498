<template lang="pug">
  .over-due-details
    table
      thead
        tr
          th(style="text-align: center") # Doc
          th Fuente - Acreedor
          th(style="text-align: right") Monto 
          th(style="text-align: right") Días Venc.
      tbody
        tr
          th 
          th Documentos Impagos
          th(style="text-align: right") {{ totalDebt | formatCurrency() }}
          th(style="text-align: right") {{ maxDueDays }}
        tr(v-for="(detail, index) in overDueDetails" :key="index")
          td(style="text-align: center") {{ detail?.doc_quantity }}
          td {{ detail?.legal_name }}
          td(style="text-align: right") {{ detail?.debt | formatCurrency() }}
          td(style="text-align: right") {{ detail?.due_days }}
</template>

<script>
export default {
  name: 'sentinel-over-due-details',
  props: {
    overDueDetails: Array,
    totalDebt: Number,
    maxDueDays: Number
  },
};
</script>

<style lang="sass" scoped>
.over-due-details
  table
    width: 100%
    border: 1px solid black
    border-collapse: collapse

  th, td
    padding: 8px
    text-align: left

  thead th
    border-bottom: 1px solid black

  .total-row td
    border-top: none

  tr.total-row td, thead th
    font-weight: bold
</style>
