<template lang="pug">
  div(:class="b()")
    img(:src="$projectConfig.COMPANY_LOGO" :class="b('logo')")
    loading-spinner
</template>

<script>
  export default {
    name: 'loading-view',
  };
</script>

<style lang="sass" scoped>
  .loading-view
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

    &__logo
      width: 90%
      max-width: 400px

    &__text
      font-size: 3rem
      padding-left: .5rem
</style>