<template lang="pug">
  div(:class="b()")
    tax-report-essalud-reg-item(
      :class="b('item')"
      v-for="year in years"
      :key="year.year"
      :year="year"
    )
</template>

<script>
import TaxReportEssaludRegItem from './tax-report-essalud-reg-item';

export default {
  name: 'tax-report-essalud-reg',

  props: {
    years: { type: Array, default: () => ([]) },
  },

  components: { TaxReportEssaludRegItem },
};
</script>

<style lang="sass" scoped>
  .tax-report-essalud-reg
    &__item
      margin-bottom: 12px
</style>