import Config from '@/config';
import LocationSelect from '@/components/inputs/location-select';
import get from 'lodash/get';

export default ({
  dataKey = 'address'
} = {}) => ({
  data() {
    return {
      politicalDivisions: Config.ADDRESS_POLITICAL_DIVISIONS,
    };
  },

  components: { LocationSelect },

  created() {
    this.$addressInput = {
      onInput: (value, idx) => {
        if(this.$_addressInputMixin_locationIds[idx] !== value)
          this.$set(this.$_addressInputMixin_address, `_locationIds`, [...this.$_addressInputMixin_address._locationIds.slice(0, idx), value]);
      },

      isDisabled: (idx) => {
        return idx > this.$_addressInputMixin_locationIds.length;
      },

      getValue: (idx) => {
        return this.$_addressInputMixin_locationIds[idx];
      },

      getParentId: (idx) => {
        return this.$_addressInputMixin_locationIds[idx - 1];
      },

      getDefaultItems: (idx) => {
        const location = this.$_addressInputMixin_address?.location?.[idx];

        return location ? [location] : [];
      },
    };
  },

  computed: {
    $_addressInputMixin_address() {
      return get(this.data, dataKey);
    },

    $_addressInputMixin_locationIds() {
      return get(this.data, `${dataKey}._locationIds`);
    },
  },
});