<template lang="pug">
  div(:class="b()")
    deal(
      v-for="deal in deals"
      :key="deal.id"
      :deal="deal"
    )
</template>

<script>
  import Deal from './deals-history-row';

  export default {
    name: 'deals-history',

    props: {
      deals: { type: Array, default: () => [] },
    },

    components: { Deal },
  };
</script>

<style lang="sass" scoped>

</style>