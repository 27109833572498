import { QUALIFICATIONS_BY_COMPLIANCE } from '@/utils/constants';

const COLORS = {
  [QUALIFICATIONS_BY_COMPLIANCE.NOR]: '#00B050',
  [QUALIFICATIONS_BY_COMPLIANCE.CPP]: '#92D051',
  [QUALIFICATIONS_BY_COMPLIANCE.DEF]: '#FFCC02',
  [QUALIFICATIONS_BY_COMPLIANCE.DUD]: '#FF6600',
  [QUALIFICATIONS_BY_COMPLIANCE.PER]: '#C00000',
};

export default qualification => COLORS[qualification];
