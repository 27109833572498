<template lang="pug">
  v-dialog(
    :value="value"
    @input="args => $emit('input', args)"
    :width="600"
  )
    v-card.px-4.pt-4.pb-2
      .px-4.pb-3
        .d-flex
          span.font-weight-medium Reprogramación de Cuota
          .spacer
          v-icon(@click="$emit('input', false)") mdi-close
      v-divider.mb-4
      .pa-2
        validation-observer(v-slot="{ invalid }" ref="form")
          form(@submit.prevent="onSubmitRescheduleForm")
            validation-provider(v-if="requiresBalloonPayment" name="Pago Balloon" rules="required|min_value:0|max_value:100" v-slot="{ errors }")
              v-text-field(
                v-model.number="currentBalloonPayment"
                :error-messages="errors"
                label="Pago Balloon"
                name="Pago Balloon"
                type="number"
              )
            .d-flex.justify-center.pt-2
              v-btn(
                :disabled="invalid || requesting"
                :loading="requesting"
                :to="scheduleRoute"
                type="button"
                target="_blank"
                color="primary"
                outlined
              ).mx-1 Simular

              v-btn(
                :disabled="invalid || requesting"
                :loading="requesting"
                type="submit"
                color="primary"
              ).mx-1 Reprogramar
</template>

<script>
import { encrypt } from '@/utils/url-params-crypter';
import { QUOTE_TYPE } from '@/utils/constants';

export default {
  name: 'reschedule-modal',

  props: {
    value: { type: Boolean },
    quote: { type: Object },
    quoteType: { type: String },
    balloonPayment: { type: Number },
    investmentProposalId: { type: String },
    proposalId: { type: String },
    requesting: { type: Boolean, default: false },
  },

  data() {
    return {
      currentBalloonPayment: undefined,
    };
  },

  computed: {
    scheduleRoute() {
      if(!this.quote || !this.quoteType || !this.investmentProposalId) return;

      if(this.isBorrower)
        return {
          name:  'mortgage_schedule.investment_borrower',
          query: {
            _params: encrypt({
              investmentProposalId: this.investmentProposalId,
              proposalId: this.proposalId,
              balloonPct: this.currentBalloonPayment,
              feeNumber: this.quote.feeNumber,
            }),
          },
        };
      else
        return {
          name: 'mortgage_schedule.investment_investor',
          query: {
            _params: encrypt({
              investmentProposalId: this.investmentProposalId,
              proposalId: this.proposalId,
              feeNumber: this.quote.feeNumber,
            }),
          },
        };

    },

    isBorrower() {
      return this.quoteType === QUOTE_TYPE.BORROWER;
    },

    requiresBalloonPayment() {
      return this.isBorrower;
    },
  },

  methods: {
    onSubmitRescheduleForm() {
      if(window.confirm('¿Está seguro de que desea reprogramar la cuota?'))
        this.$emit('submit-reschedule', {
          feeNumber: this.quote.feeNumber,
          balloonPct: this.isBorrower ? this.currentBalloonPayment : undefined,
          quoteType: this.quoteType,
        });
    },
  },

  watch: {
    balloonPayment: {
      immediate: true,

      handler(newVal) {
        this.currentBalloonPayment = newVal;
      },
    },
  },
};
</script>

<style lang="sass" scoped>

</style>