<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="500")
    referral-form(
      :referral="referral"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import ReferralForm from '@/components/forms/referral-form';

  export default {
    name: 'referral-form-modal',

    mixins: [formModalMixin({ entityName: 'referral' })],

    components: { ReferralForm },
  };
</script>
