<template lang="pug">
  router-link(:class="b()" :to="{ name: 'risk_files.show', params: { riskFileId: riskFile.id }}")
    div(:class="b('customer-name')") {{ customerName }}
    div(:class="b('product')") {{ $t(`productSlug.${this.riskFile.product.slug}`) }}
    div(:class="b('motive')") {{ motive }}
    div(:class="b('footer')")
      div(:class="b('adviser')")
        user-avatar(
          v-if="riskFile.lastOpinion"
          :first-name="riskFile.lastOpinion.user.firstName"
          :last-name="riskFile.lastOpinion.user.lastName"
          :url="riskFile.lastOpinion.user.avatar"
          :size="24"
        )
      div(:class="b('tags')")
        tag(:color="statusColor") {{ $t(`riskOpinionStatus.${riskFile.status}`) }}
    div(:class="b('date')").text-right.mt-1 #[span.font-weight-medium Creación]: {{ riskFile.createdAt | formatDate }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';
  import Tag from '@/components/UI/tag';
  import getStatusColor from '@/utils/get-risk-opinion-status-color';
  import getProductMotive from '@/utils/get-product-motive';
  import { HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

  export default {
    name: 'risk-file-card',

    props: {
      riskFile: { type: Object, required: true },
    },

    computed: {
      customerName() {
        return `${this.riskFile.customer?.firstName || ''} ${this.riskFile.customer?.lastName || ''}`;
      },

      statusColor() {
        return getStatusColor(this.riskFile.status);
      },

      motive() {
        return getProductMotive(HOME_EQUITY_LOAN_SLUG, this.riskFile.motive);
      },
    },

    components: { UserAvatar, Tag },
  };
</script>

<style lang="sass" scoped>
  .risk-file-card
    display: block
    margin: .5rem .75rem
    padding: 1rem
    background-color: white
    border-radius: 4px
    border: 1px solid #dfdfdf
    box-shadow: 0 1px 2px rgba(0,0,0,0.1)
    color: inherit
    text-decoration: none
    font-size: 12px
    user-select: none
    touch-callout: none

    &__customer-name
      font-size: 1.1rem
      font-weight: 500

    &__motive
      font-weight: 500

    &__footer
      display: flex
      margin-top: .75rem

    &__tags
      flex: 1

      display: flex
      justify-content: flex-end

      & > *
        margin: 0 2px

</style>