<template lang="pug">
  div(:class="b()")
    div(
      v-for="sicom in sicoms"
      :key="sicom.id"
      :class="b('debt')"
    )
      div(v-if="sicom.recentExpirationDate") #[b Fecha de vencimiento: ] {{ sicom.recentExpirationDate | formatDate }}
      div(v-if="sicom.penAmount") #[b Deuda en Soles: ] S/ {{ sicom.penAmount | formatNumber }}
      div #[b Deuda en Dólares: ] $ {{ sicom.usdAmount | formatNumber }}
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="detail in sicom.sicomDebtDetails" :key="detail.id")
          div(v-if="detail.creditor") #[b Acreditador: ] {{ detail.creditor }}
          div(v-if="detail.amount") #[b Monto: ] {{ detail.currency}} {{ detail.amount | Number }}
          div(v-if="detail.debtCondition") #[b Condición: ] {{ detail.debtCondition }}
          div(v-if="detail.bankDocument") #[b Documento bancario: ] {{ detail.bankDocument }}
          div(v-if="detail.expirationDate") #[b Fecha de vencimiento: ] {{ detail.expirationDate | formatDate }}
</template>

<script>
  export default {
    name: 'sicoms-tab',

    block: 'debts-tab',

    props: {
      sicoms: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>
  .sicoms-tab
    &__sicom
      padding-bottom: 16px

      &:last-child
        padding-bottom: 0
</style>