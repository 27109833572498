<template lang="pug">
	div(:class="b()")
		div(v-if="!guarantor")

			h4.text--secondary(style="margin-top: 25px") #[b Semáforos de los últimos 24 meses]
			div(v-if="buroV2?.ConRap?.Resumen_ConRap?.color_qualification")
				sentinel-semaforo(
					v-if="buroV2?.ConRap?.Resumen_ConRap?.color_qualification"
					:semaforos="buroV2?.ConRap?.Resumen_ConRap?.color_qualification"
					style="height: 120px; width: 100%"
				)
				sentinel-semaforo-resume(
					v-if="buroV2?.ConRap?.Resumen_ConRap"
					:resumen="buroV2?.ConRap?.Resumen_ConRap"
				)
			v-alert(
				v-if="!buroV2?.ConRap?.Resumen_ConRap?.color_qualification"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre los semáforos de los últimos 24 meses

			h4.text--secondary(style="margin-top: 25px") #[b Detalle de vencidos]
			div(v-if="buroV2?.ConRap?.DetVen?.length")
				sentinel-over-due-details(
					v-if="buroV2?.ConRap?.DetVen?.length"
					:over-due-details="buroV2?.ConRap?.DetVen[0].over_due_details"
					:total-debt="buroV2?.ConRap?.DetVen[0].debt"
					:max-due-days="buroV2?.ConRap?.DetVen[0].max_due_days"
				)
			v-alert(
				v-if="!buroV2?.ConRap?.DetVen?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre los los detalles de vencidos
			
			h4.text--secondary(style="margin-top: 25px") #[b Créditos e indicadores]
			div(v-if="buroV2?.ConRap?.IndCre?.length")
				sentinel-indicators(
					v-if="buroV2?.ConRap?.IndCre"
					:indicators="buroV2?.ConRap?.IndCre"
				)
			v-alert(
				v-if="!buroV2?.ConRap?.IndCre?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre los créditos e indicadores

			h4.text--secondary(style="margin-top: 25px") #[b Historial de deudas]
			div(v-if="buroV2?.ConRap?.DetSBSMicr?.length")
				sentinel-table-history(
					v-if="buroV2?.ConRap?.DetSBSMicr"
					:debts="buroV2?.ConRap?.DetSBSMicr"
				)
			v-alert(
				v-if="!buroV2?.ConRap?.DetSBSMicr?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre el historial de deudas

			h4.text--secondary(style="margin-top: 25px") #[b Lineas de Crédito]
			div(v-if="buroV2?.ConRap?.UtiLinCre?.length")
				sentinel-credit-lines(
					v-if="buroV2?.ConRap?.UtiLinCre"
					:creditLines="buroV2?.ConRap?.UtiLinCre"
				)
			v-alert(
				v-if="!buroV2?.ConRap?.UtiLinCre?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre las lineas de crédito

		div(v-if="guarantor")
			h4.text--secondary(style="margin-top: 25px") #[b Es Avalista de:]
			div(v-if="buroV2?.AvalCod?.AvalDe?.length")
				sentinel-guarantor(
					v-if="buroV2?.AvalCod?.AvalDe"
					:guarantor="buroV2?.AvalCod?.AvalDe"
				)
			v-alert(
				v-if="!buroV2?.AvalCod?.AvalDe?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre a quiénes avala

			h4.text--secondary(style="margin-top: 25px") #[b Quiénes lo Avalan:]
			div(v-if="buroV2?.AvalCod?.QuiAval?.length")
				sentinel-guarantor(
					v-if="buroV2?.AvalCod?.QuiAval"
					:guarantor="buroV2?.AvalCod?.QuiAval"
				)
			v-alert(
				v-if="!buroV2?.AvalCod?.QuiAval?.length"
				:value="true"
				type="info"
				outlined
			).mt-2 Aún no hay información sobre quiénes lo avalan
</template>

<script>
	import SentinelSemaforo from './sentinel-semaforo';
	import SentinelSemaforoResume from './sentinel-semaforo-resume';
	import SentinelTableHistory from './sentinel-table-history';
	import SentinelIndicators from './sentinel-indicators';
	import SentinelCreditLines from './sentinel-credit-lines';
	import SentinelGuarantor from './sentinel-guarantor';
	import SentinelOverDueDetails from './sentinel-over-due-details';

	export default {
		name: 'sentinel',

		props: {
			buroV2: {},
			guarantor: { type: Boolean, default: false }
		},

		components: {
			SentinelSemaforo,
			SentinelSemaforoResume,
			SentinelTableHistory,
			SentinelIndicators,
			SentinelCreditLines,
			SentinelGuarantor,
			SentinelOverDueDetails
		},
	};
</script>

<style lang="sass" scoped>
	h4
		margin: 0
		padding: 0
		font-size: 1.2rem
		font-weight: 400
		margin-bottom: 1rem
		text-align: center
</style>