<template lang="pug">
  div(:class="b({ rescheduled: isRescheduled })")
    div(:class="b('body')")
      div(:class="b('header')")
        div(:class="b('reference')") # {{ quote.feeNumber }}
        status-tag(:class="b('header-label')" :status="quote.status")
        div(:class="b('header-title')") {{ quoteData.netAmountDue | formatCurrency(currency) }}

      div(:class="b('content')")
        div(:class="b('data')")
          div(:class="b('data-value')") {{ grossAmountDue | formatCurrency(currency) }}
          div(:class="b('data-title')") Cuota Bruta
        div(:class="b('data')")
          div(:class="b('data-value')") {{ total | formatCurrency(currency) }}
          div(:class="b('data-title')") Comisión Total
        div(v-if="quoteData.irTax" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.irTax | formatCurrency(currency) }}
          div(:class="b('data-title')") Imp. renta
        div(:class="b('data')")
        div(v-if="quoteData.penaltyAmount" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.penaltyAmount | formatCurrency(currency) }}
          div(:class="b('data-title')") Penalidad
        div(v-if="quoteData.defaultAmount" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.defaultAmount | formatCurrency(currency) }}
          div(:class="b('data-title')") Mora
        div(v-if="quoteData.propertyInsurance" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.propertyInsurance | formatCurrency(currency) }}
          div(:class="b('data-title')") Seguro Inmueble
        div(v-if="quoteData.lifeInsurance" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.lifeInsurance | formatCurrency(currency) }}
          div(:class="b('data-title')") Seguro Vida
    div(:class="b('footer')")
      div(v-if="isPaid" :class="b('paid-status')")
        div(:class="b('paid-status-number')") # {{ quote.paymentOperationNumber }}
        div(:class="b('paid-status-amount')") {{ quote.amount | formatCurrency(currency) }}
        div(:class="b('paid-status-date')") {{ quote.paymentDate | formatDate }}
      div(v-else-if="quote.canReschedule && quote.canPay" :class="b('reschedule-button')" @click="onClickReschedule") Reprogramar
      div(v-else-if="!isRescheduled && quote.canPay" :class="b('register-button')" @click="onClickRegisterPayment")
        div Registrar Pago
</template>

<script>
  import QuotesItem from '../borrower-quotes-card/quotes-item';

  export default {
    name: 'quotes-item',

    extends: QuotesItem,

    computed: {
      total() {
        const { rtcCommission, ivaTax } = this.quoteData;

        return rtcCommission + ivaTax;
      },

      grossAmountDue() {
        const { amortization, interest } = this.quoteData;

        return amortization + interest;
      },
    },
  };
</script>
