<template lang="pug">
  base-dialog(
    :value="value"
    title="Aval"
    @input="args => $emit('input', args)"
  )
    div(
      v-for="guarantee in guarantees"
      :key="guarantee.id"
      :class="b('guarantee')"
    )
      div(v-if="guarantee.name") #[b Nombre: ] {{ guarantee.name }}
      div(v-if="guarantee.documentNumber") #[b Documento: ] ({{ guarantee.documentType}}) {{ guarantee.documentNumber}}
      div(v-if="guarantee.created") #[b Fecha de registro: ] {{ guarantee.created | formatDate('D MMMM YYYY') }}
      template(v-if="guarantee.guaranteeEntities")
        div #[b Entidades: ]
        ul(:class="b('entities')")
          guarantee-entity(v-for="entity in guarantee.guaranteeEntities" :key="entity.id" :entity="entity")
</template>

<script>
  import BaseDialog from './base-dialog';
  import GuaranteeEntity from './guarantees-dialog-entity';

  export default {
    name: 'guarantees-dialog',

    props: {
      value: { type: Boolean },
      guarantees: { type: Array, default: () => [] },
    },

    components: { BaseDialog, GuaranteeEntity },
  };
</script>

<style lang="sass" scoped>
  .guarantees-dialog
    &__guarantee
      padding-bottom: 22px

      &:last-child
        padding-bottom: 0

    &__entities
      margin-left: 18px
</style>