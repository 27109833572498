import { decrypt } from '@/utils/url-params-crypter';
import pick from 'lodash/pick';

export default ({
  parseKeys = [],
  requiredKeys = [],
  brokenParamsMessage = 'La ruta a la que intenta accerder no existe',
  redirectRoute = { name: 'dashboard.index' },
}) => ({
  methods: {
    onBrokenParams() {
      window.alert(brokenParamsMessage);

      this.$router.replace(redirectRoute);
    },

    parseQueryParams() {
      const params = pick(decrypt(this.$route.query?._params), parseKeys);
      const paramsKeys = Object.keys(params);

      if(requiredKeys.some(key => !paramsKeys.includes(key))) return this.onBrokenParams();

      Object.assign(this, params);
    },
  },


  created() {
    this.parseQueryParams();
  },
});
