import VSelect from 'vuetify/lib/components/VSelect';
import { REFERRAL_TYPES } from '@/utils/constants';

const VALUES = [
  { value: REFERRAL_TYPES.CUSTOMER_NETWORK, text: 'Referido de otro cliente' },
  { value: REFERRAL_TYPES.ADVISOR_NETWORK, text: 'Referido de un asesor' },
  { value: REFERRAL_TYPES.OTHER_NETWORK, text: 'Otros / Conseguidos en la calle' },
];

export default {
  name: 'referral-type-select',

  extends: VSelect,

  props: {
    items: { type: Array, default: () => VALUES },
  },
};
