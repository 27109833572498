<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit" :class="b()")
      validation-provider(name="tipo" :rules="'required'" v-slot="{ errors }")
        field-type-select(
          :value="selected.key"
          name="tipo"
          :error-messages="errors"
          :items="fieldTypes"
          @input="onInputKey"
        )
      field-control(
        :value="fieldControlValue"
        :field-type="selectedFieldType"
        :additional-props="fieldControlAdditionalProps"
        name="valor"
        @input="onInputFieldControl"
      )
      v-btn(
        color="primary"
        type="submit"
        :disabled="!selected.value"
      ) Filtrar
</template>

<script>
  import find from 'lodash/find';

  import FieldTypeSelect from './field-type-select';
  import FieldControl from './field-control';

  export default {
    name: 'field-form',

    props: {
      fieldTypes: { type: Array, required: true },
      currentFilters: { type: Array, default: () => [] },
    },

    data() {
      return {
        selected: {
          key: null,
          value: null,
        },
      };
    },

    computed: {
      selectedFieldType() {
        return find(this.fieldTypes, { key: this.selected.key });
      },

      fieldControlAdditionalProps() {
        return this.selectedFieldType?.additionalProps ? this.selectedFieldType.additionalProps.call(this) : {};
      },

      fieldControlValue()  {
        return this.selected?.value && this.selectedFieldType?.parseInputValue ?
          this.selectedFieldType.parseInputValue(this.selected.value) :
          null;
      },
    },

    methods: {
      async onSubmit() {
        try {
          const success = await this.$refs.form.validate();

          if(!success) return;

          this.$emit(
            'submit',
            {
              ...this.selected,
              text: this.selected.value,
              ...(this.selectedFieldType?.parseSubmit ?
                this.selectedFieldType.parseSubmit(this.selected.value) : {}
              ),
            }
          );
        } catch {
          return;
        }
      },

      clear() {
        this.selected = { key: null, value: null };

        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      },

      onInputFieldControl(data) {
        this.selected.value = data;
      },

      onInputKey(key) {
        this.selected = {
          key,
          value: null,
        };

        // SET default value
        if(this.selectedFieldType.defaultValue) this.selected.value = this.selectedFieldType.defaultValue;
      },
    },

    components: {
      FieldTypeSelect,
      FieldControl,
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .field-form
    +sm
      display: flex
      align-items: center

      & > *
        flex-grow: 0
        margin-right: 1rem

        &:first-child
          width: 120px
          margin-right: 1rem

        &:nth-child(2)
          flex: 1

        &:last-child
          width: 96px
</style>