<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      v-btn(text color="primary" :to="{ name: 'library' }").mb-2
        v-icon mdi-arrow-left
        span.ml-2 Regresar
      div(:class="b('header-title')") {{ category.name }}
    v-expansion-panels(accordion :class="b('expansion-panel')")
      subcategory-group(
        v-for="subcategory in category.subcategories"
        :key="subcategory.id"
        :subcategory="subcategory || []"
      )
    v-alert(
      v-if="emptyNotification && !categories.length"
      :value="true"
      type="info"
      outlined
    ) {{ emptyNotificationText }}
</template>

<script>
import SubcategoryGroup from './subcategory-group';

export default {
  name: 'category-group',

  props: {
    category: { type: Object },
    emptyNotification: { type: Boolean, default: false },
    emptyNotificationText: {
      type: String,
      default: 'No hay documentos para este cliente',
    },
  },

  components: {
    SubcategoryGroup,
  },
};
</script>

<style lang="sass" scoped>
.category-group
  &__header
    margin-top: 16px
    padding-bottom: 24px

    &-title
      padding: 8px 24px
      font-size: 18px
      font-weight: 500
      background-color: $primaryColor
      color: white
      border-radius: 6px

  &__title
    font-size: 20px
    font-weight: 500
    color: darken(white, 55%)
</style>
