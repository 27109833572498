<template lang="pug">
  table(:class="b()").rtc-metrics-table
    borrowers-payment-days-average-table-head(:labels="headLabels")
    borrowers-payment-days-average-table-body(:entries="entries")
</template>

<script>
import BorrowersPaymentDaysAverageTableHead from './borrowers-payment-days-average-table-head';
import BorrowersPaymentDaysAverageTableBody from './borrowers-payment-days-average-table-body';

export default {
  name: 'borrowers-payment-days-average-table',

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {
    headLabels() {
      return this.entries.map(({ feeNumber }) => `${feeNumber}`);
    },
  },

  components: { BorrowersPaymentDaysAverageTableHead, BorrowersPaymentDaysAverageTableBody },
};
</script>

<style lang="sass" scoped>
  //.borrowers-payment-days-average-table
</style>