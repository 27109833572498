<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs12 md4 lg2)
        country-select(
          :value="data.country"
          @input="onInputCountry"
          clearable
        )
      v-flex(xs12 md4 lg2)
        date-picker(
          v-model="data.fromDate"
          :max="fromDateMax"
          label="Desde"
          clearable
        )
      v-flex(xs12 md4 lg2)
        date-picker(
          v-model="data.toDate"
          :min="toDateMin"
          label="Hasta"
          clearable
        )
      v-flex(xs12 md4 lg2)
        product-select(
          v-model="data.product"
          :country="data.country"
          label="Producto"
        )
      v-flex(xs12 md4 lg2)
        group-by-select(
          v-model="data.groupBy"
        )
      v-flex.text-right
        v-btn(
          color="primary"
          :disabled="isDisabled"
          @click="onSubmit"
        ) Aplicar filtros
</template>

<script>
  import moment from 'moment';
  import basicFormMixin from '@/components/forms/basic-form-mixin'
  import DatePicker from '@/components/inputs/input-date-picker';

  import ProductSelect from '../../../components/product-select';
  import CountrySelect from '../../../components/country-select';
  import GroupBySelect from '../../../components/group-by-select';

  export default {
    name: 'filters',

    mixins: [
      basicFormMixin({
        modelKey: 'filters',
        mapPropsToData({ filters }) {
          return {
            country: filters?.country,
            fromDate: filters?.fromDate,
            toDate: filters?.toDate,
            product: filters?.product,
            groupBy: filters?.groupBy,
          };
        },
      }),
    ],

    computed: {
      fromDateMax() {
        if(!this.data.toDate) return;

        return moment(this.data.toDate).startOf('day').subtract(1, 'day').format();
      },
      toDateMin() {
        if(!this.data.fromDate) return;

        return moment(this.data.fromDate).subtract(1, 'day').startOf('day').format();
      },
    },

    components: {
      ProductSelect,
      DatePicker,
      CountrySelect,
      GroupBySelect,
    },

    methods: {
      onInputCountry(country) {
        if(country) this.data.country = country;
        else {
          Object.assign(this.data, {
            product: undefined,
          });
        }
      }
    },
  };
</script>

<style lang="sass">
  .filters
    .flex
      padding: 0 6px

    .v-btn
      padding-left: 32px
      padding-right: 32px
</style>