<script>
import { Line } from 'vue-chartjs';
import moment from 'moment';

export default {
  name: 'sentinel-semaforo',

  extends: Line,

  props: {
    semaforos: { type: String, default: () => '' },
  },

  mounted() {
    const last24MonthsList = this.semaforos
      .split('')
      .map((semaforo, index) => ({
        value: semaforo,
        monthYear: moment()
          .subtract(24 - index, 'months')
          .format('MM/YYYY'),
      }));

    const getColorAndLabel = (value) => {
      if (value === 'V') {
        return { color: '#00ff00', label: 'Sin deudas vencidas' };
      } else if (value === 'A') {
        return { color: '#ffff00', label: 'Deudas con poco atraso*' };
      } else if (value === 'R') {
        return { color: '#ff0000', label: 'Deudas con atraso significativo*' };
      } else {
        return { color: '#808080', label: 'No registra información de deudas' };
      }
    };

    const dataPoints = last24MonthsList.map(({ value, monthYear }) => {
      const { color, label } = getColorAndLabel(value);
      return {
        x: monthYear,
        y: 0,
        color,
        label,
      };
    });

    const uniqueLabels = {};
    const lastValue = dataPoints[dataPoints.length - 1].label;

    const datasets = [
      {
        label: 'Semáforo',
        responsive: true,
        pointBorderWidth: 4,
        pointBackgroundColor: dataPoints.map((point) => point.color),
        fill: false,
        tension: 0,
        borderColor: dataPoints.map((point) => point.color),
        data: dataPoints,
      },
    ];

    dataPoints.forEach((point) => {
      uniqueLabels[point.label] = point.color;
    });

    const legendLabels = Object.keys(uniqueLabels).map((label) => {
      const isLastValue = label === lastValue;
      const legendLabel = {
        text: isLastValue ? `${label} ⇦` : label,
        fillStyle: uniqueLabels[label],
        hidden: false,
        lineCap: 'round',
        lineDash: [],
        lineDashOffset: 0.0,
        lineJoin: 'round',
        lineWidth: 1,
        strokeStyle: uniqueLabels[label],
        pointStyle: 'circle',
      };

      return legendLabel;
    });

    this.renderChart(
      {
        labels: last24MonthsList.map(({ monthYear }) => monthYear),
        datasets,
      },
      {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
                stepSize: 0,
              },
              labels: ['Estado'],
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const point =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return `${point.label}: ${tooltipItem.xLabel}`;
            },
          },
        },
        legend: {
          display: true,
          labels: {
            generateLabels: function () {
              return legendLabels;
            },
          },
        },
      },
    );
  },
};
</script>