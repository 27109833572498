<template lang="pug">
  i Este simulador tiene carácter referencial, por lo tanto no es vinculante ni constituye declaración ni genera responsabilidad para RebajaTusCuentas o el banco. Las cuotas del cronograma definitivo pueden variar respecto de las indicadas en este simulador en función a la aprobación crediticia, a las fechas de desembolso y al número de días considerados en el periodo de gracia.
</template>

<script>
  export default {
    name: 'disclaimer',
  };
</script>

<style lang="sass" scoped>

</style>