<template lang="pug">
  div(:class="b()")
    deals-product(
      v-for="product in products"
      :key="product.slug"
      :product="product"
    )
</template>

<script>
  import DealsProduct from './deals-products-item';

  export default {
    name: 'deals-products',

    props: {
      products: { type: Array, default: () => [] },
    },

    components: { DealsProduct },
  };
</script>

<style lang="sass" scoped>

</style>