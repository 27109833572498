<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") {{ year.year }}
    div(:class="b('amount')") {{ amount | formatCurrency }}
    div(:class="b('type')") {{ type }}
</template>

<script>
export default {
  name: 'tax-report-essalud-reg',

  props: {
    year: { type: Object, required: true },
  },

  computed: {
    amount() {
      return Number(this.year.nrus || this.year.rer);
    },

    type() {
      return this.year.nrus ? 'NRUS' : 'RER';
    },
  },
};
</script>

<style lang="sass" scoped>
  .tax-report-essalud-reg
    background-color: darken(white, 6%)
    padding: 12px
    border-radius: 6px

    text-align: center

    &__title
      font-size: 20px
      font-weight: bold
      color: darken(white, 50%)

    &__amount
      font-size: 25px
      font-weight: 500

    &__type
      font-size: 18px
      font-weight: bold

</style>