<template lang="pug">
  tr
    td.font-weight-bold {{ entry.borrower.firstName }} {{ entry.borrower.lastName }}
    td(v-for="line, idx in entry.lines" :key="idx").text-no-wrap
      template(v-if="line.value") {{ parseValue(line.value) }}
</template>

<script>
export default {
  name: 'borrowers-aums-table-body-row',

  props: {
    entry: { type: Object, required: true },
    parseValue: { type: Function },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-aums-table-row
</style>