<template lang="pug">
  .data-card
    .data-card__title.d-inline-flex.align-center
      span Resumen de operación # {{ investmentProposal.id }}
      v-chip(v-if="isRescheduled" color="#c37432" text-color="#ffffff" small).ml-2 Reprogramacion
    .data-card__body
      div(v-if="isPendingStatus" :class="b('name')") {{ investorFullName }} - {{ investmentProposal.investor.vat }}
      v-layout(wrap)
        v-flex(xs6)
          .data-row
            b Crédito:&nbsp;
            span {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}
          .data-row
            b Plazo:&nbsp;
            span {{ investmentProposal.quotesQuantity }} meses
          .data-row
            b Valor comercial de la propiedad:&nbsp;
            span {{ investmentProposal.propertyValue | formatCurrency(investmentProposal.currency) }}
          .data-row
            b Valor de realización de la propiedad:&nbsp;
            span {{ investmentProposal.propertyAuctionValue | formatCurrency(investmentProposal.currency) }}
          .data-row
            b Fecha de desembolso:&nbsp;
            span {{ investmentProposal.disbursementDate | formatDate('DD MMMM YYYY') }}
          .data-row
            b Fecha de primera cuota:&nbsp;
            span {{ investmentProposal.firstPaymentDate | formatDate('DD MMMM YYYY') }}
        v-flex(xs6)
          .data-row
            b Cuota Simple:&nbsp;
            span {{ simpleFee | formatCurrency(investmentProposal.currency) }}
          .data-row(v-if="Boolean(doubleFee)")
            b Cuota Doble:&nbsp;
            span {{ doubleFee | formatCurrency(investmentProposal.currency) }}
          .data-row
            b TEA:&nbsp;
            span {{ tea | formatNumber }}%
          .data-row
            b TREA:&nbsp;
            span {{ tea | formatNumber }}%
          .data-row
            b Moneda:&nbsp;
            span {{ investmentProposal.currency.name }}
</template>

<script>
  import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';
  import balloonPaymentType from '@/filters/balloon-payment-type';

  export default {
    name: 'proposal-card',

    props: {
      investmentProposal: { type: Object },
      simpleFee: { type: Number },
      doubleFee: { type: Number },
      tea: { type: Number },
      trea: { type: Number },
      isRescheduled: { type: Boolean },
    },

    computed: {
      investorFullName() {
        return `${this.investmentProposal.investor.firstName} ${this.investmentProposal.investor.lastName}`;
      },

      isPendingStatus() {
        return this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.PENDING ;
      },
    },

    filters: { balloonPaymentType },
  };
</script>

<style lang="sass" scoped>
  .proposal-card
    &__name
      font-size: 19px
      font-weight: 500
      text-align: center
      margin-bottom: 8px
</style>