<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="400")
    payment-form(
      :requesting="fetching"
      :default-values="defaultValues"
      :rules="rules"
      :disabled-fields="disabledFields"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import FormModal from '@/components/modals/base-form-modal';
  import PaymentForm from './quote-payment-form';

  export default {
    name: 'quote-payment-form-modal',

    props: {
      value: { type: Boolean },
      fetching: { type: Boolean },
      defaultValues: { type: Object },
      rules: { type: Object },
      disabledFields: { type: Array, default: () => [] },
    },

    components: { FormModal, PaymentForm },
  };
</script>
