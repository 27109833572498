import { render, staticRenderFns } from "./tachometer.vue?vue&type=template&id=02b1fdfa&scoped=true&lang=pug"
import script from "./tachometer.vue?vue&type=script&lang=js"
export * from "./tachometer.vue?vue&type=script&lang=js"
import style0 from "./tachometer.vue?vue&type=style&index=0&id=02b1fdfa&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b1fdfa",
  null
  
)

export default component.exports