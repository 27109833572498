<template lang="pug">
  tr
    td {{ entry.borrower.firstName }} {{ entry.borrower.lastName }}
    td(
      v-for="line, idx in entry.lines"
      :key="idx"
      :style="{ backgroundColor: qualificationColor(line.qualification) }"
    ).text-no-wrap.font-weight-bold
      template(v-if="line.qualification==='SCH'") -
      template(v-else) {{ line.value }}
</template>

<script>
import qualificationColor from '@/utils/buro-qualification-color';

export default {
  name: 'borrowers-payment-days-diff-table-body-row',

  props: {
    entry: { type: Object, required: true },
    parseValue: { type: Function },
  },

  methods: {
    qualificationColor(qualification) {
      return qualificationColor(qualification) + '99' ;
    },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-payment-days-diff-table-row
</style>
