import { render, staticRenderFns } from "./library-categories-list-view.vue?vue&type=template&id=318fe4cf&scoped=true&lang=pug"
import script from "./library-categories-list-view.vue?vue&type=script&lang=js"
export * from "./library-categories-list-view.vue?vue&type=script&lang=js"
import style0 from "./library-categories-list-view.vue?vue&type=style&index=0&id=318fe4cf&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318fe4cf",
  null
  
)

export default component.exports