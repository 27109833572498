export default ({
  itemsKey = 'items',
} = {}) => ({
  props: {
    defaultItems: { type: Array, default:() => [] },
    lazy: { type: Boolean, default: true },
  },

  data() {
    return {
      innerItems: [...this.defaultItems],
      focused: false,
    };
  },

  computed: {
    $_lazySelectMixin_currentItems() {
      return this[itemsKey] || [];
    },

    $_lazySelectMixin_innerItemsValues() {
      return this.innerItems.map(this.$_lazySelectMixin_getValueFromItem);
    },

    $_lazySelectMixin_currentItemsValues() {
      return this.$_lazySelectMixin_currentItems.map(this.$_lazySelectMixin_getValueFromItem);
    },

    lazySkipQuery() {
      return !this.focused;
    },

    /**
     * Si en los items actuales del select ya está el objeto seleccionado
     * ignoramos el guardado en innerItems y usamos el actual
     */
    lazyItems() {
      return [
        ...(this.innerItems || []).filter(
          item => !this.$_lazySelectMixin_currentItemsValues.includes(this.$_lazySelectMixin_getValueFromItem(item))
        ),
        ...this.$_lazySelectMixin_currentItems,
      ];
    },
  },

  methods: {
    onFocus() {
      this.focused = true;
    },

    $_lazySelectMixin_getValueFromItem(item) {
      return item?.[this.itemValue || 'value'];
    },

    $_lazySelectMixin_addInnerItem(item) {
      // Solo agregamos el item si no está actualmente
      if(!this.$_lazySelectMixin_innerItemsValues.includes(this.$_lazySelectMixin_getValueFromItem(item))) {
        this.innerItems.push(item);
      }
    },

    $_lazySelectMixin_onDefaultItems(items) {
      items.forEach(this.$_lazySelectMixin_addInnerItem);
    },

    /**
     * Si se cambia la opcion del select lo guardamos como innerItems
     * en caso de que la lista de cambie y no se tenga el objeto original
     */
    $_lazySelectMixin_onChangeValue(value) {
      const item = this.$_lazySelectMixin_currentItems.find(item => this.$_lazySelectMixin_getValueFromItem(item) === value);

      if(item) this.$_lazySelectMixin_addInnerItem(item);
    },
  },

  watch: {
    defaultItems: {
      inmediate: true,

      handler(items) {
        this.$nextTick(() => {
          this.$_lazySelectMixin_onDefaultItems(items);
        });
      },
    },

    value(newValue) {
      this.$_lazySelectMixin_onChangeValue(newValue);
    },
  },

  mounted() {
    if(!this.lazy) this.onFocus();
  },
});
