<template lang="pug">
  tr(:class="b()")
    td
      b ({{referral.vatType}})
      |&nbsp; {{ referral.vat }}
    td
      a(:href="emailLink") {{ referral.email }}
    td.test
      a(:href="mobileCallLink") {{ referral.mobile }}
</template>

<script>
export default {
  name: 'referrals-table-row',

  props: {
    referral: { type: Object, required: true },
  },

  computed: {
    mobileCallLink() {
      return `tel:${this.referral.mobile}`;
    },

    emailLink() {
      return `mailto:${this.referral.email}`;
    },

    isVerified() {
      return Boolean(this.referral.verifiedDsUser);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>