<template lang="pug">
  table.buro-table
    thead
      tr
        th Banco
        th Linea de crédito
        th Linea no utilizada
        th Linea utilizada
        th Disposición efectivo
        th(style="min-width:90px") Otros
        th(style="min-width:90px") Total
    tbody
      table-row(
        v-for="card, idx in parsedCards"
        :key="idx"
        :card="card"
      )
    tfoot
      tr
        td.font-weight-bold Total
        td S/ {{ parsedTotal.creditLine | formatNumber }}
        td S/ {{ parsedTotal.lineNoUtil | formatNumber }}
        td S/ {{ parsedTotal.lineUtil | formatNumber }}
        td S/ {{ parsedTotal.creDisEfe | formatNumber }}
        td S/ {{ parsedTotal.others | formatNumber }}
        td S/ {{ parsedTotal.total | formatNumber }}
</template>

<script>
  import $update from 'immutability-helper';
  import TableRow from './credit-cards-table-row';

  export default {
    name: 'credit-cards-table',

    props: {
      cards: { type: Array, default: () => [] },
      total: { type: Object, required: true },
    },

    computed: {
      parsedCards() {
        return this.cards.map(card => ({
          ...card,
          total: [
            'lineUtil',
            'tarjCreDisEfe',
            'tarjCredOtros',
          ].map(key => Number(card[key] || 0))
            .reduce((acc, current) => acc + current, 0),
          }))
      },

      parsedTotal() {
        return {
          ...this.total,
          ...this.parsedCards.reduce(
            (acc, card) => $update(acc, {
              others: { $apply: value => value + Number(card.tarjCredOtros) },
              creDisEfe: { $apply: value => value + Number(card.tarjCreDisEfe) },
              total: { $apply: value => value + Number(card.total) },
            }),
            { others: 0, creDisEfe: 0, total: 0 },
          )
        };
      },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
</style>