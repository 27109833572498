<template lang="pug">
  table(:class="b()").rtc-metrics-table.rtc-metrics-table--striped
    forecast-table-head
    forecast-table-body(:entries="entries")
</template>

<script>
import ForecastTableHead from './forecast-table-head';
import ForecastTableBody from './forecast-table-body';

export default {
  name: 'forecast-table',

  props: {
    entries: { type: Array, default: () => [] },
  },

  components: { ForecastTableHead, ForecastTableBody },
};
</script>