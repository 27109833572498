<template lang="pug">
  tag(:color="color") {{ $t(`appraisalStatus.${status}`) }}
</template>

<script>
  import Tag from '@/components/UI/tag';
  import { APPRAISAL_STATUS } from '@/utils/constants';

  const COLORS = {
    [APPRAISAL_STATUS.EXCEPTION]: '#ffc107',
    [APPRAISAL_STATUS.APPROVED]: '#4caf50',
    [APPRAISAL_STATUS.IN_REVIEW]: '#cc51ff',
    [APPRAISAL_STATUS.DENIED]: '#f44336',
  };

  export default {
    name: 'appraisal-status-tag',

    props: {
      status: { type: String, required: true },
    },

    computed: {
      color() {
        return COLORS[this.status];
      },
    },

    components: { Tag },
  };
</script>
