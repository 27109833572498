<template lang="pug">
  v-form(@submit.prevent="onSubmit")
    template(v-if="customer")
      div(:class="b('title')") Titular
      v-checkbox(v-model="data.cutomer.dni" label="DNI del titular coincide con el documento y es legible" hide-details)
      v-checkbox(v-model="data.cutomer.mobile" hide-details)
        template(v-slot:label)
          div Celular del titular válido: (#[span.font-weight-medium {{ customer.mobile }}])
      v-checkbox(v-model="data.cutomer.email" hide-details)
        template(v-slot:label)
          div Correo del titular válido: (#[span.font-weight-medium {{ customer.email }}])
      v-checkbox(v-model="data.cutomer.address" hide-details)
        template(v-slot:label)
          div Dirección: #[span.font-weight-medium {{ address }}]
      v-checkbox(v-model="data.cutomer.dependents" hide-details)
        template(v-slot:label)
          div Hijos: (#[span.font-weight-medium {{ customer.dependents }}])
      v-checkbox(v-model="data.cutomer.maritalStatus" hide-details)
        template(v-slot:label)
          div Situación civil: (#[span.font-weight-medium {{ maritalStatus }}])
      v-checkbox(v-model="data.cutomer.profession" hide-details)
        template(v-slot:label)
          div Ocupación: (#[span.font-weight-medium {{ customer.profession }}])
    template(v-if="couple")
      div(:class="b('title')").mt-4 Pareja
      v-checkbox(v-model="data.couple.dni" label="DNI de la pareja coincide con el documento y es legible" hide-details)
      v-checkbox(v-model="data.couple.email" hide-details)
        template(v-slot:label)
          div Correo de la pareja válido: (#[span.font-weight-medium {{ couple.email }}])
      v-checkbox(v-model="data.couple.profession" hide-details)
        template(v-slot:label)
          div Ocupación de la pareja: (#[span.font-weight-medium {{ couple.profession }}])

    div(:class="b('title')").mt-4 Hipoteca
    div(v-if="currentCredit" :class="b('current-credit')").mt-2
      span.font-weight-medium {{ currentCredit.bank.name}}
      span.ml-2 {{ currentCredit.credit | formatCurrency(currentCredit.currency) }}
    v-switch(v-model="data.mustNewMortgage" :disabled="!currentCredit" label="¿Hay que levantar la hipoteca en el inmueble a dejar en garantía" hide-details)
    div(v-if="data.mustNewMortgage").pl-4.pt-2
      v-checkbox(v-model="data.newMortgageDocuments" label="Adjuntó la documentación que validan el estado de la hipoteca actual" hide-details)
    div(v-if="data.mustNewMortgage").pl-4.pt-2
      v-text-field(v-model="data.privateInvestor" label="Nombre del inversionista privado" counter="100" hide-details)


    template(v-if="debts.length")
      div(:class="b('title')").mt-4 Deudas vigentes
      v-switch(v-model="data.payDebts" label="¿Va a cancelar deudas vigentes?" hide-details).mb-4

      debts-table(
        v-show="data.payDebts"
        v-model="data.selectedDebts"
        :debts="debts"
      )
      
    div(style="padding-top: 36px")
      v-btn(type="submit" :disabled="isDisabled" color="primary") Validar Información

</template>

<script>
import sortBy from 'lodash/sortBy';
import DebtsTable from './debts-table';
import formStepMixin from '../form-step-mixin';

export default {
  name: 'personal-form-step',

  mixins: [formStepMixin()],

  props: {
    deal: { type: Object },
    customer: { type: Object },
    couple: { type: Object },
    buro: { type: Object },
  },

  data() {
    return {
      data: {
        cutomer: {
          dni: false,
          mobile: false,
          email: false,
          address: false,
          dependents: false,
          maritalStatus: false,
          profession: false,
        },
        couple: {
          dni: false,
          email: false,
          profession: false,
        },

        mustNewMortgage: false,
        newMortgageDocuments: false,

        payDebts: false,
        selectedDebts: [],
        ...this.defaultValues,
      },
    };
  },

  computed: {
    isDisabled() {
      return (
        Object.values(this.data.cutomer).includes(false)
        || (this.couple ? Object.values(this.data.couple).includes(false) : false)
        || (this.data.mustNewMortgage && this.data.newMortgageDocuments === false)
        || (this.data.payDebts && this.data.selectedDebts.length === 0)
        || this.disabled
      );
    },

    address() {
      const address = this?.customer?.address;

      return address ?
        [
          address.line1,
          ...address.location.map(({ name }) => name),
        ].join(', ')
        : null;
    },

    maritalStatus() {
      return this.$t(`maritalStatus.${this.customer?.maritalStatus?.status}`)
    },

    debts() {
      const finalEntries = [];

      if (!this.buro) return finalEntries;

      (this.buro?.lastPeriodDebts || []).forEach(({ bank, product, qualification, amount }) => {
        finalEntries.push({ bank, product, qualification, totalAmount: amount })
      });

      return sortBy(finalEntries, ['bank']).filter(({ totalAmount }) => totalAmount > 0);
    },

    currentCredit() {
      return this.customer?.properties?.[0]?.credit;
    },
  },

  methods: {
    onSubmit() {
      this.$emit('submit', { data: this.data });
    },
  },

  components: {
    DebtsTable,
  },
};
</script>

<style lang="sass" scoped>
  .personal-form-step
    &__title
      font-size: 16px
      font-weight: bold
      color: darken(white, 40%)
      margin-bottom: 8px

    &__current-credit
      display: inline-block
      padding: 2px 14px
      background-color: darken(white, 4%)
      border-radius: 6px
</style>
