<script>
import moment from 'moment';
import { Bar } from 'vue-chartjs';
import { groupBy, map, pipe, find, capitalize, get } from 'lodash/fp';
import uniqolor from 'uniqolor';
import { formatNumber, formatCurrency } from '@/filters/accounting';

export default {
  name: 'tax-report-essalud-chart',

  extends: Bar,

  props: {
    entries: { type: Array, default: () => [] },
    dataKey: { type: String },
    title: { type: String, default: 'Ventas' },
  },

  computed: {
    entriesByYear() {
      return groupBy('year')(this.entries);
    },

    datasets() {
      return pipe(
        Object.values,
        map(
          (entries) => {
            const label = `${this.title} ${entries[0].year}`
            const { color } = uniqolor(label, { format: 'rgb' });

            return {
              label: label,
              pointBorderWidth: 4,
              pointBackgroundColor: color,
              borderColor: color,
              backgroundColor: color.slice(0, color.length - 1) + ',0.7)',
              data: Array.from({ length: 12 })
                .map((_, monthIndex) => pipe(
                  find({ month: monthIndex + 1 }),
                  get(this.dataKey)
                )(entries))
            };
          },
        ),
      )(this.entriesByYear);
    },

    labels() {
      return Array.from({ length: 12}).map((_, index) => capitalize(moment().month(index).format('MMMM')));
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{ stacked: true, categoryPercentage: 0.5, barPercentage: 1}],
          yAxes: [
            {
              stacked: true,
              ticks: {
                callback(value) {
                  return 'S/ ' + formatNumber(value);
                },
              },
            },
          ],
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            label: ({ value, datasetIndex }) => {
              return value > 0 ? [`${this.datasets[datasetIndex].label}: ${formatCurrency(value)}`] : false;
            },
          },
        },
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>