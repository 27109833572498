<template lang="pug">
  div(:class="b()")
    div(:class="b('body')")
      div(:class="b('title')") {{ stage.name }}
      div(:class="b('subtitle')") {{ stage.dealsCount }} Negociaciones
    div(:class="b('amount')") {{ stage.creditAmount | formatCurrency(currency) }}
</template>

<script>
  export default {
    name: 'product-stages-item',

    props: {
      stage: { type: Object, required: true },
      currency: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  .product-stages-item
    display: flex
    align-items: center
    padding: 8px 0

    &__body
      flex: 1

    &__title
      font-size: 14px
      font-weight: 500
      margin-bottom: 6px

    &__subtitle
      font-size: 12px
      color: #606060

    &__amount
      font-size: 18px
      font-weight: bold
      white-space: nowrap
</style>