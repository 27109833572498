<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Requisitos
    div(:class="b('summary')")
      count-badge(
        v-for="group, idx in groups"
        :key="idx"
        :count="group.count"
        :color="group.color"
      )
    div(:class="b('actions')")
      v-btn(@click="onClickAdd" color="accent" text small icon)
        v-icon(color="primary") mdi-plus
</template>

<script>
  import $update from 'immutability-helper';
  import CountBadge from './count-badge';

  export default {
    name: 'requirements-card-header',

    props: {
      requirements: { type: Array, default: () => [] },
    },

    computed: {
      groups() {
        return Object.values(this.requirements.reduce(
          (obj, { status }) => $update(obj, {
            [status.name]: {
              $apply: cStatus => ({
                count: (cStatus?.count || 0) + 1,
                color: status?.color,
              }),
            },
          }),
          {},
        ));
      },
    },

    components: { CountBadge },


    methods: {
      onClickAdd() {
        this.$emit('add');
      },
    },
  };
</script>

<style lang="sass" scoped>
  .requirements-card-header
    display: flex
    align-items: center

    &__title
      margin-right: .75rem

      font-size: 16px
      font-weight: 500

    &__summary
      display: flex
      align-items: center

    &__summary
      flex: 1

</style>