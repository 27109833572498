<template lang="pug">
  titled-card(title="Tasaciones")
    div(slot="actions")
      v-btn(@click.native="onClickAdd" text small color="primary").ma-0.px-1
        span Agregar
        v-icon mdi-plus
    div(:class="b('body')")
      appraisals-list(
        :appraisals="appraisals"
        @click-edit="onClickEdit"
        @click-add="onClickAdd"
      )
    appraisal-form-modal(
      v-if="formModal"
      v-model="formModal"
      :appraisal="editingEntity"
      :requesting="requesting"
      :persistent="false"
      @submit="onSubmit"
    )
</template>

<script>
  import AppraisalFormModal from '@/components/modals/appraisal-form-modal';
  import TitledCard from '@/components/UI/titled-card';
  import gestionableListMixin from '@/mixins/gestionable-list-mixin';

  import AppraisalsList from './appraisals-list';

  export default {
    name: 'appraisals-card',

    props: {
      appraisals: { type: Array, default: () => ([]) },
      requesting: { type: Boolean, default: true },
    },

    mixins: [
      gestionableListMixin({ entityName: 'appraisal' }),
    ],

    components: { TitledCard, AppraisalFormModal, AppraisalsList },
  };
</script>

<style lang="sass" scoped>

</style>