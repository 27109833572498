import VSelect from 'vuetify/lib/components/VSelect';
import Config from '@/config';

export default {
  name: 'gender-type-select',

  data() {
    return {
      types: Config.GENDER_TYPE_SELECT,
    };
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.types,
          loading: this.types.loading,
          label: this.$attrs.label || 'Género',
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};
