<template lang="pug">
  v-dialog(
    :value="value"
    width="900"
    @input="args => $emit('input', args)"
  )
    v-card.pa-4
      v-stepper(
        v-model="currentStep"
        vertical
      )
        v-stepper-step(
          step="1"
          :complete="currentStep > 1"
        ) Sobre la persona que presenta el préstamo
          small Confirmar datos
        v-stepper-content(step="1")
          personal-form-step(
            :deal="deal"
            :customer="customer"
            :couple="couple"
            :buro="buroResults"
            :default-values="answers && answers.personalStep"
            :disabled="throttlingDisabler"
            @update-answers="({ data }) => onUpdateAnswers(data, 'personalStep')"
            @submit="({ data }) => onSubmitStep(data, 'personalStep')"
          )

        v-stepper-step(
          step="2"
          :complete="currentStep > 2"
        ) Sobre el inmueble que se deja en garantía
        v-stepper-content(step="2")
          property-form-step(
            :deal="deal"
            :customer="customer"
            :requesting="requesting"
            :default-values="answers && answers.propertyStep"
            :disabled="throttlingDisabler"
            @update-answers="({ data }) => onUpdateAnswers(data, 'propertyStep')"
            @submit="({ data }) => onSubmitStep(data, 'propertyStep')"
            @cancel="goToPreviousStep"
          )

        v-stepper-step(
          step="3"
          :complete="currentStep > 3"
        ) Confirmación de ingresos
        v-stepper-content(step="3")
          incomes-form-step(
            :customer="customer"
            :couple="couple"
            :requesting="requesting"
            :default-values="answers && answers.incomeStep || {}"
            :disabled="throttlingDisabler"
            @update-answers="({ data }) => onUpdateAnswers(data, 'incomeStep')"
            @submit="({ data }) => onSubmitStep(data, 'incomeStep')"
            @cancel="goToPreviousStep"
          )

        v-stepper-step(
          step="4"
          :complete="currentStep > 4"
        ) Enviar expediente a riesgos
        v-stepper-content(step="4")
          risk-file-form-step(
            :deal="deal"
            :requesting="requesting"
            :default-values="answers && answers.riskFile"
            :disabled="throttlingDisabler"
            @update-answers="({ data }) => onUpdateAnswers(data, 'riskFile')"
            @submit="({ riskFile }) => onSubmitStep(riskFile, 'riskFile', true)"
            @cancel="goToPreviousStep"
          )
</template>

<script>
import humps from 'humps';
import { PEBuroQuery } from '@/components/smart/debts/graphql/queries.gql';
import PersonalFormStep from './personal-form-step';
import PropertyFormStep from './property-form-step';
import RiskFileFormStep from './risk-file-form-step';
import IncomesFormStep from './incomes-form-step';

export default {
  name: 'create-risk-file-modal',

  props: {
    value: { type: Boolean, default: false },
    deal: { type: Object },
    customer: { type: Object },
    couple: { type: Object },
    requesting: { type: Boolean, default: false },
    answers: { type: Object, default: () => ({}) },
  },

  apollo: {
    buroResults: {
      query: PEBuroQuery,
      fetchPolicy: 'cache-first',
      manual: true,

      variables() {
        return {
          userId: this.customer.id,
          country: 'PE',
          vat: this.customer.vat,
          docTypeId: this.customer.vatType?.id,
          full: this.fullQuery,
          reportType: 'equifax'
        };
      },

      result({ data }) {
        this.buroResults = data?.buro ? humps.camelizeKeys(data.buro.data) : null;
      },

      skip() {
        return !this.customer || !this.value;
      },
    }
  },

  data() {
    return {
      currentStep: 1,
      throttlingDisabler: false,
      buroResults: null,
    };
  },

  methods: {
    goToNextStep() {
      this.currentStep++;
      this.startThrottling();
    },

    goToPreviousStep() {
      this.currentStep--;
    },

    startThrottling() {
      this.throttlingDisabler = true;

      setTimeout(() => {
        this.throttlingDisabler = false;
      }, 3000);
    },

    onUpdateAnswers(data, stepName) {
      this.$emit('update-answers', {
        ...this.answers,
        [stepName]: { ...data },
      });
    },

    onSubmitStep(data, stepName, emit = false) {
      this.onUpdateAnswers(data, stepName);

      if(emit) this.$emit('submit', { ...this.answers, [stepName]: data })
      else this.goToNextStep();
    },
  },

  watch: {
    value(val) {
      if(val) this.startThrottling();
    }
  },

  components: {
    PersonalFormStep,
    PropertyFormStep,
    RiskFileFormStep,
    IncomesFormStep,
  },
};
</script>

<style lang="sass" scoped>

</style>
