<template lang="pug">
  tr
    td {{ card.bank }}
    td S/ {{ card.creditLine | formatNumber }}
    td S/ {{ card.lineNoUtil | formatNumber }}
    td S/ {{ card.lineUtil | formatNumber }}
    td S/ {{ card.tarjCreDisEfe | formatNumber }}
    td.text-no-wrap S/ {{ card.tarjCredOtros | formatNumber }}
    td.text-no-wrap S/ {{ card.total | formatNumber }}
</template>

<script>
  export default {
    name: 'credit-cards-table-row',

    props: {
      card: { type: Object },
    },
  };
</script>

<style lang="sass" scoped>

</style>