import compact from 'lodash/compact';

export default address => {
  if(!address?.location?.length) return '';

  return compact([
    address.location[0]?.name,
    address.zipCode || null,
    ...address.location.slice(1).map(({ name }) => name),
    address.line1,
  ]).reverse()
    .join(', ');
};
