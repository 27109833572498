<template lang="pug">
  tr(:class="b()")
    td
      v-checkbox(v-model="entry.selected" @change="onChangeCheck" hide-details style="margin-top: 0")
    td {{ entry.bank }}
    td {{ entry.product }}
    td(:style="{ color: calificationColor }").font-weight-bold {{ entry.qualification | formatCalification }}
    td.text-no-wrap S/ {{ entry.totalAmount | formatNumber }}
    td
      input(
        v-if="entry.selected"
        :value="entry.amount"
        @input="onInputPayment"
        style="width: 120px"
      )
    td
      span(v-if="entry.selected") {{ paymentPercent.toFixed(2) }} %
</template>

<script>
  import formatCalification from '@/utils/format-buro-qualification';
  import getCalificationColor from '@/utils/buro-qualification-color';

  export default {
    name: 'debts-table-row',

    props: {
      entry: { type: Object, required: true },
      paymentPercent: { type: Number },
    },

    computed: {
      calificationColor() {
        return getCalificationColor(this.entry.qualification);
      },
    },

    methods: {
      onChangeCheck(value) {
        this.$emit(value ? 'select' : 'unselect');
      },

      onInputPayment(e)  {
        this.$emit('update-payment-amount', Number(e.target.value));
      },
    },

    filters: {
      formatCalification,
    },
  };
</script>

<style lang="sass" scoped>
  .debts-table-row
    td
      padding: 3px 8px
      border-bottom: 1px solid darken(white, 6%)

      &::nth-child(5)
        min-width: 150px
</style>