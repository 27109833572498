<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      div(:class="b('form-group')")
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Amortización
          div(:class="b('input-group-container')")
            v-text-field(
              :value="data.amortization"
              :prefix="currency.symbol"
              type="number"
              name="amortization"
              step="0.01"
              readonly
              disabled
            )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Intereses
          div(:class="b('input-group-container')")
            validation-provider(name="interest" :rules="rules.interest" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.interest"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="interest"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Desgravamen
          div(:class="b('input-group-container')")
            validation-provider(name="lifeInsurance" :rules="rules.lifeInsurance" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.lifeInsurance"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="lifeInsurance"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Seguro de Inmueble
          div(:class="b('input-group-container')")
            validation-provider(name="propertyInsurance" :rules="rules.propertyInsurance" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.propertyInsurance"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="propertyInsurance"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Mora
          div(:class="b('input-group-container')")
            validation-provider(name="defaultAmount" :rules="rules.defaultAmount" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.defaultAmount"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="defaultAmount"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Penalidad
          div(:class="b('input-group-container')")
            validation-provider(name="penaltyAmount" :rules="rules.penaltyAmount" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.penaltyAmount"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="penaltyAmount"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Costos Procesales
          div(:class="b('input-group-container')")
            validation-provider(name="legalExpenses" :rules="rules.legalExpenses" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.legalExpenses"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="legalExpenses"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Costas Procesales
          div(:class="b('input-group-container')")
            validation-provider(name="collectingExpenses" :rules="rules.collectingExpenses" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.collectingExpenses"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="collectingExpenses"
                step="0.01"
              )
        v-divider
        div(:class="b('input-group')")
          div(:class="b('input-group-title')").font-weight-bold Total
          div(:class="b('input-group-container')")
            v-text-field(
              :value="totalAmount"
              :prefix="currency.symbol"
              type="number"
              readonly
            )
        div.pb-3
          refinance-type-radio(
            v-model="data.type"
            name="tipo"
          )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar cambios
</template>

<script>
import basicFormMixin from './basic-form-mixin';
import RefinanceTypeRadio from "@/components/inputs/refinance-type-radios.vue";
import { REFINANCE_TYPE } from '@/utils/constants';

export default {
  name: 'investment-refinance-form',

  props: {
    currency: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'refinance',
      veeValidation: true,

      mapPropsToData({ refinance }) {
        return {
          amortization: refinance.amortization,
          interest: refinance.interest,
          lifeInsurance: refinance.lifeInsurance,
          propertyInsurance: refinance.propertyInsurance,
          defaultAmount: refinance.defaultAmount,
          penaltyAmount: refinance.penaltyAmount,
          legalExpenses: refinance.legalExpenses,
          collectingExpenses: refinance.collectingExpenses,
          type: refinance.type || REFINANCE_TYPE.EXTENDED,
        };
      },
    }),
  ],

  computed: {
    rules() {
      return {
        interest: { required: true, min_value: 0 },
        lifeInsurance: { required: true, min_value: 0 },
        propertyInsurance: { required: true, min_value: 0 },
        defaultAmount: { required: true, min_value: 0 },
        penaltyAmount: { required: true, min_value: 0 },
        legalExpenses: { required: true, min_value: 0 },
        collectingExpenses: { required: true, min_value: 0 },
        type: { required: true },
      };
    },

    totalAmount() {
      return Object.values(this.data)
        .map(amount => parseFloat(amount) || 0)
        .reduce((acc, amount) => amount + acc, 0)
        .toFixed(2);
    },
  },

  components: { RefinanceTypeRadio },
};
</script>

<style lang="sass" scoped>
  .investment-refinance-form
    &__input-group
      width: 100%
      display: flex
      padding-left: 8px
      padding-right: 8px
      align-items: center

      &:nth-child(odd)
        background-color: darken(white, 2%)


      &-title
        flex: 1
        min-width: 120px
</style>
