<template lang="pug">
  div(:class="b()")
    img(:src="$projectConfig.COMPANY_LOGO" :class="b('logo')")
    template(v-if="bankLogo")
      span(:class="b('plus-icon')") +
      img(v-if="bankLogo" :src="bankLogo" :class="b('bank-logo')")
</template>

<script>
  export default {
    name: 'view-header',

    props: {
      bankLogo: { type: String },
    },
  };
</script>

<style lang="sass" scoped>
  .view-header
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 24px

    &__logo
      height: 42px

    &__bank-logo
      height: 38px

    &__plus-icon
      font-size: 24px
      margin: 0 12px
</style>