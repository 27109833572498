import VueRouter from 'vue-router';
import Config from '@/config';
import routes from './routes';

const router = new VueRouter({
  mode: 'history',
  base: Config.BASE_PATH,
  routes,
});

export default router;
