<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      loading-wrapper(
        v-if="$apollo.queries.categories.loading || categoriesError || !categories"
        :error="categoriesError"
        @retry="onRetryCategoriesFetch"
      )
      category-group(v-else :category="currentCategory")
</template>

<script>
import LibraryCategoriesListView from './library-categories-list-view';
import CategoryGroup from './components/categories/category-group';

export default {
  name: 'library-category-show-view',

  extends: LibraryCategoriesListView,

  computed: {
    currentCategory() {
      return this.categories.find(
        ({ id }) => this.$route.params.categoryId === id,
      );
    },
  },

  components: { CategoryGroup },
};
</script>

<style lang="sass" scoped>
@import '~@/sass/mixins'

.library-category-show-view
  &__container
    padding: 32px 16px
    max-width: 1400px
    margin: 0 auto
</style>
