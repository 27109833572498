<template lang="pug">
  div(:class="b()" @click="onClickRemove")
    div(:class="b('key')") {{ filter.name }}
    div(:class="b('value')") {{ filter.text }}
    div(:class="b('close')")
      v-icon mdi-close
</template>

<script>
  export default {
    name: 'filters-list-item',

    props: {
      filter: { type: Object, required: true },
    },

    methods: {
      onClickRemove() {
        this.$emit('remove', this.filter.key);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .filters-list-item
    display: flex
    margin: 0 .5rem .5rem .5rem

    line-height: 1.8em
    background-color: white
    border-radius: 4px
    overflow: hidden
    cursor: pointer
    white-space: nowrap

    &__key
      padding: 0rem .8rem

      color: white
      background-color: $primaryColor

    &__value
      padding: 0 .5rem
      color: black

    &__close
      display: flex
      flex-shrink: 0
      align-items: center
      justify-content: center
      padding: 0 .25rem

      line-height: 1rem
      background-color: #c5c5c5

      i
        font-size: 14px
        color: white
</style>