<template lang="pug">
  iframe(:src="metricsMetabase.url", frameborder="0" width="800" height="600", allowtransparency)
</template>

<script>
  import {
    MetricsMetabaseQuery,
  } from './graphql/queries.gql';

  import Config from '@/config';

  export default {
    name: 'denied-appraisals-view',

    data() {
      return {
        metricsMetabase: [],
      };
    },

    apollo: {
      metricsMetabase: {
        query: MetricsMetabaseQuery,
        variables() {
          return {
            country: Config.COUNTRY_CODE.toUpperCase(),
            report: 'DENIED_APPRAISAL'
          };
        },
      },
    },

    components: {

    },
  };
</script>