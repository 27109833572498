import AES from 'crypto-js/aes';
import UTF8 from 'crypto-js/enc-utf8';

const CRYPT_KEY ='iqO6z2512k_s@45k';

export const encrypt = params => encodeURI(AES.encrypt(JSON.stringify(params), CRYPT_KEY).toString());

export const decrypt = hash => JSON.parse(
  AES.decrypt(decodeURI(hash), CRYPT_KEY).toString(UTF8)
);
