<template lang="pug">
  tbody
    tr(v-for="item in items" :key="item.name")
      td {{ item.adviserEmail }}
      td.text-center {{ item.cnvNetDealToProposalsAccepted }}
      td.text-center {{ item.cnvProposalsAcceptedToCreditApplication }}
      td.text-center {{ item.cnvApprovalToClient }}
      td.text-center {{ item.cnvNetDealsToCreditApplications }}
      td.text-center {{ item.cnvNetDealsToApprovals }}
      td.text-center {{ item.cnvNetDealsToClients }}
</template>

<script>
  export default {
    name: 'advisers-conversion-table-body',

    props: {
      items: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>
