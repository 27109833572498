<template lang="pug">
  v-app
    v-container
      v-layout(justify-center wrap)
        v-flex(xs12 sm8 md6 lg5 xl4).pt-4.mt-4
          v-alert(
            v-if="error"
            :value="true"
            type="error"
          ) {{ error.message }}
          br
          v-card
            v-card-text
              img(:src="$projectConfig.COMPANY_LOGO" :class="b('logo')")
              login-form(
                @submit="login"
                :loading="isRequesting || $apollo.queries.currentUser.loading"
              )
    div(:class="b('version')") Version: #[span.font-weight-medium {{ version }}]
</template>

<script>
  import omit from 'lodash/omit';

  import currentUserMixin from '@/mixins/current-user-mixin';
  import { VerifyTokenMutation } from '@/graphql/mutations.gql';
  import { onLogin as loginToApollo } from '@/plugins/vue-apollo';
  import authStorage from '@/utils/auth-storage';
  import { APP_VERSION } from '@/utils/constants';

  import LoginMutation from './graphql/mutations.gql';
  import LoginForm from './components/login-form';

  export default {
    name: 'login-view',

    mixins: [currentUserMixin()],

    data() {
      return {
        isRequesting: false,
        error: null,
        version: APP_VERSION,
      };
    },

    methods: {
      async login({ username, password }) {
        this.isRequesting = true;
        this.error = null;

        try {
          authStorage.removeAll();

          const { data: { login: { token, errors }} } = await this.$apollo.mutate({
            mutation: LoginMutation,

            variables: {
              username,
              password,
            },
          });

          if(errors) throw new Error(errors[0].message);

          const { data: { verifyToken: { payload } } }  = await this.$apollo.mutate({
            mutation: VerifyTokenMutation,

            variables: { token },
          });

          authStorage.setCredentials({ token, expire: payload.exp });

          loginToApollo();

          this.goToNextRoute();
        } catch (e) {
          if((e.message ||'').includes('Please, enter valid credentials'))
            this.error = new Error('Usuario o contraseña inválidos')
          else
            this.error = e;
        } finally {
          this.isRequesting = false;
        }
      },

      goToNextRoute() {
        this.$router.replace({
          path: this.$route.query?.next || '/dashboard',

          query: {
            ...omit(this.$route.query, ['next']),
          },
        })
      },
    },

    created() {
      if(!authStorage.tokenHasExpired()) return this.goToNextRoute();
    },

    components: { LoginForm },
  };
</script>

<style lang="sass" scoped>
  .login-view
    &__logo
      max-width: 100%
      height: 60px
      display: block
      margin: 0 auto
      margin-bottom: 12px

    &__version
      position: absolute
      background-color: darken(white, 6%)
      padding: 3px 12px
      bottom: 0
      left: 0
</style>