<template lang="pug">
  div(:class="b()")
    v-card.pa-2
      v-breadcrumbs(
        :items="breadcrumbItems"
      )
    v-container(fluid)
      loading-wrapper(
        v-if="!formalization || formalizationError"
        :error="formalizationError"
        @retry="onRetryFetch"
      )
      v-layout(v-else wrap)
        v-flex(xs12 md4)
          formalization-card(
            :formalization="formalization"
            @change-status="onChangeStatusSelect"
            @click-edit="onClickEditFormalization"
          )
          formalization-form-modal(
            v-model="formalizationFormModal"
            :formalization="formalization"
            :requesting="isMutatingFormalization"
            :disabled-fields="formalizationFormDisabledFields"
            :investment-proposal-select-additional-search-params="investmentProposalSelectAdditionalSearchParams"
            :approved-appraisal="firstApprovedAppraisal"
            @submit="onSubmitUpdateFormalizationForm"
          )
        v-flex(xs12 md8)
          v-card.ma-3
            v-tabs(v-model="currentTab")
              v-tab Mensajes
              v-tab Referidos
              v-tab Contratos
              v-tab Documentos
              v-tab-item.pa-3
                messages(
                  ref="messages"
                  :requesting="isCreatingMessage"
                  :messages="formalization.messages || []"
                  :current-user="currentUser"
                  @create="onCreateMessage"
                  @delete="onDeleteMessage"
                )
              v-tab-item.pa-3
                referrals-tab(
                  ref="referrals"
                  :referrals="formalization.referrals"
                  :requesting="isMutatingReferral"
                  @create="onSubmitCreateReferralForm"
                  @update="onSubmitUpdateReferralForm"
                  @validate="onValidateReferral"
                )
              v-tab-item.pa-3
                contract-tab(
                  ref="contract"
                  :contract="contract"
                  :deal="formalization.deal"
                  :investment-proposal="formalization.investmentProposal"
                  :loading="$apollo.queries.contract.loading"
                  :requesting="isMutatingContract"
                  @create="onSubmitCreateContractForm"
                  @update="onSubmitUpdateContractForm"
                  @hook:mounted="skipContractQuery = false"
                )
              v-tab-item.pa-3
                customer-documents(
                  :customer-id="customer && customer.id"
                )
          v-layout(wrap)
            v-flex(xs12 md6)
              appraisals-card(
                ref="appraisals"
                :appraisals="appraisals"
                :requesting="isMutatingAppraisal"
                @create="onSubmitCreateAppraisalForm"
                @update="onSubmitUpdateAppraisalForm"
              )
            v-flex(xs12 md6)
              title-studies-card(
                ref="titleStudies"
                :title-studies="formalization.titleStudies || []"
                :requesting="isMutatingTitleStudy"
                :potential-owners="potentialTitleOwners"
                :adviser-id="adviserId"
                :formalization-id="formalization.id"
                @create="onSubmitCreateTitleStudyForm"
                @update="onSubmitUpdateTitleStudyForm"
              )
</template>

<script>
import compact from 'lodash/compact';
import pick from 'lodash/pick';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';
import Messages from '@/components/UI/messages';
import CustomerDocuments from '@/components/smart/customer-documents';
import FormalizationFormModal from '@/components/modals/formalization-form-modal';

import {
  FormalizationShowViewQuery,
  FormalizationShowViewContractQuery as ContractQuery,
} from './graphql/queries.gql';
import {
  UpdateFormalizationMutation,
  CreateFormalizationMessageMutation,
  DeleteFormalizationMessageMutation,
  CreateAppraisalMutation,
  UpdateAppraisalMutation,
  CreateTitleStudyMutation,
  UpdateTitleStudyMutation,
  CreateReferralMutation,
  UpdateReferralMutation,
  CreateContractMutation,
  UpdateContractMutation,
  VerifyReferralMutation,
} from './graphql/mutations.gql';
import FormalizationCard from './components/formalization-card';
import TitleStudiesCard from './components/title-studes-card';
import AppraisalsCard from './components/appraisals-card';
import ReferralsTab from './components/referrals-tab';
import ContractTab from './components/contract-tab';
import { APPRAISAL_STATUS, FORMALIZATION_STAGES, RISK_OPINION_STATUS, TITLE_STUDY_STATUS } from '@/utils/constants';

const parseTitleStudyFormDataToMutationInput = data => ({
  ...pick(data, [
    'status',
    'lienChargeLines',
    'comments',
    'status',
    'electronicRegistrationNumber',
    'electronicRegistrationNumberCity',
    'dangerZone',
    'nobleMaterial',
    'essentialServices',
    'itWorksAsSocialBuilding',
    'asphaltAndFullSidewalks',
    'hillside',
    'propertyTypeId',
  ]),
  lienChargeIds: data.liensChargesIds,
  owners: data.ownersIds.map(id => ({ dsUserId: id })),
  acquisition: data.acquisition,
});

export default {
  name: 'formalization-show-view',

  mixins: [
    mutationsHandlersMixin(),
    currentUserMixin(),
  ],

  apollo: {
    formalization: {
      query: FormalizationShowViewQuery,

      variables() {
        return {
          id: this.$route.params.formalizationId,
        };
      },

      error() {
        this.formalizationError = new Error('Ha ocurrido un error al obtener los datos');
      },

      skip() {
        return !this.$route.params?.formalizationId;
      },
    },

    contract: {
      query: ContractQuery,

      variables() {
        return {
          formalizationId: this.formalization.id,
        };
      },

      update({ formalization }) {
        return formalization.contract;
      },

      skip() {
        return this.skipContractQuery;
      },
    },
  },

  data() {
    return {
      currentTab: 0,
      contract: null,
      formalization: null,
      formalizationError: null,
      formalizationFormModal: false,
      skipContractQuery: true,
      isMutatingFormalization: false,
      isCreatingMessage: false,
      isMutatingAppraisal: false,
      isMutatingTitleStudy: false,
      isMutatingReferral: false,
      isMutatingContract: false,
    };
  },

  computed: {
    breadcrumbItems() {
      const customer = this?.formalization?.customer;

      return compact([
        { text: 'Formalizaciones', to: { name: 'formalizations.board'}, exact: true },
        this.formalization && { text: `${customer.firstName || ''} ${customer.lastName} (Solicitud #${this.formalization.deal.id}, Formalización #${this.formalization.id})` , disabled: true }
      ]);
    },

    customer() {
      return this.formalization?.customer;
    },

    appraisals() {
      return this.formalization?.appraisals || [];
    },

    titleStudies() {
      return this.formalization?.titleStudies || [];
    },


    potentialTitleOwners() {
      const fields = ['id', 'firstName', 'lastName', 'vat', 'email'];

      return compact([
        this.customer && pick(this.customer, fields),
        this.customer?.couple && pick(this.customer.couple, fields),
      ]);
    },

    firstApprovedAppraisal() {
      if (this.appraisals.find(({ status }) => status === APPRAISAL_STATUS.APPROVED) == null)
        return this.appraisals.find(({ status }) => status === APPRAISAL_STATUS.EXCEPTION)
      return this.appraisals.find(({ status }) => status === APPRAISAL_STATUS.APPROVED)
    },

    canSetInvestmentProposal() {
      const hasValidAppraisal = this.appraisals.some(({ status }) => [APPRAISAL_STATUS.APPROVED, APPRAISAL_STATUS.EXCEPTION].includes(status));
      const hasValidTitleStudy = this.titleStudies.some(({ status }) => [TITLE_STUDY_STATUS.APPROVED, TITLE_STUDY_STATUS.EXCEPTION].includes(status));

      return hasValidAppraisal && hasValidTitleStudy;
    },

    investmentProposalSelectAdditionalSearchParams() {
      return {
        status: [RISK_OPINION_STATUS.PENDING],
        borrowerIds: [this.customer?.id],
      };
    },

    formalizationFormDisabledFields() {
      const currentStage = this.formalization?.stage;

      const disabledFields = do{
        if(currentStage === FORMALIZATION_STAGES.PREPARATION)
          (['status', 'blockNumber', 'blockCity', 'blockDate', 'mortgageNumber', 'mortgageNumberStatus',  'mortgageNumberStatus2nd', 'attestationDate'])
        else if(currentStage === FORMALIZATION_STAGES.BLOCKING)
          (['status', 'mortgageNumber', 'mortgageNumberStatus', 'mortgageNumberStatus2nd', 'attestationDate',])
        else if (currentStage === FORMALIZATION_STAGES.DISBURSEMENT)
          (['status', 'blockNumber', 'blockCity', 'blockDate', 'notaryId', 'kardex'])
        else if (currentStage === FORMALIZATION_STAGES.MORTGAGE_REGISTRATION)
          (['status', 'blockNumber', 'blockCity', 'blockDate', 'notaryId', 'kardex'])
        else
          (['blockNumber', 'blockCity', 'blockDate', 'notaryId', 'kardex','mortgageNumber', 'attestationDate'])
      };

      return disabledFields.concat(this.canSetInvestmentProposal ? [] : ['investmentProposalId']);
    },

    adviserId() {
      return this.customer?.adviser?.id;
    },
  },

  methods: {
    getFormalizationQuery() {
      return {
        query: FormalizationShowViewQuery,
        variables: { id: this.$route.params.formalizationId },
      };
    },

    getContractQuery() {
      return {
        query: ContractQuery,
        variables: { id: this.formalization.id },
      };
    },

    updateFormalization({ formalization }) {
      return this.handleUpdateMutation({
        mutation: UpdateFormalizationMutation,
        requestingStateKey: 'isMutatingFormalization',

        variables: {
          data: {
            ...pick(formalization, [
              'id',
              'status',
              'notaryId',
              'kardex',
              'blockNumber',
              'blockCity',
              'blockDate',
              'mortgageNumber',
              'mortgageNumberStatus',
              'mortgageNumberStatus2nd',
              'attestationDate',
            ]),
            pcInvestmentProposalId: formalization.investmentProposalId,
          },
        },
      })
    },

    onSubmitUpdateFormalizationForm({ formalization }) {
      this.updateFormalization({ formalization })
        .then(() => {
          this.formalizationFormModal = false;
        });
    },

    onChangeStatusSelect(status) {
      this.handleUpdateMutation({
        mutation: UpdateFormalizationMutation,

        variables: {
          data: {
            id: this.formalization.id,
            status,
          },
        },
      });
    },

    onRetryFetch() {
      this.formalizationError = null;
      this.$apollo.queries.formalization.refetch();
    },

    onCreateMessage({ body }) {
      this.handleUpdateMutation({
        mutation: CreateFormalizationMessageMutation,

        requestingStateKey: 'isCreatingMessage',

        variables: {
          data: {
            dealId: this.formalization.deal.id,
            body,
          }
        },

        query: this.getFormalizationQuery(),

        updateQueryFn: ({
          resultsData: { createFormalizationMessage: { message: createdMessage }}
        }) => createdMessage ? ({
          formalization: {
            messages: { $unshift: [createdMessage] },
          },
        }) : null,
      }).then(() => {
        this.$refs.messages?.clearForm();
      }).catch((error) => {
        window.console.error(error);
      });
    },

    onDeleteMessage({ message }) {
      this.handleDeleteMutation({
        mutation: DeleteFormalizationMessageMutation,

        variables: { id: message.id },

        query: this.getFormalizationQuery(),

        updateQueryFn: () => ({
          formalization: {
            messages: {
              $apply: messages => messages.filter(cMessage => cMessage.id !== message.id),
            },
          },
        }),
      });
    },

    onSubmitCreateAppraisalForm({ appraisal }) {

      this.handleCreateMutation({
        mutation: CreateAppraisalMutation,
        requestingStateKey: 'isMutatingAppraisal',
        query: this.getFormalizationQuery(),

        variables: {
          data: {
            ...appraisal,
            formalizationId: this.formalization.id,
          },
        },

        updateQueryFn: ({
          resultsData: { createAppraisal: { appraisal: createdAppraisal }}
        }) => createdAppraisal ? ({
          formalization: {
            appraisals: { $push: [createdAppraisal] },
          },
        }): null,
      }).then(() => {
        this.$refs.appraisals.closeForm();
      });
    },

    onSubmitUpdateAppraisalForm({ appraisal }) {
      this.handleUpdateMutation({
        mutation: UpdateAppraisalMutation,
        requestingStateKey: 'isMutatingAppraisal',

        variables: {
          data: { ...appraisal },
        },
      }).then(() => {
        this.$refs.appraisals.closeForm();
      });
    },

    onSubmitCreateTitleStudyForm({ titleStudy }) {
      this.handleCreateMutation({
        mutation: CreateTitleStudyMutation,
        requestingStateKey: 'isMutatingTitleStudy',
        query: this.getFormalizationQuery(),



        variables: {
          data: {
            formalizationId: this.formalization.id,
            ...parseTitleStudyFormDataToMutationInput(titleStudy),
          },
        },

        updateQueryFn: ({
          resultsData: { createTitleStudy: { titleStudy: createdTitleStudy }}
        }) => createdTitleStudy ? ({
          formalization: {
            titleStudies: { $push: [createdTitleStudy] },
          },
        }): null,
      }).then(() => {
        this.$refs.titleStudies.closeForm();
      });
    },

    onSubmitUpdateTitleStudyForm({ titleStudy }) {
      this.handleUpdateMutation({
        mutation: UpdateTitleStudyMutation,
        requestingStateKey: 'isMutatingTitleStudy',

        variables: {
          data: {
            id: titleStudy.id,
            ...parseTitleStudyFormDataToMutationInput(titleStudy),
          }
        },
      }).then(() => {
        this.$refs.titleStudies.closeForm();
      });
    },

    onSubmitCreateReferralForm({ referral }) {
      this.handleCreateMutation({
        mutation: CreateReferralMutation,
        requestingStateKey: 'isMutatingReferral',
        query: this.getFormalizationQuery(),

        variables: {
          data: {
            ...referral,
            formalizationId: this.formalization.id,
          },
        },

        updateQueryFn: ({
          resultsData: { createReferral: { referral: createdReferral }}
        }) => createdReferral ? ({
          formalization: {
            referrals: { $push: [createdReferral] },
          },
        }): null,
      }).then(() => {
        this.$refs.referrals.closeForm();
      });
    },

    onSubmitUpdateReferralForm({ referral }) {
      this.handleUpdateMutation({
        mutation: UpdateReferralMutation,
        requestingStateKey: 'isMutatingReferral',

        variables: {
          data: { ...referral },
        },
      }).then(() => {
        this.$refs.referrals.closeForm();
      });
    },

    onValidateReferral({ referral }) {
      this.handleUpdateMutation({
        mutation: VerifyReferralMutation,

        variables: {
          data: {
            id: referral.id,
          },
        },

        requestingStateKey: 'isMutatingReferral',
      });
    },


    onSubmitCreateContractForm({ contract }) {
      
      this.handleCreateMutation({
        mutation: CreateContractMutation,
        requestingStateKey: 'isMutatingContract',
        query: this.getContractQuery(),

        variables: {
          data: {
            agreementIds: contract.agreementIds,
            formalizationId: this.formalization.id,
          },
        },

        updateQueryFn: ({
          resultsData: { createContract: { contract: createdContract }}
        }) => createdContract ? ({
          formalization: {
            contract: { $set: createdContract },
          },
        }): null,
      }).then(() => {
        this.$refs.contract.closeForm();
      });
    },

    onSubmitUpdateContractForm({ contract }) {
      this.handleUpdateMutation({
        mutation: UpdateContractMutation,
        requestingStateKey: 'isMutatingContract',

        variables: {
          data: { ...pick(contract, ['id', 'status', 'agreementIds', 'toPayDetails', 'disbursementProcedure']) },
        },
      }).then(() => {
        this.$refs.contract.closeForm();
      });
    },

    onClickEditFormalization() {
      this.formalizationFormModal = true;
    },
  },

  components: {
    AppraisalsCard,
    ContractTab,
    CustomerDocuments,
    FormalizationCard,
    FormalizationFormModal,
    LoadingWrapper,
    Messages,
    ReferralsTab,
    TitleStudiesCard,
  },
};
</script>

<style lang="sass" scoped>

</style>
