<template lang="pug">
  table(:class="b()")
    bonds-table-head(:labels="headLabels")
    bonds-table-body(:entries="items" :parse-value="parseValue")
    bonds-table-foot(:entries="totals" :parse-value="parseValue")
</template>

<script>
import moment from 'moment';
import BondsTableHead from './bonds-table-head';
import BondsTableBody from './bonds-table-body';
import BondsTableFoot from './bonds-table-foot';

export default {
  name: 'metrics-bonds-table',

  props: {
    entries: { type: Object, required: true },
    parseValue: { type: Function, default: val => val },
  },

  computed: {
    headLabels() {
      return (this.items?.[0]?.lines || [])
        .map(({ month, year }) => moment(`${year}-${month}`, 'YYYY-M').format('MMM/YYYY'));
    },

    items() {
      return this.entries?.tables?.entries || [];
    },

    totals() {
      return this.entries?.tables?.totals || [];
    },
  },

  methods: {
    onScroll() {
      const toolbarHeight = document.querySelector('.v-app-bar').offsetHeight;
      const tableBodyNode = this.$el.querySelector('tbody');
      const tableHeadNode = this.$el.querySelector('thead');
      const tableBodyClientRect = tableBodyNode.getBoundingClientRect();


      if(tableBodyClientRect.top < toolbarHeight) {
        tableHeadNode.style.transform = `translateY(${Math.abs(tableBodyClientRect.top - toolbarHeight) + tableHeadNode.offsetHeight}px)`
      } else {
        tableHeadNode.style.transform = null;
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },

  components: { BondsTableHead, BondsTableBody, BondsTableFoot },
};
</script>

<style lang="sass">
  .metrics-bonds-table
    $cellWidth: 120px
    $nameCellWidth: 260px

    position: relative
    text-align: center
    border-collapse: collapse
    margin: 0 auto

    @mixin static_width($width)
      width: $width
      min-width: $width
      max-width: $width


    thead
      tr
        th
          +static_width($cellWidth)
          position: relative
          padding: 8px 12px
          background-color: darken(white, 4%)
          color: darken(white, 70%)

          &:first-child
            +static_width($nameCellWidth)

    tbody
      tr
        td
          +static_width($cellWidth)
          padding: 8px 12px
          border-bottom: 1px solid darken(white, 10%)

          &:first-child
            +static_width($nameCellWidth)

        &:last-child
          td
            border-bottom: none

        &:hover
          background-color: transparentize($primaryColor, 0.95)

    tfoot
      td, th
        padding: 8px 12px
        background-color: darken(white, 10%)
</style>