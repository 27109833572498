<template lang="pug">
  titled-card(
    title="Referidos"
    :count="referrals.length"
  )
    referrals-list(:referrals="referrals" :editable="false" :validable="false" :score="false")
</template>

<script>
  import ReferralsList from './referrals-table.vue';

  import TitledCard from '@/components/UI/titled-card';

  export default {
    name: 'referrals-card',

    props: {
      referrals: { type: Array, default: () => [] },
    },

    components: { TitledCard, ReferralsList },
  };
</script>
