<template lang="pug">
  div(:class="b()")
    gauge(
      :start-angle="-90"
      :end-angle="90"
      :value="rci"
      :min="0"
      :max="100"
      :separator-step="0"
      :gauge-color="[{ offset: 0, color: '#0b8c5a'}, { offset: 40, color: '#f4c009'} , { offset: 90, color: '#de3a21'}]"
    )
      .inner-text(:style="{ color: innerTextColor }")
        span {{ rci }} %
</template>

<script>
  import { VueSvgGauge as Gauge } from 'vue-svg-gauge';



  export default {
    name: 'results',

    props: {
      rci: { type: Number, required: true },
    },

    computed: {
      innerTextColor() {
        if(this.rci <= 40) return '#28a745'
        else if(this.rci <= 55) return '#ffc107'
        else return '#dc3545';
      },
    },

    components: {
      Gauge,
    },
  };
</script>

<style lang="sass" scoped>
  .results
    max-width: 400px
    margin: 0 auto

  .inner-text
    display: flex
    align-items: flex-end
    justify-content: center
    height: 100%
    width: 100%
    font-weight: bold

    span
      font-size: 2em

</style>