<template lang="pug">
  v-select(
    :value="value"
    :items="items"
    :item-text="item => item.name"
    :error-messages="errorMessages"
    item-value="key"
    label="Filtrar por"
    @input="args => $emit('input', args)"
  )
</template>

<script>
  export default {
    name: 'field-type-select',

    props: {
      value: { type: String },
      items: { type: Array, default: () => [] },
      errorMessages: { type: Array },
    },
  };
</script>

<style lang="sass" scoped>

</style>