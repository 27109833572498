<template lang="pug">
  div(:class="b()")
    loading-wrapper(v-if="fetching || !quotes")
    quotes-item(
      v-else
      :class="b('item')"
      v-for="quote in quotes"
      :key="quote.feeNumber"
      :quote="quote"
      :currency="currency"
      :proposal="proposal"
      @register-payment="args => $emit('register-payment', args)"
      @click-reschedule="args => $emit('click-reschedule', args)"
    )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import QuotesItem from './quotes-item';

  export default {
    name: 'quotes',

    props: {
      quotes: { type: Array, required: true },
      fetching: { type: Boolean, default: false },
      currency: { type: Object, required: true },
      proposal: { type: Object },
    },

    components: { QuotesItem, LoadingWrapper },
  };
</script>

<style lang="sass" scoped>
  .quotes
    &__item
      border-bottom: 1px solid $cloudyColor

      &:last-child
        border-bottom: none
</style>