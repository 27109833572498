import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { DocumentTypesSelectQuery } from './queries.gql';

export default {
  name: 'bank-select',

  mixins: [lazySelectMixin({ itemsKey: 'documentTypes' })],

  props: {
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'description' },
  },

  data() {
    return {
      documentTypes: [],
    };
  },

  apollo: {
    documentTypes: {
      query: DocumentTypesSelectQuery,

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  render(h) {
    return h(
      VAutocomplete,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.documentTypes.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};
