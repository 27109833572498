import { BUY_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG, NEW_MORTGAGE_SLUG } from "./constants";

export const buildDocumentsURL = (loginURL, productSlug) => `${loginURL}&next=/postulacion/documentos&product=${productSlug}`;

export const buildProposalsURL = (loginURL, productSlug) => {
  const path = do{
    if(productSlug === BUY_MORTGAGE_SLUG) 'compra-deuda-hipotecaria';
    else if(productSlug === NEW_MORTGAGE_SLUG) 'nuevo-credito-hipotecario';
    else if (productSlug === HOME_EQUITY_LOAN_SLUG) 'prestamo-libre-disponibilidad';
    else ''
  }

  return `${loginURL}&next=/postulacion/propuestas/${path}&product=${productSlug}`
};

export default {
  buildDocumentsURL,
  buildProposalsURL,
};