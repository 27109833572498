
<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      requirement(
        v-for="requirement in requirements"
        :key="requirement.title"
        :title="requirement.title"
        :description="requirement.description"
        :icon="requirement.icon"
        :icon-color="requirement.iconColor"
      )
</template>

<script>
  import Requirement from './requirements-item';

  export default {
    name: 'requirements',

    props: {
      requirements: { type: Array, default: () => ([]) },
    },

    components: { Requirement },
  };
</script>

<style lang="sass" scoped>
  .requirements
    border: 3px solid lighten($primaryColor, 25%)
    background-color: transparentize($primaryColor, 0.95)
    border-radius: 6px

    &__container
      padding: 16px
      border-radius: 6px
</style>
