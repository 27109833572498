<template lang="pug">
  div(:class="b()")
    loading-overlay(:show="documentsIsMutating")
    loading-wrapper(v-if="!documents.length && $apollo.queries.documents.loading")
    documents(
      v-else
      :groups="documentsByOrigin"
      :requesting="documentsIsMutating"
      :gestionable="gestionable"
      @update="onUpdateDocument"
      @generate="onGenerateDocuments"
      @create="onCreateDocument"
      @delete="onDeleteDocument"
      @upload-attachments="onUploadAttachments"
      @delete-attachment="OnDeleteAttachment"
      empty-notification
    )

</template>

<script>
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import Documents from './components/documents';
import LoadingOverlay from '@/components/wrappers/loading-overlay';
import groupBy from 'lodash/groupBy';
import {
  UpdateDocumentMutation,
  GenerateDocumentsMutation,
  UploadAttachmentsMutation,
  DeleteAttachmentMutation,
  CreateDocumentMutation,
  DeleteDocumentMutation,
} from './graphql/mutations.gql';
import { CustomerDocumentsQuery } from './graphql/queries.gql';

const SORT_VALUES = {
  COMMERCIAL: 10,
  LEGAL: 11,
  OTHERS: 12,
};

export default {
  name: 'customer-documents',

  props: {
    customerId: { type: String },
    gestionable: { type: Boolean, default: true },
  },

  mixins: [
    mutationsHandlersMixin(),
  ],

  data() {
    return {
      documents: [],
      documentsIsMutating: false,
    };
  },

  apollo: {
    documents: {
      query: CustomerDocumentsQuery,
      manual: true,

      variables() {
        return { id: this.customerId };
      },

      result({ data }) {
        this.documents = data?.customer?.documents || [];
      },

      skip() {
        return !this.customerId;
      },
    },
  },

  computed: {
    customerDocumentsQuery() {
      return {
        query: CustomerDocumentsQuery,

        variables: {
          id: this.customerId,
        },
      };
    },

    documentsByOrigin() {
      return Object.entries(
        groupBy(
          this.documents || [],
          document => document.type.origin,
        ),
      ).map(([origin, documents]) => ({
        origin,
        groups: Object.entries(
          groupBy(
            documents,
            document => document?.type?.category?.name || 'Otros'
          )
        ).map(([title, documents]) => ({ title, documents }))
      })).sort((a, b) => (SORT_VALUES[a.origin] || 0) - (SORT_VALUES[b.origin] || 0));
    },

  },

  methods: {
    onUpdateDocument({ document }) {
      this.handleUpdateMutation({
        mutation: UpdateDocumentMutation,

        variables: { ...document },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({
          resultsData: { updateDocument: { document: updatedDocument }}
        }) => updatedDocument ? ({
          customer: {
            documents: {
              $apply: documents => documents.map(
                cDocument => cDocument.id === updatedDocument.id ? updatedDocument : cDocument,
              )
            }
          }
        }) : null,

        requestingStateKey: 'documentsIsMutating',
      });
    },

    onGenerateDocuments({ origin }) {
      this.handleUpdateMutation({
        mutation: GenerateDocumentsMutation,

        variables: {
          customerId: this.customerId,
          ...origin ? { origins: [origin] } : {},
        },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({
          resultsData: { generateDocuments: { documents: generatedDocuments }}
        }) => generatedDocuments ? ({
          customer: {
            documents: { $set: generatedDocuments }
          },
        }) : null,

        requestingStateKey: 'documentsIsMutating',
      });
    },

    onUploadAttachments({ files, document }) {
      this.handleUpdateMutation({
        mutation: UploadAttachmentsMutation,

        variables: {
          id: document.id,
          ...files,
        },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({ resultsData: { uploadDocument: { document: updatedDocument } }}) =>
          updatedDocument ? {
            customer: {
              documents: {
                $apply: documents => documents.map(
                  cDocument => cDocument.id === document.id ? updatedDocument : cDocument,
                ),
              },
            }
          } : null,

          requestingStateKey: 'documentsIsMutating',
      });
    },

    OnDeleteAttachment({ attachment }) {
      this.handleUpdateMutation({
        mutation: DeleteAttachmentMutation,

        variables: {
          id: attachment.id,
        },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({ resultsData: { deleteAttachment: { document: updatedDocument }}}) =>
          updatedDocument ? {
            customer: {
              documents: {
                $apply: documents => documents.map(
                  cDocument => cDocument.id === updatedDocument.id ? updatedDocument : cDocument,
                ),
              },
            }
          } : null,

          requestingStateKey: 'documentsIsMutating',
      });
    },

    onCreateDocument({ document }) {
      this.handleCreateMutation({
        mutation: CreateDocumentMutation,

        variables: {
          ...document,
          customerId: this.customerId,
        },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({
          resultsData: { createDocument: { documents: createdDocuments }}
        }) => createdDocuments ? ({
          customer: {
            documents: { $push: createdDocuments }
          },
        }) : null,

        requestingStateKey: 'documentsIsMutating',
      }).then(() => {
        this.$refs.documents?.closeForm();
      });
    },

    onDeleteDocument({ id }) {
      this.handleDeleteMutation({
        mutation: DeleteDocumentMutation,

        variables: { id },

        query: this.customerDocumentsQuery,

        updateQueryFn: ({
          resultsData: { deleteDocument: { errors }}
        }) => !errors ? ({
          customer: {
            documents: {
              $apply: documents => documents.filter(cDocument => cDocument.id !== id),
            }
          },
        }) : null,

        requestingStateKey: 'documentsIsMutating',
      });
    },
  },

  components: {
    Documents,
    LoadingWrapper,
    LoadingOverlay,
  },
};
</script>

<style lang="sass" scoped>

</style>