<template lang="pug">
  div(:class="b({ [reminder.status.toLowerCase()]: true })")
    div(:class="b('body')")
      div(:class="b('title')") {{ reminder.activity.name }}
      div(:class="b('message')") {{ reminder.message }}
      div(:class="b('assignation')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            div(:class="b('user')" v-on="on")
              user-avatar(
                :first-name="reminder.adviser.firstName"
                :last-name="reminder.adviser.lastName"
                :url="reminder.adviser.avatar"
                :size="24"
              )
              div(:class="b('user-name')") {{ reminder.adviser.firstName }} {{ reminder.adviser.lastName }}
          span Asesor asignado

        div(:class="b('datetime')")
          v-icon {{ reminderDateIcon }}
          span(:class="b('datetime-stamp')") {{ reminder.datetime | formatDate('D MMM YYYY - hh:mm A') }}
    div(:class="b('creation')")
      div(:class="b('user')")
        user-avatar(
          :first-name="reminder.author.firstName"
          :last-name="reminder.author.lastName"
          :url="reminder.author.avatar"
          :size="24"
        )
        div(:class="b('user-name')") {{ reminder.author.firstName }} {{ reminder.author.lastName }}

      div(:class="b('datetime')")
        v-icon mdi-calendar-plus
        span(:class="b('datetime-stamp')") {{ reminder.createdAt | formatDate('D MMM YYYY - hh:mm A')  }}
    div(:class="b('actions')")
      v-tooltip(top)
        template(v-slot:activator="{ on }")
          v-btn(v-if="canComplete" icon :class="b('complete-btn')" @click="onClickComplete" v-on="on")
            v-icon mdi-check-circle
        span Marcar como completado

      //- v-btn(icon :class="b('delete-btn')" @click="onClickDelete")
      //-   v-icon mdi-delete
</template>

<script>
  import { REMINDER_STATUS } from '@/utils/constants';
  import UserAvatar from '@/components/UI/user-avatar';

  export default {
    name: 'reminders-list-item',

    props: {
      reminder: { type: Object, required: true },
    },

    computed: {
      reminderDateIcon() {
        return this.reminder.status === REMINDER_STATUS.COMPLETED ? 'mdi-alarm-check' : 'mdi-alarm';
      },

      canComplete() {
        return this.reminder.status !== REMINDER_STATUS.COMPLETED;
      },
    },

    components: {
      UserAvatar,
    },

    methods: {
      onClickDelete() {
        if (window.confirm('¿Está seguro de que desea eliminar el recordatorio?'))
          this.$emit('delete', { reminder: this.reminder });
      },

      onClickComplete() {
        this.$emit('complete', { reminder: this.reminder });
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  $pendingColor: #3d8af7
  $delayedColor: #bb285c
  $completedColor: #72bb53


  .reminders-list-item
    $c: &
    padding: 12px 0
    border-left: 6px solid $pendingColor

    +lg
      display: flex

    &__title
      font-size: 16px
      font-weight: bold

    &__message
      font-size: 14px
      margin-bottom: 6px

    &__body
      flex: 1
      padding-left: 16px

    &__creation
      display: flex
      flex-direction: column
      justify-content: center
      padding: 8px 16px

      line-height: 2

      & > *
        justify-content: flex-end

      +lg
        border-top: none
        padding: 0 16px

        & > *
          justify-content: flex-start


    &__user, &__datetime
      display: flex
      align-items: center

      & > *:first-child
        margin-right: 8px

    &__assignation
      line-height: 2
      padding: 8px 0

      +lg
        display: flex
        align-items: center
        padding: 0

        & > *:first-child
          margin-right: 16px

    &__actions
      display: flex
      align-items: center
      justify-content: flex-end
      padding: 0 16px

      & > .v-btn
        margin: 0 !important

    &__complete-btn
      i
        color: #72bb53 !important

    &--delayed
      border-left-color: $delayedColor

      #{$c}
        &__assignation
          #{$c}__datetime
            color: $delayedColor

            i
              color: $delayedColor !important

    &--completed
      border-left-color: $completedColor
</style>