import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { RequirementsSelectQuery } from './queries.gql';

export default {
  name: 'requirement-select',

  mixins: [lazySelectMixin({ itemsKey: 'requirements' })],

  props: {
    excludeIds: { type: Array, default: () => [] },
    excludeStageRequirements: { type: Boolean, default: false },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
  },

  data() {
    return {
      requirements: [],
    };
  },

  computed: {
    options() {
      return this.requirements.filter(({ id }) => !this.excludeIds.includes(id));
    },
  },

  apollo: {
    requirements: {
      query: RequirementsSelectQuery,

      variables() {
        return {
          withoutStage: this.excludeStageRequirements,
        };
      },

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.requirements.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};