<template lang="pug">
  v-btn-toggle(:value="parsedValue" @change="onInputViewMode" :class="b()")
    v-btn(
      v-for="item in items"
      :key="item.value"
      text
    )
      v-icon {{ item.icon }}
</template>

<script>
  import isNumber from 'lodash/isNumber';
  import { BOARD_VIEW_MODE, TABLE_VIEW_MODE } from '@/store/modules/UI/deals/list';

  export default {
    name: 'view-mode-toggle',

    data() {
      return {
        items: [
          { icon: 'mdi-view-dashboard-variant', value: BOARD_VIEW_MODE },
          { icon: 'mdi-format-list-bulleted', value: TABLE_VIEW_MODE },
        ],
      };
    },

    props: {
      value: { type: String, required: true },
    },

    methods: {
      onInputViewMode(index) {
        if(isNumber(index))
          this.$emit('input', this.items[index].value );
      },
    },

    computed: {
      parsedValue() {
        return this.items.findIndex(({ value }) => value === this.value);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>