<template lang="pug">
  div(:class="b()")
    div(:class="b('current')" :style="{ left }")
    div(:class="b('bars')")
      div(:class="b('bar')" v-for="bar in ranges" :key="bar.text" :style="{ backgroundColor: bar.color }")
</template>

<script>
  export default {
    name: 'score-bar',

    data() {
      return {
        barWidth: 0,
        observer: null,
      };
    },

    props: {
      score: { type: Number },
      ranges: { type: Array, default: () => [] },
    },

    methods: {
      calculateBarWidth() {
        this.barWidth = this.$el.querySelector('.score-bar__bars').clientWidth / this.ranges.length;
      },
    },

    computed: {
      left() {
        if(!this.currentRange) return 0;

        let amount = this.barWidth * this.ranges.indexOf(this.currentRange);
        const offsetRangePoints = this.score - this.currentRange.min;

        amount += (offsetRangePoints * this.barWidth) / (this.currentRange.max - this.currentRange.min) - 1;

        return amount + 'px';
      },

      currentRange() {
        return this.ranges.find(({ min, max }) => this.score >= min && this.score <= max);
      },
    },

    mounted() {
      this.calculateBarWidth();

      window.addEventListener('resize', this.calculateBarWidth.bind(this));

      this.observer = new IntersectionObserver(this.calculateBarWidth.bind(this), {
        root: this.$el.parentNode,
      });

      this.observer.observe(this.$el);
    },

    watch: {
      ranges() {
        this.calculateBarWidth();
      },
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.calculateBarWidth.bind(this));
      this.observer.disconnect();
    },
  };
</script>

<style lang="sass" scoped>
  .score-bar
    position: relative

    &__bars
      padding: 6px 0
      display: flex

    &__bar
      flex: 1
      height: 11px

    &__current
      width: 3px
      height: 100%
      background-color: black
      position: absolute
      left: 0
</style>