<template lang="pug">
  div(:class="b({ accepted, disabled: !proposal.active })")
    div(:class="b('content')")
      div(:class="b('status-column')")
        v-icon(:color="proposal.active ? 'green' : 'red'").body-1 mdi-circle
      div(:class="b('logo-column')")
        v-img(:class="b('bank-logo')" :src="bankLogo" contain)
        div(v-if="proposal.productIdentifier === 'plus'" :class="b('plus-label')") Exclusiva
        router-link(
          v-if="scheduleRoute"
          :class="b('schedule-link')"
          :to="scheduleRoute"
          target="_blank"
        )
          v-icon mdi-calendar-multiselect
          span Cronograma
      div(:class="b('data-column')")
        div
          | #[b Cuota: ]
          | {{ proposal.simpleFee | formatCurrency(proposal.currency) }}&nbsp;
          saving-text(
            v-if="proposal.savings && proposal.savings.simpleFee"
            :amount="proposal.savings.simpleFee"
            :render="amount => $options.filters.formatCurrency(amount, proposal.currency)"
          )
        div(v-if="Number(proposal.doubleFee)")
          span #[b Cuota Doble: ] {{ proposal.doubleFee | formatCurrency(proposal.currency) }}&nbsp;
          saving-text(
            v-if="proposal.savings && proposal.savings.doubleFee"
            :amount="proposal.savings.doubleFee"
            :render="amount => $options.filters.formatCurrency(amount, proposal.currency)"
          )
        div(v-if="proposal.lastFee") #[b Última cuota: ] {{ proposal.lastFee | formatCurrency(proposal.currency) }}
          span(v-if="proposal.balloonPaymentType") &nbsp;({{ proposal.balloonPaymentType | balloonPaymentType }})
        div.text-no-wrap(v-if="proposal.amortizationType") #[b Mensualidad: ] {{ $t(`amortization.${proposal.amortizationType}`)}}
        div(v-if="proposal.savings && proposal.savings.total")
          span #[b Ahorro:  ]&nbsp;
          saving-text(
            :amount="proposal.savings.total"
            :render="amount => $options.filters.formatCurrency(amount, proposal.currency)"
          )
        div(v-if="proposal.acceptedAt").hidden-xl-only #[b Aceptada: ] {{ proposal.acceptedAt | formatDate }}
      div(:class="b('rates-column')")
        div.text-no-wrap #[b {{ $t('tea') }}: ] {{ proposal.tea }} %
        div.text-no-wrap #[b {{ $t('tcea') }}: ] {{ proposal.tcea }} %
        div.text-no-wrap(v-if="proposal.rateType") #[b Tasa: ] {{ $t(`rateType.${proposal.rateType}`)}}
      div(:class="b('extras-column')")
        div(@click.prevent="requirementsModal = true") Requisitos ({{ proposal.requirements.length }})
        div(@click.prevent="benefitsModal = true") Beneficios ({{ proposal.benefits.length }})
        div(@click.prevent="exonerationsModal = true") Promociones ({{ proposal.exonerations.length }})
      div(:class="b('actions-column')")
        template(v-if="proposal.acceptedAt")
          v-tooltip(v-if="canDisapprove" top)
            template(v-slot:activator="{ on }")
              v-icon( color="blue" v-on="on" @click="onClickDisapprove").mr-1 mdi-check-circle
            span Cancelar Aceptación
          span(v-else) Aceptada {{ proposal.acceptedAt | formatDate }}
        template(v-else)
          v-tooltip(v-if="proposal.active && canApprove" top)
            template(v-slot:activator="{ on }")
              v-icon(color="gray" v-on="on" @click="onClickApprove").mr-1 mdi-check-circle
            span Aceptar Propuesta
          v-tooltip(v-if="proposal.active && canDisapprove" top)
            template(v-slot:activator="{ on }")
              v-icon(color="error" v-on="on" @click="onClickDisable").mr-1 mdi-stop-circle-outline
            span Desactivar Propuesta
          v-tooltip(v-else-if="canActivate" top)
            template(v-slot:activator="{ on }")
              v-icon(color="success" v-on="on" @click="onClickEnable").mr-1 mdi-play-circle-outline
            span Activar Propuesta
        //- v-icon(v-if="canEdit" @click.native="onClickEdit" style="cursor: pointer") mdi-pencil
        //--Punto 1
    div(:class="b('footer')")
      template(v-if="proposal.managementFee")
        div
          span.font-weight-medium Gastos Administrativos:&nbsp;
          span {{ proposal.managementFee | formatCurrency(proposal.currency) }}
      template(v-if="proposal.openingCommission")
        div(v-if="proposal.openingCommission.administrationExpenses")
          span.font-weight-medium {{ $t('administrationExpenses')}}:&nbsp;
          span {{ proposal.openingCommission.administrationExpenses | formatCurrency(proposal.currency) }}
        div
          span.font-weight-medium Comisión de apertura:&nbsp;
          span {{ proposal.openingCommission.openingCommission | formatCurrency(proposal.currency) }}
      div(v-if="proposal.acceptedAt")
        span.font-weight-medium Aceptada:&nbsp;
        span {{ proposal.acceptedAt | formatDate }}
    requirements-modal(v-model="requirementsModal" :requirements="proposal.requirements")
    benefits-modal(v-model="benefitsModal" :benefits="proposal.benefits")
    exonerations-modal(v-model="exonerationsModal" :exonerations="proposal.exonerations")
</template>

<script>
import { AMORTIZATION_TYPE } from '@/utils/constants';
import isString from 'lodash/isString';
import SavingText from '@/components/UI/saving-text';
import { encrypt } from '@/utils/url-params-crypter';
import balloonPaymentType from '@/filters/balloon-payment-type';

import RequirementsModal from './requirements-modal';
import BenefitsModal from './benefits-modal';
import ExonerationsModal from './exonerations-modal';

export default {
  name: 'proposals-item',

  props: {
    proposal: { type: Object, required: true },
    canApprove: { type: Boolean, default: true },
    canDisapprove: { type: Boolean, default: true },
    canActivate: { type: Boolean, default: true },
    canDeactivate: { type: Boolean, default: true },
    canEdit: { type: Boolean, default: true },
    showSchedule: { type: Boolean, default: true },
  },

  data() {
    return {
      // Modals
      requirementsModal: false,
      benefitsModal: false,
      exonerationsModal: false,
    };
  },

  methods: {
    onClickEdit() {
      this.$emit('edit', { proposal: this.proposal });
    },

    onClickDelete() {
      this.$emit('delete', { proposal: this.proposal });
    },

    onClickApprove() {
      if (!this.proposal.acceptedAt && window.confirm('¿Está seguro de que desea aceptar la propuesta?'))
        this.$emit('approve', { proposal: this.proposal });
    },

    onClickDisapprove() {
      if (!this.canDisapprove) return;

      if (window.confirm('¿Está seguro de que desea cancelar la aceptación de la propuesta?'))
        this.$emit('disapprove', { proposal: this.proposal });
    },

    onClickDisable() {
      this.$emit('disable', { proposal: this.proposal });
    },

    onClickEnable() {
      this.$emit('enable', { proposal: this.proposal });
    },
  },

  computed: {
    bankLogo() {
      return this.$cloudfrontURL(`/logos/${this.proposal.bank.shortName.toLowerCase()}.svg`)
    },

    accepted() {
      return isString(this.proposal.acceptedAt);
    },

    scheduleRoute() {
      if (!this.showSchedule || this.proposal?.amortizationType !== AMORTIZATION_TYPE.FIXED) return;

      return {
        name: 'mortgage_schedule.borrower',
        query: {
          _params: encrypt({ proposalId: this.proposal.id }),
        },
      };
    },
  },

  filters: { balloonPaymentType },

  components: { SavingText, RequirementsModal, BenefitsModal, ExonerationsModal },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  =alg()
    @media screen and (min-width: 1400px)
      @content

  .proposals-item
    $c: &

    &__content
      position: relative

      +lg
        display: flex
        flex-wrap: wrap

      +alg
        flex-wrap: nowrap

    &__bank-logo
      height: 32px
      margin: 0 auto
      margin-bottom: .5rem

    &__plus-label
      position: absolute
      top: 3px
      right: 0
      padding: 2px 8px

      color: white
      background-color: #a63bc7

      font-size: 11px
      font-weight: 500
      text-align: center

      border-radius: 3px

      +lg
        position: static

    &__schedule-link
      display: flex
      align-items: center
      justify-content: center
      color: $primaryColor
      text-decoration: none
      margin-top: 2px
      margin-bottom: 8px
      font-size: 13px

      i
        color: $primaryColor
        margin-right: 4px

    &__logo-column
      +lg
        width: 120px
        padding: 0 10px 0 8px

      +xl
        width: 180px

    &__rates-column
      +lg
        flex: 1 1 80px

        padding: 0 4px

      +alg
        flex: 0 1 0
        white-space: nowrap
        padding: 0 8px

    &__data-column

      +lg
        flex: 1 1 30%

        padding: 0 4px

      +alg
        flex: 0 0 34%
        white-space: nowrap
        padding: 0 8px

      +xl
        padding-left: 18px

    &__extras-column
      padding: 8px 0

      color: $primaryColor

      font-weight: 500

      +lg
        order: 6
        display: flex
        align-items: center

        width: 100%
        padding-left: 140px

        & > *
          margin-right: 14px


          cursor: pointer

      +alg
        order: 4
        flex: 1 1
        display: block
        width: auto
        padding: 0

        text-align: center

        & > *
          margin: 0

          line-height: 1.5

    &__actions-column
      text-align: right
      opacity: 1 !important

      +lg
        display: flex
        align-items: center
        width: auto
        order: 5

    &__footer
      padding-bottom: 12px

      +lg
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        padding: 3px 0px 6px 6px

        div
          padding: 0 8px

          span:first-child
            font-weight: 500
</style>
