<template lang="pug">
  thead
    tr
      th Prestatario
      th(v-for="label in labels" :key="label") {{ label }}
</template>

<script>
export default {
  name: 'borrowers-aums-table-head',

  props: {
    labels: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-aums-table-head
</style>