<template lang="pug">
  div(:class="b()").v-card.pa-3
    div(:class="b('title')").text--secondary Historial de dictámenes

    div(:class="b('history-wrapper')")
      div(:class="b('history-container')")
        file-history-item(
          v-for="riskFile in riskFiles"
          :key="riskFile.id"
          :riskFile="riskFile"
        )
        v-alert(v-if="!riskFiles.length" type="info" :value="true" text).mt-2 No hay dictámenes anteriores
</template>

<script>
  import FileHistoryItem from './risk-files-history-item';

  export default {
    name: 'risk-files-history-card',

    props: {
      riskFiles: { type: Array, default: () => [] },
    },

    components: { FileHistoryItem },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .risk-files-history-card
    display: flex
    flex-direction: column
    height: 200px

    &__title
      font-size: 16px
      font-weight: 500
      margin-bottom: 6px

    &__history-wrapper
      position: relative
      flex: 1

    &__history-container
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      overflow-y: auto

    +lg
      height: 100%
</style>