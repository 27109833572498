import VSelect from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { ValidationProvider } from 'vee-validate';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { CommercialityTypesSelectQuery } from './queries.gql';

export default {
  name: 'commercialities-select',

  mixins: [lazySelectMixin({ itemsKey: 'commercialities' })],

  props: {
    questionConfig: {type: Object, required: false, default: []},
    questionCommercialities: { type: Object, required: false, default: []},
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'text' },
    errorMessages: { type: Array, default: () => [] },
  },

  data() {
      return {
          commercialities: [],
          QuestionProperty: [],
          responseQuestions: this.questionConfig.filter( item => item.option !== "DEMANDA" ) || [],
          responses:[],
      };
  },

  methods: {
    initializeQuestions() {   

        // Valida que exista información
        if (!this.commercialities.length > 0) return ;

        // Array con todos los IDs de preguntas que tenemos en el formulario
        const questionIds = this.commercialities;
  
        // Si tenemos datos previos del appraisal (en caso de edición)
        if (this.questionConfig.length > 0) {

            this.QuestionProperty = questionIds.map( items => ({
                questionId:parseInt(items.questions[0].id),
                selectedOptionId:parseInt(this.getInitialResponse(items.questions[0].id)),           
            }))
        } else {
          // Si es un nuevo formulario, inicializamos con valores por defecto
          this.QuestionProperty = questionIds.map(items => {
            return {
                "questionId": parseInt(items.questions[0].id),
                "selectedOptionId": 0,
            };
          });
        }
  
        // queda pendiente emitir los datos
        this.responses = this.QuestionProperty;

        // Emite el evento al componente padre si es necesario
        this.$emit('change', this.responses );
    },

    handleChange(questionId, selectedOption) {
        const 
            qId = parseInt(questionId),
            optionId = parseInt(selectedOption),
            existingIndex = this.QuestionProperty.findIndex( item => item.questionId === qId)
        ;

        if (existingIndex !== -1) {
            // Actualizamos la respuesta existente
            this.QuestionProperty[existingIndex].selectedOptionId = optionId;
        } else {
            // Agregamos nueva respuesta si no existe
            this.QuestionProperty.push({
                questionId: qId,
                selectedOptionId: optionId
            });
        }

        // Actualizamos el data principal para el submit
        this.responses = [...this.QuestionProperty];

        // Emite el evento al componente padre si es necesario
        this.$emit('change', this.responses );
    },
  },

  computed: {
    questions() {
        return (questionID) => {
            const selectedQuestion = this.commercialities.find( item => item.questions[0].id === questionID.toString() );

            if(!selectedQuestion) return;
            return selectedQuestion.questions[0].text;
        };
    },

    response() {
        return (questionID) => {
            const selectedResponse = this.commercialities.find( item => item.questions[0].id === questionID.toString() );

            if(!selectedResponse) return;
            return selectedResponse.questions[0].options;
        };
    },

    configRules() {
        return (questionId) => {

            if (this.responseQuestions.length === 0) {
                return 'required';
            }

            const matchingItem = this.responseQuestions.find( item => item.id === questionId.toString() );

            if (matchingItem) {
                return undefined ;
            } else {
                return 'required';
            }
        };
    },

    formattedItems() {
        return (questionID) => {
            return this.response(questionID).map( item => ({
                id:item.id,
                text:item.text,
            }));
        };    
    },
    
    getInitialResponse() {
        return (questionId) => {
            if (!this.responseQuestions.length > 0) {
                return '0';
            }
            const matchingItem = this.responseQuestions.find( item => item.id === questionId.toString() );

            if (matchingItem) {
                return matchingItem.optionId.toString();
            } else {
                return '0';
            }
        }
    },
  },

  mounted() {
    this.initializeQuestions();
  },

  watch:{
    commercialities: {
        handler() {
            this.initializeQuestions();
        },
        deep: true
    },
  },

  apollo: {
    commercialities: {
        query: CommercialityTypesSelectQuery,
        result({ data }) {
            if (data && data.commercialities && !this.questionCommercialities.length > 0) {
                this.commercialities = data.commercialities.filter(item => item.name !== 'Demanda');
            } else {
                this.commercialities = this.questionCommercialities;
            }
        },
    }     
  },

    render(h) {
        return h('div', {
            staticClass: 'd-flex flex-wrap',
            style: {
                margin: '-8px',
            }
        }, [
            this.commercialities.map(items =>
                h('div', {
                    key: items.questions[0].id,
                    staticClass: 'pa-2',
                    style: {
                        flexBasis: '50%',
                        maxWidth: '50%',
                    }
                }, [
                    h(ValidationProvider, {
                        props: {
                            name: `commerciality-${items.questions[0].id}`,
                            rules: this.configRules(items.questions[0].id)
                        },
                        scopedSlots: {
                            default: (validation) =>
                                h(VTooltip, {
                                    props: {
                                        bottom: true,
                                        // Puedes ajustar estas propiedades según tus necesidades
                                        openDelay: 200,
                                        closeDelay: 200
                                    },
                                    scopedSlots: {
                                        activator: ({on, attrs}) =>
                                            h(VSelect, {
                                                props: {
                                                    value: this.getInitialResponse(items.questions[0].id),
                                                    items: this.formattedItems(items.questions[0].id),
                                                    label: this.questions(items.questions[0].id),
                                                    itemValue: this.itemValue,
                                                    itemText: this.itemText,
                                                    errorMessages: [...this.errorMessages, ...validation.errors],
                                                    loading: this.$apollo.queries.commercialities.loading,
                                                },
                                                on: {
                                                    ...on,
                                                    input: (value) => {
                                                        this.$emit('input', value);
                                                        validation.validate(value);
                                                    },
                                                    change: (value) => this.handleChange(items.questions[0].id, value)
                                                },
                                                attrs: attrs
                                            })
                                    }
                                }, [
                                    h('span', this.questions(items.questions[0].id))
                                ])
                        }
                    })
                ])
            )
        ]);
    },

};