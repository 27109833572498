<template lang="pug">
  v-layout
    v-flex
      div.subheading {{ card.type }} ...{{ card.endingNumbers }}
      div #[b Banco: ] {{ card.bank.name }}
    v-flex
      .headline.red--text.font-weight-bold.text-right.pr-2 {{ card.debt | formatMoney(card.currencyId) }}
</template>

<script>
  export default {
    name: 'cards-row',

    props: {
      card: { type: Object, required: true },
    }
  };
</script>

<style lang="sass" scoped>

</style>