<script>
import moment from 'moment';
import { formatCurrency } from '@/filters/accounting';
import { groupBy, pipe, mapValues } from 'lodash/fp';
import { Bar } from 'vue-chartjs';

export default {
  name: 'retention-report-chart',

  extends: Bar,

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {
    parsedEntries() {
      return pipe(
        mapValues((entries) => {
          const employersCount = pipe(groupBy('employer'), Object.keys)(entries).length;
          const retentionsCount = entries.length;

          return entries.reduce(
            (acc, entry) => ({
              ...acc,
              taxBase: acc.taxBase + Number(entry.taxBase),
              tribute: acc.tribute + Number(entry.tribute)
            }),
            { employersCount, retentionsCount, taxBase: 0, tribute: 0 },
          );
        })
      )(this.entriesByPeriod);
    },

    entriesByPeriod() {
      return groupBy('period')(this.entries);
    },

    periods() {
      return Object.keys(this.entriesByPeriod)
        .sort((a, b) => moment(a).diff((moment(b))));
    },

    datasets() {
      return [
        {
          label: 'Base',
          backgroundColor: 'rgb(88, 202, 121,0.7)',
          data: this.periods.map((period) => this.parsedEntries?.[period]?.taxBase || 0)
        },
        {
          label: 'Tributo',
          backgroundColor: 'rgb(234, 44, 98,0.7)',
          data: this.periods.map((period) => this.parsedEntries?.[period]?.tribute || 0)
        },
        {
          label: 'Rentas',
          type: 'line',
          yAxisID: 'B',
          pointBorderWidth: 3,
          pointBackgroundColor: 'rgb(0, 88, 122, 0.9)',
          borderColor: 'rgb(0, 88, 122, 0.7)',
          backgroundColor: 'rgb(0, 88, 122, 0.1)',
          data: this.periods.map((period) => this.parsedEntries?.[period]?.retentionsCount || 0)
        },
        {
          label: 'Empleadores',
          type: 'line',
          yAxisID: 'B',
          pointBorderWidth: 3,
          pointBackgroundColor: 'rgb(234, 84, 85, 0.9)',
          borderColor: 'rgb(234, 84, 85, 0.7)',
          backgroundColor: 'rgb(234, 84, 85, 0.1)',
          data: this.periods.map((period) => this.parsedEntries?.[period]?.employersCount || 0)
        },
      ];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.periods,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,

        scales: {
          xAxes: [{
            ticks: {
              callback(value) {
                return moment(value).format('MMM/YYYY');
              },
            },
          }],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                callback(value) {
                  return formatCurrency(value);
                },
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                min: 0,
              },
            },
          ]
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            title(entries) {
              return moment(entries[0].label).format('MMMM/YYYY');
            },

            label: ({ value, datasetIndex }) => {
              const title = this.datasets[datasetIndex].label;
              const result = datasetIndex > 1 ? value : formatCurrency(value);

              return [`${title}: ${result}`];
            },
          }
        }
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>