<template lang="pug">
  div(:class="b()")
    div(:class="b('data-row')").
      #[b Lugar de trabajo: ] {{ income.workPlaceName }}
    div(v-if="income.startAt" :class="b('data-row')").
      #[b Fecha de inicio: ] {{ income.startAt | formatDate }} ({{ startFromNow }})
    div(:class="b('data-row')").
      #[b Posición: ] {{ income.position }}
    div(:class="b('data-row')").
      #[b Tipo de ingreso: ] {{ income.type }}
    div(:class="b('data-row')").
      #[b Descripción: ] {{ income.description }}
    div(:class="b('data-row')").
      #[b Dirección: ] {{ income.address }}
    template(v-if="income.industry")
      div(:class="b('data-row')").
        #[b Industria: ] {{ income.industry.activity}}
      div(:class="b('data-row')").
        #[b Descripción de industria: ] {{ income.industry.description}}
      div(:class="b('data-row')").
        #[b Margen: ] {{ income.industry.margin}} %
    div(:class="b('data-row')")
      documents-origin-group(
        :groups="[{title: 'De ingresos', documents: income.documents}]"
        origin="COMMERCIAL"
        :canRegenerate="true"
        :emptyNotification="true"
        :emptyNotificationText="`No hay documentos para este cliente`"
        :gestionable="false"
        :requesting="false"
      )

    incomes-row-by-month(v-if="income.byMonths" :months="income.byMonths" :currency="income.currency")

    .d-flex.align-center.justify-end.pt-2
      div(:class="b('amount')") {{ income.amount | formatCurrency(income.currency, { precision: 0 }) }}
      div(:class="b('amount-type')") Brutos
      div(:class="b('amount')")
        template(v-if="income.netAmount") {{ income.netAmount | formatCurrency(income.currency, { precision: 0 }) }}
        template(v-else) ?
      div(:class="b('amount-type')") Netos

    div(:class="b('')")
      v-text-field(
        label="Ingreso neto calculado por el analista"
        v-model="analystAmount"
        type="number"
        @blur="onBlurHandler"
        :min="0"
        :disabled="isApproved"
      )

    div(:class="b('data-row')")
      business-verification-income-select(
        v-model="selectedId"
        :riskFileId="riskFileId"
        :riskIncomeId="income.id"
        @change="showRiskFormModal = true"
        label="Verificación de negocio"
        style="width: 60%; display: inline-block; margin-right: 10px;"
      )
      v-btn(
        color="primary"
        @click="createRiskFormModal"
        style="width: 30% !important; display: inline-block;"
        :disabled="isApproved"
      ) Crear Formulario

      show-risk-form-income-modal(
        v-model="showRiskFormModal"
        :id="selectedId"
      )
      create-risk-form-income-modal(
        v-model="riskFormModal"
        :riskFileId="riskFileId"
        :riskIncomeId="income.id"
      )
</template>

<script>
import moment from 'moment';
import IncomesRowByMonth from './incomes-row-by-month';
import DocumentsOriginGroup from '@/components/smart/customer-documents/components/documents/documents-origin-group.vue';
import { DoUpdateRiskIncomeData } from '../../graphql/mutations.gql';
import BusinessVerificationIncomeSelect from '@/components/inputs/business-verification-income-select/';
import ShowRiskFormIncomeModal from '@/components/smart/show-risk-form-income-modal/';
import CreateRiskFormIncomeModal from '@/components/smart/create-risk-form-income-modal/';

export default {
  name: 'incomes-row',

  props: {
    income: { type: Object, required: true },
    riskFileId: { type: String, default: '' },
    isApproved: { type: Boolean, default: false },
  },

  data() {
    return {
      analystAmount: this.income?.analystAmount,
      selectedId: "",
      riskFormModal: false,
      showRiskFormModal: false,
    }
  },

  computed: {
    startFromNow() {
      if (this.income?.startAt)
        return this.income?.startAt ? moment(this.income.startAt).fromNow() : null
    },
  },

  methods: {
    onBlurHandler() {
      this.$apollo.mutate({
        mutation: DoUpdateRiskIncomeData,
        variables: {
          id: this.income.id,
          analystAmount: parseFloat(this.analystAmount),
        }
      })
    },

    createRiskFormModal() {
      this.riskFormModal = true;
    },
  },

  components: { IncomesRowByMonth, DocumentsOriginGroup, BusinessVerificationIncomeSelect, ShowRiskFormIncomeModal, CreateRiskFormIncomeModal },
};
</script>

<style lang="sass" scoped>

  .incomes-row
    &__amount
      font-size: 19px
      font-weight: 500

      &-type
        font-size: 13px
        margin-left: 6px
        margin-right: 12px
</style>
