<template lang="pug">
  div(:class="b()")
    div(:class="b('header')").d-flex.justify-center
      div
        span.font-weight-medium #[b Creado]: {{ titleStudy.createdAt | formatDate('D MMM YYYY - hh:mm A') }}
        span.font-weight-medium.ml-4 #[b Modificado]: {{ titleStudy.modifiedAt | formatDate('D MMM YYYY - hh:mm A') }}
      .spacer
      title-study-status-tag(:class="b('header-status')" :status="titleStudy.status")

    div.mb-2 #[span.font-weight-medium Partida Electrónica: ] {{ titleStudy.electronicRegistrationNumber }} - {{ titleStudy.electronicRegistrationNumberCity }}

    h4.text--secondary #[b Propietarios: ]
    ul.mb-2
      li(v-for="owner in titleStudy.owners" :key="owner.id") {{ owner.user.firstName }} {{ owner.user.lastName }} {{ owner.user.maternalLastName }} - {{ owner.user.vat }}

    h4.text--secondary #[b Cargas/Gravamenes: ]
    ul.mb-2
      li(v-for="lienCharge in titleStudy.lienChargeLines" :key="lienCharge.id")
        span
          span.font-weight-medium {{ lienCharge.lienCharge.text }}
          span(v-if="lienCharge.chargeAmount") &nbsp;de #[span.font-weight-medium {{ lienCharge.chargeAmount | formatCurrency(lienCharge.currency) }}]
          span &nbsp;en el asiento
          span.font-weight-medium &nbsp;{{ lienCharge.settle }}
        template(v-if="lienCharge.creditor")
          span.mx-2 -
          span(v-if="lienCharge.creditor") {{ lienCharge.creditor.name }} ({{ lienCharge.creditor.vatType}}: {{ lienCharge.creditor.vat }})


    h4.text--secondary Observaciones legales
    div(v-html="titleStudy.comments" style="white-space: pre-line").px-4.py-1

</template>

<script>
import TitleStudyStatusTag from '@/components/UI/title-study-status-tag';

export default {
  name: 'title-study-content-row',

  props: {
    titleStudy: { type: Object, required: true },
  },

  components: { TitleStudyStatusTag },
};
</script>

<style lang="sass" scoped>
  .title-study-content-row
    padding: 18px
    background-color: darken(white, 3%)

    &__header
      margin-bottom: 8px
      font-size: 1em
      color: darken(white, 70%)
</style>
