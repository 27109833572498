<template lang="pug">
  metrics-layout
    filters(
      :filters="filters"
      :requesting="$apollo.queries.tableByAdviser.loading"
      @submit="onSubmitFilters"
      slot="filters"
    )
    div
      loading-wrapper(v-if="$apollo.queries.tableByAdviser.loading")
      v-card(v-else)
        v-tabs(v-model="tab" fixed-tabs)
          v-tab(
            v-for="cTab in tabs"
            :key="cTab.title"
          ) {{ cTab.title}}
          v-tab-item
            .pa-2
              advisers-table(:items="tableByAdviser")
          v-tab-item
            .pa-2
              advisers-conversion-table(:items="tableByAdviser")
</template>

<script>
  import moment from 'moment';
  import Config from '@/config';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import isEqual from 'lodash/isEqual';

  import Filters from './filters';
  import AdvisersTable from './advisers-table';
  import AdvisersConversionTable from './advisers-conversion-table';
  import MetricsLayout from '../components/metrics-layout';
  import TableByAdviserQuery from './graphql/queries.gql';

  export default {
    name: 'metrics-advisers-view',

    data() {
      return {
        filters: {
          country: Config.COUNTRY_CODE.toUpperCase(),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          product: undefined,
          adviserGroup: 'IN_HOUSE',
          organizationId: undefined,
        },
        tableByAdviser: [],
        tab: null,
        tabs: [
          { title: 'Deals' },
          { title: 'Conversiones' },
        ],
      };
    },

    apollo: {
      tableByAdviser: {
        query: TableByAdviserQuery,
        fetchPolicy: 'network-only',

        variables() {
          return { ...this.filters };
        },
      },
    },

    components: {
      AdvisersTable,
      AdvisersConversionTable,
      Filters,
      MetricsLayout,
      LoadingWrapper,
    },

    methods: {
      onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.tableByAdviser.refetch();
        }
        else
          Object.assign(this.filters, filters);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>