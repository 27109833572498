import Vue from 'vue';
import Config from '@/config';
import authStorage from '@/utils/auth-storage';

const DEFAULT_CURRENCY_EXHANGE_RATE = 3.35;

const fetchCurrencyChangeRate = () => new Promise((resolve, reject) => {
  fetch(`${process.env.VUE_APP_GRAPH_URL}`, {
    method: 'POST',
    body: JSON.stringify({
      query: '{ defaultCurrencyChange }',
    }),
    headers: {
      'Content-Type': 'application/json',
      authorization: `JWT ${authStorage.getCredentials()?.token}`,
    },
  })
    .then(res => res.json())
    .then(res => {
      return resolve(res.data.defaultCurrencyChange);
    })
    .catch(reject)
});

const getCurrencyExchangeRate = (() => {
  let currencyExchangeRate = null;

  return () => new Promise((resolve) => {
    if(currencyExchangeRate) resolve(currencyExchangeRate);

    return fetchCurrencyChangeRate()
      .then(exchangeRate => {
        currencyExchangeRate = exchangeRate;

        resolve(exchangeRate);
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line

        resolve(DEFAULT_CURRENCY_EXHANGE_RATE);
      });
  });
})();

const isLocalCurrency = (currency) => {
  return currency.id === Config.DEFAULT_CURRENCY_ID;
};

const RtcCurrencyExchange = {
  install(Vue) {
    Vue.prototype.$currencyExchange = {
      isLocalCurrency,

      async exchange(amount, currency) {
        if(!amount || !currency) return Promise.resolve(amount);

        const exchangeRate = await getCurrencyExchangeRate();

        return Promise.resolve(
          isLocalCurrency(currency)
            ? Number(amount * exchangeRate).toFixed(2)
            : Number(amount / exchangeRate).toFixed(2)
        );
      },


    };
  }
};

Vue.use(RtcCurrencyExchange);
