<!-- eslint-disable no-console -->
<template lang="pug">
  v-dialog(
    :value="value"
    width="900"
    @input="args => $emit('input', args)"
  )
    v-card.pa-4
      v-form
        v-container
          v-row
            v-col(cols="12" class="text-center mt-4")
              h2 Crear Verificación del Negocio
          v-row
            v-col(cols="6")
              v-text-field(label="Principales clientes", v-model="mainCustomers" required)
            v-col(cols="6")
              v-text-field(label="Principales proveedores", v-model="mainVendors" required)
            v-col(cols="12")
              v-text-field(label="URL de Drive de videollamada", v-model="videoCallDriveUrl" required)
            v-col(cols="4")
              v-text-field(label="Años en el mismo edificio", v-model="yearsInSameBuilding" type="number")
            v-col(cols="4")
              v-text-field(label="Empleados de tiempo completo", v-model="fullTimeEmployees" type="number")
            v-col(cols="4")
              v-text-field(label="Ventas diarias", v-model="dailySales" type="number")
            v-col(cols="4")
              v-text-field(label="Monto semanal", v-model="weeklyAmount" type="number")
            v-col(cols="4")
              v-text-field(label="Ventas mensuales", v-model="monthlySales" type="number")
            v-col(cols="6")
              v-checkbox(label="Licencia del negocio", v-model="businessLicense")
            v-col(cols="6")
              v-checkbox(label="Cheque del libro de ventas", v-model="salesBookCheck")
            v-col(cols="12")
              v-row
                v-col(cols="12" class="text-center")
                  h3 Productos
              v-row
                v-col(cols="4")
                  v-text-field(label="Nombre" v-model="name" required)
                v-col(cols="4")
                  v-text-field(label="Precio" v-model="price" type="number" required)
                v-col(cols="4")
                  v-text-field(label="Cantidad" v-model="qty" type="number" required)
              v-col(cols="12" class="text-center")
                v-btn(color="secondary" @click="addProduct") Agregar
              v-col(cols="12" v-if="products.length > 0" class="text-center")
                v-data-table(
                  :headers=[
                    { text: 'Nombre', value: 'name' },
                    { text: 'Precio', value: 'price' },
                    { text: 'Cantidad', value: 'qty' },
                    { text: 'Acciones', value: 'actions', sortable: false },
                  ]
                  :items="products"
                  :items-per-page="5"
                  class="elevation-1"
                )
                  template(v-slot:item.actions="{ item }")
                    v-icon.mr-2(@click="removeProduct(item.id)") mdi-delete
          v-row
            v-col(cols="12" class="text-center")
              h3 Preguntas
            v-col(cols="6" v-for="question in qualitativeQuestions" :key="question.id")
              v-select(
                v-model="qualitativeQuestionsAnswers[question.id]"
                :items="question.response"
                :label="question.question"
                item-text="response"
                item-value="id"
              )
          v-row
            v-col(cols="12" class="text-center")
              v-btn(color="primary" @click="submitForm") Guardar
</template>

<script>
import DoCreateBusinessVerification from './graphql/mutations.gql';
import QualitativeQuestionsQuery from './graphql/queries.gql';

import iziToast from 'izitoast';

export default {
  name: 'create-risk-form-income-modal',

  props: {
    value: { type: Boolean, default: false },
    riskFileId: { type: String, default: '' },
    riskIncomeId: { type: String, default: '' },
  },

  data() {
    return {
      businessLicense: false,
      dailySales: 0,
      fullTimeEmployees: 0,
      mainCustomers: '',
      mainVendors: '',
      monthlySales: 0,
      salesBookCheck: false,
      topSales: '',
      name: '',
      price: 0,
      qty: 0,
      products: [],
      videoCallDriveUrl: '',
      weeklyAmount: 0,
      yearsInSameBuilding: 0,
      qualitativeQuestions: [],
      qualitativeQuestionsAnswers: {},
    };
  },
  created() {
    this.fetchQualitativeQuestions();
  },
  methods: {
    async fetchQualitativeQuestions() {
      try {
        const response = await this.$apollo.query({
          query: QualitativeQuestionsQuery,
        });
        this.qualitativeQuestions = response.data.qualitativeQuestions;
      } catch (error) {
        iziToast.error({
          message: 'No se pudieron cargar las preguntas cualitativa',
        });
      }
    },
    async submitForm() {
      if (this.products.length === 0) {
        iziToast.error({
          message: 'Debe agregar al menos un producto',
        });
        return;
      }

      try {
        const response = await this.$apollo.mutate({
          mutation: DoCreateBusinessVerification,
          variables: {
            riskIncomeId: this.riskIncomeId,
            businessLicense: this.businessLicense,
            fullTimeEmployees: parseInt(this.fullTimeEmployees),
            mainCustomers: this.mainCustomers,
            mainVendors: this.mainVendors,
            yearsInSameBuilding: parseInt(this.yearsInSameBuilding),
            salesBookCheck: this.salesBookCheck,
            videoCallDriveUrl: this.videoCallDriveUrl,
            monthlySales: parseInt(this.monthlySales),
            dailySales: parseInt(this.dailySales),
            topSales: JSON.stringify(this.products.map((product) => {
              return { name: product.name, price: product.price, qty: product.qty };
            })),
            weeklyAmount: parseInt(this.weeklyAmount),
            qualitativeQuestions: Object.entries(
              this.qualitativeQuestionsAnswers,
            ).map(([k, v]) => {
              return {
                question_id: k,
                response_id: v,
              };
            }),
          },
        });
        const result = response.data.createBusinessVerification;
        if (result.status === 'CREATED') {
          this.value = false;
          iziToast.success({
            message: 'Verificación del negocio creada con éxito',
          });
        } else {
          iziToast.error({
            message: 'No se pudo crear la verificación del negocio',
          });
        }
      } catch (error) {
        iziToast.error({
          message: 'No se pudo crear la verificación del negocio',
        });
      }
    },
    addProduct() {
      if (this.name.trim() === '' || this.price === 0 || this.qty === 0) {
        iziToast.error({
          message: 'Debe llenar todos los campos',
        });
        return;
      }
      const uniqueProducts = this.products.filter(
        (product) => product.name === this.name.trim(),
      );
      if (uniqueProducts.length > 0) {
        iziToast.error({
          message: 'Ya existe un producto con ese nombre',
        });
        return;
      }
      this.products.push({
        id: `${this.products.length} - ${this.name.trim()} - ${this.price} - ${this.qty}`,
        name: this.name.trim(),
        price: this.price,
        qty: this.qty,
      });
    },
    removeProduct(id) {
      this.products = this.products.filter((product) => product.id !== id);
    },
  },
};
</script>

<style lang="sass" scoped></style>
