<template lang="pug">
  titled-card(
    title="Solicitudes Activas"
    :count="activeProducts.length"
    icon="mdi-buffer"
  )
    div(slot="actions")
      v-btn(@click.native="onClickAdd" text small color="primary").ma-0.px-1
        span Nuevo
        v-icon mdi-plus
    div(:class="b('content')")
      active-products(:activeProducts="internalActiveProducts")
    deal-form-modal(
      v-model="formModal"
      :products="offerProducts"
      @create-deal="args => $emit('create-deal', args)"
    )
</template>

<script>
import DealFormModal from '@/components/modals/deal-form-modal';
import TitledCard from '@/components/UI/titled-card';
import ActiveProducts from './active-products';
import {
  PRODUCT_CONTEXTS,
  HOME_EQUITY_LOAN_SLUG,
  WORK_CAPITAL_PRODUCT_MOTIVE,
  DEBT_CONSOLIDATION_PRODUCT_MOTIVE,
  HOUSE_REMODELING_PRODUCT_MOTIVE,
  LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE,
  LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE,
  LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE,
  RESCHEDULE_MOTIVE,
} from '@/utils/constants';

const getContextErrors = (customer, contexts) => contexts.reduce(
  (errors, context) => {
    const credit = customer.properties?.[0]?.credit;

    switch (context) {
      case 'incomes':
        return customer.incomes.length ? errors : [...errors, 'Require tener ingresos declarados'];
      case 'properties':
        return customer.properties.length ? errors : [...errors, 'Require tener un inmueble declarado'];
      case 'credit':
        return credit ? errors : [...errors, 'Requiere que el inmueble tenga un crédito'];
      default:
        return errors;
    }
  },
  [],
);

export default {
  name: 'active-products-card',

  data() {
    return {
      formModal: false,
    };
  },

  props: {
    requesting: { type: Boolean, default: false },
    activeProducts: { type: Array, default: () => ([]) },
    availableProducts: { type: Array, default: () => ([]) },
    customer: { type: Object, required: true },
  },

  computed: {
    offerProducts() {
      return this.parsedAvailableProducts
        .map(product => ({
          ...product,
          errorMessages: getContextErrors(this.customer, PRODUCT_CONTEXTS[product?.slug]),
        }));
    },

    internalActiveProducts() {
      return this.activeProducts.map(activeProduct => ({
        ...activeProduct,
        inscribed: this.customer.mortgageInscribed && activeProduct.product?.slug === 'buy_mortgage',
      }));
    },

    /**
     * En esta función se agregan los "motive" de los productos así cómo también en el caso de home_equity_loan
     * lo agregamos como 3 productos ya que puede tener 3 motivos respectivamente
     */
    parsedAvailableProducts() {
      return this.availableProducts.reduce(
        (acc, product) => [
          ...acc,
          ...(product.slug !== HOME_EQUITY_LOAN_SLUG ?
            [{ ...product, motive: null }] :
            [
              { ...product, motive: DEBT_CONSOLIDATION_PRODUCT_MOTIVE },
              { ...product, motive: WORK_CAPITAL_PRODUCT_MOTIVE },
              { ...product, motive: HOUSE_REMODELING_PRODUCT_MOTIVE },
              { ...product, motive: LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE },
              { ...product, motive: LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE },
              { ...product, motive: LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE },
              { ...product, motive: RESCHEDULE_MOTIVE },
              { ...product, motive: null },
            ]
          )
        ],
        [],
      );
    },
  },

  methods: {
    closeForm() {
      this.formModal = false;
    },

    onClickAdd() {
      this.formModal = true;
    },
  },

  components: {
    TitledCard,
    ActiveProducts,
    DealFormModal,
  },
};
</script>

<style lang="sass" scoped>
  .active-products-card
    &__content
      max-height: 140px
      overflow-y: auto
</style>
