import isEqual from 'lodash/isEqual';
import { HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

const DNI_VAT_TYPE_ID = '1';
const CE_VAT_TYPE_ID = '2';
const USD_CURRENCY_ID = '2';

const MIN_LTV = 0.75;

export default {
  COUNTRY_CODE: 'pe',

  /**
   * FEATURES
   */
  RISK_FILES_ENABLED: true,
  INVESTMENTS_ENABLED: true,
  FORMALIZATIONS_ENABLED: true,
  WHATSAPP_TEMPLATES: true,
  LOANS_METRICS_ENABLED: true,
  SCOTIA_SCORE_ENABLED: true,
  BONDS_ENABLED: true,
  SIMULATOR_ENABLED: true,
  SENTINEL_ENABLED: true,

  /**
   * UNIQUE VALUES
   */
  PROPERTY_TYPE_TERRAIN_ID: '8',
  DEFAULT_CURRENCY_ID: '1',
  USD_CURRENCY_ID: USD_CURRENCY_ID,
  PHONE_CODE: 51,
  GROUPS_INVESTOR_ID: process.env.VUE_APP_GROUPS_INVESTOR_ID || '10',

  /**
   * THEMING
   */
  FORM_URL: 'https://rebajatuscuentas.com/pe/formulario',
  ACCOUNTING: {
    DECIMAL: '.',
    THOUSAND: ',',
    SYMBOL: 'S/',
  },
  CRM_FUNNEL_LABELS: [
    { title: 'Tunel de Ventas', color: '#f44336', size: 2 },
    { title: 'Revisión de Documentos', color: '#f44336', size: 1 },
    { title: 'Evaluación', color: '#ffc107', size: 1 },
    { title: 'Tasación', color: '#ffc107', size: 1 },
    { title: 'Notaría/Venta', color: '#4caf50', size: 1 },
  ],

  /**
   * SELECTS AND LISTS
   */

  ADDRESS_POLITICAL_DIVISIONS: [
    { name: 'país' },
    { name: 'departamento' },
    { name: 'provincia' },
    { name: 'distrito' },
  ],

  INCOME_CATEGORIES_SELECT: [
    { text: '1ra (Alquiler de bienes e inmuebles)', value: '1' },
    { text: '2da (Dividendos, Acciones y Ganancias de Capital)', value: '2' },
    { text: '3ra (Actividades empresariales)', value: '3' },
    { text: '4ta (Trabajo Independiente)', value: '4' },
    { text: '5ta (Planilla)', value: '5' },
    { text: 'No declarados, sin sustento', value: '7' },
  ],

  /**
   * VALIDATIONS
   */
  DOCS_VALID_EXTENSIONS: [
    {
      ids: ['41', '70', '81', '43'],
      extensions: ['pdf'],
    },
  ],

  PHONE_VALIDATION: {
    REGXP: /^519[0-9]{8}$/,
    MESSAGE: 'El campo {_field_} debe tener el formato +(51)9########',
  },

  CUSTOMER_FORM_RULES: ({ data }) => ({
    firstName: { required: true, alpha_spaces: true, min: 3, max: 70 },
    lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    maternalLastName: { alpha_spaces: true, min: 3, max: 50 },
    email: {
      required: true,
      email: true,
    },
    mobile: { phone_number: true },
    vat: do {
      if(data.vatTypeId === DNI_VAT_TYPE_ID)
        ({ required: true, numeric: true, length: 8 });
    else if(data.vatTypeId === CE_VAT_TYPE_ID)
        ({ required: true, numeric: true, min: 6, max: 11 });
  else({ required: true });
},
address: {
  zipCode: { numeric: true },
},
  }),

BURO_FORM_RULES: ({ data }) => ({
  firstName: { required: true, alpha_spaces: true, min: 3, max: 50 },
  lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
  maternalLastName: { required: true, alpha_spaces: true, min: 3, max: 20 },
  vatTypeId: { required: true },
  vat: do {
    if(data.vatTypeId === DNI_VAT_TYPE_ID)
        ({ required: true, numeric: true, length: 8 });
  else if(data.vatTypeId === CE_VAT_TYPE_ID)
        ({ required: true, numeric: true, min: 6, max: 11 });
      else ({ required: true });
    },
birth: { required: true },
mobile: { phone_number: true },
address: {
  zipCode: { required: true, numeric: true },
},
  }),

PROPERTY_FORM_RULES: ({ data: { currency, value } }) => ({
  value: {
    required: true,
    decimal: 2,
    min_value: currency?.id === USD_CURRENCY_ID ? 15e3 : 5e4,
    max_value: currency?.id === USD_CURRENCY_ID ? 6e6 : 2e7,
  },
  auctionValue: {
    decimal: 2,
    min_value: currency?.id === USD_CURRENCY_ID ? 1e4 : 34e3,
    max_value: value * MIN_LTV,
  },
  yearOfConstruction: {
    min_value: 1900,
    max_value: new Date().getFullYear(),
  },
  address: {
    zipCode: { numeric: true },
  },
}),

  APPRAISAL_FORM_RULES: ({ data: { commercialValue } }) => ({
    appraiserId: { required: true },
    date: { required: true },
    commercialValue: {
      required: true,
      decimal: 2,
      min_value: 2e4,
      max_value: 2e7,
    },
    auctionValue: {
      required: true,
      decimal: 2,
      min_value: 1e4,
      max_value: commercialValue * MIN_LTV,
    },
    rebuildValue: {
      required: true,
      decimal: 2,
      min_value: 0,
      max_value: commercialValue,
    },
    yearOfConstruction: {
      required: true,
      min_value: 1900,
      max_value: new Date().getFullYear(),
    },
    registrationAddress: { required: true, min: 10 },
    municipalAddress: { required: true, min: 10 },
    currencyChange: { required: true },
    landArea: { required: true, min_value: 0 },
    constructionArea: { required: true, min_value: 0 },
  }),

    INCOME_FORM_RULES: ({ data: { currency, amount }, hasWorkplaceData }) => ({
      amount: {
        required: true,
        decimal: 2,
        min_value: currency?.id === USD_CURRENCY_ID ? 270 : 900,
        max_value: currency?.id === USD_CURRENCY_ID ? 3e5 : 1e6,
      },
      netAmount: {
        decimal: 2,
        max_value: Number(amount),
      },
      incomeTypeId: {
        required: true,
        numeric: true,
      },
      workPlace: hasWorkplaceData
        ? {
          name: { required: true, min: 3, max: 50 },
          vat: { required: true, numeric: true, length: 11 },
        }
        : {
          name: null,
          vat: null,
        },
    }),

      CREDIT_FORM_RULES: ({ data: { tea, simpleFee, currency } }) => ({
        bankId: { required: true },
        insuranceTypeId: { required: true },
        credit: {
          required: true,
          decimal: 2,
          min_value: currency?.id === USD_CURRENCY_ID ? 6e3 : 2e4,
          max_value: currency?.id === USD_CURRENCY_ID ? 6e6 : 2e7,
        },
        rateType: { required: true },
        tea: { decimal: 2, min_value: 0, max_value: 50 },
        tcea: { decimal: 2, min_value: tea },
        simpleFee: {
          required: true,
          decimal: 2,
          min_value: currency?.id === USD_CURRENCY_ID ? 3e2 : 9e2,
          max_value: currency?.id === USD_CURRENCY_ID ? 12e3 : 4e4,
        },
        doubleFee: {
          required: true,
          decimal: 2,
          min_value_or_zero: (Number(simpleFee) || 0) + 1,
          max_value: currency?.id === USD_CURRENCY_ID ? 24e3 : 8e4,
        },
        limitMonths: {
          required: true,
          numeric: true,
          min_value: 12,
          max_value: 300,
        },
      }),

        PROPOSAL_FILTERS_FORM_RULES: ({
          data: { currency, propertyValue, productSlug },
          maxMonths = 240,
        }) => ({
          propertyValue: {
            min_value: currency?.id === USD_CURRENCY_ID ? 15e3 : 5e4,
            max_value: currency?.id === USD_CURRENCY_ID ? 6e6 : 2e7,
          },
          totalIncoming: {
            required: true,
            decimal: 2,
            min_value: currency?.id === USD_CURRENCY_ID ? 270 : 900,
            max_value: currency?.id === USD_CURRENCY_ID ? 3e5 : 1e6,
          },
          openAmount: {
            min_value: ((propertyValue || 0) * 0.1).toFixed(0),
          },
          creditAmount: {
            required: true,
            min_value: do {
              if(
                isEqual(HOME_EQUITY_LOAN_SLUG, productSlug) &&
              currency?.id === USD_CURRENCY_ID
        )
            15000;
            else if(isEqual(HOME_EQUITY_LOAN_SLUG, productSlug)) 10000;
          else 10000;
        },
          max_value: do {
            if (isEqual(HOME_EQUITY_LOAN_SLUG, productSlug))
              propertyValue * 0.5;
            else propertyValue * 0.9;
          },
    },
limitMonths: {
  required: true,
    min_value: 6,
      max_value: maxMonths,
    },
    balloon: { min_value: 0, max_value: 100 },
    guaranteeType: {
      required: false,
    }
  }),

TITLE_STUDY_FORM_RULES: () => ({
  electronicRegistrationNumber: { required: true },
  electronicRegistrationNumberCity: { required: true },
  liensChargesIds: { required: true },
  ownersIds: { required: true },
  comments: { required: true, min: 5 },
  status: { required: true },
  acquisition: { required: true },
}),

  SIMULATOR_FILTERS_FORM_RULES: ({
    data: { currency, propertyValue, customArguments }
  }) => ({
    propertyValue: {
      required: true,
      min_value: currency?.id === USD_CURRENCY_ID ? 15e3 : 5e4,
      max_value: currency?.id === USD_CURRENCY_ID ? 6e6 : 2e7,
    },
    openAmount: {
      min_value: ((propertyValue || 0) * 0.1).toFixed(0),
    },
    creditAmount: {
      required: true,
      min_value: currency?.id === USD_CURRENCY_ID ? 5e3 : 2e4,
      max_value: propertyValue * 0.4,
    },
    limitMonths: {
      required: true,
      min_value: 12,
      max_value: 120,
    },
    customArguments: {
      rate: { required: !!customArguments?.balloon, decimal: 2, min_value: 4, max_value: 40 },
      openAmount: { decimal: 2 },
      balloon: { min_value: 0, max_value: 100 },
    },
  }),
};
