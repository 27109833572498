const TYPES = {
  F: 'Pagos fijos',
  H: 'Hipoteca',
  L: 'Sin limite preestablecido',
  R: 'Revolvente',
  Q: 'Quirógrafario',
  A: 'Crédito de habitación o avío',
  E: 'Crédito refaccionario',
  P: 'Crédito prendario',
  AA: 'Arrendamiento automotriz',
  AB: 'Automotriz bancario',
  AE: 'Física actividad empresarial',
  AM: 'Aparatos/Muebles',
  AR: 'Arrendamiento',
  AV: 'Aviación',
  BC: 'Banca comunal',
  BL: 'Bote/Lancha',
  BR: 'Bienes raíces',
  CA: 'Compra de automóvil',
  CC: 'Crédito al consumo',
  CD: 'Crédito filscal',
  CO: 'Consolidación',
  CP: 'Crédito personal al consumo',
  ED: 'Editorial',
  EQ: 'Equipo',
  FF: 'Fondeo fira',
  FI: 'Fianza',
  FT: 'Factoraje',
  GS: 'Grupo solidario',
  OT: 'Otros',
  HB: 'Hipotecario bancario',
  HE: 'Prestamo tipo home equity',
  HV: 'Hipotecario o vivienda',
  LC: 'Línea de crédito',
  MC: 'Mejoras a la casa',
  NG: 'Préstamo no garantizado',
  PB: 'Préstamo personal bancario',
  PC: 'Procampo',
  PE: 'Préstamo para estudiante',
  PG: 'Préstamo garantizado',
  PQ: 'Préstamo quirografario',
  PM: 'Préstamo empresarial',
  PN: 'Préstamo nómina',
  PP: 'Préstamo personal',
  SH: 'Segunda hipoteca',
  TC: 'Tarjeta de crédito',
  TD: 'Tarjeta departamental',
  TG: 'Tarjeta garantizda',
  TS: 'Tarjeta de servicios',
  VR: 'Vehículo recreativo',
  NC: 'Desconocido',
};

export default type => TYPES[type] || type;
