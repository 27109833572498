import { render, staticRenderFns } from "./risk-list-view.vue?vue&type=template&id=2675da34&scoped=true&lang=pug"
import script from "./risk-list-view.vue?vue&type=script&lang=js"
export * from "./risk-list-view.vue?vue&type=script&lang=js"
import style0 from "./risk-list-view.vue?vue&type=style&index=0&id=2675da34&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2675da34",
  null
  
)

export default component.exports