<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).px-2
          validation-provider(name="código" :rules="'required|length:6'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.code"
              :error-messages="errors"
              name="código"
              label="Código"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(type="button" color="error" text @click="$emit('cancel')") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Autorizar
</template>

<script>
  export default {
    name: 'authorize-nip-form',

    props: {
      requesting: { type: Boolean },
    },

    data() {
      return {
        data: {
          code: undefined,
        },
      };
    },

    methods: {
      async onSubmit() {
        this.$refs.form.validate()
          .then((success) => {

            if(success) this.$emit('submit', { code: this.data.code });
          });
      }
    },
  };
</script>

<style lang="sass" scoped>

</style>