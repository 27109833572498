
import VSelect from 'vuetify/lib/components/VSelect';
import { QUALIFICATIONS_BY_COMPLIANCE } from '@/utils/constants';

export default {
  name: 'investment-proposal-qualification-compliance-select',

  functional: true,

  render(h, context) {
    const items = Object.values(QUALIFICATIONS_BY_COMPLIANCE).map(qualificationCompliance => ({
        value: qualificationCompliance,
        text: context.parent.$t(`qualificationCompliance.${qualificationCompliance}`),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
