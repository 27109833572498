<template lang="pug">
  div(:class="b()")
    div(v-if="entryTitle" :class="b('title')") {{ entryTitle }}
    div(:class="b('entries')")
      results-table(v-if="entriesType === 'string'")
        results-row(:title="title" :value="filteredEntries")
      results-table(v-else-if="entriesType === 'object'")
        template(
          v-for="(entryVal, entryKey) in filteredEntries"
          v-if="entryKey !== '__typename'"
        )
          div(:class="b('children')" v-if="isChildren(entryVal)")
            buro-results-entry(
              :title="entryKey"
              :entries="entryVal"
            )
          results-row(
            v-else
            :title="entryKey"
            :value="entryVal"
          )
      template(v-else-if="entriesType === 'array'")
        buro-results-entry(
          v-for="(entryVal, entryKey) in filteredEntries"
          :key="entryKey"
          :entries="entryVal"
        )
</template>

<script>
import omitBy from 'lodash/omitBy';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import humps from 'humps';

import ResultsTable from './buro-results-table';
import ResultsRow from './buro-results-row';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'buro-results-entry',

  props: {
    title: { type: String },
    entries: { type: [String, Array, Object] },
  },

  computed: {
    entriesType() {
      if (isString(this.entries)) return 'string';
      else if (isArray(this.entries)) return 'array';
      else return 'object';
    },

    entryTitle() {
      return ['array', 'object'].includes(this.entriesType) && this.title
        ? humps.decamelize(this.title, { separator: ' ' })
        : false;
    },

    filteredEntries() {
      if (this.entriesType === 'object' && IS_PE_COUNTRY)
        return omitBy(this.entries, v => ['0.00', '0'].includes(v));

      return this.entries;
    },
  },

  methods: {
    isChildren(entry) {
      return isArray(entry) || isPlainObject(entry);
    },
  },

  components: { ResultsTable, ResultsRow },
};
</script>

<style lang="sass" scoped>
.buro-results-entry
  $c: &

  &__title
    font-size: 18px
    padding: 16px 8px
    font-weight: 500

    &:first-letter
      text-transform: uppercase

  &__children
    padding: 12px

    #{$c}
      &__title
        padding-top: 12px
</style>
