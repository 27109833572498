<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      div(:class="b('toolbar')" ref="toolbar")
        filters(
          :current-filters="filters"
          :hide-filters="hiddenFilters"
          @add="onAddFilter"
          @remove="onRemoveFilter"
        )
        view-mode-toggle(
          :value="viewMode"
          @input="switchViewMode"
        )
      div(:class="b('body')")
        component(
          :is="renderComponent"
          :filters="filtersByValue"
        )
    v-btn(:class="b('fab')" fab color="primary" @click="onClickFab")
      v-icon mdi-filter-outline
</template>

<script>
  import $ from 'jquery';
  import { mapGetters, mapActions } from 'vuex';

  import { BOARD_VIEW_MODE, TABLE_VIEW_MODE } from '@/store/modules/UI/deals/list';

  import ViewModeToggle from './components/view-mode-toggle';
  import BoardView from './components/board-view';
  import TableView from './components/table-view';
  import Filters from './components/filters';

  export default {
    name: 'deals-list-view',

    methods: {
      ...mapActions('UI/deals/list', [
        'addFilter',
        'removeFilter',
        'switchViewMode',
      ]),

      onAddFilter(filter) {
        this.addFilter(filter);
      },

      onRemoveFilter(key) {
        this.removeFilter(key);
      },

      onClickFab() {
        $(this.$refs.toolbar).slideToggle();
      },
    },

    computed: {
      ...mapGetters('UI/deals/list', ['filters', 'filtersByValue', 'viewMode']),

      renderComponent() {
        switch(this.viewMode) {
          case BOARD_VIEW_MODE:
            return BoardView;
          case TABLE_VIEW_MODE:
            return TableView;
        }
      },

      hiddenFilters() {
        return this.viewMode === BOARD_VIEW_MODE ? ['stageId'] : [];
      },
    },

    mounted() {
      if(window.innerWidth < 600) $(this.$refs.toolbar).hide();
    },

    components: { BoardView, TableView, Filters, ViewModeToggle },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deals-list-view
    &__container
      display: flex
      flex-direction: column
      height: calc(100vh - 64px)

    &__body
      padding-top: 2rem
      padding-left: 1rem
      padding-right: 1rem
      height: 100%
      overflow-x: auto

    &__toolbar
      padding: 0 1rem 1rem 1rem
      display: flex
      align-items: center
      justify-content: flex-end
      flex-wrap: wrap
      background-color: white
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.08)

      .filters
        width: 100%

      +sm
        padding: 0 1rem

        .filters
          flex: 1

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +sm
        display: none
</style>