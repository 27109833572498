<template lang="pug">
  tr(:class="b()")
    td(style="width:40%")
      v-text-field(
        v-model= "entry.bank"
        hide-details
      )
    td(style="width:17%")
      v-text-field(
        v-model= "entry.product"
        hide-details
      )
    td(:style="{ color: calificationColor, width:'10%' }").font-weight-bold {{ entry.qualification | formatCalification }}
    td(style="width:12%").text-no-wrap 
      v-text-field(
        v-model="entry.totalAmount"
        type="number"
        hide-details
      )
    td(style="width:10%").text-no-wrap  
      v-text-field(
        v-model="entry.amount"
        type="number"
        hide-details
      )
    td(style="width:10%").text-no-wrap  {{calculoPorcentaje}}
    v-btn(@click="removeobjDebt1" style="width:.50rem; height:2.5rem; margin-left:1rem; color:red; background-color:transparent; border-radius:0; box-shadow:none" title="Eliminar deuda") X
</template>

<script>
  import formatCalification from '@/utils/format-buro-qualification';
  import getCalificationColor from '@/utils/buro-qualification-color';

  export default {
    name: 'debts-table-row',

    props: {
      entry: { type: Object, required: true },
      index: Number,
    },

    data() {
      return {
        calculoPorcentaje:'', 
      }
    },

    methods: {
      removeobjDebt1() {
        this.$emit('remove-file1', this.index);
      },

      porcentajeTotal() {
        if (this.entry.amount <= this.entry.totalAmount) {
          if (this.entry.amount > 0 && this.entry.amount <= this.entry.totalAmount) {
            this.calculoPorcentaje = ((this.entry.amount / this.entry.totalAmount) * 100).toFixed(2) + '%';
          } else if (this.entry.amount < 0) {
            this.calculoPorcentaje = 'Monto invalido';
          } else if ((this.entry.amount == 0 || this.entry.amount == '') && this.entry.totalAmount > 0) {
            this.calculoPorcentaje = '0%';
          }
        } else {
          this.calculoPorcentaje = "Monto invalido pago mayor a deuda";
        }
      },
    },

    watch: {
      'entry.amount': 'porcentajeTotal',
      'entry.totalAmount': 'porcentajeTotal'
    },

    computed: {
      calificationColor() {
        return getCalificationColor(this.entry.qualification);
      },
    },

    mounted() {
      this.porcentajeTotal();
    },

    filters: {
      formatCalification,
    },
  };
</script>

<style lang="sass" scoped>
  .debts-table-row
    td
      padding: 3px 8px
      border-bottom: 1px solid darken(white, 6%)

      &::nth-child(5)
        min-width: 150px
</style>