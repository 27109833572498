import VSelect from 'vuetify/lib/components/VSelect';

export default {
  name: 'boolean-select',

  functional: true,


  render(h, context) {
    const items = [
      { value: true, text: context.props.trueText || 'Verdadero' },
      { value: false, text: context.props.falseText || 'Falso' },
    ];

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};