<template lang="pug">
  div
    template(v-for="product, i in products")
      product(
        :slug="product.slug"
        :motive="product.motive"
        :error-messages="product.errorMessages"
        @click.native="$emit('click', { product })"
      )
      v-divider(v-if="i + 1 < products.length").my-2
</template>

<script>
  import Product from './products-list-row';

  export default {
    name: 'products-list',

    props: {
      products: { type: Array, default: () => [] },
    },

    components: { Product },
  };
</script>

<style lang="sass" scoped>

</style>