<template lang="pug">
  router-link(:to="investmentProposalRoute" :class="b({ selected })")
    div
      v-checkbox(
        :value="selected"
        hide-details
        @change="onChangeCheckbox"
        @click.native.prevent="() => null"
      ).ma-0.pa-0
    div.font-weight-medium {{ investmentProposal.borrower.firstName }} {{ investmentProposal.borrower.lastName }}
    div.font-weight-medium {{ investmentProposal.investor.firstName }} {{ investmentProposal.investor.lastName }}
    div.text-no-wrap {{ investmentProposal.disbursementDate | formatDate }}
    div.text-no-wrap {{ investmentProposal.borrowerRate }}%
    div.text-no-wrap {{ investmentProposal.quotesQuantity }} meses
    div.text-no-wrap {{ investmentProposal.pendingDebtBorrower, investmentProposal.currency | formatCurrency }}
    div.text-no-wrap {{ investmentProposal.balloonPaymentType || 0 }}%
    div.text-no-wrap 
      sbs-tag(:qualification="investmentProposal?.qualificationByCompliance || { description: 'N/A' }")
    div.fixed-width.text-no-wrap
      qualification-tag(:status="investmentProposal?.qualificationStatus?.status || 'N/A'")
    div.fixed-width.text-no-wrap
      status-tag(:status="investmentProposal.status")
    div.text-no-wrap
      v-icon(v-if="investmentProposal.extended" color="blue").mr-1 mdi-check-circle
      v-icon(v-else ).mr-1 mdi-close-circle
    div.text-no-wrap
      v-icon(v-if="investmentProposal.refinanced" color="blue").mr-1 mdi-check-circle
      v-icon(v-else ).mr-1 mdi-close-circle
    div.text-no-wrap
      v-icon(v-if="investmentProposal.judicialized" color="blue").mr-1 mdi-check-circle
      v-icon(v-else ).mr-1 mdi-close-circle
</template>

<script>
import StatusTag from '@/components/UI/investment-proposal-status-tag';
import SbsTag from '@/components/UI/investment-proposal-sbs-tag';
import QualificationTag from '@/components/UI/investment-proposal-qualification-tag';

export default {
  name: 'investment-proposals-table-item',

  props: {
    investmentProposal: { type: Object },
    selected: { type: Boolean, default: false },
  },

  methods: {
    onChangeCheckbox(val) {
      this.$emit(val ? 'select' : 'unselect', this.investmentProposal.id);
    },
  },

  computed: {
    investmentProposalRoute() {
      return {
        name: 'investment.show',
        params: { proposalId: this.investmentProposal.id },
      };
    },
  },

  components: { StatusTag, SbsTag, QualificationTag },
};
</script>

<style lang="sass" scoped>
  .investment-proposals-table-item
    display: table-row
    text-decoration: none
    color: inherit

    &:hover
      background-color: darken(white, 1%)

    & > div
      display: table-cell
      padding: 6px 8px
      vertical-align: middle

    .fixed-width
      max-width: 100px
      width: 100px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    &__status
      display: inline-block
      padding: 3px 8px
      border-radius: 6px
      color: white
      font-weight: 500

    &--selected
      background-color: transparentize($primaryColor, 0.85) !important
</style>