<template lang="pug">
  v-chip(
    @input="args => $emit('close', args)"
    :to="profileRoute"
  )
    user-avatar(
      :url="customer.avatar"
      :first-name="customer.firstName"
      :last-name="customer.lastName"
      :size="32"
      left
    )
    span {{ customer.firstName }} {{ customer.lastName }}
    span(v-if="suffix").ml-1.font-weight-medium ({{ suffix }})
</template>

<script>
  import UserAvatar from './user-avatar';

  export default {
    name: 'customer-chip',

    props: {
      customer: { type: Object, required: true },
      close: { type: Boolean, default: false },
      profileLink: { type: Boolean, default: true },
      suffix: { type: String },
    },

    components: { UserAvatar },

    computed: {
      profileRoute() {
        return this.profileLink ? { name: 'customers.show', params: { customerId: this.customer.id }} : undefined;
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>