<template lang="pug">
  div(:class="b()")
    div(:class="b('icon')")
      v-icon mdi-file-document
    v-layout(wrap)
      v-flex(xs12)
        a(:class="b('name')" :href="attachment.mediaPath" target="_blank") {{ name }}
      v-flex(xs12)
        div(style="font-weight: 600") {{ attachment.createdAt | formatDateTime }}
    div(v-if="deletable" :class="b('delete')" @click="onClickDelete")
      v-icon mdi-close-circle
</template>

<script>
  export default {
    name: 'attachments-item',

    props: {
      attachment: { type: Object, required: true },
      deletable: { type: Boolean },
      number: { type: Number, required: true },
    },

    computed: {
      name() {
        const matches = this.attachment.mediaPath.match(/attachments\/user_\d+\/(.*)\?/)
        const name = matches?.length > 0 ? matches[1] : `/document_${this.number}`;

        return name;
      }
    },

    methods: {
      onClickDelete() {
        this.$emit('delete', { attachment: this.attachment });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .attachments-item
    display: flex
    align-items: center
    background-color: darken(white, 14%)
    border-radius: 16px
    margin: .4rem
    padding: .25rem 0
    padding-right: .25rem

    &__name
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      text-decoration: none
      color: inherit
      flex: 1
      max-width: calc(100% - 24px)

    &__icon
      padding: 0 .5rem

    &__delete
      cursor: pointer
</style>