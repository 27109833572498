<template lang="pug">
  router-link(
    :class="b({ [reminder.status.toLowerCase()]: true })"
    :to="{ name: 'deals.show', params: { dealId: reminder.deal.id }}"
  )
    div(:class="b('title')") {{ reminder.activity.name }}
    div(:class="b('message')") {{ reminder.message }}
    div(:class="b('datetime')")
      v-icon mdi-alarm
      span(:class="b('datetime-stamp')") {{ reminder.datetime | formatDate('D MMM YYYY - hh:mm A') }}
</template>

<script>
  export default {
    name: 'reminders-list-item',

    props: {
      reminder: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  $pendingColor: #3d8af7
  $delayedColor: #bb285c
  $completedColor: #72bb53

  .reminders-list-item
    $c: &
    display: block
    color: inherit
    text-decoration: none
    padding: 12px 0
    padding-left: 16px
    border-left: 6px solid $pendingColor

    &:hover
      background-color: darken(white, 1%)

    &__title
      font-size: 16px
      font-weight: bold

    &__message
      font-size: 14px
      margin-bottom: 6px

    &__datetime
      display: flex
      align-items: center
      justify-content: flex-end

      & > *:first-child
        margin-right: 8px

    &--delayed
      border-left-color: $delayedColor

      #{$c}
        #{$c}__datetime
          color: $delayedColor

          i
            color: $delayedColor !important

    &--completed
      border-left-color: $completedColor
</style>