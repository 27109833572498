<template lang="pug">
  div(:class="b()")
    div(:class="b('name')") {{ debtor.name }} (#[span.font-weight-medium {{ debtor.vatType }}]: {{ debtor.vat }})
</template>

<script>
  export default {
    name: 'debtor-card',

    props: {
      debtor: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  .debtor-card
    display: block
    width: 100%
    padding: 1rem
    color: inherit
    text-decoration: none
    font-size: 12px
    user-select: none
    touch-callout: none
    color: black

    &__name
      font-size: 1.2em
</style>