<template lang="pug">
  v-expansion-panel-header(:class="b()")
    div(:class="b('root', { disable })")
      div(:class="b('header')")
        product-icon(:slug="slug" :size="38").pr-2
        span(:class="b('title')") {{ $t(`productSlug.${slug}`) }} ({{ count }})
        template(v-if="accepted")
          v-icon.pl-2(color="blue") mdi-check-circle
          commercial-file-button(
            @generate="onClickGenerateCommercialFile"
            :file-url="commercialFileUrl"
          )
        template(v-else)
          v-icon.pl-2(@click.stop="onClickSimulate").hidden-sm-and-up mdi-calculator
          v-btn(text color="primary" small @click.stop="onClickSimulate").hidden-xs-only Simular
      div(:class="b('subheader')" v-if="firstProposal")
        v-layout(wrap)
          v-flex(xs12 sm6 xl4)
            b Crédito:&nbsp;
            span {{ firstProposal.credit | formatCurrency(firstProposal.currency) }}
          v-flex(xs12 sm6 xl4)
            b Plazo:&nbsp;
            span {{ firstProposal.limitMonths }} meses&nbsp;
            saving-text(
              v-if="firstProposal.savings && firstProposal.savings.limitMonths"
              :amount="firstProposal.savings.limitMonths"
              :render="amount => `${amount} meses`"
            )
          v-flex(xs12 sm6 xl4)
            b Seguro:&nbsp;
            span {{ $t(`insuranceTypeId.${firstProposal.insuranceType.id}`) }}
          //-- Tipo de garantia
          v-flex(v-if="firstProposal.guaranteeType" xs12 sm6 xl4)
            b Tipo de Garantía: &nbsp;
            span {{ guaranteeType }}
          v-flex(v-if="firstProposal.openAmount" xs12 sm6 xl4)
            b Inicial:&nbsp;
            span {{ firstProposal.openAmount | formatCurrency(firstProposal.currency) }}
          v-flex(v-if="!hiddenFields.includes('propertyValue') && firstProposal.propertyValue" xs12 sm6 xl4)
            b Valor Propiedad:&nbsp;
            span {{ firstProposal.propertyValue | formatCurrency(firstProposal.currency) }}
          v-flex(xs12 sm6 xl4)
            b Motivo:&nbsp;
            span {{ humanizedMotive }}
          //-- Ingreso de rtc score
          v-flex(xs12 sm6 xl4)
            b RTC Score: &nbsp;
            span {{ rtc_SCORE }}
</template>

<script>
import SavingText from '@/components/UI/saving-text';
import ProductIcon from '@/components/UI/product-icon';
import CommercialFileButton from './commercial-file-button';

import { HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';
import getProductMotive from '@/utils/get-product-motive';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'deal-header',

  props: {
    slug: { type: String, required: true },
    motive: { type: String },
    dealId: { type: String, required: true },
    commercialFileUrl: { type: String },
    accepted: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    icon: { type: String, default: 'mdi-home' },
    proposals: { type: Array },
    disable: { type: Boolean },
  },

  methods: {
    onClickSimulate() {
      this.$emit('click-simulate', {
        proposal: this.firstProposal
          ? this.firstProposal
          : { product: { slug: this.slug } },
        dealId: this.dealId,
      });
    },

    onClickGenerateCommercialFile() {
      this.$emit('click-generate-commercial-file', {
        proposal: this.acceptedProposal,
      });
    },
  },

  computed: {
    firstProposal() {
      return this.proposals?.[0];
    },

    acceptedProposal() {
      return this.proposals.find(({ acceptedAt }) => acceptedAt);
    },

    humanizedMotive() {
      return getProductMotive(this.slug, this.motive);
    },

    rtc_SCORE() {
      return this.firstProposal.rtcScore || '-';
    },

    guaranteeType() {
      return this.firstProposal.guaranteeType.name;
    },

    hiddenFields() {
      if (IS_PE_COUNTRY && this.slug === HOME_EQUITY_LOAN_SLUG)
        return ['propertyValue'];

      return [];
    },
  },

  components: { SavingText, ProductIcon, CommercialFileButton },
};
</script>

<style lang="sass" scoped>
.deal-header
  padding: 8px 0 !important

  &__header
    display: flex
    align-items: center

  &__subheader
    background-color: darken(white, 4%)
    padding: .5rem 2rem
    line-height: 1.3

  .product-icon
    display: flex
    justify-content: center
    align-items: center

  &__title
    font-weight: bold
    font-size: 1rem

  &__root
    &--disable
      opacity: 0.4
</style>
