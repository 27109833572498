<template lang="pug">
  div(:class="b()")
    form(@submit.prevent="onSubmit")
      v-layout(wrap).align-center
        v-flex(xs12 md4 lg2)
          investor-select(
            v-model="data.investorIds"
            name="Inversionista"
            placeholder="Inversionista"
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg2)
          vehicle-select(
            v-model="data.investmentVehicleIds"
            name="Vehículo"
            placeholder="Vehículo"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg2)
          date-picker(
            v-model="data.expirationDate"
            label="Fecha de vencimiento (Rango)"
            clearable
            range
          )
        v-flex(xs12 md4 lg2)
          date-picker(
            v-model="data.paymentDate"
            label="Fecha de pago (Rango)"
            clearable
            range
          )
        v-flex(xs12 md4 lg2)
          status-select(
            v-model="data.status"
            label="Estado"
            multiple
            small-chips
            deletable-chips
            clearable
          )
        v-flex.text-right
          v-btn(
            color="primary"
            type="submit"
            :disabled="isDisabled"
          ) Aplicar filtros
</template>

<script>
  import DatePicker from '@/components/inputs/input-date-picker';
  import basicFormMixin from '@/components/forms/basic-form-mixin'
  import StatusSelect from '@/components/inputs/fee-status-select';
  import UserSelect from '@/components/inputs/user-select';
  import InvestorSelect from '@/components/inputs/investor-select';
  import VehicleSelect from '@/components/inputs/vehicle-select';

  export default {
    name: 'filters',

    mixins: [
      basicFormMixin({
        modelKey: 'filters',
        mapPropsToData({ filters }) {
          return {
            investorIds: filters?.investorIds || [],
            investmentVehicleIds: filters?.investmentVehicleIds || [],
            expirationDate: filters?.expirationDate || [],
            paymentDate: filters?.paymentDate || [],
            status: filters?.status || [],
          };
        },
      }),
    ],

    components: { DatePicker, StatusSelect, UserSelect, InvestorSelect, VehicleSelect },
  };
</script>

<style lang="sass">
  .filters
    .flex
      padding: 0 6px

    .v-btn
      padding-left: 32px
      padding-right: 32px
</style>