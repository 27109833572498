<template lang="pug">
  div(:class="b()")
    router-view
    home-screen-button
</template>

<script>
import HomeScreenButton from '@/components/UI/a2hs-button';

export default {
  name: 'app',

  components: { HomeScreenButton },
};

</script>

<style lang="sass">
  body, html
    margin: 0
    padding: 0
    height: 100%
    font-size: 14px !important

    .v-application
      font-family: 'Poppins', sans-serif !important
      font-size: 14px !important

  .loading-container
    display: flex
    justify-content: center
    padding: 3rem
</style>

