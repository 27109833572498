<template lang="pug">
  div(:class="b({ show })" @click="onClick")
    div(:class="b('header')")
      div(:class="b('add-icon')")
        v-icon mdi-plus
      div(:class="b('logo')")
        img(:src="$projectConfig.COMPANY_LOGO_MINI")
    div(:class="b('text')") Instalar Sistema de Gestión Hipotecaria
    div(:class="b('close')" @click.stop="onClickClose")
      v-icon mdi-close
</template>

<script>
  import Config from '@/config';

  export default {
    name: 'a2hs-button',

    data() {
      return {
        prompt: null,
        show: false,
        companyName: Config.COMPANY_NAME,
      };
    },

    methods: {
      onClick() {
        this.show = false;

        this.prompt.prompt()

        this.prompt.userChoice.then(() => {
            this.prompt = null;
          });
      },

      onClickClose() {
        this.show = false;
      },

      addListener() {
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          e.preventDefault();

          this.prompt = e;

          setTimeout(() => {
            this.show = true;
          }, 3e3);
        });
      },
    },

    mounted() {
      this.addListener();
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .a2hs-button
    display: flex
    align-items: center
    position: fixed
    bottom: 0
    left: 0
    right: 0
    padding-left: 8px
    transition: all .3s ease-out
    transform: translateY(100%)

    background-color: $primaryColor
    color: white
    z-index: 25

    @media print
      display: none !important

    &__logo
      display: none

    &__text
      flex: 1
      padding: 8px

    &__close
      display: flex
      align-items: center
      align-self: stretch
      background-color: black
      padding: 4px 8px

    i
      color: white

    +lg
      position: fixed
      width: auto
      top: initial
      right: 8px
      bottom: 8px
      left: initial
      padding-left: 0
      z-index: 50
      transform: translateX(110%)

      border-radius: 4px
      overflow: hidden

      cursor: pointer
      border: 1px solid darken(white, 6%)

      &__header
        padding: 4px 8px
        background-color: white

      &__add-icon
        display: none

      &__logo
        display: block
        width: 42px
        height: 42px

        img
          width: 100%
          height: 100%

      &__text
        font-family: Poppins
        font-size: 15px
        padding-left: 16px
        padding-right: 12px

    &--show
      transform: none
</style>