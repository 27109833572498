const TYPES = {
  F: 'Pagos fijos',
  H: 'Hipoteca',
  L: 'Sin limite preestablecido',
  R: 'Revolvente',
  Q: 'Quirógrafario',
  A: 'Crédito de habitación o avío',
  E: 'Crédito refaccionario',
  P: 'Crédito prendario',
};

export default type => TYPES[type] || type;
