<template lang="pug">
  tbody
    borrowers-aums-table-body-row(
      v-for="entry, idx in entries"
      :key="idx"
      :entry="entry"
      :parse-value="parseValue"
    )
</template>

<script>
import BorrowersAumsTableBodyRow from './borrowers-aums-table-body-row';

export default {
  name: 'borrowers-aums-table-body',

  props: {
    entries: { type: Array, default: () => [] },
    parseValue: { type: Function },
  },

  components: { BorrowersAumsTableBodyRow },
};
</script>

<style lang="sass" scoped>
  //.borrowers-aums-table-body
</style>