<template lang="pug">
  v-alert(color="error" type="error" :value="true" outlined :class="b()")
    div(:class="b('text')") {{ message }}
    v-btn(color="error" type="button" @click="$emit('retry')") Reintentar
</template>

<script>
  export default {
    name: 'fetch-error-alert',

    props: {
      retryable: { type: Boolean, default: true },
      error: { type: Error },
    },

    computed: {
      message() {
        return this.error?.message || 'Ha ocurrido un error al obtener los datos';
      },
    },
  };
</script>

<style lang="sass" scoped>
  .fetch-error-alert
    text-align: center

    &__text
      margin-bottom: 8px
</style>