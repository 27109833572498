<template lang="pug">
  .dashboard-card
    .dashboard-card__header
      .dashboard-card__title {{ currentTitle }}
    .dashboard-card__body
      .dashboard-card__scrollable-content
        .inner-text(:style="{ color: innerTextColor }")
          span(style="font-size: 1.6em") {{currentValue}}
        .dashboard-card__legend
          .dashboard-card__legend-item(v-for="range in colorsAndTexts" :key="range.value")
            .dashboard-card__legend-item-value(:style="{ color: range.color }") {{ range.value }}
            .dashboard-card__legend-item-percentage(:style="{ color: range.color }")

</template>

<script>

  export default {
    name: 'time-card',

    props: {
      currentTitle: { type: String, default: '' },
      currentValue: { type: String, default: '' },
    },

    computed: {
      hour() {
        return parseInt(this.currentValue.split(':')[0]);
      },
      minute() {
        return parseInt(this.currentValue.split(':')[1]);
      },
      second() {
        return parseInt(this.currentValue.split(':')[2]);
      },
      innerTextColor() {
        if(this.hour >= 24) return '#de3a21';
        else if(this.hour >= 12) return '#f4c009';
        else return '#0b8c5a';
      },
      colorsAndTexts() {
        return [
          { color: '#de3a21', value: '> 24 horas' },
          { color: '#f4c009', value: '12-23 horas' },
          { color: '#0b8c5a', value: '< 12 horas' },
        ];
      },
    },
  };
</script>

<style lang="sass" scoped>
.time-card
  max-width: 400px
  margin: 0 auto

.inner-text
  display: flex
  align-items: flex-end
  justify-content: center
  height: 100%
  width: 100%
  font-weight: bold

span
  font-size: 2em

.dashboard-card__legend
  display: flex
  justify-content: space-between
  margin-top: 20px

.dashboard-card__legend-item
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.dashboard-card__legend-item-value
  font-size: 1em

.dashboard-card__legend-item-percentage
  font-size: .8em
</style>