<template lang="pug">
  v-card(:class="b()").pa-3
    div(:class="b('header')")
      router-link(:class="b('customer-name')" :to="{ name: 'deals.show', params: { dealId: formalization.deal.id }}")
        | {{ customer.firstName }} {{ customer.lastName }}
    div(:class="b('data')")
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-label
          span Estado
        status-select(
          :class="b('compact-select')"
          :value="formalization.status"
          :loading="requesting"
          @input="onChangeStatusSelect"
        )
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on")  mdi-account-card-details
          span DNI
        span {{ customer.vat }}
      div(:class="b('data-row')")
        v-icon  mdi-phone
        a(:href="`tel:${customer.mobile}`" target="_blank") {{ customer.mobile }}
        .spacer
        div(:class="b('data-row-actions')")
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              a(:href="`tel:${customer.mobile}`" v-on="on" target="_blank")
                v-icon mdi-phone-outgoing
            span Llamar
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              a(:href="whatsappLink" v-on="on" target="_blank")
                v-icon mdi-whatsapp
            span Enviar mensaje a whatsapp
      div(:class="b('data-row')")
        v-icon  mdi-email
        a(:href="`mailto:${customer.email}`") {{ customer.email }}
      div(v-if="adviser" :class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-account-tie
          span Asesor
        span {{ adviser.firstName }} {{ adviser.lastName }}
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            div(v-on="on")
              v-icon(v-on="on") mdi-handshake
              router-link(
                v-if="formalization.investmentProposal"
                :to="{ name: 'investment.show', params: { proposalId: formalization.investmentProposal.id} }"
              ) Préstamo \#{{ formalization.investmentProposal.id }}
              span(v-else) No registrado
          span Préstamo
      div(v-if="formalization.investmentProposal && formalization.investmentProposal.status !== 'CANCELLED'" :class="b('data-row')")
        v-icon mdi-calendar
        router-link(
          :to="statementRoute"
          target="_blank"
        ) Cronograma de pagos
      div(:class="b('data-row')")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-icon(v-on="on") mdi-account-cash
          span Inversionista
        span(v-if="investor") {{ investor.firstName }} {{ investor.lastName }}
      div(:class="b('data-row')")
        v-icon mdi-lock-outline
        span.font-weight-medium Título bloqueo:
        span.ml-2 {{ blockData }}
      div(:class="b('data-row')")
        v-icon mdi-file-star-outline
        span.font-weight-medium  Título hipoteca:
        span.ml-2 {{ mortgageData }}
        mortgage-number-status-tag(v-if="formalization.mortgageNumberStatus" :status="formalization.mortgageNumberStatus").ml-2
      div(:class="b('data-row')")
        v-icon mdi-file-star-outline
        span.font-weight-medium  T. gravamen a levantar:
        mortgage-number-status-tag(v-if="formalization.mortgageNumberStatus2nd" :status="formalization.mortgageNumberStatus2nd").ml-2
      div(:class="b('data-row')")
        v-icon mdi-calendar
        span.font-weight-medium Escritura:
        span(v-if="formalization.attestationDate").ml-2  {{ formalization.attestationDate | formatDate }}
      div(:class="b('data-row')")
        v-icon mdi-bank-check
        span.font-weight-medium Notaría:
        span(v-if="formalization.notary").ml-2 {{ formalization.notary.name }}
      div(:class="b('data-row')")
        v-icon mdi-pound-box
        span.font-weight-medium Kardex:
        span.ml-2 {{ formalization.kardex }}
      v-layout
        v-flex(sm6)
          div(:class="b('dates-row')")
            div(:class="b('data-row')")
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-icon(v-on="on") mdi-calendar-plus
                span Fecha de creación de formalización
              span {{ formalization.createdAt | formatDate('D MMM YYYY hh:mm A') }}
      div(:class="b('actions')")
        v-btn(color="primary" text @click="onClickEdit")
          v-icon mdi-pencil
          span.ml-2 Editar
</template>

<script>
import formalizationBlockData from '@/utils/formalization-block-data';
import StatusSelect from '@/components/inputs/formalization-status-select';
import MortgageNumberStatusTag from '@/components/UI/mortgage-number-status-tag';
import { encrypt } from '@/utils/url-params-crypter';
import formalizationMortgageData from '../../../../utils/formalization-mortgage-data';

export default {
  name: 'formalization-card',

  props: {
    formalization: { type: Object, required: true },
    requesting: { type: Boolean, default: false },
  },

  computed: {
    customer() {
      return this.formalization.customer;
    },

    adviser() {
      return this.customer.adviser;
    },

    investor() {
      return this.formalization.investor;
    },

    whatsappLink() {
      return `https://api.whatsapp.com/send?phone=${this.customer?.mobile}`;
    },

    blockData() {
      return formalizationBlockData(this.formalization);
    },

    mortgageData() {
      return formalizationMortgageData(this.formalization);
    },

    scheduleRoute() {
      return {
        name: 'mortgage_schedule.investment_borrower',
        query: {
          _params: encrypt({
            investmentProposalId: this.formalization?.investmentProposal?.id,
            proposalId: this.formalization?.investmentProposal?.proposalId,
          }),
        },
      };
    },

    statementRoute() {
      return {
        name: 'investment.borrower_statement',
        query: {
          _params: encrypt({
            investmentProposalId: this.formalization.investmentProposal.id,
          }),
        },
      };
    },
  },

  methods: {
    onChangeStatusSelect(status) {
      this.$emit('change-status', status);
    },

    onClickEdit() {
      this.$emit('click-edit', { formalization: this.formalization });
    },
  },

  components: { StatusSelect, MortgageNumberStatusTag },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .formalization-card
    &__header
      display: flex
      align-items: center

    &__customer-name
      font-size: 22px

    &__data-row
      display: flex
      align-items: center
      margin: .5rem 0

      & .v-icon:first-child
        margin-right: 1rem

      &-actions
        display: flex
        align-items: center
        color: $primaryColor

        & > *
          margin-left: 6px

        .v-icon
          color: inherit

    &__actions
      display: flex
      justify-content: flex-end

    a
      text-decoration: none

</style>

<style lang="sass">
  .formalization-card
    &__compact-select
      display: inline-flex
      padding: 0

      .v-input__slot
        margin: 0

        &:before, &:after
          border: none !important

      .v-select__selection
        color: #1976d2
        font-size: 1rem

      .v-text-field__details
        display: none

      .v-icon
        color: #1976d2
</style>
