<template lang="pug">
  div(:class="b()")
    div(:class="b('content')")
      div(:class="b('body')")
        div(:class="b('title')")
          appraisal-status-tag(:status="appraisal.status").mr-2
          span {{ appraisal.date | formatDate('D MMMM YYYY') }} - {{ appraisal.appraiser.name }}
        div(:class="b('body-content')")
          div(:class="b('data')")
            div(:class="b('data-value')") {{ appraisal.commercialValue | formatCurrency(undefined, { precision: 0 }) }}
            div(:class="b('data-title')") Comercial
          div(:class="b('data')")
            div(:class="b('data-value')") {{ appraisal.auctionValue | formatCurrency(undefined, { precision: 0 }) }}
            div(:class="b('data-title')") Realización
          div(:class="b('data')")
            div(:class="b('data-value')") {{ appraisal.landArea + appraisal.constructionArea || '?' }} m²
            div(:class="b('data-title')") Área total
          div(:class="b('data')")
            div(:class="b('data-value')") {{ (appraisal.ltv * 100).toFixed(2) }} %
            div(:class="b('data-title')") LTV
        div(style="display:flex; justify-content:center; align-items:center" :class="b('body-content')")
          div(:class="b('data')")
            div(style="font-size:1rem;" :class="b('data-value')") {{ (appraisal.commercialities.commercialityProperty) }}
            div(:class="b('data-title')") Comerc.
          div(:class="b('data')")
            div(:class="b('data-value')") {{ parseFloat((appraisal.commercialities.auctionPercentage * 100)).toFixed(2) }} %
            div(:class="b('data-title')") % remate
          div(:class="b('data')")
            div(:class="b('data-value')") {{ (appraisal.commercialities.scoreProperty) }}
            div(:class="b('data-title')") Score
        div(:class="b('body-content')")
          div(:class="b('body-postcontent')")
            div #[span.font-weight-medium Tipo de cambio: ] {{ appraisal.currencyChange }}
          div(v-if="appraisal.validator") #[span.font-weight-medium Validado por: ] {{ appraisal.validator.firstName }} {{ appraisal.validator.lastName }}

      div(:class="b('actions')")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              icon
              @click="onClickEdit"
              color="primary"
              v-bind="attrs"
              v-on="on"
            )
              v-icon mdi-pencil
          span Editar tasación

        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              icon
              @click="openPopup"
              color="info"
              v-bind="attrs"
              v-on="on"
            )
              v-icon mdi-plus-circle
          span Agregar Comercialidad

    appraisals-commercialities(
      :is-open="showPopup"
      :appraisal-data="appraisal"
      @close="closePopup"
      @success="handleSuccess"
    )
      
    v-alert(v-if="appraisal.validationResult" type="error" outlined).mt-2
      .font-weight-bold Motivos de Rechazo
      div(v-html="appraisal.validationResult")

</template>

<script>
import AppraisalStatusTag from '@/components/UI/appraisal-status-tag';
import appraisalsCommercialities from '@/views/formalizations/show/components/appraisals-commercialities-card.vue';

export default {
  name: 'appraisals-list-item',

  props: {
    appraisal: { type: Object, required: true },
  },

  data() {
    return {
      showPopup: false,
    }
  },

  methods: {
    onClickEdit() {
      this.$emit('click-edit', { appraisal: this.appraisal });
    },
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },

  },

  components: { AppraisalStatusTag, appraisalsCommercialities },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .appraisals-list-item
    margin-bottom: 8px

    &__content
      display: flex
      align-items: center

    &__body
      flex: 1

      &-content
        padding: 4px 6px 0px 12px

      &-postcontent
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 8px

    &__title
      font-weight: 500
      color: darken(white, 60%)

    &__data
      text-align: center

      &-value
        font-size: 18px
        font-weight: 500
        white-space: nowrap

      &-title
        font-size: 14px

    +sm
      &__body
        &-content
          display: flex
          flex-wrap: wrap
          justify-content: space-evenly

      &__data
        margin: 0 12px 6px 12px

    +lg
      &__data
        &-value
          font-size: 16px


</style>
