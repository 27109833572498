<template lang="pug">
  metrics-layout
    filters(
      :filters="filters"
      :requesting="$apollo.queries.costsPerDealTable.loading"
      @submit="onSubmitFilters"
      slot="filters"
    )
    div
      loading-wrapper(v-if="$apollo.queries.costsPerDealTable.loading || !costsPerDealTable")
      .pa-2(v-else)
        costs-per-deal-table(:rows="costsPerDealTable.rows" :totals="costsPerDealTable.totals")
      //- loading-wrapper(:loading="$apollo.queries.costsPerDealGraphics.loading")
      //-   v-layout(wrap)
      //-     v-flex(
      //-       v-for="graphic in costsPerDealGraphics"
      //-       :key="graphic.name"
      //-       xs12 md6 lg4
      //-     ).pa-2
      //-       chart-card(
      //-         :title="graphic.name"
      //-         :data="graphic.data"
      //-       )
</template>

<script>
  import isEqual from 'lodash/isEqual';
  import moment from 'moment';

  import Config from '@/config';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import MetricsLayout from '../components/metrics-layout';
  import Filters from './components/filters';
  import CostsPerDealTable from './components/costs-per-deal-table';
  import ChartCard from './components/chart-card';
  import {
    CostsPerDealTableQuery,
    // CostsPerDealGraphicQuery,
  } from './graphql/queries.gql';

  export default {
    name: 'metrics-costs-per-deal-view',

    data() {
      return {
        filters: {
          country: Config.COUNTRY_CODE.toUpperCase(),
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          product: undefined,
          groupBy: 'DAY',
        },
        costsPerDealTable: [],
        // costsPerDealGraphics: [],
      };
    },

    apollo: {
      costsPerDealTable: {
        query: CostsPerDealTableQuery,
        fetchPolicy: 'network-only',

        variables() {
          return {
            ...this.filters,
          };
        },
      },

      // costsPerDealGraphics: {
      //   query: CostsPerDealGraphicQuery,
      //   fetchPolicy: 'network-only',

      //   variables() {
      //     return {
      //       ...this.filters,
      //     };
      //   },
      // },
    },

    components: {
      Filters,
      MetricsLayout,
      CostsPerDealTable,
      ChartCard,
      LoadingWrapper
    },

    methods: {
      onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.costsPerDealTable.refetch();
          // this.$apollo.queries.costsPerDealGraphics.refetch();
        }
        else
          Object.assign(this.filters, filters);
      },
    },
  };
</script>