<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 sm6).px-2
          validation-provider(name="categoria" :rules="rules.incomeTypeId" v-slot="{ errors }")
            income-category-select(
              v-model="data.incomeTypeId"
              :error-messages="errors"
              name="categoria"
              @input="onInputCategory"
            )
        v-flex(xs12 sm6).px-2
          validation-provider(name="ingresos brutos" :rules="isNotDeclaredIncome || isVerified ? null : rules.amount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.amount"
              :disabled="isNotDeclaredIncome || isVerified"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              name="ingresos brutos"
              type="number"
              label="Ingresos (brutos)"
            )
        v-flex(xs12 sm6).px-2
          validation-provider(name="ingresos netos" :rules="isNotDeclaredIncome || isVerified ? null : rules.netAmount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.netAmount"
              :disabled="isNotDeclaredIncome || isVerified"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              name="ingresos netos"
              type="number"
              label="Ingresos (netos)"
            )
        v-flex(v-if="renderField('currencyId')" xs12 sm6).px-2
          validation-provider(name="moneda" :rules="'required'" v-slot="{ errors }")
            currency-select(
              v-model="data.currency"
              name="moneda"
              :error-messages="errors"
              return-object
            )
        v-flex(xs12 sm6).px-2
          date-picker(
            v-model="data.startAt"
            name="fecha de inicio"
            label="Fecha de inicio"
          )
        v-flex(xs12 sm6).px-2
          v-text-field(
            v-model="data.position"
            name="position"
            label="Posición"
          )
        v-flex(xs12).px-2
          v-text-field(
            v-model="data.description"
            name="description"
            label="Describe cómo percibes ingresos"
          )
        template(v-if="isNotDeclaredIncome")
          v-flex(xs12).px-2
            validation-provider(name="actividad económica" :rules="'required'" v-slot="{ errors }")
              economic-activity-select(
                :value="data.industryId"
                :default-items="economicActivityDefaultItems"
                :error-messages="errors"
                name="actividad económica"
                label="Industria a la que se dedica tu empresa"
                @input="onInputActivity"
                return-object
              )
          div(v-if="currentActivity" :class="b('activity-description')")
            div(:class="b('activity-description-title')") Descripción de la actividad económica
            div(:class="b('activity-description-description')") {{ currentActivity.description }}
          v-flex(xs12).px-2.pb-3
            .title.font-weight-medium.text-center.text--secondary Ventas brutas mensuales
          v-flex(xs12).px-2
            incomes-by-month(
              ref="incomesByMonth"
              v-if="isNotDeclaredIncome"
              v-model="data.byMonths"
              :disabled="isVerified"
              :min-length="6"
              :max-length="12"
              :currency-symbol="data.currency.symbol"
            )
          v-flex(xs12).pb-3
      v-divider.pb-2
      v-layout(wrap)
        v-flex(xs12).px-2.pb-1
          .title.font-weight-medium.text-center.text--secondary Datos de la empresa

        v-flex(xs12).px-2
          company-select(
            ref="companySelect"
            :value="selectedCompany ? selectedCompany.id : undefined"
            :default-items="companySelectDefaultItems"
            @input="onSelectCompany"
            label="Buscar empresa existente"
            prepend-icon="mdi-magnify"
            return-object
            clearable
          )

        v-flex(xs12 sm6).px-2
          validation-provider(name="nombre" :rules="rules.workPlace.name" v-slot="{ errors }")
            v-text-field(
              v-model="data.workPlace.name"
              :error-messages="errors"
              name="nombre"
              label="Nombre"
            )
        v-flex(xs12 sm6).px-2
          validation-provider(:name="$t('workPlaceVat')" :rules="rules.workPlace.vat" v-slot="{ errors }")
            v-text-field(
              v-model="data.workPlace.vat"
              :error-messages="errors"
              :disabled="isRegisteredCompany"
              :name="$t('workPlaceVat')"
              :label="$t('workPlaceVat')"
            )
        v-flex(xs12 sm6).px-2
          v-text-field(
            v-model="data.workPlace.phone"
            name="teléfono"
            label="Teléfono"
          )
        v-flex(xs12 sm6).px-2
          v-text-field(
            v-model="data.workPlace.email"
            name="correo"
            label="Correo"
          )
        v-flex(xs12).px-2
          v-text-field(
            v-model="data.workPlace.address.line1"
            name="dirección"
            label="Dirección"
          )
        v-flex(
          v-for="division, idx in politicalDivisions"
          v-show="idx > 0"
          :key="idx"
          xs12 md6
        ).px-2
          location-select(
            :value="$addressInput.getValue(idx)"
            :name="division.name"
            :disabled="$addressInput.isDisabled(idx)"
            :parent-id="$addressInput.getParentId(idx)"
            :default-items="$addressInput.getDefaultItems(idx)"
            :label="division.name"
            @input="value => $addressInput.onInput(value, idx)"
          )
        v-flex(v-if="renderField('zipCode')" xs12 sm6).px-2
          v-text-field(
            v-model="data.workPlace.address.zipCode"
            name="código postal"
            label="Código postal"
          )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash/omit';
import last from 'lodash/last';
import moment from 'moment';
import basicFormMixin from '@/components/forms/basic-form-mixin';
import IncomeCategorySelect from '@/components/inputs/income-category-select';
import IncomesByMonth from '@/components/inputs/incomes-by-month';
import CurrencySelect from '@/components/inputs/currency-select';
import DatePicker from '@/components/inputs/input-date-picker';
import EconomicActivitySelect from '@/components/inputs/economic-activity-select';
import CompanySelect from '@/components/inputs/companies-select';
import Config from '@/config';
import addressInputMixin from '@/mixins/address-input-mixin';
import { IS_PE_COUNTRY, IS_MX_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'income-form',

  data() {
    return {
      selectedCompany: null,
      currentActivity: null,
    };
  },

  mixins: [
    addressInputMixin({ dataKey: 'workPlace.address' }),

    basicFormMixin({
      modelKey: 'income',
      veeValidation: true,
      mapPropsToData({ income }) {
        return {
          id: income?.id,
          description: income?.description,
          incomeTypeId: income?.type?.id,
          currency: income?.currency || Config.CURRENCIES_SELECT[0],
          position: income?.position || null,
          industryId: income?.industry?.id || null,
          amount: income?.amount,
          netAmount: income?.netAmount,
          startAt: income?.startAt || null,
          byMonths: income?.byMonths?.length
            ? income.byMonths.map(income => ({
                id: uuidv4(),
                amount: income.amount,
                year: moment(income.date).year(),
                month: moment(income.date).month() + 1,
              }))
            : [{ amount: null, year: null, month: null }],
          workPlace: {
            id: income?.workPlace?.id,
            name: income?.workPlace?.name,
            phone: income?.workPlace?.phone,
            email: income?.workPlace?.email,
            vat: income?.workPlace?.vat,
            address: {
              ...income?.workPlace?.address,
              _locationIds: income?.workPlace?.address?.location?.length
                ? [...income?.workPlace?.address.location].map(({ id }) => id)
                : ['1'],
            },
          },
        };
      },

      parseSubmit() {
        const { currency, ...data } = this.data;

        return {
          income: {
            ...data,
            amount: this.data.amount || null,
            netAmount: this.data.netAmount || null,
            currencyId: currency?.id,
            byMonths: this.isNotDeclaredIncome
              ? this.data.byMonths.map(entry => ({
                  date: moment(
                    `${entry.year}-${entry.month}-01`,
                    'YYYY-M-DD',
                  ).format('YYYY-MM-DD'),
                  amount: entry.amount,
                }))
              : [],
            workPlace: this.hasWorkplaceData
              ? {
                  ...omit(this.data.workPlace, ['id', '__typename']),
                  address:
                    this.data.workPlace.address._locationIds.length > 1
                      ? {
                          ...omit(this.data.workPlace.address, [
                            'id',
                            '__typename',
                            'location',
                            '_locationIds',
                          ]),
                          locationId: last(
                            this.data.workPlace.address._locationIds,
                          ),
                        }
                      : undefined,
                }
              : null,
          },
        };
      },
    }),
  ],

  computed: {
    rules() {
      return Config.INCOME_FORM_RULES({
        data: this.data,
        hasWorkplaceData: this.hasWorkplaceData,
      });
    },

    economicActivityDefaultItems() {
      return this.income?.industry ? [{ ...this.income.industry }] : [];
    },

    hasWorkplaceData() {
      return Boolean(this.data.workPlace.vat || this.data.workPlace.name);
    },

    isRegisteredCompany() {
      return Boolean(this.data.workPlace.id);
    },

    companySelectDefaultItems() {
      return this.selectedCompany ? [{ ...this.selectedCompany }] : [];
    },

    isNotDeclaredIncome() {
      return IS_PE_COUNTRY && ['7', '3'].includes(this.data.incomeTypeId);
    },

    isVerified() {
      return Boolean(this.income?.verifiedAt);
    },

    internalHiddenFields() {
      return [
        ...(IS_PE_COUNTRY ? ['currencyId', 'verifiedAt', 'zipCode'] : []),
        ...(IS_MX_COUNTRY ? ['currencyId'] : []),
      ];
    },
  },

  components: {
    IncomeCategorySelect,
    IncomesByMonth,
    CurrencySelect,
    DatePicker,
    EconomicActivitySelect,
    CompanySelect,
  },

  methods: {
    async validate() {
      try {
        const success = await this.$refs.form.validate();
        if (this.isNotDeclaredIncome)
          await this.$refs.incomesByMonth.validate();

        if (!success) return Promise.reject();

        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },

    onChangeIncomeData(income) {
      const workPlace = income?.workPlace;
      const industry = income?.industry;

      if (workPlace?.id) {
        this.selectedCompany = workPlace;
        this.setCompanySelectSearch(workPlace.vat);
      }

      if (industry) this.currentActivity = industry;
    },

    onSelectCompany(company) {
      this.selectedCompany = company;

      if (company) {
        this.$set(this.data, 'workPlace', {
          ...company,
          address: {
            ...company?.address,
            _locationIds: company?.address?.location?.length
              ? [...company?.address.location].map(({ id }) => id)
              : ['1'],
          },
        });
      } else {
        this.$set(this.data, 'workPlace', {
          name: undefined,
          phone: undefined,
          email: undefined,
          vat: undefined,
          address: {
            line1: undefined,
            zipCode: undefined,
            _locationIds: ['1'],
          },
        });
      }

      this.$forceUpdate();
    },

    onInputCategory() {
      this.$nextTick(() => {
        if (this.isNotDeclaredIncome) {
          if (this.data.byMonths.length < 1)
            this.data.byMonths = [{ year: null, month: null, amount: null }];
        } else if (this.data.byMonths.length) {
          this.data.byMonths = [];
          this.data.industryId = null;
          this.currentActivity = null;
        }
      });
    },

    getWorkPlaceDefaultValues() {
      const income = this.income;

      return {
        name: income?.workPlace?.name,
        phone: income?.workPlace?.phone,
        email: income?.workPlace?.email,
        vat: income?.workPlace?.vat,
        address: {
          ...income?.workPlace?.address,
          _locationIds: income?.workPlace?.address?.location?.length
            ? [...income?.workPlace?.address.location].map(({ id }) => id)
            : ['1'],
        },
      };
    },

    setCompanySelectSearch(search) {
      this.$refs.companySelect.setSearch(search);
    },

    onInputActivity(activity) {
      this.data.industryId = activity.id;
      this.currentActivity = activity;
    },
  },

  mounted() {
    this.onChangeIncomeData(this.income);
  },
};
</script>

<style lang="sass" scoped>
.income-form
  &__activity-description
    padding: 12px
    border-radius: 6px
    background-color: darken(white, 5%)
    border: 3px solid darken(white, 12%)
    margin: 0px 6px 8px

    &-title
      font-weight: 500

    &-description
      margin-top: 8px
      line-height: 1.5
</style>
