const colors = {
  PENDING: '#ffc107',
  APPROVED: '#4caf50',
  UNDER_REVIEW: '#2196f3',
  REFUSED: '#f44336',
};

const getStatusColor = status => colors[status.toUpperCase()] || '#696969';

export default getStatusColor;
