<template lang="pug">
  v-app
    loading-view(v-if="!currentUser || $apollo.queries.currentUser.loading")
    template(v-else)
      drawer(
        v-model="drawer"
        :items="drawerItems"
        :current-user="currentUser"
        :can-landing-page="canLandingPage"
        @click-change-password="passwordModal = true"
        @click-edit-landing="adviserLandingModal = true"
      )
      toolbar(@logout="onLogout" @click-menu="drawer = !drawer")
      v-main
        router-view
      password-form-modal(
        v-if="passwordModal"
        v-model="passwordModal"
        :requesting="passwordIsMutating"
        @submit="onSubmitPassword"
        @cancel="passwordModal = false"
      )
      adviser-landing-form-modal(
        v-if="adviserLandingModal"
        ref="adviserLandingFormModal"
        v-model="adviserLandingModal"
        :landing="landing"
        :user="currentUser"
        :requesting="adviserLandingIsMutating"
        @click-delete="onClickDeleteTestimony"
        @submit-testimony="onSubmitTestimony"
        @submit="onSubmitAdviserLandingForm"
        @cancel="adviserLandingModal = false"
      )
</template>

<script>
import compact from 'lodash/compact';

import { ChangePasswordMutation } from '@/graphql/mutations.gql';
import { AdviserLandingQuery } from './graphql/queries.gql';
import { PERMISSIONS } from '@/utils/constants';
import {
  CreateLandingMutation,
  UpdateLandingMutation,
  CreateTestimonyMutation,
  DeleteTestimonyMutation,
  UpdateTestimonyMutation,
} from './graphql/mutations.gql';
import Config from '@/config';
import currentUserMixin from '@/mixins/current-user-mixin';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import PasswordFormModal from '@/components/modals/password-form-modal';
import AdviserLandingFormModal from '@/components/modals/adviser-landing-form-modal';

import Drawer from './components/drawer';
import LoadingView from './components/loading-view';
import protectedLayoutMixin from '../mixins/protected-layout-mixin';
import Toolbar from './components/toolbar';

export default {
  name: 'dashboard-layout',

  apollo: {
    landing() {
      return {
        query: AdviserLandingQuery,

        variables() {
          return {
            id: this.currentUser.id,
          };
        },

        skip() {
          return !this.currentUser?.id;
        },
      };
    },
  },

  mixins: [
    mutationsHandlersMixin(),
    currentUserMixin(),
    protectedLayoutMixin(),
  ],

  data() {
    return {
      landing: null,

      // RequestingStates
      passwordIsMutating: false,
      adviserLandingIsMutating: false,

      // Modals & Renderers
      drawer: false,
      passwordModal: false,
      adviserLandingModal: false,
    };
  },

  methods: {
    createAdviserLanding({ landing }) {
      this.handleCreateMutation({
        mutation: CreateLandingMutation,
        variables: { data: { ...landing, dsUserId: this.currentUser.id } },
        query: this.getAdviserLandingQuery(),

        updateQueryFn: ({
          resultsData: {
            createLanding: { landing: createdLanding },
          },
        }) => (createdLanding ? { landing: { $set: createdLanding } } : null),

        requestingStateKey: 'adviserLandingIsMutating',
      });
    },

    updateAdviserLanding({ landing }) {
      this.handleUpdateMutation({
        mutation: UpdateLandingMutation,
        variables: { data: { ...landing, dsUserId: this.currentUser.id } },
        query: this.getAdviserLandingQuery(),

        updateQueryFn: ({
          resultsData: {
            updateLanding: { landing: updatedLanding },
          },
        }) => (updatedLanding ? { landing: { $set: updatedLanding } } : null),

        requestingStateKey: 'adviserLandingIsMutating',
      });
    },

    createTestimony({ testimony }) {
      this.handleCreateMutation({
        mutation: CreateTestimonyMutation,
        variables: { data: { ...testimony, adviserId: this.landing.id } },
        query: this.getAdviserLandingQuery(),

        updateQueryFn: ({
          resultsData: {
            createTestimony: { testimony: createdTestimony },
          },
        }) =>
          createdTestimony
            ? { landing: { testimonies: { $push: [createdTestimony] } } }
            : null,

        requestingStateKey: 'adviserLandingIsMutating',
      }).then(() => this.$refs.adviserLandingFormModal?.closeTestimonyForm());
    },

    updateTestimony({ testimony }) {
      this.handleUpdateMutation({
        mutation: UpdateTestimonyMutation,
        variables: { data: { ...testimony, adviserId: this.landing.id } },
        query: this.getAdviserLandingQuery(),

        updateQueryFn: ({
          resultsData: {
            updateTestimony: { testimony: updatedTestimony },
          },
        }) =>
          updatedTestimony
            ? {
                landing: {
                  testimonies: {
                    $apply: (testimonies) =>
                      testimonies.map((cTestimony) =>
                        cTestimony.id !== testimony.id
                          ? updatedTestimony
                          : cTestimony,
                      ),
                  },
                },
              }
            : null,

        requestingStateKey: 'adviserLandingIsMutating',
      }).then(() => this.$refs.adviserLandingFormModal?.closeTestimonyForm());
    },

    onSubmitTestimony({ testimony }) {
      if (testimony.id) this.updateTestimony({ testimony });
      else this.createTestimony({ testimony });
    },

    onClickDeleteTestimony({ testimony }) {
      this.handleDeleteMutation({
        mutation: DeleteTestimonyMutation,
        variables: { id: testimony.id },
        query: this.getAdviserLandingQuery(),

        updateQueryFn: () => ({
          landing: {
            testimonies: {
              $apply: (testimonies) =>
                testimonies.filter(
                  (cTestimony) => cTestimony.id !== testimony.id,
                ),
            },
          },
        }),

        requestingStateKey: 'adviserLandingIsMutating',
      });
    },

    onSubmitPassword({ newPassword, oldPassword }) {
      this.handleUpdateMutation({
        mutation: ChangePasswordMutation,

        requestingStateKey: 'passwordIsMutating',
        successMessage: 'Contraseñá cambiada exitosamente',

        variables: { oldPassword, newPassword },
      }).then(() => (this.passwordModal = false));
    },

    onSubmitAdviserLandingForm({ landing }) {
      if (landing.id) this.updateAdviserLanding({ landing });
      else this.createAdviserLanding({ landing });
    },

    getAdviserLandingQuery() {
      return {
        query: AdviserLandingQuery,

        variables: { id: this.currentUser?.id },
      };
    },
  },

  computed: {
    drawerItems() {
      return compact([
        this.currentUserHasPermissions([PERMISSIONS.PEGASUS.DASHBOARD]) && {
          title: 'Resumen',
          icon: 'mdi-clipboard-text',
          to: { name: 'dashboard.index' },
        },
        this.currentUserHasPermissions([PERMISSIONS.PEGASUS.METRICS]) && {
          title: 'Métricas Generales',
          icon: 'mdi-chart-areaspline',
          items: [
            { title: 'General', to: { name: 'metrics.general' } },
            { title: 'Marketing', icon: 'mdi-bullhorn', items: [
              { title: 'Costos', to: { name: 'metrics.costs_per_deal' } },
              { title: 'Fuentes', to: { name: 'metrics.sources' } },
              { title: 'Asesores', to: { name: 'metrics.advisers' } },
            ]},
            ...(Config.LOANS_METRICS_ENABLED
              ? [
                  {title: 'Garantías', icon: 'mdi-certificate', items: [
                    {
                      title: 'LTV realización',
                      to: { name: 'metrics.ltv_auction' },
                    },
                    {
                      title: 'LTV comercial',
                      to: { name: 'metrics.ltv_comercial' },
                    },
                    {
                      title: 'Garantías',
                      to: { name: 'metrics.legal_mortgage_value' },
                    },
                  ]},
                  { title: 'Préstamos', icon: 'mdi-handshake', items: [
                    { title: 'Prestatarios', to: { name: 'metrics.borrowers' } },
                    { title: 'Recaudo', to: { name: 'metrics.forecast' } },
                    { title: 'AUM', to: { name: 'metrics.aum' } },
                    { title: 'AUM %', to: { name: 'metrics.aum_pct' } },
                  ]},
                ]
              : []),
            ...(Config.BONDS_ENABLED
              ? [{ title: 'Bonos', to: { name: 'metrics.bonds' } }]
              : []),
            { title: 'Tasaciones Denegadas', to: { name: 'metrics.denied_appraisals' } },
          ],
        },
        this.currentUserHasPermissions([PERMISSIONS.PEGASUS.CUSTOMERS]) && {
          title: 'Clientes',
          icon: 'mdi-account-group',
          to: { name: 'customers.list' },
        },
        this.currentUserHasPermissions([PERMISSIONS.PEGASUS.DEALS]) && {
          title: 'CRM',
          icon: 'mdi-view-dashboard-variant',
          to: { name: 'deals.list' },
        },
        Config.RISK_FILES_ENABLED &&
          this.currentUserHasPermissions([PERMISSIONS.PEGASUS.RISK_FILES]) && {
            title: 'Riesgos',
            icon: 'mdi-calculator-variant',
            to: { name: 'risk_files.list' },
          },
        Config.INVESTMENTS_ENABLED &&
          this.currentUserHasPermissions([PERMISSIONS.PEGASUS.INVESTMENT]) && {
            title: 'Préstamos',
            icon: 'mdi-handshake',
            items: [
              { title: 'Préstamos', to: { name: 'investment.list' } },
              {
                title: 'Prestatarios',
                to: { name: 'investment.borrower_quotes' },
              },
              // {
              //   title: 'Inversionistas',
              //   to: { name: 'investment.investor_quotes' },
              // },
            ],
          },
        Config.BONDS_ENABLED &&
          this.currentUserHasPermissions([PERMISSIONS.PEGASUS.BONDS]) && {
            title: 'Bonos',
            icon: 'mdi-cash',
            items: [
              { title: 'Bonos', to: { name: 'bonds.list' } },
              { title: 'Cuotas', to: { name: 'bonds.quotes' } },
            ],
          },
        Config.FORMALIZATIONS_ENABLED &&
          this.currentUserHasPermissions([
            PERMISSIONS.PEGASUS.FORMALIZATIONS,
          ]) && {
            title: 'Formalizaciones',
            icon: 'mdi-briefcase-variant',
            to: { name: 'formalizations.board' },
          },
        {
          title: 'Herramientas',
          icon: 'mdi-tools',
          items: [
            this.currentUserHasPermissions([PERMISSIONS.PEGASUS.LIBRARY]) && { title: 'Biblioteca', to: { name: 'library' } },
            Config.SIMULATOR_ENABLED && this.currentUserHasPermissions([PERMISSIONS.PEGASUS.SIMULATOR]) && { title: 'Simulador', to: { name: 'simulator' } },
          ],
        },
      ]);
    },

    canLandingPage() {
      return this.currentUserHasPermissions([
        PERMISSIONS.PEGASUS.ADVISER_LANDING,
      ]);
    },
  },

  components: {
    Drawer,
    Toolbar,
    LoadingView,
    PasswordFormModal,
    AdviserLandingFormModal,
  },
};
</script>

<style lang="sass" scoped></style>
