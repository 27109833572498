import VSelect from 'vuetify/lib/components/VSelect';

export default {
  name: 'country-select',

  extends: VSelect,

  props: {
    items: { type: Array, default: () => [
      { value: 'PE', text: 'Perú' },
      { value: 'CO', text: 'Colombia' },
      { value: 'MX', text: 'México' },
    ]},
    label: { type: String, default: 'País' },
  },
};
