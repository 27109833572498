<template lang="pug">
  div(:class="b('container')")
    div(:class="b('filters')")
      ghost-simulator-form(
        :requesting="fetching"
        :filters="filters"
        :property-value="propertyValue"
        @submit="args => $emit('submit-filters', args)"
      )
    div(:class="b('content')")
      .loading-container(v-if="fetching")
        loading-spinner
      ghost-simulator-proposals(v-else-if="proposals" :proposals="proposals")
</template>

<script>
import GhostSimulatorForm from './ghost-simulator-form';
import GhostSimulatorProposals from './ghost-simulator-proposals';

export default {
  name: 'ghost-simulator',

  props: {
    proposals: { type: Array, default: () => [] },
    filters: { type: Object },
    fetching: { type: Boolean, default: false },
    propertyValue: { type: Number },
  },

  components: { GhostSimulatorForm, GhostSimulatorProposals },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .ghost-simulator
    &__filters
      background-color: darken(white, 1%)

    +lg
      &__filters
        background-color: darken(white, 1%)
        padding: 16px
        width: 320px

      &__container
        display: flex

      &__content
        flex: 1

</style>