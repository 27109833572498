<template lang="pug">
  div(:class="b({ trust: proposal && proposal.trust })")
    div(:class="b('container')")
      v-breadcrumbs(:items="breadcrumbItems").mb-2.pl-0
      loading-wrapper(
        v-if="$apollo.queries.proposal.loading || !proposal"
        :error="proposalError"
        @retry="onRetryProposalFetch"
      )
      template(v-else)
        .text-right.pb-2(v-if="canEdit")
          v-btn(color="primary" text @click="editModal = true")
            v-icon.mr-2 mdi-pencil
            span Editar información
        proposal-card(
          :proposal="proposal"
          :currency="currency"
        ).mb-4
        edit-form-modal(
          v-model="editModal"
          :proposal="proposal"
          :currency="currency"
          :requesting="proposalIsMutating"
          @submit="onSubmitEditForm"
        )
        investment-settlement-form-modal(
          v-model="settlementModal"
          :settlement="settlement"
          :currency="currency"
          :requesting="settlementIsMutating"
          :width="500"
          @submit="onSubmitSettlement"
        )
        investment-refinance-form-modal(
          v-model="refinanceModal"
          :refinance="refinance"
          :currency="currency"
          :requesting="refinanceIsMutating"
          :width="600"
          @submit="onSubmitRefinance"
        )
        investment-duplicate-proposal-form-modal(
          v-model="duplicateProposalModal"
          :duplicate-proposal="duplicateProposal"
          :currency="currency"
          :requesting="duplicateProposalIsMutating"
          :width="600"
          @submit="onSubmitDuplicateProposal"
        )

        div(:class="b('quotes')")
          div(:class="b('quotes-item')")
            borrower-quotes-card(
              ref="borrowerQuotesCard"
              :proposal="proposal"
              :quotes="parsedBorrowerQuotes"
              :currency="currency"
              :next-quote="nextQuote"
              :fetching="$apollo.queries.borrowerQuotes.loading"
              :mutating="borrowerQuotesIsMutating"
              :settlement-button="canUpdateSettlement"
              :refinance-button="canRefinance"
              @submit-payment="onSubmitBorrowerPayment"
              @click-reschedule-quote="onClickRescheduleQuote"
              @click-update-settlement="onClickUpdateSettlement"
              @click-refinance="onClickRefinanceButton"
              @refresh="() => $apollo.queries.borrowerQuotes.refetch()"
              @click-duplicate-proposal="onClickDuplicateProposalButton"
            )
          div(v-if="!proposal.trust" :class="b('quotes-item')")
            investor-quotes-card(
              ref="investorQuotesCard"
              :proposal="proposal"
              :quotes="parsedInvestorQuotes"
              :currency="currency"
              :fetching="$apollo.queries.investorQuotes.loading"
              :mutating="investorQuotesIsMutating"
              @submit-payment="onSubmitInvestorPayment"
              @click-reschedule-quote="onClickRescheduleQuote"
              @refresh="() => $apollo.queries.investorQuotes.refetch()"
            )
        reschedule-modal(
          v-if="rescheduleModalProps.value"
          v-model="rescheduleModalProps.value"
          :quote="rescheduleModalProps.quote"
          :quote-type="rescheduleModalProps.quoteType"
          :balloon-payment="rescheduleModalProps.balloonPayment"
          :investment-proposal-id="proposal.id"
          :proposal-id="proposal.proposalId"
          :requesting="rescheduleModalProps.requesting"
          @submit-reschedule="onSubmitRescheduleForm"
        )
</template>

<script>
import humps from 'humps';
import moment from 'moment';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import orderBy from 'lodash/orderBy';
import findLast from 'lodash/findLast';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import flow from 'lodash/flow';
import omit from 'lodash/omit';

import { INVESTMENT_PROPOSAL_STATUS, QUOTE_STATUS, QUOTE_TYPE } from '@/utils/constants';
import EditFormModal from '@/components/modals/investment-edit-form-modal';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import InvestmentSettlementFormModal from '@/components/modals/investment-settlement-form-modal.vue';
import InvestmentRefinanceFormModal from '@/components/modals/investment-refinance-form-modal.vue';
import InvestmentDuplicateProposalFormModal from '@/components/modals/investment-duplicate-proposal-form-modal.vue';

import ProposalCard from './components/investment-proposal-card';
import BorrowerQuotesCard from './components/borrower-quotes-card';
import InvestorQuotesCard from './components/investor-quotes-card';
import RescheduleModal from './components/reschedule-modal';
import {
  InvestmentShowProposalsQuery,
  BorrowerQuotesQuery,
  InvestorQuotesQuery,
} from './graphql/queries.gql';

import {
  UpdateBorrowerQuoteMutation,
  UpdateInvestorQuoteMutation,
  UpdateInvestmentProposalMutation,
  RescheduleBorrowerFeeMutation,
  RescheduleInvestorFeeMutation,
  UpdateSettlementAccountMutation,
  UpdateExtendRefinancedMutation,
  DoDuplicateInvestmentProposal
} from './graphql/mutations.gql';

export default {
  name: 'investment-show-view',

  mixins: [mutationsHandlersMixin()],

  apollo: {
    proposal: {
      query: InvestmentShowProposalsQuery,

      variables() {
        return { id: this.proposalId };
      },

      update({ investmentProposals }) {
        const proposal = investmentProposals?.objects?.[0];

        return proposal || null;
      },

      error() {
        this.proposalError = new Error('Ha ocurrido un error al obtener los datos');
      },
    },

    borrowerQuotes: {
      query: BorrowerQuotesQuery,
      fetchPolicy: 'cache-and-network',
      manual: true,

      variables() {
        return {
          proposalId: this.proposalId,
        }
      },

      result({ data }) {
        this.borrowerQuotes = orderBy(data?.borrowerQuotes?.objects, 'feeNumber', 'desc') || [];
      },
    },

    investorQuotes: {
      query: InvestorQuotesQuery,
      manual: true,

      variables() {
        return {
          proposalId: this.proposalId,
        }
      },

      result({ data }) {
        this.investorQuotes = orderBy(data?.investorQuotes?.objects, 'feeNumber', 'desc') || [];
      },
    },
  },

  data() {
    return {
      proposal: null,
      proposalError: null,
      proposalIsMutating: false,
      editModal: false,
      settlementModal: false,
      refinanceModal: false,
      duplicateProposalModal: false,
      investorQuotes: [],
      borrowerQuotes: [],
      investorQuotesIsMutating: false,
      borrowerQuotesIsMutating: false,
      settlementIsMutating: false,
      refinanceIsMutating: false,
      duplicateProposalIsMutating: false,
      rescheduleModalProps: {
        value: false,
        quote: null,
        type: null,
        requesting: false,
        balloonPayment: undefined,
      },
      breadcrumbItems: [
        { text: 'Préstamos', to: { name: 'investment.list' }, exact: true },
        { text: 'Préstamo', disabled: true }
      ],
    };
  },

  computed: {
    isPayable() {
      return this.proposal?.status === INVESTMENT_PROPOSAL_STATUS.COLLECTING;
    },

    currency() {
      return this.proposal?.deal?.currency || { symbol: '' };
    },

    proposalId() {
      return this.$route.params.proposalId;
    },

    parsedBorrowerQuotes() {
      return this.borrowerQuotes
        .map((quote,) => ({
          ...quote,
          canPay: this.isPayable &&
            quote.id === this.firstUnpaidBorrowerQuote?.id &&
            (this.proposal.trust || quote.feeNumber <= this.firstUnpaidInvestorQuote?.feeNumber),
          canReschedule: this.isPayable &&
            quote.id === this.firstUnpaidBorrowerQuote?.id &&
            (this.proposal.trust || quote.feeNumber === this.firstUnpaidInvestorQuote?.feeNumber)
        }));
    },

    parsedInvestorQuotes() {
      return this.investorQuotes
        .map((quote) => ({
          ...quote,
          canPay: this.isPayable
            && quote.id === this.firstUnpaidInvestorQuote?.id
            && this.quoteIsPaid(find(this.borrowerQuotes, { feeNumber: quote.feeNumber })),
          canReschedule: this.isPayable
            && quote.id === this.firstUnpaidInvestorQuote?.id
            && find(this.borrowerQuotes, { feeNumber: quote.feeNumber })?.status === QUOTE_STATUS.RESCHEDULED,
        }))
    },

    firstUnpaidBorrowerQuote() {
      return this.getFirstUnpaidQuote(this.borrowerQuotes);
    },

    firstUnpaidInvestorQuote() {
      return this.getFirstUnpaidQuote(this.investorQuotes);
    },

    lastPaidBorrowerQuote() {
      return this.getLastPaidQuote(this.borrowerQuotes);
    },

    lastPaidInvestorQuote() {
      return this.getLastPaidQuote(this.investorQuotes);
    },

    nextQuote() {
      return this.firstUnpaidBorrowerQuote?.currentData?.amountDue;
    },

    canEdit() {
      return [INVESTMENT_PROPOSAL_STATUS.PENDING, INVESTMENT_PROPOSAL_STATUS.ASSIGNED].includes(this.proposal?.status);
    },

    canUpdateSettlement() {
      return [INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED, INVESTMENT_PROPOSAL_STATUS.COLLECTING].includes(this.proposal?.status);
    },

    canRefinance() {
      return this.proposal?.status === INVESTMENT_PROPOSAL_STATUS.COLLECTING;
    },

    settlement() {
      return {
        date: this.proposal?.settlementDate,
        legalExpenses: this.proposal?.legalExpenses,
        collectingExpenses: this.proposal?.collectingExpenses,
        judicializedPenalty: this.proposal?.judicializedPenalty,
      };
    },

    refinance() {
      const pendingDetail = this.proposal?.pendingDetail;

      if(!pendingDetail) return null;

      return flow(
        JSON.parse,
        humps.camelizeKeys,
        details => mapValues(details, v => parseFloat(v)),
      )(pendingDetail);
    },

    duplicateProposal() {
      return this.proposal
    },
  },

  components: {
    BorrowerQuotesCard,
    EditFormModal,
    InvestorQuotesCard,
    LoadingWrapper,
    ProposalCard,
    RescheduleModal,
    InvestmentSettlementFormModal,
    InvestmentRefinanceFormModal,
    InvestmentDuplicateProposalFormModal,
  },

  methods: {
    getFirstUnpaidQuote(quotes) {
      return findLast(quotes, quote => !this.quoteIsPaid(quote));
    },

    getLastPaidQuote(quotes) {
      return find(quotes, quote => this.quoteIsPaid(quote));
    },

    quoteIsPaid(quote) {
      return [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED, QUOTE_STATUS.RESCHEDULED, QUOTE_STATUS.EXTENDED].includes(quote?.status);
    },

    onRetryProposalFetch() {
      this.proposalError = null;
      this.$apollo.queries.proposal.refetch();
    },

    onSubmitInvestorPayment({ data }) {
      this.handleUpdateMutation({
        mutation: UpdateInvestorQuoteMutation,

        variables: { ...data },

        requestingStateKey: 'investorQuotesIsMutating',
      }).then(() => {
        this.$apollo.queries.borrowerQuotes.refetch();
        this.$apollo.queries.investorQuotes.refetch();
        this.$refs.investorQuotesCard.closePaymentForm();
      });
    },

    onSubmitBorrowerPayment({ data }) {
      this.handleUpdateMutation({
        mutation: UpdateBorrowerQuoteMutation,

        variables: { ...data },

        requestingStateKey: 'borrowerQuotesIsMutating',
      }).then(() => {
        this.$apollo.queries.borrowerQuotes.refetch();
        this.$apollo.queries.investorQuotes.refetch();
        this.$refs.borrowerQuotesCard.closePaymentForm();
      });
    },

    onSubmitEditForm({ proposal }) {
      this.handleUpdateMutation({
        mutation: UpdateInvestmentProposalMutation,

        variables: {
          ...proposal,
        },

        query: {
          query: InvestmentShowProposalsQuery,

          variables: { id: this.proposalId },
        },

        updateQueryFn: ({ resultsData: { updateInvestmentProposal: { proposal: updatedProposal } } }) =>
          updatedProposal ? {
            investmentProposals: {
              objects: { $set: [updatedProposal] },
            }
          } : null,

        requestingStateKey: 'proposalIsMutating'
      }).then(() => this.editModal = false);
    },

    onClickRescheduleQuote({ quote, type }) {
      Object.assign(this.rescheduleModalProps, {
        value: true,
        quote,
        quoteType: type,
        balloonPayment: this.proposal.balloonPaymentType,
      });
    },

    onSubmitRescheduleForm({ balloonPct, quoteType, feeNumber }) {
      this.rescheduleModalProps.requesting = true;

      this.handleUpdateMutation({
        mutation: QUOTE_TYPE.BORROWER === quoteType ? RescheduleBorrowerFeeMutation : RescheduleInvestorFeeMutation,

        variables: {
          data: {
            investmentProposalId: this.proposalId,
            balloonPct,
            feeNumber,
          },
        },
      }).then(() => {
        this.rescheduleModalProps.value = false;

        setTimeout(() => {
          this.$apollo.queries.borrowerQuotes.refetch()
          this.$apollo.queries.investorQuotes.refetch()
        }, 1000);

      }).finally(() => {
        this.rescheduleModalProps.requesting = false;
      })
    },

    onSubmitSettlement({ settlement }) {
      this.handleUpdateMutation({
        mutation: UpdateSettlementAccountMutation,
        requestingStateKey: 'settlementIsMutating',

        variables: {
          data: {
            investmentProposalId: this.proposal.id,
            settlementDate: settlement.date,
            legalExpenses: settlement.legalExpenses,
            collectingExpenses: settlement.collectingExpenses,
            judicializedPenalty: settlement.judicializedPenalty,
          },
        },
      }).then(() => {
        this.$refs.borrowerQuotesCard.openStatement();
      }).finally(() => {
        this.settlementModal = false;
      });
    },

    onSubmitRefinance({ refinance }) {
      if(!window.confirm('¿Está seguro de que desea ampliar/refinanciar el préstamo?')) return;

      this.handleUpdateMutation({
        mutation: UpdateExtendRefinancedMutation,
        requestingStateKey: 'refinanceIsMutating',

        variables: {
          data: {
            investmentProposalId: this.proposal.id,
            ...omit(refinance, ['amortization']),
            date: moment().format('YYYY-MM-DD'),
          },
        },
      }).then(() => {
        this.$apollo.queries.proposal.refetch();
        this.$apollo.queries.borrowerQuotes.refetch();
        this.$apollo.queries.investorQuotes.refetch();
      }).finally(() => {
        this.refinanceModal = false;
      });
    },

    onClickUpdateSettlement() {
      this.settlementModal = true;
    },

    onClickRefinanceButton() {
      this.refinanceModal = true;
    },

    onClickDuplicateProposalButton() {
      this.duplicateProposalModal = true;
    },
    
    onSubmitDuplicateProposal({ duplicateProposal }) {
      if(!window.confirm('¿Está seguro de que desea duplicar el préstamo?')) return;

      this.handleUpdateMutation({
        mutation: DoDuplicateInvestmentProposal,
        requestingStateKey: 'duplicateProposalIsMutating',

        variables: {
          id: duplicateProposal.id,
          debtAmount: duplicateProposal.debtAmount,
          quotesQuantity: duplicateProposal.quotesQuantity,
          balloonPaymentType: duplicateProposal.balloonPaymentType || 0,
          borrowerRate: duplicateProposal.borrowerRate,
          firstPaymentDate: duplicateProposal.firstPaymentDate,
          defaultRate: duplicateProposal.defaultRate,
          penaltyFee: duplicateProposal.penaltyFee,
          typeStatus: duplicateProposal.typeStatus,

        },
      }).then(({ data: { duplicateInvestmentProposal: { investmentProposal } }}) => {
        const routeData = this.$router.resolve({ 
          name: 'investment.show', 
          params: { proposalId: investmentProposal.id }
        });
        window.open(routeData.href, '_blank');
      }).finally(() => {
        this.duplicateProposalModal = false;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-show-view
    $c: &

    &__container
      padding: 16px
      max-width: 1350px
      margin: 0 auto

    &__quotes
      display: flex
      overflow-x: auto
      margin: -16px
      padding: 16px

      &-item
        min-width: 380px

        &:first-child
          padding-right: 12px

        &:last-child
          padding-left: 12px

    &--trust
      #{$c}
        &__quotes
          justify-content: center

          &-item
            max-width: 800px


    +md
      &__quotes
        &-item
          flex: 1

    +lg
      &__container
        padding-top: 36px
</style>
