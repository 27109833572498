<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12)
          validation-provider(name="descripción" :rules="'required|min:6'" v-slot="{ errors }")
            rich-textarea(
              v-model="data.description"
              :error-messages="errors"
              label="Descripción de la operación"
              name="descripción"
              placeholder="Descripción de la operación..."
            )
        v-flex(xs12).d-flex.align-center.justify-end.py-2
          v-icon(color="black").mr-1 mdi-lightbulb-on
          b.mr-1 Destino del crédito:&nbsp;
          span {{ motive }}
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          :disabled="requesting || descriptionIsEmpty"
          type="submit"
          color="primary"
          text
        ).px-4 Enviar expediente
</template>

<script>
  import basicFormMixin from '@/components/forms/basic-form-mixin';
  import RichTextarea from '@/components/inputs/rich-textarea';
  import stripHTMLTags from '@/utils/strip-html-tags';
  import getProductMotive from '@/utils/get-product-motive';


  export default {
    name: 'create-risk-file-form',

    props: {
      deal: { type: Object },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'riskFile',
        veeValidation: true,

        mapPropsToData({ riskFile }) {
          return {
            description: riskFile?.description || undefined,
          };
        },
      }),
    ],

    components: {
      RichTextarea,
    },

    computed: {
      descriptionIsEmpty() {
        return stripHTMLTags(this.data.description).length < 5;
      },

      motive() {
        return getProductMotive(this.deal.product.slug, this.deal.motive);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>