<template lang="pug">
  div(:class="b()")
    div(:class="b('debt')" v-for="protest in protests" :key="protest.id")
      div(v-if="protest.recentExpirationDate") #[b Fecha de vencimiento: ] {{ protest.recentExpirationDate }}
      div(v-if="protest.clarified") #[b Clarificado: ] {{ protest.clarified }}
      div(v-if="protest.penAmount") #[b Monto en Soles: ] {{ protest.penAmount | formatCurrency }} ({{ protest.penQuantity }})
      div(v-if="protest.usdAmount") #[b Monto en Dólares: ] {{ protest.usdAmount | formatCurrency(undefined, { symbol: '$ '}) }} ({{ protest.usdQuantity }})
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="detail in protest.protestDebtDetails" :key="detail.id")
          div(v-if="detail.bnpCorrelative") #[b Correlativo BNP: ] {{ detail.bnpCorrelative }}
          div(v-if="detail.newsletterNumber") #[b Newsletter: ] {{ detail.newsletterNumber }}
          div(v-if="detail.documentType") #[b Tipo de documento: ] {{ detail.documentType }}
          div(v-if="detail.currency") #[b Moneda: ] {{ detail.currency }}
          div(v-if="detail.amount") #[b Monto: ] {{ detail.amount | formatNumber }}
          div(v-if="detail.issuerDocument") #[b Documento: ] {{ detail.issuerDocument }}
          div(v-if="detail.expirationDate") #[b Fecha de vencimiento: ] {{ detail.expirationDate | formatDate }}
          div(v-if="detail.clarificationDate") #[b Fecha de clarificación: ] {{ detail.clarificationDate | formatDate }}
          div(v-if="detail.notary") #[b Notaría: ] {{ detail.notary }}
</template>

<script>
  export default {
    name: 'protests-tab',

    block: 'debts-tab',

    props: {
      protests: { type: Array, default: () => [] },
    },
  };
</script>
