<template lang="pug">
  div(:class="b()")
    slot
</template>

<script>
  export default {
    name: 'lost-reason',
  };
</script>

<style lang="sass" scoped>
  .lost-reason
    background-color: #ffdede
    border: 2px solid red
    border-radius: 6px
    padding: .5rem
    color: #313131
</style>