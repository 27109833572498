<template lang="pug">
  div(:class="b()")
    div(:class="b('main-cards')")
      div(:class="b('main-card')")
        div(:class="b('main-card-title')") Deuda actual
        div(:class="b('main-card-content')") $ {{ currentDebt | formatNumber }}
      div(:class="b('main-card')")
        div(:class="b('main-card-title')") Deuda mensual
        div(:class="b('main-card-content')") $ {{ monthlyDebt | formatNumber }}
    div(:class="b('body')")
      div(:class="b('data')")
        div(:class="b('data-title')") Número de cuentas:&nbsp;
        span {{ results.creditos.length }}&nbsp;
        v-icon(v-if="results.creditos.length >= 5" color="#ffa629" :size="17") mdi-alert-circle
      div(:class="b('data')")
        div(:class="b('data-title')") Cuentas en buen estado:&nbsp;
        span.font-weight-medium.success--text {{ healthyCredits.length }}
      div(:class="b('data')")
        div(:class="b('data-title')") Cuentas en atraso:&nbsp;
        span.font-weight-medium.error--text {{ lateCredits.length }}
      div(:class="b('data')")
        div(:class="b('data-title')") Número de consultas totales en buró:&nbsp;
        span {{ results.consultas.length }}
      div(:class="b('data')")
        div(:class="b('data-title')") Años de historial:&nbsp;
        span {{ historyYears }}
    div(:class="b('credits-table')")
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'credits-summary',

    props: {
      results: { type: Object, required: true },
    },

    computed: {
      historyYears() {
        const oldestAccount = this.results.creditos.reduce(
          (oldest, { fechaAperturaCuenta }) => {
            if(!oldest) return moment(fechaAperturaCuenta);

            return moment(fechaAperturaCuenta).isSameOrBefore(oldest) ?
              moment(fechaAperturaCuenta) : oldest;
          },
          null
        );

        return oldestAccount ? `${moment().year() - oldestAccount.year()} años (${oldestAccount.year()})`: '';
      },

      creditsWithoutPreventionKey() {
        return this.results.creditos.filter(({ clavePrevencion }) => !clavePrevencion);
      },

      healthyCredits() {
        return this.creditsWithoutPreventionKey.filter(({ numeroPagosVencidos }) => numeroPagosVencidos === 0);
      },

      lateCredits() {
        return this.creditsWithoutPreventionKey.filter(({ numeroPagosVencidos }) => numeroPagosVencidos > 0);
      },

      currentDebt() {
        return this.creditsWithoutPreventionKey.reduce((acc, { saldoActual }) => acc + saldoActual, 0);
      },

      monthlyDebt() {
        return this.creditsWithoutPreventionKey.reduce((acc, { montoPagar }) => acc + montoPagar, 0);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .credits-summary
    &__title
      margin-bottom: 14px

      font-size: 18px
      font-weight: bold

    &__data
      display: flex
      align-items: center

    &__data-title
      font-weight: bold

    &__main-cards
      margin: -8px -8px 12px -8px

      +sm
        display: flex


    &__main-card
      margin: 8px
      min-width: 200px
      padding: 8px 14px
      border-radius: 6px
      background-color: darken(white, 3%)

      +lg
        min-width: 220px

      &-title
        font-weight: 500

      &-content
        font-size: 24px
        font-weight: bold
        text-align: center
</style>