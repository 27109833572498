<template lang="pug">
  div(:class="b()")
    .tax-report__title Ingresos Mensuales ({{ new Date().getFullYear() }})
    tax-report-incomes-by-month-chart(:incomes="currentDeclaredIncomes")
    template(v-if="yearlyIncomes.length")
      .tax-report__title Ingresos Anuales
      tax-report-incomes-by-year-chart(:incomes="yearlyIncomes")
</template>

<script>
import TaxReportIncomesByMonthChart from './tax-report-incomes-by-month-chart';
import TaxReportIncomesByYearChart from './tax-report-incomes-by-year-chart';

export default {
  name: 'tax-report-incomes-tab',

  props: {
    incomes: { type: Object },
  },

  computed: {
    currentDeclaredIncomes() {
      return (this.incomes?.declared || []).filter(({ year }) => year === new Date().getFullYear());
    },

    yearlyIncomes() {
      return (this.incomes?.declared || [])
        .filter(({ month, amount }) => month === 13 && amount && Number(amount) !== 0);
    },
  },

  components: { TaxReportIncomesByMonthChart, TaxReportIncomesByYearChart },
};
</script>

<style lang="sass" scoped>

</style>