<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      div(:class="b('name')")
        status-icon(:status="document.status" style="margin-right: 1rem")
        | {{ title }}
      div(:class="b('date')" style="font-weight: 500; margin-right: 1rem") Actualizado el {{ document.modifiedAt | formatDateTime }}
      template(v-if="gestionable")
        div(:class="b('status-select')")
          status-select(
            :value="document.status"
            :height="24"
            style="padding-top: 0"
            @input="onChangeStatus"
            label
            hide-details
          )
        div(:class="b('action-buttons')")
          upload-icon-input(
            :extensions="validExtensions"
            @change="onChangeAttachments"
            multiple
          )
          v-icon(
            v-if="canDeleteDocument"
            style="{ cursor:pointer; }"
            @click="onClickDelete"
          ) mdi-delete
    attachments(
      :attachments="document.attachments"
      :can-delete="gestionable && canDeleteAttachment"
      @delete="args => $emit('delete-attachment', args)"
    )
</template>

<script>
  import Config from '@/config';
  import { DOCUMENT_STATUS } from '@/utils/constants';
  import StatusIcon from '@/components/UI/document-status-icon';
  import StatusSelect from '@/components/inputs/document-status-select';
  import UploadIconInput from  '@/components/inputs/upload-icon-input';
  import Attachments from './attachments';

  export default {
    name: 'documents-row',

    props: {
      document: { type: Object, required: true },
      gestionable: { type: Boolean },
    },

    computed: {
      title() {
        return [
          this.document?.type?.description || this.document?.docType?.name,
          this.document?.description || this.document?.docType?.docDescription,
          ' - ' + this.document?.extra,
        ].join(' ');
      },

      canDeleteAttachment() {
        return this.document.status !== DOCUMENT_STATUS.APPROVED;
      },

      canDeleteDocument() {
        return this.canDeleteAttachment;
      },

      validExtensions() {
        return Config.DOCS_VALID_EXTENSIONS.find(({ ids }) => ids.includes(this.document.type.id))?.extensions;
      },
    },

    methods: {
      onChangeStatus(status) {
        this.$emit('update', {
          document: {
            ...this.document,
            status,
          },
        });
      },

      onChangeAttachments({ files }) {
        if(!files?.length) return;

        this.$emit('upload-attachments', { files, document: this.document });
      },

      onClickDelete() {
        if(window.confirm('¿Está seguro que desea eliminar el document?')) {
          this.$emit('delete', { id: this.document.id });
        }
      },
    },

    components: { StatusSelect, StatusIcon, UploadIconInput, Attachments },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .documents-row
    &__header
      display: flex
      flex-wrap: wrap
      margin-bottom: 1rem

    &__name
      width: 100%

      +sm
        width: auto
        flex: 1
    
    &__date
      width: 100%

      +sm
        width: auto
        flex: 1

    &__status-select
      padding-left: 40px
      width: 190px

      +sm
        width: 240px

    &__action-buttons
      padding-left: 4px

      & > *
        padding-left: 8px

</style>