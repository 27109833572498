<template lang="pug">
  div(:class="b()")
    span Código de Pago
    div(:class="b('code')" @click="onClickCode") {{ code }}
</template>

<script>
import copy from 'copy-to-clipboard';
import iziToast from 'izitoast';

export default {
  name: 'investment-proposals-detail-payment-code',

  props: {
    code: { type: String },
  },

  methods: {
    onClickCode() {
      copy(this.code);

      iziToast.success({ message: 'El código ha sido copiado al portapapeles' });
    },
  },
};
</script>

<style lang="sass" scoped>
  .investment-proposals-detail-payment-code
    display: flex
    align-items: center
    padding: 4px 4px 4px 16px
    background-color: $accentColor
    color: white
    font-weight: 500
    border-radius: 16px

    &__code
      padding: 2px 8px
      border-radius: 0px 16px 16px 0
      margin-left: 8px
      font-weight: bold

      background-color: white
      color: black
      cursor: pointer

      &:hover
        position: relative

        &:after
          content: 'Copiar'
          position: absolute
          top: 0
          left: 0
          right: 0
          bottom: 0
          background-color: darken(white, 80%)
          color: white
          display: flex
          align-items: center
          justify-content: center
          border-radius: 0px 16px 16px 0

</style>