<template lang="pug">
  quotes-card(
    :user="proposal.investor"
    label="Inversionista"
    :vehicle="proposal.investor"
    labelVehicle="Inversionista"
  )
    template(slot="header-data")
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ proposal.investorRate }} %
        .quotes-card__header-data-item-description Tasa
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ proposal.rtcCommissionRate }} %
        .quotes-card__header-data-item-description Comisión #[br] RebajaTusCuentas
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ proposal.irTaxRate }} %
        .quotes-card__header-data-item-description Impuesto #[br] a la renta
    template(slot="header-actions")
      v-tooltip(top)
        template(v-slot:activator="{ on }")
          v-btn(
            color="primary"
            small text
            v-on="on"
          )
            v-icon mdi-calculator-variant
            span.ml-1.hidden-xs-only ¿Cómo se calcula?
        div
          ul
            li Cuota Neta = Cuota Bruta - Imp Renta - IGV de comisión total
            li Comisión RTC sin IGV = Cuota prestatario - Cuota bruta.
      v-btn(
        :to="scheduleRoute"
        target="_blank"
        color="primary"
        small text
      )
        v-icon.hidden-touch mdi-calendar-multiselect
        span.ml-1 Cronograma
      v-btn(
        :disabled="fetching"
        :loading="fetching"
        color="primary"
        small text
        @click="$emit('refresh')"
      )
        v-icon mdi-refresh
        span.ml-1.hidden-xs-only Actualizar
    quotes(
      :quotes="quotes"
      :currency="currency"
      :fetching="fetching"
      :reschedulable-fee-numbers="reschedulableFeeNumbers"
      @register-payment="onClickRegisterPayment"
      @click-reschedule="onClickRescheduleQuote"
    )
    payment-form-modal(
      v-model="paymentFormModal"
      :default-values="paymentFormDefaultValues"
      :fetching="mutating"
      :disabled-fields="['amount']"
      @submit="onSubmitPaymentForm"
    )
</template>

<script>
  import { QUOTE_TYPE } from '@/utils/constants';
  import { encrypt } from '@/utils/url-params-crypter';
  import Quotes from './quotes';
  import BorrowerQuotesCard from '../borrower-quotes-card';

  export default {
    name: 'investor-quotes-card',

    extends: BorrowerQuotesCard,

    props: {
      reschedulableFeeNumbers: { type: Array, default: () => [] },
    },

    components: { Quotes },

    computed: {
      scheduleRoute() {
        return {
          name: 'mortgage_schedule.investment_investor',
          query: {
            _params: encrypt({
              investmentProposalId: this.proposal.id,
              proposalId: this.proposal.proposalId,
            }),
          },
        };
      },

      paymentFormRules() {
        if(!this.registeringQuote) return {};

        return {
          amount: { required: true, min_value: this.registeringQuote.currentData.netAmountDue, decimal: 2 },
        };
      },

      paymentFormDefaultValues() {
        return {
          quoteId: this.registeringQuote?.id,
          amount: this.registeringQuote?.currentData?.netAmountDue,
        };
      }
    },

    methods: {
      onClickRescheduleQuote({ quote }) {
        this.$emit('click-reschedule-quote', { quote, type: QUOTE_TYPE.INVESTOR });
      },
    },

  };
</script>

<style lang="sass" scoped>

</style>