<template lang="pug">
  table(:class="b()")
    quotes-table-head(
      :order-by="orderBy"
      :order-flow="orderFlow"
      :all-selected="areAllItemsSelected"
      @order="args => $emit('order', args)"
      @select-all="onSelectAllItems"
      @unselect-all="onUnselectAllItems"
    )
    tbody
      quotes-table-item(
        v-for="quote in quotes"
        :key="quote.id"
        :quote="quote"
        :selected="selectedIds.includes(quote.id)"
        @select="onSelectItem"
        @unselect="onUnselectItem"
      )
    tfoot
      tr
        td(colspan="4").font-weight-bold Total soles <br /> Total dólares
        td {{ totals.amountDueSol | formatCurrency }} <br /> {{ totals.amountDueUSD, getUSDCurrency() | formatCurrency }}
        td {{ totals.netAmountDueSol | formatCurrency }} <br /> {{ totals.netAmountDueUSD, getUSDCurrency() | formatCurrency }}
        td {{ totals.irTaxSol | formatCurrency }} <br /> {{ totals.irTaxUSD, getUSDCurrency() | formatCurrency }}
        td {{ totals.interestSol | formatCurrency }} <br /> {{ totals.interestUSD, getUSDCurrency() | formatCurrency }}
        td {{ totals.amortizationSol | formatCurrency }} <br /> {{ totals.amortizationUSD, getUSDCurrency() | formatCurrency }}
        td(colspan="3")
</template>

<script>
import $update from 'immutability-helper';
import selectableTableMixin from '@/mixins/selectable-table-mixin';
import config from '@/config/base';

import QuotesTableItem from './quotes-table-item';
import QuotesTableHead from './quotes-table-head';

export default {
  name: 'quotes-table',

  mixins: [selectableTableMixin({ itemsKey: 'quotes' })],

  props: {
    quotes: { type: Array, default: () => [] },
    orderBy: { type: String },
    orderFlow: { type: String },
  },

  computed: {
    totals() {
      return this.quotes
      .filter(quote => {
        return this.selectedIds.length ? this.selectedIds.includes(quote.id) : true;
      })
      .reduce(
        (acc, quote) => {
          if (`${quote.currencyId}` === config.CURRENCIES_SELECT[0].id) {
            return $update(acc, {
              amountDueSol: { $apply: val => val + quote.currentData.amountDue },
              amountDueUSD: { $apply: val => val },
              netAmountDueSol: { $apply: val => val + quote.currentData.netAmountDue },
              netAmountDueUSD: { $apply: val => val },
              irTaxSol: { $apply: val => val + quote.currentData.irTax },
              irTaxUSD: { $apply: val => val },
              interestSol: { $apply: val => val + quote.currentData.interest },
              interestUSD: { $apply: val => val },
              amortizationSol: { $apply: val => val + quote.currentData.amortization },
              amortizationUSD: { $apply: val => val },
            });
          }
          return $update(acc, {
            amountDueSol: { $apply: val => val },
            amountDueUSD: { $apply: val => val + quote.currentData.amountDue },
            netAmountDueSol: { $apply: val => val },
            netAmountDueUSD: { $apply: val => val + quote.currentData.netAmountDue },
            irTaxSol: { $apply: val => val },
            irTaxUSD: { $apply: val => val + quote.currentData.irTax },
            interestSol: { $apply: val => val },
            interestUSD: { $apply: val => val + quote.currentData.interest },
            amortizationSol: { $apply: val => val },
            amortizationUSD: { $apply: val => val + quote.currentData.amortization },
          });
        },
        {
          amountDueSol: 0,
          amountDueUSD: 0,
          netAmountDueSol: 0,
          netAmountDueUSD: 0,
          irTaxSol: 0,
          irTaxUSD: 0,
          interestSol: 0,
          interestUSD: 0,
          amortizationSol: 0,
          amortizationUSD: 0,
        }
      );
    },

    isAllSelected() {
      return this.quotes.length && this.selectedIds.length === this.quotes.length;
    },
  },

  methods: {
    getSolCurrency() { return config.CURRENCIES_SELECT[0] },
    getUSDCurrency() { return config.CURRENCIES_SELECT[1] },
  },

  components: { QuotesTableItem, QuotesTableHead },
};
</script>

<style lang="sass">
  .quotes-table
    width: 100%
    margin-bottom: 1rem

    border-collapse: collapse
    background-color: white
    text-align: center

    thead
      tr
        background-color: darken(white, 6%)
        th
          padding: 4px 8px

    tbody
      & > *
        border-bottom: 1px solid darken(white, 20%)

        cursor: pointer

    tfoot
      td
        background-color: darken(white, 1%)
        font-weight: 500
        padding: 8px 4px
</style>