<script>
  import { Line } from 'vue-chartjs'
  import { formatNumber } from '@/filters/accounting';
  import moment from 'moment';

  export default {
    name: 'historical-debts-chart',

    extends: Line,

    props: {
      historicalDebts: { type: Array, default: () => [] },
    },

    mounted() {
      const timelineHistoricalDebts = ([...this.historicalDebts]).reverse();

      const datasets = [
        {
          label: 'Deuda Total',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(188,80,144)',
          borderColor: 'rgb(188,80,144)',
          backgroundColor: 'rgba(188,80,144, 0.3)',
          data: timelineHistoricalDebts.map(({ totalDebt }) => Number(totalDebt)),
        },
        {
          label: 'Deuda Vigente',
          pointBorderWidth: 4,
          pointBackgroundColor: 'rgb(255,99,97)',
          borderColor: 'rgb(255,99,97)',
          backgroundColor: 'rgba(255,99,97, 0.3)',
          data: timelineHistoricalDebts.map(({ activeDebt }) => Number(activeDebt)),
        },
      ];

      /**
       * El timeout es debido a que cuando se hace el mounted el v-tab padre no le
       * ha dado la dimension correspondiente al div y chart.js no puede calcular
       * el height debido a la opcion maintainAspectRatio: false la cual considera
       * el alto del padre antes de hacer render
       */
      setTimeout(() => {
        this.renderChart({
          labels: timelineHistoricalDebts.map(({ period }) => moment(period, 'YYYYMM').format('MM/YYYY')),

          datasets,

        }, {
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  callback(value) {
                    return 'S/ ' + formatNumber(value);
                  },
                },
              },
            ],
          },

          tooltips: {
            callbacks: {
              label: function({ yLabel, datasetIndex }) {
                return `${datasets[datasetIndex].label}: S/ ${formatNumber(yLabel)}`;
              }
            },
          },
        });
      }, 300);
    }
  };
</script>