<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      div(:class="b('toolbar')" ref="toolbar")
        filters(
          :current-filters="filters"
          @add="onAddFilter"
          @remove="onRemoveFilter"
        )
      div(:class="b('body')")
        formalization-stage(
          v-for="stage in stages"
          :key="stage.id"
          :stage="stage"
          :loading="$apollo.queries[`stage_${stage.id}_formalizations`].loading"
          @load-more="onClickLoadMore"
        )
    v-btn(:class="b('fab')" fab color="primary" @click="onClickFab")
      v-icon mdi-filter-outline
</template>

<script>
import $ from 'jquery';
import $update from 'immutability-helper';

import { FormalizationsBoardQuery } from './graphql/queries.gql';
import FormalizationStage from './components/formalization-stage';
import Filters from './components/filters';
import { FORMALIZATION_STATUS, FORMALIZATION_STAGES } from '@/utils/constants';

const STAGES = [
  FORMALIZATION_STAGES.PREPARATION,
  FORMALIZATION_STAGES.BLOCKING,
  FORMALIZATION_STAGES.DISBURSEMENT,
  FORMALIZATION_STAGES.MORTGAGE_REGISTRATION,
  FORMALIZATION_STAGES.LOCKING,
];

export default {
  name: 'formalizations-board-view',

  data() {
    return {
      filters: [
        {
          key: 'status',
          value: FORMALIZATION_STATUS.ACTIVE,
          text: 'Activo',
        },
      ],

      stages: STAGES.map(stage => ({
        id: stage,
        name: stage,
        loading: false,
        formalizations: {
          page: 0,
          pages: 0,
          hasNext: false,
          objects: [],
        },
      })),
    };
  },

  methods: {
    fetchFormalizations(stage, index) {
      this.$apollo.addSmartQuery(`stage_${stage}_formalizations`, {
        query: FormalizationsBoardQuery,

        fetchPolicy: 'cache-and-network',

        manual: true,

        variables() {
          return {
            page: 1,
            stage,
            ...this.filters.reduce(
              (acc, filter) => ({ ...acc, [filter.key]: filter.value }),
              {},
            ),
          };
        },

        result({ data }) {
          if(!data) return;

          this.$set(this.stages[index], 'formalizations', data.formalizations);
        },
      })
    },

    async onClickLoadMore({ stage, nextPage }) {
      this.$apollo.queries[`stage_${stage}_formalizations`].fetchMore({
        variables: {
          page: nextPage,
          stage,
          ...this.filters,
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          return $update(previousResult, {
            formalizations: {
              page: { $set: fetchMoreResult.formalizations.page },
              hasNext: { $set: fetchMoreResult.formalizations.hasNext },
              objects: { $push: fetchMoreResult.formalizations.objects },
            },
          });
        },
      });
    },

    onAddFilter(filter) {
      this.filters.push(filter);
    },

    onRemoveFilter(key) {
      this.$delete(this.filters, this.filters.findIndex(filter => filter.key === key ));
    },

    onClickFab() {
      $(this.$refs.toolbar).slideToggle();
    },
  },

  created() {
    this.stages.map((stage, index) => {
      this.fetchFormalizations(stage.id, index)
    });
  },

  mounted() {
    if(window.innerWidth < 600) $(this.$refs.toolbar).hide();
  },

  components: { FormalizationStage, Filters },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .formalizations-board-view
    &__container
      display: flex
      flex-direction: column
      height: calc(100vh - 64px)

    &__body
      display: flex
      padding: 2rem 1rem
      height: 100%
      overflow-x: auto

    &__toolbar
      padding: 0 1rem 1rem 1rem
      display: flex
      align-items: center
      justify-content: flex-end
      flex-wrap: wrap
      background-color: white
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.08)

      .filters
        width: 100%

      +sm
        padding: 0 1rem

        .filters
          flex: 1

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +sm
        display: none

</style>