import { render, staticRenderFns } from "./quotes-item.vue?vue&type=template&id=3fa2590e&scoped=true&lang=pug"
import script from "./quotes-item.vue?vue&type=script&lang=js"
export * from "./quotes-item.vue?vue&type=script&lang=js"
import style0 from "./quotes-item.vue?vue&type=style&index=0&id=3fa2590e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa2590e",
  null
  
)

export default component.exports