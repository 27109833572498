<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).px-2
          currency-select(
            v-model="data.currency"
            return-object
          )
        v-flex(xs12).px-2
          validation-provider(name="valor del inmueble" :rules="rules.propertyValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.propertyValue"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Valor del inmueble"
              name="valor del inmueble"
              type="number"
            )
        v-flex(xs12).px-2
          validation-provider(name="monto del financiamiento" :rules="rules.creditAmount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.creditAmount"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Monto del financiamiento"
              name="monto del financiamiento"
              step="0.01"
              type="number"
            )

        v-flex(xs6).px-2
          validation-provider(name="tipo de cuota" :rules="rules.feeType" v-slot="{ errors }")
            fee-type-select(
              v-model="data.feeType"
              :error-messages="errors"
              label="Tipo de cuota"
              name="tipo de cuota"
            )
        v-flex(xs6).px-2
          validation-provider(name="plazo" :rules="rules.limitMonths" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.limitMonths"
              label="Plazo (meses)"
              name="plazo"
              :error-messages="errors"
              type="number"
            )
        v-flex(xs12).px-2
          validation-provider(name="costo de apertura personalizado (opcional)" :rules="rules.customArguments.openAmount" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.customArguments.openAmount"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              label="Costo de apertura personalizado (opcional)"
              name="costo de apertura personalizado (opcional)"
              step="0.01"
              type="number"
            )
        v-flex(xs6).px-2
          validation-provider(name="TEA personalizada" :rules="rules.customArguments.rate" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.customArguments.rate"
              :error-messages="errors"
              label="TEA personalizada"
              name="TEA personalizada"
              type="number"
              step="0.01"
              prefix="%"
            )
        v-flex(xs6).px-2
          validation-provider(name="capital al final del periodo" :rules="rules.customArguments.balloon" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.customArguments.balloon"
              :error-messages="errors"
              label="Capital al final del periodo"
              name="capital al final del periodo"
              type="number"
              step="0.01"
              prefix="%"
            )
        v-flex(xs12).px-2
          v-checkbox(v-model="data.propertyEndoser" label="¿Endosar seguro de inmueble?")
      v-card-actions
        v-spacer
        v-btn(
          :disabled="requesting"
          color="primary"
          type="submit"
          block
        ) Generar Propuestas
</template>

<script>
import basicFormMixin from '@/components/forms/basic-form-mixin';
import FeeTypeSelect from '@/components/inputs/fee-type-select';
import CurrencySelect from '@/components/inputs/currency-select';
import Config from '@/config';
import { FEE_TYPE } from '@/utils/constants';

export default {
  name: 'proposal-simulator-filters-form',

  props: {},

  mixins: [
    basicFormMixin({
      modelKey: 'filters',
      veeValidation: true,
      mapPropsToData() {
        return {
          currency: Config.CURRENCIES_SELECT[0],
          creditAmount: null,
          propertyValue: null,
          limitMonths: null,
          feeType: FEE_TYPE.SIMPLE,
          propertyEndoser: false,
          customArguments: {
            rate: null,
            balloon: null,
            openAmount: null,
          },
        };
      },
      parseSubmit() {
        return {
          filters: {
            ...this.data,
            currencyId: this.data.currency?.id,
            customArguments: {
              ...this.data.customArguments,
              balloon: this.data.customArguments.balloon || null,
              rate: this.data.customArguments.rate || null,
              openAmount: this.data.customArguments.openAmount || null,
            },
          },
        };
      },
    }),
  ],

  computed: {
    rules() {
      return Config.SIMULATOR_FILTERS_FORM_RULES({
        data: {
          currency: this.data.currency,
          propertyValue: this.data.propertyValue,
          customArguments: this.data.customArguments,
        },
      });
    },
  },

  components: {
    FeeTypeSelect,
    CurrencySelect,
  },
};
</script>

<style lang="sass" scoped>
.proposals-filters-form
  &__custom-box
    background-color: lighten($cloudyColor, 6%)
    padding: 8px
    border-radius: 6px

    &-title
      font-weight: 500
      padding-left: 4px
</style>
