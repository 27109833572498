import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { ActivityStatusSelectQuery } from './queries.gql';

export default {
  name: 'activity-select',

  mixins: [lazySelectMixin({ itemsKey: 'status' })],

  props: {
    activityId: { type: String },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
  },

  data() {
    return {
      status: [],
    };
  },

  apollo: {
    status: {
      query: ActivityStatusSelectQuery,

      variables() {
        return {
          activityId: this.activityId,
        };
      },

      skip() {
        return this.lazySkipQuery || !this.activityId;
      },
    },
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.status.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};
