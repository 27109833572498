<template lang="pug">
  v-card(:class="b()").ma-2.mb-3
    div(:class="b('header')")
      v-icon(v-if="icon" :class="b('icon')") {{ icon }}
      span {{ title }}
      span(v-if="count").ml-1 ({{ count }})
      v-spacer
      div(:class="b('actions-container')")
        slot(name="actions")
    div(:class="b('content')")
      slot
</template>

<script>
  export default {
    name: 'titled-card',

    props: {
      title: { type: String, required: true },
      count: { type: Number },
      icon: { type: String },
    },
  };
</script>

<style lang="sass" scoped>
  .titled-card
    &__header
      display: flex
      align-items: center
      margin-left: 12px
      margin-right: 12px
      height: 40px
      font-size: 14px
      font-weight: bold
      border-bottom: 1px solid darken(white, 10%)

    &__content
      padding: 8px 16px 16px 16px

    &__icon
      margin-right: .5rem

</style>