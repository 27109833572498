import { render, staticRenderFns } from "./show-risk-form-income-modal.vue?vue&type=template&id=4ea2a4dc&scoped=true&lang=pug"
import script from "./show-risk-form-income-modal.vue?vue&type=script&lang=js"
export * from "./show-risk-form-income-modal.vue?vue&type=script&lang=js"
import style0 from "./show-risk-form-income-modal.vue?vue&type=style&index=0&id=4ea2a4dc&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea2a4dc",
  null
  
)

export default component.exports