<script>
  import { Line } from 'vue-chartjs'
  import uniq from 'lodash/uniq';
  import uniqolor from 'uniqolor'
  // import Config from '@/config';
  import { formatNumber } from '@/filters/accounting';
  import moment from 'moment';

  export default {
    name: 'credit-cards-debt-chart',

    extends: Line,

    props: {
      cards: { type: Array, default: () => [] },
    },

    mounted() {
      const datasets = this.cards.map(card => {
        const { color } = uniqolor(card.bank, { format: 'rgb' });

        return {
          label: card.bank,
          pointBorderWidth: 4,
          pointBackgroundColor: color,
          borderColor: color,
          backgroundColor: color.slice(0, color.length - 1) + ',0.3)',
          data: ([...card.history]).reverse().map(({ amount, period }) => ({
            x: moment(period, 'YYYYMM').format('MM/YYYY'),
            y: Number(amount)
          })),
        };
      });

      /**
       * El timeout es debido a que cuando se hace el mounted el v-tab padre no le
       * ha dado la dimension correspondiente al div y chart.js no puede calcular
       * el height debido a la opcion maintainAspectRatio: false la cual considera
       * el alto del padre antes de hacer render
       */
      setTimeout(() => {
        this.renderChart({
          labels: uniq(this.cards.reduce(
            (result, { history }) => [...result, ...history.map(({ period }) => period)],
            []
          )).sort()
            .map(period => moment(period, 'YYYYMM').format('MM/YYYY')),

          datasets,
        }, {
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  callback(value) {
                    return 'S/ ' + formatNumber(value);
                  },
                },
              },
            ],
          },

          tooltips: {
            callbacks: {
              label: function({ yLabel, datasetIndex }) {
                return `${datasets[datasetIndex].label}: S/ ${formatNumber(yLabel)}`;
              },

              /**
               * Por alguna razon desconocida chart.js no muestra el label correcto en el tooltip
               * cuando la gráfica tiene multiples datasets y usa syntax de puntos por lo cual
               * forzamos el título del tooltip con los valores correctos
               */
              title: function(lines, { datasets }) {
                return datasets[lines[0].datasetIndex].data[lines[0].index].x;
              },
            },
          },
        });
      }, 300);
    }
  };
</script>