import DynamicFilters from '@/components/inputs/dynamic-filters';
import DatePicker from '@/components/inputs/input-date-picker';
import StageSelect from '@/components/inputs/deal-stage-select';
import RequirementStatusSelect from '@/components/inputs/requirement-status-select';
import RequirementSelect from '@/components/inputs/requirement-select';
import AdviserSelect from '@/components/inputs/adviser-select';
import StatusSelect from '@/components/inputs/deal-status-select';
import BankSelect from '@/components/inputs/bank-select';
import ProductSelect from '@/components/inputs/product-select';
import ActivityStatusSelect from '@/components/inputs/deal-activity-select';
import DealLostReasonSelect from '@/components/inputs/deal-lost-reason-select';
import VTextField from 'vuetify/lib/components/VTextField';

const FIELD_TYPES = [
  { key: 'name', name: 'Cliente', component: VTextField },
  { key: 'email', name: ' Correo', component: VTextField },
  {
    key: 'adviserId',
    name: 'Asesor',
    component: AdviserSelect,
    parseSubmit: adviser => ({
      text: `${adviser.firstName || ''} ${adviser.lastName || ''}`,
      value: adviser.id,
      meta: { adviser },
    }),
  },
  {
    key: 'productSlug',
    name: 'Producto',
    component: ProductSelect,
    parseSubmit: product => ({
      text: product.text,
      value: product.value,
      meta: { product },
    }),
  },
  {
    key: 'status',
    name: 'Estado',
    component: StatusSelect,
    parseSubmit: status => ({
      text: status.text,
      value: status.value,
    }),
  },
  {
    key: 'activityStatus',
    name: 'Actividad',
    component: ActivityStatusSelect,
    defaultValue: 'NULL',
    parseSubmit: status => ({
      text: status.text || 'Sin Actividad',
      value: status.value || 'NULL',
    }),
  },
  {
    key: 'lostReasonId',
    name: 'Motivo Perdida',
    component: DealLostReasonSelect,
    defaultValue: 'NULL',
    parseSubmit: reason => ({
      text: reason.reason,
      value: reason.id,
    }),
  },
  { key: 'vat', name: 'DNI', component: VTextField },
  { key: 'mobile', name: 'Celular', component: VTextField },
  { key: 'created', name: 'Creación', component: DatePicker },
  {
    key: 'stageId',
    name: 'Etapa',
    component: StageSelect,
    parseSubmit: stage => ({
      text: stage.name,
      value: stage.id,
      meta: { stage },
    }),
  },
  {
    key: 'requirementId',
    name: 'Requisito',
    component: RequirementSelect,
    parseSubmit: requirement => ({
      text: requirement.name,
      value: requirement.id,
      meta: { requirement },
    }),
  },
  {
    key: 'requirementStatusId',
    name: 'Estado Req.',
    component: RequirementStatusSelect,
    additionalProps() {
      /**
       * Si la seleccion actual es de requirement status colocamos los
       * status posibles para ese requirement
       */
      const requirement = this.currentFilters.find(
        ({ key }) => key === 'requirementId',
      )?.meta?.requirement;

      return requirement ? { requirementId: requirement.id } : {};
    },
    parseSubmit: status => ({
      text: status.name,
      value: status.id,
      meta: { status },
    }),
  },
  {
    key: 'bankId',
    name: 'Banco',
    component: BankSelect,
    parseSubmit: bank => ({
      text: bank.name,
      value: bank.id,
      meta: { bank },
    }),
  },
];

export default {
  name: 'filters',

  functional: true,

  render(h, context) {
    return h(
      DynamicFilters,
      {
        props: {
          ...context.props,
          fieldTypes: FIELD_TYPES,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
