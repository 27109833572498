<template lang="pug">
  div(:class="b()")
    div(:class="b('icon')")
      v-icon(:color="iconColor") {{ icon }}
    div(:class="b('body')")
      div(:class="b('title')") {{ title }}
      div(:class="b('description')") {{ description }}
</template>

<script>
  export default {
    name: 'requirements-item',

    props: {
      title: { type: String },
      description: { type: String },
      icon: { type: String, default: 'mdi-alert-circle' },
      iconColor: { type: String },
    },
  };
</script>

<style lang="sass" scoped>
  .requirements-item
    display: flex
    margin-bottom: 8px

    &__icon
      margin-right: 8px

    &__title
      font-size: 15px
      font-weight: 500

    &__description
      margin-top: 2px
      font-size: 14px
      color: darken(white, 70%)
</style>