<template lang="pug">
  div(:class="b()")
    div(:class="b('content')" v-show="open")
      slot
    div(:class="b('trigger')" @click="onClickTrigger")
      slot(name="trigger" v-bind:open="open") Ver más
</template>

<script>
export default {
  name: 'read-more',

  data() {
    return {
      open: false,
    };
  },

  methods: {
    onClickTrigger() {
      this.open = !this.open
    },
  },
};
</script>

<style lang="sass" scoped>

</style>