<template lang="pug">
    .sentinel-semaforo-resume.mt-8
        .row
            .col-md-5.col-sm-12
                p.text--secondary #[b Nota]: {{ resumen?.note }}
                p.text--secondary #[b Nro. bancos]: {{ resumen?.bank_qt }}
                p.text--secondary #[b Fecha de proceso]: {{ resumen?.procees_date | formatDate() }}
                p.text--secondary #[b Semaforo actual]: {{ getSemaforoText(resumen?.current_color) }}
                p.text--secondary #[b Semaforo previo]: {{ getSemaforoText(resumen?.last_color) }}
                p.text--secondary #[b Semaforo peor/mejor]: {{ getSemaforoText(resumen?.worst_color) }}
            .col-md-4.col-sm-6
                p.text--secondary #[b Deuda total]: {{ resumen?.total_debt | formatCurrency() }}
                p.text--secondary #[b Deuda tributaria]: {{ resumen?.tax_debt | formatCurrency() }}
                p.text--secondary #[b Deuda laboral]: {{ resumen?.labor_debt | formatCurrency() }}
                p.text--secondary #[b Deuda impaga]: {{ resumen?.unpaid_debt | formatCurrency() }}
                p.text--secondary #[b Deuda protestos]: {{ resumen?.protest_debt | formatCurrency() }}
                p.text--secondary #[b Deuda SBS]: {{ resumen?.sbs_debt | formatCurrency() }}
            .col-md-3.col-sm-4
                p.text--secondary #[b Calificativo]:
                ul
                    li(v-for='item in splitCalificativo(resumen?.qualification)' :key='item.key')
                        span.text--secondary #[b {{ item.key }}]: {{ item.value }}%

</template>

<script>
	export default {
		name: 'sentinel-semaforo-resume',

		props: {
			resumen: { type: Object, default: () => {} },
		},

		methods: {
            getSemaforoColor(value) {
                if (value === '4') {
                    return '#00ff00';
                } else if (value === '3'){
                    return '#808080';
                } else if (value === '2') {
                    return '#ffff00';
                } else if (value === '1') {
                    return '#ff0000';
                }
            },

            getSemaforoText(value) {
                if (value === '4') {
                    return 'Sin deudas vencidas';
                } else if (value === '3'){
                    return 'No registra información de deudas';
                } else if (value === '2') {
                    return 'Deudas con poco atraso*';
                } else if (value === '1') {
                    return 'Deudas con atraso significativo*';
                }
            },

            splitCalificativo(calificativo) {
                const calificativoArray = calificativo.trim().split('%');
                return calificativoArray.slice(0, calificativoArray.length - 1).map((item) => {
                    const [key, value] = [item.trim().slice(0, 3), item.trim().slice(3).trim()];
                    return { key, value };
                });
            },
        },

		computed: {},

		components: {},
	};
</script>

<style lang="sass" scoped>
    .sentinel-semaforo-resume
        display: flex
        flex-wrap: wrap
        justify-content: space-between

    .text--secondary
        padding: 0
        margin: 0
</style>
