<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
    div(:class="b('body')")
      loading-wrapper(
        v-if="$apollo.queries.formalization.loading || !formalization"
      )
      template(v-else)
        div(:class="b('content')")
          title-study-header(:formalization="formalization").mb-3
          title-study-content(:formalization="formalization")
        v-btn(
          :class="b('fab')"
          @click="onClickPrint"
          color="primary"
          fab
          x-large
        )
          v-icon mdi-printer
</template>

<script>
import { FormalizationTitleStudyQuery } from './graphql/queries.gql';

import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import TitleStudyHeader from './components/title-study-header';
import TitleStudyContent from './components/title-study-content';

export default {
  name: 'title-study-view',

  data() {
    return {
      formalization: null,
    };
  },

  apollo: {
    formalization: {
      query: FormalizationTitleStudyQuery,
      fetchPolicy: 'network-only',

      variables() {
        return {
          id: this.$route.params.formalizationId,
        };
      },

      result({ data }) {
        if(!data?.formalization)
          this.throwNotFound();
      },

      skip() {
        return !this.$route.params.formalizationId;
      },
    },
  },

  methods: {
    throwNotFound() {
      window.alert('El estudio de título al que intenta acceder no existe');

      this.$router.replace({ name: 'dashboard.index' });
    },

    onClickPrint() {
      window.print();
    },
  },

  mounted() {
    const { formalizatonId } = this.$route.params;

    if(!formalizatonId)
      return this.throwNotFound;
  },

  components: { LoadingWrapper, TitleStudyHeader, TitleStudyContent },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .title-study-view
    &__fab
      position: fixed
      right: 18px
      bottom: 18px

    &__content
      max-width: 900px
      margin: 0 auto
      padding: 24px

    @media print
      &__fab
        display: none

      &__content
        padding: 0

    +lg
      &__fab
        bottom: 42px
        right: 42px
</style>