<script>
import uniqolor from 'uniqolor';
import { formatCurrency } from '@/filters/accounting';
import { groupBy, pipe, get, reduce, mapValues } from 'lodash/fp';
import { Radar } from 'vue-chartjs';

export default {
  name: 'tax-report-yearly-third-chart',

  extends: Radar,

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {
    entriesByYearAndName() {
      return pipe(
        groupBy('year'),
        mapValues(groupBy('name')),
      )(this.entries);
    },

    entriesByName() {
      return groupBy('name')(this.entries);
    },

    labels() {
      return Object.keys(this.entriesByName);
    },

    years() {
      return Object.keys(this.entriesByYearAndName);
    },

    datasets() {
      return this.years.map((year) => {
        const { color } = uniqolor(year, { format: 'rgb' });

        return {
          label: year,
          pointBackgroundColor: color,
          borderColor: color,
          backgroundColor: color.slice(0, color.length - 1) + ',0.4)',
          data: this.labels.map((name) => {
            return pipe(
              get([year, name]),
              reduce((acc, { amount }) => Number(amount) + acc, 0),
            )(this.entriesByYearAndName);
          })
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,

        scale: {
          pointLabels: {
            fontSize: 15
          }
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            label: ({ value, datasetIndex }) => {
              return value > 0 ? [`${this.datasets[datasetIndex].label}: ${formatCurrency(value)}`] : false;
            },

            title: (entries) => {
              return this.labels[entries[0].index];
            },
          },
        },
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>