<template lang="pug">
  validation-observer(ref="form")
    div(:class="b()")
      div(
        v-for="item, idx in value"
        :class="b('group')"
        :key="item.id"
      )
        v-layout(wrap)
          v-flex(xs12 sm6 md2).px-1
            validation-provider(:name="`tipo de documento ${idx + 1}`" :rules="rules.vatType" v-slot="{ errors }")
              vat-type-select(
                :value="item.vatType"
                :error-messages="errors"
                :name="`tipo de documento ${idx + 1}`"
                label="Tipo de documento"
                @input="v => onInputField(idx, 'vatType', v)"
              )
          v-flex(xs12 sm6 md3).px-1
            validation-provider(:name="`número de documento ${idx + 1}`" :rules="rules.vat" v-slot="{ errors }")
              v-text-field(
                :value="item.vat"
                :error-messages="errors"
                :name="`número de documento ${idx + 1}`"
                label="Número de documento"
                @input="v => onInputField(idx, 'vat', v)"
              )
          v-flex(xs12 sm6 md4).px-1
            validation-provider(:name="`correo ${idx + 1}`" :rules="rules.email" v-slot="{ errors }")
              v-text-field(
                :value="item.email"
                :error-messages="errors"
                :name="`correo ${idx + 1}`"
                label="Correo"
                @input="v => onInputField(idx, 'email', v)"
              )
          v-flex(xs12 sm6 md3).px-1
            validation-provider(:name="`teléfono ${idx + 1}`" :rules="rules.mobile" v-slot="{ errors }")
              v-text-field(
                :value.number="item.mobile"
                :error-messages="errors"
                type="number"
                :name="`teléfono ${idx + 1}`"
                label="Teléfono"
                @input="v => onInputField(idx, 'mobile', v)"
              )
        div(:class="b('delete-button')")
          v-btn(
            v-if="value.length > 0"
            color="error"
            @click="deleteItem(idx)"
            text
            small
          ) Eliminar
      v-alert(v-if="errors && errors.length" color="error" type="error") {{ errors[0] }}
      .d-flex.justify-center
        v-btn(
          v-if="value.length < maxLength"
          @click="addItem"
          color="primary"
          text
        )
          v-icon mdi-plus
          span Agregar Referido
</template>

<script>
import dynamicEntriesInputMixin from '@/mixins/dynamic-entries-input-mixin';

import VatTypeSelect from '@/components/inputs/equifax-vat-type-select';


export default {
  name: 'referrals-list-input',

  data() {
    return {
      rules: {
        vat: { required: true },
        vatType: { required: true },
        email: { required: true, email: true },
        mobile: { required: true, numeric: true },
      },
    };
  },

  mixins: [
    dynamicEntriesInputMixin({
      itemDefaultValues: { vat: null, vatType: 'DNI', email: null, mobile: null },
    }),
  ],

  components: { VatTypeSelect },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .referrals-list-input
    +xs
      &__delete-button
        display: flex
        justify-content: flex-end

    +sm
      &__group
        display: flex
        align-items: center

    &__group
      padding: 12px 0
      border-bottom: 1px solid darken(white, 12%)
</style>
