<template lang="pug">
  div(:class="b()")
    template(v-for="proposal, i in proposals")
      proposal(
        :proposal="proposal"
        :can-approve="canApprove && !hasAcceptedProposal"
        :can-disapprove="canDisapprove && !proposal.blocked"
        :can-edit="!proposal.blocked"
        :can-activate="canActivate"
        :can-deactivate="canDeactivate"
        :show-schedule="showSchedule"
        @edit="({ proposal }) => onClickEdit(proposal)"
        @delete="args => $emit('delete', args)"
        @approve="args => $emit('approve', args)"
        @disapprove="args => $emit('disapprove', args)"
        @enable="args => $emit('enable', args)"
        @disable="args => $emit('disable', args)"
      )
      v-divider(v-if="i + 1 < proposals.length").my-2
    form-modal(
      v-model="formModal"
      :requesting="requesting"
      :proposal="editingEntity"
      @submit="onSubmit"
    )
</template>

<script>
  import gestionableList from '@/mixins/gestionable-list-mixin';
  import FormModal from '@/components/modals/proposal-form-modal';

  import Proposal from './proposals-item';

  export default {
    name: 'proposals',

    mixins: [
      gestionableList({
        entityName: 'proposal',
      }),
    ],

    props: {
      proposals: { type: Array, default: () => [] },
      requesting: { type: Boolean },
      canDisapprove: { type: Boolean, default: true },
      canApprove: { type: Boolean, default: true },
      canActivate: { type: Boolean, default: true },
      canDeactivate: { type: Boolean, default: true },
      showSchedule: { type: Boolean, default: true },
    },

    computed: {
      hasAcceptedProposal() {
        return this.proposals.some(cProposal => cProposal.acceptedAt);
      },
    },

    components: { Proposal, FormModal },

    methods: {
      openProposalEdit(proposalId) {
        const proposal = this.findProposalById(proposalId);

        if(!proposal) return;

        this.onClickEdit(proposal);
      },

      findProposalById(proposalId) {
        return this.proposals.find(({ id }) => id === proposalId);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>