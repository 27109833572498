<template lang="pug">
  table(:class="b()").buro-table
    tr(:class="b('head')")
      th(colspan="2") Datos Generales
    tr
      td Nomber comercial
      td {{ contributorInfo.commercialName }}
    tr
      td Fecha de inscripción
      td {{ contributorInfo.registrationDate }}
    tr
      td Fecha de inicio de actividades
      td {{ contributorInfo.activitiesStartDate }}
    tr
      td Estado del contribuyente
      td {{ contributorInfo.contributorState }}
    tr
      td Condición del contribuyente
      td {{ contributorInfo.contributorCondition }}
    tr
      td Domicilio fiscal
      td {{ contributorInfo.fiscalAddress }}
    tr
      td Profesión u Oficio
      td {{ contributorInfo.profession }}
    tr
      td Actividad de comercio exterior
      td {{ contributorInfo.foreignTradeActivity }}
    tr
      td Actividad económica
      td {{ contributorInfo.economicActivity }}
    tr
      td Actividad económica
      td {{ contributorInfo.economicActivity }}
    tr(:class="b('head')")
      th(colspan="2") Información sobre sistema de facturación y llevado de registros
    tr
      td Sistema de emisión de comprobante
      td {{ contributorInfo.voucherIssuanceSystem }}
    tr
      td Sistema de contabilidad
      td {{ contributorInfo.accountingSystem }}
    tr
      td Sistema de contabilidad
      td {{ contributorInfo.accountingSystem }}
    tr
      td Comprobantes de pago autorizados
      td {{ contributorInfo.authorizedPaymentVouchers }}
    tr
      td Sistema de emisión de electrónica
      td {{ contributorInfo.electronicEmissionSystem }}
    tr
      td Afiliado al PLE desde
      td {{ contributorInfo.affiliatedToPleSince }}
</template>

<script>
export default {
  name: 'tax-report-contributor-table',

  props: {
    contributorInfo: { type: Object },
  }
};
</script>

<style lang="sass" scoped>
  .tax-report-contributor-table
    &__head
      background-color: lighten($primaryColor, 17%)
      text-align: center
      color: white

      th
        text-align: center !important

    tr
      td
        border-bottom: 1px solid
        border-right: 1px solid
        border-color: darken(white, 7%)
        padding: 3px 8px

        &:first-child
          font-weight: 500

        &:last-child
          border-right: 0

</style>