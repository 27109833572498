<template lang="pug">
  div(:class="b()")
    div(:class="b('name')") {{ workplace.businessName }}
    div(:class="b('description')") {{ workplace.ciiuDescription}}
    div(:class="b('body')")
      div(:class="b('data')")
        b Trabajadores:&nbsp;
        span {{ workplace.workersNumber }}
      div(:class="b('data')")
        b Dependencia:&nbsp;
        span {{ workplace.dependency }}
      div(:class="b('data')")
        b Inicio de actividades:&nbsp;
        span {{ workplace.activitiesStart | formatDate }}
      div(:class="b('data')")
        b Tipo:&nbsp;
        span {{ workplace.taxpayerType }}
      div(:class="b('data')")
        b Status:&nbsp;
        span {{ workplace.taxpayerStatus }}
      div(:class="b('data')")
        b Condición:&nbsp;
        span {{ workplace.taxpayerCondition }}
      div(:class="b('data')")
        b CIIU:&nbsp;
        span {{ workplace.ciiuCode }}
      div(:class="b('data')")
        b Actividad:&nbsp;
        span {{ workplace.foreignTradeActivity }}
    div
      b Dirección:&nbsp;
      span {{ workplace.address }}
</template>

<script>
  export default {
    name: 'workplace-card',

    props: {
      workplace: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .workplace-card
    background-color: darken(white, 3%)
    padding: 16px
    margin-bottom: 4px

    &__name
      font-size: 22px

    &__description
      margin-bottom: 8px

    &__data
      margin-right: 16px

    +lg
      &__body
        display: flex
        flex-wrap: wrap

      &__data
        width: 30%
</style>