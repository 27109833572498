import { v4 as uuidv4 } from 'uuid';
import $update from 'immutability-helper';
import compact from 'lodash/compact';

export default ({
  itemDefaultValues = {},
} = {}) => ({
  props: {
    value: { type: Array, default: () => [] },
    minLength: { type: Number, default: 0 },
    maxLength: { type: Number, default: 10 },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    onInputField(idx, key, value) {
      this.$emit('input', $update(this.value, {
        [idx]: { [key]: { $set: value }},
      }));
    },

    addItem() {
      this.$emit('input', [
        ...this.value,
        { id: uuidv4(), ...itemDefaultValues },
      ]);
    },

    deleteItem(idx) {
      this.$emit('input', $update(this.value, { $splice: [[idx, 1]] }));
    },

    async validate() {
      if(this.disabled) return Promise.resolve();

      const success = await this.$refs.form.validate();

      if(success && !this.hasErrors) return Promise.resolve()
      else return Promise.reject(`${this.options.name} field is required`);
    },
  },

  computed: {
    errors() {
      return compact([
        this.value.length < this.minLength && `Se deben registrar al menos ${this.minLength} entradas`,
        this.value.length > this.maxLength && `El número máximo de registros es ${this.maxLength}`,
      ]);
    },

    hasErrors() {
      return Boolean(this.errors.length);
    },
  },
});