<template lang="pug">
  tfoot
    tr
      td Total
      td {{ totals.deals }}
      td {{ totals.pctTotalDeals }}
      td {{ totals.investment }}
      td {{ totals.costPerDeal }}
</template>

<script>
  export default {
    name: 'costs-per-deal-table-footer',

    props: {
      totals: { type: Object },
    },
  };
</script>

<style lang="sass" scoped>

</style>