<template lang="pug">
    .sentinel-credit-lines
        .card-container
            .card(v-for='creditLine in creditLines' :key='creditLine?.bank_name')
                .card-header
                    h3 {{ creditLine.inst }}
                .card-body
                    p.text--secondary #[b Línea Aprobada:] {{ creditLine?.approve_credit | formatCurrency }}
                    p.text--secondary #[b Línea No Utilizada:] {{ creditLine?.not_use_credit | formatCurrency }}
                    p.text--secondary #[b Línea Utilizada:] {{ creditLine?.use_credit | formatCurrency }}
                    p.text--secondary #[b Tipo de Crédito:] {{ creditLine?.credit_type }}
</template>

<script>
	export default {
		name: 'sentinel-credit-lines',
		props: {
			creditLines: {
				type: Array,
				required: true,
			},
		},
	};
</script>

<style lang="sass" scoped>
.sentinel-credit-lines
  margin-top: 20px

.card-container
  display: flex
  flex-wrap: wrap
  justify-content: space-around

.card
  margin: 10px
  border: 1px solid #ddd
  border-radius: 8px
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)

.card-header
  background-color: #f2f2f2
  padding: 10px
  border-bottom: 1px solid #ddd
  text-align: center

.card-body
  padding: 10px

  p
    margin: 0
    line-height: 1.5
</style>
