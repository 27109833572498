<template lang="pug">
  metrics-layout
    filters(
      :filters="filters"
      :requesting="$apollo.queries.tableBySource.loading"
      @submit="onSubmitFilters"
      slot="filters"
    )
    div
      loading-wrapper(v-if="$apollo.queries.tableBySource.loading || !tableBySource")
      .pa-2(v-else)
        table-by-source(
          :rows="tableBySource.rows"
          :totals="tableBySource.totals"
          :order-by="filters.orderBy"
          :order-flow="filters.orderFlow"
          @order="onOrder"
        )
</template>

<script>
  import moment from 'moment';
  import Config from '@/config';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import isEqual from 'lodash/isEqual';

  import MetricsLayout from '../components/metrics-layout';
  import TableBySource from './table-by-source';
  import Filters from './filters';
  import TableBySourceQuery from './graphql/queries.gql';

  export default {
    name: 'metrics-sources-view',

    data() {
      return {
        filters: {
          country: Config.COUNTRY_CODE.toUpperCase(),
          bankId: undefined,
          fromDate: moment().startOf('month').format('YYYY-MM-DD'),
          toDate: moment().format('YYYY-MM-DD'),
          product: undefined,
          source: undefined,
          medium: undefined,
          adviserEmail: undefined,
          campaign: undefined,
          orderBy: null,
          orderFlow: null,
        },
        tableBySource: null,
      };
    },

    apollo: {
      tableBySource: {
        query: TableBySourceQuery,
        fetchPolicy: 'network-only',

        variables() {
          return { ...this.filters };
        },
      },
    },

    components: {
      Filters,
      LoadingWrapper,
      TableBySource,
      MetricsLayout,
    },

    methods: {
      onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.tableBySource.refetch();
        }
        else
          Object.assign(this.filters, filters);
      },

      onOrder({ key, flow }) {
        this.filters.orderBy = key;
        this.filters.orderFlow = flow;
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>