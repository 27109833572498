import { render, staticRenderFns } from "./sentinel-over-due-details.vue?vue&type=template&id=493f0d80&scoped=true&lang=pug"
import script from "./sentinel-over-due-details.vue?vue&type=script&lang=js"
export * from "./sentinel-over-due-details.vue?vue&type=script&lang=js"
import style0 from "./sentinel-over-due-details.vue?vue&type=style&index=0&id=493f0d80&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493f0d80",
  null
  
)

export default component.exports