<template lang="pug">
  v-card(:class="b()")
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.bonsCount }}
      div(:class="b('item-title')") Bonos
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.monthsAvg }} meses
      div(:class="b('item-title')") Duración Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.issueAvg | formatCurrency }}
      div(:class="b('item-title')") Emisión Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.minRate }} %
      div(:class="b('item-title')") Tasa mínima
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.maxRate }} %
      div(:class="b('item-title')") Tasa máxima
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.rateAvg }} %
      div(:class="b('item-title')") Tasa Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.weightedRateAvg.toFixed(2) }} %
      div(:class="b('item-title')") Tasa Ponderada
</template>

<script>
export default {
  name: 'bonds-summary',

  props: {
    summary: { type: Object, required: true },
  },
};
</script>

<style lang="sass" scoped>
  .bonds-summary
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    padding: 8px 12px

    &__item
      text-align: center
      padding: 4px 6px

      &-amount
        font-size: 1.2em
        font-weight: bold
        color: darlen(white, 60%)
</style>