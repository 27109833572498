<template lang="pug">
  tag(:color="color") {{ $t(`contractStatus.${status}`) }}
</template>

<script>
import Tag from '@/components/UI/tag';
import { CONTRACT_STATUS } from '@/utils/constants';

const COLORS = {
  [CONTRACT_STATUS.BLOCKED]: '#ffc107',
  [CONTRACT_STATUS.EMITTED]: '#4caf50',
  [CONTRACT_STATUS.PENDING]: '#cc51ff',
  [CONTRACT_STATUS.OBSERVED]: '#f44336',
  [CONTRACT_STATUS.LOST]: '#f44336',
};

export default {
  name: 'contract-status-tag',

  props: {
    status: { type: String, required: true },
  },

  computed: {
    color() {
      return COLORS[this.status];
    },
  },

  components: { Tag },
};
</script>
