<template lang="pug">
  tr
    td {{ entry['#'] }}
    td {{ entry.expirationDate | formatDate('D/MM/YY') }}
    td {{ entry.initialBalance | formatNumber(currency) }}
    td {{ entry.amortization | formatNumber(currency) }}
    td(v-if="showColumn('interest')") {{ entry.interest | formatNumber(currency) }}
    td(v-if="showColumn('insurancesProperty')") {{ propertyInsurance | formatNumber(currency) }}
    td(v-if="showColumn('insurancesLife')") {{ lifeInsurances | formatNumber(currency) }}
    td(v-if="showColumn('defaultsAndPenalties')") {{ entry.defaultsAndPenalties | formatNumber(currency) }}
    td(v-if="showColumn('managementFee')") {{ entry.managementFee | formatNumber(currency) }}
    td(v-if="showColumn('fee')") {{ entry.fee | formatNumber(currency) }}
    td(v-if="showColumn('totalPaid')") {{ entry.totalPaid | formatNumber(currency) }}
    td(v-if="showColumn('totalAmortization')") {{ entry.totalAmortization | formatNumber(currency) }}
    td {{ entry.finalBalance | formatNumber(currency) }}
    td {{ entry.paymentDate | formatDate('D/MM/YY') }}
    td {{ $t(`quoteStatus.${entry.status.toUpperCase()}`).substring(0, 4).concat('.') }}
</template>

<script>
export default {
  name: 'borrower-statement-table-row',

  props: {
    entry: { type: Object },
    currency: { type: Object },
    totals: { type: Object },
  },
  computed: {
    propertyInsurance() {
      return this.entry.propertyInsurance * 1;
    },
    lifeInsurances() {
      return this.entry.lifeInsurance * 1;
    },
  },
  methods: {
    showColumn(columnName) {
      const value = this.totals[columnName];

      if (!value && value !== 0) return false;

      const numericValue = typeof value === 'string'
        ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
        : parseFloat(value);

      return Boolean(!isNaN(numericValue) && numericValue !== 0);
    },
  }
};

</script>

<style lang="sass" scoped>

</style>
