<template lang="pug">
  v-card(:class="b()")
    div(:class="b('table-container')")
      table(:class="b('table')").rtc-metrics-table
        table-head(:items="items")
        table-body(:items="items")
</template>

<script>

  import TableHead from './general-table-head';
  import TableBody from './general-table-body';

  export default {
    name: 'general-table',

    props: {
      items: { type: Array, default: () => [] },
    },

    components: { TableHead, TableBody },
  };
</script>

<style lang="sass">
</style>