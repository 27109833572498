<template lang="pug">
  form(@submit.prevent="onSubmit" :class="b()")
    v-layout(wrap)
      //- v-flex(xs12 md3 lg2)
      //-   investment-proposal-status-select(
      //-     v-model="data.status"
      //-     label="Estado"
      //-     clearable
      //-     multiple
      //-   )
      v-flex(xs12 md6 lg4)
        investor-select(
          v-model="data.investorIds"
          label="Inversionista"
          clearable
          multiple
          deletable-chips
        )
      v-flex(xs12 md6 lg4)
        vehicle-select(
          v-model="data.investmentVehicleIds"
          label="Vehículo"
          clearable
          multiple
          deletable-chips
        )
      v-flex(xs12 md4 lg3)
        date-picker(
          v-model="data.expirationDate"
          label="Fecha de vencimiento (Rango)"
          clearable
          range
        )
      v-flex.text-right.d-flex.justify-end.align-center
        v-btn(
          color="primary"
          :disabled="isDisabled"
          type="submit"
        ) Aplicar filtros
</template>

<script>
import basicFormMixin from '@/components/forms/basic-form-mixin'

import DatePicker from '@/components/inputs/input-date-picker';
import CountrySelect from '@/views/metrics/components/country-select';
import InvestorSelect from '@/components/inputs/investor-select';
import VehicleSelect from '@/components/inputs/vehicle-select';

import InvestmentProposalStatusSelect from '../../components/investment-proposal-status-select';

export default {
  name: 'filters',

  props: {
    filters: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'filters',
      mapPropsToData({ filters }) {
        return {
          country: filters?.country,
          status: filters?.status || [],
          investorIds: filters?.investorIds || [],
          investmentVehicleIds: filters?.investmentVehicleIds || [],
          expirationDate: filters?.expirationDate || [],
        };
      },
    }),
  ],

  components: {
    CountrySelect,
    DatePicker,
    InvestmentProposalStatusSelect,
    InvestorSelect,
    VehicleSelect,
  },
};
</script>

<style lang="sass" scoped>
  //.filters
</style>