<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 md6).px-1
          validation-provider(name="partida electrónica" :rules="rules.electronicRegistrationNumber" v-slot="{ errors }")
            v-text-field(
              v-model="data.electronicRegistrationNumber"
              :error-messages="errors"
              label="Partida electrónica"
              name="partida electrónica"
            )
        v-flex(xs12 md6).px-1
          validation-provider(name="ciudad partida electrónica" :rules="rules.electronicRegistrationNumberCity" v-slot="{ errors }")
            v-text-field(
              v-model="data.electronicRegistrationNumberCity"
              :error-messages="errors"
              label="Ciudad partida electrónica"
              name="ciudad partida electrónica"
            )
        v-flex(xs12).px-1
          lien-charge-lines-input(
            v-model="data.lienChargeLines"
            ref="liensChargesInput"
            name="cargas y gravamenes"
          )
        v-flex(xs12).px-1
          validation-provider(name="propietarios" :rules="rules.ownersIds" v-slot="{ errors }")
            user-select(
              v-model="data.ownersIds"
              v-bind="ownerSelectProps"
              :error-messages="errors"
              :default-items="ownerSelectDefaultItems"
              label="Propietarios"
              name="propietarios"
              multiple
              creatable
            )

        v-flex(xs12).px-1
          validation-provider(name="observaciones legales" :rules="rules.comments" v-slot="{ errors }")
            v-textarea(
              v-model="data.comments"
              :error-messages="errors"
              name="observaciones legales"
              label="Observaciones legales"
            )
        v-flex(xs12).px-1
          validation-provider(name="forma de adquisicion" :rules="rules.acquisition" v-slot="{ errors }")
            v-text-field(
              v-model="data.acquisition"
              :error-messages="errors"
              label="Forma de adquisición"
              name="Forma de adquisicion"
            )
      v-divider
      v-card-actions
        div(:class="b('buttons')")
          v-btn(
            :loading="requesting"
            color="error"
            type="button"
            text
            @click="onClickDenyButton"
          ).ml-2
            v-icon mdi-clipboard-remove
            span.ml-1 Rechazar
          v-btn(
            :loading="requesting"
            color="warning"
            type="button"
            text
            @click="onClickExceptionButton"
          ).ml-2
            v-icon mdi-clipboard-alert
            span.ml-1 Excepción
          v-btn(
            :loading="requesting"
            color="success"
            type="button"
            text
            @click="onClickApproveButton"
          ).ml-2
            v-icon mdi-clipboard-check
            span.ml-1 Aprobar
          v-btn(
            :loading="requesting"
            color="primary"
            type="submit"
          ).ml-2
            v-icon mdi-clipboard-search
            span.ml-1 Evaluar
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import uniqBy from 'lodash/uniqBy';
  import Config from '@/config';
  import pick from 'lodash/pick';
  import basicFormMixin from './basic-form-mixin';
  import { APPRAISAL_STATUS } from '@/utils/constants';

  import LienChargeLinesInput from '@/components/inputs/lien-charge-lines-input';
  import UserSelect from '@/components/inputs/user-select';

  export default {
    name: 'title-study-form',

    props: {
      potentialOwners: { type: Array, default: () => [] },
      adviserId: { type: String },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'titleStudy',
        veeValidation: true,
        mapPropsToData() {
          return {
            id: this.titleStudy?.id || null,
            status: undefined,
            lienChargeLines: (this.titleStudy?.lienChargeLines || [])
              .map((chargeLine) => ({
                id: uuidv4(),
                ...pick(chargeLine, ['lienCharge', 'settle', 'creditor', 'chargeAmount', 'currency']),
              })),
            comments: this.titleStudy?.comments || null,
            electronicRegistrationNumber: this.titleStudy?.electronicRegistrationNumber || null,
            electronicRegistrationNumberCity: this.titleStudy?.electronicRegistrationNumberCity || null,
            ownersIds: (this.titleStudy?.owners || []).map((owner) => owner.user.id),
            acquisition: this.titleStudy?.acquisition || null,
          };
        },

        parseSubmit() {
          const { lienChargeLines, ...data } = this.data;
          return {
            titleStudy: {
              ...data,
              lienChargeLines: lienChargeLines.map(({ lienCharge, creditor, currency, ...data  }) => ({
                lienChargeId: lienCharge.id,
                creditorId: creditor.id,
                currencyId: currency.id,
                ...pick(data, ['settle']),
                chargeAmount: parseFloat(data.chargeAmount),
              })),
            },
          };
        }
      }),
    ],

    methods: {
      async validate() {
        try {
          const success = await this.$refs.form.validate();

          await this.$refs.liensChargesInput.validate();

          if(!success) return Promise.reject();

          return Promise.resolve()
        } catch {
          return Promise.reject();
        }
      },

        onClickApproveButton() {
          this.data.status = APPRAISAL_STATUS.APPROVED;

          this.onSubmit();
        },

        onClickDenyButton() {
          this.data.status = APPRAISAL_STATUS.DENIED;

          this.onSubmit();
        },

        onClickExceptionButton() {
          this.data.status = APPRAISAL_STATUS.EXCEPTION;

          this.onSubmit();
        },
    },

    computed: {
      rules() {
        return Config.TITLE_STUDY_FORM_RULES({ data: this.data });
      },

      lienChargeSelectDefaultItems() {
        const { liensCharges = [] } = this.titleStudy || [];

        return liensCharges;
      },

      ownerSelectDefaultItems() {
        const owners = (this.titleStudy?.owners || [])
          .map(owner => ({ ...owner.user }));

        return uniqBy([...this.potentialOwners, ...owners], 'id');
      },

      ownerSelectProps() {
        return {
          creatable: true,
          createAdditionalVariables: {
            adviserId: this.adviserId,
            notificationsActive: false,
          },
          createHiddenFields: [
            'firstName',
            'lastName',
            'maternalLastName',
            'notificationsActive',
            'mobile',
            'phone',
          ],
        }
      },
    },

    components: {
      LienChargeLinesInput,
      UserSelect,
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .title-study-form
    &__buttons
      width: 100%
      & > *
        width: 100%
        margin-bottom: 4px

    +md
      &__buttons
        display: flex
        justify-content: flex-end

        & > *
          width: auto
          margin-bottom: 0
</style>
