import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';

const gestionableListMixin = (
  {
    entityName,
    deleteConfirmText = '¿Está seguro que desea eliminar el recurso?',
  } = {}
) => {
  if(!entityName) throw new Error('You must pass a entityName');

  return {
    props: {
      [entityName]: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        formModal: false,
        editingEntity: null,
      };
    },

    methods: {
      closeForm() {
        this.formModal = false;
      },

      onClickEdit(entity) {
        Object.assign(this, {
          editingEntity: entity,
          formModal: true,
        });
      },

      onClickAdd() {
        Object.assign(this, {
          editingEntity: null,
          formModal: true,
        });
      },

      onClickDelete(entity) {
        if(window.confirm(isFunction(deleteConfirmText) ? deleteConfirmText(entity) : deleteConfirmText)) {
          this.onDelete(entity);
        }
      },

      onSubmit(formData) {
        if(formData[entityName]?.id)
          this.$emit('update', formData);
        else
          this.$emit('create', { [entityName]: omit(formData[entityName], ['id']) });
      },

      onDelete(entity) {
        this.$emit('delete', { [entityName]: entity });
      }
    },
  };
};

export default gestionableListMixin;
