<template lang="pug">
  table(:class="b()")
    investment-proposals-table-head(
      :order-by="orderBy"
      :order-flow="orderFlow"
      :all-selected="areAllItemsSelected"
      @select-all="onSelectAllItems"
      @unselect-all="onUnselectAllItems"
      @order="args => $emit('order', args)"
    )
    tbody
      investment-proposals-table-item(
        v-for="investmentProposal in investmentProposals"
        :key="investmentProposal.id"
        :investmentProposal="investmentProposal"
        :selected="selectedIds.includes(investmentProposal.id)"
        @select="onSelectItem"
        @unselect="onUnselectItem"
      )
    tfoot
      tr
        td(colspan="6").font-weight-bold Total soles <br /> Total dólares
        td {{ currentTotals.pendingDebtSol | formatCurrency }} <br /> {{ currentTotals.pendingDebtUSD, getUSDCurrency() | formatCurrency }}
        td
        td {{ currentTotals.paidInterestSol | formatCurrency }} <br /> {{ currentTotals.paidInterestUSD, getUSDCurrency() | formatCurrency }}
        td {{ currentTotals.expectedInterestSol | formatCurrency }} <br /> {{ currentTotals.expectedInterestUSD, getUSDCurrency() | formatCurrency }}
        td {{ currentTotals.defaultAndPenaltiesSol | formatCurrency }} <br />  {{ currentTotals.defaultAndPenaltiesUSD, getUSDCurrency() | formatCurrency }}
        td(colspan="3")
</template>

<script>
import $update from 'immutability-helper';
import InvestmentProposalsTableItem from './investment-proposals-table-item';
import InvestmentProposalsTableHead from './investment-proposals-table-head';
import selectableTableMixin from '@/mixins/selectable-table-mixin';
import config from '@/config/base';

export default {
  name: 'investment-proposals-table',

  mixins: [selectableTableMixin({ itemsKey: 'investmentProposals' })],

  props: {
    investmentProposals: { type: Array, default: () => [] },
    totals: { type: Object, required: true },
    orderBy: { type: String },
    orderFlow: { type: String },
  },

  computed: {
    currentTotals() {
      return this.investmentProposals
      .filter(investmentProposal => {
        return this.selectedIds.length ? this.selectedIds.includes(investmentProposal.id) : true;
      })
      .reduce(
        (acc, investmentProposal) => {
          if (`${investmentProposal.currency.id}` === config.CURRENCIES_SELECT[0].id) {
            return $update(acc, {
              pendingDebtSol: { $apply: val => val + investmentProposal.pendingDebtBorrower },
              pendingDebtUSD: { $apply: val => val },
              paidInterestSol: { $apply: val => val + investmentProposal.totalPaidInterest },
              paidInterestUSD: { $apply: val =>  val },
              expectedInterestSol: { $apply: val => val + investmentProposal.totalExpectedInterest },
              expectedInterestUSD: { $apply: val => val },
              defaultAndPenaltiesSol: { $apply: val => val + investmentProposal.totalDefaultAndPenalties },
              defaultAndPenaltiesUSD: { $apply: val => val },
            });
          }
          return $update(acc, {
            pendingDebtSol: { $apply: val => val },
            pendingDebtUSD: { $apply: val => val + investmentProposal.pendingDebtBorrower },
            paidInterestSol: { $apply: val => val },
            paidInterestUSD: { $apply: val => val + investmentProposal.totalPaidInterest },
            expectedInterestSol: { $apply: val => val },
            expectedInterestUSD: { $apply: val => val + investmentProposal.totalExpectedInterest },
            defaultAndPenaltiesSol: { $apply: val => val },
            defaultAndPenaltiesUSD: { $apply: val => val + investmentProposal.totalDefaultAndPenalties },
          });
        },
        {
          pendingDebtSol: 0,
          pendingDebtUSD: 0,
          paidInterestSol: 0,
          paidInterestUSD: 0,
          expectedInterestSol: 0,
          expectedInterestUSD: 0,
          defaultAndPenaltiesSol: 0,
          defaultAndPenaltiesUSD: 0,
        }
      );
    },
  },

  methods: {
    getSolCurrency() { return config.CURRENCIES_SELECT[0] },
    getUSDCurrency() { return config.CURRENCIES_SELECT[1] },
  },

  components: { InvestmentProposalsTableItem, InvestmentProposalsTableHead },
};
</script>

<style lang="sass">
  .investment-proposals-table
    width: 100%
    margin-bottom: 1rem

    border-collapse: collapse
    background-color: white
    text-align: center

    thead
      tr
        background-color: darken(white, 6%)
        th
          padding: 4px 8px

    tbody
      & > *
        border-bottom: 1px solid darken(white, 20%)

        cursor: pointer

    tfoot
      td
        background-color: darken(white, 1%)
        font-weight: 500
        padding: 8px 4px
</style>