import { render, staticRenderFns } from "./formalizations-board-view.vue?vue&type=template&id=2b090c51&scoped=true&lang=pug"
import script from "./formalizations-board-view.vue?vue&type=script&lang=js"
export * from "./formalizations-board-view.vue?vue&type=script&lang=js"
import style0 from "./formalizations-board-view.vue?vue&type=style&index=0&id=2b090c51&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b090c51",
  null
  
)

export default component.exports