<template lang="pug">
  div(:class="b({ [deal.activityStatus]: true })")
    div(:class="b('customer-name')") {{ customerName }}
    div(:class="b('product')") {{ $t(`productSlug.${this.deal.product.slug}`) }}
    div(:class="b('date')")
      span.font-weight-medium Última actividad:
      span  {{ lastActivity }}
    div(:class="b('credit')" v-if="deal.credit") {{ deal.credit | formatCurrency(deal.currency) }}
    div(:class="b('footer')")
      div(:class="b('adviser')")
        user-avatar(v-if="adviser" :first-name="adviser.firstName" :last-name="adviser.lastName" :url="adviser.avatar" :size="24")
      div(:class="b('tags')")
        tag(v-if="deal.product.slug === 'buy_mortgage' && !mortgageInscribed" color="#9c8ade") no inscrita
        status-tag(:status="deal.status")
    div(:class="b('date')").text-right #[span.font-weight-medium Creación]: {{ deal.createdAt | formatDate }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';
  import StatusTag from '@/components/UI/deal-status-tag';
  import Tag from '@/components/UI/tag';
  import formatDate from '@/filters/formatDate';


  export default {
    name: 'deal-card',

    props: {
      deal: { type: Object, required: true },
    },

    computed: {
      customerName() {
        return `${this.deal?.customer?.firstName || ''} ${this.deal?.customer?.lastName || ''}`;
      },

      mortgageInscribed() {
        return this.deal?.customer?.mortgageInscribed;
      },

      adviser() {
        return this.deal?.customer?.adviser;
      },

      lastActivity() {
        return this.deal?.lastActivityAt ? formatDate(this.deal?.lastActivityAt, 'D MMM YYYY hh:mm') : 'No registrada';
      },
    },

    components: {
      UserAvatar,
      StatusTag,
      Tag,
    },
  };
</script>

<style lang="sass" scoped>
  .deal-card
    display: block
    width: 100%
    margin: .5rem .75rem
    padding: 1rem
    background-color: white
    color: inherit
    text-decoration: none
    font-size: 12px
    user-select: none
    touch-callout: none
    color: black

    &__customer-name
      font-size: 1.1rem

    &__product
      font-weight: 500

    &__credit
      font-size: 1.2rem
      font-weight: 500
      text-align: right

    &__footer
      display: flex
      margin-top: .75rem

    &__tags
      flex: 1

      display: flex
      justify-content: flex-end

      & > *
        margin: 0 2px

</style>