<template lang="pug">
  table(:class="b()")
    table-head
    tbody
      table-row(
        v-for="quote in quotes"
        :key="quote.id"
        :quote="quote"
        :currency="currency"
      )
</template>

<script>
import TableHead from './investment-proposals-detail-quotes-table-head';
import TableRow from './investment-proposals-detail-quotes-table-row';

export default {
  name: 'investment-proposals-detail-quotes-table',

  props: {
    quotes: { type: Array, default: () => [] },
    currency: { type: Object, required: true },
  },

  components: { TableHead, TableRow },
};
</script>

<style lang="sass">
  .investment-proposals-detail-quotes-table
    width: 100%
    font-size: 14px

    td, th
      padding: 8px 6px

    tr
      td
        border-bottom: 1px solid darken(white, 8%)

      &:hover
        td
          background-color: transparentize($primaryColor, 0.96)

      &:last-child
        td
          border-bottom: 0
</style>