<template lang="pug">
  tbody
    forecast-table-body-row(
      v-for="entry in entries"
      :key="entry.date"
      :entry="entry"
    )
</template>

<script>
import ForecastTableBodyRow from './forecast-table-body-row';

export default {
  name: 'forecast-table-body',

  props: {
    entries: { type: Array, required: true },
  },

  components: { ForecastTableBodyRow },
};
</script>

<style lang="sass" scoped>
  //.forecast-table-body
</style>