import { render, staticRenderFns } from "./referral-form.vue?vue&type=template&id=6f7a2218&scoped=true&lang=pug"
import script from "./referral-form.vue?vue&type=script&lang=js"
export * from "./referral-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7a2218",
  null
  
)

export default component.exports