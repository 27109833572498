<template lang="pug">
  v-icon(:style="styles") {{ iconName }}
</template>

<script>
  import getStatusColor from '@/utils/get-status-color';

  const STATUS_ICONS = {
    PENDING: 'mdi-progress-upload',
    UNDER_REVIEW: 'mdi-progress-check',
    APPROVED: 'mdi-check-all',
    REFUSED: 'mdi-cancel',
  };

  export default {
    name: 'document-status-icon',

    props: {
      status: { type: String, required: true },
    },

    computed: {
      iconName() {
        return STATUS_ICONS[this.status.toUpperCase()];
      },

      styles() {
        return {
          color: getStatusColor(this.status),
        };
      },
    },
  };
</script>

