<template lang="pug">
  div(:class="b()")
    div(:class="b('debt')" v-for="omission in omissions" :key="omission.id")
      div(:class="b('debt-entries-item')" v-for="detail in omission.omissionDetail" :key="detail.id")
        div(v-if="detail.date") #[b Fecha: ] {{ detail.date | formatDate }}
        div(v-if="detail.concept") #[b Concepto: ] {{ detail.concept }}
        div(v-if="detail.processDate") #[b Fecha de procesamiento: ] {{ detail.processDate | formatDate }}
</template>

<script>
  export default {
    name: 'omissions-tab',

    block: 'debts-tab',

    props: {
      omissions: { type: Array, default: () => [] },
    },
  };
</script>
