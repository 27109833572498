<template lang="pug">
  div(:class="b()")
    div(:class="b('text', { error })") {{ label }}
    v-radio-group(:value="value" @change="args => $emit('input', args)" :class="b('radios')" row hide-details)
      v-radio(label="Si" :value="true" hide-details)
      v-radio(label="No" :value="false" hide-details)
</template>

<script>
export default {
  name: 'yes-or-no-radio',

  props: {
    value: { type: Boolean },
    label: { type: String, required: true },
    error: { type: Boolean, default: false }
  },
};
</script>

<style lang="sass" scoped>
  .yes-or-no-radio
    display: flex
    align-items: center
    padding: 9px 0

    &:hover
      background-color: transparentize($primaryColor, 0.94)

    &__text
      color: rgba(0,0,0,0.6)
      font-size: 16px
      padding-left: 4px
      flex: 1

      &--error
        color: red

    &__radios
      margin-top: 0
      padding-top: 0
</style>