import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { PoliticalDivisionsSelectQuery } from './queries.gql';

export default {
  name: 'location-select',

  mixins: [
    lazySelectMixin({ itemsKey: 'items' })
  ],

  apollo: {
    items: {
      query: PoliticalDivisionsSelectQuery,

      variables() {
        return {
          parentId: this.parentId,
        };
      },

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  props: {
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
    value: { type: String },
    parentId: { type: String },
  },

  data() {
    return {
      items: [],
      search: null,
    };
  },

  methods: {
    onFocus() {
      this.focused = true;
      this.search = '';
    },
  },

  watch: {
    value(newVal) {
      /**
        * Eso es debido a que si componante padre quiere borrar la locations elecionada
        * mandando el value como undefined o null el search mantiene su valor por lo cual
        * pareciera que estuviese seleccionado
        */
      if(newVal === undefined && this.search) {
        this.search = null;
      }
    },
  },

  render(h) {
    return h(
      VAutocomplete,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          searchInput: this.search,
          items: this.lazyItems,
          loading: this.$apollo.queries.items.loading,
        },
        on: {
          ...this.$listeners,
          'update:search-input': value => this.search = value,
          focus: this.onFocus,
        }
      }
    );
  },
};
