<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="!reminders || !reminders.objects"
      style="padding-top: 4rem; padding-bottom: 4rem"
    )
    template(v-else)
      template(v-if="reminders.objects.length")
        .d-flex.justify-end
          v-btn(color="primary" text @click="onClickAdd")
            v-icon mdi-alarm-plus
            span.ml-2 Agregar Nuevo
        reminders-list(
          :reminders="reminders.objects"
          @complete="args => $emit('complete', args)"
          @delete="args => $emit('delete', args)"
        )
        v-layout(justify-center v-if="reminders.pages > 1")
          v-pagination(
            :value="reminders.page"
            :length="reminders.pages"
            :disabled="loading"
            @input="args => $emit('change-page', args)"
          )
      v-alert(v-else color="primary" :value="true" outlined)
        .text-center No hay recordatorios pendientes
        .d-flex.justify-center.mt-2
          v-btn(color="primary" @click="onClickAdd")
            v-icon mdi-alarm-plus
            span.ml-2 Agregar

</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import RemindersList from './reminders-list';

  export default {
    name: 'reminders',

    props: {
      reminders: { type: Object, required: true },
      loading: { type: Boolean },
    },

    methods: {
      onClickAdd() {
        this.$emit('click-add');
      },
    },

    components: {
      LoadingWrapper,
      RemindersList,
    },
  };
</script>

<style lang="sass" scoped>

</style>