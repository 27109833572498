<template lang="pug">
  div(:class="b()")
    validation-observer(ref="form")
      form(@submit.prevent="onSubmit")
        template(v-if="showConfirmFields")
          v-text-field(
            :value="customerFullName"
            label="Nombre del cliente"
            readonly disabled
          )

          v-text-field(
            :value="customer && customer.vat"
            label="DNI"
            readonly disabled
          )

          product-select(
            :value="productSlug"
            label="Producto"
            disabled
          )

        validation-provider(name="inversionista" :rules="'required'" v-slot="{ errors }")
          investor-select(
            ref="investorSelect"
            v-model="data.investorId"
            :error-messages="errors"
            label="Inversionista"
            name="inversionista"
          )
        validation-provider(
          v-if="renderField('propertyValue')"
          name="valor comercíal propiedad"
          :rules="rules.propertyValue"
          v-slot="{ errors }"
        )
          v-text-field(
            v-model.number="data.propertyValue"
            :error-messages="errors"
            :prefix="data.propertyCurrency.symbol"
            label="Valor comercial de la propiedad"
            name="valor comercíal propiedad"
            type="number"
          )
        validation-provider(
          v-if="renderField('propertyAuctionValue')"
          name="valor de realización propiedad"
          :rules="rules.propertyAuctionValue"
          v-slot="{ errors }"
        )
          v-text-field(
            v-model.number="data.propertyAuctionValue"
            :error-messages="errors"
            :prefix="data.propertyCurrency.symbol"
            label="Valor de realización de la propiedad"
            name="valor de realización propiedad"
            type="number"
          )
        validation-provider(name="fecha de desembolso" :rules="rules.disbursementDate" v-slot="{ errors }")
          date-picker(
            v-model="data.disbursementDate"
            :error-messages="errors"
            label="Fecha de desembolso"
            name="fecha de desembolso"
          )
        validation-provider(name="fecha de primer pago" :rules="rules.firstPaymentDate" v-slot="{ errors }")
          date-picker(
            v-model="data.firstPaymentDate"
            v-bind="inputsProps.firstPaymentDate"
            :error-messages="errors"
            :allowed-dates="firstPaymentAllowedDates"
            label="Fecha de primer pago"
            name="fecha de primer pago"
          )
        v-layout(wrap style="margin: 0 -8px")
          v-flex(xs6).px-2
            validation-provider(name="comisión RebajaTusCuentas" :rules="'required'" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.rtcCommissionRate"
                :error-messages="errors"
                name="comisión RebajaTusCuentas"
                label="Comisión rebajatuscuentas"
                type="number"
                append-icon="mdi-percent"
              )
          v-flex(xs6).px-2
            validation-provider(name="impuesto a la renta" :rules="'required'" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.irTaxRate"
                :error-messages="errors"
                name="impuesto a la renta"
                label="Impuesto a la renta"
                type="number"
                append-icon="mdi-percent"
              )
          v-flex(xs6).px-2
            validation-provider(name="tasa moratoria anual" :rules="'required|min_value:0|max_value:20|decimal:2'" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.defaultRate"
                :error-messages="errors"
                name="tasa moratoria anual"
                label="Tasa moratoria anual"
                type="number"
                step="0.01"
                append-icon="mdi-percent"
              )
          v-flex(xs6).px-2
            validation-provider(name="penalidad diaria" :rules="'required|min_value:0|max_value:50|decimal:2'" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.penaltyFee"
                :error-messages="errors"
                :prefix="data.propertyCurrency.symbol"
                step="0.01"
                name="penalidad diaria"
                label="Penalidad diaria"
                type="number"
              )
        slot(name="actions" v-bind="{ requesting, isDisabled }")
          v-divider
          v-card-actions
            v-spacer
            v-btn(color="error" text @click="onCancel") Cancelar
            v-btn(
              :loading="requesting"
              :disable="isDisabled"
              color="primary"
              text
              type="submit"
            ) Guardar
</template>

<script>
import Config from '@/config';
import basicFormMixin from './basic-form-mixin';
import moment from 'moment';
import ProductSelect from '@/components/inputs/product-select';
import InvestorSelect from '@/components/inputs/investor-select';
import DatePicker from '@/components/inputs/input-date-picker';

const LOCAL_CURRENCY_PENALTY_FEE = process.env.VUE_APP_PENALTY_FEEE || 10;
const defaultRate = process.env.VUE_APP_DEFAULT_RATE || 13;

export default {
  name: 'investment-form-view',

  mixins: [
    basicFormMixin({
      modelKey: 'investmentProposal',
      veeValidation: true,

      mapPropsToData() {
        return {
          propertyValue: 0,
          propertyAuctionValue: 0,
          propertyCurrency: Config.CURRENCIES_SELECT[0],
          firstPaymentDate: null,
          disbursementDate: null,
          investorId: null,
          rtcCommissionRate: 3,
          irTaxRate: 5,
          penaltyFee: LOCAL_CURRENCY_PENALTY_FEE,
          defaultRate: defaultRate,
        };
      },

      parseSubmit() {
        return {
          investmentProposal: {
            ...this.data,
            propertyCurrencyId: this.data.propertyCurrency.id,
            propertyValue: this.data.propertyValue || 0,
            propertyAuctionValue: this.data.propertyAuctionValue || 0,
            rtcCommissionRate: this.data.rtcCommissionRate || 0,
            irTaxRate: this.data.irTaxRate || 0,
            defaultRate: this.data.defaultRate || 0,
            penaltyFee: this.data.penaltyFee || 0,
          },
        };
      },
    }),
  ],

  props: {
    customer: { type: Object },
    productSlug: { type: String },
    propertyValue: { type: Object },
    showConfirmFields: { type: Boolean, default: false },
  },

  computed: {
    customerFullName() {
      if (!this.customer) return;

      const { firstName, lastName } = this.customer;

      return [firstName, lastName].join(' ');
    },

    rules() {
      return {
        propertyValue: { required: true, decimal: 2 },
        propertyAuctionValue: { required: true, decimal: 2 },
        disbursementDate: { required: true },
        firstPaymentDate: {
          after: this.data.disbursementDate ? moment(this.data.disbursementDate).format('YYYY-MM-DD') : undefined,
          required: true,
        },
      };
    },

    inputsProps() {
      return {
        firstPaymentDate: {
          min: this.data.disbursementDate ? moment(this.data.disbursementDate).add(1, 'd').format('YYYY-MM-DD') : undefined
        },
      };
    },
  },

  components: { ProductSelect, DatePicker, InvestorSelect },

  methods: {
    focus() {
      this.$refs.investorSelect.focus();
    },

    firstPaymentAllowedDates(date) {
      const mDate = moment(date);

      return mDate.date() <= 28 && (this.data.disbursementDate ? mDate.isAfter(this.data.disbursementDate) : true);
    },
  },

  watch: {
    propertyValue(newPropertyValue) {
      if (!newPropertyValue) return;

      const { value, currency } = newPropertyValue;

      this.data.propertyValue = value
      this.data.propertyAuctionValue = value * 0.75;
      this.data.propertyCurrency = { ...currency };
      this.data.penaltyFee = LOCAL_CURRENCY_PENALTY_FEE;

      if (!this.$currencyExchange.isLocalCurrency(currency)) {
        this.$currencyExchange.exchange(LOCAL_CURRENCY_PENALTY_FEE, currency)
          .then((amount) => {
            this.data.penaltyFee = amount;
          });
      }
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
