<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    .px-2.pb-3
      .font-weight-medium Detalles del cliente
    v-divider.mb-4
    customer-data-form(
      :customer="customer"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      submit-button-text="Guardar"
      cancel-button-text="Cancelar"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
      cancelable
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import CustomerDataForm from '@/components/smart/create-customer-modal/customer-data-form';

  export default {
    name: 'title-study-form-modal',

    props: {
      potentialOwners: { type: Array, default: () => [] },
    },

    mixins: [formModalMixin({ entityName: 'customer' })],

    components: { CustomerDataForm },
  };
</script>
