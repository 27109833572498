<template lang="pug">
  div(:class="b()")
    figure(:class="b('image')")
      img(v-if="feature.imageUrl" :src="feature.imageUrl")
    div(:class="b('body')")
      div(v-if="feature.name" :class="b('title')") {{ feature.name }}
      div(:class="b('description')" v-html="feature.description")
</template>

<script>
  export default {
    name: 'extra-features-list-item',

    props: {
      feature: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .extra-features-list-item
    display: flex

    +lg
      align-items: center

    &__image
      flex-shrink: 0
      width: 74px
      height: 74px

      +lg
        width: 84px
        height: 84px

      img
        width: 100%
        height: 100%

    &__body
      padding-top: 8px
      padding-left: 18px

      +lg
        padding-top: 0
        padding-left: 28px

    &__title
      font-size: 17px
      font-weight: 500
      color: $primaryColor

      +lg
        font-size: 19px

    &__description
      margin-top: 4px

      font-size: 16px
</style>