<template lang="pug">
  v-dialog(
    :value="value"
    width="1000"
    @input="args => $emit('input', args)"
    persistent
  )
    v-card
      div(:class="b('header')")
        div(:class="b('header-title')") Registrar Nuevo Cliente
        v-btn(:class="b('header-close')" icon @click="onClickClose")
          v-icon mdi-close
      v-divider
      div(:class="b('content')")
        div(v-if="customerData" :class="b('content-aside')")
          customer-info(:customer="customerData")
        div(:class="b('content-main')")
          customer-data-form(
            v-if="step === 'CUSTOMER_FORM_STEP'"
            :requesting="isCreatingCustomer"
            :hidden-fields="customerDataIsFilledWithBuro ? ['firstName', 'lastName', 'maternalLastName', 'mobile'] : []"
            :cancelable="false"
            submit-button-text="Siguiente"
            @submit="onSubmitCustomerForm"
          )
          options-menu(
            v-if="step === 'MENU_STEP'"
            :items="menuItems"
            @select="onSelectMenuOption"
            @close="goToUserProfile"
          )
          incomes-form(
            v-if="step === 'INCOMES_FORM_STEP'"
            :requesting="isCreatingIncome"
            :cancelable="hasIncomes"
            @submit="onSubmitIncomeForm"
            @cancel="goToMenu"
          )
          property-form(
            v-if="step === 'PROPERTY_FORM_STEP'"
            :requesting="isCreatingProperty"
            @submit="onSubmitPropertyForm"
            @cancel="goToMenu"
          )
</template>

<script>
import fanci from 'fanci';
import $update from 'immutability-helper';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';
import { compact } from 'lodash/fp';

import {
  WizardCreateCustomer,
  WizardCreateIncome,
  WizardCreateProperty,
} from './graphql/mutations.gql';
import CustomerDataForm from './customer-data-form';
import IncomesForm from './incomes-form';
import PropertyForm from './property-form';
import OptionsMenu from './options-menu';
import CustomerInfo from './customer-info';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

const CUSTOMER_FORM_STEP = 'CUSTOMER_FORM_STEP';
const INCOMES_FORM_STEP = 'INCOMES_FORM_STEP';
const PROPERTY_FORM_STEP = 'PROPERTY_FORM_STEP';
const MENU_STEP = 'MENU_STEP';

export default {
  name: 'create-customer-modal',

  mixins: [mutationsHandlersMixin(), currentUserMixin()],

  data() {
    return {
      step: CUSTOMER_FORM_STEP,
      customerData: null,
      isCreatingCustomer: false,
      isCreatingProperty: false,
      isCreatingIncome: false,
    };
  },

  props: {
    value: { type: Boolean, default: false },
  },

  methods: {
    onSubmitCustomerForm({ customer }) {
      this.handleCreateMutation({
        mutation: WizardCreateCustomer,
        requestingStateKey: 'isCreatingCustomer',
        variables: {
          data: {
            ...customer,
            adviserId: this.currentUser.id,
            fillWithBuro: this.customerDataIsFilledWithBuro,
          },
        },
      }).then(({ data }) => {
        this.customerData = data.createCustomer.customer;
        this.step = INCOMES_FORM_STEP;
      });
    },

    onSubmitIncomeForm({ income }) {
      this.handleCreateMutation({
        mutation: WizardCreateIncome,
        requestingStateKey: 'isCreatingIncome',
        variables: {
          data: {
            ...income,
            userId: this.customerData.id,
          },
        },
      }).then(({ data }) => {
        this.customerData = $update(this.customerData, {
          incomes: { $push: [data.createIncome.income] },
        });

        this.goToMenu();
      });
    },

    async onSubmitPropertyForm({ property }) {
      this.handleCreateMutation({
        mutation: WizardCreateProperty,
        requestingStateKey: 'isCreatingProperty',
        variables: {
          data: {
            ...fanci.rename(property, {
              address: true,
              propertyValue: 'value',
              propertyAuctionValue: 'auctionValue',
              currencyId: true,
              valuationDate: true,
              typeId: true,
              yearOfConstruction: true,
            }),
            ownerId: this.customerData.id,
          },
        },
      }).then(({ data }) => {
        this.customerData = $update(this.customerData, {
          properties: { $push: [data.createProperty.property] },
        });

        this.goToMenu();
      });
    },

    onSelectMenuOption(step) {
      this.step = step;
    },

    onClickClose() {
      if (this.customerData) this.goToUserProfile();
      else this.$emit('input', false);
    },

    goToMenu() {
      this.step = MENU_STEP;
    },

    goToUserProfile() {
      this.$router.push({
        name: 'customers.show',
        params: { customerId: this.customerData.id },
      });
    },
  },

  computed: {
    customerDataIsFilledWithBuro() {
      return IS_PE_COUNTRY;
    },

    menuItems() {
      return compact([
        {
          step: INCOMES_FORM_STEP,
          text: '¿Agregar otro ingreso?',
          icon: 'mdi-cash-plus',
        },
        this.hasProperty
          ? null
          : {
              step: PROPERTY_FORM_STEP,
              text: '¿Agregar un inmueble?',
              icon: 'mdi-home-plus-outline',
            },
      ]);
    },

    hasProperty() {
      return Boolean(this.customerData?.properties?.length);
    },

    hasIncomes() {
      return Boolean(this.customerData?.incomes?.length);
    },
  },

  components: {
    CustomerInfo,
    CustomerDataForm,
    IncomesForm,
    PropertyForm,
    OptionsMenu,
  },
};
</script>

<style lang="sass">
@import '~@/sass/mixins'

.create-customer-modal
  &__header
    display: flex
    align-items: center
    padding: 8px 8px

    &-title
      flex: 1
      font-size: 18px
      font-weight: bold
      text-align: center

  &__content
    &-aside
      padding: 12px
      background-color: darken(white, 4%)
      word-break: break-all

    &-main
      flex: 1
      padding: 22px 16px


  &__step-title
    color: darken(white, 40%)
    font-size: 18px
    font-weight: bold
    text-align: center

  +md
    &__content
      display: flex

      &-aside
        width: 260px

  +lg
    &__content
      &-aside
        width: 310px
</style>
