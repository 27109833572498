<template lang="pug">
  div(:class="b()" :style="{ backgroundColor: color }")
    v-icon mdi-paperclip
</template>

<script>
  export default {
    name: 'requirement-status-icon',

    props: {
      color: { type: String, default: 'gray' },
    },
  };
</script>

<style lang="sass">
  .requirement-status-icon
    display: flex
    justify-content: center
    align-items: center

    padding: .25rem

    border-radius: 50%

    .v-icon
      color: white
</style>


