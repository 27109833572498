<template lang="pug">
  v-autocomplete(
    :class="b()"
    :value="value"
    :items="lazyItems"
    :label="label"
    :filter="searchFn"
    :loading="$apollo.queries.advisers.loading"
    :return-object="returnObject"
    :search-input.sync="search"
    :error-messages="errorMessages"
    :disabled="disabled"
    :item-value="itemValue"
    @input="args => $emit('input', args)"
    @focus="onFocus"
    chips
    clearable
  )
    template(v-slot:selection="data")
      div {{ data.item.firstName }} {{ data.item.lastName }}
    template(v-slot:item="data")
      v-list-item-avatar
        user-avatar(
          :url="data.item.avatar"
          :first-name="data.item.firstName"
          :last-name="data.item.lastName"
          :size="42"
        )
      v-list-item-content
        v-list-item-title {{ data.item.firstName }} {{ data.item.lastName }}
        v-list-item-subtitle {{ data.item.email }}
</template>

<script>
  import { AdvisersByCountrySelectQuery } from './queries.gql';
  import basicStringSearch from '@/utils/basic-string-search';
  import UserAvatar from '@/components/UI/user-avatar';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';

  export default {
    name: 'adviser-select',

    mixins: [lazySelectMixin({ itemsKey: 'advisers' })],

    data() {
      return {
        advisers: [],
        search: null,
      };
    },

    apollo: {
      advisers: {
        query: AdvisersByCountrySelectQuery,
        manual: true,

        variables() {
          return {
            country: this.country,
          };
        },

        result({ data }) {
          this.advisers = data?.advisers || [];
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    props: {
      value: { type: String },
      label: { type: String, default: 'Asesor' },
      itemValue: { type: String, default: 'email' },
      fetching: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
      errorMessages: { type: Array, default: null },
      country: { type: String },
    },

    methods: {
      searchFn({email, firstName, lastName}, query) {
        return basicStringSearch([email, firstName, lastName], query);
      },
    },

    components: {
      UserAvatar,
    },
  };
</script>

<style lang="sass">
  .adviser-select
    .v-select__selections
      min-height: 32px !important
</style>