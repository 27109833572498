<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import {
    VListItemContent,
    VListItemTitle,
  } from 'vuetify/lib/components/VList';
  import $ from 'jquery';
  import basicStringSearch from '@/utils/basic-string-search';
  import debounce from 'lodash/debounce';
  import { TypOfeCancellationSelectQuery } from './queries.gql';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';

  export default {
    name: 'type-cancellation-select',

    mixins: [
      lazySelectMixin({ itemsKey: 'typeOfCancellations' })
    ],

    data() {
      return {
        typeOfCancellations: [],
        search: null,
      };
    },

    apollo: {
      typeOfCancellations: {
        query: TypOfeCancellationSelectQuery,
        fetchPolicy: 'cache-and-network',
        manual: true,

        variables() {
          return {
            search: this.search,
            perPage: this.perPage,
            ...this.additionalQueryVariables,
          };
        },

        result({ data }) {
          this.typeOfCancellations = data?.typeOfCancellations?.objects || [];
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    props: {
      value: { type: [Object, String, Array] },
      itemValue: { type: String, default: 'status' },
      fetching: { type: Boolean, default: false },
      perPage: { type: Number, default: 10 },
      multiple: { type: Boolean, default: false },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),
      onInput(selected) {
        this.lazyItems.filter(
          (typeOfCancellation) => (Array.isArray(selected) ? selected : [selected]).includes(typeOfCancellation.status)
        ).map(this.$_lazySelectMixin_addInnerItem);

        this.$emit('input', selected);
      },

      searchFn({description, status}, query) {
        return basicStringSearch([description, status], query);
      },

      focus() {
        const input = this.$el.querySelector('input');

        $(input).click();
      },
    },

    render(h) {
      return h(
        'div',
        {
          class: 'd-flex align-center',
        },
        [
          h(VAutocomplete, {
            class: [this.b(), 'flex-grow-1'],

            props: {
              ...this.$attrs,
              ...this.$props,
              items: this.lazyItems,
              filter: this.searchFn,
              loading: this.$apollo.queries.typeOfCancellations.loading,
              itemText: this.multiple ? (selected) => 
                selected.description === null 
                ? `${selected.status}` 
                : `${selected.description}` 
              : undefined,
              chips: this.multiple,
            },

            on: {
              ...this.$listeners,
              input: this.onInput,
              'update:search-input': this.onChangeSearch,
              focus: this.onFocus,
            },

            scopedSlots: {
              selection: this.multiple ? undefined :data => h('div', 
                data.item.description === null 
                  ? `${data.item.status}` 
                  : `${data.item.description}`),
              prependItem: this.$slots.prependItem,

              item: data => {
                const { description, status } = data.item;
                const displayText = description === null ? status : description;

                return [
                  h(VListItemContent, [
                    h(VListItemTitle, displayText),
                  ]),
                ];
              },
            }
          }),
        ]
      );
    },
  };
</script>

<style lang="sass">
  .type-cancellation-select
    .v-select__selections
      min-height: 32px !important
</style>