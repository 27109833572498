<template lang="pug">
  v-flex(xs12 sm4 md3 lg2)
    a(:href="file.file" target="_blank" :class="b()")
      div(:class="b('icon')")
        v-icon {{ fileIcon }}
      div(:class="b('name')") {{ file.name }}
</template>

<script>
  const FILE_ICONS = {
    PDF: 'mdi-file-pdf',
    IMAGE: 'mdi-file-image',
    DOWNLOAD: 'mdi-file-download',
  };

  export default {
    name: 'url-item-type',

    props: {
      file: { type: Object, required: true },
    },

    computed: {
      fileIcon() {
        return FILE_ICONS[this.file.fileType.toUpperCase()] || 'mdi-file';
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .url-item-type
    $c: &
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center

    margin: .4rem
    padding-right: .25rem
    color: inherit
    text-decoration: none
    text-align: center

    &__name
      font-size: 14px

    &__icon
      i
        color: darken(white, 60%)
        font-size: 74px
        margin-bottom: 4px

    &__delete
      cursor: pointer

    &:hover
      color: $primaryColor

      #{$c}
        &__icon
          i
            color: $primaryColor

</style>