<template lang="pug">
  thead
    tr(:class="b()")
      th
        v-checkbox(
          :value="allSelected"
          hide-details
          @change="onChangeSelectAllCheckbox"
          @click.native.prevent="() => null"
        ).ma-0.pa-0
      table-head-item(
        v-for="item in items"
        :key="item.title"
        :title="item.title"
        :order-key="item.orderKey"
        :order-flow="item.orderFlow"
        @order="args => $emit('order', args)"
      )
</template>

<script>
  import TableHeadItem from '@/components/UI/table-head-item';
  import sortableTableHeadMixin from '@/mixins/sortable-table-head-mixin';

  const ITEMS = [
    { title: 'Inversionista', orderKey: 'investor_id' },
    { title: 'Vehículo', orderKey: 'vehicle_id' },
    { title: 'Emisión', orderKey: 'disbursement_date' },
    { title: 'No. Emisión', orderKey: 'issue_code' },
    { title: 'Tasa', orderKey: 'rate' },
    { title: 'Plazo', orderKey: 'quotes_quantity' },
    { title: 'Saldo Deudor', orderKey: 'pending_debt' },
    { title: 'Amortizado', orderKey: 'total_amortized' },
    { title: 'Interest', orderKey: 'total_interest' },
    { title: 'Status', orderKey: 'status' },
  ];

  export default {
    name: 'bonds-table-head',

    mixins: [sortableTableHeadMixin()],

    props: {
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      items() {
        return ITEMS.map(item => ({
          ...item,
          orderFlow: this.orderBy === item.orderKey ? this.orderFlow : null,
        }));
      },
    },

    components: {
      TableHeadItem,
    },
  };
</script>

<style lang="sass">
  .bonds-table-head
    th
      padding: 4px 8px

</style>