<template lang="pug">
  div(:class="b()")
    div(
      :class="b('debt')"
      v-for="account in accounts"
      :key="account.id"
    )
      div(v-if="account.recentSanctionDate" :class="b('debt-title')") {{ account.recentSanctionDate | formatDate }}
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="info in account.caNegativeInfos" :key="info.id")
          div(v-if="info.sanctionDate") #[b Fecha de sanción: ] {{ info.sanctionDate | formatDate }}
          div(v-if="info.endSanctionDate") #[b Fecha de finalización de sanción: ] {{ info.endSanctionDate | formatDate }}
          div(v-if="info.publicationNumber") #[b Número de publicación: ] {{ info.publicationNumber }}
          div(v-if="info.publishedDate") #[b Fecha de publicación: ] {{ info.publishedDate | formatDate }}
          div(v-if="info.currency") #[b Moneda: ] {{ info.currency }}
          div(v-if="info.accountType") #[b Tipo de cuenta: ] {{ info.accountType }}
          div(v-if="info.entity") #[b Entidad: ] {{ info.entity }}
</template>

<script>
  export default {
    name: 'closed-accounts-tab',

    block: 'debts-tab',

    props: {
      accounts: { type: Array, default: () => [] },
    },
  };
</script>
