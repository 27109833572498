<template lang="pug">
  table(:class="b()")
    thead
      tr
        th #
        th Fecha Vcto.
        th Amortización
        th Interés
        th(v-if="hasPropertyInsurance") {{ $t('propertyInsurance')}}
        th(v-if="hasLifeInsurance") {{ $t('lifeInsurance') }}
        th(v-if="hasManagementFee") {{ $t('managementFee') }}
        th Cuota
        th Saldo Capital
    tbody
      tr(v-for="entry, idx in schedule" :key="idx")
        td {{ entry.feeNumber }}
        td {{ entry.date | formatDate('DD MMM YYYY') }}
        td {{ entry.amortization | formatNumber(currency) }}
        td {{ entry.interest | formatNumber(currency) }}
        td(v-if="hasPropertyInsurance") {{ entry.propertyInsurance | formatNumber(currency)  }}
        td(v-if="hasLifeInsurance") {{ entry.lifeInsurance | formatNumber(currency)  }}
        td(v-if="hasManagementFee") {{ entry.managementFee | formatNumber(currency)  }}
        td {{ entry.fee | formatNumber(currency)  }}
        td {{ entry.capital | formatNumber(currency)  }}
</template>

<script>
export default {
  name: 'schedule-table',

  i18n: {
    messages: {
      'es-PE': {
        propertyInsurance: 'Seg. Bien',
        lifeInsurance: 'Seg. Desg.',
        managementFee: 'Gasto Admin.',
      },
      'es-MX': {
        propertyInsurance: 'Seg. Daños',
        lifeInsurance: 'Seg. Vida',
        managementFee: 'Gasto Admin.',
      },
    },
  },

  props: {
    schedule: { type: Array },
    currency: { type: Object },
  },

  computed: {
    hasPropertyInsurance() {
      return (
        this.schedule.reduce(
          (acc, { propertyInsurance }) => acc + propertyInsurance,
          0,
        ) > 0
      );
    },

    hasLifeInsurance() {
      return (
        this.schedule.reduce(
          (acc, { lifeInsurance }) => acc + lifeInsurance,
          0,
        ) > 0
      );
    },

    hasManagementFee() {
      return (
        this.schedule.reduce(
          (acc, { managementFee }) => acc + managementFee,
          0,
        ) > 0
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.schedule-table
  width: 100%
  border-collapse: collapse
  white-space: nowrap

  td, th
    padding: 0 9px

  thead
    text-align: right

  tbody
    text-align: right

    tr
      td
        &:first-child
          width: 20px

      &:nth-child(odd)
        background-color: darken(white, 5%)
</style>
