<template lang="pug">
  quotes-card(
    :user="proposal.borrower"
    :vehicle="proposal.investor"
    label="Prestatario"
    labelVehicle="Vehículo"
  )
    template(slot="header-data")
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ proposal.borrowerRate }} %
        .quotes-card__header-data-item-description Tasa
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ proposal.pendingQuotesQuantity || 0 }}
        .quotes-card__header-data-item-description Cuotas #[br] Pendientes
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ nextQuote | formatCurrency(currency) }}
        .quotes-card__header-data-item-description Próxima #[br] Cuota
    template(slot="header-actions")
      v-btn(
        v-if="refinanceButton && !isJudicialized"
        color="primary"
        small text
        @click="$emit('click-refinance')"
      )
        v-icon.pb-1.hidden-touch mdi-cash-sync
        span.ml-1 Ajuste de Cuota
      v-btn(
        v-if="settlementButton"
        color="primary"
        small text
        @click="$emit('click-update-settlement')"
      )
        v-icon.pb-1.hidden-touch mdi-cash-check
        span.ml-1 Liquidación
      v-btn(
        v-if="isPending || showButton"
        :to="statementALFINRoute"
        target="_blank"
        color="primary"
        small text
      )
        v-icon.hidden-touch mdi-file-table
        span.ml-1 Estado Cuenta ALFIN
      v-btn(
        v-if="isPending || showButton"
        :to="statementRoute"
        target="_blank"
        color="primary"
        small text
      )
        v-icon.hidden-touch mdi-file-table
        span.ml-1 Estado Cuenta
      v-btn(
        v-if="showButton"
        :disabled="fetching"
        :loading="fetching"
        color="primary"
        small text
        @click="$emit('refresh')"
      )
        v-icon mdi-refresh
        span.ml-1.hidden-xs-only Actualizar
    quotes(
      :quotes="quotes"
      :currency="currency"
      :fetching="fetching"
      :proposal="proposal"
      @register-payment="onClickRegisterPayment"
      @click-reschedule="onClickRescheduleQuote"
    )
    payment-form-modal(
      v-model="paymentFormModal"
      :default-values="paymentFormDefaultValues"
      :rules="paymentFormRules"
      :fetching="mutating"
      @submit="onSubmitPaymentForm"
    )
</template>

<script>
import { encrypt } from '@/utils/url-params-crypter';
import { QUOTE_TYPE, INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

import QuotesCard from '../quotes-card';
import Quotes from './quotes';
import PaymentFormModal from '../../../components/quote-payment-form-modal';

export default {
  name: 'borrower-quotes-card',

  data() {
    return {
      registeringQuote: null,
      paymentFormModal: false,
    };
  },

  props: {
    proposal: { type: Object, required: true },
    currency: { type: Object, required: true },
    nextQuote: { type: Number },
    quotes: { type: Array, default: () => [] },
    fetching: { type: Boolean, default: false },
    mutating: { type: Boolean, default: false },
    settlementButton: { type: Boolean, default: false },
    refinanceButton: { type: Boolean, default: false },
    logo: { type: Boolean, default: false },
    investmentProposal: { type: Object },
  },

  computed: {
    scheduleRoute() {
      return {
        name: 'mortgage_schedule.investment_borrower',
        query: {
          _params: encrypt({
            investmentProposalId: this.proposal.id,
            proposalId: this.proposal.proposalId,
          }),
        },
      };
    },

    statementRoute() {
      return {
        name: 'investment.borrower_statement',
        query: {
          _params: encrypt({
            investmentProposalId: this.proposal.id,
            logo: !this.logo,
          }),
        },
      };
    },

    statementALFINRoute() {
      return {
        name: 'investment.borrower_statement',
        query: {
          _params: encrypt({
            investmentProposalId: this.proposal.id,
            logo: this.logo,
          }),
        },
      };
    },

    paymentFormRules() {
      if (!this.registeringQuote) return {};

      return {
        amount: { required: true, min_value: this.registeringQuote.currentData.amountDue, decimal: 2 },
      };
    },

    paymentFormDefaultValues() {
      return {
        quoteId: this.registeringQuote?.id,
        amount: this.registeringQuote?.currentData?.amountDue,
      };
    },

    isPending() {
      return this.proposal.status === INVESTMENT_PROPOSAL_STATUS.PENDING;
    },

    showButton() {
      const allowedStatuses = [
        INVESTMENT_PROPOSAL_STATUS.COLLECTING,
        INVESTMENT_PROPOSAL_STATUS.FINISHED,
      ];

      const currentStatus = this.proposal?.status;

      return allowedStatuses.includes(currentStatus) && currentStatus !== INVESTMENT_PROPOSAL_STATUS.CANCELLED;
    },

    isJudicialized() {
      const status = this.proposal?.qualificationStatus?.status.toUpperCase();
      return status ? INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED === status : false;
    },
  },

  components: { QuotesCard, Quotes, PaymentFormModal },

  methods: {
    closePaymentForm() {
      this.paymentFormModal = false;
    },

    onClickRegisterPayment({ quote }) {
      this.paymentFormModal = true;
      this.registeringQuote = quote;
    },

    onClickRescheduleQuote({ quote }) {
      this.$emit('click-reschedule-quote', { quote, type: QUOTE_TYPE.BORROWER });
    },

    onSubmitPaymentForm({ data }) {
      this.$emit('submit-payment', { data });
    },

    openStatement() {
      const { href } = this.$router.resolve(this.statementRoute);

      window.open(href, '_blank').focus();
    }
  },
};
</script>

<style lang="sass" scoped>

</style>
