<template lang="pug">
  table(:class="b()")
    thead
      tr
        th Acreedor
        th Estado
        th Monto

    tbody
      tr(v-for="payDetail in payDetails")
        td.font-weight-medium {{ payDetail.creditor }}
        td(:style="{ color: getPayDetailStatusColor(payDetail.status) }").text-center {{ $t(`payDetailStatus.${payDetail.status}`) }}
        td {{ payDetail.amount | formatCurrency }}

    tfoot
      tr
        th(colspan="2") Total
        th {{ total | formatCurrency }}
</template>

<script>

const STATUS_COLOR = {
  PAID: '#4caf50',
  PENDING: 'rgb(229 112 12)',
  ENDORSED: '#ff1414',
};

export default {
  name: 'contract-pay-details-table',

  props: {
    payDetails: { type: Array, default: () => [] },
  },

  methods: {
    getPayDetailStatusColor(status) {
      return STATUS_COLOR[status];
    },
  },


  computed: {
    total() {
      return (this.payDetails || []).reduce((acc, { amount }) => acc + Number(amount), 0);
    },
  },
};
</script>

<style lang="sass" scoped>
  .contract-pay-details-table
    width: 100%
    border-collapse: collapse

    tr, th, td
      padding: 3px 8px

    thead
      th
        background-color: $primaryColor
        color: white

    tbody
      tr
        td
          border-bottom: 1px solid darken(white, 12%)

        td:last-child
          text-align: center

    tfoot
      td, th
        background-color: darken(white, 6%)

</style>