<template lang="pug">
  div(:class="b()")
    div(:class="b('customer-container')")
      workplace-card(v-if="'businessName' in results.client" :workplace="results.client")
      customer-card(v-else :customer="results.client")
    div(:class="b('tabs-container')")
      v-tabs(v-model="currentTab" fixed-tabs)
        v-tab Tarjetas de Crédito
        v-tab Créditos de Consumo
        v-tab Credits Amount
        v-tab Historial de Deudas
        v-tab Hipotecas
        v-tab Pagos
        v-tab Garantías
        v-tab-item.py-3
          credit-cards(:credit-cards="results.creditCards")
        v-tab-item.py-3
          consumer-loan(:credits="results.consumerLoan && results.consumerLoan.history")
        v-tab-item.py-3
          credits-amount(:credits-amount="results.creditsAmount")
        v-tab-item.py-3
          historical-debts(:historical-debts="results.historicalDebts")
        v-tab-item.py-3
          mortgages(:mortgages="results.mortgage && results.mortgage.history")
        v-tab-item.py-3
          payments-behaviour(:behaviour="results.resumePaymentBehavior")
        v-tab-item.py-3
          guarantees(:guarantees="results.guarantees")
</template>

<script>
  import CreditsAmount from './components/credits-amount';
  import CreditCards from './components/credit-cards';
  import HistoricalDebts from './components/historical-debts';
  import Mortgages from './components/mortgages';
  import Guarantees from './components/guarantees';
  import PaymentsBehaviour from './components/payments-behaviour';
  import CustomerCard from './components/customer-card';
  import WorkplaceCard from './components/workplace-card';
  import ConsumerLoan from './components/consumer-loan-credits';

  export default {
    name: 'pe-buro-results',

    props: {
      results: { type: Object, required: true },
    },

    data() {
      return {
        currentTab: 0,
      };
    },

    components: {
      CreditsAmount,
      CreditCards,
      HistoricalDebts,
      Mortgages,
      Guarantees,
      PaymentsBehaviour,
      CustomerCard,
      WorkplaceCard,
      ConsumerLoan,
    },
  };
</script>

<style lang="sass" scoped>

</style>