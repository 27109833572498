<template lang="pug">
  .data-card
    .data-card__title Cronograma de pagos
    .data-card__body
      schedule-table(:schedule="schedule.schedule" :currency="currency")
</template>

<script>
  import ScheduleTable from './schedule-table';

  export default {
    name: 'schedule-card',

    props: {
      investmentProposal: { type: Object },
      schedule: { type: Object },
      currency: { type: Object },
    },

    components: { ScheduleTable },
  };
</script>

<style lang="sass" scoped>
  .data-card
    &__body
      padding-left: 8px
      padding-right: 8px
</style>