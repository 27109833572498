<template lang="pug">
  div(:class="b()")
    v-card.px-2.py-1
      v-breadcrumbs(:items="breadcrumbItems")
    v-container(fluid)
      div(:class="b('container')")
        div(:class="b('column')")
          step(
            :number="1"
            title="Buscar Negociación"
          )
            deal-form-view(
              @submit="onSubmitDeal"
            )
        div(:class="b('column')")
          step(
            :number="2"
            :disabled="!selectedDeal"
            title="Confirmar datos"
          )
            investment-proposal-form(
              ref="investmentProposalForm"
              :requesting="isCreatingProposal"
              :product-slug="productSlug"
              :property-value="propertyValue"
              :customer="selectedCustomer"
              :disabled="!selectedDeal || !acceptedProposal"
              @submit="onSubmitInvestmentForm"
              show-confirm-fields
            )
              template(v-slot:actions="{ requesting, isDisabled }")
                v-btn(
                  :loading="requesting"
                  :disabled="isDisabled"
                  color="primary"
                  type="submit"
                  block large
                ).mt-4 CREAR PROPUESTA
</template>

<script>
  import mutationsHandlerMixin from '@/mixins/mutations-handlers-mixin';
  import { CreateInvestmentProposalMutation } from './graphql/mutations.gql';
  import { DealProposalsQuery } from './graphql/queries.gql';
  import InvestmentProposalForm from '@/components/forms/investment-proposal-form';

  import Step from './components/step';
  import DealFormView from './components/deal-form-view';

  export default {
    name: 'investment-new-view',

    apollo: {
      acceptedProposal: {
        query: DealProposalsQuery,

        variables() {
          return { dealId: this.selectedDeal.id };
        },

        update({ deal }) {
          return (deal?.proposals || []).find(({ acceptedAt }) => acceptedAt);
        },

        skip() {
          return !this.selectedDeal
        },
      },
    },

    mixins: [
      mutationsHandlerMixin(),
    ],

    data() {
      return {
        selectedDeal: null,
        acceptedProposal: null,
        isCreatingProposal: false,
        breadcrumbItems: [
          { text: 'Inversionistas', to: { name: 'investment.list'}, exact: true },
          { text: 'Nueva propuestas', disabled: true }
        ],
      };
    },

    computed: {
      selectedCustomer() {
        return this.selectedDeal?.customer;
      },

      propertyValue() {
        return this.acceptedProposal ? {
          value: this.acceptedProposal?.propertyValue,
          currency: this.acceptedProposal?.currency,
        } : null;
      },

      productSlug() {
        return this.selectedDeal?.product?.slug;
      },
    },

    components: { Step, DealFormView, InvestmentProposalForm },

    methods: {
      onSubmitDeal({ deal }) {
        this.selectedDeal = deal;

        setTimeout(() => {
          this.$refs.investmentProposalForm.focus();
        }, 200);
      },

      onSubmitInvestmentForm({ investmentProposal }) {
        this.handleCreateMutation({
          mutation: CreateInvestmentProposalMutation,

          requestingStateKey: 'isCreatingProposal',

          variables: {
            ...investmentProposal,
            dealId: this.selectedDeal.id,
          },
        }).then(({ data: { createInvestmentProposal: { investmentProposal } }}) => {
          this.$router.push({ name: 'investment.show', params: { proposalId: investmentProposal.id }})
        });
      }
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-new-view
    &__container
      max-width: 1100px
      margin: 0 auto
      padding-top: 24px
      padding-bottom: 24px

    &__column
      &:last-child
        padding-top: 26px


    +md
      &__container
        display: flex
        padding-top: 80px
        padding-left: 32px
        padding-right: 32px

      &__column
        &:first-child
          position: relative
          width: 400px
          flex-shrink: 0
          padding-right: 60px

          &:after
            content: ''
            position: absolute
            right: 0
            top: 50%
            width: 1px
            height: 220px
            transform: translateY(-50%)

            background-color: darken(white, 15%)

        &:last-child
          padding-top: 0
          padding-left: 60px

    +lg
      &__column
        &:first-child
          width: 450px
</style>