import { render, staticRenderFns } from "./legal-representatives-dialog.vue?vue&type=template&id=67138b44&scoped=true&lang=pug"
import script from "./legal-representatives-dialog.vue?vue&type=script&lang=js"
export * from "./legal-representatives-dialog.vue?vue&type=script&lang=js"
import style0 from "./legal-representatives-dialog.vue?vue&type=style&index=0&id=67138b44&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67138b44",
  null
  
)

export default component.exports