<template lang="pug">
  th(:class="b({ sortable: isSortable, active })" @click="onClick")
    div(:class="b('container')")
      div(:class="b('title')") {{ title }}
      v-icon(:class="b('icon')" v-if="isSortable") {{ iconName }}
</template>

<script>
  import isString from 'lodash/isString';

  export default {
    name: 'table-head-item',

    props: {
      title: { type: String, required: true },
      orderKey: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      iconName() {
        switch(this.orderFlow) {
          case 'ASC':
            return 'mdi-sort-ascending';
          case 'DESC':
            return 'mdi-sort-descending';
          default:
            return 'mdi-sort';
        }
      },

      active() {
        return Boolean(this.orderFlow);
      },

      isSortable() {
        return isString(this.orderKey);
      },
    },

    methods: {
      onClick() {
        if(!this.isSortable) return;

        this.$emit('order', {
          key: this.orderKey,
          flow: this.orderFlow === 'ASC' ? 'DESC' : 'ASC',
        });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .table-head-item
    &__container
      display: flex
      align-items: center
      justify-content: center

    &__title
      display: inline-block
      margin-right: 8px

    &--sortable
      cursor: pointer

    &--active
      i
        color: $primaryColor
</style>