<template lang="pug">
  div(:class="b()")
    view-header
    loading-wrapper(v-if="!proposal || !investmentProposal || !schedule")
    template(v-else)
      proposal-card(
        :proposal="proposal"
        :investment-proposal="investmentProposal"
        :simple-fee="schedule.simpleFee"
        :double-fee="schedule.doubleFee"
        :tea="schedule.tea"
        :tcea="schedule.tcea"
        :schedule="schedule.schedule"
        :is-rescheduled="isRescheduled"
      )
      div(:class="b('print-actions')")
        v-btn(color="primary" text @click="onClickPrint")
          span Imprimir&nbsp;
          v-icon mdi-printer
      schedule-card(
        :schedule="schedule.schedule"
        :currency="investmentProposal.currency"
      )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import urlCryptedParamsMixin from '@/mixins/url-crypted-params-mixin';
  import {
    BorrowerInvestmentScheduleQuery,
    BorrowerInvestmentRescheduleQuery,
    BorrowerScheduleProposalQuery,
    BorrowerScheduleInvestmentProposalQuery
  } from './graphql/queries.gql';
  import pickFees from '../utils/pick-fees';
  import ProposalCard from './components/proposal-card';
  import ViewHeader from '../components/view-header';
  import ScheduleCard from './components/schedule-card';

  export default {
    name: 'mortgage-schedule-investment-borrower-view',

    mixins: [
      urlCryptedParamsMixin({
        parseKeys: ['investmentProposalId', 'proposalId', 'feeNumber', 'balloonPct'],
        requiredKeys: ['investmentProposalId', 'proposalId'],
        brokenParamsMessage: 'El cronograma al que intenta acceder no existe',
      }),
    ],

    apollo: {
      schedule: {
        query() {
          return this.isRescheduled ? BorrowerInvestmentRescheduleQuery : BorrowerInvestmentScheduleQuery;
        },

        variables() {
          return {
            investmentProposalId: this.investmentProposalId,
            balloonPct: this.balloonPct,
            feeNumber: this.feeNumber,
          };
        },

        update({ schedule }) {
          if(!schedule) return null;

          return {
            ...schedule,
            ...pickFees(schedule.schedule),
          };
        },

        skip() {
          return !this.investmentProposalId;
        }
      },

      proposal: {
        query: BorrowerScheduleProposalQuery,

        variables() {
          return {
            id: this.proposalId,
          };
        },

        skip() {
          return !this.proposalId;
        },
      },

      investmentProposal: {
        query: BorrowerScheduleInvestmentProposalQuery,

        variables() {
          return { id: this.investmentProposalId };
        },

        update({ investmentProposals }) {
          const investmentProposal = investmentProposals?.objects?.[0];

          return investmentProposal || null;
        },

        skip() {
          return !this.investmentProposalId;
        }
      },
    },

    data() {
      return {
        proposalId: null,
        investmentProposalId: null,
        schedule: null,
        proposal: null,
        investmentProposal: null,
        feeNumber: undefined,
        balloonPct: undefined,
      };
    },

    computed: {
      isRescheduled() {
        return Boolean(this.feeNumber);
      },
    },

    components: {
      ViewHeader,
      ProposalCard,
      ScheduleCard,
      LoadingWrapper,
    },

    methods: {
      onClickPrint() {
        window.print();
      },
    },

    mounted() {
      document.title = 'Cronograma RebajaTusCuentas';
    }
  };
</script>

<style lang="sass">
  .mortgage-schedule-investment-borrower-view
    padding: 16px 32px
    max-width: 1200px
    margin: 0 auto
    font-size: 14px

    &__title
      margin: 8px 0
      text-align: center

    &__print-actions
      text-align: right
</style>

<style lang="sass">
  body, html, .application--wrap
    background-color: white !important

  .data-card
    margin-bottom: 16px

    &__title, &__body
      background-color: darken(white, 1%)

    &__title
      display: inline-block
      padding: 6px 24px
      font-size: 17px
      font-weight: 50
      border-radius: 6px 6px 0 0
      background-color: darken(white, 4%)

    &__body
      padding: 12px 24px
      border-radius: 0 6px 6px 6px

  @media print
    .dates-form, .mortgage-schedule-investment-borrower-view__print-actions
      display: none

    body
      -webkit-print-color-adjust: exact
</style>