import { QUOTE_STATUS } from '@/utils/constants';

const COLORS = {
  [QUOTE_STATUS.PAID]: '#4caf50',
  [QUOTE_STATUS.EXPIRED]: '#f44336',
  [QUOTE_STATUS.PENDING]: '#9c8ade',
  [QUOTE_STATUS.PAID_DELAYED]: '#8ec9bb',
  [QUOTE_STATUS.RESCHEDULED]: '#c37432'
};

export default status => COLORS[status];
