<template lang="pug">
  thead
    tr
      th Inversionista
      th(v-for="label in labels" :key="label") {{ label }}
</template>

<script>
export default {
  name: 'bonds-table-head',

  props: {
    labels: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  //.bonds-table-head
</style>