<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="empresa tasadora" :rules="rules.appraiserId" v-slot="{ errors }")
            appraisers-select(
              v-model="data.appraiserId"
              label="Empresa Tasadora"
              :error-messages="errors"
              :default-items="appraisersDefaultItems"
              name="empresa tasadora"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="fecha" :rules="rules.date" v-slot="{ errors }")
            date-picker(
              v-model="data.date"
              :max="datePickerMax"
              :error-messages="errors"
              label="Fecha"
              name="fecha"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="valor comercial" :rules="rules.commercialValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.commercialValue"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="valor comercial"
              label="Valor comercial"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="valor de realización" :rules="rules.auctionValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.auctionValue"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="valor de realización"
              label="Valor de realización"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="valor de reconstrucción" :rules="rules.rebuildValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.rebuildValue"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="valor de reconstruccion"
              label="Valor de reconstruccion"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="tipo de cambio" :rules="rules.currencyChange" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.currencyChange"
              :error-messages="errors"
              type="number"
              name="tipo de cambio"
              label="Tipo de cambio"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="tipo de propiedad" :rules="'required'" v-slot="{ errors }")
            property-type-select(
              v-model="data.propertyTypeId"
              :error-messages="errors"
              name="tipo de propiedad"
              label="Tipo de propiedad"
              :lazy="false"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="año de construccion" :rules="rules.yearOfConstruction" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.yearOfConstruction"
              :error-messages="errors"
              type="number"
              name="año de construccion"
              label="Año de construcción"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="area construida" :rules="rules.constructionArea" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.constructionArea"
              :error-messages="errors"
              type="number"
              name="area construida"
              label="Area construida (m²)"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="area total" :rules="rules.landArea" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.landArea"
              :error-messages="errors"
              type="number"
              name="area total"
              label="Area terreno (m²)"
              step="0.01"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="velocidad de cambio" :rules="'required'" v-slot="{ errors }")
            speed-selling-status-select(
              v-model="data.speedSelling"
              :error-messages="errors"
              name="velocidad de cambio"
              label="Velocidad de cambio"
              :lazy="false"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="demanda" :rules="'required'" v-slot="{ errors }")
            demand-status-select(
              v-model="data.demand"
              :error-messages="errors"
              name="demanda"
              label="Demanda"
              :lazy="false"
            )
        v-flex(xs12).py-2
          v-divider
        v-flex(xs12).px-1
          yes-or-no-radio(
            label="¿Está en una zona de riesgo?"
            v-model="data.dangerZone"
          )
        v-flex(xs12).px-1
          yes-or-no-radio(
            label="¿Construido con material noble?"
            v-model="data.nobleMaterial"
          )
        v-flex(xs12).px-1
          yes-or-no-radio(
            label="¿Cuenta con servicios básicos (luz, agua y desagüe)?"
            v-model="data.essentialServices"
          )
        v-flex(xs12).px-1
          yes-or-no-radio(
            label="¿Funciona cómo colegio, mercado, albergue, iglesia ni otros que tenga impacto social?"
            v-model="data.itWorksAsSocialBuilding"
          )
        v-flex(xs12).px-1
          yes-or-no-radio(
            label="¿Pistas completas?"
            v-model="data.asphaltAndFullSidewalks"
          )
        v-flex(xs12).px-1.mb-2
          yes-or-no-radio(
            label="¿Está en una falda de cerro?"
            v-model="data.hillside"
          )
        v-flex(xs12).py-2
          v-divider
        v-flex(xs12).px-1
          validation-provider(name="dirección registral" :rules="rules.registrationAddress" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.registrationAddress"
              :error-messages="errors"
              name="dirección registral"
              label="Dirección registral"
            )
        //-- Dirección Municipal
        v-flex(xs12).px-1
          validation-provider(name="dirección municipal" :rules="rules.municipalAddress" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.municipalAddress"
              :error-messages="errors"
              name="dirección municipal"
              label="Dirección municipal"
            )
        v-flex(
          v-for="division, idx in politicalDivisions"
          v-show="idx > 0"
          :key="idx"
          xs12 md6
        ).px-2
          validation-provider(:name="division.name" :rules="{ required: true }" v-slot="{ errors }")
            location-select(
              :value="$addressInput.getValue(idx)"
              :error-messages="errors"
              :name="division.name"
              :disabled="$addressInput.isDisabled(idx)"
              :parent-id="$addressInput.getParentId(idx)"
              :default-items="$addressInput.getDefaultItems(idx)"
              :label="division.name"
              @input="value => $addressInput.onInput(value, idx)"
            )
        v-flex(xs12)
        v-flex(xs12 sm6).px-1
          validation-provider(name="latitud" :rules="rules.latitude" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.latitude"
              :error-messages="errors"
              type="number"
              name="latitud"
              label="Latitúd"
              step="any"
            )
        v-flex(xs12 sm6).px-1
          validation-provider(name="longitud" :rules="rules.longitude" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.longitude"
              :error-messages="errors"
              type="number"
              name="longitud"
              label="Longitud"
              step="any"
            )
        v-flex(xs12)
          div(v-if="mapsUrl" :class="b('maps-container')")
            iframe(
              :src="mapsUrl"
              width="100%"
              height="300"
              frameborder="0"
              style="border: 0"
            )
        v-flex(xs12).px-1
          validation-provider(name="comentarios" :rules="rules.dangerZone" v-slot="{ errors }")
            v-textarea(
              v-model="data.comments"
              :error-messages="errors"
              name="comentarios"
              label="Comentarios"
            )
        v-flex(xs12).px-1
          validation-provider(name="excepcion" :rules="'required'" v-slot="{ errors }")
            appraisal-exception-select(
              v-model="data.opinionExceptionsIds"
              :error-messages="errors"
              :default-items="opinionExceptionDefaultItems"
              name="excepcion"
              label="Excepción"
            )
        v-flex(xs12).px-1
          validation-provider(name="número de partida" :rules="'required'" v-slot="{ errors }")
            v-text-field(
              v-model="data.registrationNumber"
              :error-messages="errors"
              name="número de partida"
              label="Número de partida"
            )
        <v-flex xs12 class="mod-url px-1">
          <div>
            <h2 style="margin:1rem;">Subida de Videos de Propiedad</h2>
            <ul style="list-style-type: none; padding: 0;">
              <li style="display:flex; justify-content:center; align-items:center;" v-for="(item, index) in itemURL" :key="index" :class="['input-video']">
                <input style="width:95%; height:2.25rem; border-bottom:solid .50px black" v-model="item.url" type="text" placeholder="Ingrese la URL del video">
                //--<v-btn :id="'btn-' + (index + 1)" title="Eliminar este video" style="width:.50rem; height:2.5rem; margin-left:1rem; color:red; background-color:transparent; border-radius:0; box-shadow:none" @click="removeVideoUrl(index)">X</v-btn>
              </li>
            </ul>
            <v-btn style="margin:1rem;" @click="addItems" title="Agregar Campo">Agregar</v-btn>
            <v-btn style="margin:1rem;" @click="removeItems"  title="Eliminar toda la lista">Limpiar</v-btn>
            <v-divider></v-divider>
          </div>
        </v-flex>
        div
          h2(style="margin:1rem;") Videos Subidos 
            div(style="display:flex; justify-content:center; align-items:center")
              ul(style="list-style-type: none; margin:.75rem auto auto 1rem; padding: 1rem; font-size:1.25rem;")
                li(v-for="(item, index) in itemDrive" :key="index" :class="['down-video']")
                  a(:href="item.url" target="_blank" rel="noopener noreferrer")
                    | {{ item.url ? 'Video #' + item.id + ' (Drive URL)': 'No hay videos subidos' }}
              ul(style="list-style-type: none; margin:.75rem auto auto 1rem; padding: 1rem; font-size:1.25rem;")
                li(v-for="(item, index) in itemAws" :key="index" :class="['down-video']")
                  a(:href="item.url" target="_blank" rel="noopener noreferrer")
                    | {{ item.url ? 'Video #' + item.id + ' (AWS URL)': 'No hay videos subidos' }}
      v-divider

      v-card-actions
        div(:class="b('buttons')")
          v-btn(
            :loading="requesting"
            color="error"
            type="button"
            text
            @click="onClickDenyButton"
          ).ml-2
            v-icon mdi-clipboard-remove
            span.ml-1 Rechazar
          v-btn(
            :loading="requesting"
            color="warning"
            type="button"
            text
            @click="onClickExceptionButton"
          ).ml-2
            v-icon mdi-clipboard-alert
            span.ml-1 Excepción
          v-btn(
            :loading="requesting"
            color="success"
            type="button"
            text
            @click="onClickApproveButton"
          ).ml-2
            v-icon mdi-clipboard-check
            span.ml-1 Aprobar
          v-btn(
            :loading="requesting"
            color="primary"
            type="submit"
          ).ml-2
            v-icon mdi-clipboard-search
            span.ml-1 Evaluar
</template>

<script>
import moment from 'moment';
import debounce from 'lodash/debounce';
import last from 'lodash/last';
import Config from '@/config';
import basicFormMixin from './basic-form-mixin';
import { APPRAISAL_STATUS } from '@/utils/constants';

import AppraisersSelect from '@/components/inputs/appraisers-select';
import PropertyTypeSelect from '@/components/inputs/property-type-select';
import SpeedSellingStatusSelect from '@/components/inputs/speed-selling-status-select.js'
import DemandStatusSelect from '@/components/inputs/demand-status-select';
import DatePicker from '@/components/inputs/input-date-picker';
import addressInputMixin from '@/mixins/address-input-mixin';
import YesOrNoRadio from '@/components/inputs/yes-or-no-radio';
import AppraisalExceptionSelect from '@/components/inputs/appraisal-exception-select';

export default {
  name: 'appraisal-form',

  mixins: [
    basicFormMixin({
      modelKey: 'appraisal',
      veeValidation: true,
      mapPropsToData() {
        return {
          id: this.appraisal?.id || null,
          status: undefined,
          appraiserId: this.appraisal?.appraiser?.id || null,
          date: this.appraisal?.date || moment().format('YYYY-MM-DD'),
          commercialValue: this.appraisal?.commercialValue || null,
          auctionValue: this.appraisal?.auctionValue || null,
          rebuildValue: this.appraisal?.rebuildValue || null,
          yearOfConstruction: this.appraisal?.yearOfConstruction || null,
          comments: this.appraisal?.comments || null,
          opinionExceptionsIds: this.appraisal?.opinionException?.map(({ id }) => id) || [],
          dangerZone: this.appraisal?.dangerZone || false,
          nobleMaterial: this.appraisal?.nobleMaterial || false,
          essentialServices: this.appraisal?.essentialServices || false,
          itWorksAsSocialBuilding: this.appraisal?.itWorksAsSocialBuilding || false,
          asphaltAndFullSidewalks: this.appraisal?.asphaltAndFullSidewalks || false,
          hillside: this.appraisal?.hillside || false,
          propertyTypeId: this.appraisal?.propertyTypeId || null,
          registrationAddress: this.appraisal?.registrationAddress || null,
          municipalAddress: this.appraisal?.municipalAddress || null,
          latitude: this.appraisal?.latitude || null,
          longitude: this.appraisal?.longitude || null,
          landArea: this.appraisal?.landArea || null,
          speedSelling: this.appraisal?.speedSelling || null,
          demand: this.appraisal?.demand || null,
          constructionArea: this.appraisal?.constructionArea || null,
          currencyChange: this.appraisal?.currencyChange || null,
          address: {
            location: this.appraisal?.registrationLocation || [],
            _locationIds: this.appraisal?.registrationLocation?.length ? [...this.appraisal.registrationLocation].map(({ id }) => id) : ['1'],
          },
          registrationNumber: this.appraisal?.registrationNumber || null,
        };
      },

      parseSubmit() {
        const { address, ...data } = this.data;

        return {
          appraisal: {
            ...data,
            commercialValue: data.commercialValue || null,
            auctionValue: data.auctionValue || null,
            rebuildValue: data.rebuildValue || null,
            currencyChange: data.currencyChange || null,
            yearOfConstruction: data.yearOfConstruction || null,
            constructionArea: data.constructionArea || null,
            landArea: data.landArea || null,
            speedSelling: data.speedSelling || null,
            demand: data.demand || null,
            registrationAddress: data.registrationAddress || null,
            municipalAddress: data.municipalAddress || null,
            latitude: data.latitude || null,
            longitude: data.longitude || null,
            registrationAddressDistrictId: last(address._locationIds),
            videoDriveUrls: this.data.videoDriveUrls,
          },
        };
      },

    }),

    addressInputMixin(),
  ],

  data() {
    return {
      itemURL:[], 
      itemDrive: this.appraisal?.videoUrl ? this.appraisal.videoUrl.map(item => ({ id: item.id, url: item.drive })) : [], 
      itemAws: this.appraisal?.videoUrl ? this.appraisal.videoUrl.map(item => ({ id: item.id, url: item.presigned })) : [],
      data:{
        videoDriveUrls:null, //videoDriveUrls que inicialice con null
      },
      mapsUrl: null,
      currency: Config.CURRENCIES_SELECT[0],
      commercialityProperty: this.appraisal?.commercialities?.commercialityProperty || '-' ,
      auctionPercentage: this.appraisal?.commercialities?.commercialityTotauctionPercentagealResult || 0,
      scoreProperty: this.appraisal?.commercialities?.scoreProperty || 0 , 
    };
  },

  computed: {
    rules() {
      return Config.APPRAISAL_FORM_RULES({ data: this.data });
    },

    appraisersDefaultItems() {
      const { appraiser } = this.appraisal || {};

      return appraiser ?
        [{ id: appraiser.id, name: appraiser.name }] :
        [];
    },

    opinionExceptionDefaultItems() {
      const { opinionException } = this.appraisal || {};
      return opinionException ? opinionException.map(({ id, name }) => ({ id, name })) : [];
    },

    datePickerMax() {
      return moment().format();
    },

  },

  methods: {
    onChangeCoordinates: debounce(function () {
      const { longitude, latitude } = this.data || {};

      this.mapsUrl = (longitude && latitude) ?
        `https://maps.google.com/maps?key=${Config.GOOGLE_MAPS_API_KEY}&q=${latitude},${longitude}&z=15&output=embed` :
        null;

    }, 2000),

    addItems() {
      this.itemURL.push({ id:null, url: '' });
    },
 
    addItemsURL() {
     this.data.videoDriveUrls = this.itemURL.length > 0 ? this.itemURL.filter(item => item.url !== '').map( item => ({ id:item.id, url:item.url.trim() }) ) : null;
    },

    removeVideoUrl(i) {
      this.itemURL.splice(i, 1);
    },
    
    removeItems() {
      this.itemURL = [];
    },

    onClickApproveButton() {
      this.data.status = APPRAISAL_STATUS.APPROVED;
      this.onSubmit();
    },

    onClickDenyButton() {
      this.data.status = APPRAISAL_STATUS.DENIED;
      this.onSubmit();
    },

    onClickExceptionButton() {
      this.data.status = APPRAISAL_STATUS.EXCEPTION;
      this.onSubmit();
    },
  
  },

  mounted() {
    this.onChangeCoordinates();
    this.addItemsURL();
    this.initializeQuestions();
  },

  watch: {
    'data.latitude'() {
      this.$nextTick(() => {
        this.onChangeCoordinates();
      });
    },

    'data.longitude'() {
      this.$nextTick(() => {
        this.onChangeCoordinates();
      });
    },
    itemURL: {
      handler() {
        this.addItemsURL();
      },
      deep: true
    },
  },

  components: {
    AppraisersSelect,
    DatePicker,
    PropertyTypeSelect,
    SpeedSellingStatusSelect,
    DemandStatusSelect,
    YesOrNoRadio,
    AppraisalExceptionSelect,
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .appraisal-form
    &__maps-container
      background-color: darken(white, 3%)

    &__buttons
      width: 100%
      & > *
        width: 100%
        margin-bottom: 4px

    .mod-url 
      &.px-1 
        display: flex
        align-items: center
        margin-bottom: 4px
        & > input 
          width: 20%
          margin-right: 4px
        
    +md
      &__buttons
        display: flex
        justify-content: flex-end

        & > *
          width: auto
          margin-bottom: 0
</style>
