<template lang="pug">
  div(:class="b()")
    div(
      v-for="label, idx in labels"
      :key="idx"
      :class="b('label', { [label.size]: true })"
      :style="{ backgroundColor: label.color }"
    ) {{ label.title }}
</template>

<script>
  import Config from '@/config';

  export default {
    name: 'funnel-labels',

    data() {
      return {
        labels: Config.CRM_FUNNEL_LABELS,
      };
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  $gap: 4px
  $arrowSize: 15px

  @mixin arrow-clip($labelWidth)
    clip-path: polygon($labelWidth - $arrowSize 0%, 100% 50%, $labelWidth - $arrowSize 100%, 0% 100%, $arrowSize 50%, 0% 0%)

  @mixin label-sizer($size)
    width: $size
    +arrow-clip($size)

    &--2
      $width: ($size + $gap) * 2

      width: $width
      +arrow-clip($width)


  .funnel-labels
    $c: &

    display: flex

    &__label
      $height: 34px

      flex-shrink: 0
      height: $height
      margin: 0 $gap
      color: white

      font-size: 16px
      font-weight: 500
      text-align: center
      line-height: 32px

      background-color: darken(white, 6%)

      +label-sizer(280px)


    +sm
      #{$c}__label
        +label-sizer(320px)
</style>
