<template lang="pug">
  div(:class="b()")
    deal-select(
      label="Buscar negociación"
      placeholder="Nombre del cliente"
      @input="onInput"
      return-object
    )
</template>

<script>
  import DealSelect from './deal-invertible-select';

  export default {
    name: 'deal-form-view',

    components: { DealSelect },

    methods: {
      onInput(deal) {
        this.$emit('submit', { deal });
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>