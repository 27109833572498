<template lang="pug">
  div.sentinel-indicators
    div.indicator(v-for='indicator in indicators')
      v-icon {{ getImageByIndicator(indicator?.indicator) }}
      span.text.ml-1 {{ indicator?.indicator }}
</template>

<script>
export default {
  name: 'sentinel-indicators',

  props: {
    indicators: { type: Array, default: () => [] },
  },

  methods: {
    getImageByIndicator(text) {
      switch (text) {
        case 'Crédito Hipotecario':
          return 'mdi-home';
        case 'Crédito Vehicular':
          return 'mdi-car';
        case 'Línea de Crédito':
          return 'mdi-cash-multiple';
        case 'Es Aval':
          return 'mdi-account-check';
        case 'Está Avalado':
          return 'mdi-account-alert';
        case 'Representante Legal':
          return 'mdi-account-tie';
        default:
          return 'mdi-alert';
      }
    },
  },

  computed: {},

  components: {},
};
</script>

<style lang="sass" scoped>
.sentinel-indicators
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.indicator
  text-align: centered
  min-width: 20%
  margin: 4px
  padding: 4px

.text
  margin-left: 4px
  margin-top: 4px

</style>