<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
  import CustomerDataFormModal from '@/components/modals/customer-data-form-modal';
  import VBtn from 'vuetify/lib/components/VBtn';
  import VIcon from 'vuetify/lib/components/VIcon';
  import {
    VListItemAvatar,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
  } from 'vuetify/lib/components/VList';
  import $ from 'jquery';
  import basicStringSearch from '@/utils/basic-string-search';
  import debounce from 'lodash/debounce';
  import { UsersSelectQuery } from './queries.gql';
  import { CreateUserFromSelectMutation } from './mutations.gql';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import UserAvatar from '@/components/UI/user-avatar';

  export default {
    name: 'user-select',

    mixins: [
      mutationsHandlersMixin(),
      lazySelectMixin({ itemsKey: 'users' })
    ],

    data() {
      return {
        users: [],
        search: null,
        isCreating: false,
        formModal: false,
      };
    },

    apollo: {
      users: {
        query: UsersSelectQuery,
        fetchPolicy: 'cache-and-network',
        manual: true,

        variables() {
          return {
            search: this.search,
            perPage: this.perPage,
            ...this.additionalQueryVariables,
          };
        },

        result({ data }) {
          this.users = data?.users?.objects || [];
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    props: {
      value: { type: [Object, String, Array] },
      itemValue: { type: String, default: 'id' },
      fetching: { type: Boolean, default: false },
      additionalQueryVariables: { type: Object, default: () => ({}) },
      perPage: { type: Number, default: 10 },
      multiple: { type: Boolean, default: false },
      createHiddenFields: { type: Array, default: () => [] },
      createAdditionalVariables: { type: Object },
      creatable: { type: Boolean, default: false },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      onCreateUser(user) {
        this.$_lazySelectMixin_addInnerItem({
          ...user,
          firstName: '<nuevo cliente>',
        });

        if(this.multiple) {
          this.$emit('input', [...this.value, this.returnObject ? user : user[this.itemValue]])
        } else {
          this.$emit('input', this.returnObject ? user : user[this.itemValue]);
        }

        this.formModal = false;
      },

      onSubmitCreateUser({ customer }) {
        this.handleCreateMutation({
          mutation: CreateUserFromSelectMutation,
          requestingStateKey: 'isCreating',

          variables: {
            data: {
              ...customer,
              ...this.createAdditionalVariables,
            }
          },
        }).then(({ data }) => {
          const user = data.createUser.user;

          this.onCreateUser(user);
        });
      },

      onInput(selected) {
        this.lazyItems.filter(
          (user) => (Array.isArray(selected) ? selected : [selected]).includes(user.id)
        ).map(this.$_lazySelectMixin_addInnerItem);

        this.$emit('input', selected);
      },

      searchFn({email, firstName, lastName, vat}, query) {
        return basicStringSearch([email, firstName, lastName, vat], query);
      },

      focus() {
        const input = this.$el.querySelector('input');

        $(input).click();
      },
    },

    render(h) {
      return h(
        'div',
        {
          class: 'd-flex align-center',
        },
        [
          h(VAutocomplete, {
            class: [this.b(), 'flex-grow-1'],

            props: {
              ...this.$attrs,
              ...this.$props,
              items: this.lazyItems,
              filter: this.searchFn,
              loading: this.$apollo.queries.users.loading,
              /**
               * Vuetify Bug: hay un un bug extraño en vuetify en donde, si el v-autocomplete no tiene la propiedad multiple
               * y se le asigna funcion itemText el evento update:search-input retorna null y te borra la busqueda que se
               * quiere realizar
               */
              itemText: this.multiple ? (selected) => `${selected.firstName} ${selected.lastName}` : undefined,
              chips: this.multiple,
            },

            on: {
              ...this.$listeners,
              input: this.onInput,
              'update:search-input': this.onChangeSearch,
              focus: this.onFocus,
            },

            scopedSlots: {
              /**
               * Vuetify Bug: Habilitamos el nodo hijo selection para los casos que no son de busqueda multiple de esta
               * manera se renderiza correctamente las selecciones y evitamos el bug
               */
              selection: this.multiple ? undefined :data => h('div', `${data.item.firstName} ${data.item.lastName}`),
              prependItem: this.$slots.prependItem,

              item: data => [
                h(VListItemAvatar, [
                  h(UserAvatar,
                    {
                      props: {
                        url: data.item.avatar,
                        firstName: data.item.firstName,
                        lastName: data.item.lastName,
                        size: 42,
                      },
                    },
                  ),
                ]),
                h(VListItemContent, [
                  h(VListItemTitle, `${data.item.firstName} ${data.item.lastName}`),
                  h(VListItemSubtitle, `${data.item.vat} <${data.item.email}>`),
                ]),
              ],
            }
          }),

          this.creatable && h(VBtn, {
            props: { color: 'primary', text: true, disabled: this.disabled },
            on: { click: () => this.formModal = true },
          }, [
            h(VIcon, 'mdi-plus'),
            h('span', ' Nuevo'),
          ]),

          this.creatable && h(CustomerDataFormModal, {
            props: {
              value: this.formModal,
              hiddenFields: this.createHiddenFields,
              requesting: this.isCreating,
            },
            on: {
              input: v => this.formModal = v,
              submit: this.onSubmitCreateUser,
            },
          })
        ]
      );
    },
  };
</script>

<style lang="sass">
  .user-select
    .v-select__selections
      min-height: 32px !important
</style>