<template lang="pug">
validation-observer(ref='form')
  form(@submit.prevent='onSubmit')
    v-layout(wrap)
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='título bloqueo',
          :rules='rules.blockNumber',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model='data.blockNumber',
            :error-messages='errors',
            :disabled='disabledFields.includes("blockNumber")',
            label='Título bloqueo',
            name='título bloqueo'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='ciudad de bloqueo',
          :rules='rules.blockCity',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model='data.blockCity',
            :error-messages='errors',
            :disabled='disabledFields.includes("blockCity")',
            label='Ciudad de bloqueo',
            name='ciudad de bloqueo'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='fecha de bloqueo',
          :rules='rules.blockDate',
          v-slot='{ errors }'
        )
          input-date-picker(
            v-model='data.blockDate',
            :error-messages='errors',
            :disabled='disabledFields.includes("blockDate")',
            label='Fecha de bloqueo',
            name='fecha de bloqueo'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='título hipoteca',
          :rules='rules.mortgageNumber',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model='data.mortgageNumber',
            :error-messages='errors',
            :disabled='disabledFields.includes("mortgageNumber")',
            label='Título hipoteca',
            name='título hipoteca'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='estado de título hipoteca',
          :rules='rules.mortgageNumberStatus',
          v-slot='{ errors }'
        )
          mortgage-number-status-select(
            v-model='data.mortgageNumberStatus',
            :error-messages='errors',
            :disabled='disabledFields.includes("mortgageNumberStatus")',
            label='Estado de título hipoteca',
            name='estado de título hipoteca'
          )
          mortgage-number-status-select(
            v-model='data.mortgageNumberStatus2nd',
            :error-messages='errors',
            :disabled='disabledFields.includes("mortgageNumberStatus2nd")',
            label='Estado de título gravamen a levantar',
            name='estado de título gravamen a levantar'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='fecha de escritura',
          :rules='rules.attestationDate',
          v-slot='{ errors }'
        )
          input-date-picker(
            v-model='data.attestationDate',
            :error-messages='errors',
            :disabled='disabledFields.includes("attestationDate")',
            label='Fecha de escritura',
            name='fecha de escritura'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='notaría',
          :rules='rules.notaryId',
          v-slot='{ errors }'
        )
          notary-select(
            v-model='data.notaryId',
            :error-messages='errors',
            :default-items='notarySelectDefaultItems',
            :disabled='disabledFields.includes("notaryId")',
            label='Notaría',
            name='notaría'
          )
      v-flex.px-1(xs12, sm6, md4)
        validation-provider(
          name='kardex',
          :rules='rules.kardex',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model='data.kardex',
            :error-messages='errors',
            :disabled='disabledFields.includes("kardex")',
            label='Kardex',
            name='kardex'
          )
      v-flex.px-1(xs12, md12)
        validation-provider(
          name='préstamo',
          :rules='rules.investmentProposalId',
          v-slot='{ errors }'
        )
          investment-proposal-select(
            v-model='data.investmentProposalId',
            :error-messages='errors',
            :disabled='disabledFields.includes("investmentProposalId")',
            :disabled-create='investmentProposalSelectDisabledCreate',
            :additional-search-params='investmentProposalSelectAdditionalSearchParams',
            :default-items='investmentProposalSelectDefaultItems',
            :create-hidden-fields='["propertyValue", "propertyAuctionValue"]',
            :create-additional-variables='investmentProposalCreateAdditionalVariables',
            label='Préstamo',
            :lazy='false',
            creatable
          )
          v-alert.mt-2(
            v-if='disabledFields.includes("investmentProposalId")',
            type='info',
            dense,
            text
          ) Debe tener una tasación y un estudio de título en estado Aprobado o Excepcion para modificar el préstamo
    v-divider
    v-card-actions
      v-spacer
      v-btn(color='error', text, @click='onCancel') Cancelar
      v-btn(:loading='requesting', color='primary', text, type='submit') Guardar
</template>

<script>
import Config from '@/config';
import NotarySelect from '@/components/inputs/notary-select';
import InputDatePicker from '@/components/inputs/input-date-picker';
import MortgageNumberStatusSelect from '@/components/inputs/mortgage-number-status-select';
import InvestmentProposalSelect from '@/components/inputs/investment-proposal-select';

import basicFormMixin from './basic-form-mixin';
import { MORTGAGE_NUMBER_STATUS } from '@/utils/constants';

export default {
  name: 'formalization-form',

  props: {
    investmentProposalSelectAdditionalSearchParams: { type: Object },
    approvedAppraisal: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'formalization',
      veeValidation: true,

      mapPropsToData() {
        return {
          id: this.formalization?.id || null,
          blockNumber: this.formalization?.blockNumber || null,
          blockCity: this.formalization?.blockCity || null,
          blockDate: this.formalization?.blockDate || null,
          mortgageNumber: this.formalization?.mortgageNumber || null,
          mortgageNumberStatus:
            this.formalization?.mortgageNumberStatus ||
            MORTGAGE_NUMBER_STATUS.PENDING,
          mortgageNumberStatus2nd:
            this.formalization?.mortgageNumberStatus2nd ||
            MORTGAGE_NUMBER_STATUS.PENDING,
          attestationDate: this.formalization?.attestationDate || null,
          notaryId: this.formalization?.notary?.id || null,
          kardex: this.formalization?.kardex || null,
          investmentProposalId: this.formalization?.investmentProposal?.id,
        };
      },
    }),
  ],

  data() {
    return {
      rules: {},
    };
  },

  computed: {
    notarySelectDefaultItems() {
      const { notary } = this.formalization;

      return notary ? [{ ...notary }] : [];
    },

    investmentProposalSelectDefaultItems() {
      const { investmentProposal } = this.formalization;

      return investmentProposal ? [{ ...investmentProposal }] : [];
    },

    investmentProposalCreateAdditionalVariables() {
      return {
        dealId: this.formalization?.deal?.id,
        propertyCurrencyId: Config.DEFAULT_CURRENCY_ID,
        propertyValue: this.approvedAppraisal?.commercialValue,
        propertyAuctionValue: this.approvedAppraisal?.auctionValue,
        propertyInsurableValue: this.approvedAppraisal?.rebuildValue,
        currencyChange: this.approvedAppraisal?.currencyChange,
      };
    },
  },

  methods: {
    investmentProposalSelectDisabledCreate($c) {
      return (
        $c.$apollo.queries.investmentProposals.loading ||
        $c.investmentProposals.length > 0 ||
        this.formalization?.investmentProposal
      );
    },
  },

  components: {
    NotarySelect,
    InputDatePicker,
    MortgageNumberStatusSelect,
    InvestmentProposalSelect,
  },
};
</script>

<style lang="sass" scoped>
</style>
