<template lang="pug">
  div(:class="b()")
    div(:class="b('filters-card')" ref="toolbar")
      filters(
        @submit="onSubmitFilters"
        :fetching="$apollo.queries.investmentProposals.loading"
      )
    v-container(fluid :class="b('body')")
      loading-wrapper(
        v-if="$apollo.queries.investmentProposals.loading || investmentProposalsError"
        :error="investmentProposalsError"
        @retry="onRetryQuery"
      )
      template(v-else-if="investmentProposals && investmentProposals.objects && investmentProposals.objects.length")
        div(:class="b('controls')")
          .d-flex.align-center
            b Entradas:&nbsp;
            per-page-select(
              v-model="perPage"
              :class="b('per-page-select')"
              hide-details
            ).ml-2
          .spacer
          div #[b.mr-1 Resultados: ] {{ investmentProposals.count }} Préstamos
        div(:class="b('table-container')")
          investment-proposals-table(
            :investment-proposals="investmentProposals.objects"
            :totals="investmentProposals.totals"
            :order-by="filters.orderBy"
            :order-flow="filters.orderFlow"
            @order="onOrder"
          )
      v-alert(v-else type="info" outlined) No se han encontrado préstamos con los filtros indicados
      .text-center
        v-pagination(
          v-if="investmentProposals && investmentProposals.pages > 1"
          v-model="currentPage"
          :length="investmentProposals.pages"
          :total-visible="15"
        )
</template>

<script>
  import moment from 'moment';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import PerPageSelect from '@/components/inputs/per-page-select';

  import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

  import { InvestmentListProposalsQuery } from './graphql/queries.gql';
  import InvestmentProposalsTable from './components/investment-proposals-table';
  import Filters from './components/filters';

  const parseMonthYear = data => data
    ? moment(`${data.year}/${data.month}`, 'YYYY/M').format('YYYY-MM-DD')
    : data;

  export default {
    name: 'investment-list-view',

    apollo: {
      investmentProposals: {
        query: InvestmentListProposalsQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          const { 
            condition, 
            monthYearConfirmationDate, 
            monthYearCreationDate, 
            qualificationCompliance,
            qualificationStatus,
            status, 
            typeOfCancellation,
            ...filters 
          } = this.filters;

          return {
            ...filters,
            condition: condition?.length ? condition : undefined,
            monthYearConfirmationDate: parseMonthYear(monthYearConfirmationDate),
            monthYearCreationDate: parseMonthYear(monthYearCreationDate),
            page: this.currentPage,
            perPage: this.perPage,
            qualification: qualificationCompliance?.length ? qualificationCompliance : undefined,
            situation: qualificationStatus?.length ? qualificationStatus : undefined,
            status: status?.length ? status : undefined,
            typeOfCancellation: typeOfCancellation,
          };
        },

        update({ investmentProposals = {} }) {
          return {
            pages: 1,
            objects: [],
            ...investmentProposals,
          };
        },

        error() {
          this.investmentProposalsError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },
    },

    data() {
      return {
        currentPage: 1,
        perPage: 20,
        filters: {
          orderBy: 'disbursement_date',
          orderFlow: 'DESC',
          status:  [INVESTMENT_PROPOSAL_STATUS.COLLECTING],
        },
        proposals: {
          pages: 1,
          objects: [],
        },
        investmentProposalsError: null,
      };
    },

    components: { Filters, InvestmentProposalsTable, LoadingWrapper, PerPageSelect },

    methods: {
      onRetryQuery() {
        this.investmentProposalsError = null;
        this.$apollo.queries.proposals.refetch();
      },

      async onSubmitFilters({ filters }) {
        this.filters = {
          ...this.filters,
          ...filters,
        };
      },

      onOrder({ key, flow }) {
        this.filters.orderBy = key;
        this.filters.orderFlow = flow;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-list-view
    &__table-container
      overflow-x: auto

    &__controls
      padding-bottom: 12px

      +sm
        display: flex
        align-items: center

      & > div
        display: flex
        align-items: center
        padding: 4px 0

    &__filters-card
      padding: 8px 24px
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      background-color: white

      +md
        display: block !important

    &__body
      padding-top: 32px

      +md
        padding-top: 64px

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +md
        display: none

    &__per-page-select
      max-width: 160px
      padding-top: 0
      margin-top: 0
</style>