<template lang="pug">
  div(:class="b()" :style="styles")
    slot
</template>

<script>
  export default {
    name: 'tag',

    props: {
      color: { type: String, required: true },
    },

    computed : {
      styles() {
        return {
          backgroundColor: this.color,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .tag
    display: inline-block
    background-color: darken(white, 30%)
    padding: .2rem .75rem
    border-radius: 20px
    color: white
    white-space: nowrap
</style>