import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import { APP_VERSION } from '@/utils/constants';
import authStorage from '@/utils/auth-storage';
import Config from '@/config';
import AuthEventBus from '@/event-bus/auth-event-bus';

const authLink = setContext((_, { headers }) => {
  const { token } = authStorage.getCredentials() || {}

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
      'X-Client-Version': APP_VERSION,
    }
  }
});

const createClient = ({ uri }) => new ApolloClient({
  link: authLink.concat(createUploadLink({ uri })),
  cache: new InMemoryCache(),
});

// Install the vue plugin
Vue.use(VueApollo)

const client = createClient({ uri: Config.GRAPH_URL });

export const createProvider = () => new VueApollo({
  defaultClient: client,

  errorHandler(error) {

    if([
      'GraphQL error: token no valido',
      'Signature has expired',
      'Signature verification failed',
    ].some(errMsg => error.message.includes(errMsg))) {
      AuthEventBus.$emit('expire');
    }
  },
});

// // Name of the localStorage item
// const AUTH_TOKEN = 'apollo-token'

// // Http endpoint
// const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://betaadmin.rebajatuscuentas.com/graphql'

// // Config
// const defaultOptions = {
//   // You can use `https` for secure connection (recommended in production)
//   httpEndpoint,
//   // You can use `wss` for secure connection (recommended in production)
//   // Use `null` to disable subscriptions
//   wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
//   // LocalStorage token
//   tokenName: AUTH_TOKEN,
//   // Enable Automatic Query persisting with Apollo Engine
//   persisting: false,
//   // Use websockets for everything (no HTTP)
//   // You need to pass a `wsEndpoint` for this to work
//   websocketsOnly: false,
//   // Is being rendered on the server?
//   ssr: false,

//   // Override default apollo link
//   // note: don't override httpLink here, specify httpLink options in the
//   // httpLinkOptions property of defaultOptions.
//   // link: myLink

//   // Override default cache
//   // cache: myCache

//   // Override the way the Authorization header is set
//   // getAuth: (tokenName) => ...

//   // Additional ApolloClient options
//   // apollo: { ... }

//   // Client local data (see apollo-link-state)
//   // clientState: { resolvers: { ... }, defaults: { ... } }
// }

// // Call this in the Vue app file
// export function createProvider (options = {}) {
//   // Create apollo client
//   const { apolloClient, wsClient } = createApolloClient({
//     ...defaultOptions,
//     ...options,
//   })
//   apolloClient.wsClient = wsClient

//   // Create vue apollo provider
//   const apolloProvider = new VueApollo({
//     defaultClient: apolloClient,
//     defaultOptions: {
//       $query: {
//         // fetchPolicy: 'cache-and-network',
//       },
//     },
//     errorHandler (error) {
//       // eslint-disable-next-line no-console
//       console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
//     },
//   })

//   return apolloProvider
// }

// Manually call this when user log in
export async function onLogin () {
  try {
    await client.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

export async function onLogout() {
  authStorage.removeAll();

  try {
    await client.resetStore();
  } catch(e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}
