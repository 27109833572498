<template lang="pug">
  .dashboard-card
    .dashboard-card__header
      .dashboard-card__title Recordatorios
      .dashboard-card__count {{ reminders.length }}
    .dashboard-card__body
      .dashboard-card__scrollable-content
        loading-wrapper(
          v-if="loading || error"
          :error="error"
          @retry="args => $emit('retry', args)"
        )
        reminders-list(
          v-else-if="reminders && reminders.length"
          :class="b('reminders-list')"
          :reminders="reminders"
        )
        v-alert(v-else color="success" :value="true" outlined) Estás al día con tus recordatorios 🎉🎉
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import RemindersList from './reminders-list';

  export default {
    name: 'reminders',

    props: {
      reminders: { type: Array, default: () => [] },
      error: { type: Error },
      loading: { type: Boolean },
    },

    components: {
      LoadingWrapper,
      RemindersList,
    },
  };
</script>

<style lang="sass" scoped>
  .reminders
    &__reminders-list
      max-height: 420px
      overflow-y: auto
</style>