<template lang="pug">
  div(:class="b()")
    requirements(
      v-if="requirements && requirements.length > 0"
      :requirements="requirements"
    ).mb-2
    rci(
      v-if="hasCompletedMinimumRequirements"
      :init-values="values"
      :loading="loading"
      :skipped="skipped"
      :error="error"
      @init="args => $emit('init', args)"
      @retry="args => $emit('retry', args)"
      @change="args => $emit('change-rci', args)"
    )
</template>

<script>
  import Rci from '@/components/UI/rci';
  import Requirements from './requirements';

  export default {
    name: 'rci-tab',

    props: {
      values: { type: Object },
      loading: { type: Boolean, default: false },
      skipped: { type: Boolean, default: false },
      error: { type: Error },
      requirements: { type: Array },
    },

    computed: {
      hasCompletedMinimumRequirements() {
        // Esto es debido a que hay requirements opcionales con los cuales se puede calcular el RCI si cumplirlo
        return this.requirements.filter(({ optional }) => !optional).length === 0;
      },
    },

    components: { Rci, Requirements },
  };
</script>

<style lang="sass" scoped>

</style>