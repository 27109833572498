import { render, staticRenderFns } from "./title-study-form.vue?vue&type=template&id=5cd8d8c8&scoped=true&lang=pug"
import script from "./title-study-form.vue?vue&type=script&lang=js"
export * from "./title-study-form.vue?vue&type=script&lang=js"
import style0 from "./title-study-form.vue?vue&type=style&index=0&id=5cd8d8c8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd8d8c8",
  null
  
)

export default component.exports