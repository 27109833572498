<template lang="pug">
  div(:class="b()")
    div(:class="b('customer-container')")
      workplace-card(v-if="'businessName' in results.client" :workplace="results.client")
      customer-card(v-else :customer="results.client")
    div(:class="b('tabs-container')")
      v-tabs(v-model="currentTab" fixed-tabs)
        v-tab Resumen
        v-tab Historial de Deudas
        v-tab Tarjetas de Crédito
        v-tab Créditos de Consumo
        v-tab Hipotecas
        v-tab Pagos
        v-tab(v-if="results.vehicularCredit.length") Créditos Vehiculares

        v-tab-item.py-3
          results-summary(:results="results")
        v-tab-item.py-3
          historical-debts(:historical-debts="results.historicalDebts")
        v-tab-item.py-3
          credit-cards(:credit-cards="results.creditCards")
        v-tab-item.py-3
          consumer-loan(:credits="results.consumerLoan && results.consumerLoan.history")
        v-tab-item.py-3
          mortgages(:mortgages="results.mortgage && results.mortgage.history")
        v-tab-item.py-3
          payments-behaviour(:behaviour="results.resumePaymentBehavior")
        v-tab-item(v-if="results.vehicularCredit.length").py-3
          vehicular-credits(:credits="results.vehicularCredit")
</template>

<script>
import PeBuroResults from '../pe-buro-results';
import ResultsSummary from './components/results-summary';
import VehicularCredits from './components/vehicular-credits'

export default {
  name: 'pe-full-buro-results',

  block: 'pe-buro-results',

  extends: PeBuroResults,

  props: {
    results: { type: Object },
  },

  components: {
    ResultsSummary,
    VehicularCredits,
  },
};
</script>

<style lang="sass" scoped>

</style>