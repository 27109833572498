<template lang="pug">
  v-app-bar(fixed app clipped-left color="white")
    v-btn(icon @click="$emit('click-menu')")
      v-icon mdi-menu
    img(:src="$projectConfig.COMPANY_LOGO" :class="b('logo')").hidden-xs-only
    img(:src="$projectConfig.COMPANY_LOGO_MINI" :class="b('logo')").hidden-sm-and-up
    v-spacer
    span(:class="getStatusClass(currentUser)" :title="getTooltipContent(currentUser)")
    img(:src="currentUser.organizationLogo" :class="b('logo')").hidden-xs-only(v-if="currentUser && currentUser.organizationLogo")
    v-btn(
      icon
      @click="onClickLogout"
    )
      v-icon mdi-logout
</template>

<script>
import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';

export default {
  name: 'toolbar',

  mixins: [currentUserMixin()],

  methods: {
    onClickLogout() {
      this.$emit('logout');
    },
    getStatusClass(currentUser) {
      const status = currentUser?.leadAssignmentStatus ? 'true' : 'false';
      return this.b(`status_${status}`);
    },
    getTooltipContent(currentUser) {
      return currentUser && currentUser.leadAssignmentStatus ? 'Asignación prendida' : 'Asignación apagada';
    }
  },

  components: {
  },
};
</script>

<style lang="sass" scoped>
  .toolbar
    &__logo
      height: 60%
    &__status_true
      height: 1.4rem
      width: 1.4rem
      margin-right: 1rem
      border-radius: 50%
      background-color: green
    &__status_false
      height: 1.4rem
      width: 1.4rem
      margin-right: 1rem
      border-radius: 50%
      background-color: red
</style>
