<template lang="pug">
  v-expansion-panel
    group-header(
      v-bind="count"
      :title="group.title"
      :under-review="count.UNDER_REVIEW"
      :pending="count.PENDING"
      :approved="count.APPROVED"
      :refused="count.REFUSED"
      :count="group.documents.length"
    )
    v-expansion-panel-content.pl-3
      template(v-for="document, i in group.documents")
        document(
          :document="document"
          :gestionable="gestionable"
          @update="args => $emit('update', args)"
          @delete="args => $emit('delete', args)"
          @upload-attachments="args => $emit('upload-attachments', args)"
          @delete-attachment="args => $emit('delete-attachment', args)"
        )
        v-divider(v-if="i + 1 < group.documents.length").my-3
</template>

<script>
  import Document from './documents-row';
  import GroupHeader from './documents-group-header';

  const DEFAULT_STATUS_COUNT = { PENDING: 0, under_review: 0, APPROVED: 0, REFUSED: 0};

  export default {
    name: 'documents-group',

    props: {
      group: { type: Object, required: true },
      gestionable: { type: Boolean },
    },

    computed: {
      count() {
        return this.group.documents.reduce(
          (count, doc) => ({
            ...count,
            [doc.status]: (count[doc.status] || 0) + 1,
          }),
          DEFAULT_STATUS_COUNT,
        );
      },
    },

    components: { Document, GroupHeader },
  };
</script>

<style lang="sass" scoped>
  .documents-group
    box-shadow: none

    &__title
      display: flex
      align-items: center
      font-weight: bold
      font-size: 1.2rem
      margin-bottom: 1rem
</style>
