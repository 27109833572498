<template lang="pug">
  v-dialog(
    v-model="dialog"
    persistent
    width="300px"
  )
    template(v-slot:activator="{ on }")
      v-text-field(
        :value="internalValue"
        :label="label"
        :prepend-icon="prependIcon"
        :error-messages="errorMessages"
        readonly
        v-on="on"
      )
    v-time-picker(:value="internalValue" @input="onChange")
      v-spacer
      v-btn(color="red" text @click="dialog = false") Cancelar
      v-btn(color="primary" text @click="onOk") OK
</template>

<script>
  export default {
    name: 'input-date-picker',

    props: {
      label: { type: String },
      value: { type: String },
      prependIcon: { type: String },
      errorMessages: { type: Array, default: null },
    },

    data() {
      return {
        dialog: false,
        internalValue: this.value || null,
      };
    },

    methods: {
      onChange(v) {
        this.internalValue = v;
      },

      onOk() {
        this.dialog = false;
        this.$emit('input', this.internalValue);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>