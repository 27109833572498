<template lang="pug">
  div(:class="b()")
    requirements(
      v-if="requirements && requirements.length > 0"
      :requirements="requirements"
    )
    buro(
      v-else
      :buro="buro"
      :loading="loading"
      :error="error"
      @init="args => $emit('init', args)"
      @retry="args => $emit('retry', args)"
    )
</template>

<script>
  import Buro from '@/components/UI/buro';
  import Requirements from './requirements';

  export default {
    name: 'buro-tab',

    props: {
      buro: { type: Object },
      loading: { type: Boolean },
      error: { type: Error },
      requirements: { type: Array },
    },

    components: { Buro, Requirements },
  };
</script>

<style lang="sass" scoped>

</style>