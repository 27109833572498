<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).py-1
          validation-provider(
            name='Fecha de liquidación',
            :rules='rules.date',
            v-slot='{ errors }'
          )
            date-picker(
              v-model='data.date',
              :error-messages='errors',
              :max="rules.date.before"
              :min="rules.date.after"
              label='Fecha de liquidación',
              name='Fecha de liquidación'
              clearable
            )
        v-flex(xs12).py-1
          validation-provider(name="Costos procesales" :rules="rules.legalExpenses" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.legalExpenses"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="Costos procesales"
              label="Costos procesales"
              step="0.01"
            )
        v-flex(xs12).py-1
          validation-provider(name="Costas procesales" :rules="rules.collectingExpenses" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.collectingExpenses"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="Costas procesales"
              label="Costas procesales"
              step="0.01"
            )
        v-flex(xs12).py-1
          validation-provider(name="Penalidad por Judicialización" :rules="rules.judicializedPenalty" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.judicializedPenalty"
              :error-messages="errors"
              :prefix="currency.symbol"
              type="number"
              name="Penalidad por Judicialización"
              label="Penalidad por Judicialización"
              step="0.01"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Liquidar
</template>

<script>
import moment from 'moment';
import DatePicker from '@/components/inputs/input-date-picker';
import basicFormMixin from './basic-form-mixin';

export default {
  name: 'investment-settlement-form',

  props: {
    currency: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'settlement',
      veeValidation: true,

      mapPropsToData({ settlement }) {
        return {
          date: settlement?.date,
          legalExpenses: settlement?.legalExpenses,
          collectingExpenses: settlement?.collectingExpenses,
          judicializedPenalty: settlement?.judicializedPenalty,
        };
      },

      parseSubmit() {
        return {
          settlement: {
            date: this.data.date || null,
            legalExpenses: this.data.legalExpenses || null,
            collectingExpenses: this.data.collectingExpenses || null,
            judicializedPenalty: this.data.judicializedPenalty || null,
          },
        };
      },
    }),
  ],

  computed: {
    rules() {
      return {
        date: {
          after: moment().format('YYYY-MM-DD'),
          before: moment().add(6, 'months').format('YYYY-MM-DD'),
        },
        legalExpenses: { min_value: 0 },
        collectingExpenses: { min_value: 0 },
        judicializedPenalty: { min_value: 0 },
      };
    },
  },

  components: { DatePicker },
};
</script>

<style lang="sass" scoped></style>
