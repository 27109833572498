<template lang="pug">
  v-avatar(
    :size="size"
    :title="firstName + ' ' + lastName"
    :left="left"
  )
    img(v-if="url" :src="url")
    div(v-else :class="b('initials')" :style="initialsStyle") {{ initials }}
</template>

<script>
  import compact from 'lodash/compact';

  export default {
    name: 'user-avatar',

    props: {
      firstName: { type: String },
      lastName: { type: String },
      url: { type: String  },
      size: { type: Number, default: 48 },
      left: { type: Boolean, default: false },
    },

    computed: {
      initials() {
        return compact([
          (this.firstName || '')[0],
          (this.lastName || '')[0]
        ]).join('');
      },

      initialsStyle() {
        return {
          fontSize: `${Math.round(this.size / 3)}px`,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .user-avatar
    &__initials
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 100%

      background-color: #4380f5
      color: white
      border-radius: 50%
      text-transform: uppercase
      text-align: center
      letter-spacing: 1px

      img
        border-radius: 50%
</style>