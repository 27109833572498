<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Periodo
            th Banco
            th Valor
            th Inscrita
            th Mi Vivienda
        tbody
          tr(v-for="mortgage, idx in mortgages" :key="idx")
            td {{ mortgage.period | formatPeriod }}
            td {{ mortgage.bank }}
            td.text-no-wrap S/ {{ mortgage.value | formatNumber }}
            td
              v-icon(v-if="mortgage.status === 'CONHIPINSCR'" color="green") mdi-check
              v-icon(v-else color="red") mdi-close
            td
              v-icon(v-if="mortgage.miVivienda" color="green") mdi-check
              v-icon(v-else color="red") mdi-close
</template>

<script>
  import formatPeriod from '../../filters/format-period';

  export default {
    name: 'mortgages-table',

    props: {
      mortgages: { type: Array, default: () => [] },
    },

    filters: { formatPeriod },
  };
</script>

<style lang="sass" scoped>
  .mortgages-table
    table
      text-align: center

    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>