<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import VBtn from 'vuetify/lib/components/VBtn';
  import VIcon from 'vuetify/lib/components/VIcon';
  import pick from 'lodash/pick';
  import debounce from 'lodash/debounce';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import DebtorFormModal from '@/components/modals/debtor-form-modal';
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';

  import { DebtorSelectQuery } from './graphql/queries.gql';
  import { CreateDebtorFromSelectMutation } from './graphql/mutations.gql';
  import DebtorCard from './components/debtor-card';

  export default {
    name: 'debtor-select',

    mixins: [
      mutationsHandlersMixin(),
      lazySelectMixin({ itemsKey: 'debtors' })
    ],

    props: {
      additionalSearchParams: { type: Object, default: () => ({}) },
      itemValue: { type: String, default: 'id' },
      createHiddenFields: { type: Array, default: () => [] },
      createAdditionalVariables: { type: Object },
      disabled: { type: Boolean },
      creatable: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
    },

    data() {
      return {
        debtors: [],
        isCreating: false,
        formModal: false,
        search: null,
      };
    },

    apollo: {
      debtors: {
        query: DebtorSelectQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            ...this.additionalSearchParams,
            q: this.search,
          };
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      onCreateDebtor(debtor) {
        this.$_lazySelectMixin_addInnerItem(debtor);

        this.$emit('input', this.returnObject ? debtor : debtor[this.itemValue]);

        this.formModal = false;
      },

      onSubmitCreateDebtor({ debtor }) {
        this.handleCreateMutation({
          mutation: CreateDebtorFromSelectMutation,
          requestingStateKey: 'isCreating',

          variables: {
            data: {
              ...pick(debtor, [
                'vatType',
                'vat',
                'address',
                'districtId',
              ]),
              ...this.createAdditionalVariables,
            }
          },
        }).then(({ data }) => {
          const debtor = data.createDebtor.debtor;

          this.onCreateDebtor(debtor);
        });
      },
    },

    render(h) {
      return h(
        'div',
        {
          class: 'd-flex align-center',
        },
        [
          h(VAutocomplete, {
            class: [this.b(), 'flex-grow-1'],

            props: {
              ...this.$attrs,
              ...this.$props,
              items: this.lazyItems,
              filter: this.searchFn,
              loading: this.$apollo.queries.debtors.loading,
              noFilter: true,
            },

            on: {
              ...this.$listeners,
              'update:search-input': this.onChangeSearch,
              focus: this.onFocus,
            },

            scopedSlots: {
              selection: data => h(DebtorCard, { props: { debtor: data.item }}),
              item: data => h(DebtorCard, { props: { debtor: data.item }}),
            }
          }),

          this.creatable && h(VBtn, {
            props: { color: 'primary', text: true, disabled: this.disabled },
            on: { click: () => this.formModal = true },
          }, [
            h(VIcon, 'mdi-plus'),
            h('span', ' Nuevo'),
          ]),

          this.creatable && h(DebtorFormModal, {
            props: {
              value: this.formModal,
              hiddenFields: this.createHiddenFields,
              requesting: this.isCreating,
            },
            on: {
              input: v => this.formModal = v,
              submit: this.onSubmitCreateDebtor,
            },
          })
        ]
      );
    },
  };
</script>