import { render, staticRenderFns } from "./borrowers-aums-table-head.vue?vue&type=template&id=1df8e038&scoped=true&lang=pug"
import script from "./borrowers-aums-table-head.vue?vue&type=script&lang=js"
export * from "./borrowers-aums-table-head.vue?vue&type=script&lang=js"
import style0 from "./borrowers-aums-table-head.vue?vue&type=style&index=0&id=1df8e038&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df8e038",
  null
  
)

export default component.exports