<template lang="pug">
  validation-observer(ref="form")
    div(:class="b()")
      div(:class="b('avatar')")
        user-avatar(:first-name="currentUser.firstName" :last-name="currentUser.lastName" :url="currentUser.avatar")
      div(:class="b('body')")
        validation-provider(name="mensaje" :rules="'required'" v-slot="{ errors }")
          rich-textarea(
            ref="richTextarea"
            v-model="body"
            :error-messages="errors"
            :disabled="requesting"
            :mention-groups-ids="mentionGroupsIds"
            name="mensaje"
            placeholder="Escribe tu mensaje..."
          )
        div(:class="b('buttons')").pt-2
          v-btn(@click="onSubmit" :disabled="requesting" color="primary") Comentar
          slot(name="actions")
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';
  import RichTextarea from '@/components/inputs/rich-textarea';

  export default {
    name: 'message-form',

    data() {
      return {
        body: '',
      };
    },

    props: {
      requesting: { type: Boolean, default: false },
      currentUser: { type: Object, required: true },
      mentionGroupsIds: { type: Array },
    },

    methods: {
      clearForm() {
        this.body = '';
        this.$refs.richTextarea.clear();

        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      },

      async onSubmit() {
        try {
          const success = await this.$refs.form.validate();

          if(!success) return;

          this.$emit('submit', { body: this.body });
        } catch {
          return;
        }
      },
    },

    components: { UserAvatar, RichTextarea },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .message-form
    display: flex

    &__avatar
      margin-right: 1rem

    &__body
      flex: 1

    &__buttons
      +sm
        display: flex

      & > *
        width: 100%
        margin-bottom: 4px

        +sm
          width: auto
          margin-right: 8px !important
</style>

<style lang="sass">
  .message-form
    .v-textarea
      margin: 0
      padding: 0
</style>
