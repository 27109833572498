import $update from 'immutability-helper';
import iziToast from 'izitoast';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

const buildToastedMutationHandler = function({
  toastErrors = true
} = {}) {
  return async function (config) {
    if(config.requestingStateKey) set(this, config.requestingStateKey, true);

    try {
      const results = await this.handleMutation(config);

      if(toastErrors) {
        const mutationName = config.mutationName || Object.keys(results.data)[0];
        const errors = results.data[mutationName]?.errors;

        if(!isEmpty(errors)) {
          const messages = errors.map(({ argument, message }) => `<b>${argument}</b>: ${message}</br>`);

          iziToast.error({ message: messages });

          return Promise.reject(errors);
        }
      }

      if(config.successMessage !== null)
        iziToast.success({ message: config.successMessage || 'Datos actualizados correctamente' });

      return Promise.resolve(results);
    } catch(e) {
      window.console.error(e);

      const errors = e?.graphQLErrors ?
        e?.graphQLErrors.map(({ message }) => message) :
        ['Ha ocurrido un error desconocido'];

      const message = errors.map(error => `${error}</br>`);

      iziToast.error({ title: 'ERROR!', message });

      return Promise.reject(errors);
    } finally {
      if(config.requestingStateKey) set(this, config.requestingStateKey, false);
    }
  }
};

const mutationsHandlersMixin = () => ({
  methods: {
    async handleMutation({
      client,
      mutation,
      variables,
      query,
      updateQueryFn,
      update,
    }) {
      const options = { client, mutation, variables, update };

      if (updateQueryFn && query) {
        options.update = (store, { data }) => {
          const cachedQuery = store.readQuery(query);
          const updateQueryFnResults = updateQueryFn({ resultsData: data, cachedQuery });

          if(!updateQueryFnResults) return;

          store.writeQuery({
            ...query,
            data: $update(cachedQuery, updateQueryFnResults),
          });
        }
      }

      return this.$apollo.mutate(options);
    },

    handleDeleteMutation: buildToastedMutationHandler(),

    handleUpdateMutation: buildToastedMutationHandler(),

    handleCreateMutation: buildToastedMutationHandler(),
  }
});

export default mutationsHandlersMixin;
