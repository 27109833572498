<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 md4).px-1
          validation-provider(name="tipo de documento" :rules="rules.vatType" v-slot="{ errors }")
            vat-type-select(
              v-model="data.vatType"
              :error-messages="errors"
              name="tipo de documento"
              label="Tipo de documento"
            )
        v-flex(xs12 md8).px-1
          validation-provider(name="número de documento" :rules="rules.vat" v-slot="{ errors }")
            v-text-field(
              v-model="data.vat"
              :error-messages="errors"
              name="número de documento"
              label="Número de documento"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
import VatTypeSelect from '@/components/inputs/equifax-vat-type-select';

import basicFormMixin from './basic-form-mixin';

export default {
  name: 'debtor-form',

  mixins: [
    basicFormMixin({
      modelKey: 'debtor',
      veeValidation: true,
      mapPropsToData() {
        return {
          id: this.debtor?.id || null,
          vat: this.debtor?.vat || null,
          vatType: this.debtor?.vatType || 'DNI'
        };
      },

      parseSubmit() {
        const { ...data } = this.data;

        return {
          debtor: {
            ...data,
          },
        };
      },
    }),
  ],

  data() {
    return {
      rules: {
        vat: { required: true },
        vatType: { required: true },
      },
    };
  },

  components: { VatTypeSelect },
};
</script>

<style lang="sass" scoped>

</style>
