<template lang="pug">
  div(:class="b()")
    div(:class="b('payment-column')")
      v-layout(wrap)
        v-flex(md12)
          div(:class="b('data')")
            div(:class="b('data-title')") {{ proposal.debtAmount | formatCurrency(currency, { precision: 0 }) }}
            div(:class="b('data-description')") Crédito

    div(:class="b('body-column')")
      v-layout(wrap)
        v-flex(md4)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.propertyValue | formatCurrency(currency, { precision: 0 }) }}
            div(:class="b('small-data-description')") Valor comercial propiedad
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.propertyInsurableValue | formatCurrency(currency, { precision: 0 }) }}
            div(:class="b('small-data-description')") Valor Asegurable de la propiedad
        v-flex(md4)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.penaltyFee | formatCurrency(currency) }}
            div(:class="b('small-data-description')") Penalidad diaria
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.defaultRate }}%
            div(:class="b('small-data-description')") Tasa moratoria
        v-flex(md4)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.debtAmount | formatCurrency(currency) }}
            div(:class="b('small-data-description')") Crédito
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.quotesQuantity }}
            div(:class="b('small-data-description')") Cuotas
        v-flex(xs12)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ proposal.disbursementDate | formatDate }}
            div(:class="b('small-data-description')") Fecha de desembolso
    div(:class="b('credit-column')")
      div(:class="b('data')")
        div(:class="b('data-title')") {{ proposal.pendingDebtBorrower | formatCurrency(currency) }}
        div(:class="b('data-description')") Saldo Capital
        div(:class="b('type-cancellation')") {{ proposal?.typeOfCancellation?.description || proposal?.typeOfCancellation?.status || 'N/A' }}
        div(:class="b('data-description')") Tipo Cancelación
    div(:class="b('status-label')" :style="{ backgroundColor: statusColor }") {{ $t(`investmentProposalStatus.${proposal.status}`)}}
      span(:style="{ backgroundColor: getColorOfQualificationByCompliance }") {{ getTextOfQualificationByCompliance }}
    div(:class="b('qualification-status-label')")
      qualification-tag(:status="proposal?.qualificationStatus?.status || { description: 'N/A' }")
    div(v-if="proposal?.extended" :class="b('extended-label')") 
      situation-tag(:status="proposal?.extended", :name="'EXTENDED'")
    div(v-if="proposal?.refinanced" :class="b('refinanced-label')")
      situation-tag(:status="proposal?.refinanced", :name="'REFINANCED'")
    div(v-if="proposal?.judicialized" :class="b('judicialized-label')")
      situation-tag(:status="proposal?.judicialized", :name="'JUDICIALIZED'")
</template>

<script>
import getStatusColor from '@/utils/investment-proposal-status-color';
import StatusTag from '@/components/UI/investment-proposal-status-tag';
import QualificationTag from '@/components/UI/investment-proposal-qualification-tag';
import SituationTag from '@/components/UI/investment-proposal-situation-tag';

export default {
  name: 'investment-proposal-card',

  props: {
    proposal: { 
      type: Object, 
      required: true,         
      default: () => ({ description: 'N/A' })
    },
    currency: { type: Object },
  },

  computed: {
    statusColor() {
      return getStatusColor(this.proposal.status);
    },

    getTextOfQualificationByCompliance(){
      const values = {
        NOR: 'NOR',
        CPP: 'CPP',
        DEF: "DEF",
        DUD: "DUD",
        PER: "PER",
      }
      return values[this.proposal.qualificationByCompliance?.description] || 'N/A';
    },

    getColorOfQualificationByCompliance() {
      const colors = {
        NOR: '#00B050',
        CPP: '#92D051',
        DEF: '#FFCC02',
        DUD: '#FF6600',
        PER: '#C00000',
      }
      return colors[this.proposal.qualificationByCompliance?.description] || '#C0C0C0';
    },
  },

  components: { StatusTag, QualificationTag, SituationTag },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposal-card
    position: relative
    padding: 16px
    overflow: hidden
    border-radius: 4px
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

    &__status-label
      position: absolute
      top: 0
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

      span
        margin: 16px
        padding: 0px 8px
        border-radius: 8px

    &__qualification-status-label
      position: absolute
      top: 24px
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

      span
        margin: 16px
        padding: 0px 8px
        border-radius: 8px
    
    &__extended-label
      position: absolute
      top: 120px
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

      span
        margin: 16px
        padding: 0px 8px
        border-radius: 8px

    &__refinanced-label
      position: absolute
      top: 140px
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

      span
        margin: 16px
        padding: 0px 8px
        border-radius: 8px

    &__judicialized-label
      position: absolute
      top: 160px
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

      span
        margin: 16px
        padding: 0px 8px
        border-radius: 8px

    &__dates
      padding-bottom: 18px

    &__credit
      display: flex

    &__data
      text-align: center

      &-title
        font-size: 30px
        font-weight: 500
        white-space: nowrap

      &-description
        font-size: 13px

    &__small-data
      text-align: center
      padding: 3px 8px
      margin-top: 4px

      &-title
        font-size: 24px
        font-weight: 500
        white-space: nowrap

      &-description
        font-size: 13px

    &__type-cancellation
      font-size: 14px  
      font-weight: 500
      margin-top: 8px
      white-space: normal  
      word-wrap: break-word 
      text-align: center

    +md
      display: flex
      padding: 0

      &__payment-column, &__credit-column
        display: flex
        align-items: center
        padding-left: 16px
        padding-right: 16px
        background-color: $cloudyColor
        min-height: 185px

      &__body-column
        flex: 1
        padding: 8px 16px

      &__data
        padding: 6px 16px

        &-title
          font-size: 26px

        &-description
          white-space: nowrap

      &__small-data
        padding: 3px 8px

        &-title
          font-size: 18px
          line-height: 1

        &-description
          font-size: 12px

      &__type-cancellation
        max-width: 200px 

    +lg
      &__data
        &-title
          font-size: 28px

          &--small
            font-size: 21px
</style>
