<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Ventas mensuales:
    div(:class="b('container')")
      div(
        v-for="month, idx in months"
        :key="idx"
        :class="b('item')"
      )
        div(:class="b('item-date')") {{ month.date | formatDate('MMMM YYYY') }}
        div(:class="b('item-amount')") {{ month.amount | formatCurrency(undefined, { precision: 0 }) }}
</template>

<script>
export default {
  name: 'incomes-form-step-column-by-month',

  props: {
    months: { type: Array, default: () => [] },
  }
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .incomes-form-step-column-by-month
    &__title
      font-weight: bold

    &__container
      padding-top: 4px
      padding-left: 18px

    &__item
      &-amount
        text-align: center
        font-size: 18px
        font-weight: 500

    +sm
      &__item
        display: flex
        align-items: center

        &-date
          margin-right: 16px

        &-amount
          text-align: left

</style>