<template lang="pug">
  v-container(fluid :class="b()")
    v-layout(wrap)
      v-flex(xs12 lg5 xl4).pa-2
        reminders(
          :reminders="reminders"
          :error="remindersError"
          :loading="$apollo.queries.reminders.loading"
          @retry="onRetryReminders"
        ).mb-4
        deals-without-activity(
          :deals="dealsWithoutActivity"
          :error="dealsWithoutActivityError"
          :loading="$apollo.queries.dealsWithoutActivity.loading"
          @retry="onRetryDealsWithoutActivity"
        )
      v-flex(xs12 lg7 xl8).pa-2
        deals-total(
          :total="dealsTotal"
          :error="dealsTotalError"
          :loading="$apollo.queries.dealsTotal.loading"
          @retry="onRetryDealsTotal"
        ).mb-4
        deals-month(
          :deals="dealsMonth"
          :error="dealsMonthError"
          :loading="$apollo.queries.dealsMonth.loading"
          @retry="onRetryDealsMonth"
        )
      v-flex(xs12 lg12 xl12).pa-2
        .dashboard-card
          .dashboard-card__header
            .dashboard-card__title Ejecutivos
          .dashboard-card__body
            div
              v-layout(wrap)
                v-flex(xs12 sm6 md3).px-2
                  adviser-select(
                    :error-messages="errors"
                    :lazy="lazySelects"
                    name="asesor"
                  )
                v-flex(xs12 sm6 md3).px-2
                  month-and-year-select(
                    :from-year="2019"
                    label="Fecha"
                    clearable
                  )
                v-flex(xs12 sm6 md2).pt-4
                  v-btn(
                    :loading="requesting"
                    color="primary"
                    type="submit"
                  ) Buscar
              v-divider
            executives-panel(
              :totalReceivedLeads="250"
              :totalActionsRegistredCallsValid="46"
              :totalActionsRegistredCallsTotal="460"
              :totalActionsRegistredMeetings="15"
              :portfolioMoneyGrowth = [
                { name: 'Enero 2023', ratio: 100000 },
                { name: 'Febrero 2023', ratio: 45000 },
                { name: 'Marzo 2023', ratio: 80000 },
                { name: 'Abril 2023', ratio: 39000 },
                { name: 'Mayo 2023', ratio: 65000 },
                { name: 'Junio 2023', ratio: 72000 },
                { name: 'Julio 2023', ratio: 88000 },
                { name: 'Agosto 2023', ratio: 97000 },
                { name: 'Septiembre 2023', ratio: 91000 },
                { name: 'Octubre 2023', ratio: 112000 },
                { name: 'Noviembre 2023', ratio: 103000 },
                { name: 'Diciembre 2023', ratio: 99000 },
                { name: 'Enero 2024', ratio: 101000 },
              ]
              :portfolioNumberGrowth = [
                { name: 'Enero 2023', ratio: 16 },
                { name: 'Febrero 2023', ratio: 8 },
                { name: 'Marzo 2023', ratio: 12 },
                { name: 'Abril 2023', ratio: 4 },
                { name: 'Mayo 2023', ratio: 7 },
                { name: 'Junio 2023', ratio: 8 },
                { name: 'Julio 2023', ratio: 9 },
                { name: 'Agosto 2023', ratio: 10 },
                { name: 'Septiembre 2023', ratio: 11 },
                { name: 'Octubre 2023', ratio: 18 },
                { name: 'Noviembre 2023', ratio: 12 },
                { name: 'Diciembre 2023', ratio: 10 },
                { name: 'Enero 2024', ratio: 17 },
              ]
              :ratioCurrentMonth="50"
              :ratioLastThreeMonths="50"
              :ratioLastThreeMonthsRTC="50"
              :actionsCurrentMonth="10"
              :actionsLastThreeMonths="10"
              :actionsLastThreeMonthsRTC="10"
              :timeFromManagementToCreation="'12:30:45'"
              :ratioSQLToClient = [
                { name: 'Hector', ratio: 2 },
                { name: 'Jaime', ratio: 5 },
                { name: 'Karla', ratio: 2 },
                { name: 'Adriana', ratio: 3 },
                { name: 'Roberto', ratio: 3 },
                { name: 'Darío', ratio: 2 },
                { name: 'Jhon I', ratio: 1 },
                { name: 'Jhon II', ratio: 1 },
                { name: 'Jhon III', ratio: 1 },
                { name: 'Jhon IV', ratio: 1 },
                { name: 'Jhon V', ratio: 1 },
                { name: 'Jhon VI', ratio: 1 },
                { name: 'Jhon VII', ratio: 1 },
                { name: 'Jhon VIII', ratio: 1 },
                { name: 'Jhon IX', ratio: 1 },
                { name: 'Jhon X', ratio: 1 },
              ]
              :loading=false
              :error=false
            )
</template>

<script>
  import moment from 'moment';
  import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';

  import {
    DashboardRemindersQuery,
    DealsMonthQuery,
    DealsTotalQuery,
    DealsWithoutActivityQuery,
  } from './graphql/queries.gql';
  import Reminders from './components/reminders';
  import DealsMonth from './components/deals-month';
  import DealsTotal from './components/deals-total';
  import DealsWithoutActivity from './components/deals-without-activity';
  import ExecutivesPanel from './components/executives/executives-panel';
  import AdviserSelect from '@/components/inputs/adviser-select';
  import MonthAndYearSelect from '@/components/inputs/month-and-year-select';

  export default {
    name: 'dashboard-view',

    mixins: [
      currentUserMixin(),
    ],

    data() {
      return {
        reminders: [],
        remindersError: null,
        dealsTotal: null,
        dealsTotalError: null,
        dealsMonth: [],
        dealsMonthError: null,
        dealsWithoutActivity: [],
        dealsWithoutActivityError: null,
      };
    },

    apollo: {
      reminders: {
        query: DashboardRemindersQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            adviserId: this.currentUser.id,
            date: moment().add(3, 'days').endOf('day').format('YYYY-MM-DD'),
          };
        },

        update({ reminders }) {
          return reminders?.objects || [];
        },

        skip() {
          return !this.currentUser;
        },

        error() {
          this.remindersError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },

      dealsMonth: {
        query: DealsMonthQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            adviserId: this.currentUser.id,
            createdSince: moment().startOf('month').format('YYYY-MM-DD'),
            createdUntil: moment().endOf('month').format('YYYY-MM-DD'),
          };
        },

        update({ deals }) {
          return deals?.objects;
        },

        error() {
          this.dealsMonthError = new Error('Ha ocurrido un error al obtener los datos');
        },

        skip() {
          return !this.currentUser;
        },
      },

      dealsWithoutActivity: {
        query: DealsWithoutActivityQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            adviserId: this.currentUser.id,
          };
        },

        update({ deals }) {
          return deals?.objects;
        },

        error() {
          this.dealsWithoutActivityError = new Error('Ha ocurrido un error al obtener los datos');
        },

        skip() {
          return !this.currentUser;
        },
      },

      dealsTotal: {
        query: DealsTotalQuery,
        fetchPolicy: 'cache-and-network',

        error() {
          this.dealsTotalError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },
    },

    components: {
      Reminders,
      DealsMonth,
      DealsTotal,
      DealsWithoutActivity,
      ExecutivesPanel,
      AdviserSelect,
      MonthAndYearSelect,
    },

    methods: {
      onRetryReminders() {
        this.remindersError = null;
        this.$apollo.queries.reminders.refetch();
      },

      onRetryDealsMonth() {
        this.dealsMonthError = null;
        this.$apollo.queries.dealsMonth.refetch();
      },

      onRetryDealsTotal() {
        this.dealsTotalError = null;
        this.$apollo.queries.dealsTotal.refetch();
      },

      onRetryDealsWithoutActivity() {
        this.dealsWithoutActivityError = null;
        this.$apollo.queries.dealsWithoutActivity.refetch();
      },
    },
  };
</script>

<style lang="sass">
  @import '~@/sass/mixins'

  .dashboard-view
    .dashboard-card
      background-color: white
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)
      border-radius: 3px

      &__header
        display: flex
        align-items: center
        padding: 0 24px
        height: 48px

      &__title
        margin-right: 8px

        font-size: 15px
        font-weight: bold
        color: darken(white, 40%)

      &__count
        display: inline-block
        font-size: 14px
        padding: 2px 8px
        margin: 0 8px

        background-color: #6fa3fd
        color: white
        border-radius: 3px

        font-weight: 500

        +lg
          padding: 2px 11px

          font-size: 18px

      &__body
        padding: 4px 24px 24px 24px

      &__scrollable-content
        max-height: 400px
        overflow-y: auto

      &__loading-container
        display: flex
        justify-content: center
        align-items: center
        padding: 26px
</style>