<template lang="pug">
  table(:class="b()")
    borrowers-payment-days-diff-table-head(:labels="headLabels")
    borrowers-payment-days-diff-table-body(:entries="entries")
</template>

<script>
import BorrowersPaymentDaysDiffTableHead from './borrowers-payment-days-diff-table-head';
import BorrowersPaymentDaysDiffTableBody from './borrowers-payment-days-diff-table-body';

export default {
  name: 'borrowers-payment-days-diff-table',

  props: {
    entries: { type: Array, required: true },
  },

  data() {
    return {
      stickyHead: null,
    };
  },

  methods: {
    onScroll() {
      const toolbarHeight = document.querySelector('.v-app-bar').offsetHeight;
      const tableBodyNode = this.$el.querySelector('tbody');
      const tableHeadNode = this.$el.querySelector('thead');
      const tableBodyClientRect = tableBodyNode.getBoundingClientRect();


      if(tableBodyClientRect.top < toolbarHeight) {
        tableHeadNode.style.transform = `translateY(${Math.abs(tableBodyClientRect.top - toolbarHeight) + tableHeadNode.offsetHeight}px)`
      } else {
        tableHeadNode.style.transform = null;
      }
    },
  },

  computed: {
    headLabels() {
      return (this.entries?.[0]?.lines || [])
        .map(({ feeNumber }) => `${feeNumber}`);
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },

  components: { BorrowersPaymentDaysDiffTableHead, BorrowersPaymentDaysDiffTableBody },
};
</script>

<style lang="sass">
  .borrowers-payment-days-diff-table
    position: relative
    text-align: center
    border-collapse: collapse
    margin: 0 auto

    thead
      tr
        th
          position: relative
          width: 60px
          min-width: 60px
          padding: 8px 12px
          background-color: darken(white, 4%)
          color: darken(white, 70%)

          &:first-child
            width: 260px
            min-width: 260px

    tbody
      tr
        td
          width: 60px
          min-width: 60px
          padding: 8px 12px
          border-bottom: 1px solid darken(white, 10%)

          &:first-child
            width: 260px
            min-width: 260px

        &:last-child
          td
            border-bottom: none

        &:hover
          background-color: transparentize($primaryColor, 0.95)
</style>