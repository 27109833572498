<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      formalization-referrals(
        ref="formalizationReferrals"
        :referrals="referrals"
        :requesting="requesting"
        @create="args => $emit('create', args)"
        @update="args => $emit('update', args)"
        @validate="args => $emit('validate', args)"
        validable
      )
</template>

<script>
import FormalizationReferrals from '@/components/UI/formalization-referrals';

export default {
  name: 'referrals-tab',

  props: {
    referrals: { type: Array, default: () => ([]) },
    requesting: { type: Boolean, default: false },
  },

  methods: {
    closeForm() {
      this.$refs.formalizationReferrals.closeForm();
    },
  },

  components: { FormalizationReferrals },
};
</script>

<style lang="sass" scoped>
  .referrals-tab
    &__container
      margin: 0 auto
      padding-bottom: 32px
</style>