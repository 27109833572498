
import moment from 'moment';

export default (schedule = []) => {
  let simpleFee = schedule?.[1].fee || null;
  let doubleFee = null;

  for (let index = 1; index < schedule.length; index++) {
    const quote = schedule[index];

    // Si es julio o diciembre
    if([6, 11].includes(moment(quote.date).get('month'))) {
      if(quote.fee !== simpleFee) doubleFee = quote.fee;

      break;
    }
  }

  return {
    simpleFee,
    doubleFee,
  };
};