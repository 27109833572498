<template lang="pug">
  .data-card
    .data-card__title Resumen de Inversión
    .data-card__body
      v-layout(wrap)
        v-flex(xs6)
          .data-row
            b Total de cuotas:&nbsp;
            span {{ schedule.totalGrossFee | formatCurrency(currency) }}
          .data-row
            b Total cuotas netas:&nbsp;
            span {{ schedule.totalNetFee | formatCurrency(currency) }}
          .data-row
            b Total de interés:&nbsp;
            span {{ schedule.totalInterest | formatCurrency(currency) }}
        v-flex(xs6)
          .data-row
            b Ganancia bruta:&nbsp;
            span {{ grossProfit | formatCurrency(currency) }}
          .data-row
            b Ganancia neta:&nbsp;
            span {{ netProfit | formatCurrency(currency) }}
          .data-row
            b LTV Comercial:&nbsp;
            span {{ schedule.ltc }}%
          .data-row
            b LTV realización:&nbsp;
            span {{ schedule.ltv }}%
</template>

<script>
  export default {
    name: 'investment-summary-card',

    props: {
      investmentProposal: { type: Object },
      schedule: { type: Object },
      currency: { type: Object },
    },

    computed: {
      netProfit() {
        return this.schedule.totalNetFee - this.investmentProposal.debtAmount;
      },

      grossProfit() {
        return this.schedule.totalGrossFee - this.investmentProposal.debtAmount;
      },
    },


  };
</script>

<style lang="sass" scoped>
</style>