import capitalize from 'lodash/capitalize';
import VSelect from 'vuetify/lib/components/VSelect';
import { PAY_DETAIL_STATUS } from '@/utils/constants';


export default {
  name: 'pay-detail-status-select',

  functional: true,

  render(h, context) {
    const items = Object.values(PAY_DETAIL_STATUS)
      .map(status => ({
        value: status,
        text: capitalize(context.parent.$t(`payDetailStatus.${status}`)),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};