<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Ventas brutas mensuales
    div(:class="b('content')")
      div(
        v-for="month in months"
        :class="b('item')"
      )
        div(:class="b('item-date')") {{ month.date | formatDate('MMMM YYYY') }}
        div(:class="b('item-amount')") {{ month.amount | formatCurrency(currency, { precision: 0 }) }}
</template>

<script>
export default {
  name: 'incomes-row-by-month',

  props: {
    months: { type: Array, default: () => [] },
    currency: { type: Object },
  },
};
</script>

<style lang="sass" scoped>
  .incomes-row-by-month
    &__title
      font-weight: bold

    &__content
      padding: 8px 12px

    &__item
      display: flex
      align-items: center
      padding-bottom: 3px

      &-date
        margin-right: 16px

      &-amount
        font-size: 18px
        font-weight: 500
</style>