<template lang="pug">
  div(:class="b()")
    mortgages-chart(:mortgages="mortgages")
    mortgages-table(:mortgages="mortgages")
</template>

<script>
  import MortgagesTable from './mortgages-table';
  import MortgagesChart from './mortgages-chart';

  export default {
    name: 'mortgages',

    props: {
      mortgages: { type: Array, default: () => [] },
    },

    components: { MortgagesTable, MortgagesChart },
  };
</script>

<style lang="sass" scoped>

</style>