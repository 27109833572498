<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="loading || error"
      :error="error"
      @retry="args => $emit('retry', args)"
    )
    div(v-else-if="skipped" :class="b('button-container')")
      v-btn(color="primary" type="button" @click="$emit('init')") Obtener endeudamiento
    v-layout(v-else-if="data || risk" wrap)
      v-flex(xs12 lg6).pa-2
        incomes(v-model="data.incomes" :disabled="disabled || risk")
      v-flex(xs12 lg6).pa-2
        proposals(v-if="data.product" v-model="data.product" :disabled="disabled")
      v-flex(xs12 lg8).pa-2
        debts(v-model="data.debts" @reset="onResetDebt" :disabled="disabled")
      v-flex(xs12 lg4).pa-2
        h3(style="text-align: center;") DTI
        results(:rci="dti")
        h3(style="text-align: center;") PTI
        results(:rci="pti")
        v-btn(v-if="risk" class="primary" style="margin-top: 20px; text-align: center; margin-left: 50%; transform: translateX(-50%);" @click="saveData" :disabled="getRiskFileIsApproved") Guardar
    v-alert(v-else color="info" outlined) No hay datos de endeudamiento del cliente
</template>

<script>
import isEqual from 'lodash/isEqual';
import iziToast from 'izitoast';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

import Incomes from './incomes';
import Proposals from './proposals';
import Debts from './debts';
import Results from './results';

import DoUpdateRiskFile from './graphql/mutations.gql';
import { RISK_OPINION_STATUS } from '@/utils/constants';

export default {
  name: 'rci',

  props: {
    initValues: { type: Object },
    error: { type: Error },
    loading: { type: Boolean, default: false },
    skipped: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    risk: { type: Boolean, default: false },
    riskFileId: { type: String, default: null },
    status: { type: String, default: null },
  },

  data() {
    return {
      data: null,
    };
  },

  computed: {
    dti() {
      return (
        Number(((this.totalExpenses / this.totalIncomes) * 100).toFixed(2)) || 0
      );
    },

    pti() {
      return (
        Number(
          ((this.totalExpensesPTI / this.totalIncomes) * 100).toFixed(2),
        ) || 0
      );
    },

    totalIncomes() {
      if (!this.data) return 0;

      const fixedIncomes =
        Number(this.data.incomes.main.fixed || 0) +
        Number(this.data.incomes.couple.fixed || 0);
      const variableIncomes =
        Number(this.data.incomes.main.variable || 0) +
        Number(this.data.incomes.couple.variable || 0);

      return fixedIncomes + variableIncomes;
    },

    totalExpenses() {
      if (!this.data || !this.data.debts) return 0;

      const mortgageFee = this.data.debts.mortgage
        ? Number(this.data.debts.mortgage.fee || 0)
        : 0;
      const consumerFee = this.data.debts.consumer
        ? Number(this.data.debts.consumer.fee || 0)
        : 0;
      const vehicularFee = this.data.debts.vehicular
        ? Number(this.data.debts.vehicular.fee || 0)
        : 0;
      const usedCreditCardFee = this.data.debts.usedCreditCard
        ? Number(this.data.debts.usedCreditCard.fee || 0)
        : 0;
      const unusedCreditCardFee = this.data.debts.unusedCreditCard
        ? Number(this.data.debts.unusedCreditCard.fee || 0)
        : 0;
      const cardWithdrawalsFee = this.data.debts.cardWithdrawals
        ? Number(this.data.debts.cardWithdrawals.fee || 0)
        : 0;
      const extraLineFee = this.data.debts.extraLine
        ? Number(this.data.debts.extraLine.fee || 0)
        : 0;
      const productFee = this.data.product
        ? Number(this.data.product.fee || 0)
        : 0;

      return (
        mortgageFee +
        consumerFee +
        vehicularFee +
        usedCreditCardFee +
        unusedCreditCardFee +
        cardWithdrawalsFee +
        extraLineFee +
        productFee
      );
    },

    totalExpensesWithOutProduct() {
      if (!this.data || !this.data.debts) return 0;

      const mortgageFee = this.data.debts.mortgage
        ? Number(this.data.debts.mortgage.fee || 0)
        : 0;
      const consumerFee = this.data.debts.consumer
        ? Number(this.data.debts.consumer.fee || 0)
        : 0;
      const vehicularFee = this.data.debts.vehicular
        ? Number(this.data.debts.vehicular.fee || 0)
        : 0;
      const usedCreditCardFee = this.data.debts.usedCreditCard
        ? Number(this.data.debts.usedCreditCard.fee || 0)
        : 0;
      const unusedCreditCardFee = this.data.debts.unusedCreditCard
        ? Number(this.data.debts.unusedCreditCard.fee || 0)
        : 0;
      const cardWithdrawalsFee = this.data.debts.cardWithdrawals
        ? Number(this.data.debts.cardWithdrawals.fee || 0)
        : 0;
      const extraLineFee = this.data.debts.extraLine
        ? Number(this.data.debts.extraLine.fee || 0)
        : 0;

      return (
        mortgageFee +
        consumerFee +
        vehicularFee +
        usedCreditCardFee +
        unusedCreditCardFee +
        cardWithdrawalsFee +
        extraLineFee
      );
    },

    totalExpensesPTI() {
      return this.data ? Number(this.data.product?.fee || 0) : 0;
    },

    getRiskFileIsApproved() {
      return RISK_OPINION_STATUS[this.status] === RISK_OPINION_STATUS.APPROVED;
    },
  },

  methods: {
    onResetDebt(context) {
      this.$set(
        this.data.debts[context],
        'fee',
        this.initValues.debts[context].fee,
      );
    },

    sendTotalIncomesResult() {
      this.$emit('totalIncomesResult', this.totalIncomes);
    },

    sendTotalExpensesResult() {
      this.$emit('totalExpensesResult', this.totalExpensesWithOutProduct);
    },

    sendTotalExpensesPTIResult() {
      this.$emit('totalExpensesPTIResult', this.totalExpensesPTI);
    },

    saveData() {
      this.$apollo.mutate({
        mutation: DoUpdateRiskFile,
        variables: {
          id: this.riskFileId,
          rciRiskAgent: JSON.stringify({
            income: {
              main: {
                fixed: this.data.incomes.main.fixed,
                variable: this.data.incomes.main.variable,
              },
              couple: {
                fixed: this.data.incomes.couple.fixed,
                variable: this.data.incomes.couple.variable,
              },
            },
            debts: {
              mortgage: {
                amount: this.data.debts.mortgage.amount,
                fee: this.data.debts.mortgage.fee,
              },
              consumer: {
                amount: this.data.debts.consumer.amount,
                fee: this.data.debts.consumer.fee,
              },
              vehicular: {
                amount: this.data.debts.vehicular.amount,
                fee: this.data.debts.vehicular.fee,
              },
              usedCreditCard: {
                amount: this.data.debts.usedCreditCard.amount,
                fee: this.data.debts.usedCreditCard.fee,
              },
              unusedCreditCard: {
                amount: this.data.debts.unusedCreditCard.amount,
                fee: this.data.debts.unusedCreditCard.fee,
              },
              cardWithdrawals: {
                amount: this.data.debts.cardWithdrawals?.amount,
                fee: this.data.debts.cardWithdrawals?.fee,
              },
              extraLine: {
                amount: this.data.debts.extraLine?.amount,
                fee: this.data.debts.extraLine?.fee,
              },
            },
            product: {
              slug: this.data.product.slug,
              fee: this.data.product.fee,
            },
          }),
        },
      }).then((res) => {
        if (res.data.updateRiskFile.status === 'UPDATED') {
          iziToast.success({
            message: 'Datos actualizados correctamente',
          });
        } else {
          iziToast.error({
            message: 'Error al actualizar los datos',
          });
        }
      });
    },
  },

  components: {
    Incomes,
    Proposals,
    Debts,
    Results,
    LoadingWrapper,
  },

  watch: {
    initValues: {
      immediate: true,
      deep: true,

      handler(values, oldValues) {
        if (values && !isEqual({ ...values }, { ...oldValues }))
          this.$set(this, 'data', { ...values });
      },
    },

    data: {
      immediate: true,
      deep: true,

      handler(values) {
        this.$nextTick(() => {
          if (values)
            this.$emit('change', { values: { ...values }, rci: this.dti });
        });
        this.$emit('mounted');
        this.sendTotalIncomesResult();
        this.sendTotalExpensesResult();
        this.sendTotalExpensesPTIResult();
      },
    },
  },

  mounted() {
    this.$emit('mounted');
    this.sendTotalIncomesResult();
    this.sendTotalExpensesResult();
    this.sendTotalExpensesPTIResult();
  },
};
</script>

<style lang="sass">
.rci
  &__button-container
    text-align: center
    padding: 28px 0
</style>
