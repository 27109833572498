<template lang="pug">
  div(:class="b()")
    div(:class="b('body')")
      loading-wrapper(v-if="loading")
      template(v-else)
        contract-item(
          v-if="contract"
          :contract="contract"
          @click-edit="({ contract }) => onClickEdit(contract)"
        )
        v-alert(v-else-if="!investmentProposal" type="error").text-center Antes de poder crear un contrato se debe haber registrado un préstamo
        v-alert(v-else color="info" outlined).text-center
          .mb-1 Aún no hay algún contrato registrado
          v-btn(@click="onClickAdd" color="primary")
            v-icon mdi-file-plus-outline
            span.ml-1 Crear Contrato
    contract-form-modal(
      v-if="formModal"
      v-model="formModal"
      :to-pay-details-total="deal.credit"
      :contract="editingEntity"
      :requesting="requesting"
      @submit="onSubmit"
    )
</template>

<script>
import ContractItem from './contract-item';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

import gestionableListMixin from '@/mixins/gestionable-list-mixin';
import ContractFormModal from '@/components/modals/contract-form-modal';

export default {
  name: 'contract-tab',

  props: {
    contract: { type: Object },
    deal: { type: Object },
    investmentProposal: { type: Object },
    requesting: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  mixins: [
    gestionableListMixin({
      entityName: 'contract',
    }),
  ],

  components: { ContractFormModal, ContractItem, LoadingWrapper },
};
</script>

<style lang="sass" scoped>

</style>