<template lang="pug">
  div(:class="b()")
    score(
      v-for="(score, idx) in scores"
      :key="idx"
      :score="score"
    )
</template>

<script>
  import Score from './score';

  export default {
    name: 'scores',

    props: {
      scores: { type: Array, default: () => [] },
    },

    components: { Score },
  };
</script>

<style lang="sass" scoped>

</style>