<template lang="pug">
  div(:class="b()")
    form(@submit.prevent="onSubmit")
      v-layout(wrap).align-center
        v-flex(xs12 md4 lg3)
          borrower-select(
            v-model="data.borrowerIds"
            label="Prestatario"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg3)
          vehicle-select(
            v-model="data.investorIds"
            label="Vehículo"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg3)
          status-select(
            v-model="data.status"
            label="Estado"
            multiple
            clearable
          )
        v-flex(xs12 md4 lg3)
          condition-select(
            v-model="data.condition"
            label="Condición"
            multiple
            clearable
          )
        v-flex(xs12 md4 lg3)
          qualification-compliance-select(
            v-model="data.qualificationCompliance"
            label="Clasificación"
            multiple
            clearable
          )
        v-flex(xs12 md4 lg3)
          qualification-status-select(
            v-model="data.qualificationStatus"
            label="Situación"
            multiple
            clearable
          )
        v-flex(xs12 md4 lg3)
          type-cancellation-select(
            v-model="data.typeOfCancellation"
            label="Cancelación"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex.text-right
          v-btn(
            :disabled="disabled || fetching"
            :loading="fetching"
            type="submit"
            color="primary"
          ) BUSCAR PROPUESTAS
</template>

<script>
  import VehicleSelect from '@/components/inputs/vehicle-select';
  import BorrowerSelect from '@/components/inputs/borrower-select';
  import StatusSelect from '@/components/inputs/investment-proposal-status-select';
  import ConditionSelect from '@/components/inputs/investment-proposal-condition-select';
  import QualificationComplianceSelect from '@/components/inputs/investment-proposal-qualification-compliance-select';
  import QualificationStatusSelect from '@/components/inputs/investment-proposal-qualification-status-select';
  import TypeCancellationSelect from '@/components/inputs/investment-proposal-type-cancellation-select';
  import MonthPicker from '@/components/inputs/month-picker';
  import MonthAndYearSelect from '@/components/inputs/month-and-year-select';
  import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants'

  export default {
    name: 'filters',

    props: {
      fetching: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },

    data() {
      return {
        data: {
          investorIds: [],
          borrowerIds: [],
          status: [INVESTMENT_PROPOSAL_STATUS.COLLECTING],
          condition: [],
          qualificationCompliance: [],
          qualificationStatus: [],
          monthYearCreationDate: undefined,
          monthYearConfirmationDate: undefined,
        }
      };
    },

    methods: {
      onSubmit() {
        this.$emit('submit', { filters: this.data });
      },
    },

    components: {
      BorrowerSelect,
      ConditionSelect,
      MonthAndYearSelect,
      MonthPicker,
      QualificationComplianceSelect,
      QualificationStatusSelect,
      StatusSelect,
      TypeCancellationSelect,
      VehicleSelect,
    },
  };
</script>

<style lang="sass" scoped>
  .filters
    width: 100%
</style>