<template lang="pug">
  div(:class="b({ verified: income.verifiedAt })")
    .d-flex.pb-1
      div
        span.subheading.font-weight-medium {{ income.type.description }}
        v-chip.ml-2 {{ income.type.category }}
      .flex
      v-icon.cursor-pointer(@click.native="onClickEdit") mdi-pencil
      v-icon.cursor-pointer(@click.native="onClickDelete") mdi-delete
    div(v-if="income.description").text--secondary.pb-1 {{ income.description }}
    .text-left(v-if="income.startAt")
      span.font-weight-medium Desde:&nbsp;
      span {{ income.startAt | formatDate }}&nbsp;
      span ({{ startFromNow }})
    .text-left(v-if="income.position")
      span.font-weight-medium Posición:&nbsp;
      span {{ income.position }}&nbsp;
    .d-flex.align-center.justify-end.pt-2
      div(:class="b('amount')") {{ income.amount | formatCurrency(income.currency, { precision: 0 }) }}
      div(:class="b('amount-type')") Brutos
      div(:class="b('amount')")
        template(v-if="income.netAmount") {{ income.netAmount | formatCurrency(income.currency, { precision: 0 }) }}
        template(v-else) ?
      div(:class="b('amount-type')") Netos
      v-tooltip(v-if="income.verifiedAt" top)
        template(v-slot:activator="{ on }")
          v-icon(v-on="on" color="success") mdi-check-decagram
        span {{ income.verifiedAt | formatDate('D MMMM YYYY') }}
    div(v-if="income.workPlace")
      .title.font-weight-medium {{ income.workPlace.name }}
      .text--secondary {{ address }}
      v-layout(wrap)
        v-flex(xs12 md6)
          span.font-weight-medium {{ $t('workPlaceVat') }}:&nbsp;
          span {{ income.workPlace.vat }}
        v-flex(xs12 md6)
          span.font-weight-medium Teléfono:&nbsp;
          span {{ income.workPlace.phone }}
        v-flex(xs12 md6)
          span.font-weight-medium Correo:&nbsp;
          span {{ income.workPlace.email }}
</template>

<script>
  import moment from 'moment';
  import addressToString from '@/utils/address-to-string';

  export default {
    name: 'incomes-row',

    props: {
      income: { type: Object, required: true },
    },

    methods: {
      onClickEdit() {
        this.$emit('edit', { income: this.income });
      },

      onClickDelete() {
        this.$emit('delete', { income: this.income });
      },
    },

    computed: {
      address() {
        return addressToString(this.income.workPlace?.address) || null;
      },

      startFromNow() {
        if(this.income?.startAt)

        return this.income?.startAt ? moment(this.income.startAt).fromNow() : null
      },
    },
  };
</script>

<style lang="sass" scoped>

  .incomes-row
    &__verified-icon
      margin-left: .25em

    &--verified
      .incomes-row
        &__verified-icon
          color: green

    &__amount
      font-size: 19px
      font-weight: 500

      &-type
        font-size: 13px
        margin-left: 6px
        margin-right: 12px
</style>