<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit" :class="b()")
      v-layout(wrap)
        v-flex(xs12)
          validation-provider(name="descripción" :rules="'required|min:6'" v-slot="{ errors }")
            rich-textarea(
              v-model="data.description"
              :error-messages="errors"
              label="Descripción"
              name="descripción"
              placeholder="Descripción del dictamen..."
            )
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="estado" :rules="'required'" v-slot="{ errors }")
            status-select(
              v-model="data.status"
              :error-messages="errors"
              name="estado"
              label="Estado"
            )
        v-flex(v-if="isRefusing" xs12 sm6 md4).px-2
          validation-provider(name="motivo" :rules="'required'" v-slot="{ errors }")
            refuse-reason-select(
              v-model="data.refusedReasonId"
              :error-messages="errors"
              :disabled="!isRefusing"
              name="motivo"
              label="Motivo de rechazo"
            )
        v-flex(v-if="isNotPendingOrObserved" xs12 sm6 md4).px-2
          validation-provider(name="evaluacion" :rules="'required'" v-slot="{ errors }")
            evaluation-type-select(
              v-model="data.evaluationTypeIds"
              :error-messages="errors"
              :disabled="!isNotPendingOrObserved"
              name="evaluacion"
              label="Tipo de evaluación"
            )
        v-flex(v-if="isNotPendingOrObserved" xs12 sm6 md4).px-2
          validation-provider(name="excepcion" :rules="'required'" v-slot="{ errors }")
            exception-select(
              v-model="data.opinionExceptionIds"
              :error-messages="errors"
              :disabled="!isNotPendingOrObserved"
              name="excepcion"
              label="Excepción"
            )
      v-divider
      v-card-actions
        div(:class="b('title')").text--secondary.px-8 Declaro bajo juramento que los valores estimados e ingresados han sido debidamente revisados.
        v-spacer
        v-btn(
          :loading="requesting"
          :disabled="descriptionIsEmpty"
          type="submit"
          color="primary"
        ).px-4 Dictaminar
    div(style="width: 100%; display: flex; justify-content: center; margin-top: 10px;")
      div(v-if="opinion.DTI" style="display: inline").mr-3
        span.font-weight-medium DTI:&nbsp;
        span {{ opinion.DTI }} %

      div(v-if="opinion.PTI" style="display: inline").mr-3
        span.font-weight-medium PTI:&nbsp;
        span {{ opinion.PTI }} %

    div(style="width: 100%; display: flex; justify-content: center; margin-top: 10px;")
      div(v-if="totalIncomesResult" style="display: inline").mr-3
        span.font-weight-medium Ingresos Netos:&nbsp;
        span {{ totalIncomesResult | formatCurrency }}

      div(v-if="totalExpensesResult" style="display: inline").mr-3
        span.font-weight-medium Pago Mensual:&nbsp;
        span {{ totalExpensesResult | formatCurrency }}

      div(v-if="totalExpensesPTIResult" style="display: inline").mr-3
        span.font-weight-medium Cuota RTC:&nbsp;
        span {{ totalExpensesPTIResult | formatCurrency }}

</template>

<script>
  import { RISK_OPINION_STATUS } from '@/utils/constants';
  import RichTextarea from '@/components/inputs/rich-textarea';
  import basicFormMixin from '@/components/forms/basic-form-mixin';

  import StatusSelect from './inputs/status-select';
  import ExceptionSelect from './inputs/exception-select';
  import EvaluationTypeSelect from './inputs/evaluation-type-select';
  import RefuseReasonSelect from './inputs/refuse-reason-select';


  export default {
    name: 'opinion-form',

    props: {
      opinion: { type: Object, default: () => ({}) },
      requesting: { type: Boolean, default: false },
      totalIncomesResult: { type: Number, default: 0 },
      totalExpensesResult: { type: Number, default: 0 },
      totalExpensesPTIResult: { type: Number, default: 0 },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'opinion',
        veeValidation: true,

        mapPropsToData({ opinion }) {
          return {
            description: opinion?.description || undefined,
            status: opinion?.status || undefined,
            refusedReasonId: opinion?.refusedReason?.id || undefined,
            evaluationTypeIds: opinion?.evaluationTypeIds?.map((evaluation) => evaluation.id) || undefined,
            opinionExceptionIds: opinion?.opinionExceptionIds?.map((exception) => exception.id) || undefined,
          };
        },

        parseSubmit() {
          return {
            opinion: {
              ...this.data,
              refusedReasonId: this.isRefusing ? this.data.refusedReasonId : null,
              evaluationTypeIds: this.isNotPendingOrObserved ? [this.data.evaluationTypeIds] : null,
              opinionExceptionIds: this.isNotPendingOrObserved ? this.data.opinionExceptionIds : null,
            },
          };
        },
      }),
    ],

    components: {
      EvaluationTypeSelect,
      ExceptionSelect,
      StatusSelect,
      RefuseReasonSelect,
      RichTextarea,
    },

    computed: {
      descriptionIsEmpty() {
        return !this.data.description || this.data.description === '<p></p>';
      },

      isRefusing() {
        return this.data.status === RISK_OPINION_STATUS.REFUSED;
      },

      isNotPendingOrObserved() {
        return ! (this.data.status === RISK_OPINION_STATUS.PENDING || this.data.status === RISK_OPINION_STATUS.OBSERVED);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>