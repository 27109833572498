<script>
  import UrlItemType from './url-item-type';
  import YoutubeItemType from './youtube-item-type';

  export default {
    name: 'file-list-item',

    props: {
      file: { type: Object, required: true },
    },

    render(h) {
      const { fileType } = this.file;
      const component = do{
        if(fileType === 'video') YoutubeItemType
        else UrlItemType
      };

      return h(component, {
        props: {
          file: this.file,
        }
      });
    }
  };
</script>

