<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table(:class="b('table')").rtc-metrics-table.rtc-metrics-table--striped
        table-head(:items="items")
        table-body(:items="items")
</template>

<script>

  import TableHead from './advisers-table-head';
  import TableBody from './advisers-table-body';

  export default {
    name: 'advisers-table',

    props: {
      items: { type: Array, default: () => [] },
    },

    components: { TableHead, TableBody },
  };
</script>

<style lang="sass">
</style>