<template lang="pug">
  thead
    tr
      th Funnel stage
      th Number
      th Conversion from previous stage
      th Conversion from top of the funnel
      th Credit volume (USD)
</template>

<script>
  export default {
    name: 'general-table-head',

    props: {
      items: { type: Array, default: () => [] },
    },

    computed: {
      titles() {
        return this.items.map(({ name }) => name);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>