import { render, staticRenderFns } from "./totals-summary.vue?vue&type=template&id=06677b36&scoped=true&lang=pug"
import script from "./totals-summary.vue?vue&type=script&lang=js"
export * from "./totals-summary.vue?vue&type=script&lang=js"
import style0 from "./totals-summary.vue?vue&type=style&index=0&id=06677b36&prod&lang=sass&scoped=true"
import style1 from "./totals-summary.vue?vue&type=style&index=1&id=06677b36&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06677b36",
  null
  
)

export default component.exports