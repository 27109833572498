<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).px-2.text-center
          validation-provider(name="avatar" :rules="'required'" v-slot="{ errors }")
            avatar-input(
              v-model="data.avatar"
              name="avatar"
              :error-messages="errors"
              :size="86"
            )
          div(:class="b('url-container')")
            div(:class="b('url-wrapper')")
              a(target="_blank" :href="url") {{ url }}
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-btn(
                    v-on="on"
                    text small icon
                    @click="onClickCopy"
                  )
                    v-icon mdi-content-copy
                span Copiar link
        v-flex(xs12 md6).px-2
          validation-provider(name="url" :rules="'required'" v-slot="{ errors }")
            v-text-field(
              v-model="data.slug"
              :error-messages="errors"
              name="url"
              label="URL (unicamente letras, guiones)"
              placeholder="nombre-apellido"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="años de experiencia" :rules="'required|numeric|min_value:1|max_value:50'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.yearsOfExperience"
              :error-messages="errors"
              name="años de experiencia"
              label="Años de experiencia"
              type="number"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="número de clientes asesorados" :rules="'required|numeric|min_value:1'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.advisedClients"
              :error-messages="errors"
              name="número de clientes asesorados"
              label="Número de clientes asesorados"
              type="number"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="ahorro total" :rules="'numeric'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.totalSaving"
              :error-messages="errors"
              name="ahorro total"
              label="Ahorro total"
              type="number"
            )
        v-flex(xs12 md6).px-2
          v-text-field(
            v-model="data.certificateCode"
            name="número de asesor"
            label="Número de asesor (certificado)"
            placeholder="B-01234"
          )
        v-flex(xs12 md6).px-2
          v-text-field(
            v-model="data.socialFacebook"
            name="facebook"
            label="Facebook"
            placeholder="https://www.facebook.com/TUNOMBRE/"
          )
        v-flex(xs12 md6).px-2
          v-text-field(
            v-model="data.socialInstagram"
            name="instagram"
            label="Instagram"
            placeholder="https://www.instagram.com/TUNOMBRE/"
          )
        v-flex(xs12 md6).px-2
          v-text-field(
            v-model="data.socialLinkedin"
            name="linkedin"
            label="Linkedin"
            placeholder="https://www.linkedin.com/TUNOMBRE/"
          )
        v-flex(xs12 md6).px-2
          v-text-field(
            v-model="data.twitter"
            name="tiktok"
            label="Tiktok"
            placeholder="https://www.tiktok.com/@usuario"
          )
        v-flex(xs12).pb-4
          div(:class="b('main-photo-label')") Foto principal (570x697 pixeles)

          validation-provider(name="foto principal" :rules="'required'" v-slot="{ errors }")
            image-preview-input(
              v-model="data.mainPhoto"
              :class="b('main-photo')"
              :error-messages="errors"
              name="foto principal"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar Cambios
</template>

<script>
import compact from 'lodash/compact';
import Config from '@/config';
import basicFormMixin from './basic-form-mixin';
import copy from 'copy-to-clipboard';

import ImagePreviewInput from '@/components/inputs/image-preview-input';
import AvatarInput from '@/components/inputs/avatar-input';

const buildDefaultSlug = user => compact([
  user.firstName,
  user.lastName,
]).map(name => name.toLowerCase())
  .map(name => name.replace(/\s/g, '-'))
  .join('-')


export default {
  name: 'adviser-landing-form',

  mixins: [
    basicFormMixin({
      modelKey: 'landing',
      veeValidation: true,

      mapPropsToData({ landing, user }) {
        return {
          id: landing?.id,
          dsUserId: landing?.dsuserId,
          slug: landing?.id ? landing?.slug : buildDefaultSlug(user),
          yearsOfExperience: landing?.yearsOfExperience,
          advisedClients: landing?.advisedClients,
          totalSaving: landing?.totalSaving,
          avatar: landing?.avatar,
          mainPhoto: landing?.mainPhoto,
          certificateCode: landing?.certificateCode,
          socialFacebook: landing?.socialFacebook,
          socialInstagram: landing?.socialInstagram,
          socialLinkedin: landing?.socialLinkedin,
          twitter: landing?.twitter,
        };
      },

      parseSubmit() {
        return {
          landing: {
            ...this.data,
            mainPhoto: this.data?.mainPhoto?.includes('http') ? undefined : this.data.mainPhoto,
            avatar: this.data?.avatar?.includes('http') ? undefined : this.data.avatar,
            yearsOfExperience: this.data.yearsOfExperience || null,
            advisedClients: this.data.advisedClients || null,
            totalSaving: this.data.totalSaving || null,
            certificateCode: this.data.certificateCode || null,
            socialFacebook: this.data.socialFacebook || null,
            socialInstagram: this.data.socialInstagram || null,
            socialLinkedin: this.data.socialLinkedin || null,
            twitter: this.data.twitter || null,
          },
        };
      },
    }),
  ],

  props: {
    user: { type: Object },
  },

  computed: {
    isCreating() {
      return !this.landing?.id;
    },

    url() {
      return `${Config.ADVISER_LANDING_URL}/${this.data.slug || ''}`;
    },
  },

  methods: {
    onClickCopy() {
      copy(this.url);

      window.alert('El link ha sido copiado al portapapeles');
    },
  },

  components: { ImagePreviewInput, AvatarInput },
};
</script>

<style lang="sass" scoped>
  .adviser-landing-form
    &__main-photo
      margin: 0 auto
      width: 285px
      height: 348px

    &__main-photo-label
      text-align: center
      font-size: 16px
      font-weight: 500
      margin-bottom: 8px

    &__url-container
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      margin-top: 8px
      margin-bottom: 8px

      a
        margin-right: 8px

    &__url-wrapper
      background-color: darken(white, 6%)
      padding: 2px 8px
      border-radius: 6px
</style>
