<template lang="pug">
  base-quotes-card(
    :user="bond.investor"
    label="Inversionista"
    :vehicle="bond.vehicle"
    labelVehicle="Vehículo"
  )
    template(slot="header-data")
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ bond.pendingQuotesQuantity }}
        .quotes-card__header-data-item-description Cuotas #[br] Pendientes
      .quotes-card__header-data-item
        .quotes-card__header-data-item-value {{ bond.issueCode }}
        .quotes-card__header-data-item-description Número #[br] Emisión
      v-btn(
        :disabled="fetching"
        :loading="fetching"
        color="primary"
        small text
        @click="$emit('refresh')"
      )
        v-icon mdi-refresh
        span.ml-1.hidden-xs-only Actualizar
    quotes(
      :quotes="quotes"
      :currency="currency"
      :fetching="fetching"
      @register-payment="onClickRegisterPayment"
    )
    payment-form-modal(
      v-model="paymentFormModal"
      :default-values="paymentFormDefaultValues"
      :rules="paymentFormRules"
      :fetching="mutating"
      @submit="onSubmitPaymentForm"
    )
</template>

<script>

  import BaseQuotesCard from '@/views/investment/show/components/quotes-card';
  import PaymentFormModal from '@/views/investment/components/quote-payment-form-modal.vue';

  import Quotes from './quotes';

  export default {
    name: 'quotes-card',

    data() {
      return {
        registeringQuote: null,
        paymentFormModal: false,
      };
    },

    props: {
      bond: { type: Object, required: true },
      currency: { type: Object, required: true },
      nextQuote: { type: Number },
      quotes: { type: Array, default: () => [] },
      fetching: { type: Boolean, default: false },
      mutating: { type: Boolean, default: false },
    },

    computed: {
      paymentFormRules() {
        if(!this.registeringQuote) return {};

        return {
          amount: { required: true, min_value: this.registeringQuote.currentData.netAmountDue, decimal: 2 },
        };
      },

      paymentFormDefaultValues() {
        return {
          quoteId: this.registeringQuote?.id,
          amount: this.registeringQuote?.currentData?.netAmountDue,
        };
      },
    },

    components: { BaseQuotesCard, Quotes, PaymentFormModal },

    methods: {
      closePaymentForm() {
        this.paymentFormModal = false;
      },

      onClickRegisterPayment({ quote }) {
        this.paymentFormModal = true;
        this.registeringQuote = quote;
      },

      onSubmitPaymentForm({ data }) {
        this.$emit('submit-payment', { data });
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>