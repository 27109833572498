import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { APP_VERSION } from '@/utils/constants'

import Config from '@/config';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: Config.RAVEN_DSN,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: Config.SENTRY_ENVIRONMENT,
    release: APP_VERSION,
    ignoreErrors: [
      /NetworkError:.*/,
    ],
  });
}

