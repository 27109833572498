import { CurrentUserQuery } from './queries.gql';
import getPegasusPermissions from './get-pegasus-permissions';
import authStorage from '@/utils/auth-storage';

export default () => ({
  apollo: {
    currentUser: {
      query: CurrentUserQuery,
      manual: true,

      result({ data }) {
        if(data && data.currentUser) {

          authStorage.setUserData(data.currentUser);

          this.currentUser = data.currentUser;
        }
      },

      skip() {
        return this.currentUser || !authStorage.getCredentials()?.token;
      },
    },
  },

  data() {
    return {
      currentUser: authStorage.getUserData(),
    };
  },

  methods: {
    currentUserHasPermissions(permissions = []) {
      return this.currentUser?.isSuperuser ||
        permissions.every(permission => (this.currentUserPegasusPermissions).includes(permission));
    },

    currentUserHasDjangoPermissions(permissions = []) {
      return this.currentUser?.isSuperuser ||
        permissions.every(permission => (this.currentUser?.permissions || []).includes(permission));
    },

    currentUserBelongsToGroups(groupIds = []) {
      return this.currentUser?.isSuperuser ||
        groupIds.every((groupId) => (this.currentUser?.groups || []).some(({ id }) => groupId === id))
    },
  },

  computed: {
    currentUserPegasusPermissions() {
      return this.currentUser ? getPegasusPermissions(this.currentUser) : [];
    },
  },
});
