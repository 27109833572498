<template lang="pug">
  .data-card
    .data-card__title.d-inline-flex.align-center
      span Resumen de operación # {{ investmentProposal.id }}
      v-chip(v-if="isRescheduled" color="#c37432" text-color="#ffffff" small).ml-2 Reprogramacion
    .data-card__body
      div(:class="b('name')") {{ borrowerFullName }} - {{ investmentProposal.borrower.vat }}
      v-layout(wrap)
        v-flex(xs6)
          .data-row
            b Crédito total (incluido gastos):&nbsp;
            span {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}
          .data-row(v-if="hasValidGraceDays")
            b Capitalización por días de gracia:&nbsp;
            span {{ capitalizationGraceDays | formatCurrency(investmentProposal.currency) }}
          .data-row
            b Monto solicitado:&nbsp;
            span {{ proposal.credit | formatCurrency(investmentProposal.currency) }}
          .data-row
            b Costos de apertura, registrales y notariales:&nbsp;
            span Financiados
          .data-row
            b Fecha de desembolso:&nbsp;
            span {{ investmentProposal.disbursementDate | formatDate('DD MMMM YYYY') }}
          .data-row
            b Fecha de primera cuota:&nbsp;
            span {{ investmentProposal.firstPaymentDate |  formatDate('DD MMMM YYYY') }}
          .data-row(v-if="hasValidGraceDays")
            b Días de gracia:&nbsp;
            span {{ graceDays }}
        v-flex(xs6)
          .data-row
            b Cuota Simple:&nbsp;
            span {{ simpleFee | formatCurrency(investmentProposal.currency) }}
          .data-row(v-if="Boolean(doubleFee)")
            b Cuota Doble:&nbsp;
            span {{ doubleFee | formatCurrency(investmentProposal.currency) }}
          .data-row
            b {{ $t('tea') }}:&nbsp;
            span {{ tea }}%
          .data-row
            b {{ $t('tcea') }}:&nbsp;
            span {{ tcea }}%
          .data-row
            b Plazo:&nbsp;
            span {{ investmentProposal.quotesQuantity }} meses
          .data-row(v-if="proposal.amortizationType")
            b Mensualidad:&nbsp;
            span {{ $t(`amortization.${proposal.amortizationType}` )}}
          .data-row
            b Moneda:&nbsp;
            span {{ investmentProposal.currency.name }}
</template>

<script>
import moment from 'moment';
import balloonPaymentType from '@/filters/balloon-payment-type';

export default {
  name: 'proposal-card',

  props: {
    proposal: { type: Object },
    investmentProposal: { type: Object },
    simpleFee: { type: Number },
    doubleFee: { type: Number },
    tea: { type: Number },
    tcea: { type: Number },
    schedule: { type: Array, default: () => [] },
    isRescheduled: { type: Boolean, default: false },
  },

  computed: {
    administrationExpenses() {
      return this.proposal?.openingCommission?.administrationExpenses;
    },

    borrowerFullName() {
      return `${this.investmentProposal.borrower.firstName} ${this.investmentProposal.borrower.lastName} ${this.investmentProposal.borrower.maternalLastName}`;
    },

    capitalizationGraceDays() {
      return this.schedule.reduce((acc, { amortization }) => acc + amortization, 0) - this.investmentProposal.debtAmount;
    },

    graceDays() {
      return moment(this.investmentProposal.firstPaymentDate).diff(this.investmentProposal.disbursementDate, 'days') - 30;
    },

    hasValidGraceDays() {
      return this.graceDays > 0 && this.capitalizationGraceDays > 0;
    }
  },

  filters: { balloonPaymentType },
};
</script>

<style lang="sass" scoped>
  .proposal-card
    &__name
      font-size: 19px
      font-weight: 500
      margin-bottom: 8px
      text-align: center
</style>
