<template lang="pug">
  div(:class="b()")
    div(:class="b('wrapper')")
      div(:class="b('header')")
        div(:class="b('header-title')") {{ $t(`formalizationStage.${stage.name}` )}} ({{ count }})
        div(v-if="total" :class="b('total')") {{ total.amount | formatCurrency(total.currency) }}
      div(:class="b('body')")
        formalization-stage-card(
          v-for="formalization in formalizations"
          :key="formalization.id"
          :formalization="formalization"
        )
        .text-center
          div(v-if="loading" :class="b('loading-container')")
            loading-spinner
          v-btn(
            v-else-if="hasNext"
            color="primary"
            :disabled="loading"
            @click="onClickLoadMore"
          ) Cargar Más
</template>

<script>
import FormalizationStageCard from './formalization-stage-card';

export default {
  name: 'formalization-stage',

  props: {
    stage: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },

  computed: {
    formalizations() {
      return this.stage?.formalizations?.objects || [];
    },

    hasNext() {
      return this.stage?.formalizations?.hasNext;
    },

    count() {
      return this.stage?.formalizations?.count;
    },

    total() {
      return this.stage?.formalizations?.total?.[0];
    },
  },

  methods: {
    onClickLoadMore() {
      this.$emit('load-more', {
        stage: this.stage.name,
        nextPage: this.stage.formalizations.page + 1,
      });
    },
  },

  components: { FormalizationStageCard },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .formalization-stage
    flex-shrink: 0

    width: 320px
    height: 100%
    margin: 0 4px
    border: 1px solid darken(white, 15%)

    border-radius: 3px

    +xs
      width: 280px

    &__wrapper
      display: flex
      flex-direction: column

      height: 100%

      white-space: normal

    &__header
      padding: .5rem 1rem

      font-weight: bold

      background-color: white

    &__total
      font-size: 1.2rem
      font-weight: 500
      text-align: right

    &__body
      flex: 1

      padding-bottom: 1rem

      overflow-y: auto

    &__loading-container
      display: flex
      justify-content: center
      align-items: center

      padding: 1rem 0


</style>