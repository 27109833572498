<template lang="pug">
  v-flex(xs12 md6 xl4)
    div(:class="b()")
      div(:class="b('responsiver')")
        iframe(
          :src="url"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        )
      i(:class="b('title')") {{ file.name }}
</template>

<script>
  export default {
    name: 'youtube-item-type',

    props: {
      file: { type: Object, required: true },
    },

    computed: {
      url() {
        const regex = /(\?v=|embed\/|v\/)([\w|-]+)/;
        const videoHash = this.file.file.match(regex)?.[2];

        return `https://youtube.com/embed/${videoHash}`;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .youtube-item-type
    position: relative

    &__responsiver
      width: 100%
      padding-top: 56.25%
      position: relative

      iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

    &__title
      margin-top: 4px
      font-size: 15px
</style>