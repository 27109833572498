<template lang="pug">
  div(:class="b()")
    div(:class="b('item')" v-for="item in items" :key="item.qualification")
      div(:class="b('item-color')" :style="{ backgroundColor: item.color }")
      div(:class="b('item-text')") {{ item.qualification }}
</template>

<script>
import { BURO_QUALIFICATIONS } from '@/utils/constants';
import buroQualificationColor from '@/utils/buro-qualification-color';
import formatBuroQualification from '@/utils/format-buro-qualification';

export default {
  name: 'borrowers-payment-days-diff-legend',

  data() {
    return {
      items: Object.values(BURO_QUALIFICATIONS).map((q) => ({
        qualification: formatBuroQualification(q),
        color: buroQualificationColor(q),
      })),
    };
  },
};
</script>

<style lang="sass" scoped>
  .borrowers-payment-days-diff-legend
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center

    &__item
      display: flex
      align-items: center
      margin: 4px 8px

      &-color
        width: 19px
        height: 16px
        margin-right: 8px
</style>