import DynamicFilters from '@/components/inputs/dynamic-filters';
import FormalizationStatusSelect from '@/components/inputs/formalization-status-select';
import MortgageNumberStatusSelect from '@/components/inputs/mortgage-number-status-select';
import UserSelect from '@/components/inputs/user-select';
import AdviserSelect from '@/components/inputs/adviser-select';
import NotarySelect from '@/components/inputs/notary-select';
import ContractStatusSelect from '@/components/inputs/contract-status-select';
import VTextField from 'vuetify/lib/components/VTextField';

const FIELD_TYPES = [
  {
    key: 'status',
    name: 'Estado',
    component: FormalizationStatusSelect,
    parseSubmit: status => ({
      text: status.text,
      value: status.value,
    }),
  },
  {
    key: 'userId',
    name: 'Cliente',
    component: UserSelect,
    parseInputValue: user => user.id,
    parseSubmit: user => ({
      text: `${user.firstName || ''} ${user.lastName || ''}`,
      value: user.id,
      meta: { user },
    }),
  },
  {
    key: 'adviserId',
    name: 'Asesor',
    component: AdviserSelect,
    parseInputValue: user => user.id,
    parseSubmit: adviser => ({
      text: `${adviser.firstName || ''} ${adviser.lastName || ''}`,
      value: adviser.id,
      meta: { adviser },
    }),
  },
  {
    key: 'mortgageNumberStatus',
    name: 'Estado de título',
    component: MortgageNumberStatusSelect,
    parseSubmit: status => ({
      text: status.text,
      value: status.value,
    }),
  },
  {
    key: 'contractStatus',
    name: 'Estado de contrato',
    component: ContractStatusSelect,
    parseSubmit: status => ({
      text: status.text,
      value: status.value,
    }),
  },
  {
    key: 'notaryId',
    name: 'Notaría',
    component: NotarySelect,
    parseInputValue: notary => notary.id,
    parseSubmit: notary => ({
      text: notary.name,
      value: notary.id,
    }),
  },
  { key: 'kardex', name: 'Kardex', component: VTextField },
];

export default {
  name: 'filters',

  functional: true,

  render(h, context) {
    return h(
      DynamicFilters,
      {
        props: {
          ...context.props,
          fieldTypes: FIELD_TYPES,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
