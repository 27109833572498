/**
 * basicFormMixin
 * @param {Object} config - Configuración
 * @param {String} config.modelKey - Model key
 * @param {parseEntityFnCallback} config.mapPropsToData - Function used to parse props to data
 * @param {boolean} config.veeValidation - Enable/Disable vee validation
 * @param {submitDataFnCallback} config.parseSubmit - Function used to parse data for $emit
 */
const basicFormMixin = ({
  modelKey,
  mapPropsToData,
  veeValidation = false,
  parseSubmit,
  defaultHiddenFields = [],
} = {}) => {
  if(!mapPropsToData) throw new Error('mapPropsToData is required');

  return {
    props: {
      [modelKey]: { type: Object, default: () => ({}) },
      requesting: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      cancelable: { type: Boolean, default: true, },
      actions: { type: Boolean, default: true },
      hiddenFields: { type: Array, default: () => defaultHiddenFields },
      disabledFields: { type: Array, default: () => [] },
      submitButtonText: { type: String, default: 'Guardar' },
      cancelButtonText: { type: String, default: 'Cancelar' }
    },

    data() {
      return {
        data: {
          ...mapPropsToData.call(this, this),
        },
      };
    },

    computed: {
      isDisabled() {
        return this.requesting || this.disabled;
      },
    },

    methods: {
      /**
       * Event handlers
       */
      onCancel() {
        this.$emit('cancel');
      },

      async onSubmit() {
        try {
          await this.validate();

          this.$emit('submit', this.getSubmitData());
        } catch {
          return;
        }
      },

      /**
       * Public methods
       */
      async validate() {
        if(veeValidation) {
          const success = await this.$refs.form.validate()

          return success ? Promise.resolve() : Promise.reject();
        } else {
          return Promise.resolve(true);
        }
      },

      async getFormData() {
        try {
          await this.validate();

          return Promise.resolve(this.getSubmitData());
        } catch {
          return Promise.resolve(false);
        }
      },

      getSubmitData() {
        return parseSubmit ? parseSubmit.call(this) : { [modelKey]: this.data };
      },

      renderField(name) {
        const hiddenfields = [...this.hiddenFields || [], ...this.internalHiddenFields || []];

        return !hiddenfields.includes(name);
      },

      mapPropsToData() {
        this.data = {...mapPropsToData.call(this, this) }
      },
    },

    watch: {
      [modelKey]() {
        this.mapPropsToData();
      },
    },
  }
};

export default basicFormMixin;
