<template lang="pug">
  div(:class="b()")
    div(:class="b('main-cards')")
      div(v-if="!hideEstimatedIncomes && estimatedIncomes" :class="b('main-card')")
        div(:class="b('main-card-title')") Ingresos estimados
        div(:class="b('main-card-content')")
          span(v-if="estimatedIncomes.initialRange > 0") {{ estimatedIncomes.initialRange | formatCurrency(undefined, { precision: 0 }) }}
          span(v-if="estimatedIncomes.initialRange > 0 && estimatedIncomes.endRange > 0") &nbsp;-&nbsp;
          span(v-if="estimatedIncomes.endRange > 0") {{ estimatedIncomes.endRange | formatCurrency(undefined, { precision: 0 }) }}
      div(:class="b('main-card')")
        div(:class="b('main-card-title')") Deuda Total
        div(:class="b('main-card-content')") {{ totalDebt | formatCurrency}}
    div(:class="b('body')")
      div(:class="b('totals')")
        div(
          v-for="total, idx in totals"
          :key="idx"
          :class="b('totals-item')"
          v-html="total"
        )
</template>

<script>
import { formatNumber } from '@/filters/accounting';

export default {
  name: 'totals-summary',

  props: {
    results: { type: Object },
    hideEstimatedIncomes: { type: Boolean, default: false },
  },

  computed: {
    totals() {
      return [
        `Tiene <b>${this.results?.creditCards?.creditCard?.length || 0}</b> tarjetas de crédito por un total de <b>S/ ${formatNumber(this.creditCardsTotal)}</b>`,
        `Tiene <b>${this.results?.consumerLoan?.lastPeriod?.length || 0}</b> créditos por un total de <b>S/ ${formatNumber((this.results?.consumerLoan?.lastPeriod || []).reduce((acc, { value }) => acc + Number(value), 0))}</b>`,
        `Tiene <b>${this.results?.mortgage?.lastPeriod?.length || 0}</b> hipotecas por un total de <b>S/ ${formatNumber((this.results?.mortgage?.lastPeriod || []).reduce((acc, { value }) => acc + Number(value), 0))}</b>`,
        `Tiene <b>${this.results?.vehicularCredit.length || 0}</b> créditos vehiculares por un total de <b>S/ ${formatNumber((this.results?.vehicularCredit || []).reduce((acc, { value }) => acc + Number(value), 0))}</b>`,
      ];
    },

    estimatedIncomes() {
      return this.results?.incomePredictors?.length ?
        {
          initialRange: Number(this.results.incomePredictors[0].initialRange),
          endRange: Number(this.results.incomePredictors[0].endRange),
        } : null;
    },

    totalDebt() {
      return (this.results?.creditsAmount || []).reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0);
    },

    creditCardsTotal() {
      return (this.results?.creditCards?.creditCard || [])
        .reduce(
          (acc, { lineUtil, tarjCreDisEfe, tarjCredOtros }) => acc +
            Number(lineUtil) +
            Number(tarjCreDisEfe) +
            Number(tarjCredOtros)
          , 0
        );
    }
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .totals-summary
    &__totals
      margin-bottom: 12px

    &__main-cards
      margin: -8px -8px 12px -8px

      +sm
        display: flex

    &__main-card
      margin: 8px
      min-width: 200px
      padding: 8px 14px
      border-radius: 6px
      background-color: darken(white, 3%)

      +lg
        min-width: 220px

      &-title
        font-weight: 500

      &-content
        font-size: 24px
        font-weight: bold
        text-align: center
</style>

<style lang="sass">
  .totals-summary
    .credits-amount
      &__table-container
        max-width: 700px !important
        margin: 0

</style>