<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).px-2
          validation-provider(name="nombre" :rules="rules.firstName" v-slot="{ errors }")
            v-text-field(
              v-model="data.firstName"
              :error-messages="errors"
              name="nombre"
              label="Nombre"
              autofocus
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="apellido paterno" :rules="rules.lastName" v-slot="{ errors }")
            v-text-field(
              v-model="data.lastName"
              :error-messages="errors"
              name="apellido paterno"
              label="Apellido paterno"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="apellido materno" :rules="rules.maternalLastName" v-slot="{ errors }")
            v-text-field(
              v-model="data.maternalLastName"
              :error-messages="errors"
              name="apellido materno"
              label="Apellido materno"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="tipo de documento" :rules="rules.vatTypeId" v-slot="{ errors }")
            vat-type-select(
              v-model="data.vatTypeId"
              :error-messages="errors"
              :default-items="vatTypeDefaultItems"
              :lazy="lazySelects"
              name="tipo de documento"
              label="Tipo de Documento"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="numero de identificacion" :rules="rules.vat" v-slot="{ errors }")
            v-text-field(
              v-model="data.vat"
              :error-messages="errors"
              name="numero de identificacion"
              label="Número de Identifiación"
              required
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="fecha de nacimiento" :rules="rules.birth" v-slot="{ errors }")
            date-picker(
              v-model="data.birth"
              :error-messages="errors"
              label="Fecha Nacimiento"
              name="fecha de nacimiento"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="celular" :rules="rules.mobile" v-slot="{ errors }")
            phone-input(
              v-model="data.mobile"
              :error-messages="errors"
              label="Celular"
              name="celular"
              prepend-icon="mdi-cellphone-android"
            )
        v-flex(xs12).px-2
          validation-provider(name="dirección" :rules="'required'" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.line1"
              :error-messages="errors"
              name="dirección"
              label="dirección"
            )
        v-flex(
          v-for="division, idx in politicalDivisions"
          v-show="idx > 0"
          :key="idx"
          xs12 md6
        ).px-2
          validation-provider(:name="division.name" :rules="'required'" v-slot="{ errors }")
            location-select(
              :value="$addressInput.getValue(idx)"
              :error-messages="errors"
              :name="division.name"
              :disabled="$addressInput.isDisabled(idx)"
              :parent-id="$addressInput.getParentId(idx)"
              :default-items="$addressInput.getDefaultItems(idx)"
              :label="division.name"
              @input="value => $addressInput.onInput(value, idx)"
            )
        v-flex(
          xs12 md6
        ).px-2
          validation-provider(name="código postal" :rules="rules.address && rules.address.zipCode" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.zipCode"
              :error-messages="errors"
              name="código postal"
              label="código postal"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          type="submit"
          color="primary"
          text
        ) Enviar NIP
</template>

<script>
import Config from '@/config';
import omit from 'lodash/omit';
import last from 'lodash/last';
import PhoneInput from '@/components/inputs/phone-input';
import DatePicker from '@/components/inputs/input-date-picker';
import VatTypeSelect from '@/components/inputs/vat-type-select';
import addressInputMixin from '@/mixins/address-input-mixin';

import basicFormMixin from './basic-form-mixin';

export default {
  name: 'request-nip-form',

  props: {
    lazySelects: { type: Boolean, default: false },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'customer',
      veeValidation: true,
      mapPropsToData({ customer }) {
        return {
          id: customer?.id || null,
          firstName: customer?.firstName || null,
          lastName: customer?.lastName || null,
          maternalLastName: customer?.maternalLastName || null,
          mobile: customer?.mobile || null,
          vat: customer?.vat || null,
          vatTypeId: customer?.vatType?.id || '1',
          birth: customer?.birth || null,
          address: {
            ...customer?.address,
            _locationIds: customer?.address?.location?.length ? [...customer.address.location].map(({ id }) => id) : ['1'],
          },
        };
      },

      parseSubmit() {
        return {
          customer: {
            ...this.data,
            address: this.data.address._locationIds.length ? {
              ...omit(this.data.address, ['id', '__typename', 'location', '_locationIds']),
              locationId: last(this.data.address._locationIds)
            } : undefined,
          },
        };
      }
    }),

    addressInputMixin(),
  ],

  computed: {
    rules() {
      return Config.BURO_FORM_RULES({ data: this.data });
    },

    vatTypeDefaultItems() {
      return this.customer?.vatType ? [{ id: this.customer?.vatType?.id, description: this.customer?.vatType?.description }] : [];
    },
  },

  components: {
    DatePicker,
    PhoneInput,
    VatTypeSelect,
  },
};
</script>

<style lang="sass" scoped>

</style>