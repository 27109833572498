<template lang="pug">
  div(:class="b()" :style="{ backgroundColor: color }") {{ $t(`quoteStatus.${status}`)}}
</template>

<script>
import { QUOTE_STATUS } from '@/utils/constants';

const COLORS = {
  [QUOTE_STATUS.PAID]: '#4caf50',
  [QUOTE_STATUS.EXPIRED]: '#f44336',
  [QUOTE_STATUS.PENDING]: '#9c8ade',
  [QUOTE_STATUS.PAID_DELAYED]: '#8ec9bb',
  [QUOTE_STATUS.RESCHEDULED]: '#c37432',
  [QUOTE_STATUS.EXTENDED]: '#e50b0b'
};

export default {
  name: 'quote-status-tag',

  props: {
    status: { type: String, required: true },
  },

  computed: {
    color() {
      return COLORS[this.status];
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .quote-status-tag
    display: flex
    align-items: center
    justify-content: center
    padding: 2px 12px
    border-radius: 4px
    text-transform: uppercase
    font-size: 9px
    font-weight: 500
    color: white

    +md
      padding: 2px 12px
      font-size: 11px
</style>
