<template lang="pug">
  metrics-layout
    filters(
      slot="filters"
      :filters="filters"
      @submit="onSubmitFilters"
    )
    div(:class="b()")
      loading-wrapper(v-if="$apollo.queries.metrics.loading || !metrics")
      template(v-else)
        v-card.px-2.py-4
          div(:class="b('title')").mb-1 Promedio de días para pagar
          .d-flex.justify-center.mb-2
            v-chip(color="primary")
              v-icon(left) mdi-account-group
              span {{ borrowersCount }} Prestatarios
          div(:class="b('table-container')")
            borrowers-payment-days-average-table(:entries="borrowersFeePaymentDaysAvg")
          div(:class="b('title')") Días para pagar por prestatario
          borrowers-payment-days-diff-legend.mb-2
          div(:class="b('table-container')")
            borrowers-payment-days-diff-table(:entries="borrowersFeePaymentDaysDiff")
</template>

<script>
import Config from '@/config';
import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { BorrowersMetricsQuery } from './graphql/queries.gql';

import MetricsLayout from '../../metrics/components/metrics-layout';
import BorrowersPaymentDaysDiffTable from './components/borrowers-payment-days-diff-table';
import BorrowersPaymentDaysDiffLegend from './components/borrowers-payment-days-diff-legend';
import BorrowersPaymentDaysAverageTable from './components/borrowers-payment-days-average-table';
import Filters from './components/filters';

export default {
  name: 'borrowers-metrics-view',

  data() {
    return {
      metrics: null,
      currentTab: null,

      filters: {
        country: Config.COUNTRY_CODE.toUpperCase(),
        disbursementDate: [],
        status: ["COLLECTING"],
        borrowerIds: [],
        investorIds: [],
      },
    };
  },

  apollo: {
    metrics: {
      query: BorrowersMetricsQuery,

      variables() {
        const { country, disbursementDate, status, borrowerIds, investorIds } = this.filters;

        return {
          country,
          fromDisbursementDate: disbursementDate?.[0],
          toDisbursementDate: disbursementDate?.[1],
          status: status?.length ? status : undefined,
          borrowerIds: borrowerIds?.length ? borrowerIds : undefined,
          investorIds: investorIds?.length ? investorIds : undefined ,
        };
      },
    },
  },

  methods: {
    onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.metrics.refetch();
        }
        else
          Object.assign(this.filters, filters);
    },
  },

  computed: {
    borrowersFeePaymentDaysDiff() {
      return this.metrics?.tables?.borrowersFeePaymentDaysDiff?.entries;
    },

    borrowersFeePaymentDaysAvg() {
      return this.metrics?.tables?.borrowersFeePaymentDaysAvg;
    },

    borrowersCount() {
      return this.borrowersFeePaymentDaysDiff?.length || 0;
    },
  },

  components: {
    MetricsLayout,
    Filters,
    LoadingWrapper,
    BorrowersPaymentDaysDiffTable,
    BorrowersPaymentDaysDiffLegend,
    BorrowersPaymentDaysAverageTable,
  },
};
</script>

<style lang="sass" scoped>
  .borrowers-metrics-view
    &__table-container
      padding: 16px
      overflow-x: auto

    &__title
      text-align: center
      font-size: 1.2em
      font-weight: bold
      color: darken(white, 55%)
</style>
