<template lang="pug">
	div
		div(style="display: flex; justify-content: space-between; text-align: center;")
			b.col-md-3(:style="{color: getColor('4')}") {{ getText('4') }}
			b.col-md-3(:style="{color: getColor('3')}") {{ getText('3') }}
			b.col-md-3(:style="{color: getColor('2')}") {{ getText('2') }}
			b.col-md-3(:style="{color: getColor('1')}") {{ getText('1') }}
		.sentinel-table
			table
				thead
					tr
						th Tipo Doc
						th Nro Documento
						th Nombre / Razón Social
						th Actual
						th Previo
						th 12 Meses
						th Garantías
				tbody
					template(v-for='item in guarantor')
						tr
							td {{ item?.doc_type }}
							td {{ item?.doc_number }}
							td {{ item?.legal_name }}
							td(style="text-align: center")
								span(:style="{backgroundColor: getColor(item?.current_color), borderRadius: '50%', width: '20px', height: '20px', display: 'inline-block'}")
							td(style="text-align: center")
								span(:style="{backgroundColor: getColor(item?.last_color), borderRadius: '50%', width: '20px', height: '20px', display: 'inline-block'}")
							td(style="text-align: center")
								span(:style="{backgroundColor: getColor(item?.twelve_months_color), borderRadius: '50%', width: '20px', height: '20px', display: 'inline-block'}")
							td(colspan=3)
								table
									thead
										tr
											th Tipo Doc
											th Nro Documento
											th Nombre / Razón Social
											th Calificación
											th Fecha Reporte
											th Tipo
											th Deuda
									tbody
										tr(v-for='detail in item?.quarantee')
											td {{ detail?.doc_type }}
											td {{ detail?.doc_number }}
											td {{ detail?.legal_name }}
											td {{ detail?.qualifitacion }}
											td {{ detail?.report_date | formatDate}}
											td {{ detail?.type }}
											td {{ detail?.debt }}

</template>

<script>
	export default {
		name: 'sentinel-guarantor',
		props: {
			guarantor: {
				type: Array,
				required: true,
			},
		},
        methods: {
            getColor(value) {
                if (value === '4') {
                    return '#00ff00';
                } else if (value === '3'){
                    return '#808080';
                } else if (value === '2') {
                    return '#ffff00';
                } else if (value === '1') {
                    return '#ff0000';
                }
            },

            getText(value) {
                if (value === '4') {
                    return 'Sin deudas vencidas';
                } else if (value === '3'){
                    return 'No registra información de deudas';
                } else if (value === '2') {
                    return 'Deudas con poco atraso*';
                } else if (value === '1') {
                    return 'Deudas con atraso significativo*';
                }
            },
        }
	};
</script>

<style lang="sass" scoped>
.sentinel-table
  max-height: 200px
  overflow-x: scroll
  overflow-y: scroll
  margin-top: 20px
  display: flex
  justify-content: center
  width: 100%
  border-collapse: collapse
  border: 1px solid #ddd
  white-space: nowrap

  table
    width: 100%
    text-align: left
    display: inline-block

    th, td
      border-bottom: 1px solid #ddd
      padding: 0px 8px

    th
      background-color: #f2f2f2
</style>
