<template lang="pug">
  div(:class="b()")
    div(v-if="gestionable && canCreate" :class="b('actions')").text-right
      v-btn(
        @click="onClickCreate"
        color="primary"
        text small
      )
        v-icon mdi-plus
        span Agregar documento
    origin-group(
      v-for="group in groups"
      :key="group.origin"
      :groups="group.groups"
      :origin="group.origin"
      :requesting="requesting"
      :empty-notification="emptyNotification"
      :empty-notification-text="emptyNotificationText"
      :can-regenerate="canRegenerate"
      :gestionable="gestionable"
      @generate="args => $emit('generate', args)"
      @create="onClickCreate"
      @delete="args => $emit('delete', args)"
      @update="args => $emit('update', args)"
      @upload-attachments="args => $emit('upload-attachments', args)"
      @delete-attachment="args => $emit('delete-attachment', args)"
    )
    document-form-modal(
      v-model="formModal"
      :requesting="requesting"
      :document="creatingDocument"
      @submit="args => $emit('create', args)"
    )
</template>

<script>
  import DocumentFormModal from '@/components/modals/document-form-modal';

  import OriginGroup from './documents-origin-group';

  export default {
    name: 'documents',

    data() {
      return {
        formModal: false,
        creatingDocument: {},
      };
    },

    props: {
      groups: { type: Array, default: () => ([]) },
      gestionable: { type: Boolean, default: true },
      requesting: { type: Boolean, default: false },
      canRegenerate: { type: Boolean, default: true },
      canCreate: { type: Boolean, default: true },
      emptyNotification: { type: Boolean, default: false },
      emptyNotificationText: { type: String, default: 'No hay documentos para este cliente' },
    },

    components: {
      OriginGroup,
      DocumentFormModal,
    },

    methods: {
      onClickCreate() {
        this.formModal = true;
      },

      closeForm() {
        this.formModal = false;
      },
    },
  };
</script>