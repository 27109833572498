<template lang="pug">
  div(:class="b()")
    .create-customer-modal__step-title Datos Personales

    validation-observer(ref="form")
      form(@submit.prevent="onSubmit")
        v-layout(wrap)
          v-flex(v-if="renderField('firstName')" xs12 md6).px-2
            validation-provider(name="nombre" :rules="rules.firstName" v-slot="{ errors }")
              v-text-field(
                v-model="data.firstName"
                :error-messages="errors"
                name="nombre"
                label="Nombre"
                autofocus
              )
          v-flex(v-if="renderField('lastName')" xs12 md6).px-2
            validation-provider(name="apellido paterno" :rules="rules.lastName" v-slot="{ errors }")
              v-text-field(
                v-model="data.lastName"
                :error-messages="errors"
                name="apellido paterno"
                label="Apellido paterno"
              )
          v-flex(v-if="renderField('maternalLastName')" xs12 md6).px-2
            validation-provider(name="apellido materno" :rules="rules.maternalLastName" v-slot="{ errors }")
              v-text-field(
                v-model="data.maternalLastName"
                :error-messages="errors"
                name="apellido materno"
                label="Apellido materno"
              )
          v-flex(xs12 md6).px-2
            validation-provider(name="tipo de documento" :rules="'required'" v-slot="{ errors }")
              vat-type-select(
                v-model="data.vatTypeId"
                :error-messages="errors"
                :lazy="false"
                name="tipo de documento"
                label="Tipo de Documento"
              )
          v-flex(xs12 md6).px-2
            validation-provider(name="numero de identificacion" :rules="rules.vat" v-slot="{ errors }")
              v-text-field(
                v-model="data.vat"
                :error-messages="errors"
                name="numero de identificacion"
                label="Número de Identifiación"
                required
              )
          v-flex(xs12 md6).px-2
            validation-provider(name="correo" :rules="rules.email" v-slot="{ errors }")
              v-text-field(
                v-model="data.email"
                :error-messages="errors"
                name="correo"
                label="Correo"
                prepend-icon="mdi-email"
                autocomplete="email"
              )
          v-flex(v-if="renderField('mobile')" xs12 md6).px-2
            validation-provider(name="celular" :rules="rules.mobile" v-slot="{ errors }")
              phone-input(
                v-model="data.mobile"
                :error-messages="errors"
                label="Celular"
                name="celular"
                prepend-icon="mdi-cellphone-android"
              )
          v-flex(xs12 md6).px-2
            validation-provider(name="referido" :rules="'required'" v-slot="{ errors }")
              referral-type-select(
                v-model="referralType"
                :error-messages="errors"
                name="referido"
                label="Referido"
                @change="onChangeReferralType"
              )
          v-flex(v-if="mustFillAdvisorName" xs12 md6).px-2
            validation-provider(name="nombre de asesor" :rules="'required|alpha_spaces|min:5'", v-slot="{ errors }")
              v-text-field(
                v-model="advisorName"
                :error-messages="errors"
                label="Nombre de asesor (ejem: Juan Perez)"
                name="nombre de asesor"
              )
          v-flex(v-if="renderField('notificationsActive')" xs12).px-2
            v-checkbox(
              v-model="data.notificationsActive"
              label="¿Habilitar notificaciones?"
            )
        v-divider
        v-card-actions
          v-spacer
          v-btn(v-if="cancelable" color="error" text @click="onCancel") {{ cancelButtonText }}
          v-btn(
            :loading="requesting"
            color="primary"
            text
            type="submit"
          ) {{ submitButtonText }}
</template>

<script>
import basicFormMixin from '@/components/forms/basic-form-mixin';
import Config from '@/config';
import ReferralTypeSelect from './referral-type-select';
import { REFERRAL_TYPES } from '@/utils/constants';
import VatTypeSelect from '@/components/inputs/vat-type-select';
import PhoneInput from '@/components/inputs/phone-input';

export default {
  name: 'customer-data-form',

  mixins: [
    basicFormMixin({
      modelKey: 'customer',
      veeValidation: true,
      mapPropsToData() {
        return {
          firstName: null,
          lastName: null,
          maternalLastName: null,
          mobile: null,
          vatTypeId: '1',
          vat: null,
          email: null,
          notificationsActive: true,
          source: null,
          medium: null,
          campaign: null,
        };
      },

      parseSubmit() {
        return {
          customer: {
            ...this.data,
            campaign: this.mustFillAdvisorName
              ? this.advisorName
                .trim()
                .toLowerCase()
                .replace(/\s/g, '_')
              : this.data.campaign,
          }
        }
      }
    }),
  ],

  data() {
    return {
      referralType: null,
    };
  },

  computed: {
    rules() {
      return Config.CUSTOMER_FORM_RULES({ data: this.data });
    },

    mustFillAdvisorName() {
      return this.referralType === REFERRAL_TYPES.ADVISOR_NETWORK;
    },
  },

  methods: {
    onChangeReferralType(type) {
      switch (type) {
        case REFERRAL_TYPES.CUSTOMER_NETWORK:
          this.data.source = 'referral';
          this.data.medium = 'customer_network';
          this.data.campaign = 'clientes'
          break;
        case REFERRAL_TYPES.ADVISOR_NETWORK:
          this.data.source = 'referral';
          this.data.medium = 'advisor_network';
          this.data.campaign = null;
          this.advisorName = null;
          break;

        case REFERRAL_TYPES.OTHER_NETWORK:
          this.data.source = 'referral';
          this.data.medium = null;
          this.data.campaign = null;
          break;
      }
    },
  },

  components: {
    VatTypeSelect,
    PhoneInput,
    ReferralTypeSelect,
  },
};
</script>

<style lang="sass" scoped>

</style>