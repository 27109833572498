import VSelect from 'vuetify/lib/components/VSelect';

export default {
  name: 'group-by-select',

  extends: VSelect,

  props: {
    items: { type: Array, default: () => [
      { value: 'DAY', text: 'Día' },
      { value: 'WEEK', text: 'Semana' },
      { value: 'MONTH', text: 'Mes' },
      { value: 'QUARTER', text: 'Quarter' },
    ]},
    label: { type: String, default: 'Agrupar' },
  },
};
