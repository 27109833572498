<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="300")
    authorize-nip-form(
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import AuthorizeNipForm from '@/components/forms/authorize-nip-form';

  export default {
    name: 'authorize-nip-form-modal',

    mixins: [formModalMixin()],

    components: { AuthorizeNipForm },
  };
</script>
