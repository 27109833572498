<template lang="pug">
  div(:class="b()")
    v-card.pa-2
      v-breadcrumbs(
        :items="breadcrumbItems"
      )
    v-container(fluid)
      loading-wrapper(
        v-if="!deal || dealError"
        :error="dealError"
        @retry="onRetryDeal"
      )
      v-layout(v-else wrap)
        v-flex(xs12 md4)
          deal-card(
            :deal="deal"
            :reminders="reminders"
            :can-generate-commercial-file="canGenerateCommercialFile"
            :commercial-file-url="deal.commercialFile"
            @generate-commercial-file="onGenerateCommercialFile"
            @change-stage="onChangeStage"
            @change-status="onChangeStatus"
            @change-motive="onChangeMotive"
            @click-reminders="remindersModal = true"
          ).ma-3
          requirements-card(
            ref="requirementsCard"
            v-if="deal.requirements.length"
            :requirements="deal.requirements"
            :requesting="requirementsIsMutating"
            @change-status="onChangeRequirementStatus"
            @create="onCreateRequirement"
            @delete="onDeleteRequirement"
          ).ma-3
        v-flex(xs12 md8)
          v-card.ma-3
            v-tabs(v-model="currentTab")
              v-tab Mensajes
              v-tab Historial
              v-tab Propuestas
              v-tab Documentos
              v-tab(v-if="$projectConfig.RCI_ENABLED") Riesgos
              v-tab(v-if="$projectConfig.FORMALIZATIONS_ENABLED && canSeeFormalizationTab") Formalizacion
              v-tab-item.pa-3
                messages(
                  ref="messages"
                  :requesting="creatingMessage"
                  :messages="deal.messages"
                  :current-user="currentUser"
                  @create="onCreateMessage"
                  @delete="onDeleteMessage"
                )
                  template(slot="form-actions")
                    v-btn(
                      @click="activityModal = true"
                      :disabled="creatingMessage"
                      type="button"
                      color="secondary"
                    ) Registrar actividad
              v-tab-item.pa-3
                timeline(:timeline="deal.timeline")
              v-tab-item.pa-3
                deal-summary(:deal="deal")
                proposals(
                  ref="proposals"
                  :proposals="proposals"
                  :requesting="proposalIsMutating"
                  :can-disapprove="canDisapproveProposals"
                  @enable="onEnableProposal"
                  @disable="onDisableProposal"
                  @update="onUpdateProposal"
                  @approve="onApproveProposal"
                  @disapprove="onDisapproveProposal"
                )
              v-tab-item.pa-3
                v-tabs(v-model="currentDocumentsTab")
                  v-tab Documentos Titular
                  v-tab(v-if="customer && customer.couple") Documentos Pareja
                  v-tab-item
                    customer-documents(
                      :customer-id="customer && customer.id"
                      ref="documents"
                    )
                  v-tab-item
                    customer-documents(
                      :customer-id="customer && customer.couple && customer.couple.id"
                      ref="documents"
                    )
              v-tab-item(v-if="$projectConfig.RCI_ENABLED").pa-3
                debts(
                  ref="debts"
                  :deal-id="dealId"
                  :customer="customer"
                  :couple="customer && customer.couple"
                  @change-rci="onChangeRci"
                )
                risk-file-actions(
                  v-if="riskFileActionsEnabled && canSeeRiskFileActions"
                  :disabled="!currentRci"
                  :validations="rciValidations"
                  :fetching="$apollo.queries.riskFiles.loading"
                  :is-notificating="isNotificatingRiskFileOpinion"
                  :creating="isWaitingForRiskFile"
                  :risk-file="currentRiskFile"
                  :can-visit="canVisitRiskFile"
                  :can-notify="canNotifyRiskFileOpinion"
                  @hook:mounted="skipRiskFileQuery = false"
                  @click-create-risk-file="onClickCreateRiskFile"
                  @click-notify="onClickNotifyRiskFileOpinion"
                  :currency="currency"
                ).pa-2
              v-tab-item(v-if="$projectConfig.FORMALIZATIONS_ENABLED && canSeeFormalizationTab").pa-3.pb-4
                formalization-tab(
                  ref="formalizationTab"
                  :formalization="formalization"
                  :creating="isCreatingFormalization"
                  :fetching="$apollo.queries.formalization.loading"
                  :requesting-referrals="isMutatingFormalizationReferrals"
                  :can-visit="canVisitFormalization"
                  @hook:mounted="skipFormalizationQuery = false"
                  @click-create-formalization="onClickCreateFormalization"
                  @submit-create-referral="onSubmitCreateFormalizationReferralForm"
                  @submit-update-referral="onSubmitUpdateFormalizationReferralForm"
                )
          v-layout(wrap)
            v-flex(xs12 md7)
              properties(
                ref="properties"
                v-if="customer && customer.properties"
                :properties="customer.properties"
                :customer="customer"
                :requesting="propertyIsMutating"
                :requesting-credit="creditIsMutating"
                @delete="onDeleteProperty"
                @create="onCreateProperty"
                @create-credit="onCreateCredit"
                @update="onUpdateProperty"
                @update-credit="onUpdateCredit"
                @delete-credit="onDeleteCredit"
                @add-electronic-record="onCreatePropertiesCertificates"
              ).ma-3
            v-flex(xs12 md5)
              incomes(
                ref="incomes"
                v-if="customer && customer.incomes"
                :customer="customer"
                :requesting="incomeIsMutating"
                @create="onCreateIncome"
                @update="onUpdateIncome"
                @delete="onDeleteIncome"
              ).ma-3.mb-4
              incomes(
                ref="coupleIncomes"
                v-if="customer && customer.couple"
                :customer="customer.couple"
                :requesting="incomeIsMutating"
                @create="onCreateIncome"
                @update="onUpdateIncome"
                @delete="onDeleteIncome"
                is-couple
              ).ma-3
        reminders-modal(
          v-model="remindersModal"
          :reminders="reminders"
          :loading="$apollo.queries.reminders.loading"
          @complete="onCompleteReminder"
          @delete="onDeleteReminder"
          @change-page="onChangeRemindersPage"
          @click-add="reminderFormModal = true"
        )
        reminder-form-modal(
          v-model="reminderFormModal"
          :requesting="creatingReminder"
          :current-user-id="currentUser.id"
          @submit="onCreateReminder"
        )
        activity-question-modal(
          v-model="activityQuestionModal"
          @submit="onSubmitActivityQuestion"
        )
        activity-form-modal(
          v-model="activityModal"
          :requesting="creatingActivity"
          @submit="onCreateActivity"
        )
        create-risk-file-modal(
          v-model="createRiskFileModal"
          :answers="deal.riskAssessmentRequest"
          :requesting="creatingRiskFile"
          :deal="deal"
          :customer="customer"
          :couple="couple"
          @submit="onSubmitCreateRiskFileFormModal"
          @update-answers="onUpdateRiskAnswers"
        )
</template>

<script>
import waitUntil from 'wait-until';
import moment from 'moment';

import {
  PERMISSIONS,
  DEAL_STATUS,
  RISK_OPINION_STATUS,
  REMINDER_STATUS,
  NEW_MORTGAGE_SLUG,
  BUY_MORTGAGE_SLUG,
  HOME_EQUITY_LOAN_SLUG,
} from '@/utils/constants';
import { unparse as unparseRCIJson } from '@/components/smart/debts/utils/rci-json-parser';
import { GenerateCommercialFileMutation } from '@/graphql/mutations.gql';
import ActivityFormModal from '@/components/modals/activity-form-modal';
import Config from '@/config';
import currentUserMixin from '@/mixins/current-user-mixin';
import customerIncomesMixin from '@/mixins/customer-incomes-mixin';
import customerPropertiesMixin from '@/mixins/customer-properties-mixin';
import rciValidationsMixin from '@/mixins/rci-validations-mixin';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import Proposals from '@/components/lists/proposals';
import ReminderFormModal from '@/components/modals/reminder-form-modal';
import Debts from '@/components/smart/debts';
import CustomerDocuments from '@/components/smart/customer-documents';
import CreateRiskFileModal from '@/components/smart/create-risk-file-modal';
import Messages from '@/components/UI/messages';

import {
  DealActiveDealsQuery,
  DealCustomerQuery,
  DealQuery,
  RemindersQuery,
  DealFormalizationQuery,
  DealRiskFileQuery,
} from './graphql/queries.gql';
import {
  DealApproveProposalMutation,
  CreateActivityMutation,
  CreateMessageMutation,
  CreateReminderMutation,
  CreateRequirementMutation,
  CreateRiskFileMutation,
  DeleteMessageMutation,
  DeleteReminderMutation,
  DeleteRequirementMutation,
  DealDisapproveProposalMutation,
  MoveDealToStageMutation,
  UpdateDealMutation,
  DealUpdateProposalMutation,
  UpdateReminderMutation,
  UpdateRequirementStatusMutation,
  CreateDealFormalizationMutation,
  CreateDealFormalizationReferralMutation,
  UpdateDealFormalizationReferralMutation,
  SendRiskFileEventMutation,
} from './graphql/mutations.gql';
import ActivityQuestionModal from './components/register-activity-question-modal';
import CreateRiskFileFormModal from './components/create-risk-file-form-modal';
import RiskFileActions from './components/risk-file-actions';
import DealCard from './components/deal-card';
import RemindersModal from './components/reminders-modal';
import RequirementsCard from './components/requirements-card';
import Timeline from './components/timeline';
import DealSummary from './components/deal-summary';
import FormalizationTab from './components/formalization-tab';
import {
  getNotifiedOpinionsId,
  setNotifiedOpinionsId,
} from './utils/risk-file-notify-storage';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

const PROPOSALS_TAB_INDEX = 3;
const PE_HEL_DISABLED_REQUIREMENTS = ['2', '4'];

const parseRequirements = (requirements, productSlug) =>
  IS_PE_COUNTRY && productSlug === HOME_EQUITY_LOAN_SLUG
    ? requirements.map(requirement => ({
        ...requirement,
        disabled: PE_HEL_DISABLED_REQUIREMENTS.includes(requirement.typeId),
      }))
    : requirements;

const makeProposalReplacerObject = newProposal => ({
  deal: {
    proposals: {
      $apply: proposals =>
        proposals.map(cProposal =>
          cProposal.id !== newProposal.id ? cProposal : newProposal,
        ),
    },
  },
});

export default {
  name: 'deals-show-view',

  mixins: [
    mutationsHandlersMixin(),
    currentUserMixin(),
    customerPropertiesMixin(),
    customerIncomesMixin({ getQueryMethod: 'getCustomerQuery' }),
    rciValidationsMixin(),
  ],

  apollo: {
    deal: {
      query: DealQuery,

      variables() {
        return { id: this.dealId };
      },

      update({ deal }) {
        if (!deal) return deal;

        return {
          ...deal,
          requirements: parseRequirements(deal.requirements, deal.product.slug),
        };
      },

      error() {
        this.dealError = new Error('Ha ocurrido un error al obtener los datos');
      },
    },

    customer: {
      query: DealCustomerQuery,

      variables() {
        return { id: this.deal?.customer?.id };
      },

      skip() {
        return !this.deal?.customer?.id;
      },
    },

    reminders: {
      query: RemindersQuery,
      fetchPolicy: 'cache-and-network',

      variables() {
        return {
          page: this.currentRemindersPage,
          dealId: this.dealId,
        };
      },

      skip() {
        return !this.deal;
      },
    },

    activeDeals: {
      query: DealActiveDealsQuery,
      fetchPolicy: 'cache-and-network',

      variables() {
        return { customerId: this.deal?.customer?.id };
      },

      skip() {
        return !this.deal?.customer?.id;
      },
    },

    riskFiles: {
      query: DealRiskFileQuery,
      manual: true,
      fetchPolicy: 'cache-and-network',

      variables() {
        return { dealId: this.dealId };
      },

      result({ data }) {
        if (!data?.riskFile) return;

        this.currentRiskFile = data.riskFile;

        if (!this.currentRiskFile) return;

        const { createdAt, status } = this.currentRiskFile;

        if (this.isWaitingForRiskFile) {
          if (this.riskFileCreateRetries >= 10) {
            this.currentRiskFile.errorMessage =
              'No se pudo obtener información sobre el estado del expediente de riesgos';

            return this.stopWaitForRiskFile();
          } else if (
            moment(createdAt).isAfter(this.riskFileCreateTimestamp) &&
            [
              RISK_OPINION_STATUS.PENDING,
              RISK_OPINION_STATUS.CREATED,
              RISK_OPINION_STATUS.FAILED,
            ].includes(status)
          ) {
            return this.stopWaitForRiskFile();
          }

          this.riskFileCreateRetries++;
        }
      },

      skip() {
        return (
          !Config.RISK_FILES_ENABLED || !this.dealId || this.skipRiskFileQuery
        );
      },
    },

    formalization: {
      query: DealFormalizationQuery,
      fetchPolicy: 'cache-and-network',

      variables() {
        return { dealId: this.dealId };
      },

      skip() {
        return (
          !Config.FORMALIZATIONS_ENABLED ||
          !this.dealId ||
          this.skipFormalizationQuery
        );
      },
    },
  },

  props: {
    currency: { type: Object, default: () => {} },
  },

  data() {
    return {
      deal: null,
      activeDeals: null,
      dealError: null,
      customer: null,
      formalization: null,
      currentRemindersPage: 1,
      completingReminder: null,
      notifiedOpinionsId: getNotifiedOpinionsId(),
      reminders: {
        page: 1,
        pages: 1,
        count: 0,
        objects: [],
      },
      remindersModal: false,
      currentRiskFile: null,
      currentTab: 0,
      currentRci: null,
      riskCurrentTab: 0,
      currentDocumentsTab: 0,
      riskFileCreateTimestamp: null,
      riskFileCreateRetries: 0,
      riskFilesCountBeforeWaiting: 0,
      isWaitingForRiskFile: false,
      riskFileActionsEnabled: Config.RISK_FILES_ENABLED,
      skipFormalizationQuery: true,
      skipRiskFileQuery: true,

      // Modals
      activityModal: false,
      activityQuestionModal: false,
      reminderFormModal: false,
      createRiskFileModal: false,

      // Mutation state keys
      creatingActivity: false,
      creatingMessage: false,
      creatingReminder: false,
      creatingRiskFile: false,
      proposalIsMutating: false,
      requirementsIsMutating: false,
      isCreatingFormalization: false,
      isMutatingFormalizationReferrals: false,
      isNotificatingRiskFileOpinion: false,
    };
  },

  methods: {
    getCurrentQuery() {
      return {
        query: DealQuery,
        variables: { id: this.$route.params.dealId },
      };
    },

    getCustomerQuery() {
      return {
        query: DealCustomerQuery,
        variables: { id: this.deal?.customer?.id },
      };
    },

    getRemindersQuery(variables = {}) {
      return {
        query: RemindersQuery,
        variables: {
          dealId: this.dealId,
          page: this.currentRemindersPage,
          ...variables,
        },
      };
    },

    getFormalizationQuery() {
      return {
        query: DealFormalizationQuery,
        variables: { dealId: this.deal.id },
      };
    },

    onChangeStage({ stage }) {
      this.handleUpdateMutation({
        mutation: MoveDealToStageMutation,

        variables: {
          dealId: this.deal.id,
          stageId: stage,
        },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            moveDealToStage: { deal: movedDeal },
          },
        }) =>
          movedDeal
            ? {
                deal: { $set: movedDeal },
              }
            : null,
      });
    },

    onUpdateRiskAnswers(answers) {
      this.handleUpdateMutation({
        mutation: UpdateDealMutation,
        successMessage: null,

        variables: {
          data: {
            id: this.deal.id,
            riskAssessmentRequest: answers,
          },
        },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            updateDeal: { deal: updatedDeal },
          },
        }) =>
          updatedDeal
            ? {
                deal: { $set: updatedDeal },
              }
            : null,
      });
    },

    onChangeStatus(data) {
      this.handleUpdateMutation({
        mutation: UpdateDealMutation,

        variables: {
          data: {
            id: this.deal.id,
            ...data,
          },
        },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            updateDeal: { deal: updatedDeal },
          },
        }) =>
          updatedDeal
            ? {
                deal: { $set: updatedDeal },
              }
            : null,
      });

      /**
       * Debido a que vuetify 2 hace que las v-tabs sean lazy debemos hacerle focus a la
       * tab respectiva para acceder al component, en algunos dispositivos esto puede
       * tardar bastante por lo cual hacemos uso del wait-until
       */
      if (this.acceptedProposal && data.status === DEAL_STATUS.WON) {
        this.currentTab = PROPOSALS_TAB_INDEX;

        waitUntil(
          250,
          12,
          () => {
            return this.$refs.proposals !== undefined;
          },
          results => {
            if (results)
              this.$refs.proposals.openProposalEdit(this.acceptedProposal.id);
          },
        );
      }
    },

    onChangeMotive({ motive }) {
      this.handleUpdateMutation({
        mutation: UpdateDealMutation,

        variables: {
          data: {
            id: this.deal.id,
            motive,
          },
        },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            updateDeal: { deal: updatedDeal },
          },
        }) =>
          updatedDeal
            ? {
                deal: { $set: updatedDeal },
              }
            : null,
      });
    },

    onCreateMessage({ body }) {
      this.handleUpdateMutation({
        mutation: CreateMessageMutation,

        requestingStateKey: 'creatingMessage',

        variables: {
          dealId: this.deal.id,
          body,
        },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            createMessage: { message: createdMessage },
          },
        }) =>
          createdMessage
            ? {
                deal: {
                  messages: { $unshift: [createdMessage] },
                },
              }
            : null,
      })
        .then(() => {
          this.$refs.messages?.clearForm();
          this.$apollo.queries.deal?.refetch();
        })
        .catch(() => null);
    },

    onDeleteMessage({ message }) {
      this.handleDeleteMutation({
        mutation: DeleteMessageMutation,

        variables: { id: message.id },

        query: this.getCurrentQuery(),

        updateQueryFn: () => ({
          deal: {
            messages: {
              $apply: messages =>
                messages.filter(cMessage => cMessage.id !== message.id),
            },
          },
        }),
      });
    },

    onChangeRequirementStatus({ requirement, statusId }) {
      this.handleUpdateMutation({
        mutation: UpdateRequirementStatusMutation,

        variables: {
          requirementId: requirement.id,
          statusId,
        },

        // query: this.getCurrentQuery(),

        // updateQueryFn: ({
        //   resultsData: { updateRequirementStatus: { requirement: updatedRequirement }} // eslint-disable-line
        // }) => {
        //   return {
        //     deal: { requirements: [] },
        //   };
        // },
        // updatedRequirement ? ({
        //   deal: {
        //     requirements: {
        //       $apply: requirements => requirements.map(
        //         cRequirement => cRequirement.id === requirement.id ?
        //         updatedRequirement :
        //         cRequirement,
        //       )
        //     },
        //   },
        // }) : null,
      })
        .then(() => this.$apollo.queries.deal?.refetch())
        .catch(() => null);
    },

    onCreateRequirement({ id }) {
      this.handleUpdateMutation({
        mutation: CreateRequirementMutation,

        variables: {
          dealId: this.deal.id,
          requirementId: id,
        },

        query: this.getCurrentQuery(),

        requestingStateKey: 'requirementsIsMutating',

        updateQueryFn: ({
          resultsData: {
            createRequirement: { requirement: newRequirement },
          },
        }) =>
          newRequirement
            ? {
                deal: {
                  requirements: {
                    $unshift: [newRequirement],
                  },
                },
              }
            : null,
      })
        .then(() => this.$refs.requirementsCard.closeForm())
        .catch(() => null);
    },

    onDeleteRequirement({ requirement }) {
      this.handleDeleteMutation({
        mutation: DeleteRequirementMutation,

        variables: {
          id: requirement.id,
        },

        query: this.getCurrentQuery(),

        updateQueryFn: () => ({
          deal: {
            requirements: {
              $apply: requirements =>
                requirements.filter(
                  cRequirement => cRequirement.id !== requirement.id,
                ),
            },
          },
        }),
      });
    },

    onApproveProposal({ proposal }) {
      this.handleDeleteMutation({
        mutation: DealApproveProposalMutation,

        variables: { id: proposal.id },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            approveProposal: { proposal: approvedProposal },
          },
        }) =>
          approvedProposal
            ? makeProposalReplacerObject(approvedProposal)
            : null,
      }).then(() => {
        this.refreshRci();
      });
    },

    onDisapproveProposal({ proposal }) {
      this.handleDeleteMutation({
        mutation: DealDisapproveProposalMutation,

        variables: { id: proposal.id },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            disapproveProposal: { proposal: disapproveProposal },
          },
        }) =>
          disapproveProposal
            ? makeProposalReplacerObject(disapproveProposal)
            : null,
      }).then(() => {
        this.refreshRci();
      });
    },

    onUpdateProposal({ proposal }) {
      this.handleUpdateMutation({
        mutation: DealUpdateProposalMutation,

        requestingStateKey: 'proposalIsMutating',

        variables: { data: { ...proposal } },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            updateProposal: { proposal: updatedProposal },
          },
        }) =>
          updatedProposal ? makeProposalReplacerObject(updatedProposal) : null,
      })
        .then(() => {
          this.$refs.proposals.closeForm();
        })
        .catch(() => null);
    },

    onDisableProposal({ proposal }) {
      this.onUpdateProposal({ proposal: { id: proposal.id, active: false } });
    },

    onEnableProposal({ proposal }) {
      this.onUpdateProposal({ proposal: { id: proposal.id, active: true } });
    },

    onGenerateCommercialFile() {
      this.handleUpdateMutation({
        mutation: GenerateCommercialFileMutation,

        variables: { customerId: this.deal.customer.id },
      })
        .then(() => {
          window.alert(
            'El archivo esta siendo generado y sera enviado a tu correo electronico',
          );
        })
        .catch(() => null);
    },

    onChangeRemindersPage(page) {
      this.currentRemindersPage = page;
    },

    onCreateReminder({ reminder }) {
      this.handleUpdateMutation({
        mutation: CreateReminderMutation,

        requestingStateKey: 'creatingReminder',

        variables: {
          ...reminder,
          dealId: this.deal.id,
        },

        query: this.getRemindersQuery({ page: 1 }),

        updateQueryFn: ({
          resultsData: {
            createReminder: { reminder: createdReminder },
          },
        }) =>
          createdReminder
            ? {
                reminders: {
                  count: { $apply: count => count + 1 },
                  objects: { $unshift: [createdReminder] },
                },
              }
            : null,
      })
        .then(() => {
          this.reminderFormModal = false;
        })
        .catch(() => null);
    },

    onCompleteReminder({ reminder }) {
      this.completingReminder = reminder;
      this.activityQuestionModal = true;
    },

    onDeleteReminder({ reminder }) {
      this.handleDeleteMutation({
        mutation: DeleteReminderMutation,

        variables: { id: reminder.id },

        query: this.getRemindersQuery(),

        updateQueryFn: ({
          resultsData: {
            deleteReminder: { errors },
          },
        }) =>
          !errors
            ? {
                reminders: {
                  objects: {
                    $apply: reminders =>
                      reminders.filter(
                        cReminder => cReminder.id !== reminder.id,
                      ),
                  },
                },
              }
            : null,
      });
    },

    onCreateActivity({ activity }) {
      this.handleUpdateMutation({
        mutation: CreateActivityMutation,

        requestingStateKey: 'creatingActivity',

        variables: {
          ...activity,
          dealId: this.deal.id,
        },
      })
        .then(() => {
          if (this.completingReminder) this.completeCurrentReminder();

          this.$apollo.queries.deal?.refetch();
          this.activityModal = false;
        })
        .catch(() => null);
    },

    onSubmitActivityQuestion(answer) {
      this.activityQuestionModal = false;

      if (answer) this.activityModal = true;
      else this.completeCurrentReminder();
    },

    onRetryDeal() {
      this.dealError = null;
      this.$apollo.queries.deal.refetch();
    },

    completeCurrentReminder() {
      this.handleUpdateMutation({
        mutation: UpdateReminderMutation,

        variables: {
          ...this.completingReminder,
          status: REMINDER_STATUS.COMPLETED,
        },

        query: this.getRemindersQuery(),

        updateQueryFn: ({
          resultsData: {
            updateReminder: { reminder: updatedReminder },
          },
        }) =>
          updatedReminder
            ? {
                reminders: {
                  objects: {
                    $apply: reminders =>
                      reminders.map(cReminder =>
                        cReminder.id === this.completingReminder.id
                          ? updatedReminder
                          : cReminder,
                      ),
                  },
                },
              }
            : null,
      })
        .then(() => {
          this.completingReminder = null;
        })
        .catch(() => null);
    },

    onClickCreateRiskFile() {
      if (this.mustFillRiskFileForm) {
        this.createRiskFileModal = true;
      } else if (
        window.confirm(
          '¿Está seguro de que desea enviar el expediente a riesgos?',
        )
      ) {
        this.onSubmitCreateRiskFileFormModal({ riskFile: { description: '' } });
      }
    },

    onSubmitCreateRiskFileFormModal({ riskFile: { description } }) {
      this.handleCreateMutation({
        mutation: CreateRiskFileMutation,

        successMessage: 'El file de riesgo está siendo creado',

        variables: {
          data: {
            crmDealId: this.deal.id,
            description,
            rciAdvisor: unparseRCIJson(this.currentRci.values),
          },
        },

        requestingStateKey: 'creatingRiskFile',
      })
        .then(() => {
          this.initWaitForRiskFile();
        })
        .finally(() => {
          this.createRiskFileModal = false;
        });
    },

    onClickCreateFormalization() {
      this.handleCreateMutation({
        mutation: CreateDealFormalizationMutation,
        requestingStateKey: 'isCreatingFormalization',

        variables: {
          data: { crmDealId: this.deal.id },
        },
      }).then(() => {
        this.$apollo.queries.formalization.refetch();
      });
    },

    onSubmitCreateFormalizationReferralForm({ referral }) {
      this.handleCreateMutation({
        mutation: CreateDealFormalizationReferralMutation,
        requestingStateKey: 'isMutatingFormalizationReferrals',

        variables: {
          data: {
            ...referral,
            formalizationId: this.formalization.id,
          },
        },
      }).then(() => {
        this.$refs.formalizationTab.closeReferralForm();
        this.$apollo.queries.formalization.refetch();
      });
    },

    onSubmitUpdateFormalizationReferralForm({ referral }) {
      this.handleUpdateMutation({
        mutation: UpdateDealFormalizationReferralMutation,
        requestingStateKey: 'isMutatingFormalizationReferrals',

        variables: {
          data: { 
            ...referral, 
            mobile: String(referral.mobile)
          },
        },
      }).then(() => {
        this.$apollo.queries.formalization.refetch();
        this.$refs.formalizationTab.closeReferralForm();
      });
    },

    onChangeRci(rciData) {
      this.currentRci = rciData;
    },

    onClickNotifyRiskFileOpinion({ riskFile }) {
      this.handleUpdateMutation({
        mutation: SendRiskFileEventMutation,
        requestingStateKey: 'isNotificatingRiskFileOpinion',
        successMessage: 'Se ha enviado la notificacion exitosamente',

        variables: {
          data: { riskFileId: riskFile.id },
        },
      }).then(() => {
        this.notifiedOpinionsId.push(riskFile.lastOpinion.id);

        setNotifiedOpinionsId(this.notifiedOpinionsId);
      });
    },

    initWaitForRiskFile() {
      this.isWaitingForRiskFile = true;
      this.riskFileCreateRetries = 0;
      this.riskFileCreateTimestamp = moment();

      this.$apollo.queries.riskFiles.startPolling(2000);
    },

    stopWaitForRiskFile() {
      this.isWaitingForRiskFile = false;
      this.riskFileCreateRetries = 0;
      this.riskFileCreateTimestamp = null;

      this.$apollo.queries.riskFiles.stopPolling();
    },

    refreshRci() {
      this.$refs.debts?.refetchRci();
    },
  },

  computed: {
    dealId() {
      return this.$route.params.dealId;
    },

    couple() {
      return this.customer?.couple;
    },

    proposals() {
      return (this.deal?.proposals || []).map(proposal => ({
        ...proposal,
        product: this.deal.product,
        deal: { id: this.deal.id },
      }));
    },

    acceptedProposals() {
      return (this.activeDeals?.objects || [])
        .reduce((proposals, deal) => [...proposals, ...deal.proposals], [])
        .filter(({ acceptedAt }) => acceptedAt);
    },

    productSlug() {
      return this.deal?.product?.slug;
    },

    canGenerateCommercialFile() {
      return !!this.acceptedProposal;
    },

    acceptedProposal() {
      return this.proposals.find(({ acceptedAt }) => acceptedAt);
    },

    breadcrumbItems() {
      const customer = this?.deal?.customer;

      return [
        { text: 'CRM', to: { name: 'deals.list' }, exact: true },
        {
          text: `${customer?.firstName || ''} ${customer?.lastName || ''} ${
            this.deal ? `(Deal: #${this.deal.id})` : ''
          }`,
          disabled: true,
        },
      ];
    },

    mustFillRiskFileForm() {
      return ![NEW_MORTGAGE_SLUG, BUY_MORTGAGE_SLUG].includes(this.productSlug);
    },

    canDisapproveProposals() {
      return this.currentUserHasDjangoPermissions([
        'authentication.update_proposals',
        'authentication.change_proposals_accepted_datetime',
      ]);
    },

    canVisitFormalization() {
      return this.currentUserHasPermissions([
        PERMISSIONS.PEGASUS.FORMALIZATIONS,
      ]);
    },

    canVisitRiskFile() {
      return this.currentUserHasPermissions([PERMISSIONS.PEGASUS.RISK_FILES]);
    },

    canSeeRiskFileActions() {
      return this.currentUserHasPermissions([
        PERMISSIONS.PEGASUS.RISK_FILES__REVIEW,
      ]);
    },

    canSeeFormalizationTab() {
      return this.currentUserHasPermissions([
        PERMISSIONS.PEGASUS.FORMALIZATIONS__REVIEW,
      ]);
    },

    canNotifyRiskFileOpinion() {
      const lastOpinion = this.currentRiskFile?.lastOpinion;

      return (
        this.currentUserHasPermissions([
          PERMISSIONS.PEGASUS.RISK_FILES__NOTIFY_OPINION,
        ]) &&
        lastOpinion &&
        lastOpinion.status !== RISK_OPINION_STATUS.PENDING &&
        !this.notifiedOpinionsId.includes(lastOpinion.id)
      );
    },
  },

  components: {
    ActivityFormModal,
    ActivityQuestionModal,
    CreateRiskFileFormModal,
    CreateRiskFileModal,
    CustomerDocuments,
    DealCard,
    DealSummary,
    Debts,
    FormalizationTab,
    LoadingWrapper,
    Messages,
    Proposals,
    ReminderFormModal,
    RemindersModal,
    RequirementsCard,
    RiskFileActions,
    Timeline,
  },
};
</script>

<style lang="sass" scoped></style>
