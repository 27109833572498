<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import {
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
  } from 'vuetify/lib/components/VList';
  import debounce from 'lodash/debounce';
  import { CompaniesSelectQuery } from './queries.gql';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';

  export default {
    name: 'companies-select',

    mixins: [
      lazySelectMixin({ itemsKey: 'companies' })
    ],

    data() {
      return {
        companies: [],
        search: null,
      };
    },

    apollo: {
      companies: {
        query: CompaniesSelectQuery,
        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            search: this.search,
            ...this.additionalQueryVariables,
          };
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    props: {
      additionalQueryVariables: { type: Object, default: () => ({}) },
      fetching: { type: Boolean, default: false },
      itemValue: { type: String, default: 'id' },
      perPage: { type: Number, default: 10 },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      searchFn({ vat, name }, query) {
        return [name, vat]
          .some(cData => (cData || '').toLowerCase().includes((query || '').toLowerCase()));
      },

      setSearch(search, skipLazyMixin = true) {
        this.search = search;

        // Por mas que se setee el search al ser un lazy select tenemos que llamar al onFocus para que haga la query
        if(skipLazyMixin) this.onFocus();
      },
    },

    render(h) {
      return h(
        VAutocomplete, {
          class: this.b(),

          props: {
            ...this.$attrs,
            ...this.$props,
            items: this.lazyItems,
            filter: this.searchFn,
            loading: this.$apollo.queries.companies.loading,
            chips: true,
          },

          on: {
            ...this.$listeners,
            'update:search-input': this.onChangeSearch,
            focus: this.onFocus,
          },

          scopedSlots: {
            selection: data => h(VListItemContent, [
              h(VListItemTitle, data.item.name),
            ]),

            item: data => h(VListItemContent, [
              h(VListItemTitle, data.item.name ),
              h(VListItemSubtitle, [
                h('span', { class: { 'font-weight-medium': true }}, this.$t('workPlaceVat') + ': '),
                h('span', data.item.vat)
              ])
            ]),
          }
        },
      );
    },
  };
</script>

<style lang="sass">
  .user-select
    .v-select__selections
      min-height: 32px !important
</style>