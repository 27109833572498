<template lang="pug">
  thead
    tr(:class="b()")
      table-head-item(
        v-for="item in items"
        :key="item.title"
        :title="item.title"
        :order-key="item.orderKey"
        :order-flow="item.orderFlow"
        @order="args => $emit('order', args)"
      )
</template>

<script>
  import TableHeadItem from '@/components/UI/table-head-item';

  const ITEMS = [
    { title: 'Cliente' },
    { title: 'Crédito', orderKey: 'credit' },
    { title: 'Estado' },
    { title: 'Etapa', orderKey: 'current_stage__name' },
    { title: 'Producto', orderKey: 'product__name' },
    { title: 'Asesor' },
    // { title: 'Requisitos' },
    { title: 'Actividad', orderKey: 'last_activity_datetime' },
    { title: 'Creación', orderKey: 'created' },
  ];

  export default {
    name: 'table-head',

    props: {
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      items() {
        return ITEMS.map(item => ({
          ...item,
          orderFlow: this.orderBy === item.orderKey ? this.orderFlow : null,
        }));
      },
    },

    components: {
      TableHeadItem,
    },
  };
</script>

<style lang="sass" scoped>
</style>