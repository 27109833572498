
import VSelect from 'vuetify/lib/components/VSelect';
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

export default {
  name: 'investment-proposal-status-select',

  functional: true,

  render(h, context) {
    const items = [
      INVESTMENT_PROPOSAL_STATUS.COLLECTING,
      INVESTMENT_PROPOSAL_STATUS.REFINANCED,
      INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED,
      INVESTMENT_PROPOSAL_STATUS.FINISHED,
      INVESTMENT_PROPOSAL_STATUS.EXTENDED,
      INVESTMENT_PROPOSAL_STATUS.RESCHEDULE,

    ].map(status => ({
      value: status,
      text: context.parent.$t(`investmentProposalStatus.${status}`),
    }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
