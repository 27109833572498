import { render, staticRenderFns } from "./title-study-content-row.vue?vue&type=template&id=28e43ad4&scoped=true&lang=pug"
import script from "./title-study-content-row.vue?vue&type=script&lang=js"
export * from "./title-study-content-row.vue?vue&type=script&lang=js"
import style0 from "./title-study-content-row.vue?vue&type=style&index=0&id=28e43ad4&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e43ad4",
  null
  
)

export default component.exports