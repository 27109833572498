<template lang="pug">
  router-link(
    :to="investmentProposalRoute"
    :class="b({ selected })"
  )
    div
      v-checkbox(
        :value="selected"
        hide-details
        @change="onChangeCheckbox"
        @click.native.prevent="() => null"
      ).ma-0.pa-0
    div.font-weight-medium
      span(:class="b('investor')") {{ quote.investor.firstName }} {{ quote.investor.lastName }}
      br
      span(v-if="quote.borrower" :class="b('borrower')") ({{ quote.borrower.firstName }} {{ quote.borrower.lastName}})
    div {{ quote.feeNumber }}
    div.text-no-wrap {{ quote.currentData.grossAmountDue, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.amortization, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.interest, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.defaultAmount, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.penaltyAmount, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.rtcCommission, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.ivaTax, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.irTax, getCurrency() | formatCurrency }}
    div.font-weight-bold.text-no-wrap {{ quote.currentData.netAmountDue, getCurrency() | formatCurrency }}
    div {{ quote.daysOverdue }}
    div {{ quote.paymentDate | formatDate }}
    div {{ quote.currentData.expirationDate | formatDate }}
    div
      status-tag(:status="quote.status")
</template>

<script>
import StatusTag from '@/components/UI/quote-status-tag';
import config from '@/config/base';

export default {
  name: 'investor-quotes-table-item',

  props: {
    quote: { type: Object },
    selected: { type: Boolean, default: false },
  },

  computed: {
    investmentProposalRoute() {
      return {
        name: 'investment.show',
        params: { proposalId: this.quote.investmentProposalId },
      };
    },
  },

  methods: {
    onChangeCheckbox(val) {
      this.$emit(val ? 'select' : 'unselect', this.quote.id);
    },
    getCurrency(){
      return { symbol: `${this.quote.currencyId}` === config.CURRENCIES_SELECT[0].id ? config.CURRENCIES_SELECT[0].symbol : config.CURRENCIES_SELECT[1].symbol };
    },
  },

  components: { StatusTag },
};
</script>

<style lang="sass" scoped>
  .investor-quotes-table-item
    display: table-row
    text-decoration: none
    color: inherit

    &:hover
      background-color: darken(white, 1%)

    & > div
      display: table-cell
      padding: 6px 8px
      vertical-align: middle

    &__investor
      font-size: 16px
      font-weight: 500

    &__borrower
      color: darken(white, 50%)
      font-size: 14px

    &__status
      display: inline-block
      padding: 3px 8px
      border-radius: 6px
      color: white
      font-weight: 500

    &--selected
      background-color: transparentize($primaryColor, 0.85) !important
</style>