<template lang="pug">
    .dashboard-card
      .dashboard-card__header
        .dashboard-card__title {{ currentTitle }}
      .dashboard-card__body
        .dashboard-card__content
          v-layout
            v-flex(xs12 md12 lg12).pa-2
              .dashboard-card__title(style="text-align: center") Mes actual: 
                span {{ currentMonth }}
              .dashboard-card__title(style="text-align: center") Últimos 3 meses: 
                span {{ lastThreeMonths }}
              .dashboard-card__title(style="text-align: center") Últimos 3 meses RTC: 
                span {{ lastThreeMonthsRTC }}

</template>
  
<script>
export default {
    name: 'value-card',

    props: {
        currentTitle: { type: String, default: '' },
        currentMonth: { type: Number, default: 0 },
        lastThreeMonths: { type: Number, default: 0 },
        lastThreeMonthsRTC: { type: Number, default: 0 },
    },
};
</script>

<style lang="sass" scoped>
.value-card
    max-width: 400px
    margin: 0 auto

span
    color: black

</style>