<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12)
          div(:class="b('title')") Referidos
          referrals-list-input(
            ref="referralsListInput"
            v-model="data.referrals"
            :min-length="0"
            :max-length="2"
          )
        v-flex(xs12).pt-4
          div(:class="b('title')").mb-3 Descripción de la operación
          validation-provider(name="fecha de primera cuota" :rules="rules.firstPaymentDate" v-slot="{ errors }")
            date-picker(
              v-model="data.firstPaymentDate"
              :error-messages="errors"
              :allowed-dates="firstPaymentAllowedDates"
              label="Fecha de primera cuota"
              name="fecha de primera cuota"
            )
          validation-provider(name="descripción" :rules="'required|min:6'" v-slot="{ errors }")
            rich-textarea(
              v-model="data.description"
              :error-messages="errors"
              label="Descripción de la operación"
              name="descripción"
              placeholder="Descripción de la operación..."
            )
        v-flex(xs12).d-flex.align-center.justify-end.py-2
          v-icon(color="black").mr-1 mdi-lightbulb-on
          b.mr-1 Destino del crédito:&nbsp;
          span {{ motive }}
      v-divider
      v-card-actions
        v-btn(
          :loading="requesting"
          :disabled="requesting || descriptionIsEmpty || disabled"
          type="submit"
          color="primary"
        ).px-4 Enviar expediente
        v-btn(color="error" text @click="onCancel") Cancelar
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import basicFormMixin from '@/components/forms/basic-form-mixin';
import RichTextarea from '@/components/inputs/rich-textarea';
import DatePicker from '@/components/inputs/input-date-picker'
import stripHTMLTags from '@/utils/strip-html-tags';
import getProductMotive from '@/utils/get-product-motive';
import ReferralsListInput from '@/components/inputs/referrals-list-input';
import formStepMixin from '../form-step-mixin';

export default {
  name: 'risk-file-form-step',

  props: {
    deal: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'riskFile',
      veeValidation: true,

      mapPropsToData({ riskFile }) {
        return {
          description:
            riskFile?.description ||
            this.defaultValues?.description ||
            undefined,
          referrals: (
            riskFile?.referrals ||
            this.defaultValues?.referrals || [{}]
          ).map((referral) => ({
            id: uuidv4(),
            vat: null,
            vatType: 'DNI',
            email: null,
            mobile: null,
            ...referral,
          })),
          firstPaymentDate: riskFile?.firstPaymentDate || this.defaultValues?.firstPaymentDate,
        };
      },
    }),
    formStepMixin(),
  ],

  components: {
    RichTextarea,
    ReferralsListInput,
    DatePicker,
  },

  computed: {
    descriptionIsEmpty() {
      return stripHTMLTags(this.data.description).length < 5;
    },

    motive() {
      return getProductMotive(this.deal.product.slug, this.deal.motive);
    },

    rules() {
      return {
        firstPaymentDate: {
          required: true,
        },
      }
    },
  },

  methods: {
    async validate() {
      try {
        const success = await this.$refs.form.validate();
        await this.$refs.referralsListInput.validate();

        if (!success) return Promise.reject();

        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },

    firstPaymentAllowedDates(date) {
      const mDate = moment(date);

      return mDate.date() <= 28 && mDate.isAfter(moment());
    },
  }
};
</script>

<style lang="sass" scoped>
  .risk-file-form-step
    &__title
      font-size: 16px
      font-weight: bold
      color: darken(white, 45%)
      margin-bottom: 8px
</style>
