import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import sortBy from 'lodash/sortBy';
import { DealStagesSelectQuery } from './queries.gql';

export default {
  name: 'deal-stage-select',

  mixins: [lazySelectMixin({ itemsKey: 'stages' })],

  props: {
    loading: { type: Boolean, default: false },
    productSlug: { type: String },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
  },

  data() {
    return {
      stages: [],
    };
  },

  apollo: {
    stages: {
      query: DealStagesSelectQuery,

      variables() {
        return {
          productSlug: this.productSlug,
        };
      },

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  computed: {
    options() {
      return sortBy(this.stages, ['orderNumber']);
    },
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.stages.loading || this.$attrs.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};
