<template lang="pug">
  div(:class="b()")
    reminder(
      :class="b('item')"
      v-for="reminder in reminders"
      :key="reminder.id"
      :reminder="reminder"
      @complete="args => $emit('complete', args)"
      @delete="args => $emit('delete', args)"
    )
</template>

<script>
  import Reminder from './reminders-list-item';

  export default {
    name: 'reminders-list',

    props: {
      reminders: { type: Array, default: () => [] },
    },

    components: { Reminder },
  };
</script>

<style lang="sass" scoped>
  .reminders-list
    &__item
      border-bottom: 1px solid darken(white, 12%)

      &:last-child
        border-bottom: none
</style>