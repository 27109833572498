<template lang="pug">
  div(:class="b()")
    title-studies-list-item(
      v-for="titleStudy in titleStudies"
      :key="titleStudy.id"
      :titleStudy="titleStudy"
      @click-edit="({ titleStudy }) => $emit('click-edit', titleStudy)"
    )
    v-alert(v-if="!titleStudies.length" color="info" outlined)
      div No hay estudios de títulos registrados
      .d-flex.justify-center.mt-1
        v-btn(color="primary" @click="onClickAdd").ml-2
          v-icon mdi-plus
          span Agregar
</template>

<script>
import TitleStudiesListItem from './title-studies-list-item';

export default {
  name: 'title-studies-list',

  props: {
    titleStudies: { type: Array, default: () => [] },
  },

  methods: {
    onClickAdd() {
      this.$emit('click-add');
    },
  },

  components: { TitleStudiesListItem },
};
</script>

<style lang="sass" scoped>

</style>