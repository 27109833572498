<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      span(:class="b('chip')" :style="chipStyles")  {{ requirement.status.name }}
      v-spacer
      span(:class="b('date')") {{ requirement.modifiedAt | formatDate }}
    div(:class="b('name')") {{ requirement.name }}
    div(:class="b('description')") {{ requirement.description }}
</template>

<script>
  export default {
    name: 'requirement-card',

    props: {
      requirement: { type: Object, required: true },
    },
    
    computed: {
      chipStyles() {
        return {
          backgroundColor: this.requirement.status.color,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .requirement-card
    width: 250px
    padding: 1rem

    border-radius: 6px
    background-color: white
    box-shadow: 0 8px 24px 0 rgba(182,184,200,.4)
    z-index: 1

    &__header
      display: flex
      margin-bottom: .7rem

    &__name
      font-weight: bold
      margin-bottom: .3rem

    &__name, &__description
      &::first-letter
        text-transform: uppercase

    &__chip
      font-size: 12px
      font-weight: bold
      color: white
      padding: .1rem .6rem
      border-radius: 8px
      text-transform: capitalize
</style>