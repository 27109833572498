<template lang="pug">
  div(:class="b()")
    div(:style="customStyle") {{ parsedTitle }}
    div(:style="customStyle") {{ value }}
</template>

<script>
  const CUSTOM_STYLES = {
    period: { fontWeight: 'bold' },
    nor: { color: 'green', textTransform: 'uppercase' },
    cpp: { color: '#b5b500', textTransform: 'uppercase' },
    deff: { color: 'orange', textTransform: 'uppercase' },
    dud: { color: 'orange', textTransform: 'uppercase' },
    per: { color: 'red', textTransform: 'uppercase' },
    debtsAmount: { fontWeight: 'bold' },
    value: { fontWeight: 'bold' },
    score: { fontWeight: 'bold', fontSize: '1.2em' },
    nombreOtorgante: { fontWeight: 'bold' },
    tipoCredito: { fontWeight: 'bold' },
    cuentaActual: { fontWeight: 'bold' },
    saldoActual: { fontWeight: 'bold' },
    saldoVencido: { fontWeight: 'bold', color: 'red' },
    numeroPagosVencidos: { fontWeight: 'bold', color: 'red' },
    saldoVencidoPeorAtraso: { fontWeight: 'bold', color: 'red' },
  };
  import humps from 'humps';

  export default {
    name: 'buro-results-row',

    props: {
      title: { type: String },
      value: { type: [String, Boolean, Number] },
    },

    computed: {
      parsedTitle() {
        return humps.decamelize(this.title, { separator: ' '});
      },

      customStyle() {
        return CUSTOM_STYLES?.[this.title] || {};
      },
    },
  };
</script>

<style lang="sass">
  .buro-results-row
    display: flex

    & > *
      flex: 1
      font-size: 14px

      &:first-child
        font-weight: 500

        &::first-letter
          text-transform: uppercase
</style>