<template lang="pug">
  div(:class="b()" :style="{ backgroundColor: color }") {{ getTextOfStatusClassification }}
</template>

<script>
  import qualificationColor from '@/utils/investment-proposal-sbs-color';

  export default {
    name: 'investment-proposal-sbs-tag',

    props: {
      qualification: { 
        type: Object, 
        required: true,
        default: () => ({ description: 'N/A' })
      },
      qualificationStatus: { type: Object },
    },

    computed: {
      color() {
        return qualificationColor(this.qualification?.description) || '#C0C0C0';
      },

      getTextOfQualificationByCompliance(){
        const values = {
          NOR: 'NOR',
          CPP: 'CPP',
          DEF: "DEF",
          DUD: "DUD",
          PER: "PER",
        }
        return values[this.qualification?.description] || 'N/A';
      },

      getTextOfStatusClassification() {
        return this.qualificationStatus?.status || this.getTextOfQualificationByCompliance;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposal-sbs-tag
    display: flex
    align-items: center
    justify-content: center
    padding: 2px 12px
    border-radius: 4px
    text-transform: uppercase
    font-size: 9px
    font-weight: 500
    color: white

    +md
      padding: 2px 12px
      font-size: 11px
</style>