import FormModal from './base-form-modal';

export default ({ entityName } = {}) => ({
  props: {
    requesting: { type: Boolean, default: false },
    persistent: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    ...(entityName ? { [entityName]: { type: Object, default: () => ({}) } } : {}),
    hiddenFields: { type: Array, default: () => [] },
    disabledFields: { type: Array, default: () => [] },
    width: { type: Number },
  },

  components: { FormModal },
});
