<template lang="pug">
  div(:class="b()")
    table.buro-table
      thead
        tr
          th Periodo
          th Ent.
          th Calificación
          th Días de#[br] atraso
          th Vigente
          th Atrasado
          th Judicial
          th Castigado
          th Deuda Total
      tbody
        table-row(
          v-for="debt, idx in filterDebts"
          :key="idx"
          :debt="debt"
        )
</template>

<script>
  import TableRow from './historical-debts-table-row';

  export default {
    name: 'historical-debts',

    props: {
      historicalDebts: { type: Array, default: () => [] },
    },

    computed: {
      filterDebts() {
        return this.historicalDebts.filter(({ qualification }) => qualification !== null);
      },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .historical-debts
    overflow-x: auto

    table
      white-space: nowrap

    &__calification
      font-weight: bold

      &--NOR
        color: green

      &--CPP
        color: #b5b500

      &--DEF, &--DUD
        color: orange

      &--PER
        color: red
</style>