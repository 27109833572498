<template lang="pug">
  tr(:class="b()")
    td {{ entry.period | formatPeriod }}
    td {{ entry.daysOverdue }}
    td(:class="b('status', { [status]: true })") {{ statusText }}
</template>

<script>
  import formatPeriod from '../../filters/format-period';

  const STATUS_TEXT = {
    INFOCORP: 'Tiene deudas impagas en INFOCORP',
    BELATED: 'Tiene deuda atrasada',
    NORMAL: 'Está al día',
    UNKNOWN: 'Sin información',
  };

  export default {
    name: 'payments-behaviour-row',

    props: {
      entry: { type: Object, required: true },
    },

    computed: {
      status() {
        if(this.entry.hasDebtUnpaidInfocorp) return 'INFOCORP';
        else if(this.entry.hasDebtOverdue) return 'BELATED';
        else if(this.entry.doesntHaveUnpaid) return 'NORMAL';
        else if(this.entry.infoUnavailable) return 'UNKNOWN';
      },

      statusText() {
        return STATUS_TEXT[this.status];
      },
    },

    filters: { formatPeriod },
  };
</script>

<style lang="sass" scoped>
  .payments-behaviour-row
    &__status
      font-weight: bold

      &--INFOCORP
        color: red

      &--BELATED
        color: orange

      &--NORMAL
        color: green

      &--UNKNOWN
        color: gray
</style>