import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';

dayjs.extend(relativeTime);

dayjs.locale('es');

export const formatDate = (date, format = 'D MMM YYYY') => date ?
  dayjs(date).format(format) :
  date;

export const formatDateTime = (date, format = 'D MMM YYYY HH:mm') => date ?
  dayjs(date).format(format) :
  date;

export const timeAgo = (date) => date ?
  dayjs(date).fromNow() :
  date;
