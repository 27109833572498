<template lang="pug">
  div(:class="b()")
    div(:class="b('icon-container')")
      v-icon {{ icon }}
    div(:class="b('text')") {{ step }}
</template>

<script>
  const ICONS = {
    documents: 'mdi-file-multiple',
    proposals: 'mdi-format-list-checks',
  };

  export default {
    name: 'customer-step',

    props: {
      step: { type: String, required: true },
    },

    computed: {
      icon() {
        return ICONS[this.step];
      },
    },
  };
</script>

<style lang="sass" scoped>
  $color: darken(white, 6%)
  $iconSize: 34px

  .customer-step
    display: inline-block
    position: relative
    background-color: $color
    margin-top: .5rem
    margin-left: ($iconSize / 2)
    padding-left: ($iconSize / 2) + 8px
    padding-right: 1rem
    text-transform: capitalize

    &__icon-container
      width: $iconSize
      height: $iconSize
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      left: 0
      top: 50%
      transform: translate(-50%, -50%)
      background-color: $color
      border-radius: 50%

      i
        font-size: 1.5rem
    
</style>