import { BUY_MORTGAGE_SLUG, NEW_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

export default {
  COUNTRY_CODE: 'mx',

  /**
   * FEATURES
   */
  CREDIT_HIDDEN_FIELDS: ['doubleFee'],
  NIP_VALIDATION: true,

  /**
   * UNIQUE VALUES
   */
  DEFAULT_CURRENCY_ID: '4',
  PHONE_CODE: 52,
  GROUPS_INVESTOR_ID: process.env.VUE_APP_GROUPS_INVESTOR_ID || '9',

  /**
   * THEMING
   */
  LOCALE: 'es-MX',
  FORM_URL: 'https://rebajatuscuentas.com/mx/formulario',
  CRM_FUNNEL_LABELS: [
    { title: 'Funnel de Ventas', color: '#f44336', size: 2 },
    { title: 'Integración', color: '#f44336', size: 1 },
    { title: 'Autorización', color: '#ffc107', size: 1 },
    { title: 'Avalúo', color: '#ffc107', size: 1 },
    { title: 'Notaría/Venta', color: '#4caf50', size: 1 },
  ],

  /**
   * SELECTS AND LISTS
   */
  ADDRESS_POLITICAL_DIVISIONS: [
    { name: 'país' },
    { name: 'estado' },
    { name: 'ciudad' },
    { name: 'municipio' },
    { name: 'colonia' },
  ],

  CURRENCIES_SELECT: [
    { text: 'Pesos', id: '4', symbol: '$' },
  ],

  PRODUCTS_SELECT: [
    { value: BUY_MORTGAGE_SLUG, text: 'Sustitución hipotecaria' },
    { value: NEW_MORTGAGE_SLUG, text: 'Nuevo crédito hipotecario' },
    { value: HOME_EQUITY_LOAN_SLUG, text: 'Préstamo con garantía hipotecaria' },
  ],

  INCOME_CATEGORIES_SELECT: [
    { text: 'Asalariado', value: '1' },
    { text: 'Independiente', value: '2' },
  ],

  /**
   * Render & Hiddeners
   */

  PRODUCT_FILTER_FIELDS: slug => {
    switch (slug) {
      case BUY_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'insuranceTypeId', 'limitMonths'];
      case NEW_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'totalIncoming', 'propertyValue', 'insuranceTypeId', 'openAmount', 'limitMonths']
      case HOME_EQUITY_LOAN_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'totalIncoming', 'creditAmount', 'propertyValue', 'insuranceTypeId', 'limitMonths']
      default:
        return [];
    }
  },


  /**
   * VALIDATIONS
   */
  PHONE_VALIDATION: {
    REGXP: /^52[0-9]{10}$/,
    MESSAGE: 'El campo {_field_} debe tener el formato +(52)##########',
  },

  CUSTOMER_FORM_RULES: () => ({
    firstName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    maternalLastName: { alpha_spaces: true, min: 3, max: 50 },
    email: {
      required: true,
      email: true,
    },
    mobile: {
      phone_number: true,
    },
    vat: {
      required: true,
      alpha_num: true,
      length: 10,
    },
    address: {
      zipCode: { length: 5, numeric: true },
    },
  }),

  BURO_FORM_RULES: () => ({
    firstName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    lastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    maternalLastName: { required: true, alpha_spaces: true, min: 3, max: 50 },
    vatTypeId: { required: true },
    vat: { required: true, alpha_num: true, length: 10 },
    birth: { required: true },
    mobile: { phone_number: true },
    address: {
      zipCode: { required: true, numeric: true, length: 5 },
    },
  }),

  PROPERTY_FORM_RULES: ({ data: { value }}) => ({
    value: {
      required: true,
      decimal: 2,
      min_value: 5e5,
      max_value: 18e7,
    },
    auctionValue: {
      decimal: 2,
      min_value: 24e4,
      max_value: value * 0.7,
    },
    yearOfConstruction: { min_value: 1900, max_value: new Date().getFullYear() },
    address: {
      zipCode: { length: 5, numeric: true },
    },
  }),

  INCOME_FORM_RULES: ({ data: { amount }, hasWorkplaceData }) => ({
    amount: {
      required: true,
      decimal: 2,
      min_value: 5e3,
      max_value: 4e6,
    },
    netAmount: {
      decimal: 2,
      max_value: Number(amount),
    },
    workPlace: hasWorkplaceData ? {
      name: { required: true, min: 3, max: 50 },
      vat: { required: true, alpha_num: true, min: 12, max: 13 },
    } : {
      name: null,
      vat: null,
    },
  }),

  CREDIT_FORM_RULES: ({ data: { tea }}) => ({
    bankId: { required: true },
    insuranceTypeId: { required: true },
    credit: { required: true, decimal: 2, min_value: 25e4, max_value: 4e7 },
    tea: { decimal: 2, min_value: 0, max_value: 18 },
    tcea: { decimal: 2, min_value: tea },
    simpleFee: { required: true, decimal: 2, min_value: 2e3, max_value: 24e4 },
    limitMonths: { required: true, numeric: true, min_value: 60, max_value: 300 },
  }),
};
