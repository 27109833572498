<template lang="pug">
  tfoot
    tr
      th Total
      th(v-for="entry, idx in entries" :key="idx").text-no-wrap {{ parseValue(entry.value) }}
</template>

<script>
export default {
  name: 'borrowers-aums-table-foot',

  props: {
    entries: { type: Array, default: () => [] },
    parseValue: { type: Function },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-aums-table-foot
</style>