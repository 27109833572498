<template lang="pug">
  .popup-overlay(v-if="isOpen")
    .popup-content
      .popup-header
        h2 Agregar Comercialidad del Inmueble
      .popup-body
        .detail-row  
          v-flex(xs12).px-1
              commercialities-select(
                v-model="selectedQuestionId"
                @change="handlerData"
                :questionCommercialities="questionCommercialities"
                :questionConfig="responseConfig"
              )
      .popup-footer
        v-btn(color="primary" @click="closePopup") Cerrar
        v-btn(
          color="primary"
          @click="submitCommerciality"
          :loading="loading"
          :disabled="!responseSelect.length"
          class="ml-4"
        ) Guardar Comercialidad
  </template>
  
  <script>
  import CommercialitiesSelect from '@/components/inputs/commercialities-select'; 
  import { DoCreateCommercialityAppraisal } from '../graphql/mutations.gql';
  import iziToast from 'izitoast';
  
    export default {
      name: 'appraisals-commercialities',
      props: {
        isOpen: {
          type: Boolean,
          default: false
        },
        appraisalData: {
          type: Object,
          default: () => ({})
        }
      },

      data() {
        return {
          selectedQuestionId: null,
          responseSelect:[],
          loading: false,
        }
      },

      methods: {
        closePopup() {
          this.$emit('close')
        },

        handlerData(data) {
          this.responseSelect = data;
        },

        async submitCommerciality() {
          try {
            this.loading = true;

            const body = {
              appraisalId: this.appraisalData.id,
              responses: this.responseSelect,
            };

            const { data } = await this.$apollo.mutate({
              mutation: DoCreateCommercialityAppraisal,
              variables: {
                data: body
              }
            });

            if (data.createCommercialityAppraisal.status === 'CREATED') {
              this.closePopup()
              iziToast.success({
                message: 'Se agrego la comercialidad con éxito',
              });
            } else {
              const errorMessage = data.createCommercialityAppraisal.errors?.[0]?.message || 'Error al guardar la comercialidad'
              throw new Error(errorMessage)
            }

          } catch(err) {
            iziToast.error({
              message: `No se pudo agregar la comercialidad por el siguiente error: ${err}`,
            });
          } finally {
            this.loading = false
          }
        },
      },

      computed: {
        questionCommercialities() {
          const 
            questionsTotal = this.appraisalData?.commercialities?.commercialityDetail || [],
            question = questionsTotal.reduce((acc, item) => {
              const questionId = item.questionId;

              if (!acc[questionId]) {
                acc[questionId] = {
                  id: '',
                  name: '',
                  questions: [{
                    id: questionId,
                    text: item.questionText,
                    options: []
                  }]
                };
              }

              const existingOption = acc[questionId].questions[0].options.find(opt => opt.id === item.optionId);
              if (!existingOption) {
                acc[questionId].questions[0].options.push({
                  id: item.optionId,
                  text: item.optionText
                });
              }
              return acc;
          }, {});

          const questionArray = Object.values(question);

          return questionArray ;
        },

        responseConfig() {
          return this.appraisalData?.commercialities?.commercialityDetail?.filter( items => items.isSelected === true ).map( items => {
            return {
              "id": items.questionId, 
              "optionId":items.optionId
            };
          }) || [];
        },
      },

      components: { CommercialitiesSelect },
    }
  </script>
  
  <style lang="sass" scoped>
    .popup-overlay
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.5)
      display: flex
      justify-content: center
      align-items: center
      z-index: 1000
    
    .popup-content
      background: white
      padding: 20px
      border-radius: 8px
      min-width: 400px
      max-width: 600px
    
    .detail-row
      display: flex
      margin-bottom: 10px
    
      .label
        font-weight: bold
        margin-right: 10px
  </style>