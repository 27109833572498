<template lang="pug">
  div(:class="b()")
    div(:class="b('container')").py-3
      div(:class="b('score')")
        scores(:scores="results.scores")
      div(:class="b('summary')")
        credits-summary(:results="results")
    div(:class="b()").py-3
      credits-table(:credits="results.creditos || []")
</template>

<script>
  import Scores from './scores';
  import CreditsSummary from './credits-summary';
  import CreditsTable from './credits-table';

  export default {
    name: 'results-summary',

    props: {
      results: { type: Object },
    },

    components: {
      Scores,
      CreditsSummary,
      CreditsTable,
    }
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .results-summary
    &__container
      +lg
        display: flex

    &__summary
      flex: 1

    &__score
      padding-bottom: 12px

      +lg
        max-width: 340px
        padding-right: 18px

      +xl
        max-width: 400px
</style>