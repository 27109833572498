<template lang="pug">
  div(:class="b()")
    div(:class="b('text', { error })") {{ label }}
    v-radio-group(:value="value" @change="args => $emit('input', args)" :class="b('radios')" row hide-details)
      v-radio(v-for="option in options" :key="option.value" :label="option.label" :value="option.value" hide-details)
</template>

<script>
import { REFINANCE_TYPE } from '@/utils/constants';

export default {
  name: 'refinance-type-radio',

  data() {
    return {
      REFINANCE_TYPE,
    };
  },

  props: {
    value: { type: String },
    label: { type: String, required: false },
    error: { type: Boolean, default: false }
  },

  computed: {
    options() {
      return [
        { label: 'Ampliación', value: REFINANCE_TYPE.EXTENDED },
        { label: 'Refinanciamiento', value: REFINANCE_TYPE.REFINANCED },
      ];
    },
  },
};
</script>

<style lang="sass" scoped>
  .refinance-type-radio
    display: flex
    align-items: center
    padding: 9px 0

    &:hover
      background-color: transparentize($primaryColor, 0.94)

    &__text
      color: rgba(0,0,0,0.6)
      font-size: 16px
      padding-left: 4px
      flex: 1

      &--error
        color: red

    &__radios
      margin-top: 0
      padding-top: 0
</style>