<template lang="pug">
  div(:class="b()")
    validation-observer(ref="form")
      form(@submit.prevent="onSubmit")
        v-layout(wrap)
          v-flex(v-if="renderField('investorId')" xs12).px-2
            validation-provider(name="inversionista" :rules="'required'" v-slot="{ errors }")
              investor-select(
                ref="investorSelect"
                v-model="data.investorId"
                :error-messages="errors"
                label="Inversionista"
                name="inversionista"
              )
          v-flex(v-if="renderField('investmentVehicleId')" xs12).px-2
            validation-provider(name="vehículo" :rules="'required'" v-slot="{ errors }")
              vehicle-select(
                ref="vehicleSelect"
                v-model="data.investmentVehicleId"
                :error-messages="errors"
                label="Vehículo"
                name="vehículo"
              )
          
          v-flex(xs12).px-2
            validation-provider(
              name="no. emisión"
              :rules="rules.issueCode"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model="data.issueCode"
                :error-messages="errors"
                label="No. Emisión"
                name="no. emisión"
              )
          
          v-flex(v-if="renderField('status')" xs12).px-2
            bond-status-select(
              v-model="data.status"
              label="Estado"
            )

          v-flex(xs12 sm4).px-2
            validation-provider(
              name="moneda"
              :rules="'required'"
              v-slot="{ errors }"
            )
              currency-select(
                v-model="data.currency"
                :error-messages="errors"
                name="moneda"
                label="Moneda"
                return-object
              )

          v-flex(xs12 sm8).px-2
            validation-provider(
              name="monto"
              :rules="rules.debtAmount"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model.number="data.debtAmount"
                :error-messages="errors"
                :prefix="data.currency.symbol"
                label="Monto"
                name="monto"
                type="number"
                step="0.01"
              )
          v-flex(xs6).px-2
            validation-provider(
              name="Plazo"
              :rules="rules.quotesQuantity"
              v-slot="{ errors }"
            )
              v-text-field(
                v-model.number="data.quotesQuantity"
                :error-messages="errors"
                label="Plazo (meses)"
                name="plazo"
                type="number"
              )
          v-flex(xs6).px-2
            validation-provider(name="tasa" :rules="rules.rate" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.rate"
                :error-messages="errors"
                step="0.01"
                name="tasa"
                label="Tasa (TEA)"
                type="number"
                append-icon="mdi-percent"
              )

          v-flex(xs6).px-2
            validation-provider(name="impuesto a la renta" :rules="rules.irTaxRate" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.irTaxRate"
                :error-messages="errors"
                name="impuesto a la renta"
                label="Impuesto a la renta"
                type="number"
                append-icon="mdi-percent"
              )
          v-flex(xs6).px-2
            validation-provider(name="balloon" :rules="rules.balloonPaymentType" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.balloonPaymentType"
                :error-messages="errors"
                name="Balloon"
                label="balloon"
                type="number"
              )

          v-flex(xs12 md6).px-2
            validation-provider(name="fecha de emisión" :rules="rules.disbursementDate" v-slot="{ errors }")
              date-picker(
                v-model="data.disbursementDate"
                :error-messages="errors"
                label="Fecha de emisión"
                name="fecha de emisión"
              )
          v-flex(xs12 md6).px-2
            validation-provider(name="fecha de primer pago" :rules="rules.firstPaymentDate" v-slot="{ errors }")
              date-picker(
                v-model="data.firstPaymentDate"
                v-bind="inputsProps.firstPaymentDate"
                :error-messages="errors"
                label="Fecha de primer pago"
                name="fecha de primer pago"
              )
        slot(name="actions" v-bind="{ requesting, isDisabled }")
          v-divider
          v-card-actions
            v-spacer
            v-btn(v-if="cancelable" color="error" text @click="onCancel") Cancelar
            v-btn(
              :loading="requesting"
              :disable="isDisabled"
              color="primary"
              text
              type="submit"
            ) Guardar
</template>

<script>
  import Config from '@/config';
  import basicFormMixin from './basic-form-mixin';
  import moment from 'moment';

  import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';
  import InvestorSelect from '@/components/inputs/investor-select';
  import VehicleSelect from '@/components/inputs/vehicle-select';
  import BondStatusSelect from '@/components/inputs/investment-proposal-status-select';
  import DatePicker from '@/components/inputs/input-date-picker';
  import CurrencySelect from '@/components/inputs/currency-select';

  export default {
    name: 'bond-form-view',

    mixins: [
      basicFormMixin({
        modelKey: 'bond',
        veeValidation: true,

        mapPropsToData({ bond }) {
          return {
            investorId: bond?.investor?.id,
            investmentVehicleId: bond?.vehicle?.id,
            rate: bond?.rate,
            debtAmount: bond?.debtAmount,
            status: bond?.status || INVESTMENT_PROPOSAL_STATUS.PENDING,
            quotesQuantity: bond?.quotesQuantity || 36,
            disbursementDate: bond?.disbursementDate || moment().format('YYYY-MM-DD'),
            firstPaymentDate: bond?.firstPaymentDate || moment().add(1, 'month').format('YYYY-MM-DD'),
            balloonPaymentType: bond?.balloonPaymentType || 0,
            irTaxRate: bond?.irTaxRate || 5,
            currency: Config.CURRENCIES_SELECT[0],
          };
        },

        parseSubmit() {
          const { currency, ...data } = this.data;
          return {
            bond: {
              ...data,
              currencyId: currency.id,
            },
          };
        },
      }),
    ],

    props: {
      bond: { type: Object },
      cancelable: { type: Boolean, default: true },
    },

    computed: {
      rules() {
        return {
          issueCode : { required: true },
          rate: { decimal: 2, required: true, min_value: 1 },
          debtAmount: { decimal: 2, required: true, min_value: 1e4 },
          quotesQuantity: { numeric: true, required: true, min_value: 6 },
          irTaxRate: { decimal: 2, required: true },
          balloonPaymentType: { numeric: true, min_value: 0, max_value: 100 },
        };
      },

      inputsProps() {
        return {
          firstPaymentDate: {
            min: this.data.disbursementDate ? moment(this.data.disbursementDate).add(1, 'd').format('YYYY-MM-DD') : undefined
          },
        };
      },
    },

    components: { DatePicker, InvestorSelect, VehicleSelect, CurrencySelect, BondStatusSelect },
  };
</script>

<style lang="sass" scoped>

</style>