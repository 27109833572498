<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12).px-2
          validation-provider(name="actividad" :rules="'required'" v-slot="{ errors }")
            activity-select(
              v-model="data.activityId"
              :error-messages="errors"
              name="actividad"
              label="Actividad"
            )
        v-flex(xs12).px-2
          validation-provider(name="mensaje" :rules="'required|min:6'" v-slot="{ errors }")
            v-textarea(
              v-model="data.message"
              :error-messages="errors"
              label="Mensaje"
              name="mensaje"
            )
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="asesor" :rules="'required'" v-slot="{ errors }")
            adviser-select(
              v-model="data.adviserId"
              :error-messages="errors"
              :lazy="lazySelects"
              name="asesor"
            )
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="fecha" :rules="'required'" v-slot="{ errors }")
            date-picker(
              v-model="data.date"
              :error-messages="errors"
              :min="minDate"
              name="fecha"
              label="Fecha"
            )
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="hora" :rules="'required'" v-slot="{ errors }")
            time-picker(
              v-model="data.hour"
              :error-messages="errors"
              label="Hora"
              name="hora"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Recordar
</template>

<script>
  import moment from 'moment';

  import AdviserSelect from '@/components/inputs/adviser-select';
  import ActivitySelect from '@/components/inputs/activity-select';
  import DatePicker from '@/components/inputs/input-date-picker';
  import TimePicker from '@/components/inputs/input-time-picker';

  import basicFormMixin from './basic-form-mixin';

  export default {
    name: 'reminder-form',

    props: {
      currentUserId: { type: String },
      lazySelects: { type: Boolean, default: false },
    },

    data() {
      return {
        minDate: moment().startOf('day').subtract(1, 'day').format(),
      };
    },

    mixins: [
      basicFormMixin({
        modelKey: 'reminder',
        veeValidation: true,

        mapPropsToData({ reminder }) {
          return {
            activityId: reminder?.activityId,
            message: reminder?.message,
            date: reminder?.data,
            hour: reminder?.hour,
            adviserId: this.currentUserId,
          };
        },

        parseSubmit() {
          return {
            reminder: {
              activityId: this.data.activityId,
              adviserId: this.data.adviserId,
              message: this.data.message,
              datetime: moment(`${this.data.date} ${this.data.hour}`).format(),
            },
          };
        },
      }),
    ],

    components: {
      AdviserSelect,
      ActivitySelect,
      DatePicker,
      TimePicker,
    },
  };
</script>

<style lang="sass" scoped>

</style>