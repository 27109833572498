<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Propuestas
    table
      thead
        tr
          th Producto
          th Cuota Simple

      tbody
        td {{ $t(`productSlug.${value.slug}`)}}
        td
          v-text-field(
            :value="value.fee"
            :disabled="disabled"
            type="number"
            @input="onInput"
            hide-details
          )
</template>

<script>
  import $update from 'immutability-helper';

  export default {
    name: 'proposals',

    props: {
      value: { type: Object, required: true },
      disabled: { type: Boolean, default: false },
    },

    methods: {
      onInput(val) {
        this.$emit('input', $update(this.value, {
          fee: { $set: Number(val) },
        }));
      },
    },
  };
</script>

<style lang="sass" scoped>
  .proposals
    border: 1px solid darken(white, 16%)
    padding: 1rem
    border-radius: 6px

    &__title
      font-weight: bold
      color: darken(white, 40%)
      text-align: center


    table
      width: 100%

      tbody
        tr
          td
            padding: 3px 8px

          td:first-child
            width: auto
            font-weight: 500

          td:last-child
            max-width: 150px !important

            & > *
              margin-top: -12px
</style>