import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import defaultsDeep from 'lodash/defaultsDeep';
import accounting from 'accounting';
import Config from '@/config';

const numberOptions = {
  decimal: Config.ACCOUNTING.DECIMAL,
  thousand: Config.ACCOUNTING.THOUSAND,
  precision: 2,
};

accounting.settings = defaultsDeep({
  number: {
    ...numberOptions,
  },
}, accounting.settings);


export const parseAmount = amount => isString(amount) ? Number(amount) : amount;

export function formatNumber(amount, opts) {
  const parsedAmount = parseAmount(amount);

  if(!isNumber(parsedAmount)) return parsedAmount;

  return accounting.formatNumber(parsedAmount, opts);
}

export const formatCurrency = (amount, currency, opts) => {
  return isEmpty(amount) && !isNumber(amount) ? amount : `${currency?.symbol || Config.ACCOUNTING.SYMBOL} ${formatNumber(amount, opts)}`;
}
