<template lang="pug">
  div(:class="b()")
    customer-card(:customer="results.persona")
    v-tabs(v-model="currentTab" fixed-tabs)
      v-tab Resumen
      v-tab Cuentas
      v-tab Domicilios
      //- v-tab Consultas
      v-tab-item.py-3
        results-summary(:results="results")
      v-tab-item.py-3
        credits(:credits="results.creditos || []")
      v-tab-item.py-3
        addresses(:addresses="results.domicilios || []")
      //- v-tab-item.py-3
      //-   queries(:queries="results.consultas")
</template>

<script>
  import CustomerCard from './components/customer';
  import ResultsSummary from './components/results-summary';
  import Credits from './components/credits';
  import Addresses from './components/addresses';
  import Queries from './components/queries';

  export default {
    name: 'mx-buro-results',

    props: {
      results: { type: Object },
    },

    data() {
      return {
        currentTab: 0,
      };
    },

    components: {
      CustomerCard,
      Credits,
      ResultsSummary,
      Addresses,
      Queries,
    },
  };
</script>

<style lang="sass" scoped>

</style>