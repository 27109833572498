<template lang="pug">
  div(:class="b({ active })" @click="onClick")
    span(:class="b('title')") {{ title }}&nbsp;
    span(:class="b('content')") {{ content }}&nbsp;
    v-btn(v-if="active" small icon color="primary")
      v-icon mdi-open-in-new
</template>

<script>
  export default {
    name: 'dialog-trigger',

    props: {
      title: { type: String, required: true },
      content: { type: String, required: true },
      dialog: { type: Object, required: true },
      active: { type: Boolean, default: false },
    },

    methods: {
      onClick() {
        if(this.active) {
          this.$emit('open-dialog', this.dialog);
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .dialog-trigger
    font-size: 16px
    margin-bottom: 4px
    cursor: pointer

    &__content
      font-weight: 500

    &--active
      &:hover
        color: $primaryColor
        text-decoration: underline
</style>