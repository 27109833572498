<template lang="pug">
  titled-card(
    title="Inmuebles"
    :count="properties.length"
    icon="mdi-home"
  )
    div(slot="actions")
      v-btn(@click.native="onClickAdd" text small color="primary").ma-0.px-1
        span Agregar
        v-icon mdi-plus
    template(v-for="property, idx in properties")
      property(
        :key="property.id"
        :property="property"
        :customer="customer"
        @add-credit="onClickAddCredit"
        @edit="({ property }) => onClickEdit(property)"
        @edit-credit="({ credit }) => onEditCredit(credit)"
        @delete="({ property }) => onClickDelete(property)"
        @delete-credit="onClickDeleteCredit"
        @add-electronic-record="onClickAddElectronicRecord"
      )
      v-divider(v-if="idx + 1 < properties.length").my-2
    v-alert(
      v-if="!properties.length"
      :value="true"
      type="info"
      outlined
    ) No hay inmuebles registrados
    property-form-modal(
      v-if="formModal"
      v-model="formModal"
      :property="editingEntity"
      :customer="customer"
      :requesting="requesting"
      :fill-risk="fillRisk"
      @submit="onSubmit"
    )
    credit-form-modal(
      v-if="creditFormModal"
      v-model="creditFormModal"
      :credit="creditEditing"
      :requesting="requestingCredit"
      @submit="onSubmitCredit"
    )
</template>

<script>
  import PropertyFormModal from '@/components/modals/property-form-modal';
  import CreditFormModal from '@/components/modals/credit-form-modal';
  import gestionableList from '@/mixins/gestionable-list-mixin';
  import TitledCard from '@/components/UI/titled-card';

  import Property from './properties-row';

  export default {
    name: 'properties',

    mixins: [
      gestionableList({
        entityName: 'property',
      }),
    ],

    props: {
      properties: { type: Array, default: () => ([]) },
      customer: { type: Object },
      requesting: { type: Boolean, default: false },
      requestingCredit: { type: Boolean, default: false },
      fillRisk: { type: Boolean, default: false },
    },

    data() {
      return {
        currentProperty: null,
        creditFormModal: false,
        creditEditing: null,
      };
    },

    methods: {
      closeForm() {
        this.formModal = false;
        this.creditFormModal = false;
      },

      onEditCredit(credit) {
        this.creditEditing = credit;
        this.creditFormModal = true;
      },

      onSubmitCredit({ credit }) {
        if(credit.id) this.$emit('update-credit', { credit })
        else this.$emit('create-credit', { credit: { ...credit, propertyId: this.currentProperty.id }});
      },

      onClickAddCredit({ property }) {
        this.currentProperty = property;
        this.creditEditing = null;
        this.creditFormModal = true;
      },

      onClickDeleteCredit({ credit }) {
        if(window.confirm('¿Está seguro que desea eliminar la hipoteca?'))
          this.$emit('delete-credit', { credit });
      },

      onClickAddElectronicRecord({ property, customer }) {
        this.$emit('add-electronic-record', { property, customer });
      },
    },

    components: {
      TitledCard,
      PropertyFormModal,
      CreditFormModal,
      Property,
    },
  };
</script>

<style lang="sass" scoped>
</style>