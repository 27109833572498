<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Ingresos
    table
      thead
        tr
          th
          th Fijos
          th Variables
      tbody
        tr
          td Titular
          td
            v-text-field(
              :value="value.main.fixed"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'main.fixed')"
              hide-details
            )
          td
            v-text-field(
              :value="value.main.variable"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'main.variable')"
              hide-details
            )
        tr
          td Pareja
          td
            v-text-field(
              :value="value.couple.fixed"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'couple.fixed')"
              hide-details
            )
          td
            v-text-field(
              :value="value.couple.variable"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'couple.variable')"
              hide-details
            )
</template>

<script>
  import set from 'lodash/set';

  export default {
    name: 'incomes',

    props: {
      disabled: { type: Boolean, default: false },
      value: { type: Object },
      isApproved: { type: Boolean, default: false },
    },

    methods: {
      onInput(val, key) {
        this.$emit('input', set(this.value, key, Number(val)));
      },
    },
  };
</script>

<style lang="sass" scoped>
  .incomes
    border: 1px solid darken(white, 16%)
    padding: 1rem
    border-radius: 6px

    &__title
      font-weight: bold
      color: darken(white, 40%)
      text-align: center

    table
      width: 100%

      tbody
        tr
          td
            padding: 3px 8px

          td:first-child
            width: auto
            font-weight: 500

          td:nth-child(2), td:nth-child(3)
            max-width: 150px !important

            & > *
              margin-top: -12px
</style>