import LoginView from '@/views/login';
import DashboardLayout from '@/layouts/dashboard';
import BareProtectedLayout from '@/layouts/bare-protected-layout';
import { PERMISSIONS } from '@/utils/constants';

/* Routes */
import CustomersList from '@/views/customers/list';
import CustomersNew from '@/views/customers/new';
import CustomersShow from '@/views/customers/show';
import DealsListView from '@/views/deals/list';
import DealsShowView from '@/views/deals/show';
import DashboardView from '@/views/dashboard';
import MetricsGeneralView from '@/views/metrics/general';
import MetricsAdvisersView from '@/views/metrics/advisers';
import MetricsSourcesView from '@/views/metrics/sources';
import MetricsCostsPerDealView from '@/views/metrics/costs-per-deal';
import BondsMetricsView from '@/views/loans-metrics/bonds';
import MortgageScheduleBorrowerView from '@/views/mortgage-schedule/borrower';
import MortgageScheduleInvestmentBorrowerView from '@/views/mortgage-schedule/investment-borrower';
import MortgageScheduleInvestmentInvestorView from '@/views/mortgage-schedule/investment-investor';
import InvestmentNewView from '@/views/investment/new';
import InvestmentListView from '@/views/investment/list';
import InvestmentShowView from '@/views/investment/show';
import BorrowerQuotesListView from '@/views/investment/borrowers';
import BorrowerStatementView from '@/views/investment/borrower-statement';
import InvestorQuotesListView from '@/views/investment/investors';
import BondsListView from '@/views/bonds/list';
import BondsNewView from '@/views/bonds/new';
import BondsShowView from '@/views/bonds/show';
import BondsQuotesListView from '@/views/bonds/quotes';
import RiskFilesListView from '@/views/risk-files/list';
import RiskFilesShowView from '@/views/risk-files/show';
import SimulatorView from '@/views/simulator/simulator-view';
import FormalizationsBoardView from '@/views/formalizations/board';
import FormalizationsShowView from '@/views/formalizations/show';
import FormalizationContractView from '@/views/formalizations/contract';
import FormalizationTitleStudyView from '@/views/formalizations/title-study';
import LoansMetricsGeneralView from '@/views/loans-metrics/general';
import LoansMetricsBorrowersFeeForecastView from '@/views/loans-metrics/borrowers-fee-forecast';
import LoansMetricsBorrowersMetricsView from '@/views/loans-metrics/borrowers';
import LoansMetricsLegalMortgageValueView from '@/views/loans-metrics/legal-mortgage-value';
import LibraryCategoriesListView from '@/views/library/library-categories-list-view';
import LibraryCategoryShowView from '@/views/library/library-category-show-view';
import LoansMetricsAumPctView from '@/views/loans-metrics/aum_pct';
import LoansMetricsLTVAuctionView from '@/views/loans-metrics/ltv_auction';
import LoansMetricsLTVComercialView from '@/views/loans-metrics/ltv_comercial';
import DeniedAppraisalsView from '@/views/metrics/denied-appraisals';

export default [
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: DashboardView,
        name: 'dashboard.index',
        meta: { permissions: [PERMISSIONS.PEGASUS.DASHBOARD] },
      },
    ],
  },
  {
    path: '/customers',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: CustomersList,
        name: 'customers.list',
        meta: { permissions: [PERMISSIONS.PEGASUS.CUSTOMERS] },
      },
      {
        path: 'new',
        component: CustomersNew,
        name: 'customers.new',
        meta: { permissions: [PERMISSIONS.PEGASUS.CUSTOMERS] },
      },
      {
        path: ':customerId',
        component: CustomersShow,
        name: 'customers.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.CUSTOMERS] },
      },
    ],
  },
  {
    path: '/deals',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: DealsListView,
        name: 'deals.list',
        meta: { permissions: [PERMISSIONS.PEGASUS.DEALS] },
      },
      {
        path: ':dealId',
        component: DealsShowView,
        name: 'deals.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.DEALS] },
      },
    ],
  },
  {
    path: '/metrics',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: MetricsGeneralView,
        name: 'metrics.general',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'advisers',
        component: MetricsAdvisersView,
        name: 'metrics.advisers',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'sources',
        component: MetricsSourcesView,
        name: 'metrics.sources',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'costs-per-deal',
        component: MetricsCostsPerDealView,
        name: 'metrics.costs_per_deal',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'aum',
        component: LoansMetricsGeneralView,
        name: 'metrics.aum',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'aum_pct',
        component: LoansMetricsAumPctView,
        name: 'metrics.aum_pct',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'ltv_auction',
        component: LoansMetricsLTVAuctionView,
        name: 'metrics.ltv_auction',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'ltv_comercial',
        component: LoansMetricsLTVComercialView,
        name: 'metrics.ltv_comercial',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'forecast',
        component: LoansMetricsBorrowersFeeForecastView,
        name: 'metrics.forecast',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'borrowers',
        component: LoansMetricsBorrowersMetricsView,
        name: 'metrics.borrowers',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'legal-mortgage-value',
        component: LoansMetricsLegalMortgageValueView,
        name: 'metrics.legal_mortgage_value',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'trust-bonds',
        component: BondsMetricsView,
        name: 'metrics.bonds',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
      {
        path: 'denied-appraisals',
        component: DeniedAppraisalsView,
        name: 'metrics.denied_appraisals',
        meta: { permissions: [PERMISSIONS.PEGASUS.METRICS] },
      },
    ],
  },
  {
    path: '/investment',
    component: DashboardLayout,
    children: [
      {
        path: 'proposals',
        component: InvestmentListView,
        name: 'investment.list',
        meta: { permissions: [PERMISSIONS.PEGASUS.INVESTMENT] },
      },
      {
        path: 'proposals/new',
        component: InvestmentNewView,
        name: 'investment.new',
        meta: { permissions: [PERMISSIONS.PEGASUS.INVESTMENT] },
      },
      {
        path: 'proposals/:proposalId',
        component: InvestmentShowView,
        name: 'investment.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.INVESTMENT] },
      },
      {
        path: 'borrowers',
        component: BorrowerQuotesListView,
        name: 'investment.borrower_quotes',
        meta: { permissions: [PERMISSIONS.PEGASUS.INVESTMENT] },
      },
      {
        path: 'investors',
        component: InvestorQuotesListView,
        name: 'investment.investor_quotes',
        meta: { permissions: [PERMISSIONS.PEGASUS.INVESTMENT] },
      },
    ],
  },
  {
    path: '/investment/borrower-statement',
    component: BareProtectedLayout,
    children: [
      {
        path: '',
        component: BorrowerStatementView,
        name: 'investment.borrower_statement',
        meta: {
          permissions: [PERMISSIONS.PEGASUS.INVESTMENT_BORROWER_STATEMENT],
        },
      },
    ],
  },
  {
    path: '/bonds',
    component: DashboardLayout,
    children: [
      {
        path: '',
        component: BondsListView,
        name: 'bonds.list',
        meta: { permissions: [PERMISSIONS.PEGASUS.BONDS] },
      },
      {
        path: 'new',
        component: BondsNewView,
        name: 'bonds.new',
        meta: { permissions: [PERMISSIONS.PEGASUS.BONDS] },
      },
      {
        path: 'quotes',
        component: BondsQuotesListView,
        name: 'bonds.quotes',
        meta: { permissions: [PERMISSIONS.PEGASUS.BONDS] },
      },
      {
        path: ':bondId',
        component: BondsShowView,
        name: 'bonds.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.BONDS] },
      },
    ],
  },
  {
    path: '/risk-files',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: RiskFilesListView,
        name: 'risk_files.list',
        meta: { permissions: [PERMISSIONS.PEGASUS.RISK_FILES] },
      },
      {
        path: ':riskFileId',
        component: RiskFilesShowView,
        name: 'risk_files.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.RISK_FILES] },
      },
    ],
  },
  {
    path: '/formalizations',
    component: DashboardLayout,
    children: [
      {
        path: '/',
        component: FormalizationsBoardView,
        name: 'formalizations.board',
        meta: { permissions: [PERMISSIONS.PEGASUS.FORMALIZATIONS] },
      },
      {
        path: ':formalizationId',
        component: FormalizationsShowView,
        name: 'formalizations.show',
        meta: { permissions: [PERMISSIONS.PEGASUS.FORMALIZATIONS] },
      },
    ],
  },
  {
    path: '/formalizations',
    component: BareProtectedLayout,
    children: [
      {
        path: 'contract/:contractId',
        component: FormalizationContractView,
        name: 'formalizations.contract',
        meta: { permissions: [PERMISSIONS.PEGASUS.FORMALIZATIONS] },
      },
      {
        path: 'title-study/:formalizationId',
        component: FormalizationTitleStudyView,
        name: 'formalizations.title_study',
      },
    ],
  },
  {
    path: '/library',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'library',
        component: LibraryCategoriesListView,
        meta: { permissions: [PERMISSIONS.PEGASUS.LIBRARY] },
      },
      {
        path: ':categoryId',
        name: 'library.category',
        component: LibraryCategoryShowView,
        meta: { permissions: [PERMISSIONS.PEGASUS.LIBRARY] },
      },
    ],
  },
  {
    path: '/simulator',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'simulator',
        component: SimulatorView,
        meta: { permissions: [PERMISSIONS.PEGASUS.SIMULATOR] },
      },
    ],
  },
  {
    path: '/mortgage-schedule',
    component: BareProtectedLayout,
    children: [
      {
        path: 'borrower',
        component: MortgageScheduleBorrowerView,
        name: 'mortgage_schedule.borrower',
        meta: {
          permissions: [PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__BORROWER],
        },
      },
      {
        path: 'investment-borrower',
        component: MortgageScheduleInvestmentBorrowerView,
        name: 'mortgage_schedule.investment_borrower',
        meta: {
          permissions: [
            PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__INVESTMENT_BORROWER,
          ],
        },
      },
      {
        path: 'investment-investor',
        component: MortgageScheduleInvestmentInvestorView,
        name: 'mortgage_schedule.investment_investor',
        meta: {
          permissions: [
            PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__INVESTMENT_INVESTOR,
          ],
        },
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: LoginView,
  },
  { path: '*', redirect: '/login' },
];
