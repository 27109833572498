<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :persistent="persistent")
    .px-2.pb-3
      .font-weight-medium Detalles del estudio de título
    v-divider.mb-4
    title-study-form(
      :title-study="titleStudy"
      :requesting="requesting"
      :potential-owners="potentialOwners"
      :adviser-id="adviserId"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import TitleStudyForm from '@/components/forms/title-study-form';

  export default {
    name: 'title-study-form-modal',

    props: {
      potentialOwners: { type: Array, default: () => [] },
      adviserId: { type: String },
    },

    mixins: [formModalMixin({ entityName: 'titleStudy' })],

    components: { TitleStudyForm },
  };
</script>
