const ID = 'tidio_script';

const init = () => {
  if(process.env.VUE_APP_TIDIO_URL && !document.getElementById(ID)) {
    const s = document.createElement('script')
    s.setAttribute('id', ID);
    s.setAttribute('src', process.env.VUE_APP_TIDIO_URL)

    document.body.appendChild(s);

  }
}

init();
