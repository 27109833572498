<template lang="pug">
  v-card.pa-3
    requirements-header(
      :requirements="requirements"
      @add="openForm"
    )
    requirements(
      :requirements="requirements"
      @change-status="args => $emit('change-status', args)"
      @delete="onClickDelete"
    )
    requirement-select-modal(
      v-if="formModal"
      v-model="formModal"
      :exclude-ids="requirementsTypesIds"
      :requesting="requesting"
      @submit="onSubmit"
    )
</template>

<script>
  import gestionableListMixin from '@/mixins/gestionable-list-mixin';
  import RequirementSelectModal from '@/components/modals/requirement-select-modal';

  import RequirementsHeader from './requirements-card-header';
  import Requirements from './requirements';

  export default {
    name: 'requirements-card',

    mixins: [
      gestionableListMixin({
        entityName: 'requirement'
      }),
    ],

    props: {
      requirements: { type: Array, default: () => ([]) },
      requesting: { type: Boolean },
    },

    data() {
      return {
        formModal: false,
      };
    },

    computed: {
      requirementsTypesIds() {
        return this.requirements.map(({ typeId }) => typeId);
      }
    },

    components: { RequirementsHeader, Requirements, RequirementSelectModal },

    methods: {
      closeForm() {
        this.formModal = false;
      },

      openForm() {
        this.formModal = true;
      },

      onSubmit({ id }) {
        this.$emit('create', { id });
      },

      onClickDelete({ requirement }) {
        if(window.confirm('¿Está seguro de que desea eliminar el requisito?')) this.$emit('delete', { requirement });
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>