import moment from 'moment';

const validate = (value, { date }) => value && date ? moment(value).isSameOrAfter(date) : true;

export default {
  validate,
  message(fieldName, { date }) {
    return `El campo ${fieldName} debe ser una fecha superior al ${moment(date).format('D MMM YYYY')}`;
  },
  params: ['date'],
};
