<template lang="pug">
  v-expansion-panel(:class="b()")
    deal-header(
      :slug="deal.product.slug"
      :motive="deal.motive"
      :accepted="someAccepted"
      :count="deal.proposals.length"
      :proposals="deal.proposals"
      :commercial-file-url="deal.commercialFile"
      :disable="isGeneratingFirstProposals"
      :deal-id="deal.id"
      @click-generate-commercial-file="args => $emit('click-generate-commercial-file', args)"
      @click-simulate="args => $emit('click-simulate', args)"
    )
    v-expansion-panel-content(:class="b('content')")
      proposals(
        :proposals="this.deal.proposals"
        :can-disapprove="canDisapprove"
        :requesting="requesting"
        @update="args => $emit('update', args)"
        @delete="args => $emit('delete', args)"
        @approve="args => $emit('approve', args)"
        @disapprove="args => $emit('disapprove', args)"
        @enable="args => $emit('enable', args)"
        @disable="args => $emit('disable', args)"
      )
</template>

<script>
  import { TASK_STATUS } from '@/utils/constants';
  import Proposals from '@/components/lists/proposals';
  import DealHeader from './deal-header';

  export default {
    name: 'deals-item',

    props: {
      deal: { type: Object, required: true },
      requesting: { type: Boolean },
      canDisapprove: { type: Boolean },
    },

    computed: {
      firstProposal() {
        return this.deal.proposals[0];
      },

      isGeneratingFirstProposals() {
        return this.deal.tasksStatus.generateProposalsForFirstTime === TASK_STATUS.PENDING;
      },

      someAccepted() {
        return this.deal?.proposals?.some(cProposal => cProposal.acceptedAt);
      },
    },

    components: { DealHeader, Proposals },
  };
</script>

<style lang="sass" scoped>
  .deals-item
    box-shadow: none

    &::before
      display: none // Remove box-shadow

    &__title
      display: flex
      align-items: center
      font-weight: bold
      font-size: 1.2rem
      margin-bottom: 1rem

    &__content
      padding: 0
</style>
