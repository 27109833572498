<script>
  import VSelect from 'vuetify/lib/components/VSelect';
  import VBtn from 'vuetify/lib/components/VBtn';
  import VIcon from 'vuetify/lib/components/VIcon';
  import pick from 'lodash/pick';
  import debounce from 'lodash/debounce';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import InvestmentProposalFormModal from '@/components/modals/investment-proposal-form-modal';
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';

  import { InvestmentProposalSelectQuery } from './graphql/queries.gql';
  import { CreateInvestmentProposalFromSelect } from './graphql/mutations.gql';
  import InvestmentProposalCard from './components/investment-proposal-card';

  export default {
    name: 'investment-proposal-select',

    mixins: [
      mutationsHandlersMixin(),
      lazySelectMixin({ itemsKey: 'investmentProposals' })
    ],

    props: {
      additionalSearchParams: { type: Object, default: () => ({}) },
      itemValue: { type: String, default: 'id' },
      createHiddenFields: { type: Array, default: () => [] },
      createAdditionalVariables: { type: Object },
      disabled: { type: Boolean },
      creatable: { type: Boolean, default: false },
      disabledCreate: { type: [Boolean, Function] },
    },

    data() {
      return {
        investmentProposals: [],
        isCreating: false,
        formModal: false,
        search: null,
      };
    },

    apollo: {
      investmentProposals: {
        query: InvestmentProposalSelectQuery,
        fetchPolicy: 'cache-and-network',
        manual: true,

        variables() {
          return {
            ...this.additionalSearchParams,
          };
        },

        result({ data }) {
          this.investmentProposals = data?.investmentProposals?.objects || [];
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      onCreateInvestmentProposal(investmentProposal) {
        this.$_lazySelectMixin_addInnerItem(investmentProposal);

        this.$emit('input', investmentProposal[this.itemValue]);

        this.formModal = false;
      },

      onSubmitCreateInvestmentProposal({ investmentProposal }) {
        this.handleCreateMutation({
          mutation: CreateInvestmentProposalFromSelect,
          requestingStateKey: 'isCreating',

          variables: {
            ...pick(investmentProposal, [
              'dealId',
              'defaultRate',
              'firstPaymentDate',
              'disbursementDate',
              'firstPaymentDate',
              'investorId',
              'irTaxRate',
              'penaltyFee',
              'rtcCommissionRate',
              'propertyCurrencyId',
              'propertyValue',
              'propertyAuctionValue',
              'currencyChange',
              'propertyInsurableValue',
              'guaranteeType'
            ]),
            ...this.createAdditionalVariables,
          },
        }).then(({ data }) => {
          const investmentProposal = data.createInvestmentProposal.investmentProposal;

          this.onCreateInvestmentProposal(investmentProposal);
        });
      },
    },

    render(h) {
      return h(
        'div',
        {
          class: 'd-flex align-center',
        },
        [
          h(VSelect, {
            class: [this.b(), 'flex-grow-1'],

            props: {
              ...this.$attrs,
              ...this.$props,
              items: this.lazyItems,
              filter: this.searchFn,
              loading: this.$apollo.queries.investmentProposals.loading,
              noFilter: true,
            },

            on: {
              ...this.$listeners,
              'update:search-input': this.onChangeSearch,
              focus: this.onFocus,
            },

            scopedSlots: {
              selection: data => h(InvestmentProposalCard, { props: { investmentProposal: data.item }}),
              item: data => h(InvestmentProposalCard, { props: { investmentProposal: data.item }}),
            }
          }),

          this.creatable && h(VBtn, {
            props: {
              color: 'primary',
              text: true,
              disabled: this.disabled || ((this.disabledCreate instanceof Function) ? this.disabledCreate(this) : this.disabledCreate),
            },
            on: { click: () => this.formModal = true },
          }, [
            h(VIcon, 'mdi-plus'),
            h('span', ' Nuevo'),
          ]),

          this.creatable && h(InvestmentProposalFormModal, {
            props: {
              value: this.formModal,
              hiddenFields: this.createHiddenFields,
              requesting: this.isCreating,
            },
            on: {
              input: v => this.formModal = v,
              submit: this.onSubmitCreateInvestmentProposal,
            },
          })
        ]
      );
    },
  };
</script>
