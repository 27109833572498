<template lang="pug">
  div(:class="b()")
    div
      b Inversionista:
      span  {{ investmentProposal.investor.firstName }} {{ investmentProposal.investor.lastName }}
    div.mb-2
      b Prestatario:
      span  {{ investmentProposal.borrower.firstName }} {{ investmentProposal.borrower.lastName }}
    .d-flex.justify-space-between.align-center
      tag(:color="statusColor") {{ $t(`investmentProposalStatus.${investmentProposal.status}`)}}
      span.font-weight-medium {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}

</template>

<script>
  import Tag from '@/components/UI/tag';
  import investmentProposalStatusColor from '@/utils/investment-proposal-status-color';

  export default {
    name: 'investment-proposal-card',

    props: {
      investmentProposal: { type: Object, required: true },
    },

    computed: {
      statusColor() {
        return investmentProposalStatusColor(this.investmentProposal.status);
      },
    },

    components: {
      Tag,
    },
  };
</script>

<style lang="sass" scoped>
  .investment-proposal-card
    display: block
    width: 100%
    padding: 1rem
    background-color: white
    color: inherit
    text-decoration: none
    font-size: 12px
    user-select: none
    touch-callout: none
    color: black
</style>