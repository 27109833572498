<template lang="pug">
  div(:class="b()")
    .create-customer-modal__step-title Información de Ingreso

    validation-observer(ref="form")
      form(@submit.prevent="onSubmit")
        v-layout(wrap)
          v-flex(xs12 sm6).px-2
            validation-provider(name="categoria" :rules="rules.incomeTypeId" v-slot="{ errors }")
              income-category-select(
                v-model="data.incomeTypeId"
                :error-messages="errors"
                name="categoria"
                @input="onInputCategory"
              )
          v-flex(xs12 sm6).px-2
            validation-provider(name="ingresos brutos" :rules="isNotDeclaredIncome ? null : rules.amount" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.amount"
                :disabled="isNotDeclaredIncome"
                :error-messages="errors"
                :prefix="data.currency.symbol"
                name="ingresos brutos"
                type="number"
                label="Ingresos (brutos)"
              )
          v-flex(v-if="renderField('currencyId')" xs12 sm6).px-2
            validation-provider(name="moneda" :rules="rules.currencyId" v-slot="{ errors }")
              currency-select(
                v-model="data.currencyId"
                :error-messages="errors"
                name="moneda"
                return-object
              )
          template(v-if="isNotDeclaredIncome")
            v-flex(xs12).px-2
              validation-provider(name="actividad económica" :rules="'required'" v-slot="{ errors }")
                economic-activity-select(
                  :value="data.industryId"
                  :error-messages="errors"
                  name="actividad económica"
                  label="Industria a la que se dedica tu empresa"
                  @input="onInputActivity"
                  return-object
                )
            v-flex(v-if="currentActivity" :class="b('activity-description')" xs12).pa-2
              div(:class="b('activity-description-title')") Descripción de la actividad económica
              div(:class="b('activity-description-description')") {{ currentActivity.description }}
            v-flex(xs12).px-2.pb-3
              .title.font-weight-medium.text-center.text--secondary Ventas brutas mensuales
            v-flex(xs12).px-2
              incomes-by-month(
                ref="incomesByMonth"
                v-if="isNotDeclaredIncome"
                v-model="data.byMonths"
                :min-length="1"
                :max-length="1"
                :currency-symbol="data.currency.symbol"
              )
            v-flex(xs12).pb-3
        v-divider.pb-2
        v-card-actions
          v-spacer
          v-btn(
            v-if="cancelable"
            :disabled="requesting"
            color="error"
            text
            @click="$emit('cancel')"
          ).px-4 Atras
          v-btn(
            :loading="requesting"
            color="primary"
            type="submit"
          ).px-4 Siguiente
</template>

<script>
import moment from 'moment';
import { pick } from 'lodash/fp';
import Config from '@/config';
import basicFormMixin from '@/components/forms/basic-form-mixin';
import IncomeCategorySelect from '@/components/inputs/income-category-select';
import IncomesByMonth from '@/components/inputs/incomes-by-month';
import CurrencySelect from '@/components/inputs/currency-select';
import EconomicActivitySelect from '@/components/inputs/economic-activity-select';
import { IS_PE_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'incomes-form',

  props: {
    cancelable: { type: Boolean, default: false },
  },

  data() {
    return {
      currentActivity: null,
    };
  },

  mixins: [
    basicFormMixin({
      modelKey: 'income',
      veeValidation: true,
      mapPropsToData() {
        return {
          incomeTypeId: null,
          amount: null,
          currency: Config.CURRENCIES_SELECT[0],
          byMonths: [],
        };
      },

      parseSubmit() {
        const { currency, ...data } = this.data;

        return {
          income: {
            ...data,
            currencyId: currency?.id,
            amount: this.data.amount || 0,
            byMonths: this.isNotDeclaredIncome
              ? this.data.byMonths.map(entry => ({
                  date: moment(
                    `${entry.year}-${entry.month}-01`,
                    'YYYY-M-DD',
                  ).format('YYYY-MM-DD'),
                  amount: entry.amount,
                }))
              : [],
          },
        };
      },
    }),
  ],

  methods: {
    async validate() {
      try {
        const success = await this.$refs.form.validate();
        if (this.isNotDeclaredIncome)
          await this.$refs.incomesByMonth.validate();

        if (!success) return Promise.reject();

        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },

    onInputCategory() {
      this.$nextTick(() => {
        if (this.isNotDeclaredIncome) {
          if (this.data.byMonths.length < 1)
            this.data.byMonths = [{ year: null, month: null, amount: null }];
        } else if (this.data.byMonths.length) {
          this.data.byMonths = [];
          this.data.industryId = null;
          this.currentActivity = null;
        }
      });
    },

    onInputActivity(activity) {
      this.data.industryId = activity.id;
      this.currentActivity = activity;
    },
  },

  computed: {
    rules() {
      return pick(['amount', 'netAmout', 'curencyId', 'incomeTypeId'])(
        Config.INCOME_FORM_RULES({
          data: this.data,
          hasWorkplaceData: this.hasWorkplaceData,
        }),
      );
    },

    hiddenFields() {
      return ['currencyId'];
    },

    isNotDeclaredIncome() {
      return IS_PE_COUNTRY && ['7', '3'].includes(this.data.incomeTypeId);
    },
  },

  components: {
    IncomeCategorySelect,
    IncomesByMonth,
    CurrencySelect,
    EconomicActivitySelect,
  },
};
</script>

<style lang="sass" scoped>
.incomes-form
  &__activity-description
    padding: 8px
    background-color: darken(white, 6%)
</style>
