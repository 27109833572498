import Vue from 'vue';
import { ValidationProvider, localize, ValidationObserver } from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';

import './rules';

localize('es', es);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
