import VSelect from 'vuetify/lib/components/VSelect';
import { RATE_TYPE } from '@/utils/constants';

export default {
  name: 'rate-type-select',

  functional: true,

  render(h, context) {
    const items = Object.values(RATE_TYPE).map(rateType => ({
        value: rateType,
        text: context.parent.$t(`rateType.${rateType}`),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};

