<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="$apollo.queries.stages.loading || stagesError || !stages"
      :error="stagesError"
      @retry="onRetryStages"
    )
    template(v-else)
      funnel-labels.mb-2
      div(:class="b('stages-container')")
        stage(
          v-for="stage in stages"
          v-bind="stage"
          :key="stage.id"
          :loading="fetchingStages.includes(stage.id)"
          @move-to-stage="onMoveDealToStage"
          @load-more="onLoadMore"
        )
</template>

<script>
  import $update from 'immutability-helper';
  import sortBy from 'lodash/sortBy';

  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';

  import Stage from './stage';
  import FunnelLabels from './funnel-labels';
  import { StagesQuery, DealsQuery } from '../../graphql/queries.gql';
  import { BoardMoveDealToStageMutation } from '../../graphql/mutations.gql';

  export default {
    name: 'board-view',

    mixins: [
      mutationsHandlersMixin(),
    ],

    props: {
      filters: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        stages: null,
        stagesError: null,
        lastProductSlug: null,
        fetchingStages: [],
      };
    },

    apollo: {
      stages: {
        query: StagesQuery,
        manual: true,

        variables() {
          return {
            productSlug: this.filters.productSlug || 'buy_mortgage',
          };
        },

        result({ data: { stages }}) {
          this.stages = sortBy(stages, ['orderNumber']);

          if (this.filters.productSlug !== this.lastProductSlug) {
            this.lastProductSlug = this.filters.productSlug;
            this.stages.forEach(({ id }, index) => this.$fetchDeals(id, index))
          }
        },

        error() {
          this.stagesError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },
    },

    methods: {
      async $fetchDeals(stageId, stageIndex) {
        this.$apollo.addSmartQuery(`stage_${stageId}_deals`, {
          query: DealsQuery,

          fetchPolicy: 'cache-and-network',

          manual: true,

          variables() {
            return {
              page: 1,
              ...this.filters,
              stageId,
            };
          },

          result({ data }) {
            if(!data) return;

            this.$set(this.stages[stageIndex], 'deals', data.deals);
          },
        });
      },

      findStageIndex(stageId) {
        return this.stages.findIndex(stage => stage.id === stageId);
      },

      async onMoveDealToStage({ deal, stageId: newStageId }) {
        const oldStageId = deal.currentStage.id;

        this.handleUpdateMutation({
          mutation: BoardMoveDealToStageMutation,

          variables: { dealId: deal.id, stageId: newStageId },
          update: (store, { data: { moveDealToStage } }) => {
            if(moveDealToStage.errors) return;

            const oldStageQuery = {
              query: DealsQuery,
              variables: {
                ...this.filters,
                page: 1,
                stageId: oldStageId,
              },
            };
            const newStageQuery = {
              query: DealsQuery,
              variables: {
                ...this.filters,
                page: 1,
                stageId: newStageId,
              },
            };

            const oldStageQueryResults = store.readQuery(oldStageQuery);
            const newStageQueryResults = store.readQuery(newStageQuery);

            store.writeQuery({
              ...oldStageQuery,
              data: $update(oldStageQueryResults, {
                deals: { objects: { $apply: deals => deals.filter(cDeal => cDeal.id !== deal.id )}}
              }),
            });

            store.writeQuery({
              ...newStageQuery,
              data: $update(newStageQueryResults, {
                deals: { objects: { $unshift: [moveDealToStage.deal]}},
              }),
            });
          },
        }).catch(() => true);
      },

      async onLoadMore({ stageId, nextDealsPage }) {
        this.fetchingStages.push(stageId);

        this.$apollo.queries[`stage_${stageId}_deals`].fetchMore({
          variables: {
            page: nextDealsPage,
            stageId,
            ...this.filters,
          },

          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.fetchingStages = this.fetchingStages.filter(id => id !== stageId);

            return $update(previousResult, {
              deals: {
                page: { $set: fetchMoreResult.deals.page },
                hasNext: { $set: fetchMoreResult.deals.hasNext },
                objects: { $push: fetchMoreResult.deals.objects },
              },
            });
          },
        });
      },

      onRetryStages() {
        this.stagesError = null;
        this.$apollo.queries.stages.refetch();
      },
    },

    components: { LoadingWrapper, Stage, FunnelLabels },
  };
</script>

<style lang="sass" scoped>
  .board-view
    height: 100%

    &__stages-container
      display: flex

      height: 100%
</style>
