<template lang="pug">
  thead
    tr
      th Asesor
      th Solicitudes
      th Solicitudes Netas
      th % Solicitudes netas
      th Propuestas aceptadas
      th File Comercial
      th Aprobados
      th Clientes
</template>

<script>
  export default {
    name: 'advisers-table-head',

    props: {
      items: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>
