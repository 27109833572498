<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      div(:class="b('score')")
        scores(:scores="scores")
      div(:class="b('summary')")
        totals-summary(:results="results" :hide-estimated-incomes="!hasScore")
    summary-table(:results="results").mb-2
    div(:class="b('dialogs')" v-if="hasScore")
      div(:class="b('title')") Información adicional
      div(:class="b('dialogs-content')")
        dialogs(:results="results")
</template>

<script>
import Scores from './scores';
import SummaryTable from './summary-table';
import TotalsSummary from './totals-summary';
import Dialogs from './dialogs';

export default {
  name: 'results-summary',

  props: {
    results: { type: Object, required: true },
  },

  computed: {
    scores() {
      return this.results?.riskPredictors?.length ? this.results.riskPredictors : this.results.businessScores;
    },

    hasScore() {
      return Boolean(this.scores?.[0]?.score > 1);
    },
  },

  components: {
    Scores,
    TotalsSummary,
    Dialogs,
    SummaryTable,
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .results-summary
    &__container
      +lg
        display: flex

    &__summary
      flex: 1

    &__score
      padding-bottom: 12px

      +lg
        max-width: 340px
        padding-right: 18px

      +xl
        max-width: 460px

    &__dialogs-content
      background-color: darken(white, 6%)
      padding: 12px
      border-radius: 6px

    &__title
      font-size: 18px
      font-weight: 500
      color: darken(white, 70%)
      margin-bottom: 12px
</style>