<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Periodo
            th Banco
            th Estado
            th Valor
        tbody
          table-row(v-for="credit, idx in credits" :key="idx" :credit="credit")
</template>

<script>
  import TableRow from './consumer-loan-credits-row';

  export default {
    name: 'consumer-loan-credits-table',

    props: {
      credits: { type: Array, default: () => [] },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .consumer-loan-credits
    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>