<template lang="pug">
  div(:class="b({ circle })")
    requirement(
      v-for="requirement in requirements"
      :key="requirement.id"
      :requirement="requirement"
      :circle="circle"
    )
</template>

<script>
  import Requirement from './requirements-item';

  export default {
    name: 'requirements',

    props: {
      requirements: { type: Array, default: () => [] },
      circle: { type: Boolean, default: false },
    },

    components: { Requirement },
  };
</script>

<style lang="sass" scoped>
  $spaceBetween: 5px

  .requirements
    display: flex
    margin-top: .5rem
    margin-left: -$spaceBetween
    margin-right: -$spaceBetween

    &--circle
      margin: 0

      .requirements-item
        margin: 0 2px

    &-item
      margin-left: $spaceBetween
      margin-right: $spaceBetween
</style>