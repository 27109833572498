<template lang="pug">
  tbody
    tr(
      v-for="row, idx in rows"
      :key="idx"
    )
      td {{ row.product | productName }}
      td {{ row.deals }}
      td {{ row.pctTotalDeals }}
      td {{ row.investment }}
      td {{ row.costPerDeal }}
</template>

<script>
  const PRODUCT_NAMES = {
    buy_mortgage: 'Mortgage refinancing',
    new_mortgage: 'New mortgage',
    home_equity_loan: 'Home equity loan',
  };

  export default {
    name: 'costs-per-deal-table-body',

    props: {
      rows: { type: Array, default: () => [] },
    },

    filters: {
      productName(slug) {
        return PRODUCT_NAMES[slug] || slug;
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>