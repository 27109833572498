<template lang="pug">
  div(:class="b()")
    div(:class="b('data-row-title')") Puedes pagar en diferentes bancos a nivel nacional utilizando tu código de  pago: #[b {{investmentProposal.kashioPaymentCode}}]
      div(:class="b('data-row-title')") Para más información puedes visitar:
        a(class='button' href='https://rebajatuscuentas.com/pe/ayuda/como-realizar-el-abono-de-tu-cuota/' target="_blank") #[b ¿Cómo pagar?]
</template>

<script>
export default {
  name: 'statement-how-to-pay',

  props: {
    investmentProposal: { required: true, type: Object },
  },
};
</script>

<style lang="sass" scoped>
  .statement-how-to-pay
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    padding-left: 18px

    &__name
      font-size: 20px
      font-weight: 500
      margin-bottom: 16px
      text-align: center

    &__data-row
      text-align: center
      margin-bottom: 18px

      &-date
        color: darken(white, 60%)
        font-weight: 500

      &-value
        font-size: 22px
        margin-bottom: 4px
        line-height: 1
        font-weight: 500

      &-title
        font-size: 16px
        line-height: 1.25

      &-detail
        font-size: 16px
        line-height: 1.25
        text-align: left
</style>
