<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    investment-form(
      :proposal="proposal"
      :currency="currency"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import InvestmentForm from '@/components/forms/investment-edit-form';

  export default {
    name: 'investment-edit-form-modal',

    props: {
      currency: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'proposal' })],

    components: { InvestmentForm },
  };
</script>
