<template lang="pug">
  div(:class="b()")
    figure(v-if="!logo" :class="b('logo')")
      img(src="https://assets.rebajatuscuentas.com/logos/alfin.svg" :class="b('logo')")
    //-- Punto 2
    div(:class="b('title')") Estado de Cuenta
    div(:class="b('name')") {{ investmentProposal.borrower.firstName }} {{ investmentProposal.borrower.lastName }}
      div(:class="b('name')") Código de pago: {{ investmentProposal.kashioPaymentCode }}

</template>

<script>
export default {
  name: 'view-header',

  props: {
    investmentProposal: { type: Object, required: true },
    logo: { type: Boolean, default: true },
  },
};
</script>

<style lang="sass" scoped>
  .view-header
    text-align: center

    &__title
      font-size: 22px
      font-weight: bold
      color: darken(white, 70%)

    &__name
      font-size: 20px
      font-weight: 500

    &__logo
      height: 42px
      margin-bottom: 18px
</style>
