<template lang="pug">
  div(:class="b()")
    slot
</template>

<script>
  export default {
    name: 'buro-results-table',
  };
</script>

<style lang="sass">
  .buro-results-table
    width: 100%
    padding-bottom: 20px

    & > .buro-results-row
      font-size: 14px

      & > *
        padding: 6px 8px

      &:nth-child(odd)
        background-color: #f6f6f6
</style>