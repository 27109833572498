<template lang="pug">
  validation-observer(ref="form")
    div(:class="b()")
      div(:class="b('row')" v-for="item, idx in value")
        div(:class="b('controls')")
          v-layout(wrap)
            v-flex(xs12 md5)
              validation-provider(:name="`acreedor ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
                v-text-field(
                  :name="`acreedor ${idx + 1}`"
                  :error-messages="errors"
                  :value="item.creditor"
                  :disabled="disabledFields.includes('creditor')"
                  label="Acreedor"
                  @input="v => onInputField(idx, 'creditor', v)"
                ).px-2
            v-flex(xs12 md4)
              validation-provider(:name="`monto ${idx + 1}`" :rules="'required|min:1|decimal:2'" v-slot="{ errors }")
                v-text-field(
                  :name="`monto ${idx + 1}`"
                  :error-messages="errors"
                  :value="item.amount"
                  :disabled="disabledFields.includes('amount')"
                  :prefix="currency.symbol"
                  label="Monto"
                  step="0.01"
                  @input="v => onInputField(idx, 'amount', v)"
                ).px-2
            v-flex(xs12 md3)
              validation-provider(:name="`estado ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
                .px-2
                  status-select(
                    :name="`estado ${idx + 1}`"
                    :error-messages="errors"
                    :value="item.status"
                    :disabled="disabledFields.includes('status')"
                    label="Estado"
                    name="estado"
                    @input="v => onInputField(idx, 'status', v)"
                  )
        div(:class="b('actions')")
          v-btn(
            v-if="value.length >= 1"
            color="error"
            :disabled="disabledFields.includes('delete')"
            @click="deleteItem(idx)"
            text
          ) Eliminar
      v-alert(v-if="errors && errors.length" color="error" type="error") {{ errors[0] }}
      div(:class="b('total')")
        div(:class="b('total-amounts')")
          span.font-weight-medium Total:
          b.ml-2.text-no-wrap {{ total | formatCurrency(currency) }}
          span.mx-1 de
          span.font-weight-medium.text-no-wrap {{ requiredTotal | formatCurrency(currency) }}
          b(v-if="!isValidTotal" :style="{ color: totalDiffColor }").ml-2.text-no-wrap ({{ totalDiff | formatCurrency(currency) }})
        v-alert(v-if="!isValidTotal" type="error" outlined).mt-2 El total del detalle del desembolso debe ser igual a #[b.text-no-wrap {{ requiredTotal | formatCurrency(currency)}}] actualmente tienes una diferencia de #[b(:style="{ color: totalDiffColor }").text-no-wrap {{ totalDiff | formatCurrency(currency)}}]
      .d-flex.justify-center
        v-btn(
          v-if="value.length < maxLength"
          :disabled="disabledFields.includes('add')"
          @click="addItem"
          color="primary"
          text
        )
          v-icon mdi-plus
          span Agregar Deuda
</template>

<script>
import Config from '@/config';
import { PAY_DETAIL_STATUS } from '@/utils/constants';
import StatusSelect from '@/components/inputs/pay-detail-status-select';
import dynamicEntriesInputMixin from '@/mixins/dynamic-entries-input-mixin';

export default {
  name: 'contract-pay-details-input',

  props: {
    currency: { type: Object, default: () => Config.CURRENCIES_SELECT[0] },
    requiredTotal: { type: Number },
    disabledFields: { type: Array, default: () => [] },
  },

  mixins: [
    dynamicEntriesInputMixin({
      itemDefaultValues: { creditor: null, amount: null, status: PAY_DETAIL_STATUS.PENDING },
    }),
  ],

  computed: {
    total() {
      return this.value.reduce((acc, { amount }) => acc + Number(amount), 0);
    },

    isValidTotal() {
      return parseFloat(this.total) === parseFloat(this.requiredTotal);
    },

    totalDiff() {
      return this.total - this.requiredTotal;
    },

    totalDiffColor() {
      return this.totalDiff > 0 ? '#4caf50' : '#f44336';
    }
  },

  methods: {
    async validate() {
      if(this.disabled) return Promise.resolve();

      const success = await this.$refs.form.validate();

      if(success && !this.hasErrors && this.isValidTotal) return Promise.resolve()
      else return Promise.reject(`${this.options.name} field is required`);
    },
  },

  components: { StatusSelect },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .contract-pay-details-input
    &__row
      margin-bottom: 8px

      &:nth-child(even)
        background-color: darken(white, 3%)

    &__actions
      flex: 1
      text-align: right

    &__total
      &-amounts
        text-align: right
        font-size: 1.1em

    +md
      &__row
        display: flex
        align-items: center
        margin-bottom: 0

        &:nth-child(even)
          background-color: initial
</style>
