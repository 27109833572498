<template lang="pug">
  .dashboard-card(:class="b()")
    .dashboard-card__header
      .dashboard-card__title Negociaciones este mes
      .dashboard-card__count(v-if="deals") {{ total.deals }}
    .dashboard-card__body
      loading-wrapper(
        v-if="loading || error"
        :error="error"
        @retry="args => $emit('retry', args)"
      )
      template(v-else-if="deals && deals.length")
        div(:class="b('total')" v-if="total.amount") {{ total.amount | formatCurrency(total.currency) }}
        deals-products(:products="total.products")
      v-alert(v-else color="info" :value="true" outlined) Aún no tienes nuevas negociaciones 😕
</template>

<script>
  import groupBy from 'lodash/groupBy';

  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import DealsProducts from './deals-products';

  export default {
    name: 'deals-month',

    props: {
      deals: { type: Array },
      error: { type: Error },
      loading: { type: Boolean },
    },

    components: { DealsProducts, LoadingWrapper },

    computed: {
      total() {
        if(!this.deals) return;

        return {
          deals: this.deals.length,
          amount: this.deals.reduce((total, deal) => total + deal.credit, 0),
          currency: this.deals[0]?.currency || { symbol: '' },
          products: Object.entries(
            groupBy(this.deals, deal => deal.product.slug),
          ).map(([slug, deals]) => ({
            slug,
            deals: deals.length,
            amount: deals.reduce((total, deal) => total + deal.credit, 0),
            currency: deals[0].currency,
          })),
        };
      }
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deals-month
    &__total
      font-size: 24px
      font-weight: bold
      text-align: center

      +sm
        font-size: 30px
</style>