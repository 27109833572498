<template lang="pug">
  tr
    td {{ entry.entity }}
    td(:style="{ color: getCalificationColor(entry.qualification) }").font-weight-bold {{ entry.qualification | formatCalification }}
    td {{ entry.activeCredits | formatCurrency }}
    td {{ entry.expiredCredits | formatCurrency }}
    td {{ entry.judicialCredits | formatCurrency }}
    td {{ entry.refinancedCredits | formatCurrency }}
    td {{ entry.totalAmount | formatCurrency }}
</template>

<script>
  import formatCalification from '@/utils/format-buro-qualification';
  import getCalificationColor from '@/utils/buro-qualification-color';

  export default {
    name: 'credits-amount-row',

    props: {
      entry: { type: Object, required: true },
    },

    methods: {
      getCalificationColor,
    },

    filters: {
      formatCalification,
    },
  };
</script>

<style lang="sass" scoped>

</style>