import { BUY_MORTGAGE_SLUG, NEW_MORTGAGE_SLUG, HOME_EQUITY_LOAN_SLUG } from "@/utils/constants";

const CLOUDFRONT_URL = process.env.VUE_APP_CLOUDFRONT_URL || 'https://assets.rebajatuscuentas.com';

export default {
  COUNTRY_CODE: null,
  /**
   * FROM ENV
   */
  BASE_PATH: process.env.VUE_APP_BASE_PATH || '/',
  GRAPH_URL: process.env.VUE_APP_GRAPH_URL,
  ADVISER_LANDING_URL: process.env.VUE_APP_ADVISER_LANDING_URL || 'https://rebajatuscuentas.com/pe/l',
  CLOUDFRONT_URL,
  RAVEN_DSN: process.env.VUE_APP_RAVEN_DSN || 'https://606bb31903754aef94a1324c0c181e4e@sentry.io/1271328',
  SENTRY_ENVIRONMENT: process.env.VUE_APP_SENTRY_ENVIRONMENT || 'production',
  GOOGLE_MAPS_API_KEY: process.env.VUE_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyDoqXFjk0phlS5UttNNOqB2bcwz_Q3gwd0',
  LOCALE: 'es-PE',
  URL_CRYPT_KEY: 'k24f', // No cambiar

  /**
   * FEATURES
   */
  RCI_ENABLED: true,
  NIP_VALIDATION: false,
  RISK_FILES_ENABLED: false,
  INVESTMENTS_ENABLED: false,
  FORMALIZATIONS_ENABLED: false,
  LOANS_METRICS_ENABLED: false,
  WHATSAPP_TEMPLATES: false,
  SCOTIA_SCORE_ENABLED: false,
  BONDS_ENABLED: false,
  SIMULATOR_ENABLED: false,
  SENTINEL_ENABLED: false,
  CREDIT_HIDDEN_FIELDS: ['amortizationType'],

  /**
   * UNIQUE VALUES
   */
  DEFAULT_CURRENCY_ID: null,
  PHONE_CODE: null,
  PROPERTY_TYPE_TERRAIN_ID: '3',
  GROUPS_INVESTOR_ID: process.env.VUE_APP_GROUPS_INVESTOR_ID,
  GROUPS_RISK_ID: process.env.VUE_APP_GROUPS_RISK_ID || '9',
  GROUPS_INVESTMENT_PROPOSAL_ID: process.env.VUE_APP_GROUPS_INVESTMENT_PROPOSAL_ID || '13',
  GROUPS_FORMALIZATION_ID: process.env.VUE_APP_GROUPS_FORMALIZATION_ID,
  GROUPS_METRICS_ID: process.env.VUE_APP_GROUPS_METRICS_ID,
  GROUPS_VEHICLE_ID: process.env.VUE_APP_GROUPS_VEHICLE_ID,
  GROUPS_BONDS_ID: process.env.VUE_APP_GROUPS_BONDS_ID,
  /**
   * THEMING
   */
  COMPANY_NAME: 'RebajaTusCuentas',
  COMPANY_LEGAL_NAME: 'Latam Financial Services INC',
  WEBSITE_URL: 'https://rebajatuscuentas.com/pe',
  BLOG_URL: 'https://rebajatuscuentas.com/pe/blog',
  PRIMARY_COLOR: '#0091ff',
  SUPPORT_EMAIL: 'ayuda@rebajatuscuentas.com',
  COMPANY_LOGO: CLOUDFRONT_URL + '/logos/rebaja_tus_cuentas.svg',
  COMPANY_LOGO_WHITE: CLOUDFRONT_URL + '/logos/rebaja_tus_cuentas_white.svg',
  COMPANY_LOGO_MINI: CLOUDFRONT_URL + '/logos/rebaja_tus_cuentas_mini.svg',
  COUNTRIES: [
    { value: 'pe', text: 'Perú', flag: CLOUDFRONT_URL + '/images/flags/peru.svg', URL: 'https://pegasus.rebajatuscuentas.com/pe/login' },
    { value: 'co', text: 'Colombia', flag: CLOUDFRONT_URL + '/images/flags/colombia.svg', URL: 'https://pegasus.rebajatuscuentas.com/co/login' },
    { value: 'mx', text: 'México', flag: CLOUDFRONT_URL + '/images/flags/mexico.svg', URL: 'https://pegasus.rebajatuscuentas.com/mx/login' },
  ],

  ACCOUNTING: {
    DECIMAL: '.',
    THOUSAND: ',',
    SYMBOL: '$',
  },

  /**
   * SELECTS AND LISTS
   */
  ADDRESS_POLITICAL_DIVISIONS: [],

  INCOME_CATEGORIES_SELECT: [],

  PRODUCTS_SELECT: [
    { value: BUY_MORTGAGE_SLUG, text: 'Compra de Deuda Hipotecaria' },
    { value: NEW_MORTGAGE_SLUG, text: 'Nuevo Crédito Hipotecaria' },
    { value: HOME_EQUITY_LOAN_SLUG, text: 'Préstamo Garantía Hipotecaria' },
  ],

  MARITAL_STATUS_SELECT: [
    { value: '1', text: 'Soltero' },
    { value: '2', text: 'Casado' },
    { value: '3', text: 'Viudo' },
    { value: '4', text: 'Divorciado' },
    { value: '5', text: 'Casado/Separacion de bienes' },
  ],

  GENDER_TYPE_SELECT:[
    {value:'FEMALE', text:'Femenino'},
    {value:'MALE', text:'Masculino'}
  ],

  GENDERS_SELECT: [
    { text: 'Hombre', value: 'male' },
    { text: 'Mujer', value: 'female' },
  ],

  CURRENCIES_SELECT: [
    { text: 'Soles', id: '1', symbol: 'S/' },
    { text: 'Dólares', id: '2', symbol: 'USD$' },
  ],

  INSURANCE_TYPES_SELECT: [
    { text: 'Mancomunado', value: '1', slug: 'joint' },
    { text: 'Individual', value: '2', slug: 'individual' },
    { text: 'Endosado', value: '3', slug: 'endosed' },
  ],

  /**
   * Render & Hidders
   */

  PRODUCT_FILTER_FIELDS: slug => {
    switch (slug) {
      case BUY_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'insuranceTypeId', 'feeType', 'limitMonths', 'balloon'];
      case NEW_MORTGAGE_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'totalIncoming', 'propertyValue', 'insuranceTypeId', 'openAmount', 'feeType', 'limitMonths', 'balloon']
      case HOME_EQUITY_LOAN_SLUG:
        return ['productSlug', 'generateStrategy', 'currencyId', 'creditAmount', 'insuranceTypeId', 'feeType', 'limitMonths', 'balloon']
      default:
        return [];
    }
  },


  /**
   * VALIDATIONS
   */
  DOCS_VALID_EXTENSIONS: [],

  PHONE_VALIDATION: {
    REGXP: null,
    MESSAGE: () => null,
  },

  CUSTOMER_FORM_RULES: () => ({
    mobile: {},
    vat: {
      required: true,
      numeric: true,
    },
  }),

  BURO_FORM_RULES: () => ({}),

  CREDIT_FORM_RULES: () => ({}),

  APPRAISAL_FORM_RULES: () => ({}),

  TITLE_STUDY_FORM_RULES: () => ({}),

  PROPERTY_FORM_RULES: () => ({
    value: {
      required: true,
      decimal: 2,
    },
    insured: {
      required: true,
    },
  }),

  INCOME_FORM_RULES: () => ({
    amount: {
      required: true,
      decimal: 2,
    },
  }),

  PROPOSAL_FILTERS_FORM_RULES: () => ({}),

  SIMULATOR_FILTERS_FORM_RULES: () => ({}),
};
