<template lang="pug">
  v-chip(
    @input="args => $emit('close', args)"
  )
    user-avatar(
      :url="adviser.avatar"
      :first-name="adviser.firstName"
      :last-name="adviser.lastName"
      :size="32"
      left
    )
    | {{ adviser.firstName }} {{ adviser.lastName }}
</template>

<script>
  import UserAvatar from './user-avatar';

  export default {
    name: 'adviser-chip',

    props: {
      adviser: { type: Object, required: true },
      close: { type: Boolean, default: false },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass" scoped>

</style>