<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        div(style="width:120px").pl-2.text-center
          validation-provider(name="foto" :rules="'required'" v-slot="{ errors }")
            avatar-input(
              v-model="data.image"
              :error-messages="errors"
              name="foto"
              :size="64"
            )
        v-flex
          validation-provider(name="nombres" :rules="{ required: true, alpha_spaces: true }" v-slot="{ errors }")
            v-text-field(
              v-model="data.names"
              :error-messages="errors"
              name="nombres"
              label="Nombres"
              autofocus
            )
          validation-provider(name="ocupación" :rules="{ required: true, alpha_spaces: true }" v-slot="{ errors }")
            v-text-field(
              v-model="data.occupation"
              :error-messages="errors"
              name="ocupación"
              label="Ocupación"
            )
          validation-provider(name="testimonio" :rules="{ required: true }" v-slot="{ errors }")
            v-textarea(
              v-model="data.testimony"
              :error-messages="errors"
              name="testimonio"
              label="Testimonio"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) {{ data.id ? 'Actualizar' : 'Agregar' }} Testimonio
</template>

<script>
import basicFormMixin from './basic-form-mixin';

import AvatarInput from '@/components/inputs/avatar-input';

export default {
  name: 'testimony-form',

  mixins: [
    basicFormMixin({
      modelKey: 'testimony',
      veeValidation: true,

      mapPropsToData({ testimony }) {
        return {
          id: testimony?.id,
          names: testimony?.names,
          occupation: testimony?.occupation,
          testimony: testimony?.testimony,
          image: testimony?.image,
        };
      },

      parseSubmit() {
        return {
          testimony: {
            ...this.data,
            image: this.data?.image?.includes('http') ? undefined : this.data.image,
          },
        };
      },
    }),
  ],

  components: { AvatarInput },
};
</script>

<style lang="sass" scoped>
</style>