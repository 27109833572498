<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Validaciones de NIP
    div(:class="b('description')").pb-2
      p La validación de NIP es un proceso en el cual se le envía un código unico al cliente por SMS para que autorice a RebajaTusCuentas de poder consultar su información crediticia, dicha validación es necesaria para poder calcular el endeudamiento y historial crediticio del cliente, la validacion consta de dos sencillos pasos:
      ol
        li
          span.font-weight-medium ENVIAR NIP:&nbsp;
          span En este paso se llena la información necesaria del cliente y luego se le enviará un SMS al cliente con su respectivo código NIP.
        li
          span.font-weight-medium VALIDAR NIP:&nbsp;
          span En este segundo paso, se procede a ingresar el código NIP que fue recibido por el cliente

    div(:class="b('container')")
      nip-validation-card(
        v-if="customer"
        :customer="customer"
        context-name="Titular"
        :requesting="requesting"
        @click-request-nip="onClickRequestNip"
        @click-validate-nip="onClickValidateNip"
      )
      nip-validation-card(
        v-if="couple"
        :customer="couple"
        context-name="Pareja"
        :requesting="requesting"
        @click-request-nip="onClickRequestNip"
        @click-validate-nip="onClickValidateNip"
      )
    request-nip-form-modal(
      v-model="requestNipFormModal"
      :requesting="requesting"
      :customer="currentCustomer"
      @submit="onSubmitRequestNipForm"
      @cancel="requestNipFormModal = false"
    )
    authorize-nip-form-modal(
      v-model="authorizeNipFormModal"
      :requesting="requesting"
      @submit="onSubmitAuthorizeNipForm"
      @cancel="authorizeNipFormModal = false"
    )
</template>

<script>
  import RequestNipFormModal from '@/components/modals/request-nip-form-modal';
  import AuthorizeNipFormModal from '@/components/modals/authorize-nip-form-modal';
  import NipValidationCard from './nip-validation-card';

  export default {
    name: 'nip-validation',

    props: {
      requesting: { type: Boolean, default: false },
      customer: { type: Object },
      couple: { type: Object },
    },

    data() {
      return {
        currentCustomer: null,
        requestNipFormModal: false,
        authorizeNipFormModal: false,
      };
    },

    methods: {
      onClickRequestNip({ customer }) {
        this.currentCustomer = customer;
        this.requestNipFormModal = true;
      },

      onClickValidateNip({ customer }) {
        this.currentCustomer = customer;
        this.authorizeNipFormModal = true;
      },

      onSubmitRequestNipForm({ customer }) {
        this.$emit('submit-request-nip', { customer, component: this });
      },

      onSubmitAuthorizeNipForm({ code }) {
        this.$emit('submit-authorize-nip', { code, customer: this.currentCustomer, component: this });
      },

      closeForm() {
        this.requestNipFormModal = false;
        this.authorizeNipFormModal = false;
      },
    },

    components: {
      NipValidationCard,
      RequestNipFormModal,
      AuthorizeNipFormModal,
    },
  };
</script>

<style lang="sass" scoped>
  $cardsGap: 8px

  .nip-validation
    &__title
      font-size: 18px
      text-align: center
      font-weight: 500

    &__description
      margin-top: 4px

    &__container
      display: flex
      flex-wrap: wrap
      margin-left: -$cardsGap
      margin-right: -$cardsGap

      & > *
        flex: 1
        margin-left: $cardsGap
        margin-right: $cardsGap
        margin-bottom: 4px
</style>