<template lang="pug">
  div(:class="b()")
    product-item(
      v-for="product in products"
      :key="product.slug"
      :product="product"
      :active="value === product.slug"
      @click="onClickItem"
    )
</template>

<script>
  import ProductItem from './products-menu-item';

  export default {
    name: 'products-menu',

    props: {
      value: { type: String },
      products: { type: Array, default: () => [] },
    },

    components: { ProductItem },

    methods: {
      onClickItem(product) {
        this.$emit('input', product.slug);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .products-menu
    display: flex
    overflow-x: auto

    +lg
      display: block
</style>