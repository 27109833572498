<template lang="pug">
  div(:class="b({ selected })")
    user-avatar(
      :class="b('avatar')"
      :first-name="user.firstName"
      :last-name="user.lastName"
      :url="user.avatar"
    ).hidden-xs-only
    div(:class="b('body')")
      div(:class="b('name')") {{ user.firstName }} {{ user.lastName }}
      div(:class="b('username')") @{{ user.username }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';

  export default {
    name: 'suggestions-item',

    props: {
      user: { type: Object, required: true },
      selected: { type: Boolean, default: false },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass" scoped>
  .suggestions-item
    display: flex
    padding: .35rem 1rem
    cursor: pointer
    user-select: none

    &__name
      font-weight: 500

    &__avatar
      margin-right: 1rem
      flex-shrink: 0

    &:hover
      background-color: darken(white, 2%)

    &--selected
      background-color: darken(white, 7%)
</style>