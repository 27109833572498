<template lang="pug">
  li(:class="b()") {{ fullAddress }}
</template>

<script>
  import compact from 'lodash/compact';

  export default {
    name: 'addresses-row',

    props: {
      address: { type: Object },
    },

    computed: {
      fullAddress() {
        return compact([
          this.address.direccion,
          this.address.colonia,
          this.address.municipio,
          this.address.ciudad,
          this.address.estado,
          this.address.codigoPostal,
        ]).join(', ');
      },
    },
  };
</script>

<style lang="sass" scoped>
  .addresses-row
    padding: 4px 12px
</style>