import store from 'store';
import moment  from 'moment';
import Config from '@/config';

const AUTH_STORAGE_CREDENTIALS_KEY = `pegasus_${Config.COUNTRY_CODE}/auth_credentials`;
const AUTH_STORAGE_USER_DATA_KEY = `pegasus_${Config.COUNTRY_CODE}/auth_user_data`;

export const getCredentials = () => store.get(AUTH_STORAGE_CREDENTIALS_KEY);

export const setCredentials = ({ token, expire }) => store.set(AUTH_STORAGE_CREDENTIALS_KEY, { token, expire });

export const setUserData = data => store.set(AUTH_STORAGE_USER_DATA_KEY, data);

export const getUserData = () => store.get(AUTH_STORAGE_USER_DATA_KEY);

export const tokenHasExpired = () => {
  const { token, expire } = getCredentials() || {};

  return token
    ? moment.unix(expire).isBefore(moment())
    : true;
};


export const removeAll = () => {
  store.remove(AUTH_STORAGE_CREDENTIALS_KEY);
  store.remove(AUTH_STORAGE_USER_DATA_KEY);
};

export default {
  getCredentials,
  setCredentials,
  setUserData,
  getUserData,
  removeAll,
  tokenHasExpired,
};
