<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    request-nip-form(
      :customer="customer"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import RequestNipForm from '@/components/forms/request-nip-form';

  export default {
    name: 'request-nip-form-modal',

    mixins: [formModalMixin({ entityName: 'customer' })],

    components: { RequestNipForm },
  };
</script>
