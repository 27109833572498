<template lang="pug">
  div(:class="b()")
    form(@submit.prevent="onSubmit" :disabled="isDisabled")
      v-select(
        v-model="selectedTemplate"
        label="Seleccionar Plantilla"
        :disabled="requesting"
        :items="templates"
        item-text="name"
        return-object
      )
      div(:class="b('body')")
        div(:class="b('body-title')") Mensaje
        div(v-if="selectedTemplate" :class="b('body-content')") {{ selectedTemplate.body }}
      .d-flex.justify-end.pt-4
        v-btn(
          color="primary"
          type="submit"
          :disabled="isDisabled"
          :loading="requesting"
        )
          span Enviar Whatsapp
          v-icon.ml-2 mdi-send

</template>

<script>
export default {
  name: 'whatsapp-templates',

  data() {
    return {
      selectedTemplate: null,
    };
  },

  props: {
    templates: { type: Array, default: () => [] },
    requesting: { type: Boolean, default: false },
  },

  methods: {
    onSubmit() {
      this.$emit('submit-send', { template: this.selectedTemplate });
    },

    clear() {
      this.selectedTemplate = null;
    },
  },

  computed: {
    isDisabled() {
      return !this.selectedTemplate || this.requesting;
    },
  },
};
</script>

<style lang="sass" scoped>
  .whatsapp-templates
    &__body
      padding: 14px
      font-size: 1.1em
      background-color: darken(white, 6%)
      border-radius: 6px

      &-title
        font-weight: bold
        margin-bottom: 8px

</style>