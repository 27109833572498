
<template lang="pug">
  div(:class="b()")
    div(:class="b('name')") {{ customer.nombres }} {{ customer.apellidoPaterno }} {{ customer.apellidoMaterno }}
    div(:class="b('body')")
      div(:class="b('data')")
        b RFC:&nbsp;
        span {{ customer.rfc }}
      div(v-if="customer.curp" :class="b('data')")
        b CURP:&nbsp;
        span {{ customer.curp }}
      div(v-if="customer.numeroSeguridadSocial" :class="b('data')")
        b Seguro Social:&nbsp;
        span {{ customer.numeroSeguridadSocial }}
      div(:class="b('data')")
        b Fecha de Nacimiento:&nbsp;
        span {{ customer.fechaNacimiento }}
      div(v-if="customer.nacionalidad" :class="b('data')")
        b Nacionalidad:&nbsp;
        span {{ customer.nacionalidad }}
      div(v-if="customer.sexo" :class="b('data')")
        b Sexo:&nbsp;
        span {{ customer.sexo }}
      div(v-if="customer.estadoCivil" :class="b('data')")
        b Estado Civil:&nbsp;
        span {{ customer.estadoCivil }}
</template>

<script>
  export default {
    name: 'customer-card',

    props: {
      customer: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .customer-card
    background-color: darken(white, 3%)
    padding: 16px
    margin-bottom: 4px

    &__name
      font-size: 22px

    &__body
      +lg
        display: flex
        flex-wrap: wrap

    &__data
      margin-right: 16px
</style>