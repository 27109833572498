<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Score
    div(:class="b('container')")
      div(:class="b('body')")
        div(:class="b('score-info')")
          div(:class="b('score-name')") {{ score.riskLevel }}
          div(:class="b('score-amount')") {{ score.score }}
        score-bar(:score="score.score" :ranges="ranges")
      div(v-if="score.conclusion" :class="b('conclusion')") {{ score.conclusion }}
</template>

<script>
  import ScoreBar from '@/components/UI/score-bar';

  const RANGES = [
    { color: '#f6412d', min: 1 , max: 353 },
    { color: '#ef6743', min: 354 , max: 520 },
    { color: '#FEC049', min: 521 , max: 686 },
    { color: '#49c073', min: 687 , max: 854 },
    { color: '#52aaf9', min: 855 , max: 999 },
  ];

  export default {
    name: 'score',

    props: {
      score: { type: Object },
    },

    data() {
      return { ranges: RANGES };
    },

    components: { ScoreBar },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .score
    background-color: darken(white, 4%)
    border-radius: 6px
    padding: 12px 16px

    &__score-info
      display: flex
      align-items: center
      justify-content: space-between

    &__score-amount
      font-size: 28px
      font-weight: bold
      text-align: center
      padding: 0 8px

    &__score-name
      font-weight: 500
      font-size: 24px

    &__reasons
      padding-top: 12px
</style>