import { render, staticRenderFns } from "./nip-validation.vue?vue&type=template&id=6a5c8c9a&scoped=true&lang=pug"
import script from "./nip-validation.vue?vue&type=script&lang=js"
export * from "./nip-validation.vue?vue&type=script&lang=js"
import style0 from "./nip-validation.vue?vue&type=style&index=0&id=6a5c8c9a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5c8c9a",
  null
  
)

export default component.exports