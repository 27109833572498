<template lang="pug">
  v-card(:class="b()")
    div(:class="b('table-container')")
      table(:class="b('table')").rtc-metrics-table.rtc-metrics-table--striped
        table-head
        table-body(:rows="rows")
        table-footer(:totals="totals")
</template>

<script>
  import TableHead from './costs-per-deal-table-head';
  import TableBody from './costs-per-deal-table-body';
  import TableFooter from './costs-per-deal-table-footer';

  export default {
    name: 'costs-per-deal-table',

    props: {
      rows: { type: Array },
      totals: { type: Object },
    },

    components: {
      TableHead,
      TableBody,
      TableFooter,
    },
  };
</script>

<style lang="sass"></style>