<template lang="pug">
  validation-observer(ref="form")
    v-form(@submit.prevent="onSubmit")
      div(:class="b()")
        incomes-form-step-column(:value="selectedDocuments" :customer="customer").mb-2
        incomes-form-step-column(:value="selectedDocuments" v-if="couple" :customer="couple")
        div(style="padding-top: 36px")
          v-btn(color="primary" type="submit" :disabled="isDisabled").mr-2 Confirmar Ingresos
          v-btn(type="button" @click="$emit('cancel')" color="error" text) Regresar
</template>

<script>
import IncomesFormStepColumn from './incomes-form-step-column';
import formStepMixin from '../form-step-mixin';


export default {
  name: 'incomes-form-step',

  mixins: [
    formStepMixin()
  ],

  props: {
    customer: { type: Object },
    couple: { type: Object },
    defaultValues: { type: Object },
  },

  data() {
    return {
      selectedDocuments: {},
    };
  },

  methods: {
    onSubmit() {
      this.$refs.form.validate()
        .then((success) => {
          if (success) this.$emit('submit', { data: { ...this.selectedDocuments, ...this.defaultValues } });
        });
    },
  },

  computed: {
    isDisabled() {
      const clientIncomes = this.customer?.incomes ? this.customer.incomes.length : 0;
      const coupleIncomes = this.couple?.incomes ? this.couple.incomes.length : 0;
      const totalIncomes = clientIncomes + coupleIncomes;
      return totalIncomes > Object.keys(this.selectedDocuments).length && Object.keys(this.selectedDocuments).length === 0 ? true : Object.keys(this.selectedDocuments).map((key) => this.selectedDocuments[key].length === 0).includes(true);
    },
  },

  mounted() {
    this.selectedDocuments = this.defaultValues;
  },

  components: {
    IncomesFormStepColumn,
  },
};
</script>

<style lang="sass" scoped>
  .incomes-form-step
</style>
