<template lang="pug">
  tag(:color="color") {{ $t(`mortgageNumberStatus.${status}`) }}
</template>

<script>
import Tag from '@/components/UI/tag';
import { MORTGAGE_NUMBER_STATUS } from '@/utils/constants';

const COLORS = {
  [MORTGAGE_NUMBER_STATUS.IN_QUALIFICATION]: '#ffc107',
  [MORTGAGE_NUMBER_STATUS.SIGNED_UP]: '#4caf50',
  [MORTGAGE_NUMBER_STATUS.SIGNED_UP_2nd]: '#4caf50',
  [MORTGAGE_NUMBER_STATUS.PENDING]: '#d5b5e5',
  [MORTGAGE_NUMBER_STATUS.OBSERVED]: '#f44336',
  [MORTGAGE_NUMBER_STATUS.LIQUIDATED]: '#1976d2',
};

export default {
  name: 'mortgage-number-status-tag',

  props: {
    status: { type: String, required: true },
  },

  computed: {
    color() {
      return COLORS[this.status];
    },
  },

  components: { Tag },
};
</script>
