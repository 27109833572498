<template lang="pug">
  div(:class="b()")
    view-header(:bank-logo="bankLogo")
    loading-wrapper(v-if="$apollo.queries.proposal.loading || !proposal")
    proposal-card(
      v-else
      :proposal="proposal"
      :dates="dates"
      :simple-fee="schedule ? schedule.simpleFee : proposal.simpleFee"
      :double-fee="schedule ? schedule.doubleFee : proposal.doubleFee"
      :tea="schedule ? schedule.tea : proposal.tea"
      :tcea="schedule ? schedule.tcea : proposal.tcea"
      :schedule="schedule ? schedule.schedule : []"
    )
    dates-form(
      :default-values="dates"
      :fetching="$apollo.queries.schedule.loading"
      @submit="onSubmitDatesForm"
    )
    loading-wrapper(
      v-if="!dates || !proposal || $apollo.queries.schedule.loading"
    )
    template(v-else)
      div(:class="b('print-actions')")
        v-btn(color="primary" text @click="onClickPrint")
          span Imprimir&nbsp;
          v-icon mdi-printer
      schedule-card(
        :schedule="schedule && schedule.schedule || []"
        :currency="proposal.currency"
      )
      disclaimer.mt-3
</template>

<script>
  import moment from 'moment';
  import capitalize from 'lodash/capitalize';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import urlCryptedParamsMixin from '@/mixins/url-crypted-params-mixin';

  import { BorrowerScheduleQuery, ProposalQuery } from './graphql/queries.gql';
  import isRTCProposal from '../utils/is-rtc-proposal';
  import pickFees from '../utils/pick-fees';
  import ProposalCard from './components/proposal-card';
  import ViewHeader from '../components/view-header';
  import ScheduleCard from './components/schedule-card';
  import Disclaimer from './components/disclaimer';
  import DatesForm from './components/dates-form';

  export default {
    name: 'mortgage-schedule-borrower-view',

    mixins: [
      urlCryptedParamsMixin({
        parseKeys: ['proposalId'],
        requiredKeys: ['proposalId'],
        brokenParamsMessage: 'El cronograma al que intenta accerder no existe',
      }),
    ],

    apollo: {
      schedule: {
        query: BorrowerScheduleQuery,

        variables() {
          return {
            proposalId: this.proposalId,
            initDay: this.dates.initDay,
            payDay: this.dates.payDay,
          };
        },

        update({ schedule }) {
          if(!schedule) return null;

          return {
            ...schedule,
            ...pickFees(schedule.schedule),
          };
        },

        skip() {
          return !this.dates || !this.proposalId;
        },
      },

      proposal: {
        query: ProposalQuery,

        variables() {
          return {
            id: this.proposalId,
          };
        },

        result({ data: { proposal }}) {
          if(!proposal) return;

          const bank = proposal.bank;

          document.title = 'Cronograma RebajaTusCuentas' + (!isRTCProposal(proposal) ? ` + ${capitalize(bank.name)}` : '');
        },

        skip() {
          return !this.proposalId;
        },
      },
    },

    data() {
      return {
        dates: {
          initDay: moment().format('YYYY-MM-DD'),
          payDay: moment().add(1, 'month').format('YYYY-MM-DD'),
        },
        proposalId: null,
        schedule: null,
        proposal: null,
      };
    },

    components: {
      ViewHeader,
      ProposalCard,
      ScheduleCard,
      Disclaimer,
      DatesForm,
      LoadingWrapper,
    },

    computed: {
      bankLogo() {
        if(!this.proposal) return;

        return isRTCProposal(this.proposal) ? null : this.$cloudfrontURL(`/logos/${this.proposal.bank.shortName.toLowerCase()}.svg`)
      },
    },

    methods: {
      onClickPrint() {
        window.print();
      },

      onSubmitDatesForm(dates) {
        this.dates = {...dates};
      },
    },
  };
</script>

<style lang="sass">
  .mortgage-schedule-borrower-view
    padding: 16px 32px
    max-width: 1200px
    margin: 0 auto
    font-size: 14px

    &__title
      margin: 8px 0
      text-align: center

    &__print-actions
      text-align: right
</style>

<style lang="sass">
  body, html, .application--wrap
    background-color: white !important

  .data-card
    margin-bottom: 16px

    &__title, &__body
      background-color: darken(white, 1%)

    &__title
      display: inline-block
      padding: 6px 24px
      font-size: 17px
      font-weight: 50
      border-radius: 6px 6px 0 0
      background-color: darken(white, 4%)

    &__body
      padding: 12px 24px
      border-radius: 0 6px 6px 6px

  @media print
    .dates-form, .mortgage-schedule-borrower-view__print-actions
      display: none

    body
      -webkit-print-color-adjust: exact
</style>