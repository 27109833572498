<template lang="pug">
  thead(:class="b()")
    tr
      th.font-weight-bold Cuota
      th(v-for="label in labels" :key="label") {{ label }}
</template>

<script>
export default {
  name: 'borrowers-payment-days-average-table-head',

  props: {
    labels: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-payment-days-average-table-head
</style>