<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="loading || error"
      :error="error"
      @retry="args => $emit('retry', args)"
    )
    template(v-else-if="buro && $projectConfig.isPECountry")
      pe-full-buro-results(v-if="'sicoms' in buro" :results="buro")
      pe-buro-results(v-else :results="buro")
    mx-buro-results(v-else-if="buro && $projectConfig.isMXCountry" :results="buro")
    div(v-else :class="b('button-container')")
      v-btn(color="primary" type="button" @click="$emit('init')") Obtener reporte de buro
</template>

<script>
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import PeBuroResults from './pe-buro-results';
import PeFullBuroResults from './pe-full-buro-results';
import MxBuroResults from './mx-buro-results';

import BuroResults from './buro-results';

export default {
  name: 'buro',

  props: {
    loading: { type: Boolean, default: false },
    buro: { type: Object },
    error: { type: Error },
  },

  components: {
    BuroResults,
    PeBuroResults,
    PeFullBuroResults,
    MxBuroResults,
    LoadingWrapper,
  },
};
</script>

<style lang="sass" scoped>
.buro
  &__results-container
    max-width: 850px
    margin: 0 auto

  &__button-container
    text-align: center
    padding: 28px 0
</style>

<style lang="sass"></style>
