<template lang="pug">
  div(:class="b()")
    v-expansion-panels
      deal(
        v-for="deal in deals"
        :key="deal.id"
        :deal="deal"
        :can-disapprove="canDisapprove"
        :requesting="requesting"
        @update="args => $emit('update', args)"
        @delete="({ proposal }) => onDelete(proposal)"
        @approve="args => $emit('approve', args)"
        @disapprove="args => $emit('disapprove', args)"
        @enable="args => $emit('enable', args)"
        @disable="args => $emit('disable', args)"
        @click-simulate="onClickSimulate"
        @click-generate-commercial-file="args => $emit('generate-commercial-file', args)"
      )
    filters-form-modal(
      v-model="filtersModal"
      :requesting="requestingSimulator"
      :proposal="simulatingProposal"
      :properties="properties"
      :incomes="incomes"
      :strategies="allowedGenerateStrategies"
      :max-months="maxMonths"
      @submit="onSubmitFilters"
    )
</template>

<script>
  import FiltersFormModal from '@/components/modals/proposals-filters-form-modal';
  import find from 'lodash/find';

  import Deal from './deals-item';

  export default {
    name: 'deals',

    props: {
      deals: { type: Array, default: () => ([]) },
      properties: { type: Array },
      incomes: { type: Array },
      requesting: { type: Boolean, default: false },
      requestingSimulator: { type: Boolean, default: false },
      maxMonths: { type: Number },
      canDisapprove: { type: Boolean, default: false },
    },

    data() {
      return {
        filtersModal: false,
        simulatingProposal: null,
        simulatingDealId: null,
      };
    },

    computed: {
      allowedGenerateStrategies() {
        return find(this.deals, { id: this.simulatingDealId })?.allowedGenerateStrategies || [];
      },
    },

    methods: {
      onClickSimulate({ proposal, dealId }) {
        this.simulatingProposal = proposal;
        this.simulatingDealId = dealId;
        this.filtersModal = true;
      },

      onSubmitFilters({ filters }) {
        this.$emit('simulate', { filters, dealId: this.simulatingDealId })
      },

      closeFiltersForm() {
        this.filtersModal = false;
        this.simulatingProposal = null;
        this.simulatingDealId = null;
      },
    },

    components: {
      Deal,
      FiltersFormModal,
    },
  };
</script>

<style lang="sass">
  .deals
    .v-expansion-panel
      box-shadow: none !important
      &__header
        padding: .5rem 0

      &__container
        border: 0 !important
</style>