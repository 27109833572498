import Config from '@/config';

import UserSelect from './user-select';

export default {
  name: 'analyst-select',

  block: 'user-select',

  extends: UserSelect,

  props: {
    additionalQueryVariables: {
      type: Object,
      default: () => ({ groupsIds: [Config.GROUPS_RISK_ID] }),
    },
  },
};
