<template lang="pug">
  v-list-group(
    v-if="item.items"
    :prepend-icon="item.icon"
    no-action
  )
    template(v-slot:activator)
      v-list-item-content
        v-list-item-title {{ item.title }}
    v-list.ml-4
      drawer-list-item(
        v-for="subitem in item.items"
        :key="subitem.title"
        :item="subitem"
      )
  // Basic item
  v-list-item(
    v-else
    :to="item.to"
    exact
  )
    v-list-item-icon(v-if="item.icon")
      v-icon {{ item.icon }}
    v-list-item-content
      v-list-item-title {{ item.title }}
</template>

<script>
export default {
  name: 'drawer-list-item',

  props: {
    item: { type: Object, required: true },
  },
};
</script>

<style lang="sass" scoped>

</style>