<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 md6).px-2
          validation-provider(name="nombre" :rules="rules.firstName" v-slot="{ errors }")
            v-text-field(
              v-model="data.firstName"
              :error-messages="errors"
              name="nombre"
              label="Nombre"
              autofocus
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="apellido paterno" :rules="rules.lastName" v-slot="{ errors }")
            v-text-field(
              v-model="data.lastName"
              :error-messages="errors"
              name="apellido paterno"
              label="Apellido paterno"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="tipo de documento" :rules="'required'" v-slot="{ errors }")
            vat-type-select(
              v-model="data.vatTypeId"
              :error-messages="errors"
              :default-items="vatTypeDefaultItems"
              :lazy="lazySelects"
              name="tipo de documento"
              label="Tipo de Documento"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="numero de identificacion" :rules="rules.vat" v-slot="{ errors }")
            v-text-field(
              v-model="data.vat"
              :error-messages="errors"
              name="numero de identificacion"
              label="Número de Identifiación"
              required
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="correo" :rules="rules.email" v-slot="{ errors }")
            v-text-field(
              v-model="data.email"
              :error-messages="errors"
              name="correo"
              label="Correo"
              prepend-icon="mdi-email"
              autocomplete="email"
            )
</template>

<script>
import Config from '@/config';
import VatTypeSelect from '@/components/inputs/vat-type-select';
import addressInputMixin from '@/mixins/address-input-mixin';

import basicFormMixin from './basic-form-mixin';

export default {
  name: 'property-owner-form',

  props: {
    lazySelects: { type: Boolean, default: true },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'customer',
      veeValidation: true,
      mapPropsToData({ customer }) {
        return {
          id: customer?.id || undefined,
          firstName: customer?.firstName || null,
          lastName: customer?.lastName || null,
          email: customer?.email || null,
          vat: customer?.vat || null,
          vatTypeId: customer?.vatType?.id || '1',
        };
      },

      parseSubmit() {
        return {
          customer: { ...this.data },
        };
      }
    }),

    addressInputMixin(),
  ],

  computed: {
    rules() {
      return Config.CUSTOMER_FORM_RULES({ data: this.data });
    },

    vatTypeDefaultItems() {
      return this.customer?.vatType ? [{ id: this.customer?.vatType?.id, description: this.customer?.vatType?.description }] : [];
    },
  },

  components: {
    VatTypeSelect,
  },
};
</script>

<style lang="sass" scoped>

</style>