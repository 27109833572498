<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Banco
            th Estado
            th Valor
        tbody
          tr(v-for="guarantee, idx in guarantees" :key="idx")
            td {{ guarantee.bank }}
            td {{ guarantee.status }}
            td {{ guarantee.value }}
</template>

<script>
  export default {
    name: 'guarantees',

    props: {
      guarantees: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>
  .guarantees
    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>