<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    .px-2.pb-3
      .font-weight-medium Detalles del deudor
    v-divider.mb-4
    debtor-form(
      :debtor="debtor"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      :disabled-fields="disabledFields"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import DebtorForm from '@/components/forms/debtor-form';

  export default {
    name: 'debtor-form-modal',

    mixins: [formModalMixin({ entityName: 'debtor' })],

    components: { DebtorForm },
  };
</script>
