<template lang="pug">
  div(:class="b()")
    metrics-layout
      filters(
        slot="filters"
        :filters="filters"
        @submit="onSubmitFilters"
      )
      div(:class="b()")
        loading-wrapper(v-if="$apollo.queries.metrics.loading || !metrics && false")
        template(v-else)
          v-card(:class="b('table-container')").pa-4.mb-4
            forecast-chart(:entries="metrics").mb-2

          v-card(:class="b('table-container')")
            forecast-table(:entries="metrics")
</template>

<script>
import Config from '@/config';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

import { LoansMetricsBorrowersFeeForecastQuery } from './graphql/queries.gql';
import ForecastTable from './components/forecast-table';
import ForecastChart from './components/forecast-chart';
import Filters from './components/filters';
import MetricsLayout from '../../metrics/components/metrics-layout';

const getDefaultFromDate = () => moment().format('YYYY-MM-DD');
const getDefaultToDate = () => moment().add(12, 'months').format('YYYY-MM-DD');

export default {
  name: 'loans-metrics-borrowers-fee-forecast-view',

  data() {
    return {
      metrics: null,
      filters: {
        country: Config.COUNTRY_CODE.toUpperCase(),
        fromDate: getDefaultFromDate(),
        toDate: getDefaultToDate(),
        borrowerIds: [],
        investorIds: [],
        condition: [],
        qualification: [],
        typeOfCancellation: [],
      },
    };
  },

  apollo: {
    metrics: {
      query: LoansMetricsBorrowersFeeForecastQuery,

      variables() {
        const fromDate = this.filters.fromDate || getDefaultFromDate();
        const toDate = this.filters.toDate || getDefaultToDate();

        const monthsDiff = moment(toDate).diff(fromDate, 'months');
        const monthsAhead = (monthsDiff < 1 || monthsDiff > 12) ? 12 : monthsDiff;

        return {
          country: this.filters.country,
          fromDate ,
          monthsAhead,
          borrowerIds: this.filters.borrowerIds?.length ? this.filters.borrowerIds : undefined,
          investorIds: this.filters.investorIds?.length ? this.filters.investorIds : undefined,
          condition: this.filters.condition?.length ? this.filter.condition : undefined,
          qualification: this.filters.qualification?.length ? this.filters.qualification : undefined,
          typeOfCancellation: this.filters.typeOfCancellation?.length ? this.filters.typeOfCancellation : undefined,
          borrowerQualification:this.filters.borrowerQualification?.length ? this.filters.borrowerQualification : undefined,
        };
      },
    },
  },

  methods: {
    onSubmitFilters({ filters }) {
        if(isEqual({ ...filters }, { ...this.filters })) {
          this.$apollo.queries.metrics.refetch();
        }
        else
          Object.assign(this.filters, filters);
    },
  },

  components: { MetricsLayout, ForecastTable, LoadingWrapper, Filters, ForecastChart },
};
</script>

<style lang="sass" scoped>
  .loans-metrics-borrowers-fee-forecast-view
    &__table-container
      overflow-x: auto
</style>