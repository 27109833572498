<template lang="pug">
  div(:class="b()")
    div(:class="b('name')" v-if="customer.firstName || customer.lastName") {{ customer.firstName }} {{ customer.lastName }}
    div(:class="b('data-row')")
      v-icon(:class="b('data-row-icon')") mdi-email
      div(:class="b('data-row-value')") {{ customer.email }}
    div(:class="b('data-row')")
      v-icon(:class="b('data-row-icon')") mdi-card-account-details
      div(:class="b('data-row-value')") {{ customer.vat }}
    div(:class="b('data-row')")
      v-icon(:class="b('data-row-icon')") mdi-cash-multiple
      div(:class="b('data-row-value')") Nº Ingresos: #[span.font-weight-medium {{ customer.incomes.length }}]
    div(:class="b('data-row')")
      v-icon(:class="b('data-row-icon')") mdi-home-outline
      div(:class="b('data-row-value')") Propiedad: #[span.font-weight-medium {{ customer.properties.length ? 'SI' : 'NO'}}]
</template>

<script>
export default {
  name: 'customer-info',

  props: {
    customer: { type: Object },
  },
};
</script>

<style lang="sass" scoped>
  .customer-info
    &__name
      margin-bottom: 8px
      font-size: 17px
      font-weight: bold
      color: darken(white, 60%)
      text-align: center

    &__data-row
      display: flex
      align-items: center
      margin-bottom: 6px

      &-icon
        margin-right: 8px
</style>