import { render, staticRenderFns } from "./consumer-loan-credits.vue?vue&type=template&id=44394ab8&scoped=true&lang=pug"
import script from "./consumer-loan-credits.vue?vue&type=script&lang=js"
export * from "./consumer-loan-credits.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44394ab8",
  null
  
)

export default component.exports