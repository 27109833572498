var render, staticRenderFns
import script from "./tax-report-yearly-third-chart.vue?vue&type=script&lang=js"
export * from "./tax-report-yearly-third-chart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c64456",
  null
  
)

export default component.exports