<template lang="pug">
  div(:class="b()").ma-2.py-2
    div(:class="b('title')") Resumen
    div(:class="b('content')" v-html="description")
</template>

<script>
  export default {
    name: 'risk-file-description',

    props: {
      description: {
        type: String,
      },
    }
  };
</script>

<style lang="sass" scoped>
  .risk-file-description
    &__title
      font-size: 16px
      font-weight: bold
      margin-bottom: 8px

    &__content
      line-height: 1.5
</style>