<template lang="pug">
  .dashboard-card(:class="b()")
    div(:class="b('content')")
      loading-wrapper(
        v-if="loading || error || !total"
        :error="error"
        @retry="args => $emit('retry', args)"
      )
      template(v-else)
        div(:class="b('menu-column')")
          .dashboard-card__header(:class="b('menu-column-header')")
            .dashboard-card__title Negociaciones
            .dashboard-card__count {{ total.dealsCount }}
          div(:class="b('menu-column-body')")
            products-menu(
              v-model="currentProductSlug"
              :products="total.products"
            )
          div(:class="b('menu-column-footer')") {{ total.creditAmount | formatCurrency(currency) }}
        div(:class="b('body-column')")
          product-summary(
            :class="b('product-summary')"
            v-if="currentProduct"
            :product="currentProduct"
            :currency="currency"
          )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import ProductsMenu from './products-menu';
  import ProductSummary from './product-summary';

  export default {
    name: 'deals-total',

    props: {
      loading: { type: Boolean },
      error: { type: Error },
      total: { type: Object },
    },

    data() {
      return {
        currentProductSlug: 'buy_mortgage',
      };
    },

    computed: {
      currentProduct() {
        return (this.total?.products || []).find(({ slug }) => slug === this.currentProductSlug);
      },

      currency() {
        return this.total?.currency;
      },
    },

    components: {
      LoadingWrapper,
      ProductsMenu,
      ProductSummary,
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deals-total
    &__content
      +lg
        display: flex

    &__menu-column
      background-color: #f5f5f5

      +lg
        width: 360px
        display: flex
        flex-direction: column

      &-body
        flex: 1
        padding: 4px 18px

      &-footer
        padding-right: 24px
        padding-bottom: 14px

        text-align: right
        font-weight: bold
        font-size: 24px

    &__body-column
      flex: 1
      padding: 24px

    &__product-summary
      height: 100%
</style>