<template lang="pug">
  form-modal(
    :value="value"
    :content-class="b()"
    @input="args => $emit('input', args)"
  )
    activity-form(
      v-if="value"
      :class="b('form')"
      :activity="activity"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import ActivityForm from '@/components/forms/activity-form';

  export default {
    name: 'activity-form-modal',

    mixins: [formModalMixin({ entityName: 'activity' })],

    components: { ActivityForm },
  };
</script>

<style lang="sass">
  .activity-form-modal
    overflow-y: visible !important
</style>
