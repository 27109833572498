<template lang="pug">
  v-timeline(dense)
    timeline-item(
      v-for="entry in timeline"
      :key="entry.id"
      :entry="entry"
    )
</template>

<script>
  import TimelineItem from './timeline-item';

  export default {
    name: 'timeline',

    props: {
      timeline: { type: Array, default: () => [] },
    },

    components: { TimelineItem },
  };
</script>

<style lang="sass" scoped>

</style>