<template lang="pug">
  v-card
    v-layout(wrap).pa-3
      div(:class="b('avatar-container')")
        user-avatar(
          :first-name="customer.firstName"
          :last-name="customer.lastName"
          :url="customer.avatar"
          :size="64"
        )
          img(:src="customer.avatar")
      div(:class="b('body')")
        div.mb-1.font-weight-bold.title
          span {{ customer.firstName }} {{ customer.lastName }}
          v-chip(color="#30ceb5" text-color="white" small).ml-2 {{ label }}
        v-layout(wrap)
          v-flex(xs12 lg6)
            b {{ customer.vatTypeName }}:&nbsp
            span {{ customer.vat }}
          v-flex(xs12 lg6)
            b Estado Civil:&nbsp
            span {{ customer.maritalStatus && $t(`maritalStatus.${customer.maritalStatus}`)}}
          v-flex(v-if="customer.profession" xs12 lg6)
            b Ocupación:&nbsp
            span {{ customer.profession }}
          v-flex(xs12 lg6)
            b Cantidad de dependientes:&nbsp
            span {{ customer.dependents || 0 }}
          v-flex(xs12 lg6)
            b Fecha Nacimiento:&nbsp
            span {{ customer.birth | formatDate }}
            span(v-if="age") &nbsp;({{ age }})
          v-flex(xs12 lg6)
            b Fecha Registro:&nbsp
            span {{ customer.createdAt | formatDate }}
          v-flex(v-if="customer.address" xs12).pt-1
            b Dirección:&nbsp;
            span {{ customer.address }}
          v-flex(xs12 lg6)
            b Asesor:&nbsp;
            span {{ customer.adviserName }}
          div(:class="b('mkt')")
            div(v-if="customer.source")
              b Source:&nbsp
              span {{ customer.source }}
            div(v-if="customer.medium")
              b Medium:&nbsp
              span {{ customer.medium }}
            div(v-if="customer.campaign")
              b Campaign:&nbsp
              span {{ customer.campaign }}
</template>

<script>
import moment from 'moment';
import UserAvatar from '@/components/UI/user-avatar';

export default {
  name: 'customer-card',

  props: {
    customer: { type: Object, required: true },
    label: { type: String },
  },

  computed: {
    age() {
      return this.customer?.birth ? moment().diff(this.customer.birth, 'years') + ' años' : null;
    },
  },

  components: { UserAvatar },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .customer-card
    &__avatar-container
      width: 100%
      padding-bottom: 1rem

      text-align: center

      +sm
        width: auto
        padding: 0
        padding-right: 2rem

    &__body
      width: 100%

      +sm
        width: auto
        flex: 1
      a
        word-break: break-all !important
        text-decoration: none

    &__actions
      background-color: darken(white, 2%)

    &__whatsapp-link
      display: flex
      align-items: center

      a
        text-decoration: none
        font-weight: 500

      .v-icon
        margin-right: .5rem
        color: $primaryColor

    +md
      &__whatsapp-link
        padding: 0 12px

      &__whatsapp-links
        display: flex

    &__mkt
      background-color: transparentize($primaryColor, 0.9)
      border-radius: 3px
      margin-top: 6px

      & > div
        padding: 2px 12px

        b
          margin-right: 6px
    +md
      &__mkt
        display: inline-flex
        align-items: center
</style>
