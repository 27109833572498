import { render, staticRenderFns } from "./ghost-simulator-proposals.vue?vue&type=template&id=13b01be2&scoped=true&lang=pug"
import script from "./ghost-simulator-proposals.vue?vue&type=script&lang=js"
export * from "./ghost-simulator-proposals.vue?vue&type=script&lang=js"
import style0 from "./ghost-simulator-proposals.vue?vue&type=style&index=0&id=13b01be2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b01be2",
  null
  
)

export default component.exports