<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Periodo
            th Días de atraso
            th Estado
        tbody
          table-row(v-for="entry, idx in behaviour" :key="idx" :entry="entry")
</template>

<script>
  import TableRow from './payments-behaviour-row';

  export default {
    name: 'payments-behaviour',

    props: {
      behaviour: { type: Array, default: () => [] },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .payments-behaviour
    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>