<template lang="pug">
  validation-observer(ref="form")
    div(:class="b()")
      div(
        v-for="item, idx in value"
        :class="b('group')"
        :key="item.id"
      )
        validation-provider(:name="`año ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
          year-select(
            :name="`año ${idx + 1}`"
            :error-messages="errors"
            :value="item.year"
            :disabled="disabled"
            :from="new Date().getFullYear() - 1"
            :until="new Date().getFullYear()"
            label="Año"
            @input="v => onInputField(idx, 'year', v)"
          ).px-2
        validation-provider(:name="`mes ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
          month-select(
            :value="item.month"
            :name="`mes ${idx + 1}`"
            :error-messages="errors"
            :item-disabled="({ value: month }) => isDisabledMonth({ month, year: item.year })"
            :disabled="disabled"
            label="Mes"
            @input="v => onInputField(idx, 'month', v)"
          ).px-2
        validation-provider(:name="`monto ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
          v-text-field(
            :value="item.amount"
            :name="`monto ${idx + 1}`"
            :error-messages="errors"
            :disabled="disabled"
            :prefix="currencySymbol"
            label="Monto"
            type="number"
            @input="v => onInputField(idx, 'amount', v ? Number(v) : null)"
          ).px-2
        div(:class="b('delete-button')")
          v-btn(
            v-if="value.length > 1"
            color="error"
            @click="deleteItem(idx)"
            text
          ) Eliminar
      v-alert(v-if="errors && errors.length" color="error" type="error") {{ errors[0] }}
      .d-flex.justify-center
        v-btn(
          v-if="value.length < maxLength"
          @click="addItem"
          color="primary"
          text
        )
          v-icon mdi-plus
          span Agregar Ingreso
</template>

<script>
import moment from 'moment';
import dynamicEntriesInputMixin from '@/mixins/dynamic-entries-input-mixin';

import MonthSelect from '../month-select';
import YearSelect from '../year-select';

const CURRENT_MOMENT = moment();

export default {
  name: 'incomes-by-month',

  mixins: [
    dynamicEntriesInputMixin({
      itemDefaultValues: { year: null, month: null, amount: null },
    }),
  ],

  props: {
    currencySymbol: { type: String },
  },

  methods: {
    isDisabledMonth({ year, month }) {
      const possibleMoment = moment(`${year}-${month}`, 'YYYY-M').endOf('month');

      /*
        Deshabilitamos la fecha si es una fecha futuro o ya fue seleccionada
      */
      return possibleMoment.isAfter(CURRENT_MOMENT) ||
        this.value
          .some(item => year === item.year && month === item.month);
    },
  },

  components: { MonthSelect, YearSelect },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .incomes-by-month
    +xs
      &__delete-button
        display: flex
        justify-content: flex-end

    +sm
      &__group
        display: flex
        align-items: center
</style>
