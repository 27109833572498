<template lang="pug">
  tbody(:class="b()")
    tr
      td.font-weight-bold Días
      td(v-for="entry in entries") {{ entry.avg }}
</template>

<script>
export default {
  name: 'borrowers-payment-days-average-table-body',

  props: {
    entries: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-payment-days-average-table-body
</style>