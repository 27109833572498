import debounce from 'lodash/debounce';
import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { AppraisersSelectQuery } from './queries.gql';

export default {
  name: 'appraisers-select-query',

  mixins: [lazySelectMixin({ itemsKey: 'appraisers' })],

  props: {
    value: { type: [Object, String] },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
    noFilter: { type: Boolean, default: true },
  },

  data() {
    return {
      appraisers: [],
      search: null,
    };
  },

  apollo: {
    appraisers: {
      query: AppraisersSelectQuery,

      update({ appraisers }) {
        return appraisers?.objects || [];
      },

      variables() {
        return {
          q: this.search ? this.search : null,
        };
      },

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  methods: {
    onChangeSearch: debounce(function (value) {
      this.search = value;
    }, 300),
  },

  render(h) {
    return h(
      VAutocomplete,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.appraisers.loading,
        },

        on: {
          ...this.$listeners,
          'update:search-input': this.onChangeSearch,
          focus: this.onFocus,
        },
      }
    );
  },
};
