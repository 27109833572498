<template lang="pug">
  component(
    :is="fieldType.component || 'div'"
    :value="value"
    v-bind="additionalProps"
    :error-messages="errorMessages"
    @input="args => $emit('input', args)"
    return-object
  )
</template>

<script>
  export default {
    name: 'field-control',

    props: {
      value: { type: [String, Number, Object, Array] },
      additionalProps: { type: Object, default: () => ({}) },
      fieldType: { type: Object, default: () => ({}) },
      errorMessages: { type: Array },
    },
  };
</script>

<style lang="sass" scoped>

</style>