<template lang="pug">
  v-layout(:class="b({ [deal.status.toLowerCase()]: true })" @click="onClickDeal")
    div.pr-1.hidden-xs-only
      v-chip(
        :color="getStatusColor(deal.status)"
        text-color="white"
      ) {{ $t(`dealStatus.${deal.status}`)}}
    v-flex.d-flex(style="flex-direction: column; justify-content: center")
      div.font-weight-medium {{ $t(`productSlug.${deal.product.slug}`)}}
      div(v-if="deal.lostReason && deal.lostReason.reason") {{ deal.lostReason.reason }}
      div(:class="b('data')").hidden-sm-and-up {{ deal.modifiedAt | formatDate }}
    div(:class="b('date')").px-2
      span.hidden-xs-only {{ deal.modifiedAt | formatDate }}
</template>

<script>
  const STATUS_COLORS = {
    WON: 'green',
    LOST: 'red',
    ACTIVE: 'blue',
  };

  export default {
    name: 'deals-history-row',

    props: {
      deal: {
        type: Object,
        required: true,
      },
    },

    methods: {
      getStatusColor(status) {
        return STATUS_COLORS[status];
      },

      onClickDeal() {
        this.$router.push({ name: 'deals.show', params: { dealId: this.deal.id }});
      },
    },
  };
</script>

<style lang="sass" scoped>
  .deals-history-row
    font-size: .9rem
    align-items: center

</style>

<style lang="sass">
  @import '~@/sass/mixins'

  .deals-history-row
    padding: .25rem 0
    cursor: pointer

    &:hover
      background-color: darken(white, 4%)

    .v-chip__content
      height: 22px !important
      font-size: .8rem

    +xs
      border-left: 4px solid black
      padding-left: 1rem
      margin-bottom: 3px

    &__date
      white-space: nowrap

    &--lost
      border-left-color: red

    &--won
      border-left-color: green
</style>
