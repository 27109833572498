import { render, staticRenderFns } from "./deal-summary.vue?vue&type=template&id=ce798e30&scoped=true&lang=pug"
import script from "./deal-summary.vue?vue&type=script&lang=js"
export * from "./deal-summary.vue?vue&type=script&lang=js"
import style0 from "./deal-summary.vue?vue&type=style&index=0&id=ce798e30&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce798e30",
  null
  
)

export default component.exports