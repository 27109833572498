<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="actividad" :rules="'required'" v-slot="{ errors }")
            activity-select(
              v-model="data.activityId"
              :error-messages="errors"
              name="actividad"
              label="Actividad"
            )
        v-flex(xs12 sm6 md4).px-2
          validation-provider(name="estado" :rules="'required'" v-slot="{ errors }")
            activity-status-select(
              v-model="data.statusId"
              :activity-id="data.activityId"
              :error-messages="errors"
              name="estado"
              label="Estado"
            )
        v-flex(xs12).px-2.pt-2
          validation-provider(name="mensaje" :rules="'required|min:6'" v-slot="{ errors }")
            rich-textarea(
              v-model="data.message"
              :error-messages="errors"
              label="Mensaje"
              name="mensaje"
              placeholder="Describe la actividad..."
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          :disabled="messageIsEmpty"
          type="submit"
          color="primary"
          text
        ) Guardar
</template>

<script>
  import RichTextarea from '@/components/inputs/rich-textarea';
  import ActivitySelect from '@/components/inputs/activity-select';
  import ActivityStatusSelect from '@/components/inputs/activity-status-select';

  import basicFormMixin from './basic-form-mixin';

  export default {
    name: 'activity-form',

    mixins: [
      basicFormMixin({
        modelKey: 'activity',
        veeValidation: true,
        mapPropsToData({ activity }) {
          return {
            activityId: activity?.activityId,
            statusId: activity?.statusId,
            message: activity?.message,
          };
        },
      }),
    ],

    components: {
      ActivitySelect,
      ActivityStatusSelect,
      RichTextarea,
    },

    computed: {
      messageIsEmpty() {
        return !this.data.message || this.data.message === '<p></p>';
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>