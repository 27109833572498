<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    .px-2.pb-3
      .font-weight-medium Detalles del préstamo
    v-divider.mb-4
    investment-proposal-form(
      :investment-proposal="investmentProposal"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import InvestmentProposalForm from '@/components/forms/investment-proposal-form';

  export default {
    name: 'investment-proposal-form-modal',

    props: {
      potentialOwners: { type: Array, default: () => [] },
      hiddenFields: { type: Array}
    },

    mixins: [formModalMixin({ entityName: 'investmentProposal' })],

    components: { InvestmentProposalForm },
  };
</script>
