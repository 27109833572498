<template lang="pug">
  div(:class="b()")
    div(:class="b('filters-card')" ref="toolbar")
      slot(name="filters")
    v-container(fluid :class="b('body')")
      slot
    v-btn(:class="b('fab')" fab color="primary" @click="onClickFab")
      v-icon mdi-filter-outline
</template>

<script>
  import $ from 'jquery';

  export default {
    name: 'metrics-layout',

    methods: {
      onClickFab() {
        $(this.$refs.toolbar).slideToggle();
      },
    },

    mounted() {
      if(window.innerWidth < 600)
        $(this.$refs.toolbar).hide();
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .metrics-layout
    &__filters-card
      padding: 8px 24px
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      background-color: white

      +md
        display: block !important

    &__body
      padding-top: 32px

      +md
        padding-top: 64px

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +md
        display: none
</style>
