<template lang="pug">
  div(:class="b({ selected })" @click="onClick")
    div(:class="b('header')")
      div(:class="b('header-status')" :style="{ backgroundColor: statusColor }") {{ $t(`investmentProposalStatus.${investmentProposal.status}`) }}
      div(:class="b('header-credit')" v-if="isFinished").is-hidden-mobile-and-tablet {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}
    div(:class="b('body')")
      .notification.is-success.is-light(v-if="isFinished") Este préstamo ya ha sido finalizado 🎉
      template(v-else)
        div(:class="b('data')")
          div(:class="b('data-title')") Próxima Cuota
          div(:class="b('data-amount', { 'next-quote': true })") {{ nextQuote | formatCurrency(investmentProposal.currency) }}

        div(:class="b('data')")
          div(:class="b('data-title')") Vencimiento
          div(:class="b('data-amount')") {{ investmentProposal.nextExpirationDate | formatDate('D MMMM YYYY') }}

        div(:class="b('data')").is-hidden-desktop
          div(:class="b('data-title')") Cuotas
          div(:class="b('data-amount')") #[b {{ currentQuoteCount }}]/{{ investmentProposal.quotesQuantity}}

      //- div(:class="b('data')").is-hidden-desktop
      //-   div(:class="b('data-title')") Tasa
      //-   div(:class="b('data-amount')") BIND

      //- div(:class="b('data')").is-hidden-desktop
      //-   div(:class="b('data-title')") Crédito
      //-   div(:class="b('data-amount', { credit: true })") {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}

    div(:class="b('footer')")
      div(:class="b('footer-data-container')").is-hidden-mobile-and-tablet
        //- div(:class="b('footer-data')")
        //-   span(:class="b('footer-data-title')") Tasa:
        //-   span(:class="b('footer-data-amount')") BIND
        div(v-if="!isFinished" :class="b('footer-data')")
          span(:class="b('footer-data-title')") Cuotas:
          span(:class="b('footer-data-amount')") #[b {{ currentQuoteCount }}]/{{ investmentProposal.quotesQuantity}}

</template>

<script>
import { INVESTMENT_PROPOSAL_STATUS, QUOTE_STATUS } from '@/utils/constants';
import statusColor from '@/utils/investment-proposal-status-color';

export default {
  name: 'investment-proposals-list-item',

  props: {
    investmentProposal: { type: Object },
    selected: { type: Boolean, default: false },
  },

  computed: {
    quote() {
      return this.investmentProposal.quotes[0];
    },

    isPaid() {
      return [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED].includes(this.quote.status);
    },

    isRescheduled() {
      return this.quote.status === QUOTE_STATUS.RESCHEDULED;
    },

    quoteData() {
      return this.isRescheduled ? this.quote.originalFee : this.quote.currentData;
    },

    statusColor() {
      return statusColor(this.investmentProposal.status);
    },

    nextQuote() {
      if(!this.quote || [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED, QUOTE_STATUS.RESCHEDULED].includes(this.quote.status))
        return null;

      return this.quoteData.amountDue;
    },

    currentQuoteCount() {
      return (this.investmentProposal.quotesQuantity - this.investmentProposal.pendingQuotesQuantity) + 1;
    },

    isFinished() {
      return [INVESTMENT_PROPOSAL_STATUS.FINISHED, INVESTMENT_PROPOSAL_STATUS.REFINANCED].includes(this.investmentProposal.status);
    },
  },

  methods: {
    onClick() {
      this.$emit('click', { investmentProposal: this.investmentProposal });
    },
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposals-list-item
    border-radius: 6px
    overflow: hidden
    box-shadow: 2px 2px 3px 2px #cfcfcf
    transition: box-shadow .3s linear
    background-color: white
    cursor: pointer

    &--selected
      border-bottom: 6px solid
      box-shadow: 2px 2px 12px 5px #cfcfcf

    &__header
      &-status
        padding: 4px 8px

        font-size: 13px
        font-weight: bold
        text-align: center
        text-transform: uppercase

        color: white

    &__body
      padding: 18px 12px

    &__data
      display: flex
      align-items: center
      padding: 3px 0

      &-title
        width: 100px

        margin-right: 8px
        font-size: 13px

      &-amount
        font-size: 14px
        font-weight: 500

        &--credit
          font-size: 16px

        &--next-quote
          font-size: 20px
          font-weight: bold
          color: darken(white, 70%)

    &__footer
      text-align: center


</style>