<template lang="pug">
  metrics-layout
    filters(
      slot="filters"
      :filters="filters"
      @submit="onSubmitFilters"
    )
    div(:class="b()")
      loading-wrapper(v-if="$apollo.queries.metrics.loading || !metrics")
      template(v-else)
        v-card
          legal-mortgage-value-chart(
            :entries="metrics"
            :parse-value="parseCurrencyValue"
            title="Garantías"
          ).mb-2
          div(:class="b('table-container')").mb-2
            borrowers-aums-table(
              :entries="metrics"
              :parse-value="parseCurrencyValue"
            )
</template>

<script>
import Config from '@/config';
import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { formatCurrency } from '@/filters/accounting';
import { LegalMortgageValueMetricsQuery } from './graphql/queries.gql';
import BorrowersAumsTable from '../components/borrowers-aums-table';

import MetricsLayout from '../../metrics/components/metrics-layout';
import LegalMortgageValueChart from './components/legal-mortgage-value-chart';
import Filters from './components/filters';

export default {
  name: 'loans-metrics-legal-mortgage-value-view',

  data() {
    return {
      metrics: null,
      currentTab: null,

      filters: {
        country: Config.COUNTRY_CODE.toUpperCase(),
        borrowerIds: [],
        investorIds: [],
      },
    };
  },

  apollo: {
    metrics: {
      query: LegalMortgageValueMetricsQuery,

      variables() {
        const { country, borrowerIds, investorIds } = this.filters;

        return {
          country,
          borrowerIds: borrowerIds?.length ? borrowerIds : undefined,
          investorIds: investorIds?.length ? investorIds : undefined,
        };
      },
    },
  },

  methods: {
    onSubmitFilters({ filters }) {
      if (isEqual({ ...filters }, { ...this.filters })) {
        this.$apollo.queries.metrics.refetch();
      }
      else
        Object.assign(this.filters, filters);
    },

    parseCurrencyValue(val) {
      return val ? formatCurrency(val, Config.CURRENCIES_SELECT[1]) : val;
    },
  },

  computed: {
    borrowersAums() {
      return this.metrics?.tables?.borrowersAums;
    },

    borrowersAumsValues() {
      return this.metrics?.tables?.borrowersAumsValues;
    },

    borrowersLtvAuction() {
      return this.metrics?.tables?.borrowersLtvAuction;
    },

    borrowersLtvCommercial() {
      return this.metrics?.tables?.borrowersLtvCommercial;
    },
  },

  components: {
    MetricsLayout,
    Filters,
    LoadingWrapper,
    BorrowersAumsTable,
    LegalMortgageValueChart,
  },
};
</script>

<style lang="sass" scoped>
  .loans-metrics-legal-mortgage-value-view
    &__table-container
      padding: 16px
      overflow-x: auto
</style>
