<template lang="pug">
  v-alert(type="info" dense outlined)
    p Para enviar el expediente a riesgos se debe cumplir lo siguiente:

    ul
      li(v-for="validation, key in validations" :key="key")
        span.mr-2 {{ validation.message }}
        v-icon(v-if="validation.valid" color="success") mdi-check
        v-icon(v-else-if="validation.valid === false" color="error") mdi-close
</template>

<script>
export default {
  name: 'risk-file-validations-state',

  props: {
    validations: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>

</style>