<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th(style="width: 220px") Entidad
            th Estado
            th Vigente
            th Atrasado
            th Judicializado
            th Refinanciado
            th Total
        tbody
          template(v-for="entry, idx in results.summary")
            table-row(:entry="entry")
            products-row(:entry="entry")
            tr
              td(colspan="7" style="background-color: #ececec")
        tfoot
          tr
            th(colspan="6") Total
            td {{ totalAmount | formatCurrency }}
</template>

<script>
  import TableRow from './summary-table-row';
  import ProductsRow from './summary-table-products-row';

  export default {
    name: 'credits-amount',

    props: {
      results: { type: Object },
    },

    computed: {
      totalAmount() {
        return (this.results?.summary || []).reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0);
      },
    },

    components: { TableRow, ProductsRow },
  };
</script>

<style lang="sass" scoped>
  .credits-amount
    &__table-container
      max-width: 90%
      overflow-x: auto
      margin: 0 auto
</style>