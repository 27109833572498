<template lang="pug">
  div(:class="b()")
    div(:class="b('debt')" v-for="sunat in sunats" :key="sunat.id")
      div(:class="b('debt-title')") {{ sunat.recentCollectionDate | formatDate }}&nbsp;-&nbsp;
        span(v-if="sunat.totalAmount") {{ sunat.totalAmount | formatCurrency }} ({{ sunat.totalQuantity }})
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="debt in sunat.snDebts" :key="debt.id")
          div(v-if="debt.amount") #[b Monto: ] {{ debt.amount | formatCurrency }}
          div(v-if="debt.periodMonth && debt.periodYear") #[b Periodo: ] {{ debt.periodMonth }}/{{ debt.periodYear}}
          div(v-if="debt.dependency") #[b Dependencia: ] {{ debt.dependency }}
          div(v-if="debt.collectionDate") #[b Fecha de coleccion: ] {{ debt.collectionDate | formatDate }}
          div(v-if="debt.processDate") #[b Fecha de procesamiento: ] {{ debt.processDate | formatDate }}
</template>

<script>
  export default {
    name: 'sunat-negatives-tab',

    block: 'debts-tab',

    props: {
      sunats: { type: Array, default: () => [] },
    },
  };
</script>
