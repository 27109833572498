<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    create-risk-file-form(
      v-if="value"
      :risk-file="riskFile"
      :deal="deal"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from '@/components/modals/form-modal-mixin';

  import CreateRiskFileForm from './create-risk-file-form';

  export default {
    name: 'create-risk-file-form-modal',

    props: {
      deal: { type: Object, required: true },
    },

    mixins: [formModalMixin({ entityName: 'riskFile' })],

    components: { CreateRiskFileForm },
  };
</script>
