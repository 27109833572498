<template lang="pug">
  thead
    tr
      th Prestatario/Cuota
      th(v-for="label in labels" :key="label") {{ label }}
</template>

<script>
export default {
  name: 'borrowers-payment-days-diff-table-head',

  props: {
    labels: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  //.borrowers-payment-days-diff-table-head
</style>