import VSelect from 'vuetify/lib/components/VSelect';

const ACTIVITIES = [
  { text: 'Sin Actividad', value: 'NULL' },
  // { text: 'Alta', value: 'HIGH' },
  // { text: 'Baja', value: 'LOW' },
  // { text: 'Activa', value: 'ACTIVE' },
];

export default {
  name: 'deal-activity-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: { type: Array, default: () => ACTIVITIES },
  },
};
