<template lang="pug">
  v-dialog(
    v-model="dialog"
    persistent
    width="300px"
  )
    template(v-slot:activator="{ on }")
      v-text-field(
        :value="dateFormatted"
        :label="label"
        :prepend-icon="prependIcon"
        :error-messages="errorMessages"
        :disabled="disabled"
        :clearable="clearable"
        readonly
        v-on="on"
        @click:clear="onClickClear"
      )
    v-date-picker(
      :value="internalValue"
      :allowed-dates="allowedDates"
      :min="min"
      :max="max"
      :disabled="disabled"
      :range="range"
      @input="onChange"
      no-title scrollable
    )
      v-spacer
      v-btn(color="red" text @click="dialog = false") Cancelar
      v-btn(color="primary" text @click="onOk") OK
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'input-date-picker',

    props: {
      label: { type: String },
      value: { type: [String, Array] },
      prependIcon: { type: String },
      errorMessages: { type: Array, default: null },
      min: { type: String },
      max: { type: String },
      clearable: { type: Boolean, default: false },
      allowedDates: { type: Function },
      disabled: { type: Boolean },
      range: { type: Boolean },
    },

    data() {
      return {
        dialog: false,
        internalValue: this.value || null,
      };
    },

    computed: {
      dateFormatted() {
        if(this.range)
          return this.value.map(date => moment(date).format('D MMM YYYY')).join(' - ');
        else
          return this.value ? moment(this.value).format('D MMM YYYY') : this.value;
      },
    },

    methods: {
      onChange(v) {
        this.internalValue = v;
      },

      onOk() {
        this.dialog = false;
        this.$emit('input', this.internalValue);
      },

      onClickClear() {
        this.$emit('input', this.range ? [] : null);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>