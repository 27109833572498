<template lang="pug">
  div(:class="b()")
    historical-debts-chart(:historical-debts="historicalDebts")
    historical-debts-table(:historical-debts="historicalDebts")
</template>

<script>
  import HistoricalDebtsTable from './historical-debts-table';
  import HistoricalDebtsChart from './historical-debts-chart';

  export default {
    name: 'historical-debts',

    props: {
      historicalDebts: { type: Array, default: () => [] },
    },

    components: { HistoricalDebtsTable, HistoricalDebtsChart },
  };
</script>

<style lang="sass" scoped>

</style>