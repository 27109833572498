<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs12 sm12 md6 lg7 xl8)
        filters-list(
          :filters="parsedCurrentFilters"
          @remove="args => $emit('remove', args)"
        )
      v-flex(xs12 sm12 md6 lg5 xl4)
        field-form(
          ref="fieldForm"
          :field-types="availableFieldTypes"
          :current-filters="currentFilters"
          @submit="onSubmitFieldForm"
        )
</template>

<script>
  import FieldForm from './field-form';
  import FiltersList from './filters-list';

  export default {
    name: 'filters',

    props: {
      currentFilters: { type: Array },
      hideFilters: { type: Array, default: () => [] },
      fieldTypes: { type: Array, default: () => []},
    },

    methods: {
      onSubmitFieldForm(filter) {
        this.$emit('add', filter);

        this.$refs.fieldForm.clear();
      },
    },

    computed: {
      // Removemos los filtros que ya han sido seleccionados y los que estan hidden
      availableFieldTypes() {
        return this.fieldTypes
          .filter(({ key }) => !this.currentFiltersKeys.includes(key) && !this.hideFilters.includes(key));
      },

      parsedCurrentFilters() {
        const namesByKey = this.fieldTypes.reduce(
          (obj, fieldType) => ({ ...obj, [fieldType.key]: fieldType.name }),
          {}
        );

        return this.currentFilters.map(filter => ({
          ...filter,
          name: namesByKey[filter.key]
        }));
      },

      currentFiltersKeys() {
        return this.currentFilters.map(({ key }) => key);
      },
    },

    components: {
      FieldForm,
      FiltersList,
    },
  };
</script>

<style lang="sass" scoped>
  .filters
    padding: 1rem
</style>