<template lang="pug">
  router-link(:class="b({ [deal.activityStatus]: true })" :to="{ name: 'deals.show', params: { dealId: this.deal.id }}")
    div {{ customerName }}
    div
      span(v-if="deal.credit") {{ deal.credit | formatCurrency(deal.currency) }}
    div.text-center
      tag(v-if="deal.product.slug === 'buy_mortgage' && !mortgageInscribed" color="#9c8ade").mx-1 no inscrita
      status-tag(:status="deal.status").mx-1
    div {{ deal.currentStage.name }}
    div {{ $t(`productSlug.${deal.product.slug}`) }}
    div.text-center
      template(v-if="adviser")
        user-avatar(
          :first-name="adviser.firstName"
          :last-name="adviser.lastName"
          :url="adviser.avatar"
          :size="24"
        )
    //- div
    //-   requirements(:requirements="deal.requirements" circle)
    div {{ deal.lastActivityAt | formatDate('D MMMM YYYY hh:mm') }}
    div {{ deal.createdAt | formatDate }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';
  import Tag from '@/components/UI/tag';
  import StatusTag from '@/components/UI/deal-status-tag';

  import Requirements from '../requirements';

  export default {
    name: 'table-row',

    props: {
      deal: { type: Object, required: true },
    },

    computed: {
      customerName() {
        const { customer } = this.deal;

        return `${customer?.firstName || ''} ${customer?.lastName || ''}`;
      },

      mortgageInscribed() {
        return this.deal?.customer?.mortgageInscribed;
      },

      adviser() {
        return this.deal?.customer?.adviser;
      },
    },

    methods: {
      onClick() {
        this.$router.push({});
      },
    },

    components: { UserAvatar, Requirements, StatusTag, Tag },
  };
</script>

<style lang="sass" scoped>
  .table-row
    display: table-row
    text-decoration: none
    color: inherit

    &--HIGH
      background-color: #ff000014

    &--LOW
      background-color: #ffff0017

    & > div
      display: table-cell

      &:first-child, &:nth-child(3)
        font-weight: 500

    .status-chip
      font-size: .9rem
</style>