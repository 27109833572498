<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs12 md4 lg3 v-for="category in categories" :key="category.id").pa-2
        div(:class="b('category')" @click="onClickCategory(category)")
          div(:class="b('category-name')") {{ category.name }}
</template>

<script>
export default {
  name: 'categories',

  props: {
    categories: { type: Array, default: () => [] },
  },

  methods: {
    onClickCategory(category) {
      this.$router.push({
        name: 'library.category',
        params: { categoryId: category.id },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.categories
  display: flex
  flex-wrap: wrap

  &__category
    background-color: $primaryColor
    color: white
    border-radius: 8px
    height: 94px
    font-size: 18px
    font-weight: 500
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: box-shadow .2s

    &:hover
      background-color: lighten($primaryColor, 6%)
      box-shadow: 3px 4px 8px #ababab
</style>
