<template lang="pug">
  div(:class="b()")
    div(v-if="label" :class="b('label')") {{ label }}
    div(:class="b('control')").pb-2
      year-select(
        v-model="data.year"
        :from="fromYear"
        :until="untilYear"
        :item-disabled="isYearDisabled"
        hide-details
        @input="onInput"
      ).pt-0.mt-0
      div(:class="b('divider')").mx-2 /
      month-select(
        v-model="data.month"
        :disabled="!data.year"
        :item-disabled="isMonthDisabled"
        hide-details
        @input="onInput"
      ).pt-0.mt-0
      div(v-if="hasData" :class="b('clear-icon')" @click="clear")
        v-icon mdi-close
</template>

<script>
import moment from 'moment';
import YearSelect from './year-select';
import MonthSelect from './month-select';

const GET_DEFAULT_VALUE = () => ({ month: null, year: null });

export default {
  name: 'month-and-year-select',

  props: {
    value: { type: Object, default: GET_DEFAULT_VALUE },
    label: { type: String },
    fromYear: { type: Number },
    untilYear: { type: Number },
    min: { type: String },
  },

  data() {
    return {
      data: this.value ? this.value : GET_DEFAULT_VALUE(),
    };
  },

  computed: {
    hasData() {
      return this.data.month || this.data.year;
    },
  },

  methods: {
    clear() {
      this.data = GET_DEFAULT_VALUE();

      this.$emit('input', null);
    },

    onInput() {
      if(this.data.month && this.data.year) this.$emit('input', { ...this.data });
    },

    isYearDisabled({ value: year }) {
      return this.min ? moment(this.min).year() > year : false;
    },

    isMonthDisabled({ value: month }) {
      return this.min && this.data.year
        ? moment(this.min).endOf('month').isAfter(moment(`${this.data.year}-${month}-01`, 'YYYY-M-DD'))
        : false;
    },
  },

  watch: {
    value(val) {
      this.data = val ? val : GET_DEFAULT_VALUE();
    },
  },

  components: { YearSelect, MonthSelect },
};
</script>

<style lang="sass" scoped>
  .month-and-year-select
    &__label
      font-size: 12px
      color: darken(white, 70%)

    &__control
      display: flex
      align-items: center

    &__clear-icon
      padding: 8px
      cursor: pointer
</style>