import { render, staticRenderFns } from "./general-table-head.vue?vue&type=template&id=59df0f6a&scoped=true&lang=pug"
import script from "./general-table-head.vue?vue&type=script&lang=js"
export * from "./general-table-head.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59df0f6a",
  null
  
)

export default component.exports