import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { BanksSelectQuery } from './queries.gql';
import { RTC_BANK_ID } from '@/utils/constants';


export default {
  name: 'bank-select',

  mixins: [lazySelectMixin({ itemsKey: 'banks' })],

  props: {
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
    itemState: { type: Boolean, default: false },
  },

  data() {
    return {
      banks:[],
      selectedOpcion:this.itemState ? (process.env.VUE_APP_RTC_BANK_ID || RTC_BANK_ID) : '',
    };
  },

  apollo: {
    banks: {
      query: BanksSelectQuery,

      skip() {
        return this.lazySkipQuery;
      },
    },
  },  

  atch: {
    banks(newBanks) {
      if (this.itemState) {
        const bankExists = newBanks.some( bank => bank.id === process.env.VUE_APP_RTC_BANK_ID || bank.id === RTC_BANK_ID );
        if (bankExists) {
          this.selectedOpcion = process.env.VUE_APP_RTC_BANK_ID || RTC_BANK_ID; // Asigna el ID si existe
        }
      } else {
        this.selectedOpcion = ''; // Si itemState es false, no selecciona nada
      }
    }
  },

  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.banks.loading,
          value: this.selectedOpcion, 
          itemValue: this.itemValue, 
          itemText: this.itemText, 
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
          input: (selected) => {
            this.$emit('input', selected);
          },
        }
      }
    );
  },

};