<template lang="pug">
  .v-card(:class="b()")
    div(:class="b('column')")
      div(:class="b('title')")
        span.text--secondary Crédito propuesto:&nbsp;
        b {{ proposal.creditWithOpeningCommission | formatCurrency(proposal.currency, { precision: 0}) }}
      div(v-if="riskFile.motive" :class="b('data-row')").
        #[b Destino: ] {{ motive }}
      div(:class="b('data-row')").
        #[b Monto Solicitado: ] {{ proposal.amount | formatCurrency(proposal.currency) }}
      div(:class="b('data-row')").
        #[b Plazo: ] {{ proposal.months }} meses
      div(:class="b('data-row')").
        #[b Cuota simple: ] {{ proposal.simpleFee | formatCurrency(proposal.currency) }}
      div(:class="b('data-row')" v-if="proposal.doubleFee").
        #[b Cuota doble: ] {{ proposal.doubleFee | formatCurrency(proposal.currency) }}
      div(v-if="proposal.lastFee" :class="b('data-row')").
        #[b Última cuota: ] {{ proposal.lastFee | formatCurrency(proposal.currency) }}
      div(:class="b('data-row')" v-if="proposal.tea").
        #[b {{ $t('tea') }}: ] {{ proposal.tea }} %
      div(:class="b('data-row')" v-if="proposal.tcea").
        #[b {{ $t('tcea') }}: ] {{ proposal.tcea }} %
      div(:class="b('data-row')").
        #[b Tipo de amortizacion: ] Fija ({{ proposal.balloonPaymentType | balloonPaymentType }})
      div(:class="b('data-row')").
        #[b Seguro: ] {{ proposal.insuranceTypeModeName }}
      //-- Ingreso de rtc score risk file
      div(:class="b('data-row')").
        #[b RTC Score: ] {{ proposal?.rtcScoreFromProposal || '-' }}
      //-- Ingreso de gastos administrativos risk file
      div(:class="b('data-row')").
        #[b Gastos administrativos: ] {{ proposal?.managementFee | formatCurrency(proposal.currency) }}
      div(:class="b('data-row')").
        #[b Tipo de garantía: ] {{ proposal?.guaranteeType?.name || '-' }}
    div(:class="b('column')")
      div(:class="b('title')").text--secondary Garantía
      div(:class="b('data-row')").
        #[b Direccion: ] {{ property.address }}
      div(:class="b('data-row')")
        b Propietario:&nbsp;
        template(v-if="property.realOwner")
          span {{ property.realOwner.firstName }} {{ property.realOwner.lastName }}&nbsp;
          span(v-if="property.realOwnerRelationship").font-weight-medium ({{ property.realOwnerRelationship }})
          span(v-if="age").font-weight-medium ({{ age }})
        span(v-else) Titular
      div(:class="b('data-row')").
        #[b Tipo de vivienda: ] {{ property.type }}
      div(v-if="antiquity" :class="b('data-row')").
        #[b Antiguedad: ] {{ antiquity }}
      div(v-if="property.registrationNumber" :class="b('data-row')").
        #[b Número de partida: ] {{ property.registrationNumber }}
      div(:class="b('data-row')").d-flex
        div(style="flex: 1").
          #[b Area total: ] {{ property.totalArea }} m²
        div(v-if="squareMeterValue").
          #[b Valor (m²): ] {{ squareMeterValue | formatCurrency(property.currency, { precision: 0}) }}
      div(:class="b('data-row')").d-flex
        div(style="flex: 1")
          b Valor comercíal:&nbsp;
          span.text-no-wrap {{ property.commercialValue | formatCurrency(property.currency, { precision: 0 }) }}
        div.text-no-wrap
          b LTV:&nbsp;
          span {{ riskFile.loanToValueCommercial }} %
      div(:class="b('data-row')").d-flex
        div(style="flex: 1")
          b Valor realización:&nbsp;
          span.text-no-wrap {{ property.auctionValue | formatCurrency(property.currency, { precision: 0 }) }}
        div.text-no-wrap
          b LTV:&nbsp;
          span {{ riskFile.loanToValueAuction }} %
    div(v-if="mortgage" :class="b('column')" )
      div(:class="b('title')").text--secondary Hipoteca Actual
      div(:class="b('data-row')").
        #[b Banco: ] {{ mortgage.bankName }}
      div(:class="b('data-row')").
        #[b Crédito: ] {{ mortgage.credit | formatCurrency({ symbol: mortgage.currencySymbol }) }}
      div(:class="b('data-row')").
        #[b Cuota simple: ] {{ mortgage.simpleFee | formatCurrency({ symbol: mortgage.currencySymbol }) }}
      div(v-if="mortgage.doubleFee" :class="b('data-row')").
        #[b Cuota simple: ] {{ mortgage.doubleFee | formatCurrency({ symbol: mortgage.currencySymbol }) }}
      div(:class="b('data-row')").
        #[b TEA: ] {{ mortgage.tea }} %
        #[b.ml-3 TCEA: ] {{ mortgage.tcea }} %
      div(:class="b('data-row')").
        #[b Plazo: ] {{ mortgage.limitMonths }} meses
      div(:class="b('data-row')").
        #[b Tipo de tasa: ] {{ mortgage.rateType }}
      div(:class="b('data-row')").
        #[b Seguro: ] {{ mortgage.insuranceTypeMOdeName }}
    div(:class="[b('column'), b('score-column')]")
      div(:class="b('title')").text--secondary Dictamen
      div(:class="b('score-container')" :style="{ color: statusColor }")
        //- div(:class="b('score')") 700
        div(:class="b('status')") {{ $t(`riskOpinionStatus.${riskFile.status}`) }}
      v-btn(color="primary" @click="$emit('click-opinion')") Dictaminar
</template>

<script>
import moment from 'moment';
import getStatusColor from '@/utils/get-risk-opinion-status-color';
import getProductMotive from '@/utils/get-product-motive';
import balloonPaymentType from '@/filters/balloon-payment-type';
import { HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

export default {
  name: 'risk-summary-card',

  props: {
    riskFile: { type: Object, required: true },
  },

  computed: {
    statusColor() {
      return getStatusColor(this.riskFile.status);
    },

    proposal() {
      return this.riskFile.customer.proposal;
    },

    mortgage() {
      return this.property?.mortgage;
    },

    property() {
      return this.riskFile.customer.property;
    },  

    guaranteeType() {
      return this.riskFile.customer.proposal.guaranteeType;
    },

    antiquity() {
      const yearOfConstruction = this.property?.yearOfConstruction;

      return yearOfConstruction ? moment().diff(moment(yearOfConstruction), 'years') + ' años' : null;
    },

    motive() {
      return getProductMotive(HOME_EQUITY_LOAN_SLUG, this.riskFile.motive);
    },

    squareMeterValue() {
      return this.property.auctionValue && this.property.totalArea ? this.property.auctionValue / this.property.totalArea : null
    },
    age() {
      return this.property.realOwner?.birth ? moment().diff(this.property.realOwner.birth, 'years') + ' años' : null;
    },
  },

  filters: { balloonPaymentType },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .risk-summary-card
    height: 100%
    line-height: 1.8

    &__column
      flex: 2
      padding: 12px 16px

      border-right: 1px solid $cloudyColor

      &:last-child
        border-right: 0

    &__title
      font-size: 16px
      font-weight: 500
      margin-bottom: 6px

    &__score-container
      flex: 1
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center

    &__score
      padding: 0 24px
      font-size: 62px
      line-height: 1
      font-weight: 500

    &__status
      font-weight: bold
      text-transform: uppercase
      font-size: 24px

    &__score-column
      flex: 1
      display: flex
      flex-direction: column

    +lg
      display: flex
</style>
