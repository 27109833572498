<template lang="pug">
  div(:class="b()")
    results-entry(
      :entries="results"
    )
</template>

<script>
  import ResultsEntry from './buro-results-entry';

  export default {
    name: 'buro-results-context',

    props: {
      results: { type: [Array, Object] },
    },

    components: { ResultsEntry },
  };
</script>

<style lang="sass">
  .buro-results-context
    .buro-results-entry__entries
      & > .buro-results-entry
        border-bottom: 1px solid darken(white, 30%)
        margin-bottom: 28px

        &:last-child
          border-bottom: 0
          margin-bottom: 0
</style>