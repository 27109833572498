<template lang="pug">
  table(:class="b()")
    bonds-table-head(
      :order-by="orderBy"
      :order-flow="orderFlow"
      :all-select="areAllItemsSelected"
      @select-all="onSelectAllItems"
      @unselect-all="onUnselectAllItems"
      @order="args => $emit('order', args)"
    )
    tbody
      bonds-table-item(
        v-for="bond in bonds"
        :key="bond.id"
        :bond="bond"
        :selected="selectedIds.includes(bond.id)"
        @select="onSelectItem"
        @unselect="onUnselectItem"
      )
    tfoot
      tr
        td(colspan="7").font-weight-bold Total soles <br /> Total dólares
        td {{ currentTotals.debtAmountSol | formatCurrency }} <br /> {{ currentTotals.debtAmountUSD, getUSDCurrency() | formatCurrency }}
        td {{ currentTotals.totalAmortizedSol | formatCurrency }} <br /> {{ currentTotals.totalAmortizedUSD, getUSDCurrency() | formatCurrency }}
        td {{ currentTotals.totalInterestSol | formatCurrency }} <br /> {{ currentTotals.totalInterestUSD, getUSDCurrency() | formatCurrency }}
        td
</template>

<script>
import $update from 'immutability-helper';
import selectableTableMixin from '@/mixins/selectable-table-mixin';
import config from '@/config/base';
import BondsTableItem from './bonds-table-item';
import BondsTableHead from './bonds-table-head';

export default {
  name: 'bonds-table',

  mixins: [selectableTableMixin({ itemsKey: 'bonds' })],

  props: {
    bonds: { type: Array, default: () => [] },
    totals: { type: Object, required: true },
    orderBy: { type: String },
    orderFlow: { type: String },
  },

  data() {
    return {
      selectedIds: [],
    };
  },

  computed: {
    currentTotals() {
      return this.bonds
      .filter(bond => {
        return this.selectedIds.length ? this.selectedIds.includes(bond.id) : true;
      })
      .reduce(
        (acc, bond) => {
          if (`${bond.currency.id}` === config.CURRENCIES_SELECT[0].id) {
            return $update(acc, {
              debtAmountSol: { $apply: val => val + bond.debtAmount },
              debtAmountUSD: { $apply: val => val },
              totalAmortizedSol: { $apply: val => val + bond.totalAmortized },
              totalAmortizedUSD: { $apply: val => val },
              totalInterestSol: { $apply: val => val + bond.totalInterest },
              totalInterestUSD: { $apply: val => val },
            });
          }
          return $update(acc, {
            debtAmountSol: { $apply: val => val },
            debtAmountUSD: { $apply: val => val + bond.debtAmount },
            totalAmortizedSol: { $apply: val => val },
            totalAmortizedUSD: { $apply: val => val + bond.totalAmortized },
            totalInterestSol: { $apply: val => val },
            totalInterestUSD: { $apply: val => val + bond.totalInterest },
          });
        },
        {
          debtAmountSol: 0,
          debtAmountUSD: 0,
          totalAmortizedSol: 0,
          totalAmortizedUSD: 0,
          totalInterestSol: 0,
          totalInterestUSD: 0,
        }
      );
    },

    currency() {
      return this.bonds?.[0].currency;
    },
  },

  methods: {
    getSolCurrency() { return config.CURRENCIES_SELECT[0] },
    getUSDCurrency() { return config.CURRENCIES_SELECT[1] },
  },

  components: { BondsTableItem, BondsTableHead },
};
</script>

<style lang="sass">
  .bonds-table
    width: 100%
    margin-bottom: 1rem

    border-collapse: collapse
    background-color: white
    text-align: center

    thead
      tr
        background-color: darken(white, 6%)
        th
          padding: 4px 8px

    tbody
      & > *
        border-bottom: 1px solid darken(white, 20%)

        cursor: pointer

    tfoot
      td
        background-color: darken(white, 1%)
        font-weight: 500
        padding: 8px 4px
</style>