<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    .px-2.pb-3
      .font-weight-medium Detalles de la formalización
    v-divider.mb-4
    formalization-form(
      :formalization="formalization"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      :disabled-fields="disabledFields"
      :investment-proposal-select-additional-search-params="investmentProposalSelectAdditionalSearchParams"
      :approved-appraisal="approvedAppraisal"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import FormalizationForm from '@/components/forms/formalization-form';

  export default {
    name: 'formalization-form-modal',

    props: {
      investmentProposalSelectAdditionalSearchParams: { type: Object },
      approvedAppraisal: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'formalization' })],

    components: { FormalizationForm },
  };
</script>
