<script>
  import Config from '@/config';
  import { Bar } from 'vue-chartjs';
  import { formatNumber } from '@/filters/accounting';

  export default {
    extends: Bar,

    props: {
      cards: { type: Array, default: () => [] },
      total: { type: Object, required: true },
    },

    mounted() {
      const labels = this.cards.map(({ bank }) => bank);

      this.renderChart({
        labels,
        datasets: [
          {
            label: 'Linea de crédito',
            backgroundColor: '#ceedf382',
            data: this.cards.map(({ bank, creditLine }) => ({ y: Number(creditLine), x: bank })),
          },
          {
            label: 'Linea utilizada',
            backgroundColor: Config.PRIMARY_COLOR,
            data: this.cards.map(({ bank, lineUtil }) => ({ y: Number(lineUtil), x: bank })),
          },
        ],
      }, {
        scales: {
          xAxes: [{
            stacked: true,
            ticks: {
              callback: (bank, index) => {
                const card = this.cards[index];
                const percent = ((Number(card.lineUtil) * 100) / Number(card.creditLine)).toFixed(2);

                return `${bank} (${percent}%)`;
              },
            }
          }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,

                callback(value) {
                  return 'S/ ' + formatNumber(value);
                },
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',

          callbacks: {
            label: function(tooltipItems, data) {
              const labelName = data.datasets[tooltipItems.datasetIndex].label;

              return `${labelName}: S/ ${formatNumber(tooltipItems.yLabel)}`
            }
          },
        },
      });
    },
  };
</script>

<style lang="sass" scoped>

</style>