import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { guaranteeTypesSelectQuery } from './queries.gql';

export default {
    name: 'guaranteeTypes-select',

    mixins: [lazySelectMixin({ itemsKey: 'guaranteeTypes' })],

    props: {
        value: {type: String , default: null },
        itemValue: { type: String, default: 'slug' },
        itemText: { type: String, default: 'name' },
        errorMessages: { type: Array, default: () => [] },
         // Etiqueta del select
        label: {
            type: String,
            default: 'Selecciona un tipo de garantía',
        },   
        // Nombre (si es necesario)
        name: {
            type: String,
            default: '',
        },  
    },

    data() {
        return {
            guaranteeTypes:[],
        };
    },

    computed: {
        errors() {
            return this.errorMessages;
        },

        setGuaranteeTypes() {
            const 
                selectedType = this.guaranteeTypes.find( items => items.isDefault === true ),
                value = this.value.toString()
            ;

            if ( value !== '' && value !== null && value !== undefined ) return value;
            return selectedType.slug.toString();
        },
    },
    
    apollo: {
        guaranteeTypes: {
            query: guaranteeTypesSelectQuery,
            result({ data }) {
                if (data && data.guaranteeTypes) {
                    this.guaranteeTypes = data.guaranteeTypes ;
                }
            },
        },     
    },

    render(h) {
        return h(VSelect, {
            props: {
                items: this.guaranteeTypes,
                label: this.label,                         // Etiqueta pasada por props
                itemText: this.itemText,                   // Campo para mostrar el texto
                itemValue: this.itemValue,                 // Campo para el valor
                value: this.setGuaranteeTypes,             // Asegúrate de que el valor sea el slug, Valor vinculado a v-model
                'error-messages': this.errorMessages,      // Mensajes de error
                name: this.name,                           // Si necesitas asignar un nombre
            },
            on: {
                input: (selected) => {
                    console
                    this.$emit('input', selected);
                }
            },
        });
    },
};