import { render, staticRenderFns } from "./title-studes-card.vue?vue&type=template&id=764e40d7&scoped=true&lang=pug"
import script from "./title-studes-card.vue?vue&type=script&lang=js"
export * from "./title-studes-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764e40d7",
  null
  
)

export default component.exports