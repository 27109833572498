<template lang="pug">
  v-autocomplete(
    :value="value"
    :items="lazyItems"
    :label="label"
    :error-messages="errorMessages"
    :return-object="returnObject"
    :loading="$apollo.queries.organizations.loading"
    :disabled="disabled"
    item-value="id"
    item-text="name"
    @input="args => $emit('input', args)"
    @focus="onFocus"
    clearable
  )
</template>

<script>
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import { OrganizationsQuery } from './queries.gql';

  export default {
    name: 'organization-select',

    mixins: [lazySelectMixin({ itemsKey: 'organizations' })],

    props: {
      label: { type: String },
      errorMessages: { type: Array },
      value: { type: String },
      returnObject: { type: Boolean },
      disabled: { type: Boolean, default: false },
    },

    data() {
      return {
        organizations: [],
      };
    },

    apollo: {
      organizations: {
        query: OrganizationsQuery,

        skip() {
          return this.lazySkipQuery;
        },
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>