<template lang="pug">
  thead
    tr(:class="b()")
      table-head-item(
        v-for="item in items"
        :key="item.title"
        :title="item.title"
        :order-key="item.orderKey"
        :order-flow="item.orderFlow"
        @order="args => $emit('order', args)"
      )
</template>

<script>
  import TableHeadItem from '@/components/UI/table-head-item';

  const ITEMS = [
    { title: 'Source', orderKey: 'source' },
    { title: 'Medium', orderKey: 'medium' },
    { title: 'Campaign', orderKey: 'campaign' },
    { title: '# Deals', orderKey: 'deals' },
    { title: '% of total deals', orderKey: 'pct_total_deals' },
    { title: '# Users GA', orderKey: 'users' },
    { title: 'Conversion user to deal', orderKey: 'cnv_user_to_deal' },
    { title: 'Investment', orderKey: 'investment' },
    { title: 'Cost per deal', orderKey: 'cost_per_deal' },
  ];

  export default {
    name: 'table-by-source-head',

    props: {
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      items() {
        return ITEMS.map(item => ({
          ...item,
          orderFlow: this.orderBy === item.orderKey ? this.orderFlow : null,
        }));
      },
    },

    components: { TableHeadItem },
  };
</script>

<style lang="sass" scoped>
  thead
    tr
      background-color: darken(white, 6%)
</style>