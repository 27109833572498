import { BUY_MORTGAGE_SLUG, NEW_MORTGAGE_SLUG } from '@/utils/constants';
import compact from 'lodash/compact';

export default () => ({
  computed: {
    rciValidations() {
      const rci = this.currentRci?.rci;

      if (rci === null || rci === undefined) return [{ message: 'Obtener el nivel de endeudamiento', valid: false }];

      // Don't validate new_mortage or buy_mortgage
      if ([NEW_MORTGAGE_SLUG, BUY_MORTGAGE_SLUG].includes(this.productSlug)) return [];

      const maxAllowed = Math.round(Number(this.currentRci?.values?.rciMaxAllowed) * 100);
      const ltvMaxAllowed = Math.round(Number(this.currentRci?.values?.ltvMaxAllowed) * 100);

      return compact([
        { message: `Tener un endeudamiento máximo de ${maxAllowed}%`, valid: rci < maxAllowed },
        { message: 'Tener una propuesta aceptada', valid: Boolean(this.acceptedProposal) },
        { message: `Tener un LTV máximo de ${ltvMaxAllowed}%`, valid: null },
        !this.customer?.properties?.length && { message: 'Debe tener un inmueble registrado', valid: false },
        { message: `Se ingreso el número de partida`, valid: this.customer?.properties?.[0]?.registrationNumber || false },
      ]);
    },
  },
});
