import {
  MORTGAGE_NUMBER_STATUS,
  APPRAISAL_STATUS,
  TITLE_STUDY_STATUS,
  DOCUMENT_STATUS,
  RATE_TYPE,
  FEE_TYPE,
  INVESTMENT_PROPOSAL_STATUS,
  DEAL_STATUS,
  CONTRACT_STATUS,
  FORMALIZATION_STATUS,
  AMORTIZATION_TYPE,
  QUOTE_STATUS,
  REMINDER_STATUS,
  DEMAND_STATUS,
  SPEED_SELLING_STATUS,
} from '@/utils/constants';

export default {
  vat: 'CC',
  tea: '%EA',
  tcea: 'TCEA',
  workPlaceVat: 'VAT',
  simpleFee: 'Cuota',
  administrationExpenses: 'Gastos administrativos inc. IVA',
  productSlug: {
    new_mortgage: 'Nuevo crédito hipotecario',
    buy_mortgage: 'Compra de cartera hipotecaria',
  },
  insuranceTypeId: {
    '1': 'Mancomunado',
    '2': 'Individual',
    '3': 'Endosado',
  },
  maritalStatus: {
    single: 'Soltero',
    married: 'Casado',
    widowed: 'Viudo',
    divorced: 'Divorciado',
  },
  generateStrategy: {
    'default': 'Situación actual de tu crédito',
    'custom': 'Personalizada',
    '0_percent_more': 'Manten tu cuota actual, optimiza el plazo',
    'max_month': 'Baja tu cuota ampliando el plazo',
    '5_percent_more': 'Aumenta tu cuota, optimiza el plazo',
    '10_percent_more': '10% more',
    '20_percent_more': '20% more',
  },
  documentOrigin: {
    COMMERCIAL: 'Comercial',
    LEGAL: 'Legal',
    OTHERS: 'Otros',
  },
  riskOpinionStatus: {
    PENDING: 'Pendiente',
    OBSERVED: 'Observado',
    APPROVED: 'Aprobado',
    REFUSED: 'Denegado',
  },
  formalizationStage: {
    PREPARATION: 'Preparación',
    BLOCKING: 'Bloqueo',
    DISBURSEMENT: 'Desembolso',
    MORTGAGE_REGISTRATION: 'Inscripción hipotecaria',
    LOCKING: 'Cierre documental',
  },
  payDetailStatus: {
    PENDING: 'Pendiente',
    PAID: 'Pagada',
    ENDORSED: 'Endosado',
  },
  mortgageNumberStatus: {
    [MORTGAGE_NUMBER_STATUS.PENDING]: 'Pendiente',
    [MORTGAGE_NUMBER_STATUS.IN_QUALIFICATION]: 'En Calificación',
    [MORTGAGE_NUMBER_STATUS.OBSERVED]: 'Observado',
    [MORTGAGE_NUMBER_STATUS.SIGNED_UP]: 'Inscrito',
    [MORTGAGE_NUMBER_STATUS.LIQUIDATED]: 'Liquidado',
  },
  appraisalStatus: {
    [APPRAISAL_STATUS.APPROVED]: 'Aprobado',
    [APPRAISAL_STATUS.EXCEPTION]: 'Excepción',
    [APPRAISAL_STATUS.DENIED]: 'Rechazado',
    [APPRAISAL_STATUS.IN_REVIEW]: 'En Revisión',
  },
  titleStudyStatus: {
    [APPRAISAL_STATUS.APPROVED]: 'Aprobado',
    [TITLE_STUDY_STATUS.EXCEPTION]: 'Excepción',
    [TITLE_STUDY_STATUS.DENIED]: 'Rechazado',
    [TITLE_STUDY_STATUS.IN_REVIEW]: 'En Revisión',
  },
  documentStatus: {
    [DOCUMENT_STATUS.PENDING]: 'Pendiente',
    [DOCUMENT_STATUS.UNDER_REVIEW]: 'Bajo Revisión',
    [DOCUMENT_STATUS.APPROVED]: 'Aprobado',
    [DOCUMENT_STATUS.REFUSED]: 'Rechazado',
  },
  rateType: {
    [RATE_TYPE.FIXED]: 'Fija',
    [RATE_TYPE.MIXED]: 'Mixta',
  },
  feeType: {
    [FEE_TYPE.SIMPLE]: 'Simple',
    [FEE_TYPE.DOUBLE]: 'Doble',
  },
  investmentProposalStatus: {
    [INVESTMENT_PROPOSAL_STATUS.PENDING]: 'Pendiente',
    [INVESTMENT_PROPOSAL_STATUS.ASSIGNED]: 'Asignada',
    [INVESTMENT_PROPOSAL_STATUS.COLLECTING]: 'Cobrando',
    [INVESTMENT_PROPOSAL_STATUS.FINISHED]: 'Finalizada',
    [INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED]: 'Judicializada',
    [INVESTMENT_PROPOSAL_STATUS.CANCELLED]: 'Cancelada',
    [INVESTMENT_PROPOSAL_STATUS.REFINANCED]: 'Refinanciado',
  },
  trustBondStatus: {
    [INVESTMENT_PROPOSAL_STATUS.PENDING]: 'Pendiente',
    [INVESTMENT_PROPOSAL_STATUS.ASSIGNED]: 'Asignada',
    [INVESTMENT_PROPOSAL_STATUS.COLLECTING]: 'Emitido',
    [INVESTMENT_PROPOSAL_STATUS.FINISHED]: 'Finalizada',
    [INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED]: 'Judicializada',
    [INVESTMENT_PROPOSAL_STATUS.CANCELLED]: 'Cancelada',
    [INVESTMENT_PROPOSAL_STATUS.REFINANCED]: 'Refinanciado',
  },
  dealStatus: {
    [DEAL_STATUS.WON]: 'Ganado',
    [DEAL_STATUS.LOST]: 'Perdido',
    [DEAL_STATUS.ON_HOLD]: 'En espera',
    [DEAL_STATUS.ACTIVE]: 'Activo',
  },
  contractStatus: {
    [CONTRACT_STATUS.PENDING]: 'Pendiente',
    [CONTRACT_STATUS.BLOCKED]: 'Bloqueado',
    [CONTRACT_STATUS.EMITTED]: 'Emitido',
    [CONTRACT_STATUS.OBSERVED]: 'Observado',
    [CONTRACT_STATUS.LOST]: 'Perdido'
  },
  formalizationStatus: {
    [FORMALIZATION_STATUS.ACTIVE]: 'Activa',
    [FORMALIZATION_STATUS.CLOSED]: 'Cerrada',
    [FORMALIZATION_STATUS.LOST]: 'Perdida',
  },
  amortization: {
    [AMORTIZATION_TYPE.FIXED]: 'Fija',
    [AMORTIZATION_TYPE.INCREASING]: 'Creciente',
  },
  quoteStatus: {
    [QUOTE_STATUS.PENDING]: 'Pendiente',
    [QUOTE_STATUS.EXPIRED]: 'Vencida',
    [QUOTE_STATUS.PAID]: 'Pagada',
    [QUOTE_STATUS.PAID_DELAYED]: 'Pago retrasado',
    [QUOTE_STATUS.RESCHEDULED]: 'Reprogramada',
    [QUOTE_STATUS.DISBURSEMENT]: 'Desembolsada',
  },
  reminderStatus: {
    [REMINDER_STATUS.PENDING]: 'Pendiente',
    [REMINDER_STATUS.DELAYED]: 'Retrasado',
    [REMINDER_STATUS.COMPLETED]: 'Completado',
  },
  demandStatus: {
    [DEMAND_STATUS.NONE]: 'Ninguna',
    [DEMAND_STATUS.SLOW]: 'Baja',
    [DEMAND_STATUS.MEDIUM]: 'Media',
    [DEMAND_STATUS.HIGH]: 'Alta',
  },
  speedSellingStatus: {
    [SPEED_SELLING_STATUS.LOW]: 'Baja',
    [SPEED_SELLING_STATUS.MEDIUM]: 'Media',
    [SPEED_SELLING_STATUS.HIGH]: 'Alta',
  },
};
