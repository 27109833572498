<template lang="pug">
  titled-card(
    title="Tarjetas"
    :count="cards.length"
    icon="mdi-credit-card-multiple"
  )
    template(v-for="card, i in cards")
      card(:card="card")
      v-divider(v-if="i + 1 < cards.length").my-2
</template>

<script>
  import card from './cards-row';
  import TitledCard from '@/components/UI/titled-card';

  export default {
    name: 'cards',

    props: {
      cards: { type: Array, default: () => ([]) },
    },

    components: { TitledCard, card },
  };
</script>

<style lang="sass" scoped>
  hr
    margin: .75rem 0
</style>