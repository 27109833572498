<template lang="pug">
  div
    customer(
      :customer="customer"
      :profile-link="false"
      :deletable="canDelete"
      extended
      @edit="({ customer }) => onClickEdit(customer)"
      @delete="({ customer }) => onClickDelete(customer)"
    )
    form-modal(
      v-model="formModal"
      :customer="editingEntity"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      @submit="onSubmit"
    )
</template>

<script>
  import Customer from '@/components/cards/customer-card';
  import FormModal from '@/components/modals/customer-form-modal';
  import gestionableList from '@/mixins/gestionable-list-mixin';

  export default {
    name: 'customer-card',

    mixins: [
      gestionableList({
        entityName: 'customer',
      }),
    ],

    props: {
      requesting: { type: Boolean, default: false },
      canDelete: { type: Boolean, default: false },
      canChangeAdviser: { type: Boolean, default: false },
      canChangeSensibleData: { type: Boolean, default: false },
    },

    computed: {
      hiddenFields() {
        const fields = [];

        if(!this.canChangeAdviser) fields.push('adviser');
        if(!this.canChangeSensibleData) fields.push('email', 'vat', 'vatTypeId')

        return fields;
      },
    },

    components: { Customer, FormModal },
  };
</script>

<style lang="sass" scoped>

</style>