<template lang="pug">
  .dashboard-card
    .dashboard-card__header
      .dashboard-card__title {{ currentTitle }}
    .dashboard-card__body
      .dashboard-card__scrollable-content
        gauge(
          :start-angle="-90"
          :end-angle="90"
          :value="currentValue"
          :min="0"
          :max="secondOffset"
          :separator-step="0"
          :gauge-color="[{ offset: 0, color: '#de3a21'}, { offset: (firstOffset / secondOffset * 80), color: '#f4c009'} , { offset: (secondOffset / secondOffset * 100), color: '#0b8c5a'}, { offset: (thirdOffset / secondOffset * 120), color: '#3498db'}]"
        )
          .inner-text(:style="{ color: innerTextColor }")
            span(style="font-size: 1.6em") {{ currentValue }}
        .dashboard-card__legend
          .dashboard-card__legend-item(v-for="range in colorsAndTexts" :key="range.percentage")
            .dashboard-card__legend-item-value(:style="{ color: range.color }") {{ range.value }}
            .dashboard-card__legend-item-percentage(:style="{ color: range.color }") {{ range.percentage }}



</template>

<script>
  import { VueSvgGauge as Gauge } from 'vue-svg-gauge';

  export default {
    name: 'tachometer',

    props: {
      currentTitle: { type: String, default: '' },
      currentValue: { type: Number, default: 0 },
      firstOffset: { type: Number, default: 0 },
      secondOffset: { type: Number, default: 0 },
      thirdOffset: { type: Number, default: 0 },
    },

    computed: {
      innerTextColor() {
        if(this.currentValue < this.firstOffset) return '#de3a21'
        else if(this.currentValue < this.secondOffset) return '#f4c009'
        else if(this.currentValue < this.thirdOffset) return '#0b8c5a'
        else return '#3498db';
      },

      colorsAndTexts() {
        return [
          { value: "N/A", color: '#de3a21', percentage: '0-79%' },
          { value: this.firstOffset, color: '#f4c009', percentage: '80-99%' },
          { value: this.secondOffset, color: '#0b8c5a', percentage: '100-119%' },
          { value: this.thirdOffset, color: '#3498db', percentage: '+120%' },
        ];
      },
    },

    components: {
      Gauge,
    },
  };
</script>

<style lang="sass" scoped>
.tachometer
  max-width: 400px
  margin: 0 auto

.inner-text
  display: flex
  align-items: flex-end
  justify-content: center
  height: 100%
  width: 100%
  font-weight: bold

span
  font-size: 2em

.dashboard-card__legend
  display: flex
  justify-content: space-between
  margin-top: 20px

.dashboard-card__legend-item
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.dashboard-card__legend-item-value
  font-size: 1em

.dashboard-card__legend-item-percentage
  font-size: .8em

</style>