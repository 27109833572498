<template lang="pug">
  v-dialog(
    :value="value"
    @input="args => $emit('input', args)"
    :width="width"
    :persistent="persistent"
    :content-class="contentClass"
  )
    v-card
      .px-4.pt-4.pb-2
        slot
</template>

<script>
  export default {
    name: 'base-form-modal',

    props: {
      value: { type: Boolean, default: false },
      width: { type: Number, default: 800 },
      persistent: { type: Boolean, default: true },
      contentClass: { type: String },
    },
  };
</script>
