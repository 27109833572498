<template lang="pug">
  v-form(@submit.prevent="onSubmit")
    div(:class="b('property-data')" v-if="property").mb-2
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") Tipo de propiedad:
        div(:class="b('property-data-item-value')") {{ property.type.name }}
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") Valor comercial:
        div(:class="b('property-data-item-value')") {{ property.value | formatCurrency(property.currency) }}
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") Area Total
        div(:class="b('property-data-item-value')") {{ property.totalArea }} m2
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") Propietario:
        div(:class="b('property-data-item-value')") {{ realOwnerFullName }}
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") Dirección:
        div(:class="b('property-data-item-value')") {{ propertyAddress }}
      div(:class="b('property-data-item')")
        div(:class="b('property-data-item-title')") # de partida:
        div(:class="b('property-data-item-value')") {{ property.registrationNumber }}

    yes-or-no-radio(
      v-model="data.dni"
      label="El DNI del propietario coincide con el documento y es legible"
      :error="data.dni === false"
    )
    yes-or-no-radio(
      v-model="data.alreadyRegistered"
      label="El inmueble está correctamente registrado en registros públicos"
      :error="data.alreadyRegistered === false"
    )
    yes-or-no-radio(
      v-model="data.correctHolder"
      label="El inmueble está a nombre de una persona (no a nombre de una empresa)"
      :error="data.correctHolder === false"
    )
    yes-or-no-radio(
      v-model="data.agree"
      label="Todas las personas propietarias del inmueble están de acuerdo con hipotecar el bien"
      :error="data.agree === false"
    )
    yes-or-no-radio(
      v-model="data.publicSpace"
      label="En el inmueble funciona colegios, mercados, albergues, iglesias o campos recreativos públicos"
      :error="data.publicSpace === true"
    )
    yes-or-no-radio(
      v-model="data.judicialProcess"
      label="El inmueble tiene algun proceso judicial vigente o no concluido"
      :error="data.judicialProcess === true"
    )
    yes-or-no-radio(
      v-model="data.services"
      label="El inmueble tiene servicios públicos básicos (agua, desagüe y luz)"
      :error="data.services === false"
    )
    yes-or-no-radio(
      v-model="data.asphalted"
      label="Las pistas frente al inmueble son asfaltadas"
      :error="data.asphalted === false"
    )
    yes-or-no-radio(
      v-model="data.materials"
      label="El inmueble esta construido con material noble (paredes de ladrillo o bloques de cemento)"
      :error="data.materials === false"
    )
    yes-or-no-radio(
      v-model="data.annexed"
      label="El inmueble es una azotea o aires de otra propiedad"
      :error="data.annexed === true"
    )
    div(style="padding-top: 36px")
      v-btn(type="submit" :disabled="!isValid || disabled" color="primary").mr-2 Validar Información
      v-btn(type="button" @click="$emit('cancel')" color="error" text) Regresar
</template>

<script>
import addressToString from '@/utils/address-to-string';
import PropertyTypeSelect from '@/components/inputs/property-type-select';
import YesOrNoRadio from '@/components/inputs/yes-or-no-radio';
import formStepMixin from '../form-step-mixin';

export default {
  name: 'property-step-form',

  mixins: [formStepMixin()],

  props: {
    customer: { type: Object },
  },

  data() {
    return {
      data: {
        dni: null,
        alreadyRegistered: null,
        correctHolder: null,
        agree: null,
        services: null,
        publicSpace: null,
        judicialProcess: null,
        asphalted: null,
        materials: null,
        annexed: null,
        ...this.defaultValues,
      }
    };
  },

  computed: {
    isValid() {
      const trueKeys = ['dni', 'alreadyRegistered', 'correctHolder', 'agree', 'asphalted', 'materials', 'services'];
      const falseKeys = ['publicSpace', 'judicialProcess', 'annexed'];

      return trueKeys.every(key => this.data[key] === true) && falseKeys.every(key => this.data[key] === false);
    },

    property() {
      return this.customer?.properties[0];
    },

    realOwnerFullName() {
      return [
        this.property?.realOwner?.firstName || '',
        this.property?.realOwner?.lastName || '',
      ].join(' ');
    },

    propertyAddress() {
      return this.property ? addressToString(this.property.address) : '';
    },
  },

  methods: {
    onSubmit() {
      this.$emit('submit', { data: this.data });
    },
  },

  components: { PropertyTypeSelect, YesOrNoRadio },
};
</script>

<style lang="sass" scoped>
  .property-step-form
    &__property-data
      background-color: darken(white, 4%)
      padding: 8px 12px
      border-radius: 6px

      &-item
        display: flex

        &-title
          font-weight: 500
          margin-right: 8px

    &__control
      display: flex
      align-items: center
      padding: 9px 0

      &-text
        color: rgba(0,0,0,0.6)
        font-size: 16px
        padding-left: 4px
        flex: 1

        &--error
          color: red

      &-radios
        margin-top: 0
        padding-top: 0
</style>
