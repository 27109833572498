<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 md6).px-2
          validation-provider(name="nombre" :rules="rules.firstName" v-slot="{ errors }")
            v-text-field(
              v-model="data.firstName"
              :error-messages="errors"
              name="nombre"
              label="Nombre"
              autofocus
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="apellido paterno" :rules="rules.lastName" v-slot="{ errors }")
            v-text-field(
              v-model="data.lastName"
              :error-messages="errors"
              name="apellido paterno"
              label="Apellido paterno"
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="apellido materno" :rules="rules.maternalLastName" v-slot="{ errors }")
            v-text-field(
              v-model="data.maternalLastName"
              :error-messages="errors"
              name="apellido materno"
              label="Apellido materno"
            )
        v-flex(v-if="renderField('vatTypeId')" xs12 md6).px-2
          validation-provider(name="tipo de documento" :rules="'required'" v-slot="{ errors }")
            vat-type-select(
              v-model="data.vatTypeId"
              :error-messages="errors"
              :default-items="vatTypeDefaultItems"
              :lazy="lazySelects"
              name="tipo de documento"
              label="Tipo de Documento"
            )
        v-flex(v-if="renderField('vat')" xs12 md6).px-2
          validation-provider(name="numero de identificacion" :rules="rules.vat" v-slot="{ errors }")
            v-text-field(
              v-model="data.vat"
              :error-messages="errors"
              name="numero de identificacion"
              label="Número de Identifiación"
              required
            )
        v-flex(v-if="renderField('maritalStatusId')" xs12 md6).px-2
          validation-provider(name="estado civil" :rules="'required'" v-slot="{ errors }")
            marital-status-select(
              v-model="data.maritalStatusId"
              label="Estado Civil"
              name="estado civil"
              :error-messages="errors"
            )
        //--:lazy="lazySelects"
        v-flex(v-if="renderField('gender')" xs12 md6).px-2
          validation-provider(name="gender" :rules="'required'" v-slot="{ errors }")
            gender-type-select(
              v-model="data.gender"
              :error-messages="errors"
              name="genero"
              label="Género"
            )
        v-flex(v-if="renderField('birth')" xs12 md6).px-2
          date-picker(
            v-model="data.birth"
            label="Fecha Nacimiento"
          )
        v-flex(v-if="renderField('mobile')" xs12 md6).px-2
          validation-provider(name="celular" :rules="rules.mobile" v-slot="{ errors }")
            phone-input(
              v-model="data.mobile"
              :error-messages="errors"
              label="Celular"
              name="celular"
              prepend-icon="mdi-cellphone-android"
            )
        v-flex(v-if="renderField('phone')" xs12 md6).px-2
          validation-provider(name="teléfono" :rules="rules.phone" v-slot="{ errors }")
            v-text-field(
              v-model="data.phone"
              :error-messages="errors"
              label="Teléfono"
              name="teléfono"
              prepend-icon="mdi-phone"
            )
        v-flex(v-if="renderField('email')" xs12 md6).px-2
          validation-provider(name="correo" :rules="rules.email" v-slot="{ errors }")
            v-text-field(
              v-model="data.email"
              :error-messages="errors"
              name="correo"
              label="Correo"
              prepend-icon="mdi-email"
              autocomplete="email"
            )
        v-flex(v-if="renderField('couple')" xs12 md6).px-2
          .d-flex.align-center
            v-layout
              user-select(
                v-model="data.coupleId"
                :default-items="coupleDefaultItems"
                :per-page="5"
                label="Pareja"
                clearable
              )
            v-btn(v-if="!hasCouple" icon color="primary" :to="createCoupleRoute").ml-2
              v-icon mdi-account-plus
        v-flex(v-if="renderField('profession')" xs12 md6).px-2
          v-text-field(
            v-model="data.profession"
            label="Ocupación"
            name="ocupación"
          )
        v-flex(v-if="renderField('dependents')" xs12 md6).px-2
          validation-provider(name="cantidad de dependientes" :rules="'numeric|required|min_value:0'" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.dependents"
              :error-messages="errors"
              label="Cantidad del dependientes"
              name="cantidad de dependientes"
              type="number"
            )
        v-flex(v-if="renderField('adviser')" xs12 md6).px-2
          adviser-select(
            v-model="data.adviserId"
            :default-items="adviserDefaultItems"
            label="Asesor"
          )
        v-flex(v-if="renderField('address')" xs12).px-2
          validation-provider(name="dirección" :rules="data.address._locationIds.length > 1 ? { required: true } : null" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.line1"
              :error-messages="errors"
              name="dirección"
              label="dirección"
            )
        v-flex(
          v-if="renderField('address')"
          v-for="division, idx in politicalDivisions"
          v-show="idx > 0"
          :key="idx"
          xs12 md6
        ).px-2
          validation-provider(:name="division.name" :rules="data.address.line1 && idx === 0 ? { required: true } : null" v-slot="{ errors }")
            location-select(
              :value="$addressInput.getValue(idx)"
              :error-messages="errors"
              :name="division.name"
              :disabled="$addressInput.isDisabled(idx)"
              :parent-id="$addressInput.getParentId(idx)"
              :default-items="$addressInput.getDefaultItems(idx)"
              :label="division.name"
              @input="value => $addressInput.onInput(value, idx)"
            )
        v-flex(
          v-if="renderField('address')"
          xs12 md6
        ).px-2
          validation-provider(name="código postal" :rules="rules.address && rules.address.zipCode" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.zipCode"
              :error-messages="errors"
              name="código postal"
              label="código postal"
            )
        v-layout(v-if="passwordField")
          v-flex(xs12 md6).pr-1
            v-text-field(
              v-model="data.password"
              type="password"
              label="Contraseña"
            )
          v-flex(xs12 md6).pl-1
            v-text-field(
              v-model="data.passwordConfirm"
              type="password"
              label="Repetir Contraseña"
            )
        v-flex(v-if="isCreatingForm && renderField('notificationsActive') " xs12).px-2
          .d-flex.justify-end
            v-checkbox(
              v-model="data.notificationsActive"
              label="¿Habilitar notificaciones?"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(v-show="!isCreatingForm" color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
import omit from 'lodash/omit';
import last from 'lodash/last';

import MaritalStatusSelect from '@/components/inputs/marital-status-select';
import PhoneInput from '@/components/inputs/phone-input';
import DatePicker from '@/components/inputs/input-date-picker';
import Config from '@/config';
import UserSelect from '@/components/inputs/user-select';
import AdviserSelect from '@/components/inputs/adviser-select';
import VatTypeSelect from '@/components/inputs/vat-type-select';
import GenderTypeSelect from '@/components/inputs/gender-type-select/gender-type-select';
import addressInputMixin from '@/mixins/address-input-mixin';

import basicFormMixin from './basic-form-mixin';

export default {
  name: 'customer-form',

  props: {
    passwordField: { type: Boolean, default: false },
    lazySelects: { type: Boolean, default: true },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'customer',
      veeValidation: true,
      mapPropsToData({ customer }) {
        return {
          id: customer?.id || undefined,
          firstName: customer?.firstName || null,
          lastName: customer?.lastName || null,
          maternalLastName: customer?.maternalLastName || null,
          mobile: customer?.mobile || null,
          phone: customer?.phone || null,
          email: customer?.email || null,
          vat: customer?.vat || null,
          vatTypeId: customer?.vatType?.id || '1',
          birth: customer?.birth || null,
          maritalStatusId: customer?.maritalStatus?.id || '1',
          adviserId: customer?.adviser?.id,
          coupleId: customer?.couple?.id,
          dependents: customer?.dependents,
          profession: customer?.profession,
          notificationsActive: true,
          address: {
            ...customer?.address,
            _locationIds: customer?.address?.location?.length ? [...customer.address.location].map(({ id }) => id) : ['1'],
          },
          gender: customer?.gender || [],
        };
      },

      parseSubmit() {
        return {
          customer: {
            ...this.data,
            coupleId: this.data.coupleId || null, // Si no seteamos null apollo no lo manda y no podremos desasignar la pareja
            dependents: this.data.dependents || null,
            address: this.data.address._locationIds.length > 1 ? {
              ...omit(this.data.address, ['id', '__typename', 'location', '_locationIds']),
              locationId: last(this.data.address._locationIds)
            } : undefined,
          },
        };
      }
    }),

    addressInputMixin(),
  ],

  computed: {
    rules() {
      return Config.CUSTOMER_FORM_RULES({ data: this.data });
    },

    vatTypeDefaultItems() {
      return this.customer?.vatType ? [{ id: this.customer?.vatType?.id, description: this.customer?.vatType?.description }] : [];
    },

    adviserDefaultItems() {
      return this.customer?.adviser ? [{ ...this.customer?.adviser }] : [];
    },

    coupleDefaultItems() {
      return this.customer?.couple ? [{ ...this.customer?.couple }] : [];
    },

    createCoupleRoute() {
      return { name: 'customers.new', query: { coupleId: this.customer?.id }};
    },

    hasCouple() {
      return Boolean(this.data.coupleId);
    },

    isCreatingForm() {
      return Boolean(!this.data.id);
    },
  },

  components: {
    MaritalStatusSelect,
    DatePicker,
    PhoneInput,
    AdviserSelect,
    VatTypeSelect,
    UserSelect,
    GenderTypeSelect,
  },
};
</script>

<style lang="sass" scoped>

</style>