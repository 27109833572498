<template lang="pug">
  base-dialog(
    :value="value"
    title="SUNAT"
    @input="args => $emit('input', args)"
  )
    div(
      v-for="sunat in sunats"
      :key="sunat.id"
      :class="b('sunat')"
    )
      div(v-if="sunat.businessName") #[b Nombre: ] {{ sunat.businessName }}
      div(v-if="sunat.commercialName") #[b Nombre Comercial: ] {{ sunat.commercialName }}
      div(v-if="sunat.ruc") #[b RUC: ] {{ sunat.ruc }}
      div(v-if="sunat.foreignTradeActivity") #[b Actividad: ] {{ sunat.foreignTradeActivity }}
      div(v-if="sunat.taxpayerType") #[b Tipo de contribuyente: ] {{ sunat.taxpayerType }}
      div(v-if="sunat.taxpayerStatus") #[b Estado: ] {{ sunat.taxpayerStatus }}
      div(v-if="sunat.taxpayerCondition") #[b Condición: ] {{ sunat.taxpayerCondition }}
      div(v-if="sunat.dependency") #[b Dependencia: ] {{ sunat.dependency }}
      div(v-if="sunat.ciiuCode") #[b CIIU: ] {{ sunat.ciiuCode }}
      div(v-if="sunat.ciiuDescription") #[b Descripción: ] {{ sunat.ciiuDescription }}
      div(v-if="sunat.activitiesStart") #[b Inicio de actividades: ] {{ sunat.activitiesStart | formatDate }}
      div(v-if="sunat.workersNumber") #[b Trabajadores: ] {{ sunat.workersNumber }}
      div(v-if="sunat.address") #[b Dirección: ] {{ sunat.address }}
</template>

<script>
  import BaseDialog from './base-dialog';

  export default {
    name: 'sunats-dialog',

    props: {
      value: { type: Boolean },
      sunats: { type: Array, default: () => [] },
    },

    components: { BaseDialog },
  };
</script>

<style lang="sass" scoped>
  .sunats-dialog
    &__sunat
      padding-bottom: 16px

      &:last-child
        padding-bottom: 0
</style>