<template lang="pug">
  div
    v-card.pa-4
      v-layout(align-center)
        v-btn(text color="accent" @click="createModal = true")
          v-icon(left) mdi-account-plus
          span Nuevo
        v-spacer
        v-flex(xs4)
          v-text-field(
            :value="querySearch"
            :clearable="true"
            @input="onChangeQuerySearch"
            append-icon="mdi-magnify"
            placeholder="Buscar cliente..."
            text
            hide-details
          ).pt-0
    div(:class="b('body')")
      .text-center
        v-pagination(
          v-if="totalPages > 1"
          :value="page"
          :length="totalPages"
          :total-visible="15"
          @input="goToPage"
        )
      loading-wrapper(
        v-if="$apollo.queries.customers.loading || customersError || !customers"
        :error="customersError"
        @retry="onRetryCustomers"
      )
      customers-list(
        v-else-if="customers.length"
        :customers="customers"
      )
      v-alert(
        v-else
        :value="true"
        type="info"
      ) No se han encontrado clientes que coincidan con los parametros de busqueda
      .text-center
        v-pagination(
          v-if="totalPages > 1"
          :value="page"
          :length="totalPages"
          :total-visible="15"
          @input="goToPage"
        )
      create-customer-modal(v-model="createModal")
</template>

<script>
import debounce from 'lodash/debounce';

import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import CreateCustomerModal from '@/components/smart/create-customer-modal';

import { CustomersQuery } from './graphql/queries.gql';
import CustomersList from './components/customers-list';

export default {
  name: 'customers-list-view',

  data() {
    return {
      page: 1,
      totalPages: 0,
      querySearch: null,
      customers: null,
      customersError: null,
      createModal: false,
    };
  },

  apollo: {
    customers: {
      query: CustomersQuery,
      manual: true,

      variables() {
        return {
          page: this.page,
          search: this.querySearch ? this.querySearch : null,
        };
      },

      result({ data }) {
        const customers = data?.customers;

        if(!customers) return;

        this.customers = customers.objects;
        this.page = customers.page;
        this.totalPages = customers.pages;
      },

      error() {
        this.customersError = new Error('Ha ocurrido un error al obtener los datos');
      },
    },
  },

  methods: {
    goToPage(n) {
      this.page = n;
    },

    onChangeQuerySearch: debounce(function (value) {
      this.querySearch = value;
    }, 300),

    onRetryCustomers() {
      this.customersError = null;
      this.$apollo.queries.customers.refetch();
    },
  },

  components: {
    CustomersList,
    CreateCustomerModal,
    LoadingWrapper,
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .customers-list-view
    &__body
      padding: 2rem 0rem

      +md
        padding: 2rem
</style>