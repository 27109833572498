<template lang="pug">
  .data-card
    .data-card__body(:class="b('investment-proposal-card-pending')")
      v-layout()
        v-flex(md-4 sm-6)
          .data-row()
            span(:class="b('data-row-title')") No. préstamo:&nbsp;
            span(:class="b('data-row-value')") {{ investmentProposal.id }}
          .data-row()
            span(:class="b('data-row-title')") TEA:&nbsp;
            span(:class="b('data-row-value')") {{ Number(statement.borrowerRate * 100).toFixed(2) }} %
          .data-row()
            span(:class="b('data-row-title')") TCEA:&nbsp;
            span(:class="b('data-row-value')") {{ Number(statement.tcea).toFixed(2) }} %
          .data-row()
            span(:class="b('data-row-title')") Tasa de interés moratorio nominal anual:&nbsp;
            span(:class="b('data-row-value')") {{ Number(investmentProposal.defaultRate).toFixed(2) }} %
          .data-row()
            span(:class="b('data-row-title')") Penalidad por cada día de atraso:&nbsp;
            span(:class="b('data-row-value')") {{ investmentProposal.penaltyFee | formatCurrency(investmentProposal.currency) }}
        v-flex(md-4 sm-6)
          .data-row()
            span(:class="b('data-row-title')") Moneda:&nbsp;
            span(:class="b('data-row-value')") {{ investmentProposal.currency.name }} ({{ investmentProposal.currency.symbol }})
          .data-row()
            span(v-if="logo" :class="b('data-row-title')") Monto total del desembolso:&nbsp;
            span(v-if="!logo" :class="b('data-row-title')") Monto total del crédito:&nbsp;
            span(:class="b('data-row-value')") {{ investmentProposal.debtAmount | formatCurrency(investmentProposal.currency) }}
          .data-row(v-if="hasValidGraceDays")
            span(:class="b('data-row-title')") Intereses por los días de gracia:&nbsp;
            span(:class="b('data-row-value')") {{ (Number(entry.interest) + Number(entry.propertyInsurance) + Number(entry.lifeInsurance)) | formatCurrency(investmentProposal.currency)  }}
          .data-row()
            span(:class="b('data-row-title')") Monto total de intereses compensatorios:&nbsp;
            span(:class="b('data-row-value')") {{ statement.totals.interest | formatCurrency(investmentProposal.currency) }}
        v-flex(md-4 sm-6)
          .data-row()
            span(:class="b('data-row-title')") Plazo:&nbsp;
            span(:class="b('data-row-value')") {{ statement.remainingMonths }} meses
          .data-row()
              span(:class="b('data-row-title')") Fecha de desembolso:&nbsp;
              span(:class="b('data-row-value')") {{ investmentProposal.disbursementDate | formatDate }}
          .data-row()
            span(:class="b('data-row-title')") Primera cuota:&nbsp;
            span(:class="b('data-row-value')") {{ investmentProposal.firstPaymentDate | formatDate }}
          .data-row(v-if="hasValidGraceDays")
            span(:class="b('data-row-title')" ) Días de gracia:&nbsp;
            span(:class="b('data-row-value')") {{graceDays}} días
</template>

<script>
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';
import moment from 'moment';
export default {
  name: 'investment-proposal-card-pending',

  props: {
    statement: { type: Object },
    investmentProposal: { required: true, type: Object },
    logo: { type: Boolean, default: true },
    totalAmortization: { type: Number },
    entry: { type: Object },
  },
  computed: {
    showDetail() {
      return this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.COLLECTING || this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED;
    },
    graceDays() {
      return moment(this.investmentProposal.firstPaymentDate).diff(this.investmentProposal.disbursementDate, 'days') - 30;
    },
    hasValidGraceDays() {
      return this.graceDays > 0;
    },
  },
};
</script>

<style lang="sass" scoped>
  .investment-proposal-card-pending
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    padding-left: 60px

    &__name
      font-size: 16px
      font-weight: 500
      margin-bottom: 16px
      text-align: center

    &__data-row
      text-align: center
      margin-bottom: 14px
      text-align: center

      &-date
        color: darken(white, 60%)
        font-weight: 600

      &-value
        font-size: 12px
        margin-bottom: 4px
        line-height: 1
        font-weight: 600

      &-title
        font-size: 12px
        line-height: 1.25

      &-detail
        font-size: 12px
        line-height: 1.25
        text-align: left
</style>
