<template lang="pug">
  v-expansion-panel(expand :class="b('expansion-panel')")
    v-expansion-panel-header
      div(:class="b('header')")
        div(:class="b('title')") {{ title }}  ({{ credits.length }})
        v-icon(v-if="icon" :color="iconColor") {{ icon }}
    v-expansion-panel-content(:class="b()")
      credits-list(:credits="credits")
</template>

<script>
  import CreditsList from './credits-list';

  export default {
    name: 'credits-group',

    props: {
      title: { type: String },
      credits: { type: Array, default: () => [] },
    },

    computed: {
      icon() {
        return ['LC', 'CL'].includes(this.credits[0].clavePrevencion) ? 'mdi-alert' : null;
      },

      iconColor() {
        const COLORS = {
          LC: 'red',
          CL: '#ffa629',
        };

        return COLORS[this.credits[0].clavePrevencion];
      },
    },

    components: {
      CreditsList,
    },
  };
</script>

<style lang="sass" scoped>
  .credits-group
    &__header
      display: flex
      align-items: center
      font-weight: bold

      .v-icon
        margin-left: 8px
</style>