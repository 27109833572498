<template lang="pug">
  div(:class="b()")
    table(:class="b('table')")
      thead(:class="b('table-head')")
        tr
          th Documento
          th Correo
          th Teléfono
      tbody(:class="b('table-body')")
        referrals-table-row(
          v-for="referral in referrals"
          :key="referral.id"
          :referral="referral"
        )
</template>

<script>
import ReferralsTableRow from './referrals-table-row';

export default {
  name: 'referrals-table',

  props: {
    referrals: { type: Array, default: () => [] },
  },

  components: { ReferralsTableRow },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'
  .referrals-table
    @media screen and (max-width: 959px)

      table, thead, tbody, th, td, tr
        display: block

      thead
        display: none

      &::v-deep
        tr, td
          display: block

        td
          position: relative
          padding: 4px 0
          min-height: 30px
          padding-left: 30%
          border-bottom: 1px solid darken(white, 6%)

          &:before
            position: absolute
            left: 4px
            top: 4px
            width: 25%
            white-space: nowrap
            font-weight: 500

          &:nth-of-type(1):before
            content: 'Documento'
          &:nth-of-type(2):before
            content: 'Correo'
          &:nth-of-type(3):before
            content: 'Teléfono'

          &:last-child
            margin-bottom: 12px
            border-bottom: 0

    +md
      &__table
        width: 100%
        border-collapse: collapse

        &-head
          th
            padding: 3px 0
            background-color: darken(white, 3%)

        &-body
          &::v-deep tr
            td
              padding: 2px 6px
              border-bottom: 1px solid darken(white, 7%)
              text-align: center

            &:last-child
              td
                border-bottom: 0
</style>