<template lang="pug">
  router-link(:class="b()" :to="{ name: 'formalizations.show', params: { formalizationId: formalization.id } }")
    div(:class="b('customer-name')") {{ customer.firstName }}  {{ customer.lastName }}
    div(:class="b('date')")
      span.font-weight-medium Título:
      span.ml-1 {{ mortgageData }}
      mortgage-number-status-tag(v-if="formalization.mortgageNumberStatus" :status="formalization.mortgageNumberStatus" style="font-size: 11px").ml-1
    div(:class="b('date')")
      span.font-weight-medium Bloqueo:
      span.ml-1 {{ blockData }}
    div(:class="b('credit')" v-if="formalization.deal") {{ formalization.deal.credit | formatCurrency(formalization.deal.currency) }}
    div(:class="b('footer')")
      status-tag(:status="formalization.status")
      div(v-if="adviser" :class="b('adviser')")
        div {{ adviser.firstName }} {{ adviser.lastName }}
        user-avatar(v-if="adviser" :first-name="adviser.firstName" :last-name="adviser.lastName" :url="adviser.avatar" :size="24")
</template>

<script>
import UserAvatar from '@/components/UI/user-avatar';
import StatusTag from '@/components/UI/formalization-status-tag';
import MortgageNumberStatusTag from '@/components/UI/mortgage-number-status-tag';
import formalizationBlockData from '@/utils/formalization-block-data';
import formalizationMortgageData from '@/utils/formalization-mortgage-data';

export default {
  name: 'formalization-stage-card',

  props: {
    formalization: { type: Object, required: true },
  },

  computed: {
    customer() {
      return this.formalization.customer;
    },

    adviser() {
      return this.formalization.customer.adviser;
    },

    blockData() {
      return formalizationBlockData(this.formalization);
    },

    mortgageData() {
      return formalizationMortgageData(this.formalization);
    },
  },

  components: { UserAvatar, StatusTag, formalizationBlockData, MortgageNumberStatusTag },
};
</script>

<style lang="sass" scoped>

  .formalization-stage-card
    display: block
    margin: .5rem .75rem
    padding: 1rem
    background-color: white
    border-radius: 4px
    border: 1px solid #dfdfdf
    box-shadow: 0 1px 2px rgba(0,0,0,0.1)
    color: inherit
    text-decoration: none
    font-size: 12px
    user-select: none
    touch-callout: none

    &__customer-name
      font-size: 1.1rem

    &__credit
      font-size: 1.2rem
      font-weight: 500
      text-align: right


    &__footer
      display: flex
      justify-content: space-between
      margin-top: .75rem

    &__adviser
      display: flex
      align-items: center

      & > *:last-child
        margin-left: 6px

</style>