<template lang="pug">
  v-card(:class="b()")
    div(:class="b('title')") {{ title }}
    div(:class="b('chart-container')")
      linear-chart(:chartdata="chartdata" :options="chartopts")
</template>

<script>
  import Config from '@/config';
  import LinearChart from './linear-chart';

  export default {
    name: 'chart-card',

    props: {
      title: { type: String },
      data: { type: Array, default: () => [] },
    },

    data() {
      return {
        chartopts: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        },
      };
    },

    computed: {
      chartdata() {
        return {
          labels: this.data.map(({ x }) => x),
          datasets: [
            {
              pointBorderWidth: 4,
              pointBackgroundColor: Config.PRIMARY_COLOR,
              borderColor: Config.PRIMARY_COLOR,
              backgroundColor: '#ceedf382',
              data: this.data.map(({ y }) => y),
            },
          ],
        };
      },
    },

    components: { LinearChart },
  };
</script>

<style lang="sass" scoped>
  .chart-card
    padding: 24px

    &__title
      margin-bottom: 22px
      font-weight: 500
</style>