<script>
  import moment from 'moment';
  import { Bar } from 'vue-chartjs'
  import { formatNumber, formatCurrency } from '@/filters/accounting';
  import { pipe, groupBy, mapValues, get, reduce } from 'lodash/fp';
  import uniqolor from 'uniqolor';

  const getPeriodYear = period => moment(period).year();

  export default {
    name: 'incomes-chart',

    extends: Bar,

    props: {
      incomes: { type: Array, default: () => [] },
    },

    computed: {
      periods() {
        return Object.keys(this.incomesByPeriodsAndCategories);
      },

      categories() {
        return pipe(
          groupBy('concept'),
          Object.keys,
        )(this.incomes);
      },

      incomesByPeriodsAndCategories() {
        return pipe(
          groupBy('period'),
          mapValues(value => groupBy('concept')(value)),
        )(this.incomes);
      },

      averagesByYear() {
        return pipe(
          groupBy(({ period }) => getPeriodYear(period)),
          mapValues(incomes => {
            const incomesByPeriods = groupBy('period')(incomes);

            return pipe(
              Object.values,
              reduce((acc, periodIncomes) => {
                return acc + periodIncomes.reduce((acc, { incomeAmount }) => Number(incomeAmount || 0) + acc, 0);
              }, 0)
            )(incomesByPeriods) / Object.keys(incomesByPeriods).length;
          }),
        )(this.incomes);
      },

      averageDataset() {
        return {
          label: 'Promedio (año)',
          type: 'line',
          pointBorderWidth: 1,
          backgroundColor: 'rgba(51, 153, 255, 0.15)',
          borderColor: 'rgba(51, 153, 255, 0.4)',
          pointBackgroundColor: 'rgb(51, 153, 255)',
          data: this.periods.map(period => this.averagesByYear[getPeriodYear(period)]),
        }
      },

      datasets() {
        return [
          ...this.categories.map(category => {
            const { color } = uniqolor(category, { format: 'rgb' });

            return {
              label: category,
              pointBorderWidth: 4,
              pointBackgroundColor: color,
              borderColor: color,
              backgroundColor: color.slice(0, color.length - 1) + ',0.7)',
              data: this.periods.map(period => {
                return pipe(
                  get([period, category]),
                  reduce((acc, { incomeAmount }) => acc + Number(incomeAmount), 0),
                )(this.incomesByPeriodsAndCategories);
              }),
            };
          }),
          this.averageDataset,
        ]
      }
    },

    mounted() {
      /**
       * El timeout es debido a que cuando se hace el mounted el v-tab padre no le
       * ha dado la dimension correspondiente al div y chart.js no puede calcular
       * el height debido a la opcion maintainAspectRatio: false la cual considera
       * el alto del padre antes de hacer render
       */
      setTimeout(() => {
        this.renderChart({
          labels: this.periods.map((period) => moment(period).format('MMM/YYYY')),

          datasets: this.datasets,
        }, {
          maintainAspectRatio: false,
          scales: {
            xAxes: [{ stacked: true, categoryPercentage: 0.5, barPercentage: 1}],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  callback(value) {
                    return 'S/ ' + formatNumber(value);
                  },
                },
              },
            ],
          },

          tooltips: {
            mode: 'label',
            callbacks: {
              label: ({ value, datasetIndex }) => {
                return value > 0 ? [`${this.datasets[datasetIndex].label}: ${formatCurrency(value)}`] : false;
              },

              footer: (objects) => {
                if(!objects?.length) return;


                return `Total: ${formatCurrency(
                  this.datasets.slice(0, this.datasets.length - 1).reduce( // slice due to ignore yearly average
                    (acc, dataset) => acc + Number(dataset.data[objects[0].index]),
                    0
                  )
                )}`
              },
            },
          },
        });
      }, 500);
    }
  };
</script>