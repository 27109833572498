<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Score
    div(:class="b('container')")
      div(:class="b('body')")
        div(:class="b('score-info')")
          div(:class="b('score-name')") {{ scoreName }}
          div(:class="b('score-amount')") {{ score.score }}
        score-bar(:score="score.score" :ranges="ranges")
      ul(:class="b('reasons')" v-if="score.razones")
        li(v-for="reason, idx in score.razones" :key="idx") {{ reason.descripcion }}
</template>

<script>
  import ScoreBar from '@/components/UI/score-bar';
  import { getRangeByScore } from '@/utils/score-utils';

  const RANGES = [
    { text: 'Bajo', color: '#f6412d', min: 300 , max: 549 },
    { text: 'Regular', color: '#f4c10b', min: 550, max: 649 },
    { text: 'Bueno', color: '#49c073', min: 650, max: 749 },
    { text: 'Excelente', color: '#52aaf9', min: 750, max: 999 },
  ];
  export default {
    name: 'score',

    props: {
      score: { type: Object },
    },

    data() {
      return { ranges: RANGES };
    },

    computed: {
      scoreName() {
        return this.currentScore?.text;
      },

      currentScore() {
        return getRangeByScore(RANGES, this.score.score);
      },
    },

    components: { ScoreBar },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .score
    background-color: darken(white, 4%)
    border-radius: 6px
    padding: 12px 16px

    &__score-info
      display: flex
      align-items: center
      justify-content: space-between

    &__score-amount
      font-size: 28px
      font-weight: bold
      text-align: center
      padding: 0 8px

    &__score-name
      font-weight: 500
      font-size: 24px

    &__reasons
      padding-top: 12px
</style>