import VSelect from 'vuetify/lib/components/VSelect';
import {
  HOME_EQUITY_LOAN_SLUG,
  DEBT_CONSOLIDATION_PRODUCT_MOTIVE,
  HOUSE_REMODELING_PRODUCT_MOTIVE,
  WORK_CAPITAL_PRODUCT_MOTIVE,
  LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE,
  LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE,
  LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE,
  RESCHEDULE_MOTIVE,
} from "@/utils/constants";
import getProductMotive from '@/utils/get-product-motive';


export default {
  name: 'deal-motive-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: {
      type: Array,
      default: () => [
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, WORK_CAPITAL_PRODUCT_MOTIVE),
          value: WORK_CAPITAL_PRODUCT_MOTIVE,
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, DEBT_CONSOLIDATION_PRODUCT_MOTIVE),
          value: DEBT_CONSOLIDATION_PRODUCT_MOTIVE
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, HOUSE_REMODELING_PRODUCT_MOTIVE),
          value: HOUSE_REMODELING_PRODUCT_MOTIVE
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE),
          value: LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE),
          value: LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE),
          value: LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE
        },
        {
          text: getProductMotive(HOME_EQUITY_LOAN_SLUG, RESCHEDULE_MOTIVE),
          value: RESCHEDULE_MOTIVE
        },
        {
          text: 'Otro Destino',
          value: null,
        },
      ]
    },
  },
};
