<script>
import { Bar } from 'vue-chartjs';
import { groupBy, map, pipe, } from 'lodash/fp';
import uniqolor from 'uniqolor';
import { formatNumber, formatCurrency } from '@/filters/accounting';

export default {
  name: 'tax-report-incomes-chart',

  extends: Bar,

  props: {
    incomes: { type: Array, default: () => [] },
  },

  computed: {
    incomesByYears() {
      return groupBy('year')(this.incomes);
    },

    incomesByCategory() {
      return groupBy('incomeCategory')(this.incomes);
    },

    datasets() {
      return pipe(
        Object.values,
        map(
          (incomes) => {
            const category = incomes[0].incomeCategory;
            const { color } = uniqolor(category, { format: 'rgb' });

            return {
              label: category,
              pointBorderWidth: 4,
              pointBackgroundColor: color,
              borderColor: color,
              backgroundColor: color.slice(0, color.length - 1) + ',0.7)',
              data: this.labels.map((year) => {
                return incomes.filter(({ year: cYear }) => Number(year) === Number(cYear))
                  .reduce((acc, { amount }) => acc + Number(amount), 0);
                },
              ),
            };
          },
        ),
      )(this.incomesByCategory);
    },

    labels() {
      return Object.keys(this.incomesByYears);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,
        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback(value) {
                  return 'S/ ' + formatNumber(value);
                },
              },
            },
          ],
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            label: ({ value, datasetIndex }) => {
              return value > 0 ? [`${this.datasets[datasetIndex].label}: ${formatCurrency(value)}`] : false;
            },
          },
        },
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>