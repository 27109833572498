<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="banco" :rules="rules.bankId" v-slot="{ errors }")
            bank-select(
              v-model="data.bankId"
              label="Banco"
              :error-messages="errors"
              :default-items="bankDefaultItems"
              name="banco"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="tasa" :rules="rules.rateType" v-slot="{ errors }")
            rate-type-select(
              v-model="data.rateType"
              :error-messages="errors"
              name="tasa"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="seguro" :rules="rules.insuranceTypeId" v-slot="{ errors }")
            insurance-type-select(
              v-model="data.insuranceTypeId"
              :error-messages="errors"
              name="seguro"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(:name="$t('tea')" :rules="rules.tea" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.tea"
              :error-messages="errors"
              :name="$t('tea')"
              :label="$t('tea')"
              step="0.01"
              type="number"
              append-icon="mdi-percent"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(:name="$t('tcea')" :rules="rules.tcea" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.tcea"
              :error-messages="errors"
              :name="$t('tcea')"
              :label="$t('tcea')"
              step="0.01"
              type="number"
              append-icon="mdi-percent"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(:name="$t('simpleFee')" :rules="rules.simpleFee" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.simpleFee"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              :name="$t('simpleFee')"
              :label="$t('simpleFee')"
              type="number"
            )
        v-flex(v-if="renderField('doubleFee')" xs12 sm6 md4).px-1
          validation-provider(name="cuota doble" :rules="rules.doubleFee" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.doubleFee"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              name="cuota doble"
              label="Cuota Doble"
              type="number"
            )
        v-flex(v-if="renderField('amortizationType')" xs12 sm6 md4).px-1
          amortization-type-select(
            v-model="data.amortizationType"
            name="mensualidad"
          )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="plazo" :rules="rules.limitMonths" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.limitMonths"
              :error-messages="errors"
              name="plazo"
              label="Plazo (meses)"
              type="number"
            )
        v-flex(xs12 sm6 md4).px-1
          validation-provider(name="credito" :rules="rules.credit" v-slot="{ errors }")
            v-text-field(
              v-model="data.credit"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              name="credito"
              label="Crédito"
              type="number"
            )
        v-flex(v-if="renderField('currencyId')" xs12 sm6 md4).px-1
          validation-provider(name="moneda" :rules="'required'" v-slot="{ errors }")
            currency-select(
              v-model="data.currency"
              :error-messages="errors"
              :multiple="false"
              name="moneda"
              return-object
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
import { RATE_TYPE, AMORTIZATION_TYPE } from '@/utils/constants';
import CurrencySelect from '@/components/inputs/currency-select';
import BankSelect from '@/components/inputs/bank-select';
import RateTypeSelect from '@/components/inputs/credit-rate-type-select';
import InsuranceTypeSelect from '@/components/inputs/insurance-type-select';
import AmortizationTypeSelect from '@/components/inputs/amortization-type-select';
import basicFormMixin from './basic-form-mixin';
import Config from '@/config';
import { IS_MX_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'credit-form',

  mixins: [
    basicFormMixin({
      modelKey: 'credit',
      veeValidation: true,
      defaultHiddenFields: Config.CREDIT_HIDDEN_FIELDS,

      mapPropsToData({ credit }) {
        return {
          id: credit?.id,
          bankId: credit?.bank?.id,
          rateType: credit?.rateType || RATE_TYPE.FIXED,
          insuranceTypeId: credit?.insuranceType?.id || '2',
          tea: credit?.tea,
          tcea: credit?.tcea,
          simpleFee: credit?.simpleFee,
          doubleFee: credit?.doubleFee,
          amortizationType: credit?.amortizationType || AMORTIZATION_TYPE.FIXED,
          limitMonths: credit?.limitMonths,
          credit: credit?.credit,
          currency: credit?.currency || Config.CURRENCIES_SELECT[0],
        };
      },
      parseSubmit() {
        const { currency, ...data } = this.data;

        return {
          credit: {
            ...data,
            credit: parseFloat(data.credit),
            currencyId: currency?.id,
            simpleFee: data.simpleFee || null,
            doubleFee: data.doubleFee || null,
            limitMonths: data.limitMonths || null,
            tea: data.tea || null,
            tcea: data.tcea || null,
          },
        };
      },
    }),
  ],

  computed: {
    rules() {
      return Config.CREDIT_FORM_RULES({ data: this.data });
    },

    bankDefaultItems() {
      return this.credit?.bank
        ? [{ id: this.credit.bank.id, name: this.credit.bank.name }]
        : [];
    },

    internalHiddenFields() {
      return IS_MX_COUNTRY ? ['currencyId'] : [];
    },
  },

  components: {
    CurrencySelect,
    BankSelect,
    RateTypeSelect,
    InsuranceTypeSelect,
    AmortizationTypeSelect,
  },
};
</script>

<style lang="sass" scoped></style>
