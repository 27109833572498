<template lang="pug">
  div(:class="b()")
    div(
      v-for="entry in status"
      :key="entry.label"
      :class="b('entry')"
      :style="{ backgroundColor: entry.color, width: entry.value + '%' }"
      :title="entry.label + ': ' + entry.value + '%'"
    )
</template>

<script>
  export default {
    name: 'status-bar',

    props: {
      status: { type: Array, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  .status-bar
    display: flex
    height: 6px
    background-color: $cloudyColor

    border-radius: 6px
    overflow: hidden
</style>