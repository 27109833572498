<template lang="pug">
  router-link(:to="investmentProposalRoute" :class="b({ selected })")
    div
      v-checkbox(
        :value="selected"
        hide-details
        @change="onChangeCheckbox"
        @click.native.prevent="() => null"
      ).ma-0.pa-0
    div.font-weight-medium {{ quote.borrower.firstName }} {{ quote.borrower.lastName }} - {{ quote.borrower.adviser.firstName }}
    div {{ quote.feeNumber }}
    div.text-no-wrap {{ quote.investmentProposalId }}
    div.text-no-wrap {{ quote.currentData.amountDue, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.interest, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.amortization, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.propertyInsurance, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.defaultAmount, getCurrency() | formatCurrency }}
    div.text-no-wrap {{ quote.currentData.penaltyAmount, getCurrency() | formatCurrency }}
    div {{ quote.paymentDate | formatDate }}
    div {{ quote.daysOverdue }}
    div {{ quote.originalFee.expirationDate | formatDate }}
    div
      status-tag(:status="quote.status")
</template>

<script>
import StatusTag from '@/components/UI/quote-status-tag';
import config from '@/config/base';

export default {
  name: 'borrower-quotes-table-item',

  props: {
    quote: { type: Object },
    selected: { type: Boolean, default: false },
  },

  computed: {
    investmentProposalRoute() {
      return {
        name: 'investment.show',
        params: { proposalId: this.quote.investmentProposalId },
      };
    },
  },

  methods: {
    onChangeCheckbox(val) {
      this.$emit(val ? 'select' : 'unselect', this.quote.id);
    },
    getCurrency(){
      return { symbol: `${this.quote.currencyId}` === config.CURRENCIES_SELECT[0].id ? config.CURRENCIES_SELECT[0].symbol : config.CURRENCIES_SELECT[1].symbol };
    },
  },

  components: { StatusTag },
};
</script>

<style lang="sass" scoped>
  .borrower-quotes-table-item
    display: table-row
    text-decoration: none
    color: inherit

    &:hover
      background-color: darken(white, 1%)

    & > div
      display: table-cell
      padding: 6px 8px
      vertical-align: middle

    &__status
      display: inline-block
      padding: 3px 8px
      border-radius: 6px
      color: white
      font-weight: 500

    &--selected
      background-color: transparentize($primaryColor, 0.85) !important
</style>
