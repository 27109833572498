<template lang="pug">
  form-modal(
    :value="value"
    :content-class="b()"
    :width="width"
    @input="args => $emit('input', args)"
  )
    .px-2.pb-3
      .font-weight-medium Duplicar Préstamo
    v-divider.mb-4
    investment-duplicate-proposal-form(
      :class="b('form')"
      :duplicate-proposal="duplicateProposal"
      :currency="currency"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import InvestmentDuplicateProposalForm from '@/components/forms/investment-duplicate-proposal-form';
  import formModalMixin from './form-modal-mixin';

  export default {
    name: 'investment-duplicate-proposal-form-modal',

    props: {
      currency: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'duplicateProposal' })],

    components: { InvestmentDuplicateProposalForm },
  };
</script>

<style lang="sass">
</style>
