<template lang="pug">
  table.buro-table
    thead
      tr
        th(colspan="2") Resumen de cuentas
    tbody
      tr(v-for="(count, title) in groups")
        td {{ count }}
        td {{ title | humanizePreventionKey }}
    tfoot
      tr
        th {{ credits.length }}
        th Cuentas
</template>

<script>
  import humanizePreventionKey from '../../utils/humanize-prevention-key';

  export default {
    name: 'credits-table',

    props: {
      credits: { type: Array, default: () => [] },
    },

    computed: {
      groups() {
        return this.credits
          .reduce(
            (groups, { clavePrevencion }) => ({ ...groups, [clavePrevencion || 'OP']: (groups[clavePrevencion || 'OP'] || 0) + 1 }),
            {}
          );
      },
    },

    filters: {
      humanizePreventionKey(key) {
        return humanizePreventionKey(key);
      },
    },
  };
</script>

<style lang="sass" scoped>
</style>