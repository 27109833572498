<template lang="pug">
  div
    v-layout(wrap)
      div(:class="b('table-column')")
        cards-table(:cards="cards" :total="total")
      div(:class="b('chart-container')")
        cards-chart(:cards="cards" :total="total")
    cards-debt-chart(:cards="cards")
</template>

<script>
  import CardsTable from './credit-cards-table';
  import CardsChart from './credit-cards-chart';
  import CardsDebtChart from './credit-cards-debt-chart';

  export default {
    name: 'credit-cards',

    props: {
      creditCards: { type: Object, default: () => [] },
    },

    computed: {
      cards() {
        return this.creditCards.creditCard || [];
      },

      total() {
        return this.creditCards.total;
      },
    },

    components: { CardsTable, CardsChart, CardsDebtChart },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .credit-cards
    display: flex

    &__table-column
      flex: 1
      padding-bottom: 24px

      +lg
        padding-right: 16px

    &__chart-container
      width: 100%
      max-width: 400px
      margin: 0 auto

      +lg
        width: 400px
        margin: 0

      +xl
        width: 500px
</style>