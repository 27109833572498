<template lang="pug">
  extra-features-modal(
    title="Requisitos obligatorios"
    :value="value"
    :features="requirements"
    @input="args => $emit('input', args)"
  )
</template>

<script>
  import ExtraFeaturesModal from './extra-features-modal';

  export default {
    name: 'requirements-modal',

    props: {
      value: { type: Boolean, default: false },
      requirements: { type: Array, default: () => [] },
    },

    components: { ExtraFeaturesModal },
  };
</script>
