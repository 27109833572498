export default ({ itemsKey = 'items' } = {}) => ({
	data() {
		return {
			selectedIds: [],
		};
	},

	computed: {
		areAllItemsSelected() {
			return Boolean(this.selectedIds.length && this.selectedIds.length === this[itemsKey].length);
		},
	},

	methods: {
		onSelectItem(id) {
			this.selectedIds.push(id);
		},

    onUnselectItem(id) {
			this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
    },

		onSelectAllItems() {
			this.selectedIds = this[itemsKey].map(({ id }) => id);
		},

		onUnselectAllItems() {
			this.selectedIds = [];
		},
	},

  watch: {
    selectedIds(selectedIds) {
      this.$emit('update:selected-ids', selectedIds);
    },
  },
});