<template lang="pug">
  thead
    tr
      th #
      th Fecha#[br]Venc.
      th Saldo#[br]Inicial
      th Principal
      th(v-if="showColumn('interest')") Interés
      th(v-if="showColumn('insurancesProperty')") Seguros#[br]Inm.
      th(v-if="showColumn('insurancesLife')") Seguros#[br]Desg.
      th(v-if="showColumn('defaultsAndPenalties')") Mora y#[br]Penali.
      th(v-if="showColumn('managementFee')") Gastos#[br]Admin.
      th(v-if="showColumn('fee')") Cuota
      th(v-if="showColumn('totalPaid')") Total#[br]Abonado
      th(v-if="showColumn('totalAmortization')") Total#[br]Amortizado
      th Saldo final
      th Fecha#[br]Pago
      th Estado
</template>

<script>
export default {
  name: 'borrower-statement-table-head',

  props: {
    totals: { type: Object },
  },

  methods: {
    showColumn(columnName) {
      const value = this.totals[columnName];

      if (!value && value !== 0) return false;

      const numericValue = typeof value === 'string'
        ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
        : parseFloat(value);

      return Boolean(!isNaN(numericValue) && numericValue !== 0);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
