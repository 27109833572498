<!-- eslint-disable no-console -->
<template lang="pug">
  v-dialog(
    :value="value"
    width="800px"
    @input="args => $emit('input', args)"
  )
    v-card.pa-4
      v-form
        v-container
          v-row
            v-col(cols="12" class="text-center mt-4")
              h1 Formulario de verificación: {{ name }}
          v-row
            v-col(cols="6" v-if="mainCustomers" class="text-left").pa-1
              p.mb-0
                b.pr-2 Clientes principales:
                span {{ mainCustomers }}
            v-col(cols="6" v-if="mainVendors" class="text-left").pa-1
              p.mb-0
                b.pr-2 Proveedores principales:
                span {{ mainVendors }}
            v-col(cols="6" v-if="yearsInSameBuilding" class="text-left").pa-1
              p.mb-0
                b.pr-2 Años en el mismo local:
                span {{ yearsInSameBuilding }}
            v-col(cols="6" v-if="fullTimeEmployees" class="text-left").pa-1
              p.mb-0
                b.pr-2 Empleados de tiempo completo:
                span {{ fullTimeEmployees }}
            v-col(cols="12" v-if="topSales" class="text-left").pa-1
              p
                b.pr-2 Productos más vendidos:
                div(v-if="isValidJSON(topSales)")
                  v-data-table(
                    :headers=[
                      { text: 'Nombre', value: 'name' },
                      { text: 'Precio', value: 'price' },
                      { text: 'Cantidad', value: 'qty' },
                    ]
                    :items="JSON.parse(topSales)"
                    :items-per-page="5"
                    class="elevation-1"
                  )
                span(v-else) {{ topSales }}
          v-row
            v-col(cols="12" class="text-left")
              h2(style="margin-bottom: 1rem;") Preguntas y respuestas cualitativas
              v-row.custom-row
                v-col(cols="6" v-for="(item, index) in questionsResponses" :key="index").pa-1
                  p.mb-0
                    b.pr-2 {{ item.qualitativeQuestion }}:
                    span {{item.qualitativeResponse }}
                v-col(cols="12" v-if="qualitativeScore").pa-1
                  p.mb-0.highlighted
                    b.pr-2 Record cualitativo:
                    span {{ qualitativeScore }}
                v-col(cols="12" v-if="qualitativeLevel").pa-1
                  p.mb-0.highlighted
                    b.pr-2 Nivel cualitativo:
                    span {{ qualitativeLevel }}
            v-col(cols="6" class="text-left")
                h2(style="margin-bottom: 1rem;") Ventas declaradas
                v-row.custom-row
                  v-col(cols="12" v-if="dailySales").pa-1
                    p.mb-0
                      b.pr-2 Diarias:
                      span {{ dailySales | formatCurrency }}
                  v-col(cols="12" v-if="weeklyAmount").pa-1
                    p.mb-0
                      b.pr-2 Semanales:
                      span {{ weeklyAmount | formatCurrency }}
                  v-col(cols="12" v-if="monthlySales").pa-1
                    p.mb-0
                      b.pr-2 Mensuales:
                      span {{ monthlySales | formatCurrency }}
            v-col(cols="6" class="text-left")
                h2(style="margin-bottom: 1rem;") Ventas estimadas
                v-row.custom-row
                  v-col(cols="12" v-if="netIncomeDailyMonthly").pa-1
                    p.mb-0
                      b.pr-2 Neto diario:
                      span {{ netIncomeDailyMonthly | formatCurrency }}
                  v-col(cols="12" v-if="netIncomeWeeklyMonthly").pa-1
                    p.mb-0
                      b.pr-2 Neto semanal:
                      span {{ netIncomeWeeklyMonthly | formatCurrency }}
                  v-col(cols="12" v-if="netIncomeMonthly").pa-1
                    p.mb-0
                      b.pr-2 Neto mensual:
                      span {{ netIncomeMonthly | formatCurrency }}
                  v-col(cols="12" v-if="netIncomeTopProducts").pa-1
                    p.mb-0
                      b.pr-2 Más vendidos:
                      span {{ netIncomeTopProducts | formatCurrency }}
                  v-col(cols="12" v-if="netIncomeDailyEstimateMin").pa-1
                    p.mb-0.highlighted
                      b.pr-2 Ingreso neto diario:
                      span {{ netIncomeDailyEstimateMin | formatCurrency }}
                      b.px-2 -
                      span {{ netIncomeDailyEstimateMax | formatCurrency }}
            v-col(cols="12" v-if="videoCallDriveUrl" class="text-left")
              p
                a(:href="videoCallDriveUrl" target="_blank") Video llamada (Drive URL)
            v-col(cols="12" v-if="videoUrlS3" class="text-left")
              p
                a(:href="videoUrlS3" target="_blank") Video (URL)

            v-col(cols="6" class="text-left")
              p
                b.pr-2 Licencia:
                span(v-if="businessLicense" style="color: green") &#10004;
                span(v-else style="color: red") &#10008;
            v-col(cols="6" class="text-left")
              p.mb-0
                b.pr-2 Mostró libro de ventas:
                span(v-if="salesBookCheck" style="color: green") &#10004;
                span(v-else style="color: red") &#10008;

</template>

<script>

import BusinessVerificationQuery from './graphql/queries.gql';
import iziToast from 'izitoast';

export default {
  name: 'create-risk-form-income-modal',

  props: {
    value: { type: Boolean, default: false },
    id: { type: String, default: '' },
  },

  data() {
    return {
      name: '',
      mainCustomers: '',
      mainVendors: '',
      videoCallDriveUrl: '',
      videoUrlS3: '',
      yearsInSameBuilding: 0,
      fullTimeEmployees: 0,
      monthlySales: 0,
      weeklyAmount: 0,
      dailySales: 0,
      topSales: 0,
      businessLicense: false,
      salesBookCheck: false,
      netIncomeMonthly: 0,
      netIncomeDailyMonthly: 0,
      netIncomeTopProducts: 0,
      netIncomeWeeklyMonthly: 0,
      netIncomeDailyEstimateMin: 0,
      netIncomeDailyEstimateMax: 0,
      qualitativeScore: 0,
      qualitativeLevel: '',
      questionsResponses: [],
    };
  },

  methods: {
    async load() {
      try {
        const response = await this.$apollo.query({
          query: BusinessVerificationQuery,
          variables: { id: this.id },
        });

        const { businessVerification } = response.data;

        this.name = businessVerification[0].name;
        this.mainCustomers = businessVerification[0].mainCustomers;
        this.mainVendors = businessVerification[0].mainVendors;
        this.videoCallDriveUrl = businessVerification[0].videoCallDriveUrl;
        this.videoUrlS3 = businessVerification[0].videoUrlS3;
        this.yearsInSameBuilding = businessVerification[0].yearsInSameBuilding;
        this.fullTimeEmployees = businessVerification[0].fullTimeEmployees;
        this.monthlySales = businessVerification[0].monthlySales;
        this.weeklyAmount = businessVerification[0].weeklyAmount;
        this.dailySales = businessVerification[0].dailySales;
        this.topSales = businessVerification[0].topSales;
        this.businessLicense = businessVerification[0].businessLicense;
        this.salesBookCheck = businessVerification[0].salesBookCheck;
        this.netIncomeMonthly = businessVerification[0].netIncomeMonthly;
        this.netIncomeDailyMonthly = businessVerification[0].netIncomeDailyMonthly;
        this.netIncomeTopProducts = businessVerification[0].netIncomeTopProducts;
        this.netIncomeWeeklyMonthly = businessVerification[0].netIncomeWeeklyMonthly;
        this.netIncomeDailyEstimateMin = businessVerification[0].netIncomeDailyEstimateMin;
        this.netIncomeDailyEstimateMax = businessVerification[0].netIncomeDailyEstimateMax;
        this.qualitativeScore = businessVerification[0].qualitativeScore;
        this.qualitativeLevel = businessVerification[0].qualitativeLevel.response;
        this.questionsResponses = businessVerification[0].questionsResponses;
      } catch (error) {
        iziToast.error({
          message: 'No se pudo cargar la verificación del negocio',
        });
      }
    },

    isValidJSON(json) {
      try {
        JSON.parse(json);
        return true;
      } catch (e) {
        return false;
      }
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.load();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass" scoped>
  .highlighted
    background-color: #e7f0fd 
    border: 1px solid #c2d3ea 
    border-radius: 4px
    padding: 0.2rem 
    display: inline-block 
    margin-right: 0.2rem 
    margin-bottom: 0.2rem

  .custom-row
    margin-left: 0px
</style>

