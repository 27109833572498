<template lang="pug">
  v-dialog(
    :value="value"
    @input="args => $emit('input', args)"
    width="570"
  )
    .card
      div(:class="b('header')")
        img(:class="b('logo')" :src="$projectConfig.COMPANY_LOGO").image
        div(:class="b('title')") {{ count }} {{ title }}
        i(
          :class="b('close-icon')"
          class="rtc-icon rtc-close"
          @click="$emit('input', false)"
        )
      div(:class="b('body')")
        features(:features="features")
</template>

<script>
  import Features from './extra-features-list';

  export default {
    name: 'extra-features-modal',

    props: {
      value: { type: Boolean, default: false },
      title: { type: String },
      features: { type: Array, default: () => [] },
    },

    computed: {
      count() {
        return this.features.length;
      },
    },

    components: { Features },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .card
    background-color: white
    border-radius: 8px

  .extra-features-modal
    &__header
      position: relative
      padding: 51px 0 20px
      border-bottom: 3px solid darken(white, 4%)
      text-align: center

    &__close-icon
      position: absolute
      top: 37px
      right: 27px

      font-size: 16px

      color: $primaryColor

      cursor: pointer

    &__logo
      height: 48px
      margin-left: auto
      margin-right: auto
      margin-bottom: 21px

    &__title
      font-size: 16px
      font-weight: 500
      text-align: center

      +lg
        font-size: 18px

    &__body
      padding: 28px 23px 36px 17px

      +lg
        padding: 32px 60px 41px 59px
</style>