<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      v-tabs(v-model="currentTab")
        v-tab Datos Generales
        v-tab(v-if="hasIncomes") Ingresos
        v-tab(v-if="hasThirdDeclared") Declaración 3ra
        v-tab(v-if="hasResRus") RES/RUS
        v-tab-item
          tax-report-contributor-table(:contributor-info="report.contributorInfo")
        v-tab-item(v-if="hasIncomes")
          tax-report-incomes-tab(:incomes="report.incomes")
        v-tab-item(v-if="hasThirdDeclared")
          tax-report-third-declared-tab(
            :yearly-third-rows="report.yearlyThirdRows"
            :info-monthly-rows="report.infoMonthlyRows"
          )
        v-tab-item(v-if="hasResRus")
          tax-report-essalud(
            :essalud="report.essaludRows"
            :yearly-income-by-reg-rows="report.yearlyIncomeByRegRows"
          )
</template>

<script>
import TaxReportContributorTable from './tax-report-contributor-table';
import TaxReportIncomesTab from './tax-report-incomes-tab'
import TaxReportEssalud from './tax-report-essalud';
import TaxReportThirdDeclaredTab from './tax-report-third-declared-tab';

export default {
  name: 'tax-report',

  props: {
    report: { type: Object },
  },

  data() {
    return {
      currentTab: 0,
    };
  },

  computed: {
    hasIncomes() {
      return Boolean(this.report?.incomes?.declared?.length);
    },

    hasThirdDeclared() {
      return Boolean(this.report?.yearlyThirdRows?.length) || Boolean(this.report?.infoMonthlyRows?.length);
    },

    hasResRus() {
      return (this.report?.essaludRows || [])
        .some(({ netIncomes, sales }) => Boolean(netIncomes) || Boolean(sales))
        || Boolean(this.report?.yearlyIncomeByRegRows?.length);
    },
  },

  components: {
    TaxReportContributorTable,
    TaxReportIncomesTab,
    TaxReportEssalud,
    TaxReportThirdDeclaredTab,
  },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .tax-report
    &__container
      max-width: 1300px
      margin: 0 auto

    &__years
      &-item
        background-color: darken(white, 2%)
        padding: 4px

        &-title
          font-size: 16px
          text-align: center
          font-weight: bold
          color: darken(white, 45%)

    +lg
      &__years
        display: flex

        &-item
          width: 50%
          border-radius: 6px

          &:first-child
            border-right: 1px solid darken(white, 12%)
</style>

<style lang="sass">
  .tax-report
    &__title
      margin-bottom: 12px
      text-align: center
      font-size: 22px
      font-weight: bold
      color: #b3b3b3
</style>