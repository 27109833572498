<template lang="pug">
  div(:class="b()")
    investment-proposals-list-item(
      :class="b('item')"
      v-for="investmentProposal in investmentProposals"
      :key="investmentProposal.id"
      :investment-proposal="investmentProposal"
      :selected="investmentProposal.id === selectedId"
      @click="args => $emit('click-investment-proposal', args)"
    )
</template>

<script>
import InvestmentProposalsListItem from './investment-proposals-list-item';

export default {
  name: 'investment-proposals-list',

  props: {
    investmentProposals: { type: Array, default: () => [] },
    selectedId: { type: [Number, String] },
  },

  components: { InvestmentProposalsListItem },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposals-list
    display: flex
    padding: 12px
    margin: -12px
    overflow-x: auto

    &__item
      width: 270px
      margin-right: 12px
      flex-shrink: 0

</style>