<template lang="pug">
  div(:class="b()" :style="styles") {{ count }}
</template>

<script>
  export default {
    name: 'count-badge',

    props: {
      count: { type: Number },
      color: { type: String },
    },

    computed: {
      styles() {
        return {
          backgroundColor: this.color,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .count-badge
    display: flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    margin: 0 .25rem
    color: white
    font-size: 12px
    font-weight: 500
    border-radius: 50%
</style>