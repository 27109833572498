import { render, staticRenderFns } from "./property-form.vue?vue&type=template&id=982cb412&scoped=true&lang=pug"
import script from "./property-form.vue?vue&type=script&lang=js"
export * from "./property-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "982cb412",
  null
  
)

export default component.exports