import debounce from 'lodash/debounce';

export default () => ({
  props: {
    defaultValues: { type: Object },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    onChangeData: debounce(function() {
      this.$emit('update-answers', { data: this.data })
    }, 3000),
  },

  watch: {
    data: {
      deep: true,

      handler(values) {
        if(values) {
          this.$nextTick(() => {
            this.onChangeData();
          });
        }
      },
    },
  },
});