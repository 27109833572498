<template lang="pug">
  div(:class="b()")
    v-layout(:class="b('container')" wrap)
      v-flex(xs12 md4 lg3 :class="b('filters-box')")
        simulator-filters-form(
          :requesting="simulatorIsMutating"
          @submit="onSubmitSimulatorFilters"
        )
      v-flex(xs12 md8 lg9 :class="b('proposals-box')")
        div(:class="b('proposals-container')" ref="proposalsContainer")
          loading-wrapper(v-if="simulatorIsMutating")
          proposals(
            v-else
            :proposals="this.proposals"
            :requesting="simulatorIsMutating"
            :can-disapprove="false"
            :can-approve="false"
            :can-activate="false"
            :can-deactivate="false"
            :show-schedule="false"
          )
</template>

<script>
import $ from 'jquery';
import currentUserMixin from '@/mixins/current-user-mixin/current-user-mixin';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import { SimulatorGenerateProposalsMutation } from './graphql/mutations.gql';
import Proposals from '@/components/lists/proposals';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';

import SimulatorFiltersForm from './components/simulator-filters-form.vue';

export default {
  name: 'simulator-view',

  mixins: [mutationsHandlersMixin(), currentUserMixin()],

  props: {},

  data() {
    return {
      proposals: [],
      simulatorIsMutating: false,
    };
  },

  methods: {
    onSubmitSimulatorFilters({ filters }) {
      const hasCustomArguments = Object.values(filters.customArguments).some(val => !!val);

      this.handleUpdateMutation({
        mutation: SimulatorGenerateProposalsMutation,

        variables: {
          ...filters,
          userId: this.currentUser.id,
          customArguments: hasCustomArguments ? filters.customArguments : undefined,
        },

        requestingStateKey: 'simulatorIsMutating',
      }).then(({ data: { generateProposals } }) => {
        if (generateProposals?.proposals)
          this.proposals = generateProposals.proposals;
      });

      this.$nextTick(() => {
        $('html, body').animate({ scrollTop: window.innerHeight }, 300);
      });
    },
  },

  components: {
    SimulatorFiltersForm,
    Proposals,
    LoadingWrapper,
  },
};
</script>

<style lang="sass" scoped>
@import '~@/sass/mixins'

.simulator-view
  &__filters-box
    padding: 16px
    border-bottom: 2px solid darken(white, 8%)

  &__proposals-box
    padding: 16px

  +md
    height: 100%

    &__container
      height: 100%

    &__proposals-box
      padding-top: 64px

    &__proposals-container
      margin: 0 auto
      max-width: 500px

    &__filters-box
      padding: 24px 16px 0px 16px
      height: 100%
      border-bottom: none
      border-right: 2px solid darken(white, 8%)

  +lg
    &__proposals-container
      max-width: 900px

    &::v-deep
      .proposals-item
        padding: 16px
        border-radius: 6px
        box-shadow: 1px 1px 8px #bdb6b6
        margin-bottom: 18px

      .my-2.v-divider
        display: none

</style>
