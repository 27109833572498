<template lang="pug">
  div(:class="b({ loading: isLoading })")
    loading-wrapper(
      v-if="$apollo.queries.deals.loading && !deals || dealsError"
      :error="dealsError"
      style="padding-top: 8rem; padding-bottom: 8rem"
      @retry="onRetryDeals"
    )
    template(v-else)
      div(:class="b('controls')")
        div
          b Entradas:&nbsp;
          per-page-select(
            :class="b('per-page-select')"
            v-model="perPage"
            hide-details
          ).ml-2
        .spacer
        div #[b.mr-1 Resultados: ] {{ deals.count }} Negociaciones #[b.ml-1 ({{ total }})]
      table(:class="b('table')")
        table-head(
          :order-by="viewFilters.orderBy"
          :order-flow="viewFilters.orderFlow"
          @order="onOrder"
        )
        tbody
          table-row(
            v-for="deal in deals.objects"
            :key="deal.id"
            :deal="deal"
          )
      .text-center
        v-pagination(
          v-if="deals.pages > 1"
          v-model="currentPage"
          :length="deals.pages"
          :total-visible="15"
        )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';
  import PerPageSelect from '@/components/inputs/per-page-select';

  import TableRow from './table-row';
  import TableHead from './table-head';
  import { DealsQuery } from '../../graphql/queries.gql';

  export default {
    name: 'table-view',

    props: {
      filters: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        currentPage: 1,
        perPage: 20,
        deals: null,
        dealsError: null,
        viewFilters: {
          orderBy: null,
          orderFlow: null,
        },
      };
    },

    apollo: {
      deals: {
        query: DealsQuery,

        fetchPolicy: 'cache-and-network',

        variables() {
          return {
            page: this.currentPage,
            perPage: this.perPage,
            ...this.filters,
            ...this.viewFilters,
          };
        },
      },
    },

    methods: {
      onOrder({ key, flow }) {
        this.viewFilters.orderBy = key;
        this.viewFilters.orderFlow = flow;
      },

      onRetryDeals() {
        this.dealsError = null;
        this.$apollo.queries.deals.refetch();
      },
    },

    computed: {
      total() {
        if(!this.deals?.total?.length) return;

        return this.$options.filters.formatCurrency(this.deals.total[0].amount, this.deals.total[0].currency);
      },

      isLoading() {
        return !this.deals || this.$apollo.queries.deals.loading;
      },
    },

    watch: {
      filters() {
        this.currentPage = 1;
      },
    },

    components: {
      LoadingWrapper,
      PerPageSelect,
      TableHead,
      TableRow,
    },
  };
</script>

<style lang="sass" >
  @import '~@/sass/mixins'

  .table-view
    $c: &

    &__controls
      padding-bottom: 12px

      +sm
        display: flex
        align-items: center

      & > div
        display: flex
        align-items: center
        padding: 4px 0

    &__per-page-select
      max-width: 160px
      padding-top: 0
      margin-top: 0

    &__table
      min-width: 100%
      margin-bottom: 1rem

      border-collapse: collapse
      background-color: white

      thead
        tr
          background-color: darken(white, 6%)
          th
            padding: .75rem 1.5rem

      tbody
        & > *
          border-bottom: 1px solid darken(white, 20%)

          cursor: pointer

          & > *
            padding: .5rem 1.5rem

    &--loading
      tbody
        opacity: 0.4
</style>