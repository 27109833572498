<template lang="pug">
  tbody
    borrower-statement-table-row(
      v-for="entry in entries"
      :key="entry.id"
      :entry="entry"
      :currency="currency"
      :totals="totals"
    )
</template>

<script>
import BorrowerStatementTableRow from './borrower-statement-table-row';

export default {
  name: 'borrower-statement-table-body',

  props: {
    entries: { type: Array, default: () => [] },
    currency: { type: Object },
    totals: { type: Object },
  },

  components: { BorrowerStatementTableRow },
};
</script>

<style lang="sass" scoped>

</style>