<template lang="pug">
  div(:class="b()" v-if="investmentProposals.length > 0")
    investment-proposals-list(
      :class="b('list')"
      :investment-proposals="investmentProposals"
      :selected-id="selectedInvestmentProposal.id"
      @click-investment-proposal="onClickInvestmentProposal"
    )
    investment-proposals-detail(
      :class="b('detail')"
      :investment-proposal="selectedInvestmentProposal"
    )
</template>

<script>
import InvestmentProposalsList from './investment-proposals-list';
import InvestmentProposalsDetail from './investment-proposals-detail';

export default {
  name: 'investment-proposals',

  data() {
    return {
      selectedInvestmentProposalIndex: 0,
    };
  },

  props: {
    investmentProposals: { type: Array, default: () => [] },
  },

  methods: {
    onClickInvestmentProposal({ investmentProposal }) {
      this.selectedInvestmentProposalIndex = this.investmentProposals.findIndex((cProposal) => cProposal.id === investmentProposal.id);
    },
  },

  computed: {
    selectedInvestmentProposal() {
      return this.investmentProposals?.[this.selectedInvestmentProposalIndex];
    },
  },

  components: { InvestmentProposalsList, InvestmentProposalsDetail },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposals
    &__list
      margin-bottom: 24px

</style>