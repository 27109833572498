<template lang="pug">
  div(:class="b()")
    user-avatar(
      :class="b('avatar')"
      :first-name="user.firstName"
      :last-name="user.lastName"
      :url="user.avatar"
      :size="36"
    )
    div(:class="b('info')")
      div(:class="b('name')") {{ user.firstName }} {{ user.lastName }}
      div(:class="b('label')") {{ label }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';

  export default {
    name: 'user-info',

    props: {
      user: { type: Object, required: true },
      label: { type: String, required: true },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass" scoped>
  .user-info
    display: flex

    &__avatar
      margin-right: 12px

    &__name
      font-weight: 500

    &__label
      font-size: 11px
      color: #6e6e6e
</style>