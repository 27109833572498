<template lang="pug">
  tbody
    tr(v-for="item in items" :key="item.name")
      td {{ item.name }}
      td.text-center {{ item.number | formatNumber }}
      td.text-center {{ item.conversionFromPreviousStage }}
      td.text-center {{ item.conversionFromTopFunnel }}
      td.text-center {{ item.creditVolume }}
</template>

<script>
  export default {
    name: 'general-table-body',

    props: {
      items: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>