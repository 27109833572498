<template lang="pug">
  v-tabs(v-model="currentTab")
    template(v-for="buro in buros")
      v-tab {{ buro.title }}
    template(v-if="customerResult && isSentinelEnabled()")
      v-tab(@click="getBuroV2(true)") Sentinel
    template(v-if="variables")
      v-tab(v-if="variables") Variables de Riesgos
    template(v-if="customerReports")
      v-tab(v-if="customerReports.incomeReports && customerReports.incomeReports.length") Reporte ingresos (Titular)
      v-tab(v-if="customerReports.taxReport") Reporte tributario (Titular)
      v-tab(v-if="hasRetentionReport(customerReports)") Reporte Retenciónes (Titular)
      v-tab(v-if="hasDeclarationReport(customerReports)") Reporte Declaraciones (Titular)
    template(v-if="coupleReports")
      v-tab(v-if="coupleReports.incomeReports && coupleReports.incomeReports.length") Reporte ingresos (Pareja)
      v-tab(v-if="coupleReports.taxReport") Reporte tributario (Pareja)
      v-tab(v-if="hasRetentionReport(coupleReports)") Reporte Retenciónes (Pareja)
      v-tab(v-if="hasDeclarationReport(coupleReports)") Reporte Declaraciones (Pareja)
    template(v-for="buro in buros")
      v-tab-item
        buro(:buro="buro.results")
    template(v-if="customerResult && isSentinelEnabled()")
      v-tab-item
        v-tabs
          v-tab(v-if="customerResult" @click="getBuroV2(true)") Consulta Rápida (Titular)
          v-tab(v-if="customerResult && customerResult?.couple" @click="getBuroV2(false)") Consulta Rápida (Pareja)
          v-tab(v-if="customerResult" @click="getBuroV2(true)") Aval (Titular)
          v-tab(v-if="customerResult && customerResult?.couple" @click="getBuroV2(false)") Aval (Pareja)
          v-tab-item(v-if="customerResult")
            loading-wrapper(v-if="!buroV2 && !buroV2Error" @retry="buroV2(true)")
            sentinel(:buroV2="buroV2 || {}")
          v-tab-item(v-if="customerResult && customerResult?.couple")
            loading-wrapper(v-if="!buroV2Couple && !buroV2CoupleError" @retry="buroV2(false)")
            sentinel(:buroV2="buroV2Couple || {}")
          v-tab-item(v-if="customerResult")
            loading-wrapper(v-if="!buroV2 && !buroV2Error" @retry="buroV2(true)")
            sentinel(:buroV2="buroV2 || {}" guarantor=true)
          v-tab-item(v-if="customerResult && customerResult?.couple")
            loading-wrapper(v-if="!buroV2Couple && !buroV2CoupleError" @retry="buroV2(false)")
            sentinel(:buroV2="buroV2Couple || {}" guarantor=true)
    template(v-if="variables")
      v-tab-item(v-if="variables")
        risk-variables-report(:report="variables")
    template(v-if="customerReports")
      v-tab-item(v-if="customerReports.incomeReports && customerReports.incomeReports.length")
        incomes-report(:incomes="customerReports.incomeReports")
      v-tab-item(v-if="customerReports.taxReport")
        tax-report(:report="customerReports.taxReport")
      v-tab-item(v-if="hasRetentionReport(customerReports)")
        retention-report(:report="customerReports.retentionReport")
      v-tab-item(v-if="hasDeclarationReport(customerReports)")
        declaration-report(:report="customerReports.declarationReport")
    template(v-if="coupleReports")
      v-tab-item(v-if="coupleReports.incomeReports && coupleReports.incomeReports.length")
        incomes-report(:incomes="coupleReports.incomeReports")
      v-tab-item(v-if="coupleReports.taxReport")
        tax-report(:report="coupleReports.taxReport")
      v-tab-item(v-if="coupleReports.retentionReport")
        retention-report(:report="hasRetentionReport(coupleReports)")
      v-tab-item(v-if="hasDeclarationReport(coupleReports)")
        declaration-report(:report="coupleReports.declarationReport")
</template>

<script>
import Buro from '@/components/UI/buro';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import TaxReport from './reports/tax-report';
import IncomesReport from './reports/incomes';
import RetentionReport from './reports/retention-report';
import DeclarationReport from './reports/declaration-report';
import RiskVariablesReport from './reports/risk-variables-report';
import Sentinel from '../../../customers/show/components/sentinel/sentinel';
import { CustomerQuery } from '../../../customers/show/graphql/queries.gql';
import Config from '@/config';

export default {
  name: 'buro-tabs',

  props: {
    buros: { type: Array, default: () => [] },
    customerReports: { type: Object },
    coupleReports: { type: Object },
    variables: { type: Object },
    variablesCouple: { type: Object },
    customerId: { type: String },
  },

  data() {
    return {
      currentTab: null,
      buroV2: null,
      buroV2Couple: null,
      buroV2Error: null,
      buroV2CoupleError: null,
      customerResult: null,
      // Errors
      customerError: null,
    };
  },

  apollo: {

    customer: {
      query: CustomerQuery,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',

      variables() {
        return {
          id: this.customerId,
        };
      },

      result({ data, loading }) {
        if (data?.customer) this.customerResult = data.customer;
        else if (!loading)
          this.customerError = new Error(
            'El cliente que estás buscando no existe',
          );
      },

      error() {
        this.customerError = new Error(
          'Ha ocurrido un error al obtener los datos',
        );
      },
    },
  },

  methods: {
    hasRetentionReport(customer) {
      return customer?.retentionReport?.rows?.length;
    },

    hasDeclarationReport(customer) {
      return customer?.declarationReport?.rows?.length;
    },

    async getBuroV2(titular) {
      if (titular) {
        this.buroV2 = this.variables?.sentinelData;
      } else {
        this.buroV2Couple = this.variablesCouple?.sentinelData;
      }
    },

    isSentinelEnabled() {
      return Config.SENTINEL_ENABLED;
    }
  },

  components: {
    Buro,
    Sentinel,
    LoadingWrapper,
    TaxReport,
    IncomesReport,
    RetentionReport,
    DeclarationReport,
    RiskVariablesReport
  },
};
</script>

<style lang="sass" scoped>

</style>