import VTextField from 'vuetify/lib/components/VTextField';

import DatePicker from '@/components/inputs/input-date-picker';
import ProductSelect from '@/components/inputs/product-select';
import DynamicFilters from '@/components/inputs/dynamic-filters';
import BooleanSelect from '@/components/inputs/boolean-select';

const FIELD_TYPES = [
  {
    key: 'active',
    name: 'Estado',
    component: BooleanSelect,
    additionalProps() {
      return {
        trueText: 'Activo',
        falseText: 'Inactivo',
      };
    },
    parseSubmit: status => ({
      text: status.value ? 'Activo' : 'Inactivos',
      value: status.value,
    }),
  },
  {
    key: 'productSlug',
    name: 'Producto',
    component: ProductSelect,
    parseSubmit: product => ({
      text: product.text,
      value: product.value,
      meta: { product },
    }),
  },
  { key: 'createdFrom', name: 'Creado desde', component: DatePicker },
  { key: 'createdTo', name: 'Creado hasta', component: DatePicker },
  { key: 'ruledFrom', name: 'Dictaminado desde', component: DatePicker },
  { key: 'ruledTo', name: 'Dictaminado hasta', component: DatePicker },
  { key: 'userName', name: 'Cliente', component: VTextField },
  { key: 'userVat', name: 'DNI', component: VTextField },
  { key: 'userEmail', name: 'Correo', component: VTextField },
];

export default {
  name: 'filters',

  functional: true,

  render(h, context) {
    return h(
      DynamicFilters,
      {
        props: {
          ...context.props,
          fieldTypes: FIELD_TYPES,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
