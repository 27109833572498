<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Ingresos de {{ customer.firstName }} {{ customer.lastName }}

    div(:class="b('incomes')" v-for="income in customer.incomes" :key="income.id")
      div(:class="b('data-row')")
        div(:class="b('data-row-title')") Categoría:
        div(:class="b('data-row-value')") {{ income.type.category }} ({{ income.type.description }})

      div(:class="b('data-row')")
        div(:class="b('data-row-title')") Descripción:
        div(:class="b('data-row-value')") {{ income.description }}

      div(:class="b('data-row')")
        div(:class="b('data-row-title')") Fecha de Inicio:
        div(:class="b('data-row-value')") {{ income.startAt | formatDate }}

      div(:class="b('data-row')")
        div(:class="b('data-row-title')") Posición:
        div(:class="b('data-row-value')") {{ income.position }}

      incomes-form-step-column-by-months(v-if="income.byMonths && income.byMonths.length" :months="income.byMonths")

      div(:class="b('workplace')" v-if="income.workPlace")
        div(:class="b('data-row')")
          div(:class="b('data-row-title')") Nombre:
          div(:class="b('data-row-value')") {{ income.workPlace.name }}

        div(:class="b('data-row')")
          div(:class="b('data-row-title')") Dirección:
          div(:class="b('data-row-value')") {{ income.workPlace.address | address }}

        div(:class="b('data-row')")
          div(:class="b('data-row-title')") {{ $t('workPlaceVat') }}:
          div(:class="b('data-row-value')") {{ income.workPlace.vat }}

        div(:class="b('data-row')")
          div(:class="b('data-row-title')") Teléfono:
          div(:class="b('data-row-value')") {{ income.workPlace.phone }}

        div(:class="b('data-row')")
          div(:class="b('data-row-title')") Correo:
          div(:class="b('data-row-value')") {{ income.workPlace.email }}

      div
        documents-select(
          v-model="value[`${income.id}`]"
          :customerId="customer.id"
          :error-messages="errors"
          name="documentos"
          label="Documentos"
        )

      div(:class="b('amounts')")
        div(:class="b('amount')")
          div(:class="b('amount-value')") {{ income.amount | formatCurrency(income.currency) }}
          div(:class="b('amount-title')") Brutos

        div(:class="b('amount')")
          div(:class="b('amount-value')") {{ income.netAmount | formatCurrency(income.currency) }}
          div(:class="b('amount-title')") Netos


</template>

<script>
import IncomesFormStepColumnByMonths from './incomes-form-step-column-by-months';
import addressToString from '@/utils/address-to-string';
import DocumentsSelect from '@/components/inputs/documents-select';

export default {
  name: 'incomes-form-step-column',

  props: {
    customer: { type: Object },
    errors: { type: Array },
    value: { type: Object },
  },

  filters: {
    address: addressToString,
  },

  components: { IncomesFormStepColumnByMonths, DocumentsSelect },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .incomes-form-step-column
    max-width: 600px
    margin: 0 auto

    &__title
      margin-bottom: 8px
      text-align: center
      font-size: 19px
      color: darken(white, 35%)
      font-weight: bold

    &__workplace
      padding: 12px
      background-color: darken(white, 3%)
      border-radius: 6px

    &__incomes
      padding-bottom: 8px

    &__data-row, &__amounts, &__amount
      display: flex

    &__data-row
      &-title
        font-weight: bold
        margin-right: 4px

    &__amounts
      margin-top: 2px
      justify-content: flex-end

    &__amount
      align-items: center
      margin-left: 12px

      &-value
        margin-right: 4px

        font-size: 21px
        font-weight: 500

    +xs
      &__amounts
        display: block
        text-align: right
</style>