<template lang="pug">
  tfoot
    tr
      td(colspan="3") Total
      td {{ totals.deals }}
      td {{ totals.pctTotalDeals }}
      td {{ totals.users }}
      td {{ totals.cnvUserToDeal }}
      td {{ totals.investment }}
      td {{ totals.costPerDeal }}
</template>

<script>
  export default {
    name: 'table-by-source-footer',

    props: {
      totals: { type: Object },
    },
  };
</script>

<style lang="sass" scoped>

</style>