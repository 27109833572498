<template lang="pug">
  div
    v-card.pa-2
      v-breadcrumbs(
        :items="breadcrumbItems"
      )
    v-container(fluid)
      loading-wrapper(
        v-if="!customer || customerError"
        :error="customerError"
        @retry="onRetryCustomer"
      )
      v-layout(v-else wrap)
        v-flex(xs12)
          customer-card(
            ref="customerCard"
            :customer="customer"
            :profile-link="false"
            :requesting="customerIsMutating"
            :can-delete="currentUser && currentUser.isSuperuser"
            :can-change-adviser="currentUser && currentUser.isSuperuser"
            :can-change-sensible-data="currentUser && currentUser.isSuperuser"
            @update="onUpdateCustomer"
            @delete="onDeleteCustomer"
            extended
          )
        v-flex(xs12 md7 lg8)
          v-layout(wrap)
            v-flex(xs12 lg6)
              active-products-card(
                ref="activeProductsCard"
                :requesting="dealIsMutating"
                :active-products="customer.activeProducts"
                :available-products="customer.availableProducts"
                :customer="customer"
                @create-deal="onCreateDeal"
              )
            v-flex(xs12 lg6)
              deals-history-card(
                :deals="historyDeals.objects"
              )
            v-flex(xs12).px-2
              v-card
                v-tabs(v-model="currentTab")
                  v-tab Propuestas
                  v-tab Documentos
                  v-tab(v-if="$projectConfig.RCI_ENABLED") Riesgos
                  v-tab(@click="getInvestmentProposals") Préstamos
                  v-tab(v-if="isSentinelEnabled()" @click="getBuroV2") Sentinel
                  v-tab-item.pa-3
                    deals(
                      ref="proposalsCard"
                      :deals="activeDeals.objects"
                      :properties="customer.properties"
                      :incomes="customer.incomes"
                      :requesting="proposalIsMutating"
                      :requesting-simulator="simulatorIsMutating"
                      :can-disapprove="canDisapproveProposals"
                      :max-months="customer.maxMonthsStrategy"
                      @update="onUpdateProposal"
                      @simulate="onSimulateProposals"
                      @approve="onApproveProposal"
                      @disapprove="onDisapproveProposal"
                      @enable="onEnableProposal"
                      @disable="onDisableProposal"
                      @generate-commercial-file="onClickGenerateCommercialFile"
                    )
                    v-alert(
                      v-if="!activeDeals.objects.length"
                      :value="true"
                      type="info"
                      outlined
                    ) Aún no hay propuestas para este cliente
                  v-tab-item.pa-3
                    v-tabs(v-model="currentDocumentsTab")
                      v-tab Documentos Titular
                      v-tab(v-if="customer && customer.couple") Documentos Pareja
                      v-tab-item
                        customer-documents(
                          :customer-id="customerId"
                          ref="documents"
                        )
                      v-tab-item
                        customer-documents(
                          :customer-id="customer && customer.couple && customer.couple.id"
                          ref="documents"
                        )
                  v-tab-item(v-if="$projectConfig.RCI_ENABLED").pa-3
                    debts(
                      :customer="customer"
                      :couple="customer.couple"
                      @change-rci="onChangeRci"
                    )
                    risk-file-validations-state(
                      v-if="canSeeRiskFileValidations && rciValidations.length"
                      :validations="rciValidations"
                    )
                  v-tab-item.pa-3
                    investment-proposals(:investmentProposals="investmentProposals.objects || []")
                  v-tab-item.pa-3(v-if="isSentinelEnabled()")
                    v-tabs
                      v-tab(v-if="customer" @click="getBuroV2(true)") Consulta Rápida (Titular)
                      v-tab(v-if="customer && customer.couple" @click="getBuroV2(false)") Consulta Rápida (Pareja)
                      v-tab(v-if="customer" @click="getBuroV2(true)") Aval (Titular)
                      v-tab(v-if="customer && customer.couple" @click="getBuroV2(false)") Aval (Pareja)
                      v-tab-item(v-if="customer")
                        loading-wrapper(v-if="!buroV2" @retry="buroV2(true)")
                        sentinel(:buroV2="buroV2 || {}")
                      v-tab-item(v-if="customer && customer.couple")
                        loading-wrapper(v-if="!buroV2Couple" @retry="buroV2(false)")
                        sentinel(:buroV2="buroV2Couple || {}")
                      v-tab-item(v-if="customer")
                        loading-wrapper(v-if="!buroV2" @retry="buroV2(true)")
                        sentinel(:buroV2="buroV2 || {}" guarantor=true)
                      v-tab-item(v-if="customer && customer.couple")
                        loading-wrapper(v-if="!buroV2Couple" @retry="buroV2(false)")
                        sentinel(:buroV2="buroV2Couple || {}" guarantor=true)

        v-flex(xs12 md5 lg4)
          properties(
            ref="properties"
            v-if="customer && customer.properties"
            :properties="customer.properties"
            :customer="customer"
            :requesting="propertyIsMutating"
            :requesting-credit="creditIsMutating"
            :fill-risk="isFillingRisk"
            @delete="onDeleteProperty"
            @create="onCreateProperty"
            @create-credit="onCreateCredit"
            @update="onUpdateProperty"
            @update-credit="onUpdateCredit"
            @delete-credit="onDeleteCredit"
            @add-electronic-record="onCreatePropertiesCertificates"
          )
          incomes(
            ref="incomes"
            v-if="customer && customer.incomes"
            :customer="customer"
            :requesting="incomeIsMutating"
            @create="onCreateIncome"
            @update="onUpdateIncome"
            @delete="onDeleteIncome"
          )
          incomes(
            ref="coupleIncomes"
            v-if="customer && customer.couple"
            :customer="customer.couple"
            :requesting="incomeIsMutating"
            @create="onCreateIncome"
            @update="onUpdateIncome"
            @delete="onDeleteIncome"
            is-couple
          )
</template>

<script>
import Config from '@/config';
import $update from 'immutability-helper';

import { GenerateCommercialFileMutation } from '@/graphql/mutations.gql';
import { PERMISSIONS } from '@/utils/constants';
import Debts from '@/components/smart/debts';
import activeDealsMixin from '@/mixins/active-deals-mixin';
import currentUserMixin from '@/mixins/current-user-mixin';
import customerIncomesMixin from '@/mixins/customer-incomes-mixin';
import customerPropertiesMixin from '@/mixins/customer-properties-mixin';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
import rciValidationsMixin from '@/mixins/rci-validations-mixin';
import CustomerDocuments from '@/components/smart/customer-documents';
import RiskFileValidationsState from '@/components/UI/risk-file-validations-state';

import { CustomerQuery, HistoryDealsQuery, InvestmentProposalStatementQuery } from './graphql/queries.gql';
import { PEBuroQuery } from '../../../components/smart/debts/graphql/queries.gql';

import {
  CreateDealMutation,
  UpdateCustomerMutation,
  UpdateProposalMutation,
  DeleteCustomerMutation,
  GenerateProposalsMutation,
  ApproveProposalMutation,
  DisapproveProposalMutation,
} from './graphql/mutations.gql';
import Cards from './components/cards';
import Deals from './components/deals';
import CustomerCard from './components/customer-card';
import DealsHistoryCard from './components/deals-history-card';
import ActiveProductsCard from './components/active-products-card';
import InvestmentProposals from  './components/investment-proposals/investment-proposals';
import Sentinel from './components/sentinel/sentinel';
import { IS_PE_COUNTRY } from '@/utils/country-checker';


const makeProposalReplaerObject = ({ newProposal }) => ({
  deals: {
    objects: {
      $apply: deals =>
        deals.map(cDeal =>
          cDeal.id !== newProposal.deal.id
            ? cDeal
            : {
                ...cDeal,
                proposals: cDeal.proposals.map(cProposal =>
                  cProposal.id !== newProposal.id ? cProposal : newProposal,
                ),
              },
        ),
    },
  },
});

export default {
  name: 'customers-show',

  mixins: [
    mutationsHandlersMixin(),
    currentUserMixin(),
    customerPropertiesMixin({
      getQueryMethod: 'getCurrentQuery',
      beforeUpdate() {
        if (!this.isFillingRisk) return;

        this.isFillingRisk = false;
        this.$refs.properties.closeForm();
        this.onSimulateProposals(this.lastSimulatingProps);
      },
    }),
    customerIncomesMixin({ getQueryMethod: 'getCurrentQuery' }),
    activeDealsMixin(),
    rciValidationsMixin(),
  ],

  apollo: {
    customer: {
      query: CustomerQuery,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',

      variables() {
        return {
          id: this.customerId,
        };
      },

      result({ data, loading }) {
        if (data?.customer) this.customer = data.customer;
        else if (!loading)
          this.customerError = new Error(
            'El cliente que estás buscando no existe',
          );
      },

      error() {
        this.customerError = new Error(
          'Ha ocurrido un error al obtener los datos',
        );
      },
    },

    historyDeals: {
      query: HistoryDealsQuery,
      manual: true,
      fetchPolicy: 'cache-and-network',

      variables() {
        return { customerId: this.customerId };
      },

      result({ data }) {
        if (!data?.deals) return;

        this.historyDeals = data?.deals;
      },
    },
  },

  data() {
    return {
      historyDeals: { objects: [] },
      dealsRefetchTimeout: null,
      currentRci: null,
      // Errors
      customerError: null,
      // Modals & Tabs
      currentTab: 0,
      riskCurrentTab: 0,
      currentDocumentsTab: 0,
      // Mutating States
      customerIsMutating: false,
      creditIsMutating: false,
      proposalIsMutating: false,
      dealIsMutating: false,
      simulatorIsMutating: false,
      authorizeNipIsMutating: false,
      // Supportk
      isFillingRisk: false,
      lastSimulatingProps: null,
      investmentProposals: { objects: [] },
      buroV2: null,
      buroV2Couple: null,
    };
  },

  computed: {
    customerId() {
      return this.$route.params.customerId;
    },

    productSlug() {
      return this.customer?.activeProducts?.[0]?.product?.slug;
    },

    breadcrumbItems() {
      const customer = this?.customer;

      return [
        { text: 'Clientes', to: { name: 'customers.list' }, exact: true },
        {
          text: `${customer?.firstName || ''} ${customer?.lastName || ''}`,
          disabled: true,
        },
      ];
    },

    acceptedProposals() {
      return (this.activeDeals?.objects || [])
        .reduce((proposals, deal) => [...proposals, ...deal.proposals], [])
        .filter(({ acceptedAt }) => acceptedAt);
    },

    acceptedProposal() {
      return Boolean(this.acceptedProposals.length);
    },

    canDisapproveProposals() {
      return this.currentUserHasDjangoPermissions([
        'authentication.update_proposals',
        'authentication.change_proposals_accepted_datetime',
      ]);
    },

    canSeeRiskFileValidations() {
      return this.currentUserHasPermissions([PERMISSIONS.AUTH.INTERNAL]);
    },
  },

  components: {
    ActiveProductsCard,
    Cards,
    CustomerCard,
    CustomerDocuments,
    Deals,
    DealsHistoryCard,
    Debts,
    LoadingWrapper,
    RiskFileValidationsState,
    InvestmentProposals,
    Sentinel,
  },

  methods: {
    onUpdateCustomer({ customer }) {
      this.handleUpdateMutation({
        mutation: UpdateCustomerMutation,

        variables: { customer },

        query: this.getCurrentQuery(),

        updateQueryFn: ({
          resultsData: {
            updateCustomer: { customer: updatedCustomer },
          },
        }) =>
          updatedCustomer
            ? {
                customer: { $set: updatedCustomer },
              }
            : null,

        requestingStateKey: 'customerIsMutating',
      }).then(this.$refs.customerCard.closeForm);
    },

    onUpdateProposal({ proposal }) {
      this.handleUpdateMutation({
        mutation: UpdateProposalMutation,

        requestingStateKey: 'proposalIsMutating',

        variables: { data: { ...proposal } },

        query: this.getActiveDealsQuery(),

        updateQueryFn: ({
          resultsData: {
            updateProposal: { proposal: updatedProposal },
          },
        }) =>
          updatedProposal
            ? makeProposalReplaerObject({
                newProposal: updatedProposal,
              })
            : null,
      }).then(this.$refs.proposalsCard.closeForm);
    },

    onApproveProposal({ proposal }) {
      this.handleUpdateMutation({
        mutation: ApproveProposalMutation,

        variables: { id: proposal.id },

        query: this.getActiveDealsQuery(),

        updateQueryFn: ({
          resultsData: {
            approveProposal: { proposal: approvedProposal },
          },
        }) =>
          approvedProposal
            ? makeProposalReplaerObject({
                newProposal: approvedProposal,
              })
            : null,
      });
    },

    onDisapproveProposal({ proposal }) {
      this.handleUpdateMutation({
        mutation: DisapproveProposalMutation,

        variables: { id: proposal.id },

        query: this.getActiveDealsQuery(),

        updateQueryFn: ({
          resultsData: {
            disapproveProposal: { proposal: disapprovedProposal },
          },
        }) =>
          disapprovedProposal
            ? makeProposalReplaerObject({
                newProposal: disapprovedProposal,
              })
            : null,
      });
    },

    onDisableProposal({ proposal }) {
      this.onUpdateProposal({ proposal: { id: proposal.id, active: false } });
    },

    onEnableProposal({ proposal }) {
      this.onUpdateProposal({ proposal: { id: proposal.id, active: true } });
    },

    onSimulateProposals({ filters, dealId }) {
      this.lastSimulatingProps = { filters, dealId };

      this.handleUpdateMutation({
        mutation: GenerateProposalsMutation,

        variables: {
          ...filters,
          customerId: this.customer.id,
        },

        requestingStateKey: 'simulatorIsMutating',

        query: this.getActiveDealsQuery(),

        updateQueryFn: ({
          resultsData: {
            generateProposals: { proposals: newProposals },
          },
        }) =>
          newProposals
            ? {
                deals: {
                  objects: {
                    $apply: deals =>
                      deals.map(cDeal =>
                        cDeal.id !== dealId
                          ? cDeal
                          : { ...cDeal, proposals: newProposals },
                      ),
                  },
                },
              }
            : null,
      }).then(
        ({
          data: {
            generateProposals: { risk, errors },
          },
        }) => {
          this.$refs.proposalsCard.closeFiltersForm();

          if (
            filters.productSlug === 'home_equity_loan' &&
            risk === false &&
            !errors &&
            IS_PE_COUNTRY
          ) {
            window.alert(
              'Se requieren algunos datos del inmueble antes de continuar',
            );

            this.isFillingRisk = true;
            this.$refs.properties.onClickEdit(this.customer.properties[0]);
          }
        },
      );
    },

    onCreateDeal({ product }) {
      this.handleCreateMutation({
        mutation: CreateDealMutation,

        variables: {
          productSlug: product.slug,
          customerId: this.customer.id,
          motive: product.motive,
        },

        query: this.getActiveDealsQuery(),

        update: (
          store,
          {
            data: {
              createDeal: { deal },
            },
          },
        ) => {
          const customerQueryData = store.readQuery(this.getCurrentQuery());
          const dealsQueryData = store.readQuery(this.getActiveDealsQuery());

          store.writeQuery({
            ...this.getCurrentQuery(),

            data: $update(customerQueryData, {
              customer: {
                activeProducts: {
                  $push: [
                    {
                      dealId: deal.id,
                      product: deal.product,
                      stage: 'proposal',
                      credit: null,
                      currency: null,
                      __typename: 'ActiveProduct',
                    },
                  ],
                },
              },
            }),
          });

          store.writeQuery({
            ...this.getActiveDealsQuery(),

            data: $update(dealsQueryData, {
              deals: {
                objects: {
                  $unshift: [deal],
                },
              },
            }),
          });
        },

        requestingStateKey: 'dealIsMutating',
      });

      this.$refs.activeProductsCard.closeForm();
    },

    async onDeleteCustomer({ customer }) {
      this.$apollo.mutate({
        mutation: DeleteCustomerMutation,
        variables: { id: customer.id },
      });

      window.location.href = this.$baseURL('/customers');
    },

    onClickGenerateCommercialFile() {
      this.handleUpdateMutation({
        mutation: GenerateCommercialFileMutation,

        variables: { customerId: this.customer.id },
      }).then(() => {
        window.alert(
          'El archivo esta siendo generado y sera enviado a tu correo electronico',
        );
      });
    },

    onRetryCustomer() {
      this.customerError = null;
      this.$apollo.queries.customer.refetch();
    },

    onChangeRci(rciData) {
      this.currentRci = rciData;
    },

    getCurrentQuery() {
      return {
        query: CustomerQuery,
        variables: { id: this.customerId },
      };
    },

    async getInvestmentProposals() {
      this.$apollo.query({
        query: InvestmentProposalStatementQuery,
        variables: {
          borrowerIds: [this.customerId],
        },
      }).then(
        ({ data }) => {
          this.investmentProposals = data.investmentProposals;
        },
      ).catch(error => {
        this.investmentProposalsError = new Error('Ha ocurrido un error al obtener los datos') || error;
      });
    },

    async getBuroV2(titular) {
      this.$apollo.query({
        query: PEBuroQuery,
        variables: {
          userId: titular ? parseInt(this.customerId) : parseInt(this.customer.couple.id),
          country: 'PE',
          vat: titular ? this.customer.vat : this.customer.couple.vat,
          docTypeId: titular ? this.customer.vatType.id : this.customer.couple.vatType.id,
          full: true,
          reportType: 'sentinel',
        },
      }).then(
        ({ data }) => {
          if (titular) {
            this.buroV2 = data.buro.data;
          } else {
            this.buroV2Couple = data.buro.data;
          }
        },
      ).catch(error => {
        if (titular)
          this.buroV2Error = new Error('Ha ocurrido un error al obtener los datos') || error;
        else
          this.buroV2CoupleError = new Error('Ha ocurrido un error al obtener los datos') || error;
      });
    },

    isSentinelEnabled() {
      return Config.SENTINEL_ENABLED;
    }
  },

  watch: {
    customerId(oldCustomerId, newCustomerId) {
      if (oldCustomerId !== newCustomerId) this.customer = null;
    },
  },
};
</script>

<style lang="sass">
.v-window__container--is-active
  height: auto !important
</style>
