import _get from 'lodash/get';
import _has from 'lodash/has';

/** Configs */
import base from './base';
import co from './co';
import pe from './pe';
import mx from './mx';
import mx_loanco from './mx_loanco';

const locales = {
  co,
  pe,
  mx,
  mx_loanco,
};

const data = { ...base, };

/**
 * Usamos object.assign debido a que usar object spread hace que intellisense no funcione
 */
Object.assign(data, _get(locales, process.env.VUE_APP_LOCALE || 'pe', {}));

export default new Proxy(data, {
  get(target, key) {
    if(!_has(target, key)) throw new Error(`Key <${key}> not found on config file, this requires an urgent fix`);

    return target[key];
  }
});
