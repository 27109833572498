export default () => ({
	props: {
		allSelected: { type: Boolean },
	},

	methods: {
		onChangeSelectAllCheckbox(val) {
			if(val) this.$emit('select-all');
			else this.$emit('unselect-all');
		},
	},
});