<template lang="pug">
  div(:class="b()")
    view-header
    loading-wrapper(
      v-if="!investmentProposal || !schedule"
    )
    template(v-else)
      proposal-card(
        :investment-proposal="investmentProposal"
        :simple-fee="schedule.simpleFee"
        :double-fee="schedule.doubleFee"
        :tea="schedule.tea"
        :trea="schedule.trea"
        :is-rescheduled="isRescheduled"
      )
      investment-summary-card(
        :schedule="schedule"
        :investment-proposal="investmentProposal"
        :currency="investmentProposal.currency"
      )
      div(:class="b('print-actions')")
        v-btn(color="primary" text @click="onClickPrint")
          span Imprimir&nbsp;
          v-icon mdi-printer
      schedule-card(
        :schedule="schedule"
        :investment-proposal="investmentProposal"
        :currency="investmentProposal.currency"
      )
</template>

<script>
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import MortgageScheduleInvestmentBorrowerView from '../investment-borrower/mortgage-schedule-investment-borrower-view';
  import {
    InvestorScheduleQuery,
    InvestorRescheduleQuery,
    InvestorScheduleInvestmentProposalQuery
  } from './graphql/queries.gql';
  import pickFees from '../utils/pick-fees';
  import ProposalCard from './components/proposal-card';
  import ScheduleCard from './components/schedule-card';
  import InvestmentSummaryCard from './components/investment-summary-card';

  export default {
    name: 'mortgage-schedule-investor-view',

    extends: MortgageScheduleInvestmentBorrowerView,

    apollo: {
      schedule: {
        query() {
          return this.isRescheduled ? InvestorRescheduleQuery : InvestorScheduleQuery;
        },

        variables() {
          return {
            investmentProposalId: this.investmentProposalId,
            balloonPct: this.balloonPct,
            feeNumber: this.feeNumber,
          };
        },

        update({ schedule }) {
          if(!schedule) return null;

          return {
            ...schedule,
            ...pickFees(schedule.schedule),
          };
        },

        skip() {
          return !this.investmentProposalId;
        }
      },

      investmentProposal: {
        query: InvestorScheduleInvestmentProposalQuery,

        variables() {
          return { id: this.investmentProposalId };
        },

        update({ investmentProposals }) {
          const investmentProposal = investmentProposals?.objects?.[0];

          return investmentProposal || null;
        },

        skip() {
          return !this.investmentProposalId;
        }
      },
    },

    components: {
      ProposalCard,
      ScheduleCard,
      LoadingWrapper,
      InvestmentSummaryCard,
    },

    mounted() {
      document.title = 'Cronograma RebajaTusCuentas';
    }
  };
</script>

<style lang="sass">
  .mortgage-schedule-investor-view
    padding: 16px 32px
    max-width: 1200px
    margin: 0 auto
    font-size: 14px

    &__title
      margin: 8px 0
      text-align: center

    &__print-actions
      text-align: right
</style>

<style lang="sass">
  body, html, .application--wrap
    background-color: white !important

  .data-card
    margin-bottom: 16px

    &__title, &__body
      background-color: darken(white, 1%)

    &__title
      display: inline-block
      padding: 6px 24px
      font-size: 17px
      font-weight: 500
      border-radius: 6px 6px 0 0
      background-color: darken(white, 4%)

    &__body
      padding: 12px 24px
      border-radius: 0 6px 6px 6px

  @media print
    .dates-form, .mortgage-schedule-investor-view__print-actions
      display: none

    body
      -webkit-print-color-adjust: exact
</style>