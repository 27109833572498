<template lang="pug">
  metrics-layout
    filters(
      slot="filters"
      :filters="filters"
      @submit="onSubmitFilters"
    )
    div(:class="b()")
      loading-wrapper(v-if="$apollo.queries.metrics.loading || !metrics")
      template(v-else)
        bonds-summary(:summary="metrics.data").mb-4
        v-card
          bonds-chart(
            :entries="metrics"
            :parse-value="parseCurrencyValue"
            title="Bonos"
          ).mb-2
          div(:class="b('table-container')").mb-2
            bonds-table(
              :entries="metrics"
              :parse-value="parseCurrencyValue"
            )
</template>

<script>
import Config from '@/config';
import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { formatCurrency } from '@/filters/accounting';
import { BondsMetricsQuery } from './graphql/queries.gql';

import BondsTable from './components/bonds-table';
import BondsChart from '../components/borrowers-aums-chart.vue';
import MetricsLayout from '../../metrics/components/metrics-layout';
import BondsSummary from './components/bonds-summary.vue';
import Filters from './components/filters';

export default {
  name: 'bonds-metrics-view',

  data() {
    return {
      metrics: null,
      currentTab: null,

      filters: {
        country: Config.COUNTRY_CODE.toUpperCase(),
        status: [],
        investorIds: [],
        investmentVehicleIds: [],
        fromDate: null,
        toDate: null,
      },
    };
  },

  apollo: {
    metrics: {
      query: BondsMetricsQuery,

      variables() {
        const { country, status, investorIds, investmentVehicleIds, expirationDate } = this.filters;

        return {
          country,
          status: status?.length ? status : undefined,
          investorIds: investorIds?.length ? investorIds : undefined,
          investmentVehicleIds: investmentVehicleIds?.length ? investmentVehicleIds : undefined,
          fromDate: expirationDate?.[0],
          toDate: expirationDate?.[1] || expirationDate?.[0],
        };
      },
    },
  },

  methods: {
    onSubmitFilters({ filters }) {
      if (isEqual({ ...filters }, { ...this.filters })) {
        this.$apollo.queries.metrics.refetch();
      }
      else
        Object.assign(this.filters, filters);
    },

    parseCurrencyValue(val) {
      return val ? formatCurrency(val) : val;
    },
  },

  components: {
    MetricsLayout,
    Filters,
    LoadingWrapper,
    BondsTable,
    BondsChart,
    BondsSummary,
  },
};
</script>

<style lang="sass" scoped>
  .bonds-metrics-view
    &__table-container
      padding: 16px
      overflow-x: auto
</style>
