import Vue from 'vue';
import { DEAL_STATUS } from '@/utils/constants';

export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SWITCH_VIEW_MODE = 'SWITCH_VIEW_MODE';

export const BOARD_VIEW_MODE = 'BOARD_VIEW_MODE';
export const TABLE_VIEW_MODE = 'TABLE_VIEW_MODE';

export const initState = {
  viewMode: BOARD_VIEW_MODE,
  filters: [
    {
      key: 'status',
      value: DEAL_STATUS.ACTIVE,
      text: 'Activa',
    },
  ],
};

export const mutations = {
  [ADD_FILTER](state, filter) {
    state.filters.push(filter);
  },

  [REMOVE_FILTER](state, key) {
    Vue.delete(state.filters, state.filters.findIndex(filter => filter.key === key ));
  },

  [CLEAR_FILTERS](state) {
    state.filters = [];
  },

  [SWITCH_VIEW_MODE](state, mode) {
    state.viewMode = mode;
  },
};

export const actions = {
  addFilter({ commit }, filter) {
    commit(ADD_FILTER, filter);
  },

  removeFilter({ commit }, key)  {
    commit(REMOVE_FILTER, key);
  },

  clearFilters({ commit }) {
    commit(CLEAR_FILTERS);
  },

  switchViewMode({ commit }, mode) {
    commit(SWITCH_VIEW_MODE, mode);
  },
};

export const getters = {
  filters: state => state.filters,
  filtersByValue: state => state.filters.reduce(
    (obj, { key, value }) => ({ ...obj, [key]: value }),
    {},
  ),
  viewMode: state => state.viewMode,
};

export default {
  namespaced: true,

  state: { ...initState },

  actions,

  mutations,

  getters,
};
