<template lang="pug">
  base-dialog(
    :value="value"
    title="Direcciones"
    @input="args => $emit('input', args)"
  )
    div(
      v-for="address in addresses"
      :key="address.id"
      :class="b('address')"
    )
      div(v-if="address.description") #[b Dirección: ] {{ address.description }}
      div(v-if="address.date") #[b Fecha: ] {{ address.date | formatDate }}
      div(v-if="address.phone") #[b Teléfono: ] {{ address.phone }}
      div(v-if="address.annexed") #[b Anexo: ] {{ address.annexed }}
      div(v-if="address.source") #[b Lugar: ] {{ address.source }}
      div(v-if="address.department") #[b Departamento: ] {{ address.department }}
      div(v-if="address.province") #[b Provincia: ] {{ address.province }}
      div(v-if="address.district") #[b Distrito: ] {{ address.district }}
</template>

<script>
  import BaseDialog from './base-dialog';

  export default {
    name: 'address-dialog',

    props: {
      value: { type: Boolean },
      addresses: { type: Array, default: () => [] },
    },

    components: { BaseDialog },
  };
</script>

<style lang="sass" scoped>
  .address-dialog
    &__address
      padding-bottom: 16px

      &:last-child
        padding-bottom: 0
</style>