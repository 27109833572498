<template lang="pug">
  div(:class="b()")
    div(:class="b('content')")
      div(:class="b('body')")
        div(:class="b('title')")
          title-study-status-tag(:status="titleStudy.status").mr-2
          span {{ titleStudy.createdAt | formatDate('D MMMM YYYY') }} - (# {{ titleStudy.electronicRegistrationNumber }})
        div(:class="b('body-content')")
          ul(:class="b('liens-charges')")
            li(
              v-for="chargeLine in titleStudy.lienChargeLines"
              :key="chargeLine.id"
              :class="b('liens-charges-item')"
            ).mb-2
              div #[span.font-weight-medium Carga / Gravamen: ] {{ chargeLine.lienCharge.text }}
              div #[span.font-weight-medium Monto: ] {{ chargeLine.chargeAmount | formatCurrency(chargeLine.currency) }}
              div #[span.font-weight-medium Asiento: ] {{ chargeLine.settle }}
          div(v-if="titleStudy.validator") #[span.font-weight-medium Validado por: ] {{ titleStudy.validator.firstName }} {{ titleStudy.validator.lastName }}
      div(:class="b('actions')")
        v-btn(icon @click="onClickEdit" color="primary").ml-2
          v-icon(@click="onClickEdit") mdi-pencil

    v-alert(v-if="titleStudy.validationResult" type="error" outlined).mt-2
      .font-weight-bold Motivos de Rechazo
      div(v-html="titleStudy.validationResult")
</template>

<script>
import TitleStudyStatusTag from '@/components/UI/title-study-status-tag';

export default {
  name: 'title-studies-list-item',

  props: {
    titleStudy: { type: Object, required: true },
  },

  methods: {
    onClickEdit() {
      this.$emit('click-edit', { titleStudy: this.titleStudy });
    },
  },

  components: { TitleStudyStatusTag },
};
</script>

<style lang="sass" scoped>

  @import '~@/sass/mixins'

  .title-studies-list-item
    &__content
      display: flex
      align-items: center
      padding-bottom: 8px

    &__body
      flex: 1

      &-content
        padding: 4px 16px

    &__title
      font-weight: 500
      color: darken(white, 60%)

</style>