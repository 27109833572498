<template lang="pug">
  div(:class="b()")
    loading-wrapper(
      v-if="loading || error"
      :error="error"
      @retry="args => $emit('retry', args)"
    )
    v-layout(wrap)
      v-flex(xs12 md4 lg3).pa-2
        tachometer(:currentValue="totalReceivedLeads" currentTitle="Leads recibidos" :firstOffset="160" :secondOffset="200" :thirdOffset="240")
      v-flex(xs12 md8 lg6).pa-2
        tachometer-actions(
          currentTitle="Acciones: Llamada, WhatsApp, Email"
          :currentValueValid="totalActionsRegistredCallsValid"
          :firstOffsetValid="40"
          :secondOffsetValid="50"
          :thirdOffsetValid="60"
          :currentValueTotal="totalActionsRegistredCallsTotal"
          :firstOffsetTotal="400"
          :secondOffsetTotal="500"
          :thirdOffsetTotal="600"
        )
      v-flex(xs12 md4 lg3).pa-2
        tachometer(:currentValue="totalActionsRegistredMeetings" currentTitle="Acciones: Reuniones" :firstOffset="16" :secondOffset="20" :thirdOffset="24")
      v-flex(xs12 md4 lg3).pa-2
        line-card(:currentValue="portfolioMoneyGrowth" title="Crecimiento de cartera: $")
      v-flex(xs12 md4 lg3).pa-2
        line-card(:currentValue="portfolioNumberGrowth" title="Crecimiento de cartera: N°")
      v-flex(xs12 md4 lg3).pa-2
        v-layout(wrap)
          v-flex(xs12)
            value-card(:currentMonth="ratioCurrentMonth" :lastThreeMonths="ratioLastThreeMonths" :lastThreeMonthsRTC="ratioLastThreeMonthsRTC" currentTitle="Ratio de leads a clientes")
          v-flex(xs12).pt-2
            value-card(:currentMonth="actionsCurrentMonth"  :lastThreeMonths="actionsLastThreeMonths" :lastThreeMonthsRTC="actionsLastThreeMonthsRTC" currentTitle="Acciones para cerrar un cliente")
      v-flex(xs12 md4 lg3).pa-2
        time-card(:currentValue="timeFromManagementToCreation" currentTitle="Tiempo gestión vs creación")
      v-flex(xs12 md12 lg12).pa-2
        bar-card(:currentValue="ratioSQLToClient" currentTitle="Total aplicaciones de crédito")
    //- v-alert(v-else color="info" outlined) No hay datos
</template>

<script>
  import Tachometer from './tachometer';
  import TachometerActions from './tachometer-actions';
  import ValueCard from './value-card';
  import TimeCard from './time-card';
  import BarCard from './bar-card';
  import LineCard from './line-card';

  export default {
    name: 'executives-panel',

    props: {
      totalReceivedLeads: { type: Number, default: 0 },
      totalActionsRegistredCallsValid: { type: Number, default: 0 },
      totalActionsRegistredCallsTotal: { type: Number, default: 0 },
      totalActionsRegistredMeetings: { type: Number, default: 0 },
      portfolioMoneyGrowth: { type: Array, default: () => [] },
      portfolioNumberGrowth: { type: Array, default: () => [] },
      ratioCurrentMonth: { type: Number, default: 0 },
      ratioLastThreeMonths: { type: Number, default: 0 },
      ratioLastThreeMonthsRTC: { type: Number, default: 0 },
      actionsCurrentMonth: { type: Number, default: 0 },
      actionsLastThreeMonths: { type: Number, default: 0 },
      actionsLastThreeMonthsRTC: { type: Number, default: 0 },
      timeFromManagementToCreation: { type: String, default: '' },
      ratioSQLToClient: { type: Array, default: () => [] },
      loading: { type: Boolean, default: false },
      error: { type: Error },
    },

    components: { Tachometer, TachometerActions, ValueCard, TimeCard, BarCard, LineCard},
  };
</script>

<style lang="sass" scoped>
.executives-panel
  &__button-container
    text-align: center
    padding: 28px 0
</style>