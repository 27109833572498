const colors = {
  PENDING: '#ffc107',
  APPROVED: '#4caf50',
  OBSERVED: '#2196f3',
  REFUSED: '#f44336',
};

const getRiskOpinionStatusColor = status => colors[status.toUpperCase()] || '#696969';

export default getRiskOpinionStatusColor;
