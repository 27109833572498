<template lang="pug">
  div(:class="b()")
    div(:class="b('name')") {{ customer.fullName }}
    div(:class="b('body')")
      div(:class="b('data')")
        b Fecha de Nacimiento:&nbsp;
        span {{ customer.birth }}
      div(:class="b('data')")
        b Género:&nbsp;
        span {{ customer.gender }}
      div(:class="b('data')")
        b Estado Civil:&nbsp;
        span {{ customer.civilStatus }}
</template>

<script>
  export default {
    name: 'customer-card',

    props: {
      customer: { type: Object, required: true },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .customer-card
    background-color: darken(white, 3%)
    padding: 16px
    margin-bottom: 4px

    &__name
      font-size: 22px

    &__body
      +lg
        display: flex

    &__data
      margin-right: 16px
</style>