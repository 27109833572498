<template lang="pug">
  div(:class="b()")
    div(:class="b('payment-column')")
      div(:class="b('data')")
        div(:class="b('data-title')") {{ bond.debtAmount | formatCurrency(currency, { precision: 0 }) }}
        div(:class="b('data-description')") Monto
    div(:class="b('body-column')")
      v-layout(wrap)
        v-flex(xs4 md2)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ bond.quotesQuantity }}
            div(:class="b('small-data-description')") Cuotas
        v-flex(xs4 md2)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ bond.rate }}%
            div(:class="b('small-data-description')") Tasa
        v-flex(xs4 md2)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ bond.irTaxRate }}%
            div(:class="b('small-data-description')") Imp. Renta
        v-flex(xs6 md3)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ bond.endPaymentDate | formatDate }}
            div(:class="b('small-data-description')") Fecha de vencimiento
        v-flex(xs6 md3)
          div(:class="b('small-data')")
            div(:class="b('small-data-title')") {{ bond.disbursementDate | formatDate }}
            div(:class="b('small-data-description')") Fecha de emisión
    div(:class="b('credit-column')")
      div(:class="b('data')")
        div(:class="b('data-title')") {{ bond.totalAmortized | formatCurrency(currency, { precision: 0 }) }}
        div(:class="b('data-description')") Amortizado
    div(:class="b('status-label')" :style="{ backgroundColor: statusColor }") {{ $t(`trustBondStatus.${bond.status}`)}}
</template>

<script>
  import getStatusColor from '@/utils/investment-proposal-status-color';

  export default {
    name: 'bond-card',

    props: {
      bond: { type: Object, required: true },
      currency: { type: Object },
    },

    computed: {
      statusColor() {
        return getStatusColor(this.bond.status);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .bond-card
    position: relative
    padding: 16px
    overflow: hidden
    border-radius: 4px
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

    &__status-label
      position: absolute
      top: 0
      left: 0
      padding: 2px 16px
      text-transform: uppercase
      font-size: 12px
      color: white

    &__dates
      padding-bottom: 18px

    &__credit
      display: flex

    &__data
      text-align: center

      &-title
        font-size: 30px
        font-weight: 500
        white-space: nowrap

      &-description
        font-size: 13px

    &__small-data
      text-align: center
      padding: 3px 8px

      &-title
        font-size: 24px
        font-weight: 500
        white-space: nowrap

      &-description
        font-size: 13px

    +md
      display: flex
      padding: 0

      &__payment-column, &__credit-column
        display: flex
        align-items: center
        padding: 12px 16px
        background-color: $cloudyColor

      &__body-column
        flex: 1
        display: flex
        align-items: center
        padding: 8px 16px

      &__data
        padding: 6px 16px

        &-title
          font-size: 26px

        &-description
          white-space: nowrap

      &__small-data
        padding: 3px 8px

        &-title
          font-size: 18px
          line-height: 1

        &-description
          font-size: 12px

    +lg
      &__data
        &-title
          font-size: 28px

          &--small
            font-size: 21px
</style>