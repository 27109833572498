<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(xs12)
          validation-provider(name="contraseña actual" :rules="'required|min:8'" v-slot="{ errors }")
            v-text-field(
              v-model="data.oldPassword"
              :error-messages="errors"
              name="contraseña actual"
              label="Contraseña Actual"
              type="password"
            )
        v-flex(xs12)
          validation-provider(name="contraseña" :rules="'required|min:8'" v-slot="{ errors }")
            v-text-field(
              ref="password"
              v-model="data.password"
              :error-messages="errors"
              name="nueva contraseña"
              label="Nueva Contraseña"
              type="password"
            )
        v-flex(xs12)
          validation-provider(name="repetir contraseña" :rules="'required|confirmed:contraseña'" v-slot="{ errors }")
            v-text-field(
              v-model="data.confirmPassword"
              :error-messages="errors"
              name="repetir contraseña"
              label="Repetir Contraseña"
              type="password"
            )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar
</template>

<script>
  export default {
    name: 'password-form',

    props: {
      requesting: { type: Boolean },
    },

    data() {
      return {
        data: {
          oldPassword: null,
          password: null,
          confirmPassword: null,
        },
      };
    },

    methods: {
      async onSubmit() {
        try {
          const success = await this.$refs.form.validate();

          if(!success) return;


          this.$emit('submit', {
            oldPassword: this.data.oldPassword,
            newPassword: this.data.password
          });
        } catch {
          return;
        }
      },

      onCancel() {
        this.$emit('cancel');
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>