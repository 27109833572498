<template lang="pug">
  v-expansion-panel-header(:class="b()")
    div(:class="b('content')")
      span(:class="b('title')") {{ title }}
      span(
        v-for="badge, i in badges"
        v-if="badge.count > 0"
        :key="i"
        :class="[b('badge'), badge.color]"
      ) {{ badge.count }}
</template>

<script>
  export default {
    name: 'documents-group-header',

    props: {
      title: { type: String, required: true },
      count: { type: Number, default: 0 },
      pending: { type: Number, default: 0 },
      approved: { type: Number, default: 0 },
      refused: { type: Number, default: 0 },
      underReview: { type: Number, default: 0 },
      icon: { type: String, default: 'mdi-home' },
    },

    computed: {
      badges() {
        return [
          { count: this.pending, color: 'amber'},
          { count: this.underReview, color: 'blue' },
          { count: this.approved, color: 'green' },
          { count: this.refused, color: 'red' },
        ]
      },
    },
  };
</script>

<style lang="sass" scoped>
  .documents-group-header
    &__content
      display: flex
      align-items: center

    &__title
      font-weight: 500

    &__badge
      display: flex
      justify-content: center
      align-items: center
      width: 20px
      height: 20px
      border-radius: 50%
      font-size: .8rem
      font-weight: bold
      text-align: center
      color: white
      margin-left: .5rem
</style>