<template lang="pug">
  table(:class="b()")
    borrower-statement-table-head(:totals="updatedTotals")
    borrower-statement-table-body(:totals="updatedTotals" :entries="entries" :currency="currency")
    borrower-statement-table-footer(:totals="updatedTotals" :currency="currency" v-if="showDetail")
</template>

<script>
import BorrowerStatementTableHead from './borrower-statement-table-head';
import BorrowerStatementTableBody from './borrower-statement-table-body';
import BorrowerStatementTableFooter from './borrower-statement-table-footer';
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

export default {
  name: 'borrower-statement-table',

  props: {
    entries: { type: Array, default: () => [] },
    totals: { type: Object },
    currency: { type: Object },
    investmentProposal: { type: Object },
    logo: { type: Boolean, default: true },
  },
  computed: {
    showDetail() {
      return this.investmentProposal?.status === (INVESTMENT_PROPOSAL_STATUS.COLLECTING || this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED) || !this.logo;
    },
    updatedTotals() {
      const 
        totalManagementFee = this.calculateTotalManagementFee(),
        totalFee = this.calculateTotalfee(),
        totalPaid = this.calculateTotalPaid(),
        totalFinalBalance = this.calculateTotalfinalBalance()
      ;
      return {
        ...this.totals,
        managementFee:totalManagementFee,
        fee:totalFee,
        totalPaid:totalPaid,
        totalFinalBalance:totalFinalBalance,
        finalBalance:totalFinalBalance,
      };
    }
  },

  methods: {
    calculateTotalManagementFee() {
      return this.entries.reduce((sum, entry) => {
        const 
          total = entry.managementFee ?  parseFloat(entry.managementFee) : 0,
          sumaActual = typeof sum === 'number' ? sum : 0
        ;
        return sumaActual + total ;
      });
    },
    calculateTotalfee() {
      return this.entries.reduce((sum, entry) => {
        const 
          total = entry.fee ?  parseFloat(entry.fee) : 0,
          sumaActual = typeof sum === 'number' ? sum : 0
        ;
        return sumaActual + total ;
      });
    },
    calculateTotalPaid() {
      return this.entries.reduce((sum, entry) => {
        const 
          total = entry.totalPaid ?  parseFloat(entry.totalPaid) : 0,
          sumaActual = typeof sum === 'number' ? sum : 0
        ;
        return sumaActual + total ;
      });
    },
    calculateTotalfinalBalance() {
      return this.entries.reduce((sum, entry) => {
        const 
          total = entry.totalPaid ?  parseFloat(entry.totalPaid) : 0,
          sumaActual = typeof sum === 'number' ? sum : 0
        ;
        return sumaActual - total ;
      });
    },
  },

  components: { BorrowerStatementTableHead, BorrowerStatementTableBody, BorrowerStatementTableFooter },
};
</script>

<style lang="sass">

  .borrower-statement-table
    width: 100%
    border-collapse: collapse
    white-space: nowrap

    td, th
      padding: 0 9px

    thead
      text-align: right

    tbody
      text-align: right

      tr
        td
          &:first-child
            width: 20px

        &:nth-child(odd)
          background-color: darken(white, 5%)
</style>
