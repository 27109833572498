<template lang="pug">
  .dashboard-card
    .dashboard-card__header
      .dashboard-card__title {{ currentTitle }}
    .dashboard-card__body
      .dashboard-card__content
        v-layout
          v-flex(xs12 md6 lg6).pa-2
            .dashboard-card__title(style="text-align: center") Válidas
            gauge(
              :start-angle="-90"
              :end-angle="90"
              :value="currentValueValid"
              :min="0"
              :max="secondOffsetValid"
              :separator-step="0"
              :gauge-color="[{ offset: 0, color: '#de3a21'}, { offset: (firstOffsetValid / secondOffsetValid * 80), color: '#f4c009'} , { offset: (secondOffsetValid / secondOffsetValid * 100), color: '#0b8c5a'}, { offset: (thirdOffsetValid / secondOffsetValid * 120), color: '#3498db'}]"
            )
              .inner-text(:style="{ color: innerTextColorValid }")
                span(style="font-size: 1.6em") {{ currentValueValid }}
            .dashboard-card__legend
              .dashboard-card__legend-item(v-for="range in colorsAndTextsValid" :key="range.percentage")
                .dashboard-card__legend-item-value(:style="{ color: range.color }") {{ range.value }}
                .dashboard-card__legend-item-percentage(:style="{ color: range.color }") {{ range.percentage }}
          v-flex(xs12 md6 lg6).pa-2
            .dashboard-card__title(style="text-align: center") Total
            gauge(
              :start-angle="-90"
              :end-angle="90"
              :value="currentValueTotal"
              :min="0"
              :max="secondOffsetTotal"
              :separator-step="0"
              :gauge-color="[{ offset: 0, color: '#de3a21'}, { offset: (firstOffsetTotal / secondOffsetTotal * 80), color: '#f4c009'} , { offset: (secondOffsetTotal / secondOffsetTotal * 100), color: '#0b8c5a'}, { offset: (thirdOffsetTotal / secondOffsetTotal * 120), color: '#3498db'}]"
            )
              .inner-text(:style="{ color: innerTextColorTotal }")
                span(style="font-size: 1.6em") {{ currentValueTotal }}
            .dashboard-card__legend
              .dashboard-card__legend-item(v-for="range in colorsAndTextsTotal" :key="range.percentage")
                .dashboard-card__legend-item-value(:style="{ color: range.color }") {{ range.value }}
                .dashboard-card__legend-item-percentage(:style="{ color: range.color }") {{ range.percentage }}

</template>

<script>
  import { VueSvgGauge as Gauge } from 'vue-svg-gauge';

  export default {
    name: 'tachometer-actions',

    props: {
      currentTitle: { type: String, default: '' },
      currentValueValid: { type: Number, default: 0 },
      currentValueTotal: { type: Number, default: 0 },
      firstOffsetValid: { type: Number, default: 0 },
      firstOffsetTotal: { type: Number, default: 0 },
      secondOffsetValid: { type: Number, default: 0 },
      secondOffsetTotal: { type: Number, default: 0 },
      thirdOffsetValid: { type: Number, default: 0 },
      thirdOffsetTotal: { type: Number, default: 0 },
    },

    methods: {
      innerTextColor(currentValue, firstOffset, secondOffset, thirdOffset) {
        if(currentValue < firstOffset) return '#de3a21'
        else if(currentValue < secondOffset) return '#f4c009'
        else if(currentValue < thirdOffset) return '#0b8c5a'
        else return '#3498db';
      },
      colorsAndTexts(firstOffset, secondOffset, thirdOffset) {
        return [
          { value: "N/A", color: '#de3a21', percentage: '0-79%' },
          { value: firstOffset, color: '#f4c009', percentage: '80-99%' },
          { value: secondOffset, color: '#0b8c5a', percentage: '100-119%' },
          { value: thirdOffset, color: '#3498db', percentage: '+120%' },
        ];
      },
    },

    computed: {

      innerTextColorValid() {
        return this.innerTextColor(this.currentValueValid, this.firstOffsetValid, this.secondOffsetValid, this.thirdOffsetValid);
      },

      innerTextColorTotal() {
        return this.innerTextColor(this.currentValueTotal, this.firstOffsetTotal, this.secondOffsetTotal, this.thirdOffsetTotal);
      },

      colorsAndTextsValid() {
        return this.colorsAndTexts(this.firstOffsetValid, this.secondOffsetValid, this.thirdOffsetValid);
      },

      colorsAndTextsTotal() {
        return this.colorsAndTexts(this.firstOffsetTotal, this.secondOffsetTotal, this.thirdOffsetTotal);
      },
    },

    components: {
      Gauge,
    },
  };
</script>

<style lang="sass" scoped>
.tachometer
  max-width: 400px
  margin: 0 auto

.dashboard-card
  min-height: 32em
  max-height: 800px

.inner-text
  display: flex
  align-items: flex-end
  justify-content: center
  height: 100%
  width: 100%
  font-weight: bold

span
  font-size: 2em

.dashboard-card__legend
  display: flex
  justify-content: space-between
  margin-top: 20px

.dashboard-card__legend-item
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.dashboard-card__legend-item-value
  font-size: 1em

.dashboard-card__legend-item-percentage
  font-size: .8em

</style>