<template lang="pug">
  tr(v-if="quote")
    td.has-text-weight-bold {{ quote.feeNumber }}
    td {{ quote.originalFee.expirationDate | formatDate('D MMM YYYY') }}
    td {{ quoteData.amountDue | formatCurrency(currency, { precision: 2 }) }}
    td {{ quoteData.amortization | formatCurrency(currency, { precision: 2 }) }}
    td {{ quoteData.interest | formatCurrency(currency, { precision: 2 }) }}
    td {{ insurances | formatCurrency(currency, { precision: 2 }) }}
    td {{ totalPenaltyAmount | formatCurrency(currency, { precision: 2 }) }}
    td {{ quote?.paymentDate | formatDate('D MMM YYYY') }}
    td(style="width: 130px")
      div(:class="b('status-tag')" :style="{ backgroundColor: statusColor }") {{ $t(`quoteStatus.${quote.status}`)}}
</template>

<script>
import { INVESTMENT_PROPOSAL_STATUS, QUOTE_STATUS } from '@/utils/constants';
import quoteStatusColor from '@/utils/investment-proposal-quote-status-color';

export default {
  name: 'investment-proposals-detail-quotes-table-row',

  props: {
    quote: { type: Object, required: true },
    currency: { type: Object, required: true },
  },

  computed: {
    statusColor() {
      return quoteStatusColor(this.quote.status);
    },

    isRescheduled() {
      return this.quote.status === QUOTE_STATUS.RESCHEDULED;
    },

    quoteData() {
      return this.isRescheduled ? this.quote.originalFee : this.quote.currentData;
    },

    totalPenaltyAmount() {
      return this.quoteData.penaltyAmount + this.quoteData.defaultAmount;
    },

    insurances() {
      return this.quoteData.lifeInsurance  + this.quoteData.propertyInsurance;
    },

    nextQuote() {
      if(!this.quote || [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED, QUOTE_STATUS.RESCHEDULED].includes(this.quote.status))
        return null;

      return this.quoteData.amountDue;
    },

    currentQuoteCount() {
      return (this.investmentProposal.quotesQuantity - this.investmentProposal.pendingQuotesQuantity) + 1;
    },

    isFinished() {
      return [INVESTMENT_PROPOSAL_STATUS.FINISHED, INVESTMENT_PROPOSAL_STATUS.REFINANCED].includes(this.investmentProposal.status);
    },
  },
};
</script>

<style lang="sass" scoped>
  .investment-proposals-detail-quotes-table-row
    &__status-tag
      padding: 2px 8px
      color: white
      font-weight: 500
      font-size: 12px
      text-align: center
      border-radius: 6px
</style>
