<template lang="pug">
  base-dialog(
    :value="value"
    :width="width"
    :class="b()"
    title="Deudas sin pagar"
    @input="args => $emit('input', args)"
    scrollable
  )
    v-tabs(v-model="currentTab" fixed-tabs)
      v-tab(v-if="hasUnpaidDebts") Resumen deudas
      v-tab(v-if="hasSicomsDebts") Deudas Infocorp
      v-tab(v-if="hasAfpDebts") Deudas AFP
      v-tab(v-if="hasCanceledCards") Tarjetas Canceladas
      v-tab(v-if="hasClosedAccounts") Cuentas Cerradas
      v-tab(v-if="hasOmissions") Omisiones
      v-tab(v-if="hasProtests") Protestos
      v-tab(v-if="hasSunatNegatives") Deudas SUNAT
      v-tab-item.py-3(v-if="hasUnpaidDebts")
        unpaid-debts-tab(:debts="results.resumeUnpaidDebts")
      v-tab-item.py-3(v-if="hasSicomsDebts")
        sicoms-tab(:sicoms="results.sicoms")
      v-tab-item.py-3(v-if="hasAfpDebts")
        afp-debts-tab(:debts="results.afpDebts")
      v-tab-item.py-3(v-if="hasCanceledCards")
        canceled-cards-tab(:cards="results.canceledCards")
      v-tab-item.py-3(v-if="hasClosedAccounts")
        closed-accounts-tab(:accounts="results.closedAccounts")
      v-tab-item.py-3(v-if="hasOmissions")
        omissions-tab(:taxpayers="results.omissions")
      v-tab-item.py-3(v-if="hasProtests")
        protests-tab(:protests="results.protests")
      v-tab-item.py-3(v-if="hasSunatNegatives")
        sunat-negatives-tab(:sunats="results.sunatNegatives")
</template>

<script>
  import SicomsTab from './sicoms-tab';
  import UnpaidDebtsTab from './unpaid-debts-tab';
  import AfpDebtsTab from './afp-debts-tab';
  import CanceledCardsTab from './canceled-cards-tab';
  import ClosedAccountsTab from './closed-accounts-tab';
  import OmissionsTab from './omissions-tab';
  import ProtestsTab from './protests-tab';
  import SunatNegativesTab from './sunat-negatives';
  import BaseDialog from '../base-dialog';

  export default {
    name: 'unpaid-debts-dialog',

    props: {
      value: { type: Boolean },
      width: { type: [Number, String] },
      results: { type: Object, required: true },
    },

    data() {
      return {
        currentTab: false,
      };
    },

    computed: {
      hasUnpaidDebts() {
        return Boolean(this.results?.resumeUnpaidDebts?.length);
      },

      hasSicomsDebts() {
        return Boolean(this.results?.sicoms?.length);
      },

      hasAfpDebts() {
        return Boolean(this.results?.afpDebts?.length);
      },

      hasCanceledCards() {
        return Boolean(this.results?.canceledCards?.length);
      },

      hasClosedAccounts() {
        return Boolean(this.results?.closedAccounts?.length);
      },

      hasOmissions() {
        return Boolean(this.results?.omissions?.length);
      },

      hasProtests() {
        return Boolean(this.results?.protests?.length);
      },

      hasSunatNegatives() {
        return Boolean(this.results?.sunatNegatives?.length);
      },
    },

    components: {
      BaseDialog,
      SicomsTab,
      UnpaidDebtsTab,
      AfpDebtsTab,
      CanceledCardsTab,
      ClosedAccountsTab,
      OmissionsTab,
      ProtestsTab,
      SunatNegativesTab,
    },
  };
</script>

<style lang="sass">
  .debts-tab
    &__debt
      padding-bottom: 16px

      &-title
        color: darken(white, 60%)
        font-weight: 500

      &:last-child
        padding-bottom: 0

      &-entries
        padding-left: 16px
        padding-top: 4px

        &-item
          padding-bottom: 8px

          &:last-child
            padding-bottom: 0
</style>