import { render, staticRenderFns } from "./quotes-card.vue?vue&type=template&id=1b9d685c&scoped=true&lang=pug"
import script from "./quotes-card.vue?vue&type=script&lang=js"
export * from "./quotes-card.vue?vue&type=script&lang=js"
import style0 from "./quotes-card.vue?vue&type=style&index=0&id=1b9d685c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b9d685c",
  null
  
)

export default component.exports