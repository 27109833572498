<template lang="pug">
  v-timeline-item(:class="b('')" :icon="iconName" fill-dot)
    div(:class="b('message')") {{ entry.message }}
    div(:class="b('date')") {{ entry.createdAt | formatDate }} ({{ entry.createdAt | timeAgo }})
</template>

<script>
  const ICONS = {
    DEAL_CREATION: 'mdi-briefcase-plus',
    DEAL_MOVE: 'mdi-content-duplicate',
    OWNER_CHANGED: 'mdi-account-switch',
    REQUIREMENT_IS_DONE: 'mdi-file-document-edit-outline',
    DEAL_NOTE_ADDED: 'mdi-message-plus',
    DEAL_CHANGE_STATUS: 'mdi-label',
  };

  export default {
    name: 'timeline-item',
    
    props: {
      entry: { type: Object, required: true },
    },

    computed: {
      iconName() {
        return ICONS[this.entry.action];
      },
    },
  };
</script>

<style lang="sass" scoped>
  .timeline-item
    &__date
      color: darken(white, 60%)
      font-size: 12px
</style>