<template lang="pug">
  table.buro-table
    thead
      tr
        th Empleador
        th Periodo
        th Concepto
        th Monto de renta
        th Monto de retencion
    tbody
      tr(v-for="income, idx in sortedIncomes" :key="idx")
        td {{ income.employer }}
        td {{ income.period | formatDate('MMMM YYYY') }}
        td {{ income.concept }}
        td {{ income.incomeAmount | formatCurrency }}
        td {{ income.withholdingAmount | formatCurrency }}
</template>

<script>
import { sortBy, pipe } from 'lodash/fp';

export default {
  name: 'incomes-table',

  props: {
    incomes: { type: Array },
  },

  computed: {
    sortedIncomes() {
      return pipe(
        sortBy(['period']),
        results => results.reverse(),
      )(this.incomes);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>