<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Banco
            th Calificación
            th Valor
        tbody
          table-row(v-for="creditAmount, idx in creditsAmount" :key="idx" :credit-amount="creditAmount")
        tfoot
          tr
            th(colspan="2") Total
            td {{ totalAmount | formatCurrency }}
</template>

<script>
  import TableRow from './credits-amount-row';

  export default {
    name: 'credits-amount',

    props: {
      creditsAmount: { type: Array, default: () => [] },
    },

    computed: {
      totalAmount() {
        return this.creditsAmount.reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0);
      },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .credits-amount
    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>