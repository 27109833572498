<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      loading-wrapper(
        v-if="$apollo.queries.categories.loading || categoriesError || !categories"
        :error="categoriesError"
        @retry="onRetryCategoriesFetch"
      )
      categories(v-else :categories="categories || []")
</template>

<script>
import Config from '@/config';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { CategoriesQuery } from './graphql/queries.gql';
import Categories from './components/categories';

export default {
  name: 'library-categories-list-view',

  apollo: {
    categories: {
      query: CategoriesQuery,

      variables() {
        return {
          country: Config.COUNTRY_CODE.toUpperCase(),
        };
      },

      /**
       * Filtramos categories, subcategories y items desactivados
       */
      update({ categories = [] }) {
        return categories.reduce((rCategories, category) => {
          return category.active
            ? [
                ...rCategories,
                {
                  ...category,
                  subcategories: (category.subcategories || []).reduce(
                    (rSubcategories, subcategory) => {
                      return subcategory.active
                        ? [
                            ...rSubcategories,
                            {
                              ...subcategory,
                              items: (subcategory.items || []).filter(
                                ({ active }) => active,
                              ),
                            },
                          ]
                        : rSubcategories;
                    },
                    [],
                  ),
                },
              ]
            : rCategories;
        }, []);
      },

      error() {
        this.categoriesError = new Error(
          'Ha ocurrido un error al obtener los datos',
        );
      },
    },
  },

  data() {
    return {
      categories: [],
      categoriesError: null,
    };
  },

  methods: {
    onRetryCategoriesFetch() {
      this.categoriesError = null;
      this.$apollo.queries.categories.refetch();
    },
  },

  components: { Categories, LoadingWrapper },
};
</script>

<style lang="sass" scoped>
@import '~@/sass/mixins'

.library-categories-list-view
  &__container
    padding: 32px 16px
    max-width: 1400px
    margin: 0 auto
</style>
