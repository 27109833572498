<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    customer-form(
      :customer="customer"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import CustomerForm from '@/components/forms/customer-form';

  export default {
    name: 'customer-form-modal',

    mixins: [formModalMixin({ entityName: 'customer' })],

    components: { CustomerForm },
  };
</script>
