<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table.buro-table
        thead
          tr
            th Periodo
            th Banco
            th Valor
            th Estado
        tbody
          tr(v-for="credit, idx in credits" :key="idx")
            td {{ credit.period | formatPeriod }}
            td {{ credit.bank }}
            td.text-no-wrap S/ {{ credit.value | formatNumber }}
            td {{ credit.status }}
</template>

<script>
  import formatPeriod from '../../../pe-buro-results/filters/format-period';

  export default {
    name: 'vehicular-credits-table',

    props: {
      credits: { type: Array, default: () => [] },
    },

    filters: { formatPeriod },
  };
</script>

<style lang="sass" scoped>
  .vehicular-credits-table
    table
      text-align: center

    &__table-container
      max-width: 700px
      overflow-x: auto
      margin: 0 auto
</style>