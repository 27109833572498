<template lang="pug">
  v-dialog(
    :value="value"
    @input="args => $emit('input', args)"
    width="400"
    persistent
  )
    v-card
      v-card-title.headline ¿Registraste la actividad?
      v-card-text ¿Deseas registrar la actividad que llevaste a cabo para completar el recordatorio? de ya haber sido registrada puedes omitirlo.
      v-card-actions
        v-spacer
        v-btn(
          @click="onClickCancel"
          color="red"
          text
        ) NO
        v-btn(
          @click="onClickAccept"
          color="primary"
          text
        ) SI
</template>

<script>
  export default {
    name: 'base-form-modal',

    props: {
      value: { type: Boolean, default: false },
    },

    methods: {
      onClickCancel() {
        this.$emit('submit', false);
      },

      onClickAccept() {
        this.$emit('submit', true);
      },
    },
  };
</script>
