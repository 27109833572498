<template lang="pug">
	div
		div(style="display: flex; justify-content: space-between; text-align: center;")
			b.col-md-6 Fecha de Proceso: {{debts[0].detail[0].process_date | formatDate }}
			b.col-md-6 Fecha de Reporte: {{debts[0].detail[0].report_date | formatDate }}
		.sentinel-table
			table
				thead
					tr
						th Año
						th Mes
						th Entidad
						th Calificación
						th Saldo Deudor
						th Días Vencidos
				tbody
					template(v-for='item in debts')
						tr(v-for='detail in item?.detail')
							td {{ item?.year }}
							td {{ item?.month }}
							td {{ detail?.name }}
							td {{ detail?.qualification }}
							td {{ detail?.debt }}
							td {{ detail?.due_days }}
</template>

<script>
	export default {
		name: 'sentinel-table-history',
		props: {
			debts: {
				type: Array,
				required: true,
			},
		},
	};
</script>

<style lang="sass" scoped>
.sentinel-table
  max-height: 200px
  overflow-y: scroll
  margin-top: 20px
  display: flex
  justify-content: center
  width: 100%
  border-collapse: collapse
  border: 1px solid #ddd

  table
    width: 100%
    text-align: left

    th, td
      border-bottom: 1px solid #ddd

    th
      background-color: #f2f2f2
</style>
