import buildURL from 'build-url';
import Config from '../config/index';

const builder = base => (path = '', config = {}) => buildURL(base, {
  path,
  ...config,
});

export const baseURL = builder(window.location.origin + Config.BASE_PATH.replace(/\/$/, ''));
export const formURL = builder(Config.FORM_URL);
export const cloudfrontURL = builder(Config.CLOUDFRONT_URL);
