import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';
import { FormalizationOpinionExceptionsSelectQuery } from './queries.gql';

export default {
  name: 'appraisal-exception-select',

  mixins: [lazySelectMixin({ itemsKey: 'formalizationOpinionExceptions' })],

  props: {
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'name' },
  },

  data() {
    return {
      formalizationOpinionExceptions: [],
    };
  },

  apollo: {
    formalizationOpinionExceptions: {
      query: FormalizationOpinionExceptionsSelectQuery,

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  render(h) {
    return h(VSelect, {
      props: {
        ...this.$attrs,
        ...this.$props,
        items: this.lazyItems,
        loading: this.$apollo.queries.formalizationOpinionExceptions.loading,
        multiple: true,
      },
      on: {
        ...this.$listeners,
        focus: this.onFocus,
      },
    });
  },
};
