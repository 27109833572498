import VSelect from 'vuetify/lib/components/VSelect';
import { FEE_TYPE } from '@/utils/constants';

export default {
  name: 'fee-type-select',

  functional: true,

  render(h, context) {
    const items = Object.values(FEE_TYPE).map(feeType => ({
        value: feeType,
        text: context.parent.$t(`feeType.${feeType}`),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};
