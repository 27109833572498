<template lang="pug">
  div(:class="b()")
    requirements-modal(v-model="requirementsModal" :requirements="proposal.requirements")
    benefits-modal(v-model="benefitsModal" :requirements="proposal.benefits")
    div(:class="b('container')")
      div(:class="b('fees')")
        div #[b Cuota simple: ] {{ proposal.simpleFee | formatCurrency(proposal.currency) }}
        div
          b Última cuota#[span(v-if="proposal.balloonPaymentType")  ({{ proposal.balloonPaymentType }}%)]:
          span  {{ proposal.lastFee | formatCurrency(proposal.currency) }}
      div(:class="b('teas')")
        div #[b TEA: ] {{ proposal.tea }} %
        div #[b TCEA: ] {{ proposal.tcea }} %
      div(:class="b('extras')")
        div(@click.prevent="requirementsModal = true") Requisitos ({{ proposal.requirements.length }})
        div(@click.prevent="benefitsModal = true") Beneficios ({{ proposal.benefits.length }})
</template>

<script>
import RequirementsModal from '@/components/lists/proposals/requirements-modal';
import BenefitsModal from '@/components/lists/proposals/benefits-modal';

export default {
  name: 'ghost-simulator-proposals-item',

  props: {
    proposal: { type: Object, required: true },
  },

  data() {
    return {
      requirementsModal: false,
      benefitsModal: false,
    };
  },

  computed: {
    bankLogo() {
      return this.$cloudfrontURL(`/logos/${this.proposal.bank.shortName.toLowerCase()}.svg`)
    },
  },

  components: { RequirementsModal, BenefitsModal },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .ghost-simulator-proposals-item
    padding: 12px 16px

    &__bank-logo
      width: 160px
      margin-right: 18px

      img
        width: 100%
        height: 100%

    &__fees, &__teas, &__extras
      padding-left: 18px
      padding-right: 18px

    &__extras
      color: $primaryColor
      font-weight: 500
      cursor: pointer

    +lg
      padding: 12px 8px

      &__container
        display: flex
        align-items: center

</style>