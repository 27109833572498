<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :persistent="requesting")
    opinion-form(
      v-if="value"
      :opinion="opinion"
      :requesting="requesting"
      :totalIncomesResult="totalIncomesResult"
      :totalExpensesResult="totalExpensesResult"
      :totalExpensesPTIResult="totalExpensesPTIResult"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
    risk-variables-report(:report="variables")
    opinions-list(v-if="opinions.length" :opinions="opinions" :currency="currency")
</template>

<script>
  import formModalMixin from '@/components/modals/form-modal-mixin';

  import OpinionForm from './opinion-form';
  import RiskVariablesReport from './reports/risk-variables-report/risk-variables-report.vue';
  import OpinionsList from './opinions-list';

  export default {
    name: 'opinion-form-modal',

    mixins: [formModalMixin({ entityName: 'opinion' })],

    props: {
      opinions: { type: Array, default: () => [] },
      currency: { type: Object },
      variables: { type: Object },
      totalIncomesResult: { type: Number, default: 0 },
      totalExpensesResult: { type: Number, default: 0 },
      totalExpensesPTIResult: { type: Number, default: 0 },
    },

    components: { OpinionForm, OpinionsList, RiskVariablesReport },
  };
</script>
