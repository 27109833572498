import VSelect from 'vuetify/lib/components/VSelect';

const VALUES = [
  { value: 'DNI', text: 'DNI' },
  { value: 'RUC', text: 'RUC' },
  { value: 'CE', text: 'Carnet de Extranjería' },
];

export default {
  name: 'equifax-vat-type-select',

  extends: VSelect,

  props: {
    items: { type: Array, default: () => VALUES },
  },
};
