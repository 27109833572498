<template>
  <v-dialog v-model="isVisible" max-width="65vw">
    <v-card>
      <v-card-title class="headline">Actualización de Tabla de Deudas</v-card-title>
      <v-card-text>
        <!-- se recibe los datos del objecto modificado -->
        <debts-table :debts="updateDebt" @remove-file2="removeobjDebt3" />  
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addFileDebt" title="Agrega una fila">Agregar</v-btn>
        <v-btn color="primary" @click="updateSelectDebst" title="Confirmar datos">Guardar</v-btn>
        <v-btn color="primary" @click="closePopup" title="Regresar al menu anterior">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import iziToast from 'izitoast';
import DoUpdateRiskFile from '../../graphql/mutations-popup.gql';
import DebtsTable from './debts-table.vue';

export default {
  name: 'PopupTable',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    riksFile: {
      type: Object,
      default: () => [],
    },
  },

  data() {
    return {
      //Modificación de datos componente Hijo
      updateDebt:[], 
      //Modificación de datos componente Padre
      objDebt:this.riksFile?.riskAssessmentRequest.personalStep.selectedDebts || [], 
    }
  },

  methods: {
    closePopup() {
      this.$emit('close');
    },

    addFileDebt() {
      this.updateDebt.push({ bank:'', product:'', qualification:'', totalAmount:0, amount:0});
    },

    removeobjDebt3(index) {
      this.updateDebt.splice(index, 1);
    },

    updateSelectDebst() {
      this.riksFile.riskAssessmentRequest.personalStep.selectedDebts = this.updateDebt;
      // Validar que el monto a pagar no sea mayor a la deuda antes de registrar los datos
      if (!this.updateDebt.some(item => item.totalAmount < item.amount)) {
          this.$apollo.mutate({
            mutation: DoUpdateRiskFile,
            variables:{
              id:this.riksFile.id,
              riskAssessmentRequest: this.riksFile.riskAssessmentRequest,
            },
          }).then(res => {
            if(res.data.updateRiskFile.status === 'UPDATED') {
              iziToast.success({
                message: 'Datos actualizados correctamente',
              });
            }
          }).catch(error => {
            iziToast.error({
                message: error,
            });
          });
      } else {
        iziToast.error({
          message: 'No se realizo la actualización de datos ya que unos de los montos a pagar es mayor a la deuda',
        });
      }
    }, 
  },

  mounted() {
    // Ordena los importes de por la columna amount de mayor a menor
    this.objDebt.sort((a, b) => b.amount - a.amount);
  },

  watch:{
    isVisible(val) {
      if (val) {
        // Código para actualizar el objDebt cuando el popup se abre y refrescar los ultimos datos del objecto riskFile
        this.updateDebt= this.objDebt.map(({amount,bank,product,qualification,totalAmount}) => ({amount,bank,product,qualification,totalAmount})).sort((a, b) => b.amount - a.amount);
      }
    },
  },
  
  components: {
    DebtsTable,
  },
}
</script>

<style scoped>
  .headline {
    font-weight: bold;
  }
</style>