import capitalize from 'lodash/capitalize';
import VSelect from 'vuetify/lib/components/VSelect';
import lazySelectMixin from '@/mixins/lazy-select-mixin';

import { RequirementStatusSelectQuery } from './queries.gql';

export default {
  name: 'requirement-status-select',
  mixins: [lazySelectMixin({ itemsKey: 'status' })],

  apollo: {
    status: {
      query: RequirementStatusSelectQuery,

      variables() {
        return {
          requirementId: this.requirementId,
        };
      },

      skip() {
        return this.lazySkipQuery;
      },
    },
  },

  props: {
    requirementId: { type: [String, Number] },
    itemValue: { type: String, default: 'id' },
    itemText: { type: [String, Function], default: () => item => capitalize(item.name) },
  },

  data() {
    return {
      status: [],
    };
  },


  render(h) {
    return h(
      VSelect,
      {
        props: {
          ...this.$attrs,
          ...this.$props,
          items: this.lazyItems,
          loading: this.$apollo.queries.status.loading,
        },
        on: {
          ...this.$listeners,
          focus: this.onFocus,
        }
      }
    );
  },
};