<template lang="pug">
  div(:class="b()")
    template(v-for="titleStudy in formalization.titleStudies")
      div(:class="b('title')") Estudio de Título: (\#{{ titleStudy.id }})
      title-study-content-row(
        :key="titleStudy.id"
        :title-study="titleStudy"
      ).mb-4
</template>

<script>
import TitleStudyContentRow from './title-study-content-row';

export default {
  name: 'title-study-content',

  props: {
    formalization: { type: Object, default: () => {} },
  },

  components: { TitleStudyContentRow },
};
</script>

<style lang="sass" scoped>
  .title-study-content
    &__title
      font-size: 22px
      font-weight: 500
      color: darken(white, 60%)
      margin-bottom: 24px
      text-align: center
</style>