<template lang="pug">
  thead
    tr(:class="b()")
      th
        v-checkbox(
          :value="allSelected"
          hide-details
          @change="onChangeSelectAllCheckbox"
          @click.native.prevent="() => null"
        ).ma-0.pa-0
      table-head-item(
        v-for="item in items"
        :key="item.title"
        :title="item.title"
        :order-key="item.orderKey"
        :order-flow="item.orderFlow"
        @order="args => $emit('order', args)"
      )
</template>

<script>
  import TableHeadItem from '@/components/UI/table-head-item';
  import sortableTableHeadMixin from '@/mixins/sortable-table-head-mixin';

  const ITEMS = [
    { title: 'Prestatario' },
    { title: 'Cuota', orderKey: 'fee_number' },
    { title: 'Préstamo', orderKey: 'investment_proposal_id' },
    { title: 'Monto', orderKey: 'current_data__amount_due' },
    { title: 'Interés', orderKey: 'current_data__interest' },
    { title: 'Capital', orderKey: 'current_data__amortization'  },
    { title: 'Seguros', orderKey: 'current_data__property_insurance' },
    { title: 'Mora', orderKey: 'current_data__default_amount' },
    { title: 'Penalidad', orderKey: 'current_data__penalty_amount' },
    { title: 'Fecha pago', orderKey: 'payment_date' },
    { title: 'Dias atraso' },
    { title: 'Fecha Venc', orderKey: 'original_data__expiration_date' },
    { title: 'Status' },
  ];

  export default {
    name: 'borrower-quotes-table-head',

    mixins: [sortableTableHeadMixin()],

    props: {
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      items() {
        return ITEMS.map(item => ({
          ...item,
          orderFlow: this.orderBy === item.orderKey ? this.orderFlow : null,
        }));
      },
    },

    components: {
      TableHeadItem,
    },
  };
</script>

<style lang="sass">
  .borrower-quotes-table-head
    th
      padding: 4px 8px

</style>
