<template lang="pug">
  tr
    td {{ credit.period | formatPeriod }}
    td {{ credit.bank }}
    td.font-weight-medium {{ credit.status }}
    td.text-no-wrap S/ {{ credit.value | formatNumber }}
</template>

<script>
  import formatPeriod from '../../filters/format-period';

  export default {
    name: 'credits-amount-row',

    props: {
      credit: { type: Object, required: true },
    },

    filters: { formatPeriod },
  };
</script>

<style lang="sass" scoped>

</style>