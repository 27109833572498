import VSelect from 'vuetify/lib/components/VSelect';
import { FORECAST_FILTERS_BY_QUALIFICATION } from '@/utils/constants';

export default {
  name: 'condition-select',

  functional: true,

  render(h, context) {
    const items = Object.values(FORECAST_FILTERS_BY_QUALIFICATION).map(qualificationForecast => ({
        value: qualificationForecast,
        text: context.parent.$t(`qualificationForecast.${qualificationForecast}`),
      }));

    return h(
      VSelect,
      {
        props: {
          ...context.props,
          items,
        },
        class: context.data.class,
        on: context.listeners,
      }
    );
  },
};