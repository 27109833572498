<template lang="pug">
  div(:class="b()")
    filter-item(
      v-for="filter in filters"
      :key="filter.key"
      :filter="filter"
      @remove="args => $emit('remove', args)"
    )
</template>

<script>
  import FilterItem from './filters-list-item';

  export default {
    name: 'filters-list',

    props: {
      filters: { type: Array, default: () => [] },
    },

    components: { FilterItem },
  };
</script>

<style lang="sass" scoped>
  .filters-list
    display: flex
    flex-wrap: wrap
    padding-top: 1.2rem
</style>