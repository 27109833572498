<template lang="pug">
  table.buro-table
    thead
      tr
        th Fecha
        th Otorgante
        th Tipo de cuenta
        th Importe
        th Responsabilidad
    tbody
      tr(v-for="(query, index) in [...queries].reverse()")
        td {{ query.fechaConsulta }}
        td {{ query.nombreOtorgante }}
        td {{ query.tipoCredito | humanizeCreditType }}
        td $ {{ query.importeCredito | formatNumber }}
        td {{ query.tipoResponsabilidad | humanizeResponsability }}
</template>

<script>
  import humanizeCreditType from '../../utils/humanize-credit-type';
  import humanizeResponsability from '../../utils/humanize-responsability';

  export default {
    name: 'queries',

    props: {
      queries: { type: Array, default: () => [] },
    },

    filters: {
      humanizeCreditType,
      humanizeResponsability,
    },
  };
</script>

<style lang="sass" scoped>

</style>