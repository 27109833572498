<template lang="pug">
  v-dialog(:value="value" @input="args => $emit('input', args)" width="300" presistent)
    v-card(:class="b()")
      .title ¿Cual es el motivo?
      reason-select(
        v-model="reasonId"
      )
      v-card-actions
        v-spacer
        v-btn(
          :disabled="!reasonId"
          color="primary"
          text
          @click="onSubmit"
        ) Guardar
</template>

<script>
  import ReasonSelect from '@/components/inputs/deal-lost-reason-select';

  export default {
    name: 'deal-lost-reason-modal',

    props: {
      value: { type: Boolean, default: false },
    },

    data() {
      return {
        reasonId: null,
      };
    },

    methods: {
      onSubmit() {
        this.$emit('submit', this.reasonId);
      },
    },

    components: { ReasonSelect },
  };
</script>

<style lang="sass" scoped>
  .deal-lost-reason-modal
    padding: 1.5rem 1.5rem 0 1.5rem
</style>

