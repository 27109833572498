import Config from '@/config';
import { PERMISSIONS } from '@/utils/constants';

const USER_EXTERNAL_PERMISSIONS = [
  PERMISSIONS.PEGASUS.CUSTOMERS,
  PERMISSIONS.PEGASUS.DEALS,
  PERMISSIONS.PEGASUS.LIBRARY,
  PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__BORROWER,
  PERMISSIONS.PEGASUS.ADVISER_LANDING,
  PERMISSIONS.PEGASUS.SIMULATOR
];

const USER_INTERNAL_PERMISSIONS = [
  PERMISSIONS.PEGASUS.CUSTOMERS,
  PERMISSIONS.PEGASUS.DEALS,
  PERMISSIONS.PEGASUS.INVESTMENT_BORROWER_STATEMENT,
  PERMISSIONS.PEGASUS.LIBRARY,
  PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__BORROWER,
  PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__INVESTMENT_BORROWER,
  PERMISSIONS.PEGASUS.MORTGAGE_SCHEDULE__INVESTMENT_INVESTOR,
  PERMISSIONS.PEGASUS.FORMALIZATIONS__REVIEW,
  PERMISSIONS.PEGASUS.RISK_FILES__REVIEW,
  PERMISSIONS.PEGASUS.RISK_FILES__NOTIFY_OPINION,
  PERMISSIONS.PEGASUS.ADVISER_LANDING,
  PERMISSIONS.PEGASUS.SCOTIA_SCORE,
  PERMISSIONS.PEGASUS.SIMULATOR
];

export default (user) => {
  const permissions = [
    PERMISSIONS.PEGASUS.DASHBOARD,
    ...user.permissions,
  ];

  if(user.permissions.includes(PERMISSIONS.AUTH.EXTERNAL)) {
    permissions.push(...USER_EXTERNAL_PERMISSIONS)
  } else if (!user.permissions.includes(PERMISSIONS.AUTH.TRUST_ONLY)) {
    permissions.push(...USER_INTERNAL_PERMISSIONS);
  }

  user.groups.forEach(group => {
    if(group.id === Config.GROUPS_METRICS_ID)
      permissions.push(PERMISSIONS.PEGASUS.METRICS)
    if(group.id === Config.GROUPS_FORMALIZATION_ID) {
      permissions.push(PERMISSIONS.PEGASUS.FORMALIZATIONS)
      permissions.push(PERMISSIONS.PEGASUS.FORMALIZATIONS__REVIEW)
    }
    if(group.id === Config.GROUPS_INVESTMENT_PROPOSAL_ID) {
      permissions.push(PERMISSIONS.PEGASUS.INVESTMENT)
      permissions.push(PERMISSIONS.PEGASUS.INVESTMENT_BORROWER_STATEMENT)
    }
    if(group.id === Config.GROUPS_RISK_ID) {
      permissions.push(PERMISSIONS.PEGASUS.RISK_FILES)
      permissions.push(PERMISSIONS.PEGASUS.RISK_FILES__REVIEW)
    }
    if(group.id === Config.GROUPS_BONDS_ID) {
      permissions.push(PERMISSIONS.PEGASUS.BONDS)
    }
  });

  return permissions;
};