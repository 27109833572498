import './plugins/offline-plugin';

import Vue from 'vue'
import App from './App.vue'
import moment from 'moment';

import 'moment/locale/es';

import store from './store';

// Plugins
import './plugins/vue-router';
import './plugins/vue-bem-cn';
import './plugins/vue-url-builder';
import './plugins/vue-vee-validate';
import './plugins/vue-project-config';
import './plugins/vue-rtc-currency-exchange';
import './plugins/sentry';
import './scripts/tidio';
import i18n from './i18n';

// Filters
import { formatDate, timeAgo, formatDateTime } from './filters/dates';
import { formatNumber, formatCurrency } from './filters/accounting';

// CSS Deps
import 'izitoast/dist/css/iziToast.css';
import '@mdi/font/css/materialdesignicons.css';

import router from './router';
import { createProvider } from './plugins/vue-apollo';

// Global Components
import LoadingSpinner from '@/components/loading-spinner';
import FetchErrorAlert from '@/components/UI/fetch-error-alert';

import vuetify from './plugins/vuetify';

moment.locale('es');

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateTime', formatDateTime);
Vue.filter('timeAgo', timeAgo);
Vue.filter('formatNumber', formatNumber);
Vue.filter('formatCurrency', formatCurrency)

// Global Components
Vue.component('fetch-error-alert', FetchErrorAlert);
Vue.component('loading-spinner', LoadingSpinner);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  apolloProvider: createProvider(),
  router,
  i18n,
  vuetify,
  store
}).$mount('#app')
