<template lang="pug">
  div(:class="b()")
    declaration-report-chart(:entries="report.rows")
</template>

<script>
import DeclarationReportChart from './declaration-report-chart';

export default {
  name: 'declaration-report',

  props: {
    report: { type: Object, required: true },
  },

  components: { DeclarationReportChart },
};
</script>

<style lang="sass" scoped>
  .declaration-report
    padding: 12px
</style>