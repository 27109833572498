<template lang="pug">
  v-card(:to="profileLink ? { name: 'customers.show', params: { customerId: customer.id }} : undefined").ma-2
    v-layout(wrap).pa-3
      div(v-if="extended" :class="b('avatar-container')")
        user-avatar(
          :first-name="customer.firstName"
          :last-name="customer.lastName"
          :url="customer.avatar"
          :size="extended ? 64 : 48"
        )
          img(:src="customer.avatar")
      div(:class="b('body')")
        div.mb-1.font-weight-bold.title {{ customer.firstName }} {{ customer.lastName }} {{ customer.maternalLastName }}
        v-layout(wrap)
          v-flex(v-bind="fieldLayoutProps")
            b Correo:&nbsp
            a(:href="`mailto:${customer.email}`" style="text-decoration: none" @click.stop) {{ customer.email }}
          v-flex(v-bind="fieldLayoutProps" v-if="customer.vatType")
            b {{ customer.vatType.description }}:&nbsp
            span {{ customer.vat }}
          v-flex(v-bind="fieldLayoutProps")
            b Estado Civil:&nbsp
            span {{ customer.maritalStatus && $t(`maritalStatus.${customer.maritalStatus.status}`)}}
          v-flex(v-if="customer.profession" v-bind="fieldLayoutProps")
            b Ocupación:&nbsp
            span {{ customer.profession }}
          v-flex(v-bind="fieldLayoutProps")
            b Cantidad de dependientes:&nbsp
            span {{ customer.dependents || 0 }}
          v-flex(v-bind="fieldLayoutProps")
            b Celular:&nbsp
            span {{ customer.mobile }}
          v-flex(v-bind="fieldLayoutProps" v-if="customer.phone")
            b Teléfono:&nbsp
            span {{ customer.phone }}
          v-flex(v-bind="fieldLayoutProps")
            b Fecha Nacimiento:&nbsp
            span {{ customer.birth | formatDate }}
            span(v-if="age") &nbsp;({{ age }})
          v-flex(v-bind="fieldLayoutProps")
            b Fecha Registro:&nbsp
            span {{ customer.createdAt | formatDate }}
          v-flex(v-bind="fieldLayoutProps" v-if="customer.couple")
            b Pareja:&nbsp;
            customer-chip(:customer="customer.couple")
          v-flex(v-bind="fieldLayoutProps" v-if="adviser")
            b Asesor:&nbsp;
            adviser-chip(:adviser="adviser")
          v-flex(v-if="extended && customer.address" xs12).pt-1
            b Dirección:&nbsp;
            span {{ fullAddress }}
          v-flex(v-if="extended && (customer.source || customer.medium || customer.campaign)" xs12)
            div(:class="b('mkt')")
              div(v-if="customer.source")
                b Source:
                span {{ customer.source }}
              div(v-if="customer.medium")
                b Medium:
                span {{ customer.medium }}
              div(v-if="customer.campaign")
                b Campaign
                span {{ customer.campaign }}
      //- div(v-if="deal.currentStage.id === '2'" :class="b('data-row')")
      //-   v-tooltip(top)
      //-     template(v-slot:activator="{ on }")
      //-       v-icon(v-on="on") mdi-whatsapp
      //-     span Enviar mensaje a whatsapp
      //-   a(:href="deal.customer.whatsappMissingDocuments" target="_blank") Cargar Documentos
          //- v-flex(v-bind="fieldLayoutProps")
          //-   b Etapa:&nbsp
          //-   step(:step="customer.step")
    v-card-actions(v-if="extended" :class="b('actions')")
      div(:class="b('whatsapp-links')")
        v-btn(v-if="$projectConfig.WHATSAPP_TEMPLATES" color="primary" text @click="whatsappSendModal = true" )
          v-icon(left) mdi-whatsapp
          span Enviar mensaje
        v-menu(v-else-if="whatsappUrls.length" offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              v-bind="attrs"
              v-on="on"
              color="primary"
              text
            )
              v-icon(left) mdi-whatsapp
              span Enviar mensaje
          v-list
            v-list-item(
              v-for="item in whatsappUrls"
              :key="item.text"
              :href="item.url"
              target="_blank" link
            )
              v-list-item-title {{ item.text }}

        v-menu(v-if="copyUrls.length" offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(
              v-bind="attrs"
              v-on="on"
              color="primary"
              text
            )
              v-icon(left) mdi-content-copy
              span Copiar Enlace
          v-list
            v-list-item(v-for="item in copyUrls" :key="item.text" link)
              v-list-item-title(@click="copyToClipboard(item.url)") {{ item.text }}

      v-spacer
      v-btn(icon small @click.native.stop="onEdit")
        v-icon mdi-pencil
      v-btn(v-if="deletable" icon small @click.native.stop="onDelete")
        v-icon mdi-delete

    whatsapp-send-modal(v-model="whatsappSendModal" :customer-id="customer.id")
</template>

<script>
  import moment from 'moment';
  import copy from 'copy-to-clipboard';
  import compact from 'lodash/compact';
  import iziToast from 'izitoast';

  import { buildDocumentsURL, buildProposalsURL } from '@/utils/user-account-url-builder';
  import AdviserChip from '@/components/UI/adviser-chip';
  import CustomerChip from '@/components/UI/customer-chip';
  import UserAvatar from '@/components/UI/user-avatar';
  import addressToString from '@/utils/address-to-string';
  import WhatsappSendModal from '@/components/smart/whatsapp-send-modal';
  import Step from '../customer-step';

  export default {
    name: 'customer-card',

    props: {
      customer: { type: Object, required: true },
      deletable: { type: Boolean, default: false },
      extended: { type: Boolean, default: false },
      profileLink: { type: Boolean, default: true },
    },

    data() {
      return {
        whatsappSendModal: false,
      };
    },

    methods: {
      onDelete() {
        this.$emit('delete', { customer: this.customer });
      },

      onEdit() {
        this.$emit('edit', { customer: this.customer });
      },

      copyToClipboard(text) {
        copy(text);

        iziToast.success({ message: 'El enlace ha sido copiado al portapapeles' });
      },
    },

    computed: {
      fieldLayoutProps() {
        return this.extended ? { xs12: true, lg4: true} : { xs12: true }
      },

      adviser() {
        return this.customer?.adviser;
      },

      fullAddress() {
        return addressToString(this.customer.address);
      },

      age() {
        return this.customer?.birth ? moment().diff(this.customer.birth, 'years') + ' años' : null;
      },

      firstActiveProduct() {
        return this.customer?.activeProducts?.[0];
      },

      copyUrls() {
        const { loginUrl } = this.customer || {};

        return compact([
          loginUrl && { text: 'Inicio de sesión', url: loginUrl },
          loginUrl && this.firstActiveProduct
            && {
              text: 'Propuestas',
              url: buildProposalsURL(loginUrl, this.firstActiveProduct.product.slug),
            },
          loginUrl && this.firstActiveProduct
            && {
              text: 'Documentos',
              url: buildDocumentsURL(loginUrl, this.firstActiveProduct.product.slug),
            },
        ]);
      },

      whatsappUrls() {
        return compact([
          this.customer?.whatsappLoginUrl && { text: 'Aceptar propuesta', url: this.customer.whatsappLoginUrl },
          this.customer?.whatsappMissingDocuments && { text: 'Cargar documentos', url: this.customer.whatsappMissingDocuments },
        ]);
      },
    },

    components: { Step, AdviserChip, CustomerChip, UserAvatar, WhatsappSendModal },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .customer-card
    &__mkt
      background-color: transparentize($primaryColor, 0.9)
      border-radius: 3px
      margin-top: 6px

      & > div
        padding: 2px 12px

        b
          margin-right: 6px

    &__avatar-container
      width: 100%
      padding-bottom: 1rem

      text-align: center

      +sm
        width: auto
        padding: 0
        padding-right: 2rem

    &__body
      width: 100%

      +sm
        width: auto
        flex: 1
      a
        word-break: break-all !important
        text-decoration: none

    &__actions
      background-color: darken(white, 2%)

    +md
      &__mkt
        display: inline-flex
        align-items: center

</style>