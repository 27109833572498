<template lang="pug">
  div(:class="b()" :style="style")
    img(:src="icon")
</template>

<script>
  import { cloudfrontURL } from '@/utils/urlBuilder';

  export default {
    name: 'product-icon',

    props: {
      slug: { type: String, required: true },
      size: { type: Number, default: 120 },
    },

    computed: {
      icon() {
        return cloudfrontURL(`/icons/products/${this.slug}_v3.svg`);
      },

      style() {
        return {
          width: `${this.size}px`,
          height: `${this.size}px`,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .product-icon
    img
      width: 100%
</style>