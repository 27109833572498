<template lang="pug">
  div(:class="b()")
    div(:class="b('body')")
      div(:class="b('title')") {{ $t(`productSlug.${product.slug}`)}}
      product-stages(:stages="product.stages" :currency="currency")
    div(:class="b('footer')")
      div(:class="b('deals')") {{ product.dealsCount }} Negociaciones
      div(:class="b('amount')") {{ product.creditAmount | formatCurrency(currency) }}
</template>

<script>
  import ProductStages from './product-stages';

  export default {
    name: 'product-summary',

    props: {
      product: { type: Object, required: true },
      currency: { type: Object, required: true },
    },

    components: { ProductStages },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .product-summary
    display: flex
    flex-direction: column

    &__title
      font-weight: bold
      font-size: 18px
      margin-bottom: 6px

    &__body
      flex: 1

    &__footer
      padding-top: 16px

      +lg
        display: flex
        align-items: center
        justify-content: space-between

    &__deals
      font-size: 14px
      color: #606060
      text-align: right

      +lg
        text-align: left

    &__amount
      font-size: 24px
      font-weight: bold
      color: #444444
      text-align: right

      +lg
        text-align: left
</style>