<template lang="pug">
validation-observer(ref="form")
  form(@submit.prevent="onSubmit")
    v-layout(wrap)
      v-flex(xs12)
        validation-provider(name="número de referencia" :rules="internalRules.operationNumber" v-slot="{ errors }")
          v-text-field(
            v-model.number="data.operationNumber"
            :error-messages="errors"
            type="number"
            name="número de referencia"
            label="Número de referencia"
          )
      v-flex(xs12)
        validation-provider(name="monto pagado" :rules="internalRules.amount" v-slot="{ errors }")
          v-text-field(
            v-model.number="data.amount"
            :disabled="disabledFields.includes('amount')"
            :error-messages="errors"
            step="0.01"
            type="number"
            name="monto pagado"
            label="Monto pagado"
          )
      v-flex(xs12)
        validation-provider(name="fecha de pago" :rules="internalRules.date" v-slot="{ errors }")
          date-picker(
            v-model="data.date"
            :error-messages="errors"
            :max="datePickerMax"
            name="fecha de pago"
            label="Fecha"
          )
      v-flex(xs12)
        validation-provider(name="bajar plazo" v-slot="{ errors }")
          div.checkbox-wrapper(@change="handleChange")
            input(type="checkbox" v-model="isChecked")
            label Bajar plazo
    v-divider
    v-card-actions
      v-spacer
      v-btn(color="error" text @click="onCancel") Cancelar
      v-btn(
        :loading="requesting"
        color="primary"
        type="submit"
        text
      ) Guardar
</template>

<script>
import moment from 'moment';
import DatePicker from '@/components/inputs/input-date-picker';

export default {
  name: 'quote-payment-form',

  props: {
    requesting: { type: Boolean },
    defaultValues: { type: Object, default: () => ({}) },
    rules: { type: Object, default: () => ({}) },
    amountKey: { type: String, default: 'amountDue' },
    disabledFields: { type: Array, default: () => [] },
  },

  data() {
    return {
      data: {
        quoteId: null,
        operationNumber: undefined,
        amount: null,
        date: moment().format('YYYY-MM-DD'),
        amortizeMonths: null,
        ...this.defaultValues,
      },
      isChecked: false,
      localRules: {
        operationNumber: { required: true, numeric: true, min_value: 1 },
        amount: { required: true, decimal: 2, min_value: 1 },
        date: { required: true },
      },
    };
  },

  computed: {
    internalRules() {
      return { ...this.localRules };
    },
    datePickerMax() {
      return moment().startOf('day').format();
    },
  },

  methods: {
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      if (this.isChecked && !confirm("¿Estás seguro que deseas bajar el plazo?")) return;

      this.$emit('submit', { data: this.data });
    },

    onCancel() {
      this.$emit('cancel');
    },

    updateAmountRules(isChecked) {
      this.localRules.amount = {
        ...this.localRules.amount,
        min_value: isChecked ? (this.rules?.amount?.min_value * 2 || 1) : (this.rules?.amount?.min_value || 1),
      };
    },

    handleChange() {
      this.data.amortizeMonths = this.isChecked.toString();
      this.updateAmountRules(this.isChecked);
    },
  },

  components: { DatePicker },

  watch: {
    isChecked: 'updateAmountRules',
    defaultValues(newValues) {
      this.data = { date: moment().format('YYYY-MM-DD'), ...newValues };
      this.$nextTick(() => this.$refs.form.reset());
    },
  },
};
</script>

<style lang="sass" scoped>
.checkbox-wrapper
  gap: .5rem
  display: flex
  justify-content: flex-start
  align-items: center
</style>
