import { render, staticRenderFns } from "./debtor-card.vue?vue&type=template&id=e9bfcaf4&scoped=true&lang=pug"
import script from "./debtor-card.vue?vue&type=script&lang=js"
export * from "./debtor-card.vue?vue&type=script&lang=js"
import style0 from "./debtor-card.vue?vue&type=style&index=0&id=e9bfcaf4&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9bfcaf4",
  null
  
)

export default component.exports