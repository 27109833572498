import VSelect from 'vuetify/lib/components/VSelect';
import Config from '@/config';

export default {
  name: 'product-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: { type: Array, default: () => Config.PRODUCTS_SELECT },
    label: { type: String, default: 'Producto' },
  },
};
