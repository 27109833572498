<template lang="pug">
  div(:class="b({ active })" @click="args => $emit('click', product)")
    div(:class="b('icon-container')")
      product-icon(:slug="product.slug" :size="38")
    div(:class="b('body')")
      div(:class="b('title')") {{ $t(`productSlug.${product.slug}`) }}
      div(:class="b('subtitle')") {{ product.dealsCount }} Negociaciones
</template>

<script>
  import ProductIcon from '@/components/UI/product-icon';

  export default {
    name: 'products-menu-item',

    props: {
      product: { type: Object, required: true },
      active: { type: Boolean, default: false },
    },

    components: { ProductIcon },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .products-menu-item
    $c: &

    display: flex
    flex-shrink: 0
    width: 200px
    padding: 4px 8px
    margin: 0 4px

    border-radius: 12px
    border: 2px solid #6fa3fd
    cursor: pointer

    +lg
      width: auto
      margin: 0
      margin-bottom: 8px
      padding: 8px
      border-radius: 4px
      border: 0

    &:hover
      background-color: lighten(#6fa3fd, 20%)

    &--active
      background-color: #6fa3fd !important

      #{$c}
        &__title, &__subtitle
          color: white

    &__icon-container
      display: none

      +lg
        display: block
        margin-right: 12px

    &__title
      font-size: 12px
      font-weight: 500

      +lg
        font-size: 16px

        margin-bottom: 6px

    &__subtitle
      font-size: 12px
      color: #606060

      +lg
        font-size: 14px
</style>