var render, staticRenderFns
import script from "./legal-mortgage-value-chart.vue?vue&type=script&lang=js"
export * from "./legal-mortgage-value-chart.vue?vue&type=script&lang=js"
import style0 from "./legal-mortgage-value-chart.vue?vue&type=style&index=0&id=0f8a28b4&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8a28b4",
  null
  
)

export default component.exports