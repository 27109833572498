<script>
  import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
  import {
    VListItemAvatar,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
  } from 'vuetify/lib/components/VList';
  import debounce from 'lodash/debounce';

  import basicStringSearch from '@/utils/basic-string-search';
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import UserAvatar from '@/components/UI/user-avatar';

  import { AdvisersSelectQuery } from './queries.gql';

  export default {
    name: 'adviser-select',

    mixins: [lazySelectMixin({ itemsKey: 'advisers' })],

    data() {
      return {
        advisers: [],
        search: null,
      };
    },

    apollo: {
      advisers: {
        query: AdvisersSelectQuery,
        manual: true,

        variables() {
          return {
            isStaff: true,
            search: this.search,
          };
        },

        result({ data }) {
          this.advisers = (data?.advisers?.objects || []);
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },

    props: {
      fetching: { type: Boolean, default: false },
      itemValue: { type: String, default: 'id' },
    },

    methods: {
      onChangeSearch: debounce(function (value) {
        this.search = value;
      }, 300),

      searchFn({email, firstName, lastName}, query) {
        return basicStringSearch([email, firstName, lastName], query);
      },
    },

    render(h) {
      return h(
        VAutocomplete, {
          class: this.b(),

          props: {
            ...this.$attrs,
            ...this.$props,
            items: this.lazyItems,
            filter: this.searchFn,
            loading: this.$apollo.queries.advisers.loading,
          },

          on: {
            ...this.$listeners,
            'update:search-input': this.onChangeSearch,
            focus: this.onFocus,
          },

          scopedSlots: {
            selection: data => h('div', `${data.item.firstName} ${data.item.lastName}`),

            item: data => [
              h(VListItemAvatar, [
                h(UserAvatar,
                  {
                    props: {
                      url: data.item.avatar,
                      firstName: data.item.firstName,
                      lastName: data.item.lastName
                    },
                  },
                ),
              ]),
              h(VListItemContent, [
                h(VListItemTitle, `${data.item.firstName} ${data.item.lastName}`),
                h(VListItemSubtitle, `${data.item.email}`),
              ]),
            ],
          }
        },
      );
    },
  };
</script>

<style lang="sass">
  .adviser-select
    .v-select__selections
      min-height: 32px !important
</style>