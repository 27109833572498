import UserSelect from './user-select';

export default {
  name: 'borrower-select',

  block: 'user-select',

  extends: UserSelect,

  props: {
    additionalQueryVariables: { type: Object, default: () => ({ onlyBorrowers: true }) },
  },
};
