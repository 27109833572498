<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      div(:class="b('header-main')")
        user-info(
          :user="user"
          :label="label"
        )
        .spacer
        user-info(
          :user="vehicle"
          :label="labelVehicle"
        )

      div(:class="b('header-data')")
        slot(name="header-data")
      div(:class="b('header-actions')")
        slot(name="header-actions")

    div(:class="b('body')")
      slot
</template>

<script>
  import UserInfo from '../../../components/user-info';

  export default {
    name: 'quotes-card',

    props: {
      user: { type: Object },
      vehicle: { type: Object },
      label: { type: String },
      labelVehicle: { type: String },
      headerData: { type: Array, default: () => [] },
    },

    components: { UserInfo },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .quotes-card
    padding: 16px
    border-radius: 4px
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

    &__header-main
      display: flex
      align-items: center

    &__header-actions
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: center
      padding: 5px 16px
      margin: 0 -16px
      background-color: darken(white, 3%)

      color: $primaryColor

      a
        text-decoration: none

    &__header-data
      display: flex
      align-items: center
      justify-content: space-between
      padding-top: 3px
      padding-bottom: 6px

      &-item
        display: flex
        align-items: center

        &-value
          font-size: 13px
          font-weight: 500
          margin-right: 4px

        &-description
          font-size: 9px

    +md
      &__header-data
        &-item
          &-value
            font-size: 18px
            margin-right: 12px

          &-description
            font-size: 12px

      &__schedule-link
        font-size: 14px
</style>