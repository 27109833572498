<template lang="pug">
  div(:class="b()")
    div(:class="b('avatar')")
      user-avatar(:first-name="author.firstName" :last-name="author.lastName" :url="author.avatar")
    div(:class="b('continaer')")
      div(:class="b('author-name')") {{ author.firstName }} {{ author.lastName }}
      div(:class="b('subheading')") {{ message.createdAt | formatDate }} ({{ message.createdAt | timeAgo }})
      div(:class="b('body')" v-html="message.body")
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';

  export default {
    name: 'messages-item',

    props: {
      message: { type: Object, default: () => ({}) },
      deletable: { type: Boolean, default: false },
    },

    methods: {
      onClickDelete() {
        if(window.confirm('¿Está seguro que desea eliminar el mensaje?'))
          this.$emit('delete', { message: this.message });
      },
    },

    computed: {
      author() {
        return {
          firstName: 'Usuario',
          lastName: 'Desconocido',
          ...this.message?.author,
        };
      },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass">
  .messages-item
    display: flex
    margin: 1rem 0 1.5rem 0

    &__avatar
      margin-right: 1rem

    &__author-name
      font-weight: 500
      font-size: 14px

    &__subheading
      font-size: 12px
      color: darien(white, 60%)
      font-weight: normal

    &__delete-button
      margin-left: 1rem
      color: red
      cursor: pointer

    &__body
      margin-top: .2rem
      font-size: 16px
      &::first-letter
        text-transform: uppercase

      @import '~@/sass/message-format'

</style>