<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)")
    bond-form(
      :bond="bond"
      :currency="currency"
      :requesting="requesting"
      :hidden-fields="hiddenFields"
      :cancelable="true"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import BondForm from '@/components/forms/bond-form';

  export default {
    name: 'bond-edit-form-modal',

    props: {
      currency: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'bond' })],

    components: { BondForm },
  };
</script>
