<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table
        thead
          tr
            th Banco
            th Producto
            th Calificación
            th Deuda Total
            th A pagar
        tbody
          table-row(
            :class="b('row')"
            v-for="debt, idx in debts"
            :key="idx"
            :entry="debt"
          )
        tfoot
          tr
            th(colspan="3") Total
            td {{ totalAmount | formatCurrency }}
            td.font-weight-bold {{ totalPaymentAmount | formatCurrency }}
</template>

<script>
  import TableRow from './debts-table-row';

  export default {
    name: 'debts-table',

    props: {
      debts: { type: Array, default: () => [] },
    },

    computed: {
      totalAmount() {
        return this.debts.reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0);
      },

      totalPaymentAmount() {
        return this.debts.reduce(
          (acc, { amount }) => acc + amount,
          0
        );
      },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .debts-table
    border-collapse: collapse
    max-width: 100%

    table
      width: 100%

    thead
      background-color: $primaryColor
      color: white

      td, th
        padding: 2px 8px

    tfoot
      td, th
        padding: 2px 8px
        background-color: darken(white, 8%)

    table
      max-width: 100%
      margin: 0 auto

    &__table-container
      max-width: 100%
      overflow-x: auto
      margin: 0 auto
</style>