<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Testimonios de clientes

    div(:class="b('testimonies')")
      testimony(
        v-for="testimony in testimonies"
        :key="testimony.id"
        :testimony="testimony"
        @click-delete="args => $emit('click-delete', args)"
        @click-edit="args => $emit('click-edit', args)"
      )
</template>

<script>
import Testimony from './testimonies-item';

export default {
  name: 'testimonies',

  props: {
    testimonies: { type: Array, default: () => [] },
  },

  components: { Testimony },
};
</script>

<style lang="sass" scoped>
  .testimonies
    &__title
      text-align: center
      font-size: 21px
      margin-bottom: 12px
      color: darken(white, 70%)
</style>