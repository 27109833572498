<template lang="pug">
  div(:class="b()")
    incomes-chart(:incomes="flatIncomes")
    div(:class="b('title')") Ingresos reportados los últimos 6 meses
    div(:class="b('table-container')")
      incomes-last-months-table(:incomes="lastIncomes")
    //- div(:class="b('title')") Historial de ingresos reportados
    //- div(:class="b('table-container')")
    //-   incomes-table(:incomes="flatIncomes")
</template>

<script>
import { pipe, reduce, sortBy } from 'lodash/fp';
import moment from 'moment';
import IncomesTable from './incomes-table';
import IncomesLastMonthsTable from './incomes-last-months-table';
import IncomesChart from './incomes-chart';

const MINIMUM_PERIOD = moment().startOf('month').subtract(6, 'months');

export default {
  name: 'incomes',

  props: {
    incomes: { type: Array },
  },

  computed: {
    flatIncomes() {
      return pipe(
        reduce((acc, { incomes }) => [...acc, ...incomes || []], []),
        sortBy('period'),
      )(this.incomes);
    },

    lastIncomes() {
      return this.flatIncomes.filter(({ period }) => moment(period).isSameOrAfter(MINIMUM_PERIOD));
    },
  },

  components: { IncomesTable, IncomesChart, IncomesLastMonthsTable },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .incomes
    &__title
      font-size: 19px
      text-align: center
      font-weight: bold
      color: darken(white, 30%)
      padding-top: 12px
      padding-bottom: 12px

    &__table-container
      max-width: 100%
      overflow-x: auto

    +md
      &__title
        font-size: 22px

      &__table-container
        max-height: 500px
        overflow-y: auto
</style>