<template lang="pug">
  div(:class="b()")
    div(:class="b('wrapper')")
      div(:class="b('header')")
        div(:class="b('title')") {{ name }}&nbsp;
          span(v-if="riskFiles && riskFiles.objects") ({{ riskFiles.objects.length }} de {{ total }})
      div(:class="b('body')")
        file-card(
          v-for="riskFile in riskFiles.objects"
          :key="riskFile.id"
          :risk-file="riskFile"
          oncontextmenu="return false;"
        )
        .text-center(slot="footer")
          div(v-if="loading" :class="b('loading-container')")
            loading-spinner
          v-btn(
            v-else-if="riskFiles.hasNext"
            color="primary"
            :disabled="loading"
            @click="onClickLoadMore"
          ) Cargar Más
</template>

<script>
  import Draggable from 'vuedraggable';
  import FileCard from './risk-file-card';

  export default {
    name: 'status',

    props: {
      status: { type: String, required: true },
      name: { type: String },
      riskFiles: { type: Object, default: () => ({}) },
      loading: { type: Boolean, default: false },
    },

    methods: {
      onClickLoadMore() {
        this.$emit('load-more', {
          status: this.status,
          nextRiskFilePage: this.riskFiles.page + 1,
        });
      },
    },

    computed: {
      total() {
        return this.riskFiles?.count || 0;
      },
    },

    components: { FileCard, Draggable },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .status
    flex-shrink: 0

    width: 320px
    height: 100%
    margin: 0 .25rem
    border: 1px solid darken(white, 15%)

    border-radius: 3px

    +xs
      width: 280px

    &__wrapper
      display: flex
      flex-direction: column

      height: 100%

      white-space: normal

    &__header
      padding: .5rem 1rem

      font-weight: bold

      background-color: white

    &__total
      padding-top: .5rem

      font-size: 1.2rem
      font-weight: 500
      text-align: right

    &__body
      flex: 1

      padding-bottom: 1rem

      overflow-y: auto

    &__loading-container
      display: flex
      justify-content: center
      align-items: center

      padding: 1rem 0

</style>