<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
    div(:class="b('body')")
      loading-wrapper(
        v-if="$apollo.queries.contract.loading || !contract"
      )
      template(v-else)
        div(:class="b('contract')")
          div(v-if="contract.disbursementProcedure" :class="b('contract-disbursement-procedure')")
            p
              img(src="https://assets.rebajatuscuentas.com/logos/rebaja_tus_cuentas--black.svg" height="32")
            h2.text-center Mecánica de desembolso
            br
            div(:class="b('contract-disbursement-procedure-content')" v-html="contract.disbursementProcedure")
            div(style="page-break-after: always; margin-bottom: 80px")

          div(:class="b('contract-content')" v-html="contract.html")
        v-btn(
          :class="b('fab')"
          @click="onClickPrint"
          color="primary"
          fab
          x-large
        )
          v-icon mdi-printer
</template>

<script>
import { ContractQuery } from './graphql/queries.gql';

import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import { CONTRACT_STATUS } from '@/utils/constants';

export default {
  name: 'formalization-contract-view',

  data() {
    return {
      contract: null,
    };
  },

  apollo: {
    contract: {
      query: ContractQuery,
      fetchPolicy: 'network-only',

      variables() {
        return {
          id: this.$route.params.contractId,
        };
      },

      result({ data }) {
        if(data?.contract?.status !== CONTRACT_STATUS.PENDING)
          this.throwNotFound();
      },

      skip() {
        return !this.$route.params.contractId;
      },
    },
  },

  methods: {
    throwNotFound() {
      window.alert('El contrato al que intenta acceder no existe');

      this.$router.replace({ name: 'dashboard.index' });
    },

    onClickPrint() {
      window.print();
    },
  },

  mounted() {
    const { contractId } = this.$route.params;

    if(!contractId)
      return this.throwNotFound;
  },

  components: { LoadingWrapper },
};
</script>

<style lang="sass">
  @import '~@/sass/mixins'

  .formalization-contract-view
    &__fab
      position: fixed
      right: 18px
      bottom: 18px

    &__contract
      max-width: 1200px
      margin: 0 auto
      padding: 24px
      font-family: Arial, Helvetica, sans-serif
      font-size: 12pt
      line-height: 1.25

      table
        width: 100%
        border-collapse: collapse
        margin-bottom: 1.5em

        tr, td, th
          border: 1px solid black
          padding: 3px

        th
          background-color: darken(white, 7%)

    @media print
      &__fab
        display: none

      &__contract
        padding: 0

    +lg
      &__fab
        bottom: 42px
        right: 42px

    .pagebreak
      page-break-before: always

</style>