<template lang="pug">
  div(:class="b()")
    form(@submit.prevent="onSubmit")
      v-layout(wrap).align-center
        v-flex(xs12 md4 lg2)
          investor-select(
            v-model="data.investorIds"
            label="Inversionista"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg2)
          vehicle-select(
            v-model="data.investmentVehicleIds"
            label="Vehículo"
            clearable
            multiple
            small-chips
            deletable-chips
          )
        v-flex(xs12 md4 lg2)
          status-select(
            v-model="data.status"
            label="Estado"
            multiple
            clearable
          )
        v-flex(xs12 md4 lg3)
          date-picker(
            v-model="data.emittionDate"
            label="Fecha de emisión (Rango)"
            clearable
            range
          )
        v-flex(xs12 md4 lg3)
          date-picker(
            v-model="data.expirationDate"
            label="Fecha de vencimiento (Rango)"
            clearable
            range
          )
        v-flex.text-right
          v-btn(
            :disabled="disabled || fetching"
            :loading="fetching"
            type="submit"
            color="primary"
          ) BUSCAR PROPUESTAS
</template>

<script>
  import InvestorSelect from '@/components/inputs/investor-select';
  import basicFormMixin from '@/components/forms/basic-form-mixin';
  import UserSelect from '@/components/inputs/user-select';
  import StatusSelect from '@/components/inputs/bond-status-select';
  import DatePicker from '@/components/inputs/input-date-picker';
  import VehicleSelect from '@/components/inputs/vehicle-select';

  export default {
    name: 'filters',

    props: {
      fetching: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },

    mixins: [
      basicFormMixin({
        modelKey: 'filters',
        mapPropsToData({ filters }) {
          return {
            investorIds: filters?.investorIds || [],
            investmentVehicleIds: filters?.investmentVehicleIds || [],
            emittionDate: filters?.emittionDate || [],
            expirationDate: filters?.expirationDate || [],
            status: filters?.status || [],
          };
        },
      }),
    ],

    components: { InvestorSelect, UserSelect, StatusSelect, DatePicker, VehicleSelect },
  };
</script>

<style lang="sass" scoped>
  .filters
    width: 100%
</style>