<template lang="pug">
  div
    v-card.pa-2
      v-breadcrumbs(:items="breadcrumbItems")
    v-container
      v-layout(justify-center)
        v-flex(xs12 sm8 md6)
          customer-form(
            :requesting="isRequesting"
            :hidde-fields="['adviser', 'couple']"
            :lazy-selects="false"
            @submit="onSubmitCustomer"
          )
</template>

<script>
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
  import currentUserMixin from '@/mixins/current-user-mixin';
  import { CreateCustomerMutation } from './graphql/mutations.gql';
  import CustomerForm from '@/components/forms/customer-form';

  export default {
    name: 'customers-edit-view',

    mixins: [
      currentUserMixin(),
      mutationsHandlersMixin(),
    ],

    data() {
      return {
        isRequesting: false,
        breadcrumbItems: [
          { text: 'Clientes', to: { name: 'customers.list'}, exact: true },
          { text: 'Nuevo', disabled: true }
        ],
      };
    },

    components: {
      CustomerForm,
    },

    methods: {
      async onSubmitCustomer({ customer }) {
        this.handleCreateMutation({
          mutation: CreateCustomerMutation,

          variables: {
            customer: {
              ...customer,
              adviserId: this.currentUser.id,
              coupleId: Number(this.$route.query.coupleId) || null,
            },
          },
          requestingStateKey: 'isRequesting',
        }).then(({ data: { createCustomer: { customer }} }) => {
          this.$router.push({ name: 'customers.show', params: { customerId: customer.id } });
        });
      },
    }
  };
</script>

<style lang="sass" scoped>

</style>