<template lang="pug">
  div(:class="b()")
    div(:class="b('summary-boxes')")
      div(:class="b('summary-box')")
        div(:class="b('summary-box-title')") Deuda Actual
        div(:class="b('summary-box-amount')") {{ investmentProposal.pendingDebtBorrower | formatCurrency(investmentProposal.currency )}}
      div(:class="b('summary-box')")
        div(:class="b('summary-box-title')") Plazo
        div(:class="b('summary-box-amount')") {{ investmentProposal.quotesQuantity }} meses
      div(:class="b('summary-box')")
        div(:class="b('summary-box-title')") Desembolso
        div(:class="b('summary-box-amount')") {{ investmentProposal.disbursementDate | formatDate('D MMMM YYYY')}}

    div(style="display: flex; justify-content: center;")
      .is-flex.is-justify-content-center
        router-link(:to="accountStatementRoute" target="_blank" :class="b('button')" style="text-decoration-line: none; display: flex; width: fit-content;")
          v-icon(style="color: white;") mdi-calendar
          span Estado de Cuenta

    div(style="display: flex; justify-content: flex-end; margin-bottom: 12px")
      investment-proposals-detail-payment-code(:code="investmentProposal.kashioPaymentCode")

    div(:class="b('table-container')")
      investment-proposals-detail-quotes-table(
        :quotes="investmentProposal.quotes"
        :currency="investmentProposal.currency"
      )
</template>

<script>
import { encrypt } from '@/utils/url-params-crypter';
import InvestmentProposalsDetailQuotesTable from './investment-proposals-detail-quotes-table';
import investmentProposalsDetailPaymentCode from './investment-proposals-detail-payment-code';

export default {
  name: 'investment-proposals-detail',

  props: {
    investmentProposal: { type: Object, required: true },
  },

  computed: {
    accountStatementRoute() {
      return {
        name: 'investment.borrower_statement',
        query: {
          _params: encrypt({
            investmentProposalId: this.investmentProposal.id,
          }),
        },
      };
    },

    scheduleRoute() {
      return {
        name: 'schedule',
        query: {
          _params: encrypt({ proposalId: this.investmentProposal.proposalAcceptedId }),
        },
      };
    },
  },

  components: { InvestmentProposalsDetailQuotesTable, investmentProposalsDetailPaymentCode },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposals-detail
    &__summary-boxes
      display: flex
      align-items: center
      overflow-x: auto
      margin-bottom: 18px
      justify-content: space-between

    &__summary-box
      display: flex
      align-items: center
      background-color: transparent
      padding: 0
      margin: 0 3px
      text-align: center
      white-space: nowrap
      border-radius: 6px

      &-title
        font-size: 15px
        margin-right: 10px
        margin-bottom: 0

      &-amount
        font-weight: 500
        font-size: 20px

    &__buttons
      display: flex
      justify-content: center

    &__button
      display: flex
      align-items: center
      line-height: 1
      padding: 16px 24px
      font-weight: 500
      cursor: pointer
      background-color: $primaryColor
      color: white
      border-radius: 24px

      &:hover
        background-color: lighten($primaryColor, 5%)

      i
        font-size: 1.2em


    &__table-container
      background-color: white
      padding: 8px
      overflow-x: auto
      border-radius: 8px

      table
        white-space: nowrap

</style>