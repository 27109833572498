<template lang="pug">
  div(:class="b()")
    requirements-item(
      v-for="requirement in requirements"
      :key="requirement.id"
      :requirement="requirement"
      @change-status="args => $emit('change-status', args)"
      @delete="args => $emit('delete', args)"
    )
</template>

<script>
  import RequirementsItem from './requirements-item';

  export default {
    name: 'requirements',

    props: {
      requirements: { type: Array, default: () => [] },
    },

    components: { RequirementsItem },
  };
</script>

<style lang="sass" scoped>
  .requirements
    .requirements-item
      border-bottom: 1px solid darken(white, 8%)

      &:last-child
        border-bottom: 0
</style>