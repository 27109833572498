<template lang="pug">
  span(:class="b({ green: amount < 0, red: amount > 0 })")
    span {{ amount > 0 ? '+' : '-' }}
    span (
    span {{ render ? render(Math.abs(amount)) : Math.abs(amount) }}
    span )
</template>

<script>
  export default {
    name: 'saving-text',

    props: {
      amount: { type: Number, required: true },
      render: { type: Function },
    },
  };
</script>

<style lang="sass" scoped>
  .saving-text
    &--green
      color: green
    &--red
      color: red
</style>