<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      div(:class="b('title')") {{ $t('documentOrigin.' + origin) }}
      div(v-if="gestionable" :class="b('actions')")
        v-btn(
          v-if="canRegenerate"
          color="primary"
          :loading="requesting"
          text small
          @click="onClickGenerate"
        )
          v-icon mdi-refresh
          | &nbsp;Regenerar
    v-expansion-panels(accordion :class="b('expansion-panel')")
      documents-group(
        v-for="group in groups"
        :key="group.title"
        :group="group"
        :gestionable="gestionable"
        @update="args => $emit('update', args)"
        @delete="args => $emit('delete', args)"
        @upload-attachments="args => $emit('upload-attachments', args)"
        @delete-attachment="args => $emit('delete-attachment', args)"
      )

    v-alert(
      v-if="emptyNotification && !groups.length"
      :value="true"
      type="info"
      outlined
    ) {{ emptyNotificationText }}
</template>

<script>
  import DocumentsGroup from './documents-group';

  export default {
    name: 'documents-origin-group',

    props: {
      groups: { type: Array, default: () => ([]) },
      origin: { type: String, required: true },
      requesting: { type: Boolean, default: false },
      gestionable: { type: Boolean },
      canRegenerate: { type: Boolean, default: true },
      emptyNotification: { type: Boolean, default: false },
      emptyNotificationText: { type: String, default: 'No hay documentos para este cliente' },
    },

    components: {
      DocumentsGroup,
    },

    methods: {
      onClickGenerate() {
        this.$emit('generate', { origin: this.origin });
      },
    },
  };
</script>

<style lang="sass" scoped>
  .documents-origin-group
    &__header
      display: flex
      justify-content: space-between
      margin-top: 16px
      padding-bottom: 12px

    &__title
      font-weight: bold
</style>