<template lang="pug">
  div(:class="b()" :style="{ backgroundColor: color }") {{ text }}
</template>

<script>
  import statusColor from '@/utils/investment-proposal-qualification-color';

  export default {
    name: 'investment-proposal-qualification-tag',

    props: {
      status: { 
        type: String, 
        required: true,
      },
    },

    computed: {
      color() {
        return this.status !== 'N/A' ? statusColor(this.status) : '#C0C0C0';
      },
      text() {
        return this.status !== 'N/A' ? this.$t(`qualificationStatus.${this.status}`) : 'N/A';
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .investment-proposal-qualification-tag
    display: flex
    align-items: center
    justify-content: center
    padding: 2px 12px
    border-radius: 4px
    text-transform: uppercase
    font-size: 9px
    font-weight: 500
    color: white

    +md
      padding: 2px 12px
      font-size: 11px
</style>