import { render, staticRenderFns } from "./borrowers-payment-days-average-table-body.vue?vue&type=template&id=367df7f0&scoped=true&lang=pug"
import script from "./borrowers-payment-days-average-table-body.vue?vue&type=script&lang=js"
export * from "./borrowers-payment-days-average-table-body.vue?vue&type=script&lang=js"
import style0 from "./borrowers-payment-days-average-table-body.vue?vue&type=style&index=0&id=367df7f0&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "367df7f0",
  null
  
)

export default component.exports