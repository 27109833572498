<template lang="pug">
  div(:class="b()")
    credits-chart(:credits="credits")
    credits-table(:credits="credits")
</template>

<script>
  import CreditsTable from './consumer-loan-credits-table';
  import CreditsChart from './consumer-loan-credits-chart';

  export default {
    name: 'consumer-loan-credits',

    props: {
      credits: { type: Array, default: () => [] },
    },

    components: { CreditsTable, CreditsChart },
  };
</script>

<style lang="sass" scoped>

</style>