import moment from 'moment';

import DatePicker from './input-date-picker';

const allowedDates = date => {
  return moment(date).endOf('month').isSame(date, 'day');
};

export default {
  name: 'month-picker',

  extends: DatePicker,

  props: {
    allowedDates: { type: Function, default: allowedDates },
  },
};
