<template lang="pug">
  v-card(:class="b()")
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.loansCount }}
      div(:class="b('item-title')") Préstamos
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.monthsAvg }} meses
      div(:class="b('item-title')") Plazo Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.disbursementAvg | formatCurrency }}
      div(:class="b('item-title')") Desembolso Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.minBorrowerRate }} %
      div(:class="b('item-title')") Tasa mínima
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.maxBorrowerRate }} %
      div(:class="b('item-title')") Tasa máxima
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.rateAvg }} %
      div(:class="b('item-title')") Tasa Promedio
    div(:class="b('item')")
      div(:class="b('item-amount')") {{ summary.weightedRateAvg.toFixed(2) }} %
      div(:class="b('item-title')") Tasa Ponderada
</template>

<script>
export default {
  name: 'loans-summary',

  props: {
    summary: { type: Object, required: true },
  },
};
</script>

<style lang="sass" scoped>
  .loans-summary
    display: flex
    flex-wrap: wrap
    justify-content: space-around
    padding: 8px 12px

    &__item
      text-align: center
      padding: 4px 6px

      &-amount
        font-size: 1.2em
        font-weight: bold
        color: darlen(white, 60%)
</style>