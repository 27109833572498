<template lang="pug">
  div(:class="b()")
    active-product(
      v-for="activeProduct in activeProducts"
      :key="activeProduct.product.slug"
      :activeProduct="activeProduct"
    )
</template>

<script>
  import ActiveProduct from './active-products-row';

  export default {
    name: 'active-products',

    props: {
      activeProducts: { type: Array, default: () => [] },
    },

    components: { ActiveProduct },
  };
</script>
