<template lang="pug">
  tbody
    tr(v-for="item in items" :key="item.name")
      td {{ item.adviserEmail }}
      td.text-center {{ item.deals | formatNumber }}
      td.text-center {{ item.netDeals | formatNumber }}
      td.text-center {{ item.pctNetDeals }}
      td.text-center {{ item.proposalsAccepted | formatNumber }}
      td.text-center {{ item.creditApplications | formatNumber }}
      td.text-center {{ item.approvals | formatNumber }}
      td.text-center {{ item.clients | formatNumber }}
</template>

<script>
  export default {
    name: 'advisers-table-body',

    props: {
      items: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>