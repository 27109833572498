<template lang="pug">
  div(:class="b()")
    feature(
      v-for="feature, idx in features"
      :class="b('item')"
      :key="idx"
      :feature="feature"
    )
</template>

<script>
  import Feature from './extra-features-list-item';

  export default {
    name: 'extra-features-list',

    props: {
      features: { type: Array, default: () => [] },
    },

    components: { Feature },
  };
</script>

<style lang="sass" scoped>
  .extra-features-list
    &__item
      margin-bottom: 32px

      &:last-child
        margin-bottom: 0
</style>