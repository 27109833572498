<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      v-layout(wrap)
        v-flex(v-if="renderField('currencyId')" xs12 md6).px-2
          validation-provider(name="moneda" :rules="'required'" v-slot="{ errors }")
            currency-select(
              v-model="data.currency"
              :error-messages="errors"
              name="moneda"
              return-object
            )
        v-flex(xs12 md6).px-2
          validation-provider(name="valor comercial" :rules="rules.value" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.value"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              type="number"
              name="valor comercial"
              label="Valor comercial"
              step="0.01"
            )
        v-flex(v-if="renderField('auctionValue')" xs12 md6).px-2.d-flex
          validation-provider(name="valor de realización" :rules="rules.auctionValue" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.auctionValue"
              :error-messages="errors"
              :prefix="data.currency.symbol"
              type="number"
              name="valor de realización"
              label="Valor de realización"
              step="0.01"
            )
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              v-icon(color="primary" v-on="on") mdi-help-circle
            span Sugerimos colocar el 85% del valor comercial
        v-flex(v-if="renderField('typeId')" xs12 md6).px-2
          validation-provider(name="tipo de propiedad" :rules="fillRisk ? { required: true } : {}" v-slot="{ errors }")
            property-type-select(
              v-model="data.typeId"
              :default-items="typeDefaultItems"
              :error-messages="errors"
              label="tipo de propiedad"
              name="tipo de propiedad"
              @input="onInputPropertyType"
            )
        v-flex(v-if="renderField('yearOfConstruction')" xs12 md6).px-2
          validation-provider(name="año de construccion" :rules="rules.yearOfConstruction" v-slot="{ errors }")
            v-text-field(
              v-model.number="data.yearOfConstruction"
              :error-messages="errors"
              :disabled="isTerrain"
              type="number"
              name="año de construccion"
              label="año de construccion"
            )
        v-flex(v-if="renderField('totalArea')" xs12 md6).px-2
          v-text-field(
            v-model.number="data.totalArea"
            type="number"
            name="area total"
            label="Area Total (m²)"
          )
        v-flex(v-if="renderField('registrationNumber')" xs12 md6).px-2
          v-text-field(
            v-model="data.registrationNumber"
            type="string"
            name="partida registral"
            label="Partida registral"
          )
        v-flex(v-if="renderField('address.line1')" xs12).px-2
          validation-provider(name="dirección" :rules="fillRisk ? { required: true } : {}" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.line1"
              :error-messages="errors"
              name="dirección"
              label="dirección"
            )
        template(v-if="renderField('address.location')")
          v-flex(
            v-for="division, idx in politicalDivisions"
            v-show="idx > 0"
            :key="idx"
            xs12 md6
          ).px-2
            validation-provider(:name="division.name" :rules="fillRisk ? { required: true } : {}" v-slot="{ errors }")
              location-select(
                :value="$addressInput.getValue(idx)"
                :error-messages="errors"
                :name="division.name"
                :disabled="$addressInput.isDisabled(idx)"
                :parent-id="$addressInput.getParentId(idx)"
                :default-items="$addressInput.getDefaultItems(idx)"
                :label="division.name"
                @input="value => $addressInput.onInput(value, idx)"
              )
        v-flex(
          v-if="renderField('address.zipCode')"
          xs12 md6
        ).px-2
          validation-provider(name="código postal" :rules="rules.address && rules.address.zipCode" v-slot="{ errors }")
            v-text-field(
              v-model="data.address.zipCode"
              :error-messages="errors"
              name="código postal"
              label="código postal"
            )
        v-flex(v-if="renderField('valuationDate')" xs12 md6).px-2
          input-date-picker(
            v-model="data.valuationDate"
            label="Fecha Tasación"
          )
        v-flex(v-if="renderField('realOwnerId')" xs6).px-2
          .d-flex.align-center
            user-select(
              v-model="data.realOwnerId"
              :default-items="realOwnerSelectDefaultItems"
              :disabled="realOwnerForm"
              label="Propietario"
            ).mr-1
            div
              v-tooltip(top)
                template(v-slot:activator="{ on }")
                  v-btn(
                    @click="onClickToggleNewRealOwner"
                    :color="realOwnerForm ? 'primary' : null"
                    icon
                    v-on="on"
                  )
                    v-icon(v-if="realOwnerForm") mdi-account-minus
                    v-icon(v-else) mdi-account-plus
                span(v-if="realOwnerForm") Cancelar creación
                span(v-else) Nuevo propietario
        v-flex(v-if="askRealOwnerRelationship" xs6).px-2
          validation-provider(name="parentesco con el propietario" :rules="{ required: true, min: 3, alpha_spaces: true }" v-slot="{ errors }")
            v-text-field(
              v-model="data.realOwnerRelationship"
              :error-messages="errors"
              name="parentesco con el propietario"
              label="Parentesto con el propietario"
            )
        v-flex(v-if="realOwnerForm" xs12).pa-2
          div(:class="b('inner-form')")
            div(:class="b('inner-form-title')") Nuevo Propietario
            div(:class="b('inner-form-close-button')")
              v-btn(icon @click="onClickToggleNewRealOwner")
                v-icon mdi-close
            property-owner-form(
              ref="propertyRealOwnerForm"
            )
      v-divider
      v-card-actions
        slot(name="actions")
          v-spacer
          v-btn(color="error" text @click="onCancel") Cancelar
          v-btn(
            :loading="requesting"
            color="primary"
            text
            type="submit"
          ) Guardar
</template>

<script>
import omit from 'lodash/omit';
import last from 'lodash/last';
import compact from 'lodash/compact';
import pick from 'lodash/pick';

import basicFormMixin from '@/components/forms/basic-form-mixin';
import addressInputMixin from '@/mixins/address-input-mixin';
import CurrencySelect from '@/components/inputs/currency-select';
import InputDatePicker from '@/components/inputs/input-date-picker';
import PropertyTypeSelect from '@/components/inputs/property-type-select';
import UserSelect from '@/components/inputs/user-select';
import PropertyOwnerForm from '@/components/forms/property-owner-form';

import Config from '@/config';
import { IS_MX_COUNTRY } from '@/utils/country-checker';

export default {
  name: 'property-form',

  props: {
    fillRisk: { type: Boolean, default: false },
    customer: { type: Object },
  },

  data() {
    return {
      realOwnerForm: false,
      lastRealOwnerIdBeforeOpenForm: null,
    };
  },

  mixins: [
    basicFormMixin({
      modelKey: 'property',
      veeValidation: true,
      mapPropsToData({ property }) {
        return {
          id: property?.id,
          value: property?.value,
          auctionValue: property?.auctionValue,
          realOwnerId: property?.realOwner?.id || this.customer?.id,
          realOwnerRelationship: property?.realOwnerRelationship || null,
          currency: property?.currency || Config.CURRENCIES_SELECT[0],
          totalArea: property?.totalArea || null,
          registrationNumber: property?.registrationNumber || null,
          typeId: property?.type?.id,
          valuationDate: property?.valuationDate,
          yearOfConstruction: [0, null, undefined].includes(
            property?.yearOfConstruction,
          )
            ? null
            : property.yearOfConstruction,
          address: {
            ...property?.address,
            _locationIds: property?.address?.location?.length
              ? [...property.address.location].map(({ id }) => id)
              : ['1'],
          },
        };
      },

      parseSubmit() {
        const { currency, ...data } = this.data;

        return {
          property: {
            ...data,
            currencyId: currency?.id,
            value: data.value || null,
            auctionValue: data.auctionValue || null,
            yearOfConstruction: data.yearOfConstruction || null,
            totalArea: data.totalArea || null,
            address: {
              ...omit(data.address, [
                'id',
                '__typename',
                'location',
                '_locationIds',
              ]),
              locationId: last(data.address._locationIds),
            },
          },
        };
      },
    }),

    addressInputMixin(),
  ],

  computed: {
    rules() {
      return Config.PROPERTY_FORM_RULES({ data: this.data });
    },

    isTerrain() {
      return this.data.typeId === Config.PROPERTY_TYPE_TERRAIN_ID;
    },

    typeDefaultItems() {
      return this.property?.type ? [this.property.type] : [];
    },

    realOwnerSelectDefaultItems() {
      return compact([
        this.property?.realOwner && { ...this.property?.realOwner },
        this.customer &&
        pick(this.customer, [
          'id',
          'firstName',
          'lastName',
          'email',
          'vat',
          'avatar',
        ]),
      ]);
    },

    askRealOwnerRelationship() {
      return (
        this.data.realOwnerId && this.data.realOwnerId !== this.customer?.id
      );
    },

    internalHiddenFields() {
      return IS_MX_COUNTRY ? ['currencyId'] : [];
    },
  },

  methods: {
    onInputPropertyType(id) {
      if (Config.PROPERTY_TYPE_TERRAIN_ID === id)
        this.data.yearOfConstruction = null;
    },

    onClickToggleNewRealOwner() {
      this.realOwnerForm = !this.realOwnerForm;

      if (this.realOwnerForm) {
        this.lastRealOwnerIdBeforeOpenForm = this.data.realOwnerId;
        this.data.realOwnerId = '4';
      } else {
        this.data.realOwnerId = this.lastRealOwnerIdBeforeOpenForm;
      }
    },

    async onSubmit() {
      try {
        await this.validate();

        if (this.realOwnerForm) {
          const realOwnerFormData = await this.$refs.propertyRealOwnerForm.getFormData();

          if (!realOwnerFormData?.customer) return;

          this.$emit('submit', {
            ...this.getSubmitData(),
            realOwner: realOwnerFormData.customer,
          });
        } else {
          this.$emit('submit', this.getSubmitData());
        }
      } catch {
        return;
      }
    },
  },

  components: {
    CurrencySelect,
    InputDatePicker,
    PropertyTypeSelect,
    UserSelect,
    PropertyOwnerForm,
  },
};
</script>

<style lang="sass" scoped>
.property-form
  &__inner-form
    position: relative

    padding: 6px
    background-color: darken(white, 4%)
    border-radius: 6px

    &-title
      text-align: center
      font-size: 16px
      font-weight: 500
      margin-bottom: 6px

    &-close-button
      position: absolute
      top: 6px
      right: 8px
</style>
