import {
  NEW_MORTGAGE_SLUG,
  BUY_MORTGAGE_SLUG,
  HOME_EQUITY_LOAN_SLUG,
  DEBT_CONSOLIDATION_PRODUCT_MOTIVE,
  WORK_CAPITAL_PRODUCT_MOTIVE,
  HOUSE_REMODELING_PRODUCT_MOTIVE,
  BAD_BURO_PRODUCT_MOTIVE,
  COVID_PRODUCT_MOTIVE,
  LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE,
  LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE,
  LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE,
  RESCHEDULE_MOTIVE,
} from '@/utils/constants';

export default (product, motive) => {
  if (product === NEW_MORTGAGE_SLUG) return 'Compra de inmueble';
  else if (product === BUY_MORTGAGE_SLUG) return 'Traslado de crédito hipotecario';
  else if (product === HOME_EQUITY_LOAN_SLUG)
    if (motive === DEBT_CONSOLIDATION_PRODUCT_MOTIVE) return 'Consolidación de deudas';
    else if (motive === WORK_CAPITAL_PRODUCT_MOTIVE) return 'Capital de trabajo para negocio';
    else if (motive === HOUSE_REMODELING_PRODUCT_MOTIVE) return 'Remodelación/Construcción de casa';
    else if (motive === BAD_BURO_PRODUCT_MOTIVE) return 'Mal historial crediticio';
    else if (motive === COVID_PRODUCT_MOTIVE) return 'Préstamo COVID';
    else if (motive === LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE) return 'Ampliación con consolidación';
    else if (motive === LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE) return 'Ampliación para remodelación';
    else if (motive === LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE) return 'Ampliación capital de trabajo';
    else if (motive === RESCHEDULE_MOTIVE) return 'Refinanciamiento';
    else return 'Otros destinos'
  return;
}
