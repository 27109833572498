import isEqual from 'lodash/isEqual';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import PerPageSelect from '@/components/inputs/per-page-select';

export default ({
  filtersDefaultValues,
}) => ({

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      quotes: {
        page: 1,
        objects: [],
      },
      quotesError: null,
      filters: filtersDefaultValues,
    };
  },

  methods: {
    onRetryQuery() {
      this.quotesError = null;
      this.$apollo.queries.quotes.refetch();
    },

    onSubmitFilters({ filters }) {
      if(isEqual({ ...filters }, { ...this.filters })) {
        this.$apollo.queries.quotes.refetch();
      } else Object.assign(this.filters, filters);
    },

    onOrder({ key, flow }) {
      this.filters.orderBy = key;
      this.filters.orderFlow = flow;
    },
  },

  components: { LoadingWrapper, PerPageSelect },
});