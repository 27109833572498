<template lang="pug">
  div(:class="b()")
    v-expansion-panels(accordion)
      credits-group(
        v-for="(gCredits, gTitle) in groups"
        :key="gTitle"
        :credits="gCredits"
        :title="gTitle"
      ).py-2
</template>

<script>
  import flow from 'lodash/flow';
  import groupBy from 'lodash/groupBy';

  import humanizePreventionKey from '../../utils/humanize-prevention-key';
  import CreditsGroup from './credits-group'

  const sortCredits = (credits = []) => [...credits]
    .sort((a, b) => {
      if(!a.clavePrevencion || b.clavePrevencion === 'CC') return -10;
      else if (!b.clavePrevencion || a.clavePrevencion === 'CC') return 10;
      else return 0
    });

  export default {
    name: 'credits',

    props: {
      credits: { type: Array, default: () => [] },
    },

    computed: {
      groups() {
        return flow(
          sortCredits,
          credits => credits.map(credit => ({
            ...credit,
            _clavePrevencion: humanizePreventionKey(credit.clavePrevencion || 'OP'),
          })),
          credits => groupBy(credits, '_clavePrevencion'),
        )(this.credits)
      },
    },

    components: { CreditsGroup },
  };
</script>

<style lang="sass" scoped>
  .credits
    &__expansion-panel
      box-shadow: none !important

  .v-expansion-panel
    &__container
      border: 0 !important
</style>