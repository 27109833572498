<template lang="pug">
  div(:class="b({ empty: !value, error: Boolean(errorMessages.length) })" @click="onClickImage")
    figure(:class="b('image')")
      img(:src="value")
    input(
      :class="b('input')"
      @change="onChangeInput"
      ref="input"
      type="file"
      accept=".png,.jpg,.jpeg,.bmp"
    )
    .v-messages.error--text.mt-2(v-if="errorMessages && errorMessages.length")
      .v-messages__message(v-for="error in errorMessages" :key="error") {{ error }}
    div(:class="b('overlay')")
      v-icon {{ overlayIcon }}
</template>

<script>
  import UserAvatar from '@/components/UI/user-avatar';

  const fileToBase64 = (file) => new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  export default {
    name: 'image-preview-input',

    props: {
      value: { type: String },
      errorMessages: { type: Array },
    },

    methods: {
      onClickImage() {
        this.$refs.input.click();
      },

      onChangeInput(event) {
        const files = event.target.files;

        if(!files.length) return;

        fileToBase64(files[0])
          .then(base64 => this.$emit('input', base64));
      },
    },

    computed: {
      overlayIcon() {
        return !this.value ? 'mdi-cloud-upload' : 'mdi-pencil';
      },
    },

    components: { UserAvatar },
  };
</script>

<style lang="sass" scoped>
  .image-preview-input
    $c: &

    position: relative
    cursor: pointer
    margin-bottom: 24px

    &__input
      display: none

    &__image
      width: 100%
      height: 100%

      img
        width: 100%
        height: 100%
        object-fit: cover

    &__overlay
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      background-color: rgba(0,0,0,0.3)
      opacity: 0

      &:hover
        opacity: 1

      i
        color: darken(white, 10%)
        font-size: 32px

    &--empty
      #{$c}
        &__overlay
          opacity: 1

    &--error
      border: 2px solid red
</style>