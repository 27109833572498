<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :width="1000")
    .px-4.pb-3
      .font-weight-medium Detalles del contrato
    v-divider.mb-4

    contract-form(
      :contract="contract"
      :disabled-fields="disabledFields"
      :to-pay-details-disabled-fields="toPayDetailsDisabledFields"
      :to-pay-details-total="toPayDetailsTotal"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import formModalMixin from './form-modal-mixin';
  import ContractForm from '@/components/forms/contract-form';

  export default {
    name: 'contract-form-modal',

    props: {
      disabledFields: { type: Array, default: () => [] },
      toPayDetailsDisabledFields: { type: Array, default: () => [] },
      toPayDetailsTotal: { type: Number },
    },

    mixins: [formModalMixin({ entityName: 'contract' })],

    components: { ContractForm },
  };
</script>
