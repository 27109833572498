<template lang="pug">
  v-input(
    :error-messages="errorMessages"
  )
    v-select(
      :class="b('code-select')"
      v-model="code"
      :items="phoneCodes"
      hide-details
    )
    v-text-field(
      v-model.number="number"
      type="number"
      hide-details
    )
</template>

<script>
  import assign from 'lodash/assign';
  import Config from '@/config';

  const CODES = [
    { text: `+(${Config.PHONE_CODE})`, value: Config.PHONE_CODE },
  ];

  const destructNumber = number => ({
    code: parseInt(number.slice(0, 2)),
    number: parseInt(number.slice(2, number.length)),
  });

  export default {
    name: 'phone-input',

    props: {
      value: { type: String },
      label: { type: String, default: 'Teléfono' },
      errorMessages: { type: Array },
    },

    data() {
      return {
        phoneCodes: CODES,
        code: Config.PHONE_CODE,
        number: null,
      };
    },

    methods: {
      onChangeAnyInput() {
        if(this.number) {
          this.$emit('input', String(this.code) + String(this.number || ''));
        } else {
          this.$emit('input', null);
        }
      },
    },

    mounted() {
      if(this.value) assign(this, destructNumber(this.value));
    },

    watch: {
      code() {
        this.onChangeAnyInput();
      },

      number() {
        this.onChangeAnyInput();
      },

      value(v) {
        if(v) assign(this, destructNumber(v));
      },
    },
  };
</script>

<style lang="sass" scoped>
  .phone-input
    &__code-select
      width: 90px
      margin-right: 1rem

</style>