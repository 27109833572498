<template lang="pug">
  div(:class="b()")
    div(v-for="item in items" :key="item.step" :class="b('item')" @click="$emit('select', item.step)")
      v-icon(:class="b('item-icon')") {{ item.icon }}
      div(:class="b('item-text')") {{ item.text }}
    div(:class="b('item')" @click="$emit('close')")
      v-icon(:class="b('item-icon')") mdi-exit-to-app
      div(:class="b('item-text')") ¿Ver Cliente?

</template>

<script>
export default {
  name: 'options-menu',

  props: {
    items: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
  .options-menu
    max-width: 400px
    margin: 0 auto

    &__item
      display: flex
      border: 1px solid $primaryColor
      border-radius: 6px
      padding: 8px 12px
      margin-bottom: 8px
      cursor: pointer
      color: $primaryColor

      &:hover
        background-color: darken(white, 2%)

      i
        color: $primaryColor

      &-icon
        margin-right: 12px

      &-text
        font-size: 16px
</style>