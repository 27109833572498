<template lang="pug">
  thead
    tr
      th #
      th Venc.
      th Cuota
      th Amortización
      th Interés
      th Seguros
      th Mora y#[br]Penalidad
      th Fecha Pago
      th Estado
</template>

<script>
export default {
  name: 'investment-proposals-detail-quotes-table-head',
};
</script>

<style lang="sass" scoped>

</style>
