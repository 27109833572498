<template lang="pug">
  form-modal(:value="value" @input="args => $emit('input', args)" :persistent="false")
    adviser-landing-form(
      :landing="landing"
      :requesting="requesting"
      :user="user"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
    div(v-if="landing").pt-3.pb-4
      testimonies(
        :testimonies="landing && landing.testimonies || []"
        @click-delete="onClickDeleteTestimony"
        @click-edit="onClickEditTestimony"
      )
      testimony-form(
        v-if="testimonyForm"
        :testimony="editingTestimony"
        :requesting="requesting"
        @submit="args => $emit('submit-testimony', args)"
        @cancel="testimonyForm = false"
      ).pt-2
      .text-center(v-else)
        v-btn(@click="onClickAddTestimony" color="primary" text)
          v-icon.pr-2 mdi-comment-plus-outline
          span Agregar Nuevo Testimonio
</template>

<script>
  import AdviserLandingForm from '@/components/forms/adviser-landing-form';
  import Testimonies from './testimonies';
  import TestimonyForm from '@/components/forms/testimony-form';

  import FormModal from '../base-form-modal';

  export default {
    name: 'adviser-landing-form-modal',

    props: {
      value: { type: Boolean },
      landing: { type: Object },
      user: { type: Object },
      requesting: { type: Boolean },
    },

    data() {
      return {
        testimonyForm: false,
        editingTestimony: null,
      };
    },

    methods: {
      closeTestimonyForm() {
        this.testimonyForm = false;
      },

      onClickAddTestimony() {
        this.editingTestimony = null;
        this.testimonyForm = true;
      },

      onClickEditTestimony({ testimony }) {
        this.editingTestimony = testimony
        this.testimonyForm = true;
      },

      onClickDeleteTestimony({ testimony }) {
        if (this.editingTestimony?.id === testimony.id) {
          this.testimonyForm = false;
          this.editingTestimony = null;
        }

        this.$emit('click-delete', { testimony });
      },
    },

    components: { FormModal, AdviserLandingForm, TestimonyForm, Testimonies },
  };
</script>
