<template lang="pug">
  div(:class="b({ circle })" :style="styles" @mouseenter="onMouseEnter")
    requirement-card(ref="requirementCard" :requirement="requirement")
</template>

<script>
  import Popper from 'popper.js';
  import RequirementCard from './requirement-card';

  export default {
    name: 'requirements-item',

    data() {
      return {
        popperInstance: null,
      };
    },

    props: {
      requirement: { type: Object, required: true },
      circle: { type: Boolean },
    },

    computed: {
      styles() {
        return {
          backgroundColor: this.requirement.status.color,
        };
      },
    },

    methods: {
      onMouseEnter() {
        this.positionatePopper();
      },

      positionatePopper() {
        new Popper(
          this.$el,
          this.$refs.requirementCard.$el,
          {
            modifiers: {
              preventOverflow: {
                boundariesElement: document.querySelector('.deals-list-view'),
              },
            },
          },
        );
      },
    },

    components: {
      RequirementCard,
    },
  };
</script>

<style lang="sass" scoped>
  .requirements-item
    flex-basis: 50px
    height: 8px
    border-radius: 2px

    &--circle
      $size: 16px

      flex-basis: $size
      width: $size
      height: $size
      border-radius: 50%

    .requirement-card
      display: none
      cursor: initial

    &:hover
      .requirement-card
        display: block
</style>