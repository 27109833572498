<script>
  import { Bar } from 'vue-chartjs';

  export default {
    extends: Bar,

    props: {
      currentValue: { type: Array, default: () => [] },
    },

    mounted() {
      const labels = this.currentValue.map(({ name }) => name);
			const data = this.currentValue.map(({ ratio }) => ratio);

      this.renderChart({
        labels,
        datasets: [
          {
						label: 'Total aplicaciones de crédito',
						backgroundColor: '#ceedf382',
						data,
					},
        ],
      }, {
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
        },
        responsive: true,
				maintainAspectRatio: false,
      });
    },
  };
</script>

<style lang="sass" scoped>

</style>