<template lang="pug">
  form-modal(
    :value="value"
    :width="500"
    :persistent="false"
    @input="args => $emit('input', args)"
  )
    .pa-2.pb-3
      products-list(
        @click="onClickProduct"
        :products="products"
      )
      v-alert(
        v-if="!products.length"
        :value="true"
        type="info"
        outlined
      ) No hay productos disponibles para este usuario
</template>

<script>
  import FormModal from './base-form-modal';
  import ProductsList from '@/components/lists/products-list';

  export default {
    name: 'deal-form-modal',

    props: {
      requesting: { type: Boolean, default: false },
      value: { type: Boolean, default: false },
      products: { type: Array, default: () => [] },
    },

    methods: {
      onClickProduct({ product }) {
        if(product.errorMessages.length) return;

        this.$emit('create-deal', { product });
      },
    },

    components: { ProductsList, FormModal },
  };
</script>
