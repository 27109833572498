<template lang="pug">
  div(:class="b()")
    div(:class="b('container')")
      div(:class="b('toolbar')" ref="toolbar")
        filters(
          :current-filters="filters"
          :hide-filters="hiddenFilters"
          @add="onAddFilter"
          @remove="onRemoveFilter"
        )
      div(:class="b('body')")
        div(:class="b('statuses-container')")
          status(
            v-for="status in statuses.results"
            :key="status.value"
            :status="status.value"
            :name="status.name"
            :risk-files="status.riskFiles"
            :loading="statusIsLoading(status.value)"
            @load-more="onLoadMore"
          )
    v-btn(:class="b('fab')" fab color="primary" @click="onClickFab")
      v-icon mdi-filter-outline
</template>

<script>
  import $ from 'jquery';
  import $update from 'immutability-helper';
  import { RISK_OPINION_STATUS, HOME_EQUITY_LOAN_SLUG } from '@/utils/constants';

  import Status from './components/status';
  import Filters from './components/filters';
  import { RiskStatusFilesQuery } from './graphql/queries.gql';

  const ITEMS_PER_PAGE = 10;

  export default {
    name: 'risk-list-view',


    data() {
      return {
        statuses: {
          results: [
            RISK_OPINION_STATUS.PENDING,
            RISK_OPINION_STATUS.OBSERVED,
            RISK_OPINION_STATUS.APPROVED,
            RISK_OPINION_STATUS.REFUSED,
          ].map(value => ({
            value,
            name: this.$t(`riskOpinionStatus.${value}`)
          })),
          fetchingStatuses: [],
          error: null,
        },
        filters: [
          {
            key: 'active',
            value: true,
            text: 'Activa',
          },
          {
            key: 'productSlug',
            value: HOME_EQUITY_LOAN_SLUG,
            text: 'Préstamo de libre disponibilidad'
          }
        ],
        hiddenFilters: [],
      };
    },

    methods: {
      async $fetchStatusFiles(status, statusIndex) {
        this.$apollo.addSmartQuery(`${status}_files`, {
          query: RiskStatusFilesQuery,
          fetchPolicy: 'cache-and-network',

          manual: true,

          variables() {
            return {
              page: 1,
              ...this.filtersByValues,
              perPage: ITEMS_PER_PAGE,
              status,
            };
          },

          result({ data }) {
            if(!data) return;

            this.$set(this.statuses.results[statusIndex], 'riskFiles', data.riskFiles);
          },
        });
      },

      statusIsLoading(value) {
        return this.statuses.fetchingStatuses.includes(status.value) || this.$apollo.queries[value + '_files']?.loading;
      },

      async onLoadMore({ status, nextRiskFilePage }) {
        this.statuses.fetchingStatuses.push(status);

        this.$apollo.queries[`${status}_files`].fetchMore({
          variables: {
            page: nextRiskFilePage,
              ...this.filtersByValues,
            status,
          },

          updateQuery: (previousResult, { fetchMoreResult }) => {
            this.statuses.fetchingStatuses = this.statuses.fetchingStatuses.filter(cStatus => cStatus !== status);

            return $update(previousResult, {
              riskFiles: {
                page: { $set: fetchMoreResult.riskFiles.page },
                hasNext: { $set: fetchMoreResult.riskFiles.hasNext },
                objects: { $push: fetchMoreResult.riskFiles.objects },
              },
            });
          },
        });
      },

      onAddFilter(filter) {
        this.filters.push(filter);
      },

      onRemoveFilter(key) {
        this.$delete(this.filters, this.filters.findIndex(filter => filter.key === key ));
      },

      onClickFab() {
        $(this.$refs.toolbar).slideToggle();
      },
    },

    computed: {
      filtersByValues() {
        return this.filters.reduce(
          (obj, { key, value }) => ({ ...obj, [key]: value }),
          {},
        );
      },
    },

    components: { Status, Filters },

    mounted() {
      if(window.innerWidth < 600) $(this.$refs.toolbar).hide();
    },

    created() {
      this.statuses.results.forEach(({ value }, index) => this.$fetchStatusFiles(value, index))
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .risk-list-view
    &__container
      display: flex
      flex-direction: column
      height: calc(100vh - 64px)

    &__body
      padding-top: 2rem
      padding-left: 1rem
      padding-right: 1rem
      height: 100%
      overflow-x: auto

    &__toolbar
      padding: 0 1rem 1rem 1rem
      display: flex
      align-items: center
      justify-content: flex-end
      flex-wrap: wrap
      background-color: white
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.08)

      .filters
        width: 100%

      +sm
        padding: 0 1rem

        .filters
          flex: 1

    &__fab
      position: fixed
      bottom: 16px
      right: 16px

      +sm
        display: none

    &__statuses-container
      display: flex

      height: 100%
</style>