import DialogTrigger from './dialog-trigger';
import AddressDialog from './address-dialog';
import LegalRepresentativesDialog from './legal-representatives-dialog';
import SunatsDialog from './sunats-dialog';
import UnpaidDebtsDialog from './unpaid-debts-dialog';
import GoodTaxpayersDialog from './good-taxpayers-dialog';
import GuaranteesDialog from './guarantees-dialog';

export default {
  name: 'dialogs',

  props: {
    results: { type: Object, required: true },
  },

  data() {
    return {
      currentDialog: null,
    };
  },

  computed: {
    hasUnpaidDebts() {
      if(!this.results) return false;

      return this.results.resumeUnpaidDebts.some(({ hasDebt }) => hasDebt) ||
        this.results.afpDebts.some(({ dafpDebtTypes }) => Boolean(dafpDebtTypes.length)) ||
        this.results.canceledCards.some(({ ccNegativeInfos }) => Boolean(ccNegativeInfos.length)) ||
        this.results.closedAccounts.some(({ caNegativeInfos }) => Boolean(caNegativeInfos.length)) ||
        this.results.omissions.some(({ omissionDetail }) => Boolean(omissionDetail.length)) ||
        Boolean(this.results.protests.length) ||
        Boolean(this.results.sunatNegatives.length) ||
        Boolean(this.results.sicoms.length);
    },

    dialogs() {
      return [
        {
          component: AddressDialog,
          hasEntries: Boolean(this.results?.addresses?.length),
          opts: {
            props: {
              title: '¿Tiene direcciones?',
              addresses: this.results?.addresses,
            },
          },
        },
        {
          component: LegalRepresentativesDialog,
          hasEntries: Boolean(this.results?.legalRepresentatives?.length),
          opts: {
            props: {
              title: '¿Representante legal?',
              representatives: this.results?.legalRepresentatives,
            },
          },
        },
        {
          component: SunatsDialog,
          hasEntries: Boolean(this.results?.sunats?.length),
          opts: {
            props: {
              title: '¿Registros SUNAT?',
              sunats: this.results?.sunats,
            },
          },
        },
        {
          component: GoodTaxpayersDialog,
          hasEntries: Boolean(this.results?.goodTaxpayers?.length),
          opts: {
            props: {
              title: '¿Buen contribuyente?',
              taxpayers: this.results?.goodTaxpayers,
            },
          },
        },
        {
          component: UnpaidDebtsDialog,
          hasEntries: this.hasUnpaidDebts,
          opts: {
            props: {
              title: '¿Deudas sin pagar?',
              results: this.results,
              width: 900,
            },
          },
        },
        {
          component: GuaranteesDialog,
          hasEntries: Boolean(this.results?.guarantees?.length),
          opts: {
            props: {
              title: '¿Es Aval?',
              guarantees: this.results?.guarantees || [],
            },
          },
        },
      ];
    }
  },

  methods: {
    onClickOpenDialog({ dialog }) {
      this.dialogs[dialog] = true;
    },
  },

  render(h) {
    const dialogs = this.currentDialog
      ? h(
        this.currentDialog.component,
        Object.assign({}, this.currentDialog.opts, {
          props: {
            ...this.currentDialog.opts.props,
            value: true,
          },
          on: {
            input: () => {
              this.currentDialog = null;
            },
          },
        }),
      ) : null;

    const triggers = this.dialogs.map((dialog) => h(DialogTrigger, {
      props: {
        title: dialog.opts.props.title,
        content: dialog.hasEntries ? 'SI' : 'NO',
        dialog,
        active: dialog.hasEntries,
      },

      on: {
        'open-dialog': (cDialog) => {
          this.currentDialog = cDialog;
        },
      }
    }));

    return h('div', {
      class: this.b(),
    }, [
      triggers,
      dialogs,
    ]);
  },
};