<template lang="pug">
  div(:class="b()").data-card
    .data-card__title Fechas
    .data-card__body
      validation-observer(ref="form")
        form(@submit.prevent="onSubmit" :disabled="fetching")
          v-layout
            v-flex(xs4).px-2
              validation-provider(name="fecha de desembolso" :rules="'required'" v-slot="{ errors }")
                date-picker(
                  v-model="data.initDay"
                  :error-messages="errors"
                  name="fecha de desembolso"
                  label="Fecha de desembolso"
                )
            v-flex(xs4).px-2
              validation-provider(name="fecha de primera cuota" :rules="rules.payDay" v-slot="{ errors }")
                date-picker(
                  v-model="data.payDay"
                  :error-messages="errors"
                  name="fecha de primera cuota"
                  label="Fecha de primera cuota"
                )
            v-flex(xs4).text-right
              v-btn(type="submit" color="primary" :disabled="fetching") Calcular cronograma
</template>

<script>
  import moment from 'moment';
  import DatePicker from '@/components/inputs/input-date-picker';

  export default {
    name: 'dates-form',

    props: {
      fetching: { type: Boolean },
      defaultValues: { type: Object, default: () => ({}) },
    },

    data() {
      return {
        data: {
          initDay: null,
          payDay: null,
          ...this.defaultValues,
        },
      };
    },

    methods: {
      async onSubmit() {
        try {
          const success = await this.$refs.form.validate();

          if(!success) return;

          this.$emit('submit', this.data);
        } catch {
          return;
        }
      },
    },

    computed: {
      rules() {
        return {
          payDay: {
            required: true,
            after: this.data.initDay ? moment(this.data.initDay).format('YYYY-MM-DD') : null,
          },
        };
      },
    },

    components: { DatePicker },
  };
</script>

<style lang="sass" scoped>

</style>