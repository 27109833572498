<template lang="pug">
  div(:class="b()")
    div(:class="b('title')") Deudas a pagar

    debts-table(:debts="debts")
</template>

<script>
import DebtsTable from './debts-table';

export default {
  name: 'selected-debts',

  props: {
    debts: { type: Array, default: () => [] },
  },

  components: { DebtsTable },
};
</script>

<style lang="sass" scoped>
  .selected-debts
    padding: 0px 14px
    padding-bottom: 24px

    &__title
      font-size: 15px
      font-weight: 500

      margin-bottom: 16px
</style>