<template lang="pug">
  router-link(:to="bondRoute" :class="b({ selected })")
    div
      v-checkbox(
        :value="selected"
        hide-details
        @change="onChangeCheckbox"
        @click.native.prevent="() => null"
      ).ma-0.pa-0
    div.font-weight-medium {{ bond.investor ? `${bond.investor.firstName} ${bond.investor.lastName}` : '-' }}
    div.font-weight-medium {{ bond.vehicle ? `${bond.vehicle.firstName} ${bond.vehicle.lastName}` : '-' }}
    div.text-no-wrap {{ bond.disbursementDate | formatDate }}
    div.text-no-wrap {{ bond.issueCode }}
    div.text-no-wrap {{ bond.rate }}%
    div.text-no-wrap {{ bond.quotesQuantity }} meses
    div.text-no-wrap {{ bond.debtAmount | formatCurrency(bond.currency) }}
    div.text-no-wrap {{ bond.totalAmortized | formatCurrency(bond.currency) }}
    div.text-no-wrap {{ bond.totalInterest | formatCurrency(bond.currency) }}
    div(style="width: 120px; max-width: 120px").text-no-wrap
      status-tag(:status="bond.status")
</template>

<script>
import StatusTag from '@/components/UI/bond-status-tag';

export default {
  name: 'bonds-table-item',

  props: {
    bond: { type: Object },
    selected: { type: Boolean, default: false },
  },

  methods: {
    onChangeCheckbox(val) {
      this.$emit(val ? 'select' : 'unselect', this.bond.id);
    },
  },

  computed: {
    bondRoute() {
      return {
        name: 'bonds.show',
        params: { bondId: this.bond.id },
      };
    },
  },

  components: { StatusTag },
};
</script>

<style lang="sass" scoped>
  .bonds-table-item
    display: table-row
    text-decoration: none
    color: inherit

    &:hover
      background-color: darken(white, 1%)

    & > div
      display: table-cell
      padding: 6px 8px
      vertical-align: middle

    &__status
      display: inline-block
      padding: 3px 8px
      border-radius: 6px
      color: white
      font-weight: 500

    &--selected
      background-color: transparentize($primaryColor, 0.85) !important
</style>