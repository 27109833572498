<template lang="pug">
  base-dialog(
    :value="value"
    title="Representantes legales"
    @input="args => $emit('input', args)"
  )
    div(
      v-for="person in representatives"
      :key="person.id"
      :class="b('person')"
    )
      div(v-if="person.names") #[b Nombres: ] {{ person.names }}
      div(v-if="person.documentNumber") #[b Documento: ] {{ person.documentNumber }}
      div(v-if="person.position") #[b Posición: ] {{ person.position }}
      div(v-if="person.date") #[b Fecha: ] {{ person.date | formatDate }}
</template>

<script>
  import BaseDialog from './base-dialog';

  export default {
    name: 'legal-representatives-dialog',

    props: {
      value: { type: Boolean },
      representatives: { type: Array, default: () => [] },
    },

    components: { BaseDialog },
  };
</script>

<style lang="sass" scoped>
  .legal-representatives-dialog
    &__person
      padding-bottom: 16px

      &:last-child
        padding-bottom: 0
</style>