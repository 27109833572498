<template lang="pug">
  div(:class="b()")
    div(:class="b('controls')").d-flex.justify-end.mb-2.mt-2
      v-btn(v-if="!maxCount || referrals.length < maxCount" color="primary" @click="onClickAdd")
        v-icon mdi-plus
        span Agregar
    div(:class="b('content')")
      formalization-referrals-table(
        v-if="referrals.length"
        :validable="validable"
        :referrals="referrals"
        @click-edit="({ referral }) => onClickEdit(referral)"
        @click-validate="onClickValidate"
      )
      v-alert(v-else color="info" outlined).text-center
        div No hay referidos registrados
    referral-form-modal(
      v-if="formModal"
      v-model="formModal"
      :referral="editingEntity"
      :requesting="requesting"
      @submit="onSubmit"
    )
</template>

<script>
import gestionableListMixin from '@/mixins/gestionable-list-mixin';
import ReferralFormModal from '@/components/modals/referral-form-modal';
import FormalizationReferralsTable from './formalization-referrals-table';

export default {
  name: 'formalization-referrals',

  mixins: [
    gestionableListMixin({ entityName: 'referral' }),
  ],

  props: {
    referrals: { type: Array, default: () => ([]) },
    validable: { type: Boolean, default: false },
    requesting: { type: Boolean, default: false },
    maxCount: { type: Number },
  },

  methods: {
    onClickValidate({ referral }) {
      this.$emit('validate', { referral });
    },
  },

  components: { FormalizationReferralsTable, ReferralFormModal },
};
</script>

<style lang="sass" scoped>
</style>