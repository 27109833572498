<template lang="pug">
  table.buro-table
    thead
      tr
        th Empleador
        th Periodo
        th Concepto
        th Monto de renta
        th Monto de retencion
    tbody
      tr(v-for="income, idx in sortedIncomes" :key="idx")
        td {{ income.employer }}
        td {{ income.period | formatDate('MMMM YYYY') }}
        td {{ income.concept }}
        td {{ income.incomeAmount | formatCurrency }}
        td {{ income.withholdingAmount | formatCurrency }}
    tfoot
      tr
        td(colspan=3).font-weight-medium Total
        td {{ totalIncomeAmount | formatCurrency }}
        td {{ totalWithholdingAmount | formatCurrency }}

</template>

<script>
import { sortBy, pipe } from 'lodash/fp';

export default {
  name: 'incomes-last-months-table',

  props: {
    incomes: { type: Array },
  },

  computed: {
    sortedIncomes() {
      return pipe(
        sortBy(['period']),
        results => results.reverse(),
      )(this.incomes);
    },

    totalIncomeAmount() {
      return this.incomes.reduce((acc, { incomeAmount }) => acc + Number(incomeAmount), 0);
    },

    totalWithholdingAmount() {
      return this.incomes.reduce((acc, { withholdingAmount }) => acc + Number(withholdingAmount), 0);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>