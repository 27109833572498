<template lang="pug">
  div
    template(v-for="income, i in incomes")
      income(
        :income="income"
        @edit="args => $emit('edit', args)"
        @delete="args => $emit('delete', args)"
      )
      v-divider(v-if="i + 1 < incomes.length").my-2
</template>

<script>
  import Income from './incomes-row';

  export default {
    name: 'incomes',

    props: {
      incomes: { type: Array, default: () => ([]) },
    },

    components: { Income },
  };
</script>
