<template lang="pug">
  div(:class="b()")
    div(:class="b('body')")
      div(:class="b('body-head')")
        div(:class="b('body-head-icon')")
          v-icon mdi-file-document-outline
        div(:class="b('body-head-content')")
          div(:class="b('body-title')") Información del Contrato (# {{ contract.id }})
            status-tag(:class="b('body-status-tag')" :status="contract.status")
          div(:class="b('body-subtitle')") Creado: {{ contract.createdAt | formatDate('D MMMM YYYY - hh:mm a') }}

      v-layout(wrap).pb-4
        v-flex(xs12 lg7).pa-2
          div(:class="b('pay-details')")
            h3.text--secondary.mb-2 Deudas a pagar
            contract-pay-details-table(:pay-details="contract.toPayDetails")

        v-flex(xs12 lg5).pa-2
          div(:class="b('agreements')")
            h3.text--secondary.mb-2 Plantillas Utilizadas

            ul
              li(v-for="agreement in contract.agreements" :key="agreement.id") {{ agreement.name }}
        v-flex(v-if="contract.disbursementProcedure" xs12).pa-2.pt-4
          h3.text-center.text--secondary.mb-4 Mecánica de desembolso

          div(:class="b('disbursement-procedure')" v-html="contract.disbursementProcedure")

    div(:class="b('footer')")
      v-btn(color="primary" @click="onClickEdit" text).mr-2
        v-icon mdi-pencil
        span.ml-1 Editar
      v-btn(
        color="primary"
        :disabled="!canViewContract"
        :to="{ name: 'formalizations.contract', params: { contractId: contract.id }}"
        target="_blank"
      )
        v-icon mdi-file-pdf
        span.ml-1 Descargar PDF
</template>

<script>
import ContractPayDetailsTable from '@/components/UI/contract-pay-details-table';
import StatusTag from '@/components/UI/contract-status-tag';
import { CONTRACT_STATUS } from '@/utils/constants';

export default {
  name: 'contract-item',

  props: {
    contract: { type: Object, required: true },
  },

  methods: {
    onClickEdit() {
      this.$emit('click-edit', { contract: this.contract });
    },
  },

  computed: {
    canViewContract() {
      return this.contract.status === CONTRACT_STATUS.PENDING;
    },
  },

  components: { StatusTag, ContractPayDetailsTable },
};
</script>

<style lang="sass" scoped>
  .contract-item
    padding: 12px 18px

    border-radius: 6px
    background-color: darken(white, 2%)

    &__body
      &-head
        display: flex
        margin-bottom: 16px

        &-icon
          display: flex
          justify-content: center
          align-items: center
          width: 48px
          height: 48px
          margin-right: 14px
          border-radius: 50%
          background-color: $primaryColor

          i
            color: white
            font-size: 32px

      &-content
        flex: 1

      &-title
        font-size: 1.2em
        font-weight: 500

      &-status-tag
        margin-left: 8px
        font-size: 14px

      &-subtitle
        font-size: 0.9em

    &__footer
      display: flex
      justify-content: flex-end
</style>

<style lang="sass">
  .contract-item
    &__disbursement-procedure
      @import '~@/sass/message-format'
</style>