import { onLogout as logoutFromApollo } from '@/plugins/vue-apollo';
import authStorage from '@/utils/auth-storage';
import AuthEventBus from '@/event-bus/auth-event-bus';
import { PERMISSIONS } from '@/utils/constants';

export default () => ({
  methods: {
    onLogout() {
      logoutFromApollo();

      this.redirectToLogin();
    },

    redirectToLogin() {
      this.$router.push({ name: 'login', query: { next: this.$route.path, ...this.$route.query } });
    },

    checkRoutePermissions() {
      const permissions = this.$route.meta?.permissions || [];


      if(!permissions || !this.currentUser) return;

      if(!this.currentUserHasPermissions(permissions)){
        if(this.currentUserHasPermissions([PERMISSIONS.PEGASUS.DASHBOARD]))
          return this.$router.replace({ name: 'dashboard.index' });
        else if(this.currentUserHasPermissions([PERMISSIONS.PEGASUS.DEALS]))
          return this.$router.replace({ name: 'deals.list' });
        else if(this.currentUserHasPermissions([PERMISSIONS.PEGASUS.FORMALIZATIONS]))
          return this.$router.replace({ name: 'formalizations.board' });
      }
    },
  },

  created() {
    if(authStorage.tokenHasExpired()) {
      // eslint-disable-next-line no-console
      console.debug('[RTC-AUTH]: Calling onLogout due to expired token');

      return this.onLogout();
    }

    this.checkRoutePermissions();
  },

  mounted() {
    AuthEventBus.$on('expire', () => {
      // eslint-disable-next-line no-console
      console.debug('[RTC-AUTH]: Calling onLogout due to expired token response from graphql');

      if(this.currentUser) this.onLogout();
    });
  },

  watch: {
    currentUser(newVal) {
      if(newVal) this.checkRoutePermissions();
    },

    '$route.meta'(newVal) {
      if(newVal) this.checkRoutePermissions();
    },
  },
});