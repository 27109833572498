<template lang="pug">
  div(:class="b()")
    figure(:class="b('logo')")
      img(:src="$projectConfig.COMPANY_LOGO")
</template>

<script>
export default {
  name: 'title-study-header',
};
</script>

<style lang="sass" scoped>
  .title-study-header
    &__logo
      display: block
      margin: 0 auto
      width: 240px

      img
        width: 100%
        height: 100%
</style>