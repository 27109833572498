<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit" :class="b()")
      div(:class="b('control')")
        validation-provider(name="crédito" :rules="rules.creditAmount" v-slot="{ errors }")
          v-text-field(
            v-model.number="data.creditAmount"
            label="Crédito"
            name="crédito"
            :error-messages="errors"
            type="number"
            step=".01"
          )
      div(:class="b('control')")
        v-text-field(
          v-model.number="data.limitMonths"
          label="Plazo"
          name="plazo"
          type="number"
        )
      div(:class="b('control')")
        v-text-field(
          v-model.number="data.tea"
          label="Tasa"
          name="tasa"
          type="number"
          step=".01"
        )
      div(:class="b('control')")
        validation-provider(name="capital al final del periodo" :rules="rules.balloon" v-slot="{ errors }")
          v-text-field(
            v-model.number="data.balloon"
            :error-messages="errors"
            label="Capital al final del periodo"
            prefix="%"
            name="capital al final del periodo"
            type="number"
            step=".01"
          )
      v-divider
      v-card-actions
        v-btn(
          :loading="requesting"
          :disabled="requesting"
          color="primary"
          type="submit"
          block
        ).mt-2 Generar Propuestas

</template>

<script>
import Config from '@/config';
import FeeTypeSelect from '@/components/inputs/fee-type-select';
import basicFormMixin from '@/components/forms/basic-form-mixin';

export default {
  name: 'ghost-simulator-form',

  props: {
    propertyValue: { type: Number },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'filters',
      veeValidation: true,

      mapPropsToData() {
        return {
          creditAmount: this.filters?.creditAmount || null,
          limitMonths: this.filters?.limitMonths || null,
          tea: this.filters?.tea || null,
          balloon: this.filters?.balloon || null,
        };
      },

      parseSubmit() {
        return {
          filters: {
            creditAmount: this.data.creditAmount || null,
            limitMonths: this.data.limitMonths || null,
            tea: this.data.tea || null,
            balloon: this.data.balloon || undefined,
          }
        }
      }
    }),
  ],

  computed: {
    rules() {
      return {
        ...Config.PROPOSAL_FILTERS_FORM_RULES({ data: { ...this.data, propertyValue: this.propertyValue } }),
      };
    },
  },

  components: { FeeTypeSelect },
};
</script>

<style lang="sass" scoped>

</style>
