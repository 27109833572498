<template lang="pug">
  form(@submit.prevent="onSubmit" :class="b()")
    v-layout(wrap)
      v-flex(xs12 md5 lg3)
        date-picker(
          v-model="data.disbursementDate"
          label="Fecha Desembolso (Rango)"
          clearable
          range
        )
      v-flex(xs12 md3 lg2)
        investment-proposal-status-select(
          v-model="data.status"
          label="Estado"
          clearable
          multiple
        )
      v-flex(xs12 md3 lg2)
        borrower-select(
          v-model="data.borrowerIds"
          label="Prestatario"
          clearable
          multiple
          deletable-chips
        )
      v-flex(xs12 md3 lg2)
        investor-select(
          v-model="data.investorIds"
          label="Inversionista"
          clearable
          multiple
          deletable-chips
        )
      v-flex.text-right.d-flex.justify-end.align-center
        v-btn(
          color="primary"
          :disabled="isDisabled"
          type="submit"
        ) Aplicar filtros
</template>

<script>
import basicFormMixin from '@/components/forms/basic-form-mixin'

import DatePicker from '@/components/inputs/input-date-picker';
import CountrySelect from '@/views/metrics/components/country-select';
import BorrowerSelect from '@/components/inputs/borrower-select';
import InvestorSelect from '@/components/inputs/investor-select';

import InvestmentProposalStatusSelect from '../../components/investment-proposal-status-select';

export default {
  name: 'filters',

  props: {
    filters: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'filters',
      mapPropsToData({ filters }) {
        return {
          country: filters?.country,
          disbursementDate: filters?.disbursementDate || [],
          status: filters?.status || [],
          borrowerIds: filters?.borrowerIds || [],
          investorIds: filters?.investorIds || [],
        };
      },
    }),
  ],

  components: {
    CountrySelect,
    DatePicker,
    InvestmentProposalStatusSelect,
    BorrowerSelect,
    InvestorSelect,
  },
};
</script>

<style lang="sass" scoped>
  //.filters
</style>