<template lang="pug">
  div
    template(v-for="income, i in incomes")
      income(
        :riskFileId="riskFileId"
        :income="income"
        :isApproved="isApproved"
      )
      v-divider(v-if="i + 1 < incomes.length").my-2
</template>

<script>
  import Income from './incomes-row';

  export default {
    name: 'incomes',

    props: {
      incomes: { type: Array, default: () => ([]) },
      riskFileId: { type: String, default: '' },
      isApproved: { type: Boolean, default: false },
    },

    components: { Income },
  };
</script>
