<template lang="pug">
  v-dialog(
    :value="value"
    @input="args => $emit('input', args)"
    :content-class="contentClass"
    :width="width"
  )
    v-card
      div(:class="b('content')")
        div(:class="b('title')") {{ title }}
        slot
</template>

<script>
  export default {
    name: 'base-dialog',

    props: {
      title: { type: String },
      value: { type: Boolean, default: false },
      contentClass: { type: String },
      width: { type: Number, default: 500 },
    },
  };
</script>

<style lang="sass" scoped>
  .base-dialog
    &__content
      padding: 24px

    &__title
      margin-bottom: 16px
      font-size: 19px
      color: darken(white, 70%)
      text-align: center
</style>