<template lang="pug">
  div(:class="b()")
    loading-wrapper(v-if="!investmentProposal || !borrowerStatement")
    template(v-else)
      div(:class="b('container')")
        view-header(:investment-proposal="investmentProposal" :logo="logo").mb-3
        div(:class="b('print-actions')")
          v-btn(color="primary" text @click="onClickPrint")
            span Imprimir&nbsp;&nbsp;
            v-icon mdi-printer
        div(:class="b('container')")
          v-layout()
            v-flex(mb6 v-if="investmentProposal.status === 'PENDING'")
              investment-proposal-card-pending(
                :statement="borrowerStatement"
                :investment-proposal="investmentProposal"
                :logo="logo"
                :totalAmortization="totals.totalAmortization"
                :entry="entries[0]"
              )
        borrower-statement-table(
          :class="b('table')"
          :currency="investmentProposal.currency"
          :entries="entries"
          :totals="totals"
          :investment-proposal="investmentProposal"
          :logo="logo"
        )
        div(:class="b('summary-box')")
          v-layout(v-if="investmentProposal.status === 'COLLECTING'")
            v-flex(xs5)
              statement-chart(
                v-if="borrowerStatement"
                :totals="totals"
              )
            v-flex(xs5)
              investment-proposal-card(
                :statement="borrowerStatement"
                :investment-proposal="investmentProposal"
              )
          v-flex(mb6 v-if="investmentProposal.status === 'JUDICIALIZED'")
            investment-proposal-card(
              :statement="borrowerStatement"
              :investment-proposal="investmentProposal"
            )
        div(:class="b('summary-box')")
          statement-how-to-pay(
            :investment-proposal="investmentProposal"
          )
</template>

<script>
import { camelizeKeys } from 'humps';
import { BorrowerStatementQuery, InvestmentProposalStatementQuery } from './graphql/queries.gql';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import urlCryptedParamsMixin from '@/mixins/url-crypted-params-mixin';

import ViewHeader from './components/view-header';
import BorrowerStatementTable from './components/borrower-statement-table';
import InvestmentProposalCard from './components/investment-proposal-card';
import InvestmentProposalCardPending from './components/investment-proposal-card-pending';
import StatementChart from './components/statement-chart';
import StatementHowToPay from './components/statement-how-to-pay';
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

export default {
  name: 'borrower-statement',

  mixins: [
    urlCryptedParamsMixin({
      parseKeys: ['investmentProposalId', 'logo'],
      requiredKeys: ['investmentProposalId'],
      brokenParamsMessage: 'El estado de cuenta al que intenta acceder no existe',
    }),
  ],

  apollo: {
    borrowerStatement: {
      query: BorrowerStatementQuery,

      update({ borrowerStatement } = {}) {
        return borrowerStatement?.data ? camelizeKeys(borrowerStatement.data) : null;
      },

      variables() {
        return {
          investmentProposalId: this.investmentProposalId,
        };
      },

      skip() {
        return !this.investmentProposalId;
      },
    },

    investmentProposal: {
      query: InvestmentProposalStatementQuery,

      update({ investmentProposals }) {
        return investmentProposals?.objects?.[0];
      },

      variables() {
        return {
          id: this.investmentProposalId,
        };
      },

      skip() {
        return !this.investmentProposalId;
      },
    },
  },

  data() {
    return {
      investmentProposalId: null,
      investmentProposal: null,
      borrowerStatement: null,

    };
  },

  computed: {
    entries() {
      return this.borrowerStatement?.accountStatement;
    },
    totals() {
      return this.borrowerStatement?.totals;
    },
    showDetail() {
      return this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.COLLECTING || this.investmentProposal?.status === INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED;
    },
  },


  methods: {
    onClickPrint() {
      window.print();
    },
  },

  components: {
    BorrowerStatementTable,
    ViewHeader,
    InvestmentProposalCard,
    InvestmentProposalCardPending,
    LoadingWrapper,
    StatementChart,
    StatementHowToPay,
  },
};
</script>

<style lang="sass">
  .borrower-statement
    padding: 16px 32px
    max-width: 1600px
    margin: 0 auto
    font-size: 13px

    &__container
      max-width: 1200px
      margin: 0 auto

    &__table
      margin-bottom: 24px

    &__print-actions
      padding: 8px 0

    .data-card
      margin-bottom: 16px

      &__title, &__body
        background-color: darken(white, 1%)

      &__title
        display: inline-block
        padding: 6px 24px
        font-size: 17px
        font-weight: 50
        border-radius: 6px 6px 0 0
        background-color: darken(white, 4%)

      &__body
        padding: 12px 24px
        border-radius: 0 6px 6px 6px

    &__summary-box
      background-color: darken(white, 3%)
      padding: 18px


  @media print
    // @page
    //   size: landscape

    .borrower-statement
      padding: 0

      &__print-actions
        display: none

    body
      -webkit-print-color-adjust: exact
</style>
