<template lang="pug">
  div(:class="b()")
    div(:class="b('table-container')")
      table
        thead
          tr
            th
            th Banco
            th Producto
            th Calificación
            th Deuda Total
            th A pagar
            th %
        tbody
          table-row(
            :class="b('row')"
            v-for="debt, idx in internalDebts"
            :key="idx"
            :entry="debt"
            :payment-percent="debt.amount * 100 / totalAmount"
            @select="onSelect(idx, Number(debt.totalAmount))"
            @unselect="onUnselect(idx)"
            @update-payment-amount="amount => onUpdateSelectedAmount(idx , amount)"
          )
        tfoot
          tr
            th(colspan="4") Total
            td {{ totalAmount | formatCurrency }}
            td.font-weight-bold {{ totalPaymentAmount | formatCurrency }}
            td {{ totalPaymentPercent }} %
</template>

<script>
  import TableRow from './debts-table-row';

  export default {
    name: 'debts-table',

    props: {
      debts: { type: Array, default: () => [] },
      value: { type: Array, default: () => [] },
    },

    computed: {
      totalAmount() {
        return this.debts.reduce((acc, { totalAmount }) => acc + Number(totalAmount), 0);
      },

      totalPaymentAmount() {
        return this.value.reduce(
          (acc, { amount }) => acc + amount,
          0
        );
      },

      totalPaymentPercent() {
        return (this.totalPaymentAmount * 100 / this.totalAmount).toFixed(2);
      },

      internalDebts() {
        return this.debts.map((debt, debtIndex) => {
          const selectedInstance = this.value.find(({ index }) => index === debtIndex);

          return {
            ...debt,
            selected: selectedInstance ? true : false,
            amount: selectedInstance?.amount,
          };
        });
      },
    },

    methods: {
      onSelect(debtIndex, amount) {
        this.$emit('input', [
          ...this.value,
          {
            index: debtIndex,
            ...this.debts[debtIndex],
            amount,
          }
        ]);
      },

      onUnselect(debtIndex) {
        const _value = [...this.value];

        _value.splice(_value.findIndex(({ index }) => index === debtIndex), 1);

        this.$emit('input', _value);
      },

      onUpdateSelectedAmount(debtIndex, amount) {
        const _value = [...this.value];

        _value[_value.findIndex(({ index }) => index === debtIndex)].amount = amount;

        this.$emit('input', _value);
      },
    },

    components: { TableRow },
  };
</script>

<style lang="sass" scoped>
  .debts-table
    border-collapse: collapse

    thead
      background-color: $primaryColor
      color: white

      td, th
        padding: 2px 8px

    tfoot
      td, th
        padding: 2px 8px
        background-color: darken(white, 8%)

    table
      max-width: 100%
      margin: 0 auto

    &__table-container
      max-width: 100%
      overflow-x: auto
      margin: 0 auto
</style>