import VSelect from 'vuetify/lib/components/VSelect';
import Config from '@/config';

export default {
  name: 'income-category-select',

  extends: VSelect,

  props: {
    multiple: { type: Boolean, default: false },
    items: { type: Array, default: () => Config.INCOME_CATEGORIES_SELECT },
    label: { type: String, default: 'Categoría' },
  },
};
