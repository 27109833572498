<template lang="pug">
  tfoot
    tr(:class="b()")
      th(colspan="4") Total
      th(v-if="showColumn('interest')") {{ totals.interest | formatCurrency(currency) }}
      th(v-if="showColumn('insurancesProperty')") {{ totals.insurancesProperty | formatCurrency(currency) }}
      th(v-if="showColumn('insurancesLife')") {{ totals.insurancesLife | formatCurrency(currency) }}
      th(v-if="showColumn('defaultsAndPenalties')") {{ totals.defaultsAndPenalties | formatCurrency(currency) }}
      th(v-if="showColumn('managementFee')") {{ totals.managementFee | formatCurrency(currency) }}
      th(v-if="showColumn('fee')") {{ totals.fee | formatCurrency(currency) }}
      th(v-if="showColumn('totalPaid')") {{ totals.totalPaid | formatCurrency(currency) }}
      th(v-if="showColumn('totalAmortization')") {{ totals.totalAmortization | formatCurrency(currency) }}
      th {{ totals.finalBalance | formatCurrency(currency) }}
</template>

<script>
export default {
  name: 'borrower-statement-table-footer',

  props: {
    totals: { type: Object, required: true },
    currency: { type: Object, required: true },
  },

  methods: {
    showColumn(columnName) {
      const value = this.totals[columnName];

      if (!value && value !== 0) return false;

      const numericValue = typeof value === 'string'
        ? parseFloat(value.replace(/[^0-9.-]+/g, ''))
        : parseFloat(value);

      return Boolean(!isNaN(numericValue) && numericValue !== 0);
    },
  },

};
</script>

<style lang="sass" scoped>
  .borrower-statement-table-footer
    th
      padding: 8px 9px
      text-align: right

      &:first-child
        text-align: center
        background-color: darken(white, 10%)
</style>
