<template lang="pug">
  form(@submit.prevent="onSubmit")
    v-text-field(
      v-model="data.username"
      label="Usuario"
      name="username"
      prepend-icon="mdi-account"
      required
    )
    v-text-field(
      v-model="data.password"
      type="password"
      name="password"
      label="Contraseña"
      prepend-icon="mdi-textbox-password"
      required
    )
    br
    v-btn(
      type="submit"
      color="primary"
      block
      :loading="loading"
      :disabled="loading || disabled"
    ) Iniciar Sessión
</template>

<script>
  export default {
    name: 'login-form',

    props: {
      loading: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
    },

    data() {
      return {
        data: {
          username: null,
          password: null,
        },
      };
    },

    methods: {
      onSubmit() {
        this.$emit('submit', this.data);
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>