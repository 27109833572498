<template lang="pug">
  div(:class="b()")
    div
      div(xs12 md12 lg12)
        table(:class="b()")
          tr(:class="b('head')")
            th(colspan="1") General
            th(colspan="1") Titular
            th(v-if="report.couple" colspan="1") Pareja
          tr
            td Peor calificación histórica
            td(:style="{ color: getWorstCalificationColor }") {{ report.worstQualification }}
            td(v-if="report.couple" :style="{ color: getCoupleWorstCalificationColor }") {{ report.couple.worstQualification }}
          tr
            td Tiene pérdida en 12 meses
            td {{ report.hasPerIn12Months == 'True' ? 'Si' : 'No' }}
            td(v-if="report.couple") {{ report.couple.hasPerIn12Months == 'True' ? 'Si' : 'No' }}
          tr
            td Peor calificación último mes
            td(:style="{ color: getWorstCalificationInLastMonthColor }")  {{ report.worstQualifitationInLastMonth }}
            td(v-if="report.couple" :style="{ color: getCoupleWorstCalificationInLastMonthColor }")  {{ report.couple.worstQualifitationInLastMonth }}
          tr
            td Peor calificación último año
            td(:style="{ color: getWorstCalificationInLastYearColor }")  {{ report.worstQualifitationInLastYear != 'None' ? report.worstQualifitationInLastYear : '-' }}
            td(v-if="report.couple" :style="{ color: getCoupleWorstCalificationInLastYearColor }")  {{ report.couple.worstQualifitationInLastYear != 'None' ? report.couple.worstQualifitationInLastYear : '-' }}
          tr
            td Cantidad de deudas actuales
            td {{ report.qtyCurrentDebts }}
            td(v-if="report.couple") {{ report.couple.qtyCurrentDebts }}

      div(xs12 md12 lg12)
        table(:class="b()")
          tr(:class="b('head')")
            th(colspan="1") Situación en pérdida
            th(colspan="1") Titular
            th(v-if="report.couple" colspan="1") Pareja
          tr
            td Máximo número de meses en pérdida
            td {{ report.maxMonthInPer }}
            td(v-if="report.couple") {{ report.couple.maxMonthInPer }}
          tr
            td Período y monto en pérdida
            td {{ report.periodInPer && report.amountInPer ? `${report.periodInPer} (S/ ${report.amountInPer})` : 'Example - Example' }}
            td(v-if="report.couple") {{ report.couple.periodInPer && report.couple.amountInPer ? `${report.couple.periodInPer} (S/ ${report.couple.amountInPer})` : '-' }}
          tr
            td Deudas castigadas
            td {{ report.punishedDebt }}
            td(v-if="report.couple") {{ report.couple.punishedDebt }}
          tr
            td Protestos
            td {{ report.protestsQty && report.protestsTotalAmount ? `${report.protestsQty} (S/ ${report.protestsTotalAmount})` : '-' }}
            td(v-if="report.couple") {{ report.couple.protestsQty && report.couple.protestsTotalAmount ? `${report.couple.protestsQty} (S/ ${report.couple.protestsTotalAmount})` : '-' }}
          tr
            td Probabilidad de impago
            td {{ (report.PD * 100).toFixed(2) }}%
            td(v-if="report.couple") {{ (report.couple.PD * 100).toFixed(2) }}%
</template>

<script>
import getCalificationColor from '@/utils/buro-qualification-color';

export default {
  name: 'risk-variables-report',

  props: {
    report: { type: Object, required: true },
  },

  computed: {
    getWorstCalificationColor() {
      return getCalificationColor(this.report.worstQualification);
    },
    getCoupleWorstCalificationColor() {
      return getCalificationColor(this.report.couple.worstQualification);
    },
    getWorstCalificationInLastMonthColor() {
      return getCalificationColor(this.report.worstQualifitationInLastMonth);
    },
    getCoupleWorstCalificationInLastMonthColor() {
      return getCalificationColor(this.report.couple.worstQualifitationInLastMonth);
    },
    getWorstCalificationInLastYearColor() {
      return getCalificationColor(this.report.worstQualifitationInLastYear);
    },
    getCoupleWorstCalificationInLastYearColor() {
      return getCalificationColor(this.report.couple.worstQualifitationInLastYear);
    },
  },

  components: {},
};
</script>

<style lang="sass" scoped>
  .risk-variables-report
    padding: 12px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column

    div
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
  
      div
        margin: 0 auto
        justify-content: center

    table
      display: block
      th
        background-color: lighten($primaryColor, 17%)
        text-align: center
        color: white
        text-align: center !important
        padding: 3px 8px
      tr
        td
          border-bottom: 1px solid
          border-right: 1px solid
          border-color: darken(white, 7%)
          padding: 3px 8px

          &:first-child
          font-weight: 500

          &:last-child
          border-right: 0

</style>