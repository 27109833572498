<template lang="pug">
  label(:class="b()")
    input(:class="b('input')" type="file" @change="onChange" :multiple="multiple" :accept="accept")
    v-icon(:class="b('icon')").primary--text mdi-upload
</template>

<script>
import iziToast from 'izitoast';

const MAX_MEGA_BYTES_SIZE = 12;
const MAX_FILE_SIZE = 1024000 * MAX_MEGA_BYTES_SIZE;

const validateFiles = (files, extensions) => {
  for (const file of files) {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(
        `El archivo ${file.name} excede los ${MAX_MEGA_BYTES_SIZE} Mb`,
      );
    }

    if (
      !new RegExp(`.*(${extensions.join('|')})$`).test(file.name.toLowerCase())
    ) {
      throw new Error(
        `El archivo ${file.name} no tiene un formato válida (${extensions.join(
          ', ',
        )}).`,
      );
    }
  }
};

export default {
  name: 'upload-icon-input',

  props: {
    multiple: { type: Boolean, default: false },
    extensions: {
      type: Array,
      default: () => ['jpg', 'pdf', 'png', 'docx', 'jpeg', 'xls'],
    },
  },

  methods: {
    onChange({ target: { files } }) {
      try {
        validateFiles(files, this.extensions);

        this.$emit('change', { files });
      } catch (e) {
        iziToast.error({
          message: e.message,
        });
      }
    },
  },

  computed: {
    accept() {
      return this.extensions.map(extension => `.${extension}`).join(', ');
    },
  },
};
</script>

<style lang="sass" scoped>
.upload-icon-input
  cursor: pointer

  &__input
    display: none
</style>
