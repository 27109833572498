<template lang="pug">
  tr(:class="b()")
    td {{ entry.bank }}
    td {{ entry.product }}
    td(:style="{ color: calificationColor }").font-weight-bold {{ entry.qualification | formatCalification }}
    td.text-no-wrap S/ {{ entry.totalAmount | formatNumber }}
    td.text-no-wrap S/ {{ entry.amount | formatNumber }}
</template>

<script>
  import formatCalification from '@/utils/format-buro-qualification';
  import getCalificationColor from '@/utils/buro-qualification-color';

  export default {
    name: 'debts-table-row',

    props: {
      entry: { type: Object, required: true },
    },

    computed: {
      calificationColor() {
        return getCalificationColor(this.entry.qualification);
      },
    },

    filters: {
      formatCalification,
    },
  };
</script>

<style lang="sass" scoped>
  .debts-table-row
    td
      padding: 3px 8px
      border-bottom: 1px solid darken(white, 6%)

      &::nth-child(5)
        min-width: 150px
</style>