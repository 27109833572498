<template lang="pug">
  div(:class="b()")
    div(:class="b('wrapper')")
      div(:class="b('header')")
        div(:class="b('title')") {{ name }} ({{ dealsCount }})
        div(v-if="total" :class="b('total')") {{ total.amount | formatCurrency(total.currency) }}
      div(:class="b('body')")
        draggable(
          v-if="deals"
          :value="deals.objects"
          :style="{ height: '90%', minHeight: '60px' }"
          draggable=".deal-card"
          group="deals"
          @change="onChange"
        )
          deal-card(
            v-for="deal in deals.objects"
            :key="deal.id"
            :deal="deal"
            oncontextmenu="return false;"
          )
          .text-center(slot="footer")
            div(v-if="loading" :class="b('loading-container')")
              loading-spinner
            v-btn(
              v-else-if="deals.hasNext"
              color="primary"
              :disabled="loading"
              @click="onClickLoadMore"
            ) Cargar Más
</template>

<script>
  import Draggable from 'vuedraggable';
  import DealCard from './deal-card';

  export default {
    name: 'stage',

    props: {
      id: { type: String, required: true },
      name: { type: String },
      deals: { type: Object, default: () => ({}) },
      loading: { type: Boolean, default: false },
    },

    methods: {
      onChange({ added }) {
        if(added) {
          this.$emit('move-to-stage', {
            stageId: this.id,
            deal: added.element,
          });
        }
      },

      onClickLoadMore() {
        this.$emit('load-more', {
          stageId: this.id,
          nextDealsPage: this.deals.page + 1,
        });
      },
    },

    computed: {
      total() {
        return this.deals?.total?.[0];
      },

      dealsCount() {
        return this.deals?.count;
      },
    },

    components: { DealCard, Draggable },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .stage
    flex-shrink: 0

    width: 320px
    height: 100%
    margin: 0 4px
    border: 1px solid darken(white, 15%)

    border-radius: 3px

    +xs
      width: 280px

    &__wrapper
      display: flex
      flex-direction: column

      height: 100%

      white-space: normal

    &__header
      padding: .5rem 1rem

      font-weight: bold

      background-color: white

    &__total
      padding-top: .5rem

      font-size: 1.2rem
      font-weight: 500
      text-align: right

    &__body
      flex: 1

      padding-bottom: 1rem

      overflow-y: auto

    &__loading-container
      display: flex
      justify-content: center
      align-items: center

      padding: 1rem 0

</style>