<template lang="pug">
  div(:class="b()")
    table
      thead
        tr
          th
          th Deuda
          th Cuota Estimada
      tbody
        tr(v-if="value.consumer")
          td Consumo
          td(:class="b('debt')") S/ {{ value.consumer.amount | formatNumber }}
          td(v-if="value.consumer")
            v-text-field(
              :value="value.consumer.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'consumer.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'consumer')") mdi-refresh
        tr(v-if="value.vehicular")
          td Vehicular
          td(:class="b('debt')") S/ {{ value.vehicular.amount | formatNumber }}
          td
            v-text-field(
              :value="value.vehicular.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'vehicular.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'vehicular')") mdi-refresh
        tr(v-if="value.mortgage")
          td Hipotecario
          td(:class="b('debt')") S/ {{ value.mortgage.amount | formatNumber }}
          td
            v-text-field(
              :value="value.mortgage.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'mortgage.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'mortgage')") mdi-refresh
        tr(v-if="value.usedCreditCard")
          td Linea Utilizada
          td(:class="b('debt')") S/ {{ value.usedCreditCard.amount | formatNumber }}
          td
            v-text-field(
              :value="value.usedCreditCard.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'usedCreditCard.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'usedCreditCard')") mdi-refresh
        tr(v-if="value.unusedCreditCard")
          td Linea No Utilizada
          td(:class="b('debt')") S/ {{ value.unusedCreditCard.amount | formatNumber }}
          td
            v-text-field(
              :value="value.unusedCreditCard.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'unusedCreditCard.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'unusedCreditCard')") mdi-refresh
        tr(v-if="value.cardWithdrawals")
          td Disposición Efectivo
          td(:class="b('debt')") S/ {{ value.cardWithdrawals.amount | formatNumber }}
          td
            v-text-field(
              :value="value.cardWithdrawals.fee"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'cardWithdrawals.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'cardWithdrawals')") mdi-refresh
        tr(v-if="value.extraLine")
          td Otros
          td(:class="b('debt')" v-if="value.extraLine") S/ {{ value.extraLine.amount | formatNumber }}
          td
            v-text-field(
              :value="value.extraLine ? value.extraLine.fee : ''"
              :disabled="disabled"
              type="number"
              @input="val => onInput(val, 'extraLine.fee')"
              hide-details
            )
          td
            v-icon(v-if="!disabled" @click="$emit('reset', 'extraLine')") mdi-refresh
</template>

<script>
  import $update from 'immutability-helper';

  export default {
    name: 'debts',

    props: {
      value: { type: Object, required: true },
      disabled: { type: Boolean, default: false },
    },

    methods: {
      onInput(val, objKey) {
        const contextKey = objKey.split('.')[0];

        this.$emit('input', $update(this.value, {
          [contextKey]: {
            fee: { $set: Number(val) },
          },
        }));
      },
    },

  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .debts
    border: 1px solid darken(white, 16%)
    padding: 1rem
    border-radius: 6px
    overflow-x: auto

    &__title
      font-weight: bold

    table
      width: 100%
      min-width: 400px

      +lg
        min-width: none

      tbody
        tr
          td
            padding: 3px 8px

          td:first-child
            font-weight: 500
            width: auto

          td:nth-child(2) // debt
            white-space: nowrap

          td:nth-child(3) // input
            max-width: 150px !important

            & > *
              margin-top: -12px
</style>
