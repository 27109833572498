<template lang="pug">
  div
    v-card.pa-2
      v-breadcrumbs(:items="breadcrumbItems")
    v-container
      v-layout(justify-center)
        v-flex(xs12 sm8 md8 lg6)
          v-card.pt-4.px-4
            bond-form(
              :requesting="isRequesting"
              :cancelable="false"
              :hidden-fields="['status']"
              @submit="onSubmitBond"
            )
</template>

<script>
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
  import currentUserMixin from '@/mixins/current-user-mixin';
  import { CreateBondMutation } from './graphql/mutations.gql';
  import BondForm from '@/components/forms/bond-form';

  export default {
    name: 'bonds-edit-view',

    mixins: [
      currentUserMixin(),
      mutationsHandlersMixin(),
    ],

    data() {
      return {
        isRequesting: false,
        breadcrumbItems: [
          { text: 'Bonos', to: { name: 'bonds.list'}, exact: true },
          { text: 'Nuevo Bono', disabled: true }
        ],
      };
    },

    components: {
      BondForm,
    },

    methods: {
      async onSubmitBond({ bond }) {
        this.handleCreateMutation({
          mutation: CreateBondMutation,

          variables: {
            data: { ...bond },
          },
          requestingStateKey: 'isRequesting',
        }).then(({ data: { createBond: { bond }} }) => {
          this.$router.push({ name: 'bonds.show', params: { bondId: bond.id } });
        });
      },
    }
  };
</script>

<style lang="sass" scoped>

</style>