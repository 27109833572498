export default (source, q) => {
  if(!q) return false;

  const queryArray = q.trim().toLowerCase().split(' ');
  const sourceString = (Array.isArray(source) ?
    source.map(text => text?.trim()).join(' ') :
    source.trim()).toLowerCase();

  return queryArray.every(qEntry => sourceString.includes(qEntry));
};
