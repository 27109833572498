<template lang="pug">
  thead
    tr
      th Asesor
      th Solicitudes a propuesta aceptada
      th Propuesta aceptada a file comercial
      th Aprobados a cliente
      th Solicitudes a file comercial
      th Solicitudes a aprobados
      th Solicitudes a clientes
</template>

<script>
  export default {
    name: 'advisers-conversion-table-head',

    props: {
      items: { type: Array, default: () => [] },
    },
  };
</script>

<style lang="sass" scoped>

</style>
