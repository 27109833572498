<script>
import { Bar } from 'vue-chartjs';
import moment from 'moment';
import { capitalize } from 'lodash/fp'
import { formatCurrency } from '@/filters/accounting';
import Config from '@/config';

export default {
  name: 'tax-report-monthly-chart',

  extends: Bar,

  props: {
    entries: { type: Array, default: () => [] },
  },

  computed: {

    labels() {
      return Array.from({ length: 12 }).map((_, index) => capitalize(moment().month(index).format('MMMM')));
    },

    datasets() {
      return [{
        label: `Declaración (${new Date().getFullYear()})`,
        pointBackgroundColor: Config.PRIMARY_COLOR,
        borderColor: Config.PRIMARY_COLOR,
        backgroundColor: Config.PRIMARY_COLOR,
        data: Array.from({ length: 12 }).map(
          (_, index) => Number(this.entries.find(({ month }) => index + 1 === month)?.netAmount || 0),
        ),
      }];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.renderChart({
        labels: this.labels,

        datasets: this.datasets,
      }, {
        maintainAspectRatio: false,

        scales: {
          yAxes: [
            {
              ticks: {
                callback(value) {
                  return formatCurrency(value);
                },
              },
            },
          ],
        },

        tooltips: {
          mode: 'label',
          callbacks: {
            label: ({ value, datasetIndex }) => {
              return value > 0 ? [`${this.datasets[datasetIndex].label}: ${formatCurrency(value)}`] : false;
            },
          },
        },
      });
    });
  },
};
</script>

<style lang="sass" scoped>

</style>