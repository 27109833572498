<template lang="pug">
  form-modal(
    :value="value"
    :content-class="b()"
    :width="width"
    @input="args => $emit('input', args)"
  )
    .px-2.pb-3
      .font-weight-medium Ampliación / Refinanciamiento
    v-divider.mb-4
    investment-refinance-form(
      :class="b('form')"
      :refinance="refinance"
      :currency="currency"
      :requesting="requesting"
      @submit="args => $emit('submit', args)"
      @cancel="$emit('input', false)"
    )
</template>

<script>
  import InvestmentRefinanceForm from '@/components/forms/investment-refinance-form';
  import formModalMixin from './form-modal-mixin';

  export default {
    name: 'investment-refinance-form-modal',

    props: {
      currency: { type: Object },
    },

    mixins: [formModalMixin({ entityName: 'refinance' })],

    components: { InvestmentRefinanceForm },
  };
</script>

<style lang="sass">
</style>
