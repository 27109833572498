<template lang="pug">
  div(:class="b({ trust: bond && bond.trust })")
    div(:class="b('container')")
      v-breadcrumbs(:items="breadcrumbItems").mb-2.pl-0
      loading-wrapper(
        v-if="$apollo.queries.bond.loading || !bond"
        :error="bondError"
        @retry="onRetryBondFetch"
      )
      template(v-else)
        .text-right.pb-2(v-if="canEdit")
          v-btn(color="primary" text @click="editModal = true")
            v-icon.mr-2 mdi-pencil
            span Editar información
        bond-card(
          :bond="bond"
          :currency="currency"
        ).mb-4
        edit-form-modal(
          v-model="editModal"
          :bond="bond"
          :currency="currency"
          :requesting="bondIsMutating"
          :hidden-fields="['investorId']"
          @submit="onSubmitEditForm"
        )
        div(:class="b('quotes')")
          div(:class="b('quotes-item')")
            quotes-card(
              ref="quotesCard"
              :bond="bond"
              :quotes="quotes"
              :currency="currency"
              :next-quote="nextQuote"
              :fetching="$apollo.queries.quotes.loading"
              :mutating="quotesIsMutating"
              @submit-payment="onSubmitQuotePayment"
              @refresh="() => $apollo.queries.quotes.refetch()"
            )
</template>

<script>
  import mutationsHandlersMixin from '@/mixins/mutations-handlers-mixin';
  import orderBy from 'lodash/orderBy';
  import findLast from 'lodash/findLast';
  import find from 'lodash/find';

  import { BOND_STATUS, QUOTE_STATUS } from '@/utils/constants';
  import EditFormModal from '@/components/modals/bond-edit-form-modal';
  import LoadingWrapper from '@/components/wrappers/loading-wrapper';

  import BondCard from './components/bond-card';
  import QuotesCard from './components/quotes-card';
  import {
    BondShowQuery,
    BondShowQuotesQuery,
  } from './graphql/queries.gql';

  import {
    UpdateQuoteMutation,
    UpdateBondMutation,
  } from './graphql/mutations.gql';

  export default {
    name: 'bond-show-view',

    mixins: [mutationsHandlersMixin()],

    apollo: {
      bond: {
        query: BondShowQuery,

        variables() {
          return { id: this.bondId };
        },

        update({ bonds }) {
          const bond = bonds?.objects?.[0];

          return bond || null;
        },

        error() {
          this.bondError = new Error('Ha ocurrido un error al obtener los datos');
        },
      },

      quotes: {
        query: BondShowQuotesQuery,
        manual: true,

        variables() {
          return {
            bondId: this.bondId,
          }
        },

        result({ data }) {
          this.quotes = orderBy(data?.quotes?.objects || [], 'feeNumber', 'desc')
            .map(quote => ({ ...quote, status: quote.status }));
        },
      },
    },

    data() {
      return {
        bond: null,
        bondError: null,
        quotes: [],
        bondIsMutating: false,
        editModal: false,
        quotesIsMutating: false,
        breadcrumbItems: [
          { text: 'Bonos', to: { name: 'bonds.list'}, exact: true },
          { text: 'Resumen del bono', disabled: true }
        ],
      };
    },

    computed: {
      isPayable() {
        return this.bond?.status === BOND_STATUS.COLLECTING;
      },

      currency() {
        return this.bond?.currency;
      },

      bondId() {
        return this.$route.params.bondId;
      },

      firstUnpaidQuote() {
        return this.getFirstUnpaidQuote(this.quotes);
      },

      nextQuote() {
        return this.firstUnpaidQuote?.currentData?.amountDue;
      },

      canEdit() {
        return [BOND_STATUS.PENDING, BOND_STATUS.ASSIGNED].includes(this.bond?.status);
      },
    },

    components: {
      QuotesCard,
      EditFormModal,
      LoadingWrapper,
      BondCard,
    },

    methods: {
      getFirstUnpaidQuote(quotes) {
        return findLast(quotes, quote => !this.quoteIsPaid(quote));
      },

      getLastPaidQuote(quotes) {
        return find(quotes, quote => this.quoteIsPaid(quote));
      },

      quoteIsPaid(quote) {
        return [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED, QUOTE_STATUS.RESCHEDULED].includes(quote?.status);
      },

      onRetryBondFetch() {
        this.bondError = null;
        this.$apollo.queries.bond.refetch();
      },

      onSubmitQuotePayment({ data }) {
        this.handleUpdateMutation({
          mutation: UpdateQuoteMutation,

          variables: {
            data: {
              id: data.quoteId,
              paymentOperationNumber: data.operationNumber,
              amount: data.amount,
              paymentDate: data.date,
            },
          },

          requestingStateKey: 'quotesIsMutating',
        }).then(() => {
          this.$apollo.queries.quotes.refetch();
          this.$refs.quotesCard.closePaymentForm();
        });
      },

      onSubmitEditForm({ bond }) {
        this.handleUpdateMutation({
          mutation: UpdateBondMutation,

          variables: {
            data: {
              id: this.bondId,
              ...bond
            },
          },

          query: {
            query: BondShowQuery,

            variables: { id: this.bondId },
          },

          requestingStateKey: 'bondIsMutating'
        }).then(() => this.editModal = false);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .bond-show-view
    $c: &

    &__container
      padding: 16px
      max-width: 1350px
      margin: 0 auto

    &__quotes
      display: flex
      justify-content: center
      overflow-x: auto
      margin: -16px
      padding: 16px

      &-item
        min-width: 380px
        max-width: 800px

        &:first-child
          padding-right: 12px

        &:last-child
          padding-left: 12px

    +md
      &__quotes
        &-item
          flex: 1

    +lg
      &__container
        padding-top: 36px
</style>