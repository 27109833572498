const KEYS = {
  AD: 'Cuenta en aclaración',
  CA: 'Cartera al corriente vendida',
  CC: 'Cuenta cancelada o cerrada',
  CD: 'Convenio y disminución de pago',
  CL: 'Cuenta cerrada que estuvo en cobranza y fue pagada totalmente sin causar quebranto',
  CO: 'Crédito en controversia',
  CV: 'Cuenta que no está al corriente vendida o cedida a un usuario de una sociedad',
  FD: 'Cuenta fraudulenta',
  FN: 'Fraude no atribuile al consumidor',
  FP: 'Fianza pagada',
  FR: 'Adjudicación o aplicación de garantía',
  GP: 'Ejecución de garantía prendaria o fiduciaria en pago por crédito',
  IA: 'Cuenta inactiva',
  IM: 'Integrante causante de mora',
  IS: 'Integrante que fue subsidiado para evitar mora',
  LC: 'Convenio de finiquito o pago menor acordado con el consumidor',
  LG: 'Pago menor por programa institucional o gobierno',
  LO: 'En localización',
  LS: 'Tarjeta de crédito extraviada o robada',
  NA: 'Cuenta al corriente vendida o cedida a un NO usuario de una sociedad',
  NV: 'Cuenta que no está al corriente vendida o cedida a un NO usuario de una sociedad',
  PC: 'Cuenta en cobranza',
  PD: 'Prórroga otorgada debido a un desastre natural',
  PE: 'Prórroga otorgada al acreditado por situaciones especiales',
  PI: 'Prórroga otorgada al acreditado por invalidez, defunción',
  PR: 'Prórroga otorgada debido a una pérdida de relación laboral',
  RA: 'Cuenta reestructurada sin pago menor por programa institucional o gubernamental, incluyendo los apoyos a damnificados por catástrofes naturales',
  RI: 'Robo de identidad',
  RF: 'Resolución judicial favorable al cliente',
  RN: 'Cuenta reestructurada debido a un proceso judicial',
  RV: 'Cuenta reestructurada sin pago menor por modificación de la situación del cliente a petición de éste',
  SG: 'Demanda por el otorgante',
  UP: 'Cuenta que causa quebranto',
  VR: 'Dación en pago o renta',
  OP: 'Cuenta abierta',
};

export default key => KEYS[key] || 'Desconocido';
