<template lang="pug">
  tr
    td {{ debt.period | formatPeriod }}
    td {{ debt.entitiesNumber }}
    td(:class="b('status-column')")
      div(:class="b('status-container')")
        template(v-for="entry in status")
          div(:class="b('status-entry')" :style="{ color: entry.color }") {{ entry.label }} {{ entry.value }}%
      div(:class="b('status-bar')")
        status-bar(:status="status")
    td {{ debt.daysOverdue }}
    td S/ {{ debt.activeDebt | formatNumber }}
    td S/ {{ debt.overdueDebt | formatNumber }}
    td S/ {{ debt.judicialDebt | formatNumber }}
    td S/ {{ debt.punishedDebt | formatNumber }}
    td S/ {{ debt.totalDebt | formatNumber }}
</template>

<script>
  import getCalificationColor from '@/utils/buro-qualification-color';
  import formatPeriod from '../../filters/format-period';
  import formatCalification from '@/utils/format-buro-qualification';
  import StatusBar from './status-bar';

  export default {
    name: 'historical-debts-table-row',

    props: {
      debt: { type: Object },
    },

    computed: {
      status() {
        return [
          {
            label: 'Normal',
            color: getCalificationColor('NOR'),
            value: Number(this.debt.nor),
          },
          {
            label: 'Dudoso',
            color: getCalificationColor('DUD'),
            value: Number(this.debt.dud),
          },
          {
            label: 'Deficiente',
            color: getCalificationColor('DEF'),
            value: Number(this.debt.def),
          },
          {
            label: 'Potencial pérdida',
            color: getCalificationColor('CPP'),
            value: Number(this.debt.cpp),
          },
          {
            label: 'Perdida',
            color: getCalificationColor('PER'),
            value: Number(this.debt.per),
          },
        ].filter(({ value }) => value > 0);
      },
    },

    filters: {
      formatPeriod,

      formatCalification,
    },

    components: { StatusBar },
  };
</script>

<style lang="sass" scoped>
  .historical-debts-table-row
    &__status-container
      display: flex
      justify-content: space-between
      flex-wrap: nowrap

    &__status-column
      max-width: 280px
      min-width: 180px

    &__status-entry
      margin: 3px 4px
      white-space: nowrap
      font-size: 12px
      font-weight: 500
</style>