<template lang="pug">
  thead
    tr(:class="b()")
      th
        v-checkbox(
          :value="allSelected"
          hide-details
          @change="onChangeSelectAllCheckbox"
          @click.native.prevent="() => null"
        ).ma-0.pa-0
      table-head-item(
        v-for="item in items"
        :key="item.title"
        :title="item.title"
        :order-key="item.orderKey"
        :order-flow="item.orderFlow"
        @order="args => $emit('order', args)"
      )
</template>

<script>
  import TableHeadItem from '@/components/UI/table-head-item';
  import sortableTableHeadMixin from '@/mixins/sortable-table-head-mixin';

  const ITEMS = [
    { title: 'Prestatario' },
    { title: 'Vehículo' },
    { title: 'Desembolso', orderKey: 'disbursement_date'  },
    { title: 'Tasa', orderKey: 'borrower_rate' },
    { title: 'Plazo', orderKey: 'quotes_quantity' },
    { title: 'Saldo Deudor', orderKey: 'pending_debt_borrower_capitalized' },
    { title: 'Balloon' },
    { title: 'Clasificación' },
    { title: 'Situación' },
    { title: 'Status' },
    { title: 'Ampliado' },
    { title: 'Refinanciado' },
    { title: 'Judializado' },
  ];

  export default {
    name: 'investment-proposals-table-head',

    mixins: [sortableTableHeadMixin()],

    props: {
      orderBy: { type: String },
      orderFlow: { type: String },
    },

    computed: {
      items() {
        return ITEMS.map(item => ({
          ...item,
          orderFlow: this.orderBy === item.orderKey ? this.orderFlow : null,
        }));
      },
    },

    components: {
      TableHeadItem,
    },
  };
</script>

<style lang="sass">
  .investment-proposals-table-head
    th
      padding: 4px 8px

</style>