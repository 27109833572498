<template lang="pug">
validation-observer(ref='form')
  form(@submit.prevent='onSubmit')
    v-layout(wrap)
      v-flex.px-2(xs12, md6)
        validation-provider(
          name='estado',
          :rules='"required"',
          v-slot='{ errors }'
        )
          status-select(
            v-model='data.status',
            :error-messages='errors',
            name='estado',
            label='Estado'
          )
      v-flex.px-2(xs12, md6)
        validation-provider(
          name='inversionista',
          :rules='"required"',
          v-slot='{ errors }'
        )
          investor-select(
            v-model='data.investorId',
            :default-items='investorDefaultItems',
            :error-messages='errors',
            name='inversionista',
            label='Inversionista'
          )
      v-flex.px-2(xs12, md6)
        validation-provider(
          name='fecha de desembolso',
          :rules='rules.disbursementDate',
          v-slot='{ errors }'
        )
          date-picker(
            v-model='data.disbursementDate',
            :error-messages='errors',
            label='Fecha de desembolso',
            name='fecha de desembolso'
          )
      v-flex.px-2(xs12, md6)
        validation-provider(
          name='fecha de primer pago',
          :rules='rules.firstPaymentDate',
          v-slot='{ errors }'
        )
          date-picker(
            v-model='data.firstPaymentDate',
            v-bind='inputsProps.firstPaymentDate',
            :error-messages='errors',
            :allowed-dates="firstPaymentAllowedDates"
            label='Fecha de primer pago',
            name='fecha de primer pago'
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='comisión RebajaTusCuentas',
          :rules='""',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model.number='data.rtcCommissionRate',
            :error-messages='errors',
            name='comisión RebajaTusCuentas',
            label='Comisión rebajatuscuentas',
            type='number',
            append-icon='mdi-percent'
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='impuesto a la renta',
          :rules='""',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model.number='data.irTaxRate',
            :error-messages='errors',
            name='impuesto a la renta',
            label='Impuesto a la renta',
            type='number',
            append-icon='mdi-percent'
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='tasa moratoria anual',
          :rules='"required|min_value:0|max_value:20|decimal:2"',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model.number='data.defaultRate',
            :error-messages='errors',
            name='tasa moratoria anual',
            label='Tasa moratoria anual',
            type='number',
            step='0.01',
            append-icon='mdi-percent'
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='penalidad diaria',
          :rules='""',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model.number='data.penaltyFee',
            :error-messages='errors',
            :prefix='currency.symbol',
            step='0.01',
            name='penalidad diaria',
            label='Penalidad diaria',
            type='number'
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='Tipo de garantía',
          :rules='"required"',
          v-slot='{ errors }'
        )
          guaranteeTypes-select(
            v-model='data.guaranteeType',
            :error-messages='errors',
            name='tipo garantia',
            label='Tipo de garantía',
          )
      v-flex.px-2(xs6)
        validation-provider(
          name='Valor asegurable',
          :rules='"required|decimal:2"',
          v-slot='{ errors }'
        )
          v-text-field(
            v-model.number='data.propertyInsurableValue',
            :error-messages='errors',
            :prefix='currency.symbol',
            step='0.01',
            name='Valor asegurable',
            label='Valor asegurable',
            type='number'
          )
    v-divider
    v-card-actions
      v-spacer
      v-btn(color='error', text, @click='onCancel') Cancelar
      v-btn(:loading='requesting', color='primary', text, type='submit') Guardar
</template>

<script>
import basicFormMixin from './basic-form-mixin';
import moment from 'moment';
import ProductSelect from '@/components/inputs/product-select';
import InvestorSelect from '@/components/inputs/investor-select';
import DatePicker from '@/components/inputs/input-date-picker';
import StatusSelect from '@/components/inputs/investment-proposal-status-select';
import GuaranteeTypesSelect from '@/components/inputs/guaranteeTypes-select';

export default {
  name: 'investment-edit-view',

  props: {
    currency: { type: Object },
  },

  mixins: [
    basicFormMixin({
      modelKey: 'proposal',
      veeValidation: true,

      mapPropsToData({ proposal }) {
        return {
          id: proposal?.id || undefined,
          status: proposal?.status,
          investorId: proposal?.investor.id,
          disbursementDate: proposal?.disbursementDate,
          firstPaymentDate: proposal?.firstPaymentDate,
          rtcCommissionRate: proposal?.rtcCommissionRate || 0,
          irTaxRate: proposal?.irTaxRate || 0,
          penaltyFee: proposal?.penaltyFee || 0,
          defaultRate: proposal?.defaultRate || 0,
          propertyInsurableValue: proposal?.propertyInsurableValue,
          guaranteeType: proposal?.guaranteeType.slug,
        };
      },

      parseSubmit() {
        const formData = {
          proposal: {
            ...this.data,
            rtcCommissionRate: this.data.rtcCommissionRate || 0,
            irTaxRate: this.data.irTaxRate || 0,
            defaultRate: this.data.defaultRate || 0,
            penaltyFee: this.data.penaltyFee || 0,
            guaranteeType: this.data.guaranteeType || null,
          },
        };

        this.$nextTick(() => {
          this.data = formData.proposal;
        });

        return formData;
      },

    }),
  ],

  computed: {
    rules() {
      return {
        disbursementDate: { required: true },
        firstPaymentDate: {
          required: true,
          after: this.data.disbursementDate
            ? moment(this.data.disbursementDate).format('YYYY-MM-DD')
            : undefined,
        },
      };
    },

    inputsProps() {
      return {
        firstPaymentDate: {
          min: this.data.disbursementDate
            ? moment(this.data.disbursementDate)
              .add(1, 'd')
              .format('YYYY-MM-DD')
            : undefined,
        },
      };
    },

    investorDefaultItems() {
      return this.proposal?.investor ? [{ ...this.proposal.investor }] : [];
    },
  },

  components: { ProductSelect, DatePicker, InvestorSelect, StatusSelect,GuaranteeTypesSelect },

  methods: {
    firstPaymentAllowedDates(date) {
      const mDate = moment(date);

      return mDate.date() <= 28 && (this.data.disbursementDate ? mDate.isAfter(this.data.disbursementDate) : true);
    },
  },

};
</script>

<style lang="sass" scoped>
</style>
