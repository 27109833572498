<template lang="pug">
  tr
    td {{ entry.date | formatDate('MMMM YYYY') }}
    td {{ entry.amountAvg | formatCurrency }}
    td {{ entry.amount | formatCurrency }}
    td {{ entry.realAmount | formatCurrency }}
    td {{ entry.interest | formatCurrency }}
    td {{ entry.realInterest | formatCurrency }}
    td {{ entry.feesQuantity | formatNumber }}
    td {{ entry.pctCollected }} %
    td {{ entry.pctPaidFees }} %
</template>

<script>
export default {
  name: 'forecast-table-body-row',

  props: {
    entry: { type: Object },
  },
};
</script>

<style lang="sass" scoped>
  //.forecast-table-body-row
</style>