<template lang="pug">
  titled-card(title="Estudio de títulos")
    div(slot="actions")
      v-btn(@click.native="onClickAdd" text small color="primary").ma-0.px-1
        span Agregar
        v-icon mdi-plus
    div(:class="b('body')")
      title-studies-list(
        :title-studies="titleStudies"
        @click-edit="onClickEdit"
        @click-add="onClickAdd"
      )
      .d-flex.justify-center
        v-btn(
          v-if="titleStudies.length"
          color="primary"
          :to="{ name: 'formalizations.title_study', params: { formalizationId }}"
          target="_blank"
        )
          v-icon mdi-file-pdf
          span.ml-1 Descargar PDF
    title-study-form-modal(
      v-if="formModal"
      v-model="formModal"
      :title-study="editingEntity"
      :potential-owners="potentialOwners"
      :adviser-id="adviserId"
      :requesting="requesting"
      :persistent="false"
      @submit="onSubmit"
    )
</template>

<script>
  import TitledCard from '@/components/UI/titled-card';
  import TitleStudyFormModal from '@/components/modals/title-study-form-modal';
  import gestionableListMixin from '@/mixins/gestionable-list-mixin';

  import TitleStudiesList from './title-studies-list';

  export default {
    name: 'title-studies-card',

    props: {
      titleStudies: { type: Array, default: () => ([]) },
      potentialOwners: { type: Array, default: () => [] },
      adviserId: { type: String },
      requesting: { type: Boolean, default: true },
      formalizationId: { type: String },
    },

    mixins: [
      gestionableListMixin({ entityName: 'titleStudy' }),
    ],

    components: { TitledCard, TitleStudyFormModal, TitleStudiesList },
  };
</script>

<style lang="sass" scoped>

</style>