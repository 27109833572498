import { render, staticRenderFns } from "./investment-proposal-card.vue?vue&type=template&id=107ba7c8&scoped=true&lang=pug"
import script from "./investment-proposal-card.vue?vue&type=script&lang=js"
export * from "./investment-proposal-card.vue?vue&type=script&lang=js"
import style0 from "./investment-proposal-card.vue?vue&type=style&index=0&id=107ba7c8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107ba7c8",
  null
  
)

export default component.exports