import fanci from 'fanci';

const parseJSONTemplate = {
  incomes: 'income',
  debts: ['debt', {
    consumer: true,
    mortgage: true,
    vehicular: true,
    usedCreditCard: 'use_credit_card',
    unusedCreditCard: 'unuse_credit_card',
    cardWithdrawals: 'card_withdrawals',
    extraLine: 'extra_line',
  }],
  product: {
    slug: 'product_slug',
    fee: true,
  },
  rciMaxAllowed: 'rci_max_allowed',
  ltvMaxAllowed: 'ltv_max_allowed',
};

const unparseJSONTemplate = {
  income: 'incomes',
  debt: ['debts', {
    consumer: true,
    mortgage: true,
    vehicular: true,
    use_credit_card: 'usedCreditCard',
    unuse_credit_card: 'unusedCreditCard',
    card_withdrawals: 'cardWithdrawals',
    extra_line: 'extraLine',
  }],
  product: {
    product_slug: 'slug',
    fee: true,
  },
  rci_max_allowed: 'rciMaxAllowed',
  ltv_max_allowed: 'ltvMaxAllowed',
};

export const parse = json => fanci.rename(json, parseJSONTemplate);
export const unparse = json => fanci.rename(json, unparseJSONTemplate);
