<template lang="pug">
  tr(:class="b()")
    td.name-column.font-weight-medium {{ referral.name }}
    td {{ referral.vat }}
    td
      a(:href="emailLink") {{ referral.email }}
    td.test
      a(:href="mobileCallLink") {{ referral.mobile }}
    td {{ referral.buroScore }}
    td.actions-column
      v-btn(small @click="onClickEdit" color="primary").hidden-sm-and-up
        span Editar

      v-btn(icon small @click="onClickEdit" color="primary").hidden-xs-only
        v-icon mdi-pencil

      v-tooltip(v-if="isVerified" top)
        template(v-slot:activator="{ on }")
          v-chip(color="success" small outlined v-on="on").ml-2 Verificado
        span #[b Verificado por]: {{ referral.verifiedDsUser.firstName }} {{ referral.verifiedDsUser.lastName }} <{{ referral.verifiedDsUser.email}}>

      v-btn(v-else-if="validable" color="primary" small @click="onClickValidate").ml-2 Verificar

</template>

<script>
export default {
  name: 'formalization-referrals-table-row',

  props: {
    referral: { type: Object, required: true },
    validable: { type: Boolean, default: false },
  },

  methods: {
    onClickEdit() {
      this.$emit('click-edit', { referral: this.referral });
    },

    onClickValidate() {
      if(window.confirm('¿Está seguro de que desea validar este referido?'))
        this.$emit('click-validate', { referral: this.referral });
    },
  },

  computed: {
    mobileCallLink() {
      return `tel:${this.referral.mobile}`;
    },

    emailLink() {
      return `mailto:${this.referral.email}`;
    },

    isVerified() {
      return Boolean(this.referral.verifiedDsUser);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>