<template lang="pug">
  v-select(
    :value="value"
    :items="lazyItems"
    :label="label"
    :error-messages="errorMessages"
    :return-object="returnObject"
    :loading="$apollo.queries.products.loading"
    :disabled="disabled"
    :item-value="itemValue"
    item-text="name"
    @input="args => $emit('input', args)"
    @focus="onFocus"
    clearable
  )
</template>

<script>
  import lazySelectMixin from '@/mixins/lazy-select-mixin';
  import { ProductsQuery } from './queries.gql';

  export default {
    name: 'product-select',

    mixins: [lazySelectMixin({ itemsKey: 'products' })],

    props: {
      label: { type: String },
      itemValue: { type: String, default: 'slug' },
      errorMessages: { type: Array },
      value: { type: String },
      returnObject: { type: Boolean },
      country: { type: String },
      disabled: { type: Boolean, default: false },
    },

    data() {
      return {
        products: [],
      };
    },

    apollo: {
      products: {
        query: ProductsQuery,

        variables() {
          return {
            country: this.country,
          }
        },

        skip() {
          return this.lazySkipQuery;
        },
      },
    },
  };
</script>

<style lang="sass" scoped>

</style>