<template lang="pug">
  form(@submit.prevent="onSubmit" :class="b()")
    v-layout(wrap)
      v-flex(xs12 md6 lg4 xl3)
        month-and-year-select(
          v-model="data.fromMonthAndYear"
          :from-year="2019"
          :until-year="new Date().getFullYear() + 2"
          label="Fecha (Desde)"
          clearable
          @input="onInputFromMonthAndYear"
        )
      v-flex(xs12 md6 lg4 xl3)
        month-and-year-select(
          v-if="data.fromMonthAndYear"
          v-model="data.toMonthAndYear"
          :from-year="2019"
          :until-year="new Date().getFullYear() + 2"
          :min="minDate"
          label="Fecha (Hasta)"
          clearable
        )
      v-flex(xs12 md3 lg2)
        borrower-select(
          v-model="data.borrowerIds"
          label="Prestatario"
          clearable
          multiple
          deletable-chips
        )
      v-flex(xs12 md3 lg2)
        investor-select(
          v-model="data.investorIds"
          label="Inversionista"
          clearable
          multiple
          deletable-chips
        )
    v-layout(wrap)
      v-flex(xs12 md3 lg2)
        condition-select(
          v-model="data.condition"
          label="Condición"
          multiple
          clearable
        )
      v-flex(xs12 md3 lg2)
        qualification-select(
          v-model="data.qualification"
          label="Clasificación"
          clearable
          multiple
          deletable-chips
        )
      v-flex(xs12 md3 lg2)
        type-cancellation-select(
          v-model="data.typeOfCancellation"
          label="Tipo de Cancelación"
          clearable
          multiple
          small-chips
          deletable-chips
        )
      v-flex(xs12 md3 lg2)
        borrowerQualification-select(
          v-model="data.borrowerQualification"
          label="Calificación de Cuota"
          clearable
          multiple
          small-chips
          deletable-chips
        )
      v-flex.text-right.d-flex.justify-end.align-center
        v-btn(
          color="primary"
          :disabled="isDisabled"
          type="submit"
        ) Aplicar filtros
</template>

<script>
import moment from 'moment';
import basicFormMixin from '@/components/forms/basic-form-mixin'
import monthAndYearToMoment from '@/utils/month-and-year-to-moment';

import MonthAndYearSelect from '@/components/inputs/month-and-year-select';
import BorrowerSelect from '@/components/inputs/borrower-select';
import InvestorSelect from '@/components/inputs/investor-select';
import ConditionSelect from '@/components/inputs/condition-select';
import QualificationSelect from '@/components/inputs/qualification-select';
import TypeCancellationSelect from '@/components/inputs/type-cancellation-select/type-cancellation-select.vue';
import BorrowerQualificationSelect from '@/components/inputs/borrower-qualification-select';

export default {
  name: 'filters',

  props: {
    filters: { type: Object },
  },

  data() {
    return {
      condition: [],
      qualification:[],
      typeOfCancellation:[],
      borrowerQualification:[],
    }
  },

  mixins: [
    basicFormMixin({
      modelKey: 'filters',
      mapPropsToData({ filters }) {
        const fromMoment = filters?.fromDate ? moment(filters.fromDate) : null;
        const toMoment = filters?.toDate ? moment(filters.toDate) : null;

        return {
          fromMonthAndYear: fromMoment ? { month: fromMoment.month(), year: fromMoment.year() } : null,
          toMonthAndYear: toMoment ? { month: toMoment.month(), year: toMoment.year() } : null,
          borrowerIds: filters.borrowerIds || [],
          investorIds: filters.investorIds || [],
          condition: filters.condition || [],
          qualification: filters.qualification || [],
          typeOfCancellation: filters.typeOfCancellation || [],
          borrowerQualification: filters.borrowerQualification || [],
        };
      },

      parseSubmit() {
        return {
          filters: {
            fromDate: this.fromMoment ? this.fromMoment.format('YYYY-MM-DD') : null,
            toDate: this.toMoment ? this.toMoment.format('YYYY-MM-DD') : null,
            borrowerIds: this.data.borrowerIds,
            investorIds: this.data.investorIds,
            condition: this.data.condition,
            qualification: this.data.qualification,
            typeOfCancellation: this.data.typeOfCancellation,
            borrowerQualification: this.data.borrowerQualification,
          }
        };
      },
    }),
  ],

  computed: {
    minDate() {      
      return this.fromMoment ? this.fromMoment.format('YYYY-MM-DD') : null;
    },

    fromMoment() {
      return this.data.fromMonthAndYear ? monthAndYearToMoment(this.data.fromMonthAndYear) : null;
    },

    toMoment() {
      return this.data.toMonthAndYear ? monthAndYearToMoment(this.data.toMonthAndYear) : null;
    },
  },

  methods: {
    onInputFromMonthAndYear() {
      this.$nextTick(() => {
        if(this.fromMoment && this.toMoment && this.fromMoment.clone().endOf('month').isAfter(this.toMoment)) {
          this.data.toMonthAndYear = null;
        }
      });
    },
  },

  components: { 
    MonthAndYearSelect, 
    InvestorSelect, 
    BorrowerSelect, 
    ConditionSelect, 
    QualificationSelect, 
    TypeCancellationSelect,
    BorrowerQualificationSelect,
  },
};
</script>

<style lang="sass" scoped>
  //.filters
</style>

