import { version } from '../../package.json';

export const APP_VERSION = version;

export const PRODUCT_CONTEXTS = {
  balance_transfer: ['customer', 'incomes'],
  new_credit_card: ['customer', 'incomes'],
  personal_loan: ['customer', 'incomes'],
  buy_mortgage: ['customer', 'incomes', 'properties', 'credit'],
  new_mortgage: ['customer', 'incomes', 'properties'],
  home_equity_loan: ['customer', 'incomes', 'properties'],
};

export const PRODUCT_SLUG = {
  BUY_MORTGAGE: 'buy_mortgage',
  NEW_MORTGAGE: 'new_mortgage',
  HOME_EQUITY_LOAN: 'home_equity_loan',
};

export const DOCUMENT_STATUS = {
  PENDING: 'PENDING',
  UNDER_REVIEW: 'UNDER_REVIEW',
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
};

export const DEAL_STATUS = {
  ACTIVE: 'ACTIVE',
  ON_HOLD: 'ON_HOLD',
  WON: 'WON',
  LOST: 'LOST',
};

export const FEE_TYPE = {
  SIMPLE: 'SIMPLE',
  DOUBLE: 'DOUBLE',
};

export const INVESTMENT_PROPOSAL_STATUS = {
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  COLLECTING: 'COLLECTING',
  FINISHED: 'FINISHED',
  JUDICIALIZED: 'JUDICIALIZED',
  CANCELLED: 'CANCELLED',
  REFINANCED: 'REFINANCED',
  EXTENDED: 'EXTENDED',
  RESCHEDULE: 'RESCHEDULE',
};

export const INVESTMENT_PROPOSAL_CONDITION = {
  EXTENDED: 'EXTENDED',
  REFINANCED: 'REFINANCED',
  JUDICIALIZED: 'JUDICIALIZED',
};

export const QUALIFICATION_STATUS = {
  OWNED: 'OWNED',
  CURRENT: 'CURRENT',
  OVERDUE: 'OVERDUE',
};

export const SITUATION_STATUS = {
  EXTENDED: 'EXTENDED',
  JUDICIALIZED: 'JUDICIALIZED',
  REFINANCED: 'REFINANCED',
}

export const BOND_STATUS = {
  PENDING: 'PENDING',
  COLLECTING: 'COLLECTING',
  FINISHED: 'FINISHED',
  CANCELLED: 'CANCELLED'
}

export const RISK_OPINION_STATUS = {
  FAILED: 'FAILED',
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  OBSERVED: 'OBSERVED',
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
};

export const FORMALIZATION_STATUS = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  LOST: 'LOST',
};

export const RATE_TYPE = {
  FIXED: 'FIXED',
  MIXED: 'MIXED',
};

export const REFINANCE_TYPE = {
  EXTENDED: 'EXTENDED',
  REFINANCED: 'REFINANCED',
};

export const FORMALIZATION_STAGES = {
  PREPARATION: 'PREPARATION',
  BLOCKING: 'BLOCKING',
  DISBURSEMENT: 'DISBURSEMENT',
  MORTGAGE_REGISTRATION: 'MORTGAGE_REGISTRATION',
  LOCKING: 'LOCKING',
};

export const PAY_DETAIL_STATUS = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  ENDORSED: 'ENDORSED',
};

export const MORTGAGE_NUMBER_STATUS = {
  PENDING: 'PENDING',
  IN_QUALIFICATION: 'IN_QUALIFICATION',
  OBSERVED: 'OBSERVED',
  SIGNED_UP: 'SIGNED_UP',
  SIGNED_UP_2nd: 'SIGNED_UP_2nd',
  LIQUIDATED: 'LIQUIDATED',
};

export const APPRAISAL_STATUS = {
  APPROVED: 'APPROVED',
  EXCEPTION: 'EXCEPTION',
  DENIED: 'DENIED',
  IN_REVIEW: 'IN_REVIEW',
};

export const DEMAND_STATUS = {
  NONE: 'NONE',
  SLOW: 'SLOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const SPEED_SELLING_STATUS = {
  LOW: 'LOW',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
};

export const TITLE_STUDY_STATUS = {
  APPROVED: 'APPROVED',
  EXCEPTION: 'EXCEPTION',
  DENIED: 'DENIED',
  IN_REVIEW: 'IN_REVIEW',
};

export const CONTRACT_STATUS = {
  BLOCKED: 'BLOCKED',
  EMITTED: 'EMITTED',
  PENDING: 'PENDING',
  OBSERVED: 'OBSERVED',
  LOST: 'LOST',
};

export const AMORTIZATION_TYPE = {
  FIXED: 'FIXED',
  INCREASING: 'INCREASING',
};

export const QUOTE_STATUS = {
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  PAID: 'PAID',
  PAID_DELAYED: 'PAID_DELAYED',
  RESCHEDULED: 'RESCHEDULED',
  DISBURSEMENT: 'DISBURSEMENT',
  EXTENDED: 'EXTENDED',
};

export const FEE_STATUS = {
  PENDING: 'PENDING',
  EXPIRED: 'EXPIRED',
  PAID: 'PAID',
};

export const QUOTE_TYPE = {
  BORROWER: 'BORROWER',
  INVESTOR: 'INVESTOR',
};

export const REMINDER_STATUS = {
  PENDING: 'PENDING',
  DELAYED: 'DELAYED',
  COMPLETED: 'COMPLETED',
};

export const TASK_STATUS = {
  PENDING: 'PENDING',
};

export const PERMISSIONS = {
  AUTH: {
    STAFF: 'auth.Staff',
    EXTERNAL: 'auth.External',
    INTERNAL: 'auth.Internal',
    TRUST_ONLY: 'auth.trust_only',
    ACCOUNTANT: 'auth.Accountant',
  },
  PEGASUS: {
    DASHBOARD: 'pegasus.Dashboard',
    CUSTOMERS: 'pegasus.Customers',
    DEALS: 'pegasus.Deals',
    METRICS: 'pegasus.Metrics',
    BONDS: 'pegasus.Bonds',
    LOANS_METRICS: 'pegasus.LoansMetrics',
    INVESTMENT: 'pegasus.Investment',
    RISK_FILES: 'pegasus.RiskFiles',
    RISK_FILES__REVIEW: 'pegasus.RiskFiles_Review',
    RISK_FILES__NOTIFY_OPINION: 'pegasus.RiskFiles_NotifyOpinion',
    FORMALIZATIONS: 'pegasus.Formalizations',
    FORMALIZATIONS__REVIEW: 'pegasus.Formalizations_Review',
    LIBRARY: 'pegasus.Library',
    INVESTMENT_BORROWER_STATEMENT: 'pegasus.InvestmentBorrowerStatement',
    MORTGAGE_SCHEDULE__BORROWER: 'pegasus.MortgageSchedule_Borrower',
    MORTGAGE_SCHEDULE__INVESTMENT_BORROWER:
      'pegasus.MortgageSchedule_InvestmentBorrower',
    MORTGAGE_SCHEDULE__INVESTMENT_INVESTOR:
      'pegasus.MortgageSchedule_InvestmentInvestor',
    ADVISER_LANDING: 'pegasus.AdviserLanding',
    SCOTIA_SCORE: 'pegasus.ScotiaScore',
    SIMULATOR: 'pegasus.Simulator'
  },
};

export const BURO_QUALIFICATIONS = {
  NOR: 'NOR',
  DUD: 'DUD',
  DEF: 'DEF',
  PER: 'PER',
  CPP: 'CPP',
  SCH: 'SCH',
};

export const QUALIFICATIONS_BY_COMPLIANCE = {
  NOR: 'NOR',
  CPP: 'CPP',
  DEF: 'DEF',
  DUD: 'DUD',
  PER: 'PER',
};

export const FORECAST_FILTERS_BY_CONDITION = {
  EXTENDED: 'EXTENDED',
  REFINANCED: 'REFINANCED',
  JUDICIALIZED: 'JUDICIALIZED',
}

export const FORECAST_FILTERS_BY_QUALIFICATION = {
  NOR: 'NOR',
  CPP: 'CPP',
  DEF: 'DEF',
  DUD: 'DUD',
  PER: 'PER',
  SCH: 'SCH',
}

export const FORECAST_FILTERS_BY_BORROWERQUALIFICATION = {
  NOR: 'NOR',
  CPP: 'CPP',
  DEF: 'DEF',
  DUD: 'DUD',
  PER: 'PER',
  SCH: 'SCH',
}

export const REFERRAL_TYPES = {
  CUSTOMER_NETWORK: 'customer_network',
  ADVISOR_NETWORK: 'advisor_network',
  OTHER_NETWORK: 'other_network',
};

export const NEW_MORTGAGE_SLUG = 'new_mortgage';
export const BUY_MORTGAGE_SLUG = 'buy_mortgage';
export const HOME_EQUITY_LOAN_SLUG = 'home_equity_loan';
export const DEBT_CONSOLIDATION_PRODUCT_MOTIVE = 'DEBT_CONSOLIDATION';
export const WORK_CAPITAL_PRODUCT_MOTIVE = 'WORK_CAPITAL';
export const HOUSE_REMODELING_PRODUCT_MOTIVE = 'HOUSE_REMODELING';
export const BAD_BURO_PRODUCT_MOTIVE = 'BAD_BURO';
export const COVID_PRODUCT_MOTIVE = 'COVID';
export const LOAN_EXTENSION_DEBT_CONSOLIDATION_MOTIVE = 'LOAN_EXTENSION_DEBT_CONSOLIDATION';
export const LOAN_EXTENSION_HOUSE_REMODELING_MOTIVE = 'LOAN_EXTENSION_HOUSE_REMODELING';
export const LOAN_EXTENSION_DEBT_WORK_CAPITAL_MOTIVE = 'LOAN_EXTENSION_WORK_CAPITAL';
export const RESCHEDULE_MOTIVE = 'RESCHEDULE';



export const RTC_BANK_ID = '41';
