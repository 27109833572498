import { render, staticRenderFns } from "./extra-features-modal.vue?vue&type=template&id=2d42aefe&scoped=true&lang=pug"
import script from "./extra-features-modal.vue?vue&type=script&lang=js"
export * from "./extra-features-modal.vue?vue&type=script&lang=js"
import style0 from "./extra-features-modal.vue?vue&type=style&index=0&id=2d42aefe&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d42aefe",
  null
  
)

export default component.exports