import { render, staticRenderFns } from "./mortgage-schedule-investment-borrower-view.vue?vue&type=template&id=2d2fd6f6&lang=pug"
import script from "./mortgage-schedule-investment-borrower-view.vue?vue&type=script&lang=js"
export * from "./mortgage-schedule-investment-borrower-view.vue?vue&type=script&lang=js"
import style0 from "./mortgage-schedule-investment-borrower-view.vue?vue&type=style&index=0&id=2d2fd6f6&prod&lang=sass"
import style1 from "./mortgage-schedule-investment-borrower-view.vue?vue&type=style&index=1&id=2d2fd6f6&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports