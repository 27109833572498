import { TASK_STATUS } from '@/utils/constants';
import { ActiveDealsQuery } from './graphql/queries.gql';

const MAX_RETRIES = 3;
const RETRIES_TIME_GAP = 3000;

export default () => ({
  data() {
    return {
      activeDeals: { objects: [] },
      activeDealsRefetchTimeout: null,
      activeDealsRefetchRetries: 0,
    };
  },

  apollo: {
    activeDeals: {
      query: ActiveDealsQuery,
      manual: true,
      fetchPolicy: 'cache-and-network',

      variables() {
        return { customerId: this.$route.params.customerId, };
      },

      result({ data }) {
        if(!data?.deals || this.activeDealsRefetchTimeout) return;

        this.activeDeals = data.deals;

        const shouldRefetch = data.deals.objects.some(deal => [
            deal.tasksStatus.generateProposalsForFirstTime,
            deal.tasksStatus.generateStrategy
          ].includes(TASK_STATUS.PENDING)
        );

        if(shouldRefetch && this.activeDealsRefetchRetries < MAX_RETRIES) {
          this.activeDealsRefetchTimeout = setTimeout(() => {
            this.activeDealsRefetchRetries++;
            this.activeDealsRefetchTimeout = null;
            this.$apollo.queries.activeDeals?.refetch();
          }, RETRIES_TIME_GAP);
        }
      },
    },
  },

  methods: {
    getActiveDealsQuery() {
      return {
        query: ActiveDealsQuery,
        variables: { customerId: this.$route.params.customerId },
      };
    },
  },

  beforeDestroy() {
    if(this.activeDealsRefetchTimeout) clearTimeout(this.activeDealsRefetchTimeout);
  },
});