<template lang="pug">
  div(:class="b()")
    div(:class="b('header')")
      div(:class="b('user')") {{ opinion.user.firstName }} {{ opinion.user.lastName }}
      span(:class="b('label')" :style="{ backgroundColor: statusColor }") {{ $t(`riskOpinionStatus.${opinion.status}`) }}
    div(v-html="opinion.description" :class="b('description')").pt-1

    div
      div(v-if="opinion.DTI" :class="b('data-item')" style="display: inline;").mr-3
        span.font-weight-medium DTI:&nbsp;
        span {{ opinion.DTI }} %

      div(v-if="opinion.PTI" :class="b('data-item')" style="display: inline;").mr-3
        span.font-weight-medium PTI:&nbsp;
        span {{ opinion.PTI }} %
    div.mb-2
      div(v-if="opinion.netIncome" :class="b('data-item')" style="display: inline;").mr-3
        span.font-weight-medium Ingresos Netos:&nbsp;
        span {{ opinion.netIncome | formatCurrency(currency) }}

      div(v-if="opinion.monthlyPayment" :class="b('data-item')" style="display: inline;").mr-3
        span.font-weight-medium Pago Mensual:&nbsp;
        span {{ opinion.monthlyPayment | formatCurrency(currency) }}

      div(v-if="opinion.simpleFeeMax" :class="b('data-item')" style="display: inline;").mr-3
        span.font-weight-medium Cuota RTC:&nbsp;
        span {{ opinion.simpleFeeMax | formatCurrency(currency) }}

    div(v-if="opinion.refusedReason" :class="b('refused-reason')").pt-1
      span.font-weight-medium Motivo:&nbsp;
      span {{ opinion.refusedReason.reason }}
    .d-flex.justify-end
      div(v-if="opinion.ltvMax" :class="b('data-item')").mr-3
        span.font-weight-medium LTV Max:&nbsp;
        span {{ opinion.ltvMax }} %

      div(v-if="opinion.simpleFeeMax" :class="b('data-item')").mr-3
        span.font-weight-medium Cuota Max:&nbsp;
        span {{ opinion.simpleFeeMax | formatCurrency(currency) }}

      div(v-if="opinion.evaluationType" :class="b('data-item')").mr-3
        span.font-weight-medium Tipo de evaluación:&nbsp;
        span(:class="b('label')" :style="{ backgroundColor: 'gray', whiteSpace: 'nowrap', display: 'block', margin: '2px' }" v-for="et in opinion.evaluationType") {{ et.name }}

      div(v-if="opinion.opinionException" :class="b('data-item')").mr-3
        span.font-weight-medium Excepción:&nbsp;
        span(:class="b('label')" :style="{ backgroundColor: 'gray', whiteSpace: 'nowrap', display: 'block', margin: '2px' }" v-for="oe in opinion.opinionException") {{ oe.name }}

      div(v-if="opinion.rciMax" :class="b('data-item')").mr-3
        span.font-weight-medium RCI Max:&nbsp;
        span {{ opinion.rciMax }} %

      div(:class="b('data-item')").mr-3
        span {{ opinion.createdAt | formatDate('D MMM YYYY hh:mm a') }}
</template>

<script>
  import getStatusColor from '@/utils/get-risk-opinion-status-color';

  export default {
    name: 'opinions-list-item',

    props: {
      opinion: { type: Object, required: true },
      currency: { type: Object },
    },

    computed: {
      statusColor() {
        return getStatusColor(this.opinion?.status);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .opinions-list-item
    padding: 12px 0
    border-bottom: 1px solid $cloudyColor

    &__header
      display: flex
      align-items: center

    &__user
      font-size: 16px
      font-weight: 500

    &__label
      margin-left: 8px
      padding: 2px 16px

      color: white

      font-size: 12px

      border-radius: 6px
</style>