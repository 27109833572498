<template lang="pug">
  titled-card(
    :class="b()"
    title="Historial"
    :count="filteredDeals.length"
    icon="mdi-history"
  )
    div(:class="b('content')")
      deals-history(:deals="filteredDeals")
</template>

<script>
  import { DEAL_STATUS } from '@/utils/constants';
  import TitledCard from '@/components/UI/titled-card';
  import DealsHistory from './deals-history';

  export default {
    name: 'deals-history-card',

    props: {
      deals: { type: Array, default: () => ([]) },
    },

    computed: {
      filteredDeals() {
        return this.deals.filter(({ status }) => status !== DEAL_STATUS.ACTIVE);
      },
    },

    components: {
      TitledCard,
      DealsHistory,
    },
  };
</script>

<style lang="sass" scoped>
  .deals-history-card
    &__content
      max-height: 140px
      overflow-y: auto
</style>