<template lang="pug">
  div(:class="b()")
    div(:class="b('content')")
      loading-wrapper(v-if="fetching && !formalization")
      div(v-else-if="formalization")
        v-alert(type="info" outlined).mb-4
          div.mb-2 El crédito fue enviado a normalización exitosamente el #[span.font-weight-bold {{ formalization.createdAt | formatDate('D MMM YYYY hh:mm a') }}]
          div.mb-1
            b.mr-2 Estado:
            formalization-status-tag(:status="formalization.status" style="font-size: 14px").mr-3
            b.mr-2 Etapa:
            span {{ $t(`formalizationStage.${formalization.stage}`)}}
          div.mb-1
            b.mr-2 Tasación:
            template(v-if="appraisal")
              appraisal-status-tag(:status="appraisal.status" style="font-size: 14px").mr-2
              span ({{ appraisal.modifiedAt | formatDate('D MMM YYYY hh:mm a')}})
            span(v-else) No Registrada
          div.mb-1
            b.mr-2 Contrato:
            template(v-if="formalization.contract")
              contract-status-tag(:status="formalization.contract.status" style="font-size: 14px").mr-2
              span ({{ formalization.contract.modifiedAt | formatDate('D MMM YYYY hh:mm a')}})
            span(v-else) No Registrado
          div.mb-1
            b.mr-2 Título Hipoteca:
            mortgage-number-status-tag(v-if="formalization.mortgageNumberStatus" :status="formalization.mortgageNumberStatus")
            span(v-if="formalization.mortgageNumber").ml-2 ({{ formalization.mortgageNumber }})
          div.mb-1
            b.mr-2 Kardex:
            span {{ kardexData }}
          div.mb-1
            b.mr-2 Título Bloqueo:
            span {{ blockData }}
          div.mb-1
            b.mr-2 Estudio de Título:
            template(v-if="titleStudy")
              title-study-status-tag(:status="titleStudy.status" style="font-size: 14px").mr-2
              span ({{ titleStudy.modifiedAt | formatDate('D MMM YYYY hh:mm a')}})
              v-btn(
                color="primary"
                style="margin-left: 10px"
                :to="formalizationTitleStudy"
                target="_blank"
              )
                v-icon mdi-file-pdf
                span.ml-1 Descargar PDF
            span(v-else) No Registrado

            div(v-if="titleStudy && titleStudy.comments" v-html="titleStudy.comments" style="white-space: pre-line").pl-4
        div(v-if="canVisit").d-flex.justify-end.mb-3
          v-btn(:to="formalizationLink" color="primary" outlined)
            v-icon.mr-1 mdi-share
            span Ir a la formalización
        div(:class="b('referrals')").pb-2.mb-4
          h3.text-center Referidos
          referrals(
            ref="referrals"
            :referrals="formalization.referrals"
            :requesting="requestingReferrals"
            :max-count="3"
            @create="args => $emit('submit-create-referral', args)"
            @update="args => $emit('submit-update-referral', args)"
          )
        div(v-if="formalization.contract && formalization.contract.toPayDetails" :class="b()").pb-4
          h3.text-center.mb-1 Deudas a pagar
          contract-pay-details-table(:pay-details="formalization.contract.toPayDetails")
      v-alert(v-else type="info" outlined).text-center
        .mb-4 Aún no se ha enviado el crédito a formalización
        v-btn(color="primary" @click="onClickCreateFormalization").mb-2 Formalizar Crédito
</template>

<script>
import FormalizationStatusTag from '@/components/UI/formalization-status-tag';
import LoadingWrapper from '@/components/wrappers/loading-wrapper';
import Referrals from '@/components/UI/formalization-referrals';
import AppraisalStatusTag from '@/components/UI/appraisal-status-tag';
import TitleStudyStatusTag from '@/components/UI/title-study-status-tag';
import ContractStatusTag from '@/components/UI/contract-status-tag';
import formalizationBlockData from '@/utils/formalization-block-data';
import MortgageNumberStatusTag from '@/components/UI/mortgage-number-status-tag';
import ContractPayDetailsTable from '@/components/UI/contract-pay-details-table';

export default {
  name: 'formalization-tab',

  props: {
    formalization: { type: Object },
    creating: { type: Boolean, default: false },
    requestingReferrals: { type: Boolean, default: false },
    fetching: { type: Boolean, default: false },
    canVisit: { type: Boolean, default: false },
  },

  methods: {
    onClickCreateFormalization() {
      if (window.confirm('¿Está seguro de que desea enviar el crédito a formalización?')) {
        this.$emit('click-create-formalization');
      }
    },

    closeReferralForm() {
      this.$refs.referrals.closeForm();
    },
  },

  computed: {
    appraisal() {
      return this.formalization.appraisals?.[0];
    },

    titleStudy() {
      return this.formalization.titleStudies?.[0];
    },

    blockData() {
      return formalizationBlockData(this.formalization);
    },
    kardexData() {
      return this.formalization.kardex;
    },

    formalizationLink() {
      return {
        name: 'formalizations.show',
        params: {
          formalizationId: this.formalization?.id,
        },
      };
    },

    formalizationTitleStudy() {
      return {
        name: 'formalizations.title_study',
        params: {
          formalizationId: this.formalization?.id,
        },
      };
    },
  },

  components: {
    LoadingWrapper,
    Referrals,
    FormalizationStatusTag,
    AppraisalStatusTag,
    TitleStudyStatusTag,
    ContractStatusTag,
    MortgageNumberStatusTag,
    ContractPayDetailsTable,
  },
};
</script>

<style lang="sass" scoped>
  .formalization-tab
    &__content
      margin: 0 auto

    &__referrals
      padding: 9px 18px
      margin: 0 auto
      background-color: darken(white, 2%)
      border-radius: 6px
</style>
