<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs12 md4 lg2)
        country-select(
          :value="data.country"
          @input="onInputCountry"
          clearable
        )
      v-flex(xs12 md4 lg2)
        bank-select(
          v-model="data.bankId"
          label="Banco"
          clearable
        )
      v-flex(xs12 md4 lg2)
        date-picker(
          v-model="data.fromDate"
          :max="fromDateMax"
          label="Desde"
          clearable
        )
      v-flex(xs12 md4 lg2)
        date-picker(
          v-model="data.toDate"
          :min="toDateMin"
          label="Hasta"
          clearable
        )
      v-flex(xs12 md4 lg2)
        product-select(
          v-model="data.product"
          :country="data.country"
          label="Producto"
        )
      v-flex(xs12 md4 lg2)
        source-select(
          v-model="data.source"
          :country="data.country"
          label="Source"
        )
      v-flex(xs12 md4 lg2)
        medium-select(
          v-model="data.medium"
          :country="data.country"
          label="Medium"
        )
      v-flex(xs12 md4 lg2)
        campaign-select(
          v-model="data.campaign"
          :country="data.country"
          label="Campaign"
        )
      v-flex(xs12 md4 lg2)
        adviser-select(
          v-model="data.adviserEmail"
          :country="data.country"
          label="Asesor"
        )
      v-flex(xs12 md4 lg2)
        group-by-select(
          v-model="data.groupBy"
        )
      v-flex.text-right
        v-btn(
          color="primary"
          :disabled="isDisabled"
          @click="onSubmit"
        ) Aplicar filtros
</template>

<script>
  import moment from 'moment';
  import basicFormMixin from '@/components/forms/basic-form-mixin'
  import DatePicker from '@/components/inputs/input-date-picker';
  import BankSelect from '@/components/inputs/bank-select';

  import SourceSelect from '../../components/source-select';
  import MediumSelect from '../../components/medium-select';
  import CampaignSelect from '../../components/campaign-select';
  import ProductSelect from '../../components/product-select';
  import AdviserSelect from '../../components/adviser-select';
  import CountrySelect from '../../components/country-select';
  import GroupBySelect from '../../components/group-by-select';

  export default {
    name: 'filters',

    mixins: [
      basicFormMixin({
        modelKey: 'filters',
        mapPropsToData({ filters }) {
          return {
            country: filters?.country,
            bankId: filters?.bankId,
            fromDate: filters?.fromDate,
            toDate: filters?.toDate,
            product: filters?.product,
            source: filters?.source,
            medium: filters?.medium,
            adviserEmail: filters?.adviserEmail,
            campaign: filters?.campaign,
            groupBy: filters?.groupBy,
          };
        },
      }),
    ],

    computed: {
      fromDateMax() {
        if(!this.data.toDate) return;

        return moment(this.data.toDate).startOf('day').subtract(1, 'day').format();
      },
      toDateMin() {
        if(!this.data.fromDate) return;

        return moment(this.data.fromDate).subtract(1, 'day').startOf('day').format();
      },
    },

    components: {
      BankSelect,
      ProductSelect,
      SourceSelect,
      MediumSelect,
      CampaignSelect,
      AdviserSelect,
      DatePicker,
      CountrySelect,
      GroupBySelect,
    },

    methods: {
      onInputCountry(country) {
        if(country) this.data.country = country;
        else {
          Object.assign(this.data, {
            country: undefined,
            source: undefined,
            medium: undefined,
            campaign: undefined,
          });
        }
      }
    },
  };
</script>

<style lang="sass">
  .filters
    .flex
      padding: 0 6px

    .v-btn
      padding-left: 32px
      padding-right: 32px
</style>