<template lang="pug">
  v-layout(:class="b('attachments')" wrap)
    v-flex(xs12 sm6 lg4 v-for="attachment, idx in attachments" :key="attachment.mediaPath")
      attachment(
        :number="idx + 1"
        :attachment="attachment"
        :deletable="canDelete"
        @delete="onClickDelete"
      )
</template>

<script>
  import Attachment from './attachments-item';

  export default {
    name: 'attachments',

    props: {
      attachments: { type: Array, required: true },
      canDelete: { type: Boolean },
    },

    methods: {
      onClickDelete({ attachment }) {
        if(window.confirm('¿Está seguro de que desea eliminar el documento?')) this.$emit('delete', { attachment })
      },
    },

    components: { Attachment },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .attachments
    display: flex
    flex-wrap: wrap
</style>