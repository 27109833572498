import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

const COLORS = {
  [INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED]: '#f44336',
  [INVESTMENT_PROPOSAL_STATUS.COLLECTING]: '#4caf50',
  [INVESTMENT_PROPOSAL_STATUS.ASSIGNED]: '#8ec9bb',
  [INVESTMENT_PROPOSAL_STATUS.PENDING]: '#9c8ade',
  [INVESTMENT_PROPOSAL_STATUS.FINISHED]: '#0091ff',
  [INVESTMENT_PROPOSAL_STATUS.CANCELLED]: '#737373',
  [INVESTMENT_PROPOSAL_STATUS.REFINANCED]: '#8f4068',
  [INVESTMENT_PROPOSAL_STATUS.EXTENDED]: '#FFB200',
  [INVESTMENT_PROPOSAL_STATUS.RESCHEDULE]: '#EC00FF',
};

export default status => COLORS[status];
