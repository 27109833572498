<template lang="pug">
  validation-observer(ref="form")
    div(:class="b()")
      div(
        v-for="item, idx in value"
        :class="b('entry')"
        :key="item.id"
      )
        div(:class="b('entry-body')")
          v-layout(wrap)
            v-flex(xs12 md6).px-2
              validation-provider(:name="`carga/gravamen ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
                lien-charge-select(
                  :name="`carga/gravamen ${idx + 1}`"
                  :error-messages="errors"
                  :value="item.lienCharge"
                  :disabled="disabled"
                  :default-items="lienChargeSelectDefaultItems"
                  label="Carga/Gravamen"
                  @input="lienCharge => onInputField(idx, 'lienCharge', lienCharge)"
                  return-object
                )
            v-flex(xs12 md6).px-2
              validation-provider(:name="`asiento ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
                v-text-field(
                  :name="`asiento ${idx + 1}`"
                  :error-messages="errors"
                  :value="item.settle"
                  :disabled="disabled"
                  label="Asiento"
                  @input="v => onInputField(idx, 'settle', v)"
                )
            v-flex(xs12 md6).px-2
              validation-provider(:name="`moneda ${idx + 1}`" :rules="'required'" v-slot="{ errors }")
                currency-select(
                  :name="`moneda ${idx + 1 }`"
                  :error-messages="errors"
                  :value="item.currency ? item.currency.id : null"
                  :disabled="disabled"
                  label="Moneda"
                  return-object
                  @input="v => onInputField(idx, 'currency', v)"
                )
            v-flex(xs12 md6).px-2
              validation-provider(:name="`monto ${idx + 1}`" :rules="'required|min_value:0'" v-slot="{ errors }")
                v-text-field(
                  :name="`monto ${idx + 1}`"
                  :error-messages="errors"
                  :value="item.chargeAmount"
                  :disabled="disabled"
                  :prefix="item.currency ? item.currency.symbol : 'S/'"
                  label="Monto"
                  type="number"
                  step="0.01"
                  @input="v => onInputField(idx, 'chargeAmount', v)"
                )
          //- validation-provider(:name="`deudor ${idx + 1}`" :rules="{}" v-slot="{ errors }")
          debtor-select(
            :name="`deudor ${idx + 1}`"
            :error-messages="errors"
            :value="item.creditor"
            :disabled="disabled"
            :default-items="debtorSelectDefaultItems"
            label="Acreedor"
            @input="creditor => onInputField(idx, 'creditor', creditor)"
            return-object
            creatable
          ).px-2

        div(:class="b('entry-actions')").d-flex.justify-end
          div(:class="b('delete-button')")
            v-btn(
              v-if="value.length > 0"
              color="error"
              @click="deleteItem(idx)"
              text
            ) Eliminar
      v-alert(v-if="errors && errors.length" color="error" type="error") {{ errors[0] }}
      .d-flex.justify-center
        v-btn(
          v-if="value.length < maxLength"
          @click="addItem"
          color="primary"
          text
        )
          v-icon mdi-plus
          span Agregar Carga/Gravamen
</template>

<script>
import Config from '@/config';
import DebtorSelect from '@/components/inputs/debtor-select';
import CurrencySelect from '@/components/inputs/currency-select';
import dynamicEntriesInputMixin from '@/mixins/dynamic-entries-input-mixin';

import LienChargeSelect from './lien-charge-select';

export default {
  name: 'lien-charge-lines-input',

  mixins: [
    dynamicEntriesInputMixin({
      itemDefaultValues: {
        settle: null,
        lienCharge: null,
        creditor: null,
        chargeAmount: null,
        currency: {
          ...Config.CURRENCIES_SELECT[0],
        },
      },
    }),
  ],

  computed: {
    lienChargeSelectDefaultItems() {
      return this.value
        .filter(({ lienCharge }) => lienCharge)
        .map(({ lienCharge }) => lienCharge);
    },

    debtorSelectDefaultItems() {
      return this.value
        .filter(({ creditor }) => creditor)
        .map(({ creditor }) => creditor);
    },
  },

  components: { LienChargeSelect, DebtorSelect, CurrencySelect },
};
</script>

<style lang="sass" scoped>
@import '~@/sass/mixins'

.lien-charge-lines-input
  &__entry
    padding: 8px 0
    border-radius: 8px

    &:nth-child(odd)
      background-color: darken(white, 4%)


  +xs
    &__delete-button
      display: flex
      justify-content: flex-end

  +md
    &__entry
      padding: 8px 12px
</style>
