<template lang="pug">
  div(:class="b({ rescheduled: isRescheduled })")
    div(:class="b('body')")
      div(:class="b('header')")
        div(:class="b('reference')") # {{ quote.feeNumber }}
        status-tag(:class="b('header-label')" :status="quote.status")
        span(:style="{ backgroundColor: getColorOfQualificationByCompliance }") {{ getTextOfQualificationByCompliance }}
        div(:class="b('header-title')") {{ quoteData.amountDue | formatCurrency(currency) }}
        v-chip(color="#929292 " small).font-weight-medium.ml-1 {{ quote.originalFee.expirationDate | formatDate('D MMM YYYY') }}
        div(v-if="amortMonths.quantityAmortizedMonths"  :class="b('data')")  
          div(:class="b('data-value')") El plazo se disminuyó en {{ amortMonths.quantityAmortizedMonths }}
          div(:class="b('data')") Meses

      div(:class="b('content')")
        div(v-if="quoteData.interest" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.interest | formatCurrency(currency) }}
          div(:class="b('data-title')") Interés
        div(v-if="quoteData.propertyInsurance" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.propertyInsurance | formatCurrency(currency) }}
          div(:class="b('data-title')") Seguro Inmueble
        div(v-if="quoteData.lifeInsurance" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.lifeInsurance | formatCurrency(currency) }}
          div(:class="b('data-title')") Seguro Vida
        div(v-if="quoteData.penaltyAmount" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.penaltyAmount | formatCurrency(currency) }}
          div(:class="b('data-title')") Penalidad
        div(v-if="quoteData.defaultAmount" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.defaultAmount | formatCurrency(currency) }}
          div(:class="b('data-title')") Mora
        div(v-if="quoteData.amortization" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.amortization | formatCurrency(currency) }}
          div(:class="b('data-title')") Amortización
        div(v-if="quoteData.legalExpenses" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.legalExpenses | formatCurrency(currency) }}
          div(:class="b('data-title')") Costos procesales
        div(v-if="quoteData.collectingExpenses" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.collectingExpenses | formatCurrency(currency) }}
          div(:class="b('data-title')") Costas procesales
        div(v-if="quoteData.managementFee" :class="b('data')")
          div(:class="b('data-value')") {{ quoteData.managementFee | formatCurrency(currency) }}
          div(:class="b('data-title')") Gastos Administrativos
    div(:class="b('footer')")
      div(v-if="isPaid" :class="b('paid-status')")
        div(:class="b('paid-status-number')") # {{ quote.paymentOperationNumber }}
        div(:class="b('paid-status-amount')") {{ quote.amount | formatCurrency(currency) }}
        div(:class="b('paid-status-date')") {{ quote.paymentDate | formatDate }}
      div(v-else-if="quote.canPay && !isRescheduled && !isJudicialized" :class="b('register-button')" @click="onClickRegisterPayment")
        div Registrar Pago
      div(v-if="quote.canReschedule && !isJudicialized" :class="b('reschedule-button')" @click="onClickReschedule") Reprogramar
</template>

<script>
import { QUOTE_STATUS } from '@/utils/constants';
import StatusTag from '@/components/UI/quote-status-tag';
import { INVESTMENT_PROPOSAL_STATUS } from '@/utils/constants';

export default {
  name: 'quotes-item',

  props: {
    quote: { type: Object },
    currency: { type: Object },
    proposal: { type: Object },
  },

  computed: {
    isPaid() {
      return [QUOTE_STATUS.PAID, QUOTE_STATUS.PAID_DELAYED].includes(this.quote.status);
    },

    isRescheduled() {
      return this.quote.status === QUOTE_STATUS.RESCHEDULED;
    },

    quoteData() {
      return this.isRescheduled ? this.quote.originalFee : this.quote.currentData;
    },

    amortMonths() {
      return this.isRescheduled ? this.quote : this.quote;
    },

    getColorOfQualificationByCompliance() {
      const colors = {
        NOR: '#00B050',
        CPP: '#92D051',
        DEF: '#FFCC02',
        DUD: '#FF6600',
        PER: '#C00000',
      }
      return colors[this.quote.sbsStatus] || '#C0C0C0';
    },

    getTextOfQualificationByCompliance() {
      const values = {
        NOR: 'NOR',
        CPP: 'CPP',
        DEF: "DEF",
        DUD: "DUD",
        PER: "PER",
      }
      return values[this.quote.sbsStatus] || 'N/A';
    },

    isJudicialized() {
      const status = this.proposal?.qualificationStatus?.status.toUpperCase();
      return status ? INVESTMENT_PROPOSAL_STATUS.JUDICIALIZED === status : false;
    },
  },

  methods: {
    onClickRegisterPayment() {
      this.$emit('register-payment', { quote: this.quote });
    },

    onClickReschedule() {
      this.$emit('click-reschedule', { quote: this.quote });
    },
  },

  components: { StatusTag },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .quotes-item
    display: flex
    flex-direction: column
    height: 220px
    padding: 12px 0
    font-size: 12px

    &__reference
      min-width: 30px
      white-space: nowrap
      font-weight: bold
      margin-right: 4px

    &__body
      flex: 1

    &__header
      display: flex
      align-items: center
      padding-bottom: 4px

      &-title
        font-size: 16px
        font-weight: bold

      &-label
        margin-right: 12px
        height: 24px

      span
        margin-right: 12px
        padding: 0px 8px
        border-radius: 8px
        color: white

    &__footer
      padding-top: 6px

    &__content
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: space-between
      padding: 0 8px

    &__data
      display: flex
      align-items: center
      width: 50%

      &-value
        font-size: 16px
        margin-right: 4px
        white-space: nowrap

      &-title
        font-size: 11px
        color: darken(white, 70%)


    &__paid-status
      text-align: right

      &-date
        font-weight: 500

      &-amount
        font-size: 20px
        font-weight: 500
        color: #5bde5f

      &-number
        color: darken(white, 45%)

    &__register-button
      padding: 6px

      background-color: transparentize($primaryColor, 0.88)
      color: $primaryColor

      text-align: center
      cursor: pointer
      font-size: 13px
      line-height: 1.4

      border-radius: 6px

    &__reschedule-button
      padding: 6px
      margin-top: 4px

      color: red
      font-size: 13px
      text-align: center
      cursor: pointer

    +md
      font-size: 14px
      flex-direction: row
      height: 165px

      &__reference
        min-width: 32px

      &__footer
        display: flex
        flex-direction: column
        justify-content: center
        padding-top: 0
        width: auto

      &__paid-status
        &-amount
          font-size: 22px

      &__data
        &-value
          font-size: 16px
          margin-right: 6px
          white-space: nowrap

        &-title
          font-size: 12px

      &__register-button
        padding: 8px 12px

        font-size: 13px
        line-height: 1.4
</style>
