<template lang="pug">
  div(:class="b()")
    div(:class="b('debt')" v-for="debt, idx in [...debts].reverse()" :key="idx" v-if="debt.hasDebt")
      div(:class="b('debt-title')") {{ debt.period | formatDate }}
      div(:class="b('debt-entries')")
        div(:class="b('debt-entries-item')" v-for="productDebt in debt.productDebts" :key="productDebt.id")
          div(v-if="productDebt.debtInPen") #[b Deuda en Soles] {{ productDebt.debtInPen | formatCurrency }}
          div(v-if="productDebt.debtInUsd") #[b Deuda en Dólares] {{ productDebt.debtInUsd | formatCurrency }}
</template>

<script>
  export default {
    name: 'unpaid-debts-tab',

    block: 'debts-tab',

    props: {
      debts: { type: Array, default: () => [] },
    },
  };
</script>
