<template lang="pug">
  v-dialog(:value="value" @input="args => $emit('input', args)" width="400")
    v-card(:class="b()")
      .title ¿Cual es la fecha del desembolso?
      div(:class="b('content')")
        v-date-picker(v-model="date" full-width scrollable)
      v-card-actions
        v-spacer
        v-btn(
          :disabled="date === null"
          color="primary"
          text
          @click="onSubmit"
        ) Guardar
</template>

<script>
  import DatePicker from '@/components/inputs/input-date-picker';

  export default {
    name: 'deal-disbursement-date-modal',

    props: {
      value: { type: Boolean, default: false },
    },

    data() {
      return {
        date: null,
      };
    },

    methods: {
      onSubmit() {
        this.$emit('submit', this.date);
      },
    },

    components: { DatePicker },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deal-disbursement-date-modal
    padding: 1.5rem 1.5rem 0 1.5rem

    &__content
      padding: 16px 0

      text-align: center

      +sm
        padding: 24px 18px
</style>

