<template lang="pug">
  div(:class="b()")
    div(:class="b('icon-container')")
      product-icon(:slug="product.slug" :size="42")
    div(:class="b('body')")
      div(:class="b('title')") {{ $t(`productSlug.${product.slug}`) }}
      div(:class="b('summary')")
        div(:class="b('deals')") {{ product.deals }} Negociaciones
        div(:class="b('amount')") {{ product.amount | formatCurrency(product.currency) }}
</template>

<script>
  import ProductIcon from '@/components/UI/product-icon';

  export default {
    name: 'deals-products-item',

    props: {
      product: { type: Object, required: true },
    },

    components: { ProductIcon },
  };
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .deals-products-item
    display: flex
    align-items: center
    margin: 16px 0

    &__icon-container
      display: block
      margin-right: 8px

      +lg
        margin-right: 16px

    &__body
      flex: 1

    &__title
      font-weight: 500
      font-size: 15px


      +lg
        font-size: 16px
        margin-bottom: 4px

    &__summary
      +lg
        display: flex
        justify-content: space-between
        align-items: center

    &__deals
      font-size: 14px
      color: #606060

    &__amount
      font-size: 15px
      font-weight: bold
      color: #444444

      +lg
        font-size: 18px
</style>