<template lang="pug">
  div(:class="b()")
    v-layout(wrap)
      v-flex(xs12)
        v-layout
          v-flex
            div(v-if="address" :class="b('property-address')") {{ address }}
          div(:class="b('property-actions')")
            v-icon.cursor-pointer(@click.native="onClickEdit") mdi-pencil
            v-icon.cursor-pointer(@click.native="onClickDelete") mdi-delete
        div(:class="b('property-data')").justify-end
          div(:class="b('property-value')")
            div(:class="b('property-value-amount')") {{ property.value | formatCurrency(property.currency, { precision: 0 }) }}
            div(:class="b('property-value-title')") Comercial
            v-tooltip(v-if="property.valuationDate" top)
              template(v-slot:activator="{ on }")
                v-icon(v-on="on" color="success").pl-1 mdi-check-decagram
              span {{ property.valuationDate | formatDate('D MMMM YYYY') }}
          div(v-if="property.auctionValue" :class="b('property-value')")
            div(:class="b('property-value-amount')") {{ property.auctionValue | formatCurrency(property.currency, { precision: 0}) }}
            div(:class="b('property-value-title')") Realización
          .d-flex(v-if="squareMeterValue" style="flex-wrap: nowrap; margin-right: 8px")
            div(:class="b('property-value')" style="margin-right: 0")
              div(:class="b('property-value-amount')") {{ squareMeterValue | formatCurrency(property.currency, { precision: 0}) }}
              div(:class="b('property-value-title')") m²
            div(:class="b('property-m2-min')")
              v-icon(v-if="property.m2HigherThanMin === true" color="success").pl-1 mdi-check-decagram
              v-tooltip(v-else-if="property.m2HigherThanMin === false" top)
                template(v-slot:activator="{ on }")
                  v-icon(v-on="on" color="error").pl-1 mdi-alert-circle
                span m2 por debajo del mínimo permitido
        div(:class="b('property-data')").mt-2
          v-tooltip(top)
            template(v-slot:activator="{ on }")
              .mr-2(v-on="on")
                customer-chip(
                  v-if="property.realOwner"
                  :customer="property.realOwner"
                  :suffix="property.realOwnerRelationship"
                  profile-link
                )
            span Propietario del inmueble
          div(:class="b('property-type')" v-if="property.type").mr-2
            span {{ property.type.name }}
            b(v-if="property.yearOfConstruction") &nbsp;({{ property.yearOfConstruction }})
            b(v-if="property.registrationNumber") &nbsp; - {{ property.registrationNumber }}
      v-flex(xs12).text-center
        //-v-tooltip(v-if="property.statusCertificate == null" top)
        //-  template(v-slot:activator="{ on }")
        //-  v-icon(v-on="on") mdi-minus-circle
        //-    span {{ `Aun no se solicita partida` }}
        //-v-tooltip(v-else-if="property.statusCertificate == 'FINISHED'" top)
        //-  template(v-slot:activator="{ on }")
        //-    v-icon(v-on="on"  color="success") mdi-check-decagram
        //-  span Partida Generada Exitosamente ({{ property.certificateRequestedAt | formatDate("D MMM YYYY - hh:mm A") }})
        //-v-tooltip(v-else-if="property.statusCertificate == 'ERRORS'" top)
        //-  template(v-slot:activator="{ on }")
        //-    v-icon(v-on="on" color="error") mdi-close-circle
        //-  span Error Solicitando Partida ({{ property.certificateRequestedAt | formatDate("D MMM YYYY - hh:mm A") }})
        //-v-tooltip(v-else-if="property.statusCertificate == 'PENDING'" top)
        //-  template(v-slot:activator="{ on }" )
        //-    v-icon(v-on="on" color="warning") mdi-dots-circle
        //-  span Solicitando Partida ({{ property.certificateRequestedAt | formatDate("D MMM YYYY - hh:mm A") }})
      div(:class="b('credit')" v-if="credit")
        v-layout
          v-flex
            figure(:class="b('bank-logo')")
              img(:src="bankLogo")
          div(style="display: flex")
            v-icon.cursor-pointer(@click.native="onClickEditCredit") mdi-pencil
            v-icon.cursor-pointer(@click.native="onClickDeleteCredit") mdi-delete
        v-layout(wrap)
          v-flex(xs12) #[b Cuota: ] {{ credit.simpleFee | formatCurrency(credit.currency) }}
          v-flex(v-if="credit.doubleFee" xs12) #[b Cuota Double: ] {{ credit.doubleFee | formatCurrency(credit.currency) }}
          v-flex(xs12 v-if="credit.amortizationType") #[b Tipo de Mensualidad: ] {{ $t(`amortization.${credit.amortizationType}`)}}
          v-flex(xs12) #[b {{ $t('tea') }}: ] {{ credit.tea }} %
          v-flex(v-if="credit.tcea" xs12 ) #[b {{ $t('tcea') }}: ] {{ credit.tcea }} %
          v-flex(xs12) #[b Tasa: ] {{ $t(`rateType.${credit.rateType}`) }}
          v-flex(xs12) #[b Plazo: ] {{ credit.limitMonths }} meses
          v-flex(xs12) #[b Seguro: ] {{ $t(`insuranceTypeId.${credit.insuranceType.id}`) }}
          v-flex(xs12 :class="b('credit-amount')").text-right {{ credit.credit | formatCurrency(credit.currency) }}
      v-flex(v-else xs12).text-center
        v-btn(@click="onClickAddCredit" color="primary" text)
          v-icon mdi-plus
          |&nbsp; Agregar Hipoteca
      //-v-flex(xs12 :class="b('electronic-record')").text-center(v-if="$projectConfig.isPECountry")
      //-  v-btn(@click="onClickAddElectronicRecord" color="secondary" text)
      //-    v-icon mdi-file-document-outline
      //-    |&nbsp; Partida Electrónica
</template>

<script>
import CustomerChip from '@/components/UI/customer-chip';
import addressToString from '@/utils/address-to-string';

export default {
  name: 'properties-row',

  props: {
    property: { type: Object, required: true },
    customer: { type: Object },
  },

  methods: {
    onClickEditCredit() {
      this.$emit('edit-credit', { credit: this.credit });
    },

    onClickEdit() {
      this.$emit('edit', { property: this.property });
    },

    onClickDelete() {
      this.$emit('delete', { property: this.property });
    },

    onClickAddCredit() {
      this.$emit('add-credit', { property: this.property });
    },

    onClickDeleteCredit() {
      this.$emit('delete-credit', {
        credit: {
          propertyId: this.property.id,
          ...this.property.credit,
        },
      });
    },

    onClickAddElectronicRecord() {
      this.$emit('add-electronic-record', { property: this.property, customer: this.customer });
    },
  },

  computed: {
    credit() {
      return this.property?.credit;
    },

    bankLogo() {
      return this.$cloudfrontURL(`/logos/${this.credit?.bank?.shortName.toLowerCase()}.svg`)
    },

    address() {
      return addressToString(this.property.address);
    },

    squareMeterValue() {
      return this.property.auctionValue && this.property.totalArea ? this.property.auctionValue / this.property.totalArea : null
    },
  },

  components: { CustomerChip },
};
</script>

<style lang="sass" scoped>
  @import '~@/sass/mixins'

  .properties-row
    padding: .5rem 0

    &__property-actions
      flex-shrink: 0

    &__property-address
      font-style: italic

    &__property-value
      display: flex
      flex-wrap: wrap
      align-items: center
      white-space: nowrap

      &-amount
        font-size: 17px
        font-weight: 500

      &-title
        padding-left: 6px
        font-size: 12px

    &__property-type
      align-self: center
      padding: 5px 12px

      font-size: 14px

      color: black
      background-color: #e0e0e0

      border-radius: 18px

    &__credit
      width: 100%
      padding: 4px 8px
      margin-top: .5rem
      background-color: darken(white, 3%)

    &__bank-logo
      height: 26px
      margin-bottom: .5rem

      img
        height: 100%

    &__credit-amount
      padding-right: .5rem

      font-size: 20px
      font-weight: bold

    &__valuation-date
      padding-left: .5rem

    &__electronic-record
      padding-top: .5rem

    +sm
      &__property-data
        display: flex

      &__property-value
        margin-right: 16px
</style>
