<template lang="pug">
  validation-observer(ref="form")
    form(@submit.prevent="onSubmit")
      div(:class="b('form-group')")
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Monto
          div(:class="b('input-group-container')")
            validation-provider(name="debtAmount" :rules="rules.debtAmount" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.debtAmount"
                :prefix="currency.symbol"
                type="number"
                name="debtAmount"
                step="0.01"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Plazo
          div(:class="b('input-group-container')")
            validation-provider(name="quotesQuantity" :rules="rules.quotesQuantity" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.quotesQuantity"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="quotesQuantity"
                step="1"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") % de capital al final del perido
          div(:class="b('input-group-container')")
            validation-provider(name="balloonPaymentType" :rules="rules.balloonPaymentType" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.balloonPaymentType"
                :error-messages="errors"
                type="number"
                name="balloonPaymentType"
                step="0.01"
                prefix="%"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Primera cuota
          div(:class="b('input-group-container')")
            validation-provider(name="firstPaymentDate" :rules="rules.firstPaymentDate" v-slot="{ errors }")
              date-picker(
                v-model="data.firstPaymentDate"
                :error-messages="errors"
                name="firstPaymentDate"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Mora %
          div(:class="b('input-group-container')")
            validation-provider(name="defaultRate" :rules="rules.defaultRate" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.defaultRate"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="defaultRate"
                step="0.01"
                prefix="%"
              )
        div(:class="b('input-group')")
          div(:class="b('input-group-title')") Penalidad
          div(:class="b('input-group-container')")
            validation-provider(name="penaltyFee" :rules="rules.penaltyFee" v-slot="{ errors }")
              v-text-field(
                v-model.number="data.penaltyFee"
                :error-messages="errors"
                :prefix="currency.symbol"
                type="number"
                name="penaltyFee"
                step="0.01"
              )
        div(:class="b('input-group')")
            div.radio-container
              div.radio-item
                input(
                  type="radio"
                  v-model="typeStatus"
                  value="REFINANCED"
                  id="typeStatus-refinanced"
                  @change="handleRadioChange"
                )
                label(for="typeStatus-refinanced") Refinanciamiento
              div.radio-item
                input(
                  type="radio"
                  v-model="typeStatus"
                  value="EXTENDED"
                  id="typeStatus-extended"
                  @change="handleRadioChange"
                )
                label(for="typeStatus-extended") Ampliación
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="onCancel") Cancelar
        v-btn(
          :loading="requesting"
          color="primary"
          text
          type="submit"
        ) Guardar cambios
</template>

<script>
import basicFormMixin from './basic-form-mixin';
import DatePicker from '@/components/inputs/input-date-picker';

export default {
  name: 'investment-duplicate-proposal-form',

  props: {
    currency: { type: Object },
  },

  data() {
    return {
      typeStatus: 'REFINANCED',
    }
  },

  mixins: [
    basicFormMixin({
      modelKey: 'duplicateProposal',
      veeValidation: true,

      mapPropsToData({ duplicateProposal }) {
        return {
          id: duplicateProposal.id,
          debtAmount: duplicateProposal.pendingDebtBorrower,
          quotesQuantity: duplicateProposal.pendingQuotesQuantity,
          balloonPaymentType: duplicateProposal.balloonPaymentType || 0,
          borrowerRate: duplicateProposal.borrowerRate,
          firstPaymentDate: duplicateProposal.firstPaymentDate,
          defaultRate: duplicateProposal.defaultRate,
          penaltyFee: duplicateProposal.penaltyFee,
          typeStatus: 'REFINANCED',
        };
      },
    }),
  ],

  computed: {
    rules() {
      return {
        debtAmount: { required: true, min_value: 0 },
        quotesQuantity: { required: true, min_value: 0 },
        balloonPaymentType: { required: false, min_value: 0, max_value: 100 },
        borrowerRate: { required: true, min_value: 0 },
        firstPaymentDate: { required: true },
        defaultRate: { required: true, min_value: 0 },
        penaltyFee: { required: true, min_value: 0 },
      };
    },
  },

  methods: {
    handleRadioChange() {
      if (this.typeStatus) {
        this.data.typeStatus = this.typeStatus;
      } else {
        delete this.data.typeStatus;
      }
    },
  },

  components: { DatePicker },
};
</script>

<style lang="sass" scoped>
  .radio-container
    display: flex
    gap: 1.8rem
    margin: 20px

  .radio-item
    margin-bottom: 10px

  input[type="radio"]
    margin-right: 8px

  .investment-duplicate-proposal-form
    &__input-group
      width: 100%
      display: flex
      padding-left: 8px
      padding-right: 8px
      align-items: center

      &:nth-child(odd)
        background-color: darken(white, 2%)


      &-title
        flex: 1
        min-width: 120px
</style>
